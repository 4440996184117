import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { withRouter } from 'react-router-dom';
import listHOC from '../components/ListHOC.js';
import ActivityLog from '../components/ActivityLog';
import ContactTabForm from '../components/ContactTabForm.js';
import ContactNote from '../components/ContactNote';
import BalancesPreview from '../components/BalancesPreview';
import UserContext from '../context/UserContext';
import DevicePreview from '../components/DevicePreview';



const styles = {
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
};
/**
 * The contact display page. This page is expected to be the screen pop for 
 * an agent when a call connects.
 * @category Pages
 * @component
 */
class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;
        this.state = {
            contactId: this.props.match.params.id
        };
        this.context = {
            userContext: UserContext
        }
        this.options = {"activityLogContactId":this.props.match.params.id}
        this.note = {
            title: '',
            body: ''
        }

        this.rteRef = React.createRef();
    }

    render() {
        const ActivityLogHOC = listHOC(ActivityLog, queries.getContactActivity, subscriptions.onTenantUpdateActivityLog, subscriptions.onTenantCreateActivityLog, subscriptions.onTenantDeleteActivityLog);
        return (
            <UserContext.Consumer>
                {({ tenant, licenses }) => (
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Grid item container diretion="row" spacing={1}>
                            <Grid xs={12} md={8} item>
                                <ContactTabForm contactId={this.state.contactId} />
                            </Grid>
                            <Grid xs={12} md={4} item>
                                <ContactNote contactId={this.state.contactId} />
                                <div hidden={!licenses.includes('PromptPay')}>
                                    <br></br>
                                <BalancesPreview contactId={this.state.contactId} />
                                </div>
                                <br></br>
                                <DevicePreview contactId={this.state.contactId} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ActivityLogHOC
                                tenant={tenant}
                                filter={{ activityLogContactId: { eq: this.state.contactId } }}
                                options={this.options}
                                subscriptionFilter={{ name: 'activityLogContactId', value: this.state.contactId }}
                                listProperty="getContactActivity"
                                onCreateProperty="onTenantCreateActivityLog"
                                onUpdateProperty="onTenantUpdateActivityLog"
                                onDeleteProperty="onTenantDeleteActivityLog" />
                        </Grid>
                    </Grid>
                )}
            </UserContext.Consumer>
        )
    }
}
export default withRouter(withStyles(styles)(Contact));
import React from 'react';
import {Grid, FormControlLabel, Checkbox, Button} from '@material-ui/core';
import StepperController from './StepperController';
import {useSnackbar} from 'notistack';

export default function MonitorApplication(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {nextStep, stepBack, appComplete, setAppComplete, cmsMerchHash} = props;

    function advanceStep() {
        if(!appComplete) {
            enqueueSnackbar("Application must be completed before continuing", { variant: "error" });
        } else {
            nextStep();
        }
    }

    return (
        <div>
            <Grid container direction="row" spacing={8}>
                <Grid item>
                    <Button variant="contained" color="primary" href={`https://onlineapp.cmsonline.com/par/658610680cac217db148ddb144efcf3b1115502b296342c314f1363db9192c44/app/${cmsMerchHash}`} target="_blank">Monitor Application</Button>
                </Grid>
                <Grid item>
                    <FormControlLabel control={<Checkbox checked={appComplete} onChange={(event) => setAppComplete(event.target.checked)} color="primary"/>} label="Application Complete" labelPlacement="end"/>
                </Grid>
            </Grid>
            <StepperController onStepBack={stepBack} onStepForward={advanceStep} />
        </div>
    );
}
import React, { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles, Paper, Grid, TextField, Button, DialogContent, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { FormControlLabel, Typography, Divider, Checkbox, IconButton } from '@material-ui/core';
import { List, ListItem, ListItemText, ListSubheader, Switch } from '@material-ui/core';
import NotInterested from '@material-ui/icons/NotInterestedOutlined';
import DoneAll from '@material-ui/icons/DoneAllOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
});

/**
 * Add Custom Retries to the Profile
 * @component
 * @example
 * const 
 * return (
 *  <CustRetryDialog dispos={} open={} addCustom={} cancel={} edit={}/>
 * )
 */
export default function CustRetryDialog(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [priority, setPriority] = useState(false);
    const [custRetryName, setCustRetryName] = useState('');
    const [retryByAttempt, setRetryByAttempt] = useState({ minDuration: 1, start: 1, end: 1, priority: false });
    const [pendingRetry, setPendingRetry] = useState({ name: '', codes: [], retriesbyattempt: [], max: '' });
    const [maxAttempts, setMaxAttempts] = useState(0);
    const [nextStart, setNextStart] = useState(1);
    const endCounter = useRef(0);
    const [pendingDispos, setPendingDispos] = useState([]);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (props.open && props.dispos.length < 1) {
            enqueueSnackbar('All Dispositions already have been configured with a custom retry setting', {
                variant: 'error',
                autoHideDuration: 4000
            });
            handleCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    useEffect(() => {
        let validationString = new RegExp(/[a-zA-Z0-9]/);
        setIsValid(validationString.test(custRetryName) && retryByAttempt.minDuration >= 1 && retryByAttempt.end >= 1);
    }, [custRetryName, retryByAttempt]);

    useEffect(() => {
        endCounter.current = retryByAttempt.end;
    }, [retryByAttempt.end]);


    const handleSave = async () => {
        if (props.addCustom) {
            //await props.pending([...pendingDispos]);
            //await props.retry({...pendingRetry});
            await props.addCustom(pendingDispos, pendingRetry);
            //once added reset the values
            setRetryByAttempt({ minDuration: 1, start: 1, end: 1 });
            setPendingRetry({ name: '', codes: [], retriesbyattempt: [] });
            setMaxAttempts(0);
            setNextStart(1);
            setPendingDispos([]);
            endCounter.current = 0;

            props.toggle();
        }
    }

    const handleCancel = () => {
        //reset all of the basic values
        setRetryByAttempt({ minDuration: 1, start: 1, end: 1 });
        setPendingRetry({ name: '', codes: [], retriesbyattempt: [] });
        setMaxAttempts(0);
        setNextStart(1);
        setPendingDispos([]);
        endCounter.current = 0;
        if (props.toggle) {
            props.toggle();
        }
    }

    const handleSelectRetryName = (event) => {
        setCustRetryName(event.target.value);
        setPendingRetry({
            ...pendingRetry,
            name: event.target.value
        })
    }

    const checkDispo = (event) => {
        if (!event.target.checked && pendingDispos.includes(event.target.name)) {
            pendingDispos.splice(pendingDispos.indexOf(event.target.name), 1);
            //    const selDispos = [...pendingDispos];
            //    selDispos.splice(selDispos.indexOf(event.target.name), 1);
            //    setPendingDispos([...selDispos]);
        }
        else if (event.target.checked && !pendingDispos.includes(event.target.name)) {
            pendingDispos.push(event.target.name)
        }
        setPendingDispos([...pendingDispos]);
        setPendingRetry({
            ...pendingRetry,
            codes: pendingDispos
        })
    }

    const checkAllDispos = () => {
        let allDispos = [];
        for (const dispo of props.dispos) {
            if (!pendingDispos.includes(dispo.name)) {
                allDispos.push(dispo.name);
            }
        }
        setPendingDispos(allDispos);

    }

    const unCheckAllDispos = () => {
        setPendingDispos([]);

    }

    const handleSelectDuration = (event) => {
        setRetryByAttempt({
            ...retryByAttempt,
            minDuration: Number(event.target.value)
        })
    }

    function handleSelectPriority (){
        const temp = (!priority);
        setPriority(temp);
        setRetryByAttempt({
            ...retryByAttempt,
            priority: temp
        })
    }

    const handleSelectEnd = (event) => {
        setRetryByAttempt({
            ...retryByAttempt,
            end: Number(event.target.value)
        });
    }

    const addRetryByAttempt = () => {
        let nxtStart = Number(endCounter.current) + 1;
        setNextStart(nxtStart)
        setRetryByAttempt({
            ...retryByAttempt,
            start: Number(nxtStart),
            end: Number(nxtStart)
        });
        const retryInfo = { ...pendingRetry };
        retryInfo.retriesbyattempt.push(retryByAttempt);
        retryInfo.max = endCounter.current;
        setMaxAttempts(endCounter.current);
        setPendingRetry(retryInfo);

    }

    return (
        <div>
            {!props.edit &&
                <Button onClick={props.toggle} color="primary" variant="contained">+ Custom Retry</Button>
            }
            <Dialog open={props.open} maxWidth="lg">
                {!props.edit &&
                    <DialogTitle id="dialog-title">Create New Custom Retry</DialogTitle>
                }
                {props.edit &&
                    <DialogTitle id="dialog-title">Edit Custom Retry</DialogTitle>
                }
                <DialogContent >
                    <Paper className={classes.paper} elevation={2}>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                                    <Grid item>
                                        <TextField
                                            className={classes.formControl}
                                            margin="dense"
                                            name="custName"
                                            label="Name"
                                            type="text"
                                            value={pendingRetry.name}
                                            onChange={handleSelectRetryName} />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" >
                                            <Grid container direction="row" justify="flex-end">
                                                <Grid item>
                                                    <div onClick={checkAllDispos}>
                                                        <IconButton disabled={!retryByAttempt.minDuration} aria-label="add" >
                                                            <DoneAll color="primary" fontSize="small" />
                                                        </IconButton> <Typography variant="button">Select All</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div onClick={unCheckAllDispos}>
                                                        <IconButton disabled={!retryByAttempt.minDuration} aria-label="add" >
                                                            <NotInterested color="primary" fontSize="small" />
                                                        </IconButton><Typography variant="button">Deselect All</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row">
                                                {props.dispos.map(dispo => (
                                                    <Grid item xs={4}>
                                                        <FormControlLabel key={dispo.code} control={<Checkbox checked={pendingDispos.indexOf(dispo.name) > -1} onChange={checkDispo} name={dispo.name} size="small" color="primary" />} label={dispo.name} />
                                                    </Grid>
                                                ))}
                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            {`Max Defined Attempts: ${maxAttempts}`}
                                        </Grid>
                                        <Grid item xs={12} >
                                            <List dense={true}>
                                                <ListSubheader >Retry Intervals by Attempt</ListSubheader>
                                                <Divider />
                                                {pendingRetry.retriesbyattempt.map(retry => (
                                                    <ListItem >
                                                        <ListItemText primary={
                                                            <Grid container justify="flex-start">
                                                                <Grid item xs={4}>
                                                                    {`Min Duration: ${retry.minDuration}`}
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    {`Start Attempt: ${retry.start}`}
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    {`End Attempt: ${retry.end}`}
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    {retry.priority && <b> {`Priotity:`}</b>}{retry.priority && <CheckIcon color="primary" />}
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                    </ListItem>
                                                ))}
                                                {pendingRetry.retriesbyattempt.length === 0 &&
                                                    <ListItem>
                                                        <ListItemText primary={
                                                            <Grid container justify="center">
                                                                <Grid item>
                                                                    No Retry Intervals Defined
                                                                            </Grid>
                                                            </Grid>
                                                        } />
                                                    </ListItem>
                                                }
                                            </List>
                                            <Divider />
                                        </Grid>
                                        <Grid container direction="row" spacing={2} justify="space-between">
                                            <Grid item xs={6}>
                                                <TextField
                                                    className={classes.formControl}
                                                    margin="dense"
                                                    name="customDuration"
                                                    label="Min Duration"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 10080, min: 1
                                                        }
                                                    }}
                                                    value={retryByAttempt.minDuration}
                                                    onChange={handleSelectDuration} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                {`Start Attempt: ${nextStart}`}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    className={classes.formControl}
                                                    margin="dense"
                                                    name="customEnd"
                                                    label="End Attempt"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 99, min: nextStart
                                                        }
                                                    }}
                                                    value={retryByAttempt.end}
                                                    onChange={handleSelectEnd}
                                                    error={retryByAttempt.end < nextStart} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="customPriority"
                                                            checked={retryByAttempt.priority}
                                                            onChange={handleSelectPriority}
                                                            value="false"
                                                            color="primary" />
                                                    }
                                                    label="Prioritize Retry"
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <IconButton disabled={!retryByAttempt.minDuration || retryByAttempt.end < nextStart} onClick={addRetryByAttempt} aria-label="add" >
                                                    <AddIcon color={!retryByAttempt.minDuration || retryByAttempt.end < nextStart ? "disabled" : "primary"} fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCancel} color="primary">
                        {'Cancel'}
                    </Button>
                    <Button disabled={!isValid} onClick={handleSave} variant="contained" color="primary">
                        {'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
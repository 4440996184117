import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { 
    Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Input, 
    FormHelperText,
    Checkbox,
    ListItemText, 
    Typography,
    CircularProgress,
    Switch,
    FormControlLabel
} from '@material-ui/core';
import * as _ from 'lodash';
import ConfirmDialog from '../components/ConfirmDialog';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

}));

export default function MediaEntity(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [selMedia, setSelMedia] = useState('');
    const [entities, setEntities] = useState([]);
    const [loadingEntities, setLoadingEntities] = useState(true);
    const [selEntity, setSelEntity] = useState('');
    const [skills, setSkills] = useState([]);
    const [loadingSkills, setLoadingSkills] = useState(false);
    const [selSkills, setSelSkills] = useState([]);
    const [activityType, setActivityType] = useState('');
    const [searchSettings, setSearchSettings] = useState(false);
    const [loggingAction, setLoggingAction] = useState('');
    const [showEntity, setShowEntity]= useState(false);
    const [mediaDialog, setMediaDialog] = useState(false);
    const [crmDialog, setCrmDialog] = useState(false);
    const [activityDialog, setActivityDialog] = useState(false);
    const [tempMedia, setTempMedia] = useState('');
    const [tempEntity, setTempEntity] = useState('');
    const [tempActivity, setTempActivity] = useState('');

    useEffect(() => {
        async function getData(){
            setSelMedia(props.selMediaType);
            setActivityType(props.activityType);
            setLoggingAction(props.loggingAction);
            setSelSkills(props.selSkills);
            setSearchSettings(props.searchSettings);
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    useEffect(() => {
        if (props.entities.length > 0){
            const sortedEntities = _.sortBy(props.entities, ['DisplayName.LocalizedLabels[0].Label']);
            setEntities([...sortedEntities]);
            setSelEntity(props.selEntity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.entities])

    useEffect(() => {
        async function stopLoad(){
            setLoadingEntities(false);
        }
        if (entities !== null && entities !== []){
            stopLoad();
        }
    }, [entities]);

    useEffect(() => {
        if(props.selEntity !== undefined){
            setSelEntity(props.selEntity);
        }
    }, [props.selEntity]);

    useEffect(() => {
        async function getData(){
            const initialSkills = props.skills;
            const usedSkills = await props.getUsedSkills();
            const sortedSkills = _.sortBy(initialSkills, ['skillName'])
            if (usedSkills.length > 0){
                for (const skill of usedSkills){
                    _.remove(sortedSkills, ['skillId', skill]);
                    setSkills([...sortedSkills]);
                }
            } else {
                setSkills([...sortedSkills]);
                enqueueSnackbar('Found no used skills. If you have other profiles, please confirm you are not reusing skills.', {
                    variant: 'info',
                    autoHideDuration: 7000
                });
            }
        }
        if (props.skills.length > 0){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.skills]);

    useEffect(() => {
        async function stopLoad(){
            setLoadingSkills(false);
        }
        if (skills !== null && skills !== []){
            stopLoad();
        }
    }, [skills])

    useEffect(() => {
        async function startLoad(){
            setLoadingSkills(true);
        }
        if (selMedia !== null && selMedia !== ''){
            if (activityType !== null && activityType !== ''){
                startLoad();
            }
        }
    }, [selMedia, activityType]);

    useEffect(() => {
        setShowEntity(loggingAction === 'create' || loggingAction === 'pop');
    }, [loggingAction]);

    const handleSelectEntities = (event) => {
        const entity = event.target.value;
        if (props.transactionLoggings.length < 1){
            props.setSelEntity(entity);
            setSelEntity(entity);
        } else if (props.transactionLoggings.length >= 1){
            setTempEntity(entity);
            setCrmDialog(true);
        }
    }

    function handleCrm(){
        setCrmDialog(false);
        props.setTransactionLoggings([]);
        props.setSelEntity(tempEntity);
        setTempEntity({});
    }

    function cancelCrm(){
        setCrmDialog(false);
    }

    const handleSelectMediaType = (event) => {
        const mediaType = event.target.value;
        if (props.transactionLoggings.length < 1){
            props.setSelMediaType(mediaType);
            setSelMedia(mediaType);
            setSelSkills([]);
            props.setSelSkills([]);
        } else if (props.transactionLoggings.length >= 1){
            setTempMedia(mediaType);
            setMediaDialog(true);
        }
    }

    function handleMedia(){
        setMediaDialog(false)
        setSelSkills([]);
        props.setSelSkills([]);
        props.setTransactionLoggings([]);
        props.setSelMediaType(tempMedia);
        setSelMedia(tempMedia);
        setTempMedia({});
    }

    function cancelMedia(){
        setMediaDialog(false);
    }

    const handleActivityType = (event) => {
        const type = event.target.value;
        if (selSkills.length < 1){
            setActivityType(type);
            props.setActivityType(type);
            setSelSkills([]);
            props.setSelSkills([]);
        } else {
            setTempActivity(type);
            setActivityDialog(true);
        }
    }

    function handleActivity(){
        setActivityType(tempActivity);
            props.setActivityType(tempActivity);
            setSelSkills([]);
            props.setSelSkills([]);
            setActivityDialog(false);
    }

    function cancelActivity(){
        setActivityDialog(false);
    }

    const handleSelectedSkills = (event) => {
        const skills = event.target.value;
        setSelSkills(skills);
        props.setSelSkills(skills);
    }

    const handleLoggingAction = (event) => {
        const action = event.target.value;
        setLoggingAction(action);
        props.setLoggingAction(action);
        if (action === 'none'){
            setSelEntity({});
            props.setSelEntity({});
        }
    }

    const updateUseSearch = (event) => {
      setSearchSettings(!searchSettings);
      props.setSearchSettings(!searchSettings);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <Grid container direction="row">
                    <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink id="mediaType">Media Type</InputLabel>
                                <Select
                                    labelId="mediaType"
                                    name="mediaType"
                                    value={selMedia}
                                    displayEmpty
                                    onChange={handleSelectMediaType}
                                    className={classes.selectFields}
                                >
                                    <MenuItem value="chat">Chat</MenuItem>
                                    <MenuItem value="email">Email</MenuItem>
                                    <MenuItem value="phoneCall">Phone Call</MenuItem>
                                    <MenuItem value="social">Social</MenuItem>
                                    <MenuItem value="workItem">Work Item</MenuItem>
                                                                            
                                </Select>
                                <FormHelperText>*When selecting media type select chat</FormHelperText>
                                <FormHelperText>'for chat and sms</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <FormControl>
                                <InputLabel shrink id="mediaType">Activity Type</InputLabel>
                                <Select
                                    labelId="mediaType"
                                    name="mediaType"
                                    value={activityType}
                                    displayEmpty
                                    onChange={handleActivityType}
                                    className={classes.selectFields}
                                >
                                    <MenuItem value="Inbound">Inbound</MenuItem>
                                    <MenuItem value="Outbound">Outbound</MenuItem>                                                                      
                                </Select>
                            </FormControl>
                          </Grid>
                          {activityType === 'Outbound' && <Grid item>
                                <FormControlLabel
                                  label="Use Search Settings"
                                  control={
                                    <Switch
                                    color='primary'
                                    value={searchSettings}
                                    onChange={(e) => updateUseSearch(e)} />
                                  }
                                >
                                </FormControlLabel>
                            </Grid>
                            }
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <InputLabel shrink id="skills">Skills</InputLabel>
                                <Select
                                    multiple
                                    labelId="skills"
                                    name="skillsList"
                                    value={selSkills}
                                    onChange={handleSelectedSkills}
                                    input={<Input />}
                                    displayEmpty
                                    className={classes.selectFields}
                                    renderValue={(selected) => selected.join(', ')}
                                >                                     
                                    {skills.map(data =>(
                                        <MenuItem key={data.skillId} value={JSON.stringify(data.skillId)}>
                                            <Checkbox checked={selSkills.indexOf(JSON.stringify(data.skillId)) > -1} color="primary" />
                                            <ListItemText primary={data.skillName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {loadingSkills && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent='flex-start' spacing={2}>
                      <Grid item>
                          <FormControl>
                              <InputLabel shrink id="loggingAction">Logging Action</InputLabel>
                              <Select
                                  labelId="loggingAction"
                                  name="loggingAction"
                                  value={loggingAction}
                                  displayEmpty
                                  className={classes.selectFields}
                                  onChange={handleLoggingAction}
                              >
                                  <MenuItem value="none">None</MenuItem>
                                  <MenuItem value="create">Create</MenuItem>
                                  <MenuItem value="pop">Create and pop</MenuItem>
                              </Select>
                          </FormControl>
                      </Grid>
                      {showEntity && <Grid item>
                          <FormControl>
                              <InputLabel shrink id="entity">Activity Logging Entity</InputLabel>
                              <Select
                                  labelId="entity"
                                  name="crmEntity"
                                  value={selEntity}
                                  displayEmpty
                                  onChange={handleSelectEntities}
                                  className={classes.selectFields}
                              >
                                  <MenuItem value="">None</MenuItem>
                                  {entities.map(data =>(
                                      <MenuItem key={data.SchemaName} value={data.SchemaName}>{data.DisplayName.LocalizedLabels[0].Label}</MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          {loadingEntities && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Grid>}
                    </Grid>

                    <ConfirmDialog
                    open={mediaDialog}
                    onConfirm={handleMedia}
                    onCancel={cancelMedia}
                    confirmText="Yes"
                    >
                        <Typography>You have transaction loggings associated with this Media Type. Changing it now will reset your loggings.  Do you want to continue?</Typography>
                    </ConfirmDialog>

                    <ConfirmDialog
                    open={crmDialog}
                    onConfirm={handleCrm}
                    onCancel={cancelCrm}
                    confirmText="Yes"
                    >
                        <Typography>You have transaction loggings associated with this CRM Enitity. Changing it now will reset your loggings.  Do you want to continue?</Typography>
                    </ConfirmDialog>

                    <ConfirmDialog
                    open={activityDialog}
                    onConfirm={handleActivity}
                    onCancel={cancelActivity}
                    confirmText="Yes"
                    >
                        <Typography>The skills you have selected are tied to the activity type. Changing this will reset your skills. Do you want to continue?</Typography>
                    </ConfirmDialog>
                </Grid>
            )}
        </UserContext.Consumer>


    );
}
/**
 * This file is a component for E2 UI Customization
 */
 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';

 export default function EmailPark(props)
 {
     const [park, setPark] = useState(false);
 
     /** Set live chat to value being passed through props */
     useEffect(() => {
         setPark(props.value);
     },[props.value]);
     
     /**Handle changes to live chat checkbox */
     const handlePark = () => {
         setPark(!park);
         props.onChange(!park);
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={park}
                         onChange={handlePark}
                         name="park"
                         color="primary"
                         />
                     }
                     label="Park"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Toggles the button that allows the agent to park the email.">
                         <TimerOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
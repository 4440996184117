/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined';

export default function RefreshE2(props)
{
    const [refreshE2, setRefreshE2] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setRefreshE2(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleRefreshE2 = () => {
        setRefreshE2(!refreshE2);
        props.onChange(!refreshE2)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={refreshE2}
                        onChange={handleRefreshE2}
                        name="refreshE2"
                        color="primary"
                        />
                    }
                    label="Refresh E2"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Allow Refresh E2 access">
                        <RestorePageOutlinedIcon color="primary"/>
                    </Tooltip>
                    
                </Grid>
            </Grid>
        </div>
    );
}


import React, { useState, useContext, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import {
    Grid, TextField, makeStyles, FormControl, Tabs, Tab, Select, MenuItem, IconButton,
    Divider, Tooltip, FormControlLabel, Switch, InputLabel, TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, Button, Typography, FormHelperText, CircularProgress, Badge, useTheme, Paper
} from '@material-ui/core';
import { AddCircleOutlineOutlined, DeleteOutlineOutlined, InfoOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { FieldArray, Formik } from 'formik';
import ReactJson from 'react-json-view';
import API, { graphqlOperation } from '@aws-amplify/api';
import { getRestConnection } from '../graphql/queries';
import UserContext from '../context/UserContext';
import clsx from 'clsx';
import { createRestConnection, updateRestConnection } from '../graphql/mutations';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import * as Yup from 'yup';
import { CenterToggleContainer, CenterToggleItem, CenterToggleChild } from 'react-center-toggle';
import status from 'http-status';
import { useHistory, useParams } from 'react-router-dom';
import YupValidations from '../components/YupValidations';


Yup.addMethod(Yup.array, 'containsRequiredFields', function (message, mapper = a => a) {
    return this.test('containsRequiredFields', message, function(list) {
        const toFields = list.map(mapper);
        const requiredMappings = ['firstName', 'lastName', 'phone'];
        let missingFields = []
        for (let requisite of requiredMappings) {
            if (requisite === 'phone') {
                if (!toFields.includes(requisite) && !toFields.includes('cell')) missingFields.push(requisite)
            } else {
                if (!toFields.includes(requisite)) missingFields.push(requisite)
            }
        }
        if (missingFields.length > 0) return this.createError({path: `mapping[0].from`, message: message})
        return true
    });
});

const useStyles = makeStyles(theme => ({
    endpoint: {
        width: '100%'
    },
    shortAuthField: {
        minWidth: '100px'
    },
    longField: {
        minWidth: '300px'
    },
    formError: {
        color: theme.palette.error.main
    },
    tableCell: {
        padding: '0px 24px 4px 16px',
        border: 'none'
    },
    paper: {
        padding: theme.spacing(2),
    }
}));

const methods = {
    POST: "POST",
    GET: "GET"
};

const authTypes = [
    'None',
    'API_Key',
    'Bearer_Token',
    'Basic_Auth',
    'SalesForce'
];

const responseCodeColorIndicators = {
    "1xx": "info",
    "2xx": "success",
    "3xx": "warning",
    "4xx": "error",
    "5xx": "error"
};

function formatTestResponse(response) {
    if (typeof (response) === 'string') {
        try {
            const parsed = JSON.parse(response);
            if (typeof (parsed) === 'string') {
                return {
                    data: parsed
                };
            }

            return parsed;
        } catch (err) {
            return {
                data: response
            };
        }
    }

    return response;
}

export default function RESTConnection() {
    const theme = useTheme();
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedTab, setSelectedTab] = useState(0);
    const userContext = useContext(UserContext);
    const [viewPassword, setViewPassword] = useState(false);
    const [logs, setLogs] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [testing, setTesting] = useState(false);
    const [newLogsBadgeColor, setNewLogsBadgeColor] = useState('primary');
    const [newLogs, setNewLogs] = useState(0);
    const [salesForce, setSalesForce] = useState(false);
    const isNew = useRef(false);
    const contactFields = useMemo(() => {
        return [
            "externalId",
            "firstName",
            "lastName",
            "source",
            "phone",
            "cell",
            "email",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "timeZone",
            "dnc",
            "updatedBy",
            "customFields",
            "expireDt",
            "complianceRequired",
            "preview",
            "outboundCallerId",
            ...userContext.customFields.map((field) => `custom:${field.name}`)
        ];
    }, [userContext]);

    const [connectionObj, setConnectionObj] = useState({
        name: '',
        endpoint: '',
        enabled: true,
        method: methods.GET,
        params: [],
        headers: [],
        auth: {
            type: 'None',
            info: {}
        },
        listPath: '$.contacts',
        mapping: []
    });

    const handleTabChange = useCallback((_, newIndex) => {
        setSelectedTab(newIndex);
        if (newIndex === 5) setNewLogs(0);
    }, []);

    function handleAuthTypeChange(e, formikProps) {
        formikProps.handleChange(e);
        setSalesForce(false);
        switch (e.target.value) {
            case authTypes[1]:
                formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, key: 'x-api-key', value: '', addTo: 'header' });
                break;
            case authTypes[2]:
                formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, token: '' });
                break;
            case authTypes[3]:
                formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, username: '', password: '' });
                break;
            case authTypes[4]:
                formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, username: '', loginUrl: '', issuer: '' });
                setSalesForce(true);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        async function getData() {
            setLoading(true);

            const restSettings = await API.graphql(graphqlOperation(getRestConnection, { id }))
            if (restSettings.data.getRestConnection) {
                restSettings.data.getRestConnection.auth.info = JSON.parse(restSettings.data.getRestConnection.auth.info ?? {});
                setConnectionObj(restSettings.data.getRestConnection);
                if (restSettings.data.getRestConnection.auth.type === authTypes[4]) { 
                    setSalesForce(true);
                }
            }

            setLoading(false);
        }
        if (id !== 'new') {
            if (!isNew.current) {
                getData();
            } else {
                isNew.current = false;
            }
        } else {
            isNew.current = true;
        }
    }, [id])

    async function test(config) {
        setTesting(true);

        const headers = _.cloneDeep(config.headers).reduce((acc, cur) => { acc[cur.key] = cur.value; return acc; }, {});
        let paramsString = '';
        if (config.params.length > 0) {
            paramsString = `?${config.params.map(x => `${encodeURIComponent(x.key)}=${encodeURIComponent(x.value)}`).join('&')}`;
        }

        switch (config.auth.type) {
            case authTypes[1]:
                if (config.auth.info.addTo === 'header') {
                    headers[config.auth.info.key] = config.auth.info.value;
                } else {
                    if (paramsString === '') {
                        paramsString += '?';
                    } else {
                        paramsString += "&";
                    }
                    paramsString += `${encodeURIComponent(config.auth.info.key)}=${encodeURIComponent(config.auth.info.value)}`;
                }
                break;
            case authTypes[2]:
                headers.Authorization = `Bearer ${config.auth.info.token}`;
                break;
            case authTypes[3]:
                headers.Authorization = `Basic ${btoa(`${config.auth.info.username}:${config.auth.info.password}`)}`;
                break;
            case authTypes[4]:
                headers.Authorization = `Bearer`;
            default:
                break;
        }

        let result;
        try {
            const URL = /* `https://wjqomkhk14.execute-api.us-west-2.amazonaws.com/dev/proxy/${encodeURIComponent( */`${config.endpoint}${paramsString}`/* )}` */;
            switch (config.method) {
                case "GET":
                default:
                    result = await axios.get(URL, { headers });
                    break;
                case "POST":
                    result = await axios.post(URL, {}, { headers });
                    break;
            }

            logs.push({
                timestamp: (new Date()).toISOString(),
                responseCode: result.request.status,
                data: formatTestResponse(result.data)
            });
            setNewLogsBadgeColor('primary');
        } catch (err) {
            console.log(err);
            logs.push({
                timestamp: (new Date()).toISOString(),
                responseCode: err.request.status,
                data: formatTestResponse(err.request.response)
            });
            setNewLogsBadgeColor('error');
        }
        setLogs([...logs]);

        setNewLogs(newLogs => newLogs + 1 * (selectedTab !== 5));

        setTesting(false);
    }

    return (
        <Formik
            validationSchema={Yup.object({
                name: Yup.string().required("A name is required").matches(YupValidations.atLeastOneLettersNumbersSpacesRegex),
                endpoint: Yup.string().required("An endpoint is required").url('The endpoint must be a valid URL'),
                method: Yup.string().required(),
                headers: Yup.array().of(Yup.object({
                    key: Yup.string().required("This field is required")
                })),
                mapping: Yup.array()
                .of(Yup.object({
                    from: Yup.string().required("This field is required"),
                    to: Yup.string().required("This field is required")
                }))
                .containsRequiredFields('Mappings must contains First Name, Last Name, and either phone or cell', a => a.to)
                .test(function (val) {
                    const fromMap = {};
                    const toMap = {};
                    for (let i = val.length - 1; i >= 0; i--) {
                        if (!(val[i].from in fromMap)) {
                            fromMap[val[i].from] = []
                        }
                        fromMap[val[i].from].push(i)
                        if (!(val[i].to in toMap)) {
                            toMap[val[i].to] = []
                        }
                        toMap[val[i].to].push(i)
                    }
                    for (const indexGroup of Object.values(fromMap).filter((group) => group.length >= 2)) {
                        for (let index of indexGroup) {
                            return this.createError({ path: `mapping[${index}].from`, message: 'Each from field must be unique' })
                        }
                    }
                    for (const indexGroup of Object.values(toMap).filter((group) => group.length >= 2)) {
                        for (let index of indexGroup) {
                            return this.createError({ path: `mapping[${index}].to`, message: 'Each to field must be unique' })
                        }
                    }
                    return true;
                }),
                listPath: Yup.string().required('The path to the list of contacts is required')

            })
            }
            initialValues={connectionObj}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async values => {
                setSaving(true);

                const clone = _.cloneDeep(values);

                delete clone.createdAt;
                delete clone.updatedAt;

                clone.tenant = userContext.tenant;

                clone.auth.info = JSON.stringify(clone.auth.info ?? {});
                try {
                    if (id === "new") {
                        const result = await API.graphql(graphqlOperation(createRestConnection, { input: clone }));
                        setConnectionObj(result.data.createRestConnection);
                        history.push(`/config/rest-connections/${result.data.createRestConnection.id}`);
                    } else {
                        await API.graphql(graphqlOperation(updateRestConnection, { input: clone }));
                    }
                    enqueueSnackbar("Connection saved successfully", { variant: 'success' });
                } catch (err) {
                    console.log(err);
                    enqueueSnackbar("Connection could not be saved", { variant: 'error' });
                }

                setSaving(false);
            }}
        >
            {formikProps => {
                return (
                    <>
                        {loading &&
                            <CircularProgress variant='indeterminate' color='primary' />
                        }
                        {!loading &&
                            <form onSubmit={formikProps.handleSubmit}>
                                <Grid container direction='column' spacing={2}>
                                    <Grid item>
                                        <Grid container justifyContent='space-between' alignItems='center' alignContent='center'>
                                            <Grid item>
                                                <Typography variant='h4'>{id === 'new' ? 'New REST Connection' : 'Edit REST Connection'}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Button color='primary' variant='outlined' onClick={() => history.push('/config/rest-connections')}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <CenterToggleContainer toggled={saving}>
                                                            <CenterToggleChild>
                                                                <Button color='primary' variant='contained' type='submit' disabled={saving}>
                                                                    Save
                                                                </Button>
                                                            </CenterToggleChild>
                                                            <CenterToggleItem>
                                                                <CircularProgress variant='indeterminate' color='primary' />
                                                            </CenterToggleItem>
                                                        </CenterToggleContainer>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Paper className={classes.paper} elevation={4}>
                                            <Grid container spacing={2} direction='column'>
                                                <Grid item>
                                                    <FormControlLabel
                                                        label='Enabled'
                                                        control={<Switch
                                                            checked={formikProps.values.enabled}
                                                            onChange={formikProps.handleChange}
                                                            color='primary'
                                                            name='enabled'
                                                        />}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant='standard'
                                                        color='primary'
                                                        name='name'
                                                        label='Name'
                                                        style={{ minWidth: '350px' }}
                                                        value={formikProps.values.name}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors?.name && formikProps.touched?.name}
                                                        helperText={formikProps.touched?.name && formikProps.errors?.name} />

                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction='row' alignItems='center'>
                                                        <Grid item>
                                                            <FormControl>
                                                                <Select
                                                                    variant='outlined'
                                                                    style={{ borderRadius: '4px 0px 0px 4px', width: '100px' }}
                                                                    SelectDisplayProps={{
                                                                        style: {
                                                                            borderRadius: '4px 0px 0px 4px'
                                                                        }
                                                                    }}
                                                                    value={formikProps.values.method}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    name='method'
                                                                >
                                                                    {Object.keys(methods).map((method, index) =>
                                                                        <MenuItem key={index} value={method}>{method}</MenuItem>)}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item style={{ flexGrow: 1 }}>
                                                            <TextField
                                                                variant='outlined'
                                                                className={classes.endpoint}
                                                                label='Endpoint'
                                                                value={formikProps.values.endpoint}
                                                                name='endpoint'
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors?.endpoint && formikProps.touched?.endpoint}
                                                                helperText={formikProps.touched?.endpoint && formikProps.errors?.endpoint}
                                                                InputProps={{
                                                                    style: {
                                                                        borderTopLeftRadius: '0px',
                                                                        borderBottomLeftRadius: '0px'
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ marginLeft: '10px' }}>
                                                            <CenterToggleContainer toggled={testing}>
                                                                <CenterToggleChild>
                                                                    <Tooltip disableHoverListener={salesForce === false} title="Salesforce testing not available yet">
                                                                        <span>
                                                                            <Button color='primary' variant='contained' onClick={() => test(formikProps.values)} disabled={testing || salesForce} >
                                                                                Test
                                                                            </Button>
                                                                        </span>
                                                                    </Tooltip>
                                                                </CenterToggleChild>
                                                                <CenterToggleItem>
                                                                    <CircularProgress variant='indeterminate' color='primary' />
                                                                </CenterToggleItem>
                                                            </CenterToggleContainer>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Tabs value={selectedTab} onChange={handleTabChange}>
                                                        <Tab value={0} label='Authorization' />
                                                        <Tab value={1} label='Headers' />
                                                        <Tab value={2} label='Params' />
                                                        <Tab value={3} label='Settings' className={clsx((formikProps.errors?.response && formikProps.touched?.response) && classes.formError)} />
                                                        <Tab value={5} label={<Badge color={newLogsBadgeColor} badgeContent={newLogs}>Logs</Badge>} />
                                                    </Tabs>
                                                    <Divider />
                                                </Grid>
                                                {selectedTab === 0 &&
                                                    <Grid item>
                                                        <Grid container spacing={4}>
                                                            <Grid item>
                                                                <FormControl variant='outlined' className={classes.shortAuthField}>
                                                                    <InputLabel id='auth-type-label'>Type</InputLabel>
                                                                    <Select
                                                                        defaultValue='None'
                                                                        labelId='auth-type-label'
                                                                        label='Type'
                                                                        onChange={e => handleAuthTypeChange(e, formikProps)}
                                                                        name='auth.type'
                                                                        value={formikProps.values.auth.type}
                                                                    >
                                                                        {authTypes.map((type, index) =>
                                                                            <MenuItem key={index} value={type}>{type.replace(/_/g, ' ')}</MenuItem>)}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            {formikProps.values.auth.type !== authTypes[0] &&
                                                                <Grid item>
                                                                    <Divider orientation='vertical' />
                                                                </Grid>}
                                                            {formikProps.values.auth.type === authTypes[1] &&
                                                                <Grid item>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                label='Key'
                                                                                value={formikProps.values.auth.info.key ?? ''}
                                                                                className={classes.longField}
                                                                                onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, key: e.target.value })}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextField
                                                                                label='Value'
                                                                                value={formikProps.values.auth.info.value ?? ''}
                                                                                className={classes.longField}
                                                                                onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, value: e.target.value })}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <FormControl className={classes.longField}>
                                                                                <InputLabel id='add-to-label'>Add to</InputLabel>
                                                                                <Select
                                                                                    defaultValue='header'
                                                                                    labelId='add-to-label'
                                                                                    label='Add to'
                                                                                    onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, addTo: e.target.value })}
                                                                                    value={formikProps.values.auth.info.addTo ?? 'header'}
                                                                                >
                                                                                    <MenuItem value='header'>Header</MenuItem>
                                                                                    <MenuItem value='queryParams'>Query Params</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>}
                                                            {formikProps.values.auth.type === authTypes[2] &&
                                                                <Grid item style={{ flexGrow: 1 }}>
                                                                    <TextField
                                                                        label='Token'
                                                                        style={{ width: '100%' }}
                                                                        onChange={e => formikProps.setFieldValue('auth.info', { token: e.target.value })}
                                                                        value={formikProps.values.auth.info.token ?? ''}
                                                                    />
                                                                </Grid>}
                                                            {formikProps.values.auth.type === authTypes[3] &&
                                                                <Grid item>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                label='Username'
                                                                                className={classes.longField}
                                                                                onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, username: e.target.value })}
                                                                                InputProps={{ inputProps: { autoComplete: "new-password" } }}
                                                                                value={formikProps.values.auth.info.username ?? ''}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container alignItems='center' alignContent='center'>
                                                                                <Grid item>
                                                                                    <TextField
                                                                                        label='Password'
                                                                                        type={viewPassword ? 'text' : 'password'}
                                                                                        className={classes.longField} onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, password: e.target.value })}
                                                                                        InputProps={{ inputProps: { autoComplete: "new-password" } }}
                                                                                        value={formikProps.values.auth.info.password ?? ''}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Tooltip title={viewPassword ? 'Hide Password' : 'View Password'}>
                                                                                        <IconButton onClick={() => setViewPassword(!viewPassword)}>
                                                                                            {viewPassword &&
                                                                                                <VisibilityOff color='primary' />}
                                                                                            {!viewPassword &&
                                                                                                <Visibility color='primary' />}
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>}
                                                                {formikProps.values.auth.type === authTypes[4] &&
                                                                <Grid item>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                label='Login Url'
                                                                                value={formikProps.values.auth.info.loginUrl ?? ''}
                                                                                className={classes.longField}
                                                                                onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, loginUrl: e.target.value })}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextField
                                                                                label='User Name'
                                                                                value={formikProps.values.auth.info.username ?? ''}
                                                                                className={classes.longField}
                                                                                onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, username: e.target.value })}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextField
                                                                                label='Issuer'
                                                                                value={formikProps.values.auth.info.issuer ?? ''}
                                                                                className={classes.longField}
                                                                                onChange={e => formikProps.setFieldValue('auth.info', { ...formikProps.values.auth.info, issuer: e.target.value })}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>}    
                                                        </Grid>
                                                    </Grid>}
                                                {selectedTab === 1 &&
                                                    <Grid item>
                                                        <Grid container spacing={2} direction='column'>
                                                            <FieldArray
                                                                validateOnChange={false}
                                                                name='headers'
                                                                render={helpers =>
                                                                    <>
                                                                        <Grid item>
                                                                            <Grid container spacing={2} alignItems='center'>
                                                                                <Grid item>
                                                                                    <Typography variant="h6">Headers</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Tooltip title='Add New Header'>
                                                                                        <IconButton onClick={() => helpers.push({ key: '', value: '', description: '' })} size='small'>
                                                                                            <AddCircleOutlineOutlined color='primary' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TableContainer>
                                                                                <Table size='small'>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>Key</TableCell>
                                                                                            <TableCell>Value</TableCell>
                                                                                            <TableCell>Description</TableCell>
                                                                                            <TableCell>Actions</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {formikProps?.values?.headers?.map((header, index) =>
                                                                                            <KeyValueDescriptionField
                                                                                                errors={formikProps.errors?.headers?.[index]}
                                                                                                touched={formikProps.touched?.headers?.[index]}
                                                                                                keyText={header.key}
                                                                                                value={header.value}
                                                                                                description={header.description}
                                                                                                handleChange={formikProps.handleChange}
                                                                                                index={index}
                                                                                                helpers={helpers}
                                                                                                handleBlur={formikProps.handleBlur}
                                                                                                key={index}
                                                                                                property='headers'
                                                                                                classes={classes}
                                                                                            />
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </Grid>
                                                                    </>}
                                                            />
                                                        </Grid>
                                                    </Grid>}
                                                {selectedTab === 2 &&
                                                    <Grid item>
                                                        <Grid container spacing={2} direction='column'>
                                                            <FieldArray
                                                                validateOnChange={false}
                                                                name='params'
                                                                render={helpers =>
                                                                    <>
                                                                        <Grid item>
                                                                            <Grid container spacing={2} alignItems='center'>
                                                                                <Grid item>
                                                                                    <Typography variant="h6">Query Params</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Tooltip title='Add New Query Param'>
                                                                                        <IconButton onClick={() => helpers.push({ key: '', value: '', description: '' })} size='small'>
                                                                                            <AddCircleOutlineOutlined color='primary' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TableContainer>
                                                                                <Table size='small'>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>Key</TableCell>
                                                                                            <TableCell>Value</TableCell>
                                                                                            <TableCell>Description</TableCell>
                                                                                            <TableCell>Actions</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {formikProps?.values?.params?.map((param, index) =>
                                                                                            <KeyValueDescriptionField
                                                                                                errors={formikProps.errors?.params?.[index]}
                                                                                                touched={formikProps.touched?.params?.[index]}
                                                                                                keyText={param.key}
                                                                                                value={param.value}
                                                                                                description={param.description}
                                                                                                handleChange={formikProps.handleChange}
                                                                                                index={index}
                                                                                                helpers={helpers}
                                                                                                handleBlur={formikProps.handleBlur}
                                                                                                key={index}
                                                                                                property='params'
                                                                                                classes={classes}
                                                                                            />
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </Grid>
                                                                    </>}
                                                            />
                                                        </Grid>
                                                    </Grid>}
                                                {selectedTab === 3 &&
                                                    <Grid item>
                                                        <FieldArray name='mapping' render={helpers => {
                                                            return (
                                                                <Grid container direction='column' spacing={2}>
                                                                    <Grid item>
                                                                        <TextField
                                                                            variant='standard'
                                                                            color='primary'
                                                                            name='listPath'
                                                                            label='List Path'
                                                                            className={classes.longField}
                                                                            value={formikProps.values.listPath}
                                                                            onChange={formikProps.handleChange}
                                                                            onBlur={formikProps.handleBlur}
                                                                            error={formikProps.errors?.listPath && formikProps.touched?.listPath}
                                                                            helperText={formikProps.touched?.listPath && formikProps.errors?.listPath}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container spacing={2} alignItems='center'>
                                                                            <Grid item>
                                                                                <Typography variant="h6">Mappings</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Tooltip title='Add New Mapping'>
                                                                                    <IconButton onClick={() => helpers.push({ from: '', to: '' })} size='small'>
                                                                                        <AddCircleOutlineOutlined color='primary' />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TableContainer>
                                                                            <Table size='small'>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>From Field</TableCell>
                                                                                        <TableCell>To Field</TableCell>
                                                                                        <TableCell>Actions</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {formikProps?.values?.mapping?.map((mapping, index) =>
                                                                                        <MappingRow
                                                                                            errors={formikProps.errors?.mapping?.[index]}
                                                                                            touched={formikProps.touched?.mapping?.[index]}
                                                                                            from={mapping.from}
                                                                                            to={mapping.to}
                                                                                            handleChange={formikProps.handleChange}
                                                                                            classes={classes}
                                                                                            index={index}
                                                                                            contactFields={contactFields}
                                                                                            helpers={helpers}
                                                                                            handleBlur={formikProps.handleBlur}
                                                                                            key={index}
                                                                                        />
                                                                                    )}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        }} />
                                                    </Grid>}

                                                {selectedTab === 5 &&
                                                    <Grid item>
                                                        {logs.length === 0 &&
                                                            <Typography variant='h6'>No data to display</Typography>}
                                                        {logs.length > 0 &&
                                                            <>
                                                                <Grid container direction='column' spacing={2}>
                                                                    <Grid item>
                                                                        <Grid container justifyContent='space-between'>
                                                                            <Grid item>
                                                                                <Grid container spacing={2} alignItems='center'>
                                                                                    <Grid item>
                                                                                        <InfoOutlined color='primary' />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Typography variant='body1'>These logs are only for localized testing. They do not get stored anywhere once you leave this page.</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Button style={{ marginTop: '16px' }} variant='contained' color='primary' onClick={() => setLogs([])}>Clear</Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item style={{ overflowY: 'auto', maxHeight: '600px', marginBottom: '15px' }}>
                                                                        <TableContainer>
                                                                            <Table>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>Timestamp</TableCell>
                                                                                        <TableCell>Code</TableCell>
                                                                                        <TableCell>Response/Error</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {logs.map((log, index) =>
                                                                                        <Log key={index} log={log} theme={theme} />)}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </Grid>
                                                                </Grid>
                                                            </>}
                                                    </Grid>}
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </form>
                        }
                    </>
                )
            }}
        </Formik>
    )
}

const KeyValueDescriptionField = memo(function KeyValueDescriptionField(props) {
    const { errors, touched, property, index, handleChange, handleBlur, helpers, classes } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const removeIndex = useCallback(() => helpers.remove(index), []);

    return (
        <TableRow>
            {[
                { key: 'key', valueKey: 'keyText' },
                { key: 'value', valueKey: 'value' },
                { key: 'description', valueKey: 'description' }].map(type =>
                    <TableCell className={classes.tableCell}>
                        <TextField
                            variant='standard'
                            color="primary"
                            style={{ width: '100%' }}
                            size='small'
                            InputProps={{ inputProps: { style: { padding: '5px' } } }}
                            value={props[type.valueKey]}
                            margin='dense'
                            name={`${property}[${index}].${type.key}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors?.[type.key] && touched?.[type.key]}
                            helperText={touched?.[type.key] && errors?.[type.key]} />
                    </TableCell>)}

            <TableCell className={classes.tableCell}>
                <Tooltip title='Delete row'>
                    <IconButton onClick={removeIndex} size='small'>
                        <DeleteOutlineOutlined color='primary' />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}, (previousProps, newProps) => {
    return previousProps.keyText === newProps.keyText
        && previousProps.value === newProps.value
        && previousProps.description === newProps.description
        && previousProps.index === newProps.index
        && previousProps.errors?.key === newProps.errors?.key
        && previousProps.errors?.value === newProps.errors?.value
        && previousProps.errors?.description === newProps.errors?.description
        && previousProps.touched?.key === newProps.touched?.key
        && previousProps.touched?.value === newProps.touched?.value
        && previousProps.touched?.description === newProps.touched?.description;
});

const Log = memo(function Log(props) {
    const { log, theme } = props;
    const clone = {};

    for (const key in log.data) {
        if (typeof (log.data[key]) !== 'function')
            clone[key] = log.data[key];
    }
    log.data = clone;

    return (
        <TableRow style={{ verticalAlign: 'baseline' }}>
            <TableCell style={{ width: '20%' }}>{(new Date(log.timestamp)).toLocaleString()}</TableCell>
            <TableCell style={{ width: '20%', fontWeight: 'bold', color: theme.palette[responseCodeColorIndicators?.[status?.[`${log.responseCode}_CLASS`]] ?? 'error'].main }}>{`${log.responseCode}: ${status[log.responseCode]}`}</TableCell>
            <TableCell style={{ width: '60%' }}><ReactJson id="json-pretty2" src={log.data} collapsed={true} theme="monokai" style={{ overflowWrap: 'anywhere' }} /></TableCell>
        </TableRow>
    )
})

const MappingRow = memo(function MappingRow(props) {
    const { errors, touched, from, to, handleChange, classes, index, contactFields, helpers, handleBlur } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const removeIndex = useCallback(() => helpers.remove(index), []);

    return (
        <TableRow>
            <TableCell style={{ width: '50%' }} className={classes.tableCell}>
                <TextField
                    variant='standard'
                    color="primary"
                    style={{ width: '100%' }}
                    size='small'
                    InputProps={{ inputProps: { style: { padding: '5px' } } }}
                    value={from}
                    margin='dense'
                    name={`mapping[${index}].from`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.from && touched?.from}
                    helperText={touched?.from && errors?.from} />
            </TableCell>
            <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                <FormControl
                    margin='dense'
                    color='primary'
                    style={{ width: '100%' }}
                    size='small'
                    error={errors?.to && touched?.to}>

                    <Select SelectDisplayProps={{ style: { padding: '5px' } }} variant='standard' value={to} name={`mapping[${index}].to`} onChange={handleChange} onBlur={handleBlur}>
                        {contactFields.map((field, index) =>
                            <MenuItem value={field} key={index} >{field} </MenuItem>)}
                    </Select>
                    {errors?.to && touched?.to &&
                        <FormHelperText error>{errors?.to}</FormHelperText>}
                </FormControl>
            </TableCell>
            <TableCell style={{ width: '10%' }} className={classes.tableCell}>
                <Tooltip title='Delete row'>
                    <IconButton onClick={removeIndex} size='small'>
                        <DeleteOutlineOutlined color='primary' />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}, (oldProps, newProps) => {
    return oldProps.errors?.from === newProps.errors?.from
        && oldProps.errors?.to === newProps.errors?.to
        && oldProps.touched?.from === newProps.touched?.from
        && oldProps.touched?.to === newProps.touched?.to
        && oldProps.from === newProps.from
        && oldProps.to === newProps.to
        && oldProps.index === newProps.index;
});
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import MaterialTable from 'material-table';
import MUIRichTextEditor from 'mui-rte';
import * as _ from 'lodash';
import * as moment from 'moment';

const styles = {
    displayNone: {
        display: 'none'
    },
    detailPanel: {
        paddingLeft: '20px',
        paddingTop: '5px',
        paddingRight: '20px',
        paddingBottom: '5px'
    },
    activityList: {
        listStyle: 'none'
    }
};

/**
 * The Activity log display table. It depends on being wrapped by the ListHOC component.
 * @component
 */
class ActivityLog extends React.Component {
    constructor(props) {
        super(props);
        this.classes = props.classes;
        this.state = {
            data: []
        }
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.data.length !== prevProps.data.length) {
            const logList = _.sortBy(this.props.data, ['timestampe']);
            this.setState({ data: logList });
        }
    }

    render() {
        return (
            <div>
                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                    <MaterialTable
                        columns={[
                            { title: 'Timestamp', field: 'timestamp', defaultSort: 'desc', render: rowData => {
                                return moment(rowData.timestamp).format('LLL')
                            } },
                            { title: 'Type', field: 'type' },
                            { title: 'Disposition', field: 'disposition' },
                            { title: 'Title', field: 'title' },
                            { title: 'Agent', field: 'agent' }
                        ]}
                        data={this.state.data}
                        title="Activity"
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 5
                        }}
                        detailPanel={[
                            rowData => ({
                                // disabled: rowData.type !== 'Note',
                                icon: () => <ExpandMore />,
                                openIcon: ExpandLess,
                                render: () => {
                                const metaData = JSON.parse(rowData.metaData);
                                return <Grid className={this.classes.detailPanel}>
                                    <ul className={this.classes.activityList}>
                                    {rowData.type === 'Dial' && <React.Fragment>
                                        <li><strong>Disposition Description</strong>: {metaData.DispositionDescription}</li>
                                        <li><strong>Disposition Classification Name</strong>: {metaData.DispositionClassificationName}</li>
                                        <li><strong>Campaign Id</strong>: {metaData.AdditionalInfo.campaignId}</li>
                                        <li><strong>Segment Id</strong>: {metaData.AdditionalInfo.segmentId}</li>
                                        {metaData.completedReason && <li><strong>Completed Reason</strong>: {metaData.completedReason}</li>}
                                        </React.Fragment>
                                    }
                                    {rowData.type === 'Note' && <li><MUIRichTextEditor readOnly={true} value={rowData.body} controls={[]} /></li> }
                                    {rowData.type === 'System' && <li><Typography variant="body2">{rowData.body}</Typography></li>}
                                    </ul>
                                    </Grid>
                                }
                            })
                        ]}
                    />
                </Grid>
            </div>
        )
    }
}

ActivityLog.propTypes = {
    /**
     * The list of data to display in the material table.
     */
    data: PropTypes.array.isRequired
}

export default withStyles(styles)(ActivityLog);
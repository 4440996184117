/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 
 export default function E2IconDisplay(props)
 {
     const [value, setValue] = useState(false);
 
     /** Set activity panel to value being passed through props */
     useEffect(() => {
         setValue(props.value);
     },[props.value]);
     
     /**Handle changes to the Set Phone Number checkbox */
     const handleValueChange = () => {
         setValue(!value);
         props.onChange(!value);
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={value}
                         onChange={handleValueChange}
                         name={props.name}
                         color="primary"
                         />
                     }
                     label={props.label}
                     />
                 </Grid>
                 <Grid item>
                     {props.icon && <Tooltip title={props.title}>
                         {props.icon}
                     </Tooltip>}
                     
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 

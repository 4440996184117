import { Chip, Divider, Grid, Typography, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PricingInfo from './PricingInfoType';

export default function PreviewVendor(props) {
    const {vendorInfo} = props;
    const [loading, setLoading] = useState(false);
    const [pricingInfoDisplay, setPricingInfoDisplay] = useState({});
    const [selectedPricingType, setSelectedPricingType] = useState("");
    const [existsFeeInfo, setExistsFeeInfo] = useState(false);
    const [appURL, setAppURL] = useState("");

    useEffect(() => {
        async function getPricingInfo() {
            setLoading(true);

            await axios.get(`${process.env.REACT_APP_PP_URL}/cms-onboard/get-fees/` + vendorInfo.id, {
                headers: {
                    'x-api-key': process.env.REACT_APP_PP_API_KEY
                }
            })
                .then(response => {
                    // If we get a 2xx, then proceed to parse the pricing info.
                    const data = response.data.items[0];

                    const pricingInfo = new PricingInfo();
    
                    setSelectedPricingType(data.type);

                    pricingInfo.selectedPricingType = data.type;

                    pricingInfo.ParseFieldJsonString(data.vmdPricing, "vmdPricing");
                    pricingInfo.ParseFieldJsonString(data.amexPricing, "amexPricing");
                    pricingInfo.ParseFieldJsonString(data.fees, "fees");

                    setPricingInfoDisplay(pricingInfo.PrettifyFields());
    
                    setExistsFeeInfo(true);})
                .catch(() => {
                    // If we get a 404, then there is no pricing info saved
                    setExistsFeeInfo(false);
                }
            );

            setLoading(false);
        }

        if(vendorInfo.cmsInfo.cmsMerchHash) {
            const rawURL = `https://onlineapp.cmsonline.com/par/658610680cac217db148ddb144efcf3b1115502b296342c314f1363db9192c44/app/${vendorInfo.cmsInfo.cmsMerchHash}`;
            setAppURL(rawURL);
        }

        getPricingInfo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loading && <Typography>Please wait...</Typography>}
            {!loading && 
            <Grid container direction='column' spacing={2} justify='center'>
                <Grid item>
                    <Grid container direction='row' spacing={2} justify='space-around'>
                        <Grid item>
                            <Typography variant='h6' color='primary'>Name</Typography>
                            <Typography variant='body1'>{vendorInfo.name}</Typography>
                        </Grid>
                        <Grid item>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid item>
                            <Typography variant='h6' color='primary'>ID</Typography>
                            <Typography variant='body1'>{vendorInfo.id}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Grid container direction='column' spacing={0}>
                        <Grid item>
                            <Typography variant='h6' color='primary'>Progress</Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked color="primary"/>} label="Sign Up" labelPlacement="end"/>
                        </Grid>
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={vendorInfo.status === "ApplicationUpdated" || vendorInfo.status === "OnBoardComplete" || vendorInfo.status === "Approved"} color="primary"/>} label="Application is up to date" labelPlacement="end"/>
                        </Grid>
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={vendorInfo.status === "OnBoardComplete" || vendorInfo.status === "Approved"} color="primary"/>} label="Onboarding Complete" labelPlacement="end"/>
                        </Grid>
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={vendorInfo.status === "Approved"} color="primary"/>} label="Final Approval" labelPlacement="end"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Grid container direction='column' spacing={2} justify='space-around'>
                        {vendorInfo.cmsInfo.cmsMerchId && 
                        <Grid item>
                            <Typography variant='body1' color='primary'>CMS Merchant ID</Typography>
                            <Typography variant='body2'>{vendorInfo.cmsInfo.cmsMerchId}</Typography>
                        </Grid>}
                        {vendorInfo.cmsInfo.cmsMerchHash && 
                        <Grid item>
                            <Typography variant='body1' color='primary'>CMS Merchant Hash</Typography>
                            <Typography variant='body2'>{vendorInfo.cmsInfo.cmsMerchHash}</Typography>
                        </Grid>}
                        {vendorInfo.cmsInfo.cmsAppToken &&
                        <Grid item>
                            <Typography variant='body1' color='primary'>CMS App Token</Typography>
                            <Typography variant='body2'>{vendorInfo.cmsInfo.cmsAppToken}</Typography>
                        </Grid>}
                        {vendorInfo.cmsInfo.nexioCredentials && 
                        <Grid item>
                            <Typography variant='body1' color='primary'>Nexio Token</Typography>
                            <Typography variant='body2'>{vendorInfo.cmsInfo.nexioCredentials}</Typography>
                        </Grid>}
                    </Grid>
                </Grid>
                {(vendorInfo.cmsInfo.cmsMerchId || vendorInfo.cmsInfo.cmsMerchHash || vendorInfo.cmsInfo.cmsAppToken || vendorInfo.cmsInfo.nexioCredentials) &&
                <Grid item>
                    <Divider />
                </Grid>}
                {existsFeeInfo && 
                <React.Fragment>
                    <Grid item>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography variant='h6' color='primary'>Pricing Info</Typography>
                            </Grid>
                            <Grid item container direction='row' spacing={1} alignItems='center' alignContent='center'>
                                <Grid item>
                                    <Typography variant='body2'>Pricing Type: </Typography>
                                </Grid>
                                <Grid item>
                                    <Chip color='primary' label={selectedPricingType} size='small' />
                                </Grid>
                            </Grid>
                            <Grid item container direction='row' spacing={4}>
                                {pricingInfoDisplay.map((provider, providerIndex) => 
                                <Grid item key={providerIndex}>
                                    <Grid container direction='column' spacing={1}>
                                        <Grid item>
                                            <Typography variant='body1' color='primary'>{provider.displayName}</Typography>
                                        </Grid>
                                        {provider.fields.map((field, fieldIndex) => 
                                        <Grid item key={fieldIndex}>
                                            <Typography variant='body2'>{`${field.displayName}: ${field.value}`}</Typography>
                                        </Grid>)}
                                    </Grid>
                                </Grid>)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                </React.Fragment>}
                {vendorInfo.cmsInfo.cmsMerchHash &&
                <Grid item>
                    <Button variant="contained" color="primary" href={appURL} target="_blank">Monitor Application</Button>
                </Grid>}
            </Grid>}
        </div>
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const ValueSelector = ({ className, handleOnChange, options, title, value }) => (
  <Grid item>
    <FormControl>
      <InputLabel id="value-selector-label" className={className}>{title}</InputLabel>
      <Select 
        labelId="value-selector-label" 
        labelWidth={0} 
        value={value} 
        onChange={(e) => handleOnChange(e.target.value)} >
        {options.map((v) => (
          <MenuItem key={v.name} value={v.name}> {v.label}</MenuItem>
        ))}

      </Select>
    </FormControl>
  </Grid>
);

ValueSelector.displayName = 'ValueSelector';

ValueSelector.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  handleOnChange: PropTypes.func,
  title: PropTypes.string
};

export default ValueSelector;

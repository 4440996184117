import { Formik } from "formik";
import React from "react";
import { makeStyles, Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Button, CircularProgress } from '@material-ui/core';
import states from 'states-us';
import * as yup from 'yup';
import YupValidations from "./YupValidations";
import { CenterToggleContainer, CenterToggleItem, CenterToggleChild } from 'react-center-toggle';

const useStyles = makeStyles({
    longField: {
        minWidth: '350px'
    },
    city: {
        width: '200px'
    },
    shortField: {
        width: '75px'
    }
})

export default function ScreenpopManualCardEntryForm(props) {
    const { contact, loading, handleManualEntrySubmit } = props;
    const classes = useStyles();
    return (
        <Formik
            initialValues={{
                name: `${contact.firstName} ${contact.lastName}`,
                address1: contact.address1,
                address2: contact.address2,
                city: contact.city,
                state: contact.state,
                zip: contact.zip
            }}
            validationSchema={yup.object({
                name: yup.string().required('A cardholder name is required').matches(YupValidations.atLeastOneLettersNumbersSpacesRegex),
                address1: yup.string().required('A street address is required').matches(YupValidations.atLeastOneLettersNumbersSpacesRegex),
                address2: yup.string().nullable().matches(YupValidations.atLeastOneLettersNumbersSpacesRegex),
                city: yup.string().required('A city is required').matches(YupValidations.atLeastOneLettersNumbersSpacesRegex),
                state: yup.string().required("A state is required"),
                zip: yup.string().required("A zip is required").matches(/^\d{5}$/, "Please specify a valid zip code")
            })}
            onSubmit={async values => {
                await handleManualEntrySubmit(values);
            }}
        >
            {formikProps => (
                <>
                    <Grid item>
                        <Typography variant='h6'>Manual Card Entry</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={1} alignItems='center'>
                            <Grid item>
                                <TextField
                                    label="Cardholder Name"
                                    variant="standard"
                                    color="primary"
                                    className={classes.longField}
                                    name='name'
                                    value={formikProps.values.name}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.errors.name && formikProps.touched.name}
                                    helperText={formikProps.touched.name && formikProps.errors.name}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Address 1"
                                    variant="standard"
                                    color="primary"
                                    className={classes.longField}
                                    name='address1'
                                    value={formikProps.values.address1}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.errors.address1 && formikProps.touched.address1}
                                    helperText={formikProps.touched.address1 && formikProps.errors.address1}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Address 2"
                                    variant="standard"
                                    color="primary"
                                    className={classes.longField}
                                    name='address2'
                                    value={formikProps.values.address2}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.errors.address2 && formikProps.touched.address2}
                                    helperText={formikProps.touched.address2 && formikProps.errors.address2}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <TextField
                                            label="City"
                                            variant="standard"
                                            color="primary"
                                            className={classes.city}
                                            name='city'
                                            value={formikProps.values.city}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            error={formikProps.errors.city && formikProps.touched.city}
                                            helperText={formikProps.touched.city && formikProps.errors.city}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControl className={classes.shortField} error={formikProps.errors.state && formikProps.touched.state}>
                                            <InputLabel>State</InputLabel>
                                            <Select name='state' value={formikProps.values.state} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur}>
                                                {states.map(x =>
                                                    <MenuItem key={x.abbreviation} value={x.abbreviation}>{x.abbreviation}</MenuItem>
                                                )}
                                            </Select>
                                            {formikProps.touched.city && formikProps.errors.city &&
                                                <FormHelperText error>{formikProps.errors.city}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="ZIP"
                                            variant="standard"
                                            color="primary"
                                            className={classes.shortField}
                                            name='zip'
                                            value={formikProps.values.zip}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            error={formikProps.errors.zip && formikProps.touched.zip}
                                            helperText={formikProps.touched.zip && formikProps.errors.zip}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CenterToggleContainer  toggled={loading}>
                            <CenterToggleChild>
                                <Button variant='contained' color='primary' onClick={formikProps.handleSubmit}>Submit</Button>
                            </CenterToggleChild>
                            <CenterToggleItem>
                                <CircularProgress variant='indeterminate' color='primary' />
                            </CenterToggleItem>
                        </CenterToggleContainer>
                    </Grid>
                </>
            )}
        </Formik>
    );
}
import React from "react";

import {
    FormControlLabel,
    Grid,
    Checkbox,
    MenuItem,
    Select,
    InputLabel,
    TextField,
    FormControl,
    FormHelperText,
} from "@material-ui/core";

export default function C2ACallMe(props) {
    const { formikProps, classes, outboundSkills, ivrScripts } = props;

    return (

        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid
                    container
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        {formikProps.values.c2a.callMeNowEnabled && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            formikProps.values?.c2a
                                                ?.callMeNow?.finalize ??
                                            false
                                        }
                                        onChange={
                                            formikProps.handleChange
                                        }
                                        onBlur={formikProps.handleBlur}
                                        name="c2a.callMeNow.finalize"
                                        color="primary"
                                    />
                                }
                                label="Finalize Call Me C2A"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {formikProps.values.c2a.callMeNowEnabled && (
                <>
                    <Grid item style={{ width: "100%" }}>
                        <FormControl>
                            <InputLabel
                                error={
                                    formikProps.errors.c2a?.callMeNow
                                        ?.skillId &&
                                    formikProps.touched?.c2a?.callMeNow
                                        ?.skillId
                                }
                            >
                                Outbound Skill
                            </InputLabel>
                            <Select
                                name="c2a.callMeNow.skillId"
                                className={classes.field}
                                value={
                                    formikProps.values?.c2a?.callMeNow
                                        ?.skillId
                                }
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                error={
                                    formikProps.errors.c2a?.callMeNow
                                        ?.skillId &&
                                    formikProps.touched?.c2a?.callMeNow
                                        ?.skillId
                                }
                            >
                                {outboundSkills.map((skill) => (
                                    <MenuItem
                                        key={skill.skillId}
                                        value={skill.skillId}
                                    >
                                        {skill.skillName}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formikProps.touched.c2a?.callMeNow
                                ?.skillId &&
                                formikProps.errors.c2a?.callMeNow
                                    ?.skillId && (
                                    <FormHelperText error>
                                        {
                                            formikProps.errors.c2a
                                                ?.callMeNow?.skillId
                                        }
                                    </FormHelperText>
                                )}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel error={formikProps.errors.c2a?.callMeNow?.ivrScript && formikProps.touched?.c2a?.callMeNow?.ivrScript}>IVR Script</InputLabel>
                            <Select
                                name="c2a.callMeNow.ivrScript"
                                className={classes.field}
                                value={formikProps.values.c2a?.callMeNow?.ivrScript ?? ''}
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                error={formikProps.errors.c2a?.callMeNow?.ivrScript && formikProps.touched?.c2a?.callMeNow?.ivrScript}
                            >
                                {ivrScripts.map(script =>
                                    <MenuItem key={script.scriptId} value={script.scriptId}>{script.scriptName}</MenuItem>)}
                            </Select>
                            {formikProps.touched.c2a?.callMeNow?.ivrScript && formikProps.errors.c2a?.callMeNow?.ivrScript &&
                                <FormHelperText error>{formikProps.errors.c2a?.callMeNow?.ivrScript}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            label="Message"
                            name="c2a.callMeNow.text"
                            type="text"
                            className={classes.textArea}
                            variant="outlined"
                            multiline
                            rows={12}
                            value={
                                formikProps.values?.c2a?.callMeNow
                                    ?.text ?? ""
                            }
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.c2a?.callMeNow
                                    ?.text &&
                                formikProps.touched?.c2a?.callMeNow
                                    ?.text
                            }
                            helperText={
                                formikProps.touched.c2a?.callMeNow
                                    ?.text &&
                                formikProps.errors.c2a?.callMeNow?.text
                            }
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

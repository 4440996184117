import React, { useState, useEffect, useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { Grid, Typography, Button, TablePagination, TextField, FormControlLabel, FormControl, Select, InputLabel, MenuItem, Checkbox, Dialog, IconButton, DialogContent, Tooltip, Divider, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import Filter9PlusOutlinedIcon from '@material-ui/icons/Filter9PlusOutlined';
import { API, graphqlOperation} from 'aws-amplify';
import { login,submitNicLicense, viewSubmittedLicenses } from '../context/InContact'
import UserContext from '../context/UserContext';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as _ from 'lodash';
import MaterialTable from 'material-table';
import { AddBoxOutlined, FiberSmartRecordOutlined, DoneAllOutlined, AddIcCallOutlined, AssessmentOutlined } from '@material-ui/icons';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import { HelpOutlineOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    selectInput: {
        minWidth: '100px'
    },
    formControl: {
        minWidth: 150,
        maxWidth: 350,
    },
    dialogDiv: {
        minWidth: '600px'
    },
    checkbox: {
        maxWidth: 20
    },
    padRight: {
        paddingRight: theme.spacing(2)
    },
}));

export default function NicLicSubmit() {
    const classes = useStyles();
    const userContext = useContext(UserContext)
    const { enqueueSnackbar } = useSnackbar();
    const [nicTenants, setNicTenants] = useState([]);

    const [curTenant, setCurTenant] = useState();
    const [businessUnit, setBU] = useState();
    const [submitTime, setSubmitTime] = useState();
    const [product, setProduct] = useState();
    const [prodAmt, setProdAmt] = useState();
    const [minProdAmt, setMinProdAmt] = useState(0);
    const [persistToNic, setPersistToNic] = useState(true);

    const [showDialog, setShowDialog] = useState(false);
    const [showAgentDetails, setShowAgentDetails] = useState(false);
    const [curLicenseAgents, setCurLicenseAgents] = useState([])
    const [viewDialog, setViewDialog] = useState(false);
    const [viewProduct, setViewProduct] = useState();
    const [getViewResults, setViewResults] = useState([]);
    const [dynamoResults, setDynamoResults] = useState([]);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    const [hideNic, setHideNic] = useState(false)
    const [hideDynamo, setHideDynamo] = useState(true);

    const [reportType, setReportType] = useState('nic');

    const products = [
        {
            name: 'E2',
            id: 349
        },
        {
            name: 'Essentials',
            id: 140
        },
        {
            name: 'Dialer',
            id: 141 
        },
        {
            name: 'OutReach',
            id: 348
        },
        {
            name: 'UMAX',
            id: 145
        }
    ];


    useEffect(() => {
        if (userContext.tenant){
            setStartTime(moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm'));
            setSubmitTime(moment().startOf('day').subtract(1, 'days').format('YYYY-MM-DDTHH:mm'));
            setEndTime(moment().startOf('day').format('YYYY-MM-DDTHH:mm'));
        }
        console.log(products);
    }, [userContext.tenant])

    useEffect(() => {
        if(startTime){
            getLicenses();
        }
    }, [startTime]);

    useEffect(() => {
        async function getData(){
            await viewAgents();
        }
        if (product){
            getData();
        }
    }, [product])

    async function getLicenses(){
        const licenses = [];

        const outreachQuery = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "agent": {
                            "terms": {
                                "field": "agent.keyword",
                                "size": 2147483647
                            }
                        }
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "type": "Dial"
                            }
                        },
                        {
                            "range": {
                                "createdAt": {
                                    "gte":  startTime,
                                    "lte": endTime
                                }
                            }
                        }
                    ]
                }
            },
            "size": 0
        }

        const e2Query = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "agent": {
                            "terms": {
                                "field": "agent",
                                "size": 2147483647
                            }
                        }
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "type": "Login"
                            }
                        },
                        {
                            "range": {
                                "createdAt": {
                                    "gte":  startTime,
                                    "lte": endTime
                                }
                            }
                        }
                    ]
                }
            },
            "size": 0
        }

        const uMaxQuery = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "agent": {
                            "terms": {
                                "field": "agent.keyword",
                                "size": 2147483647
                            }
                        }
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "type": "Login"
                            }
                        },
                        {
                            "range": {
                                "createdAt": {
                                    "gte":  startTime,
                                    "lte": endTime
                                }
                            }
                        }
                    ]
                }
            },
            "size": 0
        }

        //Make Queries
        //OutReach
        const outReachJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'activitylog',
            action: '_search',
            query: JSON.stringify(outreachQuery)
        }));
        const outReachResult = JSON.parse(outReachJSON.data.esQuery);
        if(outReachResult && outReachResult['aggregations']){
            const outReachData = outReachResult['aggregations'].tenant.buckets;
            outReachData.map(obj => {
                const license = {
                    tenant: obj.key,
                    OutReach: obj.agent.buckets.length
                }
                licenses.push(license);
            });
        }


        //E2
        const e2JSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'ciflog',
            action: '_search',
            query: JSON.stringify(e2Query)
        }));
        const e2Result = JSON.parse(e2JSON.data.esQuery);
        if(e2Result && e2Result['aggregations']){
            const e2Data = e2Result['aggregations'].tenant.buckets;
            e2Data.map(obj => {
                const index = licenses.findIndex(license => license.tenant === obj.key);
                if (index > -1){
                    licenses[index] = {...licenses[index], E2: obj.agent.buckets.length};
                } else {
                    const license = {
                        tenant: obj.key,
                        E2: obj.agent.buckets.length
                    }
                    licenses.push(license);
                }
            });
        }

        //UMAX
        const uMaxJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'umaxlog',
            action: '_search',
            query: JSON.stringify(uMaxQuery)
        }));
        const uMaxResult = JSON.parse(uMaxJSON.data.esQuery);
        if(uMaxResult && uMaxResult['aggregations']){
            const uMaxData = uMaxResult['aggregations'].tenant.buckets;
            uMaxData.map(obj => {
                const index = licenses.findIndex(license => license.tenant === obj.key);
                if (index > -1){
                    licenses[index] = {...licenses[index], UMAX: obj.agent.buckets.length};
                } else {
                    const license = {
                        tenant: obj.key,
                        UMAX: obj.agent.buckets.length
                    }
                    licenses.push(license);
                }
            });
        }

        console.log(licenses);
        setNicTenants(licenses);
    }

    async function getTenantSettings(tenant){
        const tenantResult = await API.graphql(graphqlOperation(queries.getTenantSettings, {
            id: tenant    
        }));
        if (tenantResult && tenantResult.data && tenantResult.data.getTenantSettings){
            return tenantResult.data.getTenantSettings;    
        }
    }

    const handleLicenseEdit = async (rowData, selProduct) => {
        console.log(rowData);
        console.log(selProduct);
        setSubmitTime(startTime);
        if (rowData && selProduct){
            const businessTenant = await getTenantSettings(rowData.tenant);

            setCurTenant(rowData.tenant);
            setBU(businessTenant.icBU);
            setProdAmt(rowData[selProduct.name]);
            if (selProduct.name === 'E2' && businessTenant.productSku) {
                const overrideProduct = _.find(businessTenant.productSku, ['product', 'E2']);
                if (overrideProduct) {
                    setProduct(overrideProduct.productId);
                } else {
                    setProduct(selProduct.id);
                }
            } else {
                setProduct(selProduct.id);
            }
            const minimumSeats = _.find(businessTenant.minimumSeats, ['productId', `${selProduct.id}`])
            if (minimumSeats) {
                setMinProdAmt(minimumSeats?.minimumSeats);
            } else {
                setMinProdAmt(0);
            }
        }else{
            setCurTenant('');
            setBU('');
            setProduct();
            setProdAmt(0);
            setMinProdAmt(0);
        }
        setShowDialog(true);
    }

    async function viewAgents(){
        let type;
        let model;
        let agent;
        console.log('product',product);
        if(product === 349 || product === 140){
            type = "Login"
            model = "ciflog"
            agent = "agent"
        }
        else if (product === 348){
            type = "Dial"
            model = "activitylog"
            agent = "agent.keyword"
        } else if (product === 145){
            type = "Login"
            model = "umaxlog"
            agent = "agent.keyword"
        }
        else{
            console.log("Unable to display agents")
        }

        const query = {
            "aggs": {
                "agent": {
                    "terms": {
                        "field": agent,
                        "size": 2147483647
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "type": type
                            }
                        },
                        {
                            "term": {
                                "tenant.keyword": curTenant
                            }
                        },
                        {
                            "range": {
                                "createdAt": {
                                    "gte":  startTime,
                                    "lte": endTime
                                }
                            }
                        }
                    ]
                }
            },
            "size": 0
        }

        const agentsJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: model,
            action: '_search',
            query: JSON.stringify(query)
        }));
        const agentsResult = JSON.parse(agentsJSON.data.esQuery);
        if (agentsResult && agentsResult['aggregations']){
            const agents = agentsResult['aggregations'].agent.buckets;
            setCurLicenseAgents([...agents])
            console.log(agents)
        }

    }

    async function submitToNic() {
        try {
            const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
            const loginSettings = dynamoSettings.data.getTenantSettings;
            await login(loginSettings);
            const licenseInformation = {
                "productUsage": [{
                    serverName: "Customer Dynamics",
                    businessUnitId: businessUnit,
                    prodTypeId: product,
                    prodAmt: prodAmt,
                    //agent: "agent",//TODO add agent Dynamics
                    queryDate: startTime
                }]
            };
            console.log(licenseInformation);
            const nicLicenseStatus = await submitNicLicense(licenseInformation);
            if  (nicLicenseStatus.status === 200) {
                console.log("NicLicense information Added");
                enqueueSnackbar('Submitted to NIC', {
                    variant: 'success'
                  });
            }
            else {
                console.log(nicLicenseStatus)
                enqueueSnackbar('Error submitting to NIC ' + nicLicenseStatus.productUsageResults[0].error, {
                    variant: 'error',
                    autoHideDuration: 5000
                  });
            }
            
           
        }
        catch (err) {
            console.error(err);
        }
    }

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        );
    }

    async function saveToDynamo() {
        const productName = _.find(products, ['id', product]);

        let log = {
            tenant: curTenant,
            ownerTenant: userContext.tenant,
            cdProd: productName.name,
            ncProdNum: product,
            ncBusinessUnit: businessUnit,
            Date: moment(startTime),
            Count: prodAmt
        }

        const query = {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "tenant": curTenant
                            }
                        },
                        {
                            "match_phrase": {
                                "Date": moment(startTime)
                            }
                        },
                        {
                            "match_phrase": {
                                "cdProd": productName.name
                            }
                        }
                    ]
                }
            }
        }

        const previousEntry = await API.graphql(graphqlOperation(queries.esQuery, {
            model: "dailylicensecount",
            action: '_search',
            query: JSON.stringify(query)
        }));
        const resultJson = JSON.parse(previousEntry.data.esQuery);
        if (resultJson && resultJson.hits.hits.length > 0){
            try{
                log.id = resultJson.hits.hits[0]._source.id;
                await API.graphql(graphqlOperation(mutations.updateDailyLicenseCount, { input: log}));
                enqueueSnackbar('Saved license count to Dynamo', {
                    variant: 'success'
                  });
            } catch {
                enqueueSnackbar('Error saving license count to Dynamo', {
                    variant: 'error'
                  });
            }

        } else {
            try{
                await API.graphql(graphqlOperation(mutations.createDailyLicenseCount, {input: log}));
                enqueueSnackbar('Saved license count to Dynamo', {
                    variant: 'success'
                  });
            } catch {
                enqueueSnackbar('Error saving license count to Dynamo', {
                    variant: 'error'
                  });
            }
        }
        
    }

    const handleSubmit = async () => {
        if(persistToNic){
            submitToNic();
        }
        if (curTenant && curTenant !== ''){
            if (businessUnit && businessUnit !== ''){
                if(product && product !== ''){
                    saveToDynamo();
                } else {
                    enqueueSnackbar('Please select a product', {
                        variant: 'error'
                      });
                }
            } else {
                enqueueSnackbar('Please enter a business unit', {
                    variant: 'error'
                  });
            }
        } else {
            enqueueSnackbar('Please enter a tenant name', {
                variant: 'error'
              });
        }

    }

    const handleProdChange = async (event) => {
        const changedProduct = event.target.value;
        setViewProduct(changedProduct);
        setProduct(changedProduct)
        //Get InContact Report
        await viewSubmitted(changedProduct, startTime, endTime);
        //Get Dynamo Report
        await viewDynamoSubmitted(changedProduct);
    }
    
    const handlePersist = (event) => {
        setPersistToNic(!persistToNic)
    }

    const handleAgent = (event) => {
        setShowAgentDetails(!showAgentDetails)
    }

    const handleStart = (event) => {
        const start = event.target.value;
        setStartTime(moment(start).startOf('day').format('YYYY-MM-DDTHH:mm'));
        setEndTime(moment(start).startOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm'));
        setSubmitTime(moment(start).startOf('day').format('YYYY-MM-DDTHH:mm'));
    }

    const viewDynamoSubmitted = async(productId) =>{
        const productName = _.find(products, ['id', productId]);
        const query = {
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "cdProd": productName.name
                            }
                        },
                        {
                            "range": {
                                "Date": {
                                    "gte": startTime,
                                    "lte": endTime
                                }
                            }
                        }
                    ]
                }
            }
        }

        const dynamoJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'dailylicensecount',
            action: '_search',
            query: JSON.stringify(query)
        }));
        const dynamoResult = JSON.parse(dynamoJSON.data.esQuery);
        console.log(dynamoResult);
        if (dynamoResult && dynamoResult.hits.hits.length > 0){
            setDynamoResults([...dynamoResult.hits.hits]);
        } else {
            setDynamoResults([]);
        }
    }

    const viewSubmitted = async (productId) => {
        const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
        const tempAll = dynamoSettings.data.getTenantSettings;
        const nicLicenseEndTime = moment(startTime).endOf('day').format('YYYY-MM-DDTHH:mm')
        await login(tempAll);
       const result = await viewSubmittedLicenses(productId, startTime, nicLicenseEndTime);
       if(result){
        const productResult = JSON.parse(result.text)
        setViewResults(productResult.productUsage)
       }

    }

    function openViewDialog(){
        setViewDialog(true);
    }

    function closeView(){
        setViewDialog(false);
        setProduct();
        setViewResults([]);
        setDynamoResults([]);
    }

    const handleTenant = (event) => {
        const manualTenant = event.target.value;
        setCurTenant(manualTenant);
    }

    const handleSubmitTime = (event) => {
        const handleTime = event.target.value;
        setSubmitTime(moment(handleTime).startOf('day').format('YYYY-MM-DDTHH:mm'));
    }

    const handleBU = (event) => {
        const manualBU = event.target.value;
        setBU(manualBU);
    }

    const handleAmount = (event) => {
        const manualAmount = event.target.value;
        setProdAmt(manualAmount);
    }

    const handleReportType = (event, value) => {
        setReportType(value);
        if (value === 'nic'){
            setHideNic(false);
            setHideDynamo(true);
        } else if (value === 'dynamo'){
            setHideNic(true);
            setHideDynamo(false);
        } else if (value === 'combo'){
            setHideNic(false);
            setHideDynamo(false);
        }
    }
    
    return (
        
        <div>
            <Grid item container direction="row" spacing={1} justify="flex-end" alignItems="center">
                <Grid item>
                    <TextField
                        margin="dense"
                        name="startDate"
                        label="Filter Start Date"
                        type="datetime-local"
                        variant="outlined"
                        color="primary"
                        value={startTime}
                        onChange={handleStart}
                        InputLabelProps={{
                            shrink: true
                        }} />
                </Grid>
                <Grid item>
                    <Tooltip
                        placement="top-end"
                        leaveDelay={500}
                        title="View Submitted Licenses">
                        <IconButton onClick={openViewDialog}><PageviewOutlinedIcon color="primary" /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip
                        placement="top-end"
                        leaveDelay={500}
                        title="Manual License Submit">
                        <IconButton onClick={handleLicenseEdit}><AddBoxOutlined color="secondary" /></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <br></br>
            <MaterialTable
                columns={[
                    { title: 'Tenant', field: 'tenant', render: rowData => <div>{rowData.tenant}</div>},
                    { title: 'E2', field: 'E2', render: rowData => 
                    <div>
                        <Grid item container justify="flex-start" alignItems="center" direction="row">
                            <Grid item>
                                {rowData.E2 ? rowData.E2 : 0}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {handleLicenseEdit(rowData, products[0]);}}>
                                    <Filter9PlusOutlinedIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    },
                    { title: 'ReachCx', field: 'OutReach', render: rowData => 
                    <div>
                        <Grid item container justify="flex-start" alignItems="center" direction="row">
                            <Grid item>
                                {rowData.OutReach ? rowData.OutReach : 0}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {handleLicenseEdit(rowData, products[3]);}}>
                                    <Filter9PlusOutlinedIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    },
                    { title: 'UMAX', field: 'uMax', render: rowData => 
                    <div>
                        <Grid item container justify="flex-start" alignItems="center" direction="row">
                            <Grid item>
                                {rowData.UMAX ? rowData.UMAX : 0}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {handleLicenseEdit(rowData, products[4]);}}>
                                    <Filter9PlusOutlinedIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    },
                    { title: 'C3', field: 'ppayLicenses', render: rowData => 
                    <div>
                        <Grid item container justify="flex-start" alignItems="center" direction="row">
                            <Grid item>
                                {rowData.ppayLicenses ? rowData.ppayLicenses : 0}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {handleLicenseEdit(rowData, "c3Licenses");}}>
                                    <Filter9PlusOutlinedIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    },
                    { title: 'Safe Select', field: 'orsLicenses', render: rowData => 
                    <div>
                        <Grid item container justify="flex-start" alignItems="center" direction="row">
                            <Grid item>
                                {rowData.orsLicenses ? rowData.orsLicenses : 0}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {handleLicenseEdit(rowData, "ssLicenses");}}>
                                    <Filter9PlusOutlinedIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10
                    
                }}
                components={{
                    Pagination: licensePaging
                }}
                data={nicTenants}
                title="Licensing Submit"
            />

            {/* Approval Dialog */}
            <Dialog open={showDialog}>
                <DialogContent>
                    <Grid container direction="column" alignItems="stretch" spacing={2}>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Typography variant="h4">Submit NiC Licensing</Typography>
                            <div>
                                <Grid item container direction="row" spacing={2} justify="flex-end">
                                    <Grid item>
                                        <Button onClick={() => {setShowDialog(false);}} color="primary" variant="outlined">Close</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={handleSubmit} color="primary" variant="contained">Submit</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid container item direction="row" alignContent="center" alignItems="center" justify="space-between">
                            <Grid item md={6} xs={12}>
                                <TextField
                                    margin="dense"
                                    name="tenant"
                                    label="Tenant Name"
                                    type="text"
                                    className={classes.selectInput}
                                    required={true}
                                    value={curTenant}
                                    onChange={handleTenant}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>    
                                <TextField
                                    margin="dense"
                                    name="tenant"
                                    label="Business Unit"
                                    type="text"
                                    className={classes.selectInput}
                                    required={true}
                                    value={businessUnit}
                                    onChange={handleBU}
                                />
                            </Grid>    
                            <Grid item md={6} xs={12}>    
                                <TextField
                                    margin="dense"
                                    name="name"
                                    type="datetime-local"
                                    className={classes.selectInput}
                                    required={true}
                                    onChange={handleStart}
                                    value={moment(submitTime).format('YYYY-MM-DDTHH:mm')}
                                />
                            </Grid>    
                            <Grid item md={6} xs={12}>    
                                <FormControl className={classes.formControl}>
                                    <InputLabel >Product Type</InputLabel>
                                    <Select
                                        name="productSelect"
                                        displayEmpty
                                        value={product}
                                        onChange={handleProdChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {products.map(value =>(
                                            <MenuItem value={value.id}>{value.name}</MenuItem>
                                        ))}  
                                    </Select>
                                </FormControl>
                                <Tooltip
                                    placement="top-start"
                                    leaveDelay={500}
                                    title={
                                        <React.Fragment>
                                            <Typography variant="body1">
                                                <strong>Product Codes:</strong><br />
                                                <ul>
                                                    <li>E2: 349</li>
                                                    <li>Essentials: 140</li>
                                                    <li>Dialer: 141</li>
                                                    <li>OutReach: 348</li>
                                                    <li>UMAX: 145</li>
                                                </ul>
                                            </Typography>
                                        </React.Fragment>
                                    }>
                                    <IconButton size="small"><HelpOutlineOutlined color="primary" /></IconButton>
                                </Tooltip>
                            </Grid>
                            {/* <Grid item className={classes.padRight}>
                                
                            </Grid>  */}
                            <Grid item md={6} xs={12}>    
                                <TextField
                                    margin="dense"
                                    name="unitAmount"
                                    label={`Unit Amount (Min:${minProdAmt})`}
                                    className={classes.selectInput}
                                    required={true}
                                    value={prodAmt}
                                    onChange={handleAmount}
                                />    
                            </Grid>
                            <Grid item container direction="column" md={6} xs={12}>
                                <Typography variant="overline">Persist to InContact</Typography>
                                <FormControl >
                                    <Checkbox className={classes.checkbox} checked={persistToNic} onChange={handlePersist} color="primary"></Checkbox>
                                </FormControl>
                            </Grid>    
                        </Grid>
                        <Divider></Divider>
                        <br></br>
                        <Grid container item direction="row" justify="space-between" alignItems="center" onClick={handleAgent}>
                            <Grid item>
                                <Typography variant="h6">Show Agents</Typography>
                            </Grid>
                            <Grid item>
                                {showAgentDetails ? <KeyboardArrowUpOutlinedIcon/> : <KeyboardArrowDownOutlinedIcon/>}
                            </Grid>
                        </Grid>
                        <div hidden={!showAgentDetails}>
                            <Grid container direction="column" spacing={1}>
                                {curLicenseAgents.map((agent => 
                                            <Grid item>
                                                <Typography variant="body1">{agent.key}</Typography>
                                            </Grid>
                                    ))}
                            </Grid>
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog open={viewDialog} onClose={closeView}>
                <DialogTitle>View Submitted Licenses</DialogTitle>
                <DialogContent>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="overline">Reports</Typography>
                        </Grid>
                        <Grid container item direction="row" spacing={4} alignContent="center" alignItems="flex-end">
                            <Grid item>
                                <FormControlLabel
                                control = {
                                    <ToggleButtonGroup color="primary" size="small" value={reportType} exclusive onChange={handleReportType}>
                                        <ToggleButton value="nic">
                                            <Tooltip title="NIC">
                                                <AddIcCallOutlined color="primary" />
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value="dynamo">
                                            <Tooltip title="Dynamo">
                                                <AssessmentOutlined color="primary" />
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value="combo">
                                            <Tooltip title="Combination">
                                                <DoneAllOutlined color="Primary" />
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                }
                                />
                            </Grid>
                            <Grid item>    
                                <FormControl className={classes.formControl}>
                                    <InputLabel >Product Type</InputLabel>
                                    <Select
                                        name="productSelect"
                                        displayEmpty
                                        value={product}
                                        onChange={handleProdChange}
                                    >
                                     <MenuItem value="">None</MenuItem>
                                        {products.map(value =>(
                                            <MenuItem value={value.id}>{value.name}</MenuItem>
                                        ))}   
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Divider></Divider>
                        <br></br>
                        <div hidden={hideDynamo}>
                            <Grid container item>
                                <Grid item>
                                    <Typography variant="overline">Dynamo Submitted</Typography>
                                </Grid>
                                {dynamoResults.map((result => 
                                <Grid item container spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">Business Unit: {result._source.ncBusinessUnit}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">Tenant: {result._source.tenant}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">License Count: {result._source.Count}</Typography>
                                    </Grid>
                                </Grid>               
                                ))}  
                            </Grid>
                            <br></br>
                            <Divider></Divider>
                        </div>
                        <div hidden={hideNic}>
                            <Grid container item>
                                <Grid item>
                                    <Typography variant="overline">NIC Submitted</Typography>
                                </Grid>
                                {getViewResults.map((result => 
                                <Grid item container spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">Business Unit: {result.bus_no}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">License Count: {result.prodAmt}</Typography>
                                    </Grid>
                                </Grid>               
                                ))}  
                            </Grid>
                            <br></br>
                            <Divider></Divider>
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}



import React, { useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import { Grid, Typography} from '@material-ui/core';
import ConfirmDialog from '../components/ConfirmDialog';
import MaterialTable from 'material-table';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

const IVRProfilesList = function(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState();
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const manageProfile = (path, rowData) => {
        props.history.push(path, {profileData: rowData, comingFrom: 'Edit'});
    }

    const previewProfile = async (rowData) => {
        // async function setData(){
        //     setName(rowData.name);
        //     setOrg(rowData.org);
        //     setMediaType(rowData.mediaType);
        //     setDirection(rowData.direction);
        //     setSkills(rowData.skillIds);
        //     setMultiSearch(JSON.parse(rowData.multiSearch));
        //     setSearch(JSON.parse(rowData.search));
        //     const searchAction = await JSON.parse((rowData.searchAction));
        //     setNoMatch(searchAction[0].noMatch);
        //     setSingleMatch(searchAction[1].singleMatch);
        //     setMultiMatch(searchAction[2].multiMatch);
        //     setMasterLoggings(JSON.parse(rowData.activityEntityMap));
        //     setTransactionLoggings(JSON.parse(rowData.logging));
        //     setPostTransactionLoggings(JSON.parse(rowData.postLogging));
        // }
        //     if(rowData){
        //         setData();
        //         setOpenPreview(true);
        //     }
    }

    const deleteProfile = (profile) => {
        setConfirmOpen(true);
        setToDelete(profile);
    }

    const cancelDelete = () => {
        setConfirmOpen(false);
        setToDelete({});
    }

    const handleDelete = async (profile) => {
        setConfirmOpen(false);
        setToDelete({});
        try{
            await API.graphql(graphqlOperation(mutations.deletePreProcess, {input: { id: profile.id} }));
            enqueueSnackbar('Profile deleted.');
        } catch (err) {
            enqueueSnackbar('Delete unsuccessful. ' + err, {
                variant: 'error'
              });
        }

    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <MaterialTable
                            columns={[
                                { title: 'Name', field: 'name'},
                                // { title: 'Organization', field: 'action.org.name'},
                                // { title: 'Entity', field: 'action.crmEntity'},
                                // { title: 'Action', field: 'action.Action'},
                            ]}
                            data={data}
                            title=''
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: false,
                                pageSize: 10
                            }}
                            actions={[
                                {
                                    icon: () => (<VisibilityOutlinedIcon color="primary" />),
                                    tooltip: 'Preview Profile',
                                    onClick: (event, rowData) => {
                                        previewProfile(rowData);
                                    }
                                },
                                {
                                    icon: () => (<Edit color="primary" />),
                                    tooltip: 'Edit Profile',
                                    onClick: (event, rowData) => {
                                        manageProfile(`/e2/ivr/profiles/`, rowData);
                                    }
                                },
                                {
                                    icon: () => (<DeleteIcon color="primary" />),
                                    tooltip: 'Delete Profile',
                                    onClick: (event, rowData) => {
                                        deleteProfile(rowData);
                                    }
                                }
                                
                            ]}
                        />
                    </Grid>
                    <br></br>
                    <ConfirmDialog
                        open={confirmOpen}
                        value={toDelete}
                        onConfirm={handleDelete}
                        onCancel={cancelDelete}
                        confirmTxt="Yes"
                    >
                         <Typography>Your profile will be permamently deleted.</Typography>   
                    </ConfirmDialog>
                    {/* <Dialog open={openPreview} maxWidth='xl'>
                        <DialogContent>
                            {skills &&  <Review
                                name={name} 
                                org={org} 
                                mediaType={mediaType} 
                                direction={direction}
                                skills={skills}
                                multiSearch={multiSearch}
                                search={search}
                                noMatch ={noMatch}
                                singleMatch={singleMatch}
                                multiMatch={multiMatch}
                                masterLoggings={masterLoggings}
                                transactionLoggings={transactionLoggings}
                                postTransactionLoggings={postTransactionLoggings}>
                            </Review>}
                            <Grid container direction="row" justify="space-between">
                                <Button onClick={closePreview} variant="outlined" color="primary">Cancel</Button>
                                <Button onClick={printProfile} variant="contained" color="primary">Print</Button>
                            </Grid>
                        </DialogContent>
                    </Dialog> */}
                </div>
            )}
        </UserContext.Consumer>
        
    
    );
}

export default withRouter(IVRProfilesList);
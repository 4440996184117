import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import UserContext from "../context/UserContext";
import {
    Typography,
    Grid,
    Button,
    Card,
    CardHeader,
    CardContent,
    Paper,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { useParams, useHistory } from "react-router-dom";
import { DirectionsRunOutlined } from '@material-ui/icons';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
    login,
    getMessagingSkills,
    getConfigurationIcSkills,
    getPointsOfContact,
    getScripts
} from "../context/InContact";
import C2APayment from "./C2A/Payment";
import C2ACallMe from "./C2A/CallMe";
import C2ACallUs from "./C2A/CallUs";
import C2AScheduleCallBack from "./C2A/ScheduleCallBack";
import C2ACommunication from "./C2A/Communication";
import _ from "lodash";
import MessagingOptions from "./Sections/MessagingOptions";
import Details from "./Sections/Details";
import StylingOptions from "./Sections/StylingOptions";
import {
    CenterToggleContainer,
    CenterToggleItem,
    CenterToggleChild,
} from "react-center-toggle";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    field: {
        width: "300px",
    },
    paper: {
        padding: "15px",
    },
    logoPreview: {
        width: "300px",
        height: "auto",
        marginLeft: "48px",
        marginTop: "12px",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120,
        maxWidth: 350,
    },
    dialogDiv: {
        minWidth: "600px",
    },
    longInput: {
        width: "300px",
    },
    textArea: {
        minWidth: "500px",
    },
    accordianTitle: {
        marginLeft: "1em",
    },
}));

const newProfile = {
    id: "",
    tenant: "",
    name: "",
    vendorId: "",
    apiKey: "",
    descriptor: "",
    ttl: 30,
    header1: "",
    header2: "",
    siteLogo: "",
    sitePrimary: "#ffffff",
    siteSecondary: "#ffffff",
    minType: null,
    minRate: 0,
    supportEmail: "",
    supportPhone: "",
    promptPaySkills: [],
    c2a: {
        paymentEnabled: false,
        callMeNowEnabled: false,
        callMeLaterEnabled: false,
        callUsEnabled: false,
        optOutEnabled: false,
        optInEnabled: false,
        fileDownloadEnabled: false,
        urlRedirectEnabled: false,
        payment: {
            dynamicDescriptor: "",
            minRate: 0,
            minType: "",
            finalize: true,
            balanceReplacement: {
                value: "", 
                staticField: false
            },
            agingReplacement: {
                value: "", 
                staticField: false
            },
            invoiceReplacement: {
                value: "", 
                staticField: false
            },
        },
        callMeNow: {
            text: "",
            skillId: "",
            finalize: false,
        },
        callMeLater: {
            text: "",
            skillId: "",
            finalize: true,
        },
        callUs: {
            text: "",
            extension: "",
            pointOfContact: "",
            finalize: true,
        },
        optOut: {
            text: "",
            fields: [
                "Voice",
                "SMS",
                "Email"
            ],
            finalize: false,
        },
    },
    channels: {
        emailEnabled: false,
        smsEnabled: false,
        pushNotificationsEnabled: false,
        email: {
            messageTemplate: "",
            subject: "",
            sendingEmail: "",
            emailSkill: "",
            emailIVRScript: "",
        },
        sms: {
            messageTemplate: "",
            smsSkill: "",
            smsIVRScript: "",
            providerName: "incontact",
            sendingNumber: "",
            bearerToken: ""
        },
        pushnotification: {
            title: "",
            messageTemplate: "",
            projectId: "",
        },
    },
};

Array.prototype.sortByAttribute = function (attribute) {
    this.sort((a, b) => {
        if (a[attribute] < b[attribute]) return -1;
        if (a[attribute] > b[attribute]) return 1;
        return 0;
    });
};

export default function Profile() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { id } = useParams();
    const [paymentProfile, setPaymentProfile] = useState(newProfile);

    const [availableMessageTemplates, setAvailableMessageTemplates] = useState([]);
    const minPayTypes = ["None", "Percentage", "Static"];
    const [skills, setSkills] = useState([]);
    const [smsMessagingSkills, setSmsMessagingSkills] = useState([]);
    const [emailMessagingSkills, setEmailMessagingSkills] = useState([]);
    const [outboundSkills, setOutboundSkills] = useState([]);
    

    const [projects, setProjectTemplate] = useState([]);
    const [pointsOfContact, setPointsOfContact] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPercent, setLoadingPercent] = useState(0);
    const loadingPercentRef = useRef(0);
    const [saving, setSaving] = useState(false);
    const [ivrScripts, setIvrScripts] = useState([]);

    const contactFields = useMemo(
        () => [
            { value: "externalId", label: "External Id" },
            { value: "firstName", label: "First Name" },
            { value: "lastName", label: "Last Name" },
            { value: "phone", label: "Phone" },
            { value: "source", label: "Source" },
            { value: "cell", label: "Cell" },
            { value: "email", label: "Email" },
            { value: "address1", label: "Address1" },
            { value: "address2", label: "Address2" },
            { value: "city", label: "City" },
            { value: "state", label: "State" },
            { value: "zip", label: "Zip" },
            { value: "timeZone", label: "Timezone" },
            { value: "expireDt", label: "Expiration Date" },
            { value: "complianceRequired", label: "Compliance Required" },
            { value: "preview", label: "Preview" },
            { value: "outboundCallerId", label: "Outbound Caller ID" },
            { value: "optIns:sms", label: "SMS - Opt In" },
            { value: "optIns:email", label: "Email - Opt In" },
            { value: "optIns:voice", label: "Voice - Opt In" },
            { value: "optOuts:sms", label: "SMS - Opt Out" },
            { value: "optOuts:email", label: "Email - Opt Out" },
            { value: "optOuts:voice", label: "Voice - Opt Out" },
            ...(userContext?.customFields ?? []).map((customField) => {
                return {
                    value: `custom:${customField.name}`,
                    label: customField.displayName,
                };
            }),
        ],
        [userContext.customFields]
    );

    useEffect(() => {
        async function getData() {
            setLoading(true);

            await new Promise(async (resolve) => {
                const dynamoSettings = await API.graphql(
                    graphqlOperation(queries.getTenantSettings, {
                        id: userContext.tenant,
                    })
                );
                await login(dynamoSettings.data.getTenantSettings);

                resolve();
            });

            setLoadingPercent((++loadingPercentRef.current / 7) * 100);

            const promises = [];

            if (id !== 'new') {
                promises.push(
                    (async () => {
                        const paymentProfile = await API.graphql(
                            graphqlOperation(queries.getPaymentProfile, { id: id })
                        );

                        if (
                            paymentProfile.data &&
                            paymentProfile.data.getPaymentProfile
                        ) {
                            if (paymentProfile.data.getPaymentProfile.c2a) {
                                paymentProfile.data.getPaymentProfile.c2a = JSON.parse(
                                    paymentProfile.data.getPaymentProfile.c2a
                                );
                            } else {
                                paymentProfile.data.getPaymentProfile.c2a = newProfile.c2a;
                            }

                            if (paymentProfile.data.getPaymentProfile.channels) {
                                paymentProfile.data.getPaymentProfile.channels = JSON.parse(
                                    paymentProfile.data.getPaymentProfile.channels
                                );
                            } else {
                                paymentProfile.data.getPaymentProfile.channels = newProfile.channels;
                            }


                            setPaymentProfile(
                                paymentProfile.data.getPaymentProfile
                            );
                        }
                        setLoadingPercent((++loadingPercentRef.current / 7) * 100);
                    })()
                );
            }


            promises.push(
                (async () => {
                    const paymentTemplates = await API.graphql(
                        graphqlOperation(queries.listPaymentTemplates)
                    );
                    setAvailableMessageTemplates(
                        paymentTemplates.data?.listPaymentTemplates?.items ?? []
                    );
                    setLoadingPercent((++loadingPercentRef.current / 7) * 100);
                })()
            );

            promises.push(
                (async () => {
                    const deviceTemplate = await API.graphql(
                        graphqlOperation(queries.listMobileConfigurations)
                    );
                    setProjectTemplate(
                        deviceTemplate.data?.listMobileConfigurations?.items ?? []
                    );
                })()
            ); 

            promises.push(
                (async () => {
                    const smsMessageSkills = await getMessagingSkills(4);
                    smsMessageSkills.sortByAttribute('skillName');
                    setSmsMessagingSkills(
                        Array.isArray(smsMessageSkills)
                            ? [
                                ...smsMessageSkills
                                // removed filter for now
                                // ...smsMessageSkills?.filter(
                                //     (x) => x.smsTransportCode != null
                                // ),
                            ]
                            : []
                    );
                    setLoadingPercent((++loadingPercentRef.current / 7) * 100);
                })()
            );

            promises.push(new Promise(async resolve => {
                const scripts = await getScripts();
                scripts.sortByAttribute('scriptName');
                setIvrScripts(scripts);

                setLoading(loading => ({
                    ...loading,
                    scripts: false
                }));

                resolve();
            }));

            promises.push(
                (async () => {
                    const pointsOfContact = await getPointsOfContact();
                    setPointsOfContact(
                        pointsOfContact.filter((x) => x.mediaTypeId === 4)
                    );
                    setLoadingPercent((++loadingPercentRef.current / 7) * 100);
                })()
            );

            promises.push(
                (async () => {
                    const emailSkills = await getMessagingSkills(4);
                    emailSkills.sortByAttribute('skillName');
                    setEmailMessagingSkills(
                        Array.isArray(emailSkills) ? [...emailSkills] : []
                    );
                    setLoadingPercent((++loadingPercentRef.current / 7) * 100);
                })()
            );

            promises.push(
                (async () => {
                    const profileSkills = await getConfigurationIcSkills();
                    profileSkills.sortByAttribute('skillName');
                    setSkills(profileSkills);
                    setOutboundSkills(
                        profileSkills.filter((skill) => skill.isOutbound)
                    );
                    setLoadingPercent((++loadingPercentRef.current / 7) * 100);
                })()
            );

            await Promise.all(promises);

            setLoading(false);
        }
        if (userContext.tenant !== "") {
            if (id === "new") {
                setPaymentProfile(newProfile);
            }
            getData();
        }
    }, [id, userContext.tenant]);

    function handleClose() {
        history.push("/ppay/PaymentProfiles");
    }

    return (
        <Formik
            initialValues={paymentProfile}
            enableReinitialize
            validationSchema={Yup.object({
                name: Yup.string()
                    .required("A Profile name is required")
                    .trim()
                    .matches(
                        /^[a-zA-Z0-9 -]+$/,
                        "Please enter a valid profile name"
                    ),
                vendorId: Yup.string()
                    .required("A vendor ID is required")
                    .trim()
                    .matches(
                        /^[a-zA-Z0-9 -]+$/,
                        "Please enter a valid vendor id"
                    ),
                apiKey: Yup.string()
                    .required("An API key is required")
                    .trim(),
                descriptor: Yup.string()
                    .trim(),
                ttl: Yup.number()
                    .required("An expiration time is required")
                    .positive("Expiration time must be positive")
                    .integer("Expiration time must be an integer")
                    .min(1, "Expiration time must be at least one minute"),
                siteLogo: Yup.string()
                    .required("A site logo is required")
                    .url("Please enter a valid site logo URL")
                    .matches(
                        /apng$|bmp$|gif$|ico$|cur$|jpg$|jpeg$|jfif$|pjpeg$|pjp$|png$|svg$|tif$|tiff$|webp$/,
                        "Image is invalid"
                    ),
                header1: Yup.string()
                    .trim()
                    .required("Header 1 is required"),
                header2: Yup.string()
                    .trim()
                    .required("Header 2 is required"),
                supportEmail: Yup.string()
                    .trim()
                    .required("Please enter a support email")
                    .email("Please enter a valid support email"),
                supportPhone: Yup.string()
                    .trim()
                    .required("Please enter a support phone"),
                channels: Yup.object({
                    email: Yup.mixed().when("emailEnabled", {
                        is: true,
                        then: Yup.object({
                            messageTemplate: Yup.string().required(
                                "A message template is required"
                            ),
                            subject: Yup.string().required(
                                "A subject is required"
                            ),
                            sendingEmail: Yup.string().required(
                                "A sending email is required"
                            ),
                            emailSkill: Yup.string().required(
                                "An email skill is required"
                            ),
                        }),
                    }),
                    sms: Yup.mixed().when("smsEnabled", {
                        is: true,
                        then: Yup.object({
                            messageTemplate: Yup.string().required('A message template is required'),
                            smsSkill: Yup.mixed().when('providerName', {
                                is: 'incontact',
                                then: Yup.string().required('An SMS skill is required')
                            }),
                            providerName: Yup.string().required('An SMS provider is require'),
                            sendingNumber: Yup.mixed().when('providerName', {
                                is: 'textel',
                                then: Yup.string().required('A sending number is required')
                            }),
                            bearerToken: Yup.mixed().when('providerName', {
                                is: 'textel',
                                then: Yup.string().required('A Textel bearer token is required')
                            })
                        })
                    }),
                    pushnotification: Yup.mixed().when("pushNotificationsEnabled", {
                        is: true,
                        then: Yup.object({
                          title: Yup.string().required(
                                "A title is required"
                            ),
                            messageTemplate: Yup.string().required(
                                "A message template is required"
                            ),
                            projectId: Yup.string().required(
                                "A project is required"
                            ),
                        }),
                    }),
                }),
                c2a: Yup.object({
                    payment: Yup.mixed().when("paymentEnabled", {
                        is: true,
                        then: Yup.object({
                            dynamicDescriptor: Yup.string()
                                .trim()
                                .required("Please specify a descriptor"),
                            agingInfo: Yup.number()
                                .min(
                                    1,
                                    "The expiration time must be at least one minute"
                                )
                                .integer(
                                    "The expiration time must be an integer"
                                ),
                            minType: Yup.string()
                                .trim()
                                .required("Please specify a min pay type"),
                            minRate: Yup.number().positive(
                                "The min rate must be positive"
                            ),
                            // balanceReplacement: Yup.string()
                            //     .trim()
                            //     .required("Please specify a balance field"),
                            // agingReplacement: Yup.string()
                            //     .trim(),
                            // invoiceReplacement: Yup.string()
                            //     .trim(),
                        }),
                    }),
                    callMeNow: Yup.mixed().when("callMeNowEnabled", {
                        is: true,
                        then: Yup.object({
                            text: Yup.string()
                                .trim()
                                .required("A message is required"),
                            skillId: Yup.string()
                                .trim()
                                .required(
                                    "Please specify an outbound skill id"
                                ),
                        }),
                    }),
                    callMeLater: Yup.mixed().when("callMeLaterEnabled", {
                        is: true,
                        then: Yup.object({
                            text: Yup.string()
                                .trim()
                                .required("A message is required"),
                            skillId: Yup.string()
                                .trim()
                                .required(
                                    "Please specify an outbound skill id"
                                ),
                        }),
                    }),
                    callUs: Yup.mixed().when("callUsEnabled", {
                        is: true,
                        then: Yup.object({
                            text: Yup.string()
                                .trim()
                                .required("A message is required"),
                            pointOfContact: Yup.string()
                                .trim()
                                .required("Please specify a point of contact"),
                            extension: Yup.string()
                                .trim()
                                .notRequired()
                                .matches(
                                    /^[0-9,;]*$/,
                                    "Please enter a valid extension"
                                ),
                        }),
                    }),
                    optOut: Yup.mixed().when("optOutEnabled", {
                        is: true,
                        then: Yup.object({
                            text: Yup.string()
                                .trim()
                                .required("A message is required"),
                        }),
                    }),
                    optIn: Yup.mixed().when("optInEnabled", {
                        is: true,
                        then: Yup.object({
                            text: Yup.string()
                                .trim()
                                .required("A message is required"),
                        }),
                    }),
                }),
            })}
            onSubmit={async (values) => {
                setSaving(true);

                const clone = _.cloneDeep(values);

                if (!clone.c2a.callUsEnabled) delete clone.c2a.callUs;
                if (!clone.c2a.callMeLaterEnabled) delete clone.c2a.callMeLater;
                if (!clone.c2a.callMeNowEnabled) delete clone.c2a.callMeNow;
                if (!clone.c2a.paymentEnabled) delete clone.c2a.payment;
                if (!clone.c2a.optOutEnabled) delete clone.c2a.optOut;

                if (!clone.channels.smsEnabled) clone.channels.sms = newProfile.channels.sms
                if (!clone.channels.emailEnabled) clone.channels.email = newProfile.channels.email
                if (!clone.channels.pushNotificationsEnabled) clone.channels.pushnotification = newProfile.channels.pushnotification

                let successMsg = "Payment Profile Updated";
                let mutation = mutations.updatePaymentProfile;
                clone.c2a = JSON.stringify(clone.c2a);
                clone.channels = JSON.stringify(clone.channels);
                delete clone.createdAt;
                delete clone.updatedAt;

                if (id === "new") {
                    mutation = mutations.createPaymentProfile;
                    clone.tenant = userContext.tenant;
                    successMsg = "Payment Profile Created";
                    delete clone.id;
                }
                try {
                    const paymentProfileResult = await API.graphql(
                        graphqlOperation(mutation, { input: clone })
                    );
                    enqueueSnackbar(successMsg, { variant: "success" });
                    const savedProfile =
                        paymentProfileResult.data[
                        id === "new"
                            ? "createPaymentProfile"
                            : "updatePaymentProfile"
                        ];
                    if (savedProfile.id === id) {
                        savedProfile.c2a = JSON.parse(savedProfile.c2a);
                        savedProfile.channels = JSON.parse(
                            savedProfile.channels
                        );

                        setPaymentProfile(savedProfile);
                    } else {
                        history.push(savedProfile.id);
                    }
                } catch (err) {
                    console.error(err);
                }

                setSaving(false);
            }}
        >
            {(formikProps) => {
                return (
                    <>
                        {loading && (
                            <CircularProgressWithLabel
                                variant="determinate"
                                value={loadingPercent}
                                color="primary"
                            />
                        )}
                        {!loading && (
                            <form onSubmit={formikProps.handleSubmit}>
                                <Grid container spacing={2} direction="column">
                                    <Grid item>
                                        <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
                                            <Grid item>
                                                <Typography variant="h4">Payment Profile</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" spacing={2} justify="flex-end">
                                                    <Grid item>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Button onClick={handleClose} color="primary" variant="outlined" disabled={saving}>
                                                                    Close
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <CenterToggleContainer toggled={saving}>
                                                                    <CenterToggleChild>
                                                                        <Button type="submit" color="primary" variant="contained" disabled={saving}>Save</Button>
                                                                    </CenterToggleChild>
                                                                    <CenterToggleItem>
                                                                        <CircularProgress variant="indeterminate" color="primary" />
                                                                    </CenterToggleItem>
                                                                </CenterToggleContainer>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Paper style={{ padding: "15px" }}>
                                            <Grid container justifyContent="space-between" spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <Grid container direction="column">
                                                        <Grid item>
                                                            <Details formikProps={formikProps} classes={classes} availableMessageTemplates={availableMessageTemplates} skills={skills} loading={loading} />
                                                        </Grid>
                                                        <Grid item>
                                                            <StylingOptions formikProps={formikProps} classes={classes} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} md={6}>
                                                    <MessagingOptions
                                                        classes={classes}
                                                        formikProps={formikProps}
                                                        availableMessageTemplates={availableMessageTemplates}
                                                        emailMessagingSkills={emailMessagingSkills ?? []}
                                                        smsMessagingSkills={smsMessagingSkills ?? []}
                                                        loading={loading}
                                                        ivrScripts={ivrScripts}
                                                        projects={projects ?? []}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Card variant='outlined'>
                                        <CardHeader
                                            title='Actions'
                                            avatar={<DirectionsRunOutlined color='primary' />}
                                            titleTypographyProps={{ variant: 'h6', style: { marginBottom: '7px' } }}
                                        />
                                        <CardContent>
                                            <Grid item>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Switch checked={formikProps.values.c2a?.paymentEnabled ?? false}
                                                                    onChange={formikProps.handleChange}
                                                                    name="c2a.paymentEnabled"
                                                                    color="primary" />}
                                                                label="C3 Payment"
                                                            />
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <C2APayment
                                                            formikProps={formikProps}
                                                            classes={classes}
                                                            contactFields={contactFields}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Switch checked={formikProps.values.c2a?.callMeNowEnabled ?? false}
                                                                    onChange={formikProps.handleChange}
                                                                    name="c2a.callMeNowEnabled"
                                                                    color="primary"
                                                                />}
                                                                label="Call Me"
                                                            />
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <C2ACallMe
                                                            formikProps={formikProps}
                                                            classes={classes}
                                                            outboundSkills={outboundSkills}
                                                            loading={loading}
                                                            ivrScripts={ivrScripts}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Switch checked={formikProps.values.c2a?.callUsEnabled} onChange={formikProps.handleChange} name="c2a.callUsEnabled" color="primary" />}
                                                                label="Call Us"
                                                            />
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <C2ACallUs
                                                            formikProps={formikProps}
                                                            classes={classes}
                                                            contactsList={pointsOfContact}
                                                            loading={loading}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Switch checked={formikProps.values?.c2a?.callMeLaterEnabled ?? false} onChange={formikProps.handleChange} name="c2a.callMeLaterEnabled" color="primary" />}
                                                                label="Schedule Callback"
                                                            />
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <C2AScheduleCallBack
                                                            formikProps={formikProps}
                                                            classes={classes}
                                                            outboundSkills={outboundSkills}
                                                            loading={loading}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={<Switch checked={(formikProps.values?.c2a?.optOutEnabled) ?? false} onChange={formikProps.handleChange} name="c2a.optOutEnabled" color="primary" />}
                                                                label="Communication"
                                                            />
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <C2ACommunication
                                                            formikProps={formikProps}
                                                            classes={classes}
                                                            loading={loading}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </form>
                        )}
                    </>
                );
            }}
        </Formik>
    );
}

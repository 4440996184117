import React from 'react';
import { DialogContent, Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";


class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    handleConfirm = () => {
        if (this.props.onConfirm) {
            this.props.onConfirm(this.props.value);
        }
    }

    render() {
        return (
            <Dialog open={this.props.open}>
                <DialogTitle id="confirm-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={this.handleCancel} color="primary">
                        { this.props.cancelTxt ? this.props.cancelTxt : 'Cancel' }
                    </Button>
                    <Button variant="contained" onClick={this.handleConfirm} color="secondary" autoFocus>
                        { this.props.confirmTxt ? this.props.confirmTxt : 'Confirm' }
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ConfirmDialog;
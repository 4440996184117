import React, { useState, useEffect} from 'react';
import { Grid } from '@material-ui/core';
import * as request from 'superagent';
import { Auth } from 'aws-amplify';
import { Bar, Pie, HorizontalBar, Radar, Line } from 'react-chartjs-2'; 

/**
 * A bar chart visualization for displaying the aggregated sources of dispostitioned contacts 
 * @component
 */
const StaticReport = function(props) {
    const [data, setData] = useState({});
    const propsTitle = props.title;

       
    useEffect(() => {
        
        async function getData() {
            try {
                const idToken = await (await Auth.currentSession()).getIdToken();
                const tenant = idToken.payload['custom:tenant'];
                const propsField = props.field;
                let query = {};  
                const must = getMustIncludes(tenant);

                query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                    "query":{
                        "bool":{
                            must
                        }
                    },    
                    "size":0}    
                
                const searchResult = await request.post(process.env.REACT_APP_ES_SEARCH_END_POINT)
                    .type('json')
                    .send(query);
                let resData = searchResult.body['aggregations'].dispo.buckets;
                const dataValue = resData.map(obj => {return obj.doc_count});
                const labelValue = resData.map(obj => {return obj.key});
                const colorTheme = [
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 99, 132, 0.3)',
                    'rgba(54, 162, 235, 0.3)',
                    'rgba(255, 206, 86, 0.3)',
                    'rgba(75, 192, 192, 0.3)',
                    'rgba(153, 102, 255, 0.3)',
                    'rgba(255, 159, 64, 0.3)'
                ];
                setData({ datasets: [{ barPercentage: 0.6, barThickness: 30, maxBarThickness: 40, minBarLength: 2,data: dataValue, backgroundColor: colorTheme}], labels: labelValue});
                //console.log(data);

        
            } catch (err) {
                console.error(err);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title,props.filter,props.field, props.campaignId, props.agent, props.startTime, props.endTime]);

    const getMustIncludes = (tenant) =>{
        const incArray = [];
        //Always include the tenent 
        incArray.push({"match_phrase": {"tenant": tenant}});
        //include Disposition Type 
        if (props.eventType){
            incArray.push({"match_phrase": {"type": props.eventType}});
        }
        if (props.campaignId){
            incArray.push({"match_phrase": {"metaData.AdditionalInfo.campaignId": props.campaignId}});
        }
        if (props.agent){
            incArray.push({"match_phrase": {"agent": props.agent}});
        }
        incArray.push({
            "range": {
                "createdAt": {
                    "gte":  props.startTime,
                    "lte": props.endTime
                }
            }
        })
        
        return incArray;
    }

    return (
       
        
        <Grid item >
        {props.chart === 'BarChart' &&
            < Bar data={data} options={{title:{display: true, text:propsTitle},legend: false}}/>
        }
        {props.chart === 'PieChart' &&
           < Pie data={data} options={{title:{display: true, text:propsTitle},maintainAspectRatio: true}}/>
        }
        {props.chart === 'HorBarChart' &&
            < HorizontalBar data={data} options={{title:{display: true, text:propsTitle},legend: false}}/>
        }
        {props.chart === 'RadarChart' &&
            < Radar data={data} options={{title:{display: true, text:propsTitle},maintainAspectRatio: false}}/>
        }
        {props.chart === 'LineChart' &&
            < Line data={data} options={{title:{display: true, text:propsTitle},legend: false}}/>
        }
       </Grid> 
 );
}


export default StaticReport;
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Dialog, DialogTitle, DialogContent, TextField, makeStyles, Grid, List, ListItem, ListItemText, Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DialpadOutlinedIcon from '@material-ui/icons/DialpadOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import { API } from 'aws-amplify';
import { addToList, login, getScript, sendIcEmail, sendIcSMS } from '../context/InContact';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import request from 'superagent';
import gql from 'graphql-tag';
import isEmpty from '../tools/isEmpty';
import { useSnackbar } from 'notistack';
import phone from 'phone';
import ScreenpopManualCardEntryForm from './ScreenpopManualCardEntryForm';
import DOMPurify from 'dompurify';
const moment = require('moment');

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    timezoneMenuItems: {
        maxHeight: 25
    },
    typography: {
        maxWidth: 350,
        wordBreak: 'break-word',
    }
}));

const steps = {
    ProfileSelect: 0,
    SelectType: 1,
    Generating: 2,
    Preview: 3,
    Sending: 4,
    Sent: 5,
    SendFailed: 6,
    ManualEntry: 7,
    ASREntry: 8
};

const channels = {
    Email: 0,
    SMS: 1,
    // DTMF: 2,
    // ASR: 3
};

/**
 * The dialog for creating and editing a contact.
 * @component
 */
export default function Payment(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selPayment, setSelPayment] = useState();
    const [paymentProfiles, setPaymentProfiles] = useState([]);
    const [profileOpen, setProfileOpen] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState('');
    const [sendSuccess, setSendSuccess] = useState(false);
    const [smsChannel, setSmsChannel] = useState();
    const [emailChannel, setEmailChannel] = useState();
    const curPaymentRequest = useRef({});
    const generatedPaymentRequest = useRef({});
    const [contact, setContact] = useState();
    const [tenantSettings, setTenantSettings] = useState();
    const [step, setStep] = useState(steps.ProfileSelect);
    const selectedChannel = useRef('');
    const [c3ApiKey, setC3ApiKey] = useState();

    useEffect(() => {
        console.log(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sendSuccess) {
            if (props.setSuccessfulPayment) {
                props.setSuccessfulPayment(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendSuccess])

    useEffect(() => {
        async function getData() {
            console.log('queries', queries);
            const dynamoSettings = await props.awsClient.query({
                query: gql(queries.getTenantSettings),
                variables: {
                    id: props.tenant
                }
            })
            const loginSettings = dynamoSettings.data.getTenantSettings;
            setTenantSettings({ ...loginSettings });
        }
        if (props.tenant && props.awsClient) {
            getData();
        }

    }, [props.tenant, props.awsClient]);

    useEffect(() => {
        async function getData() {
            const paymentProfiles = await props.awsClient.query({
                query: gql(queries.tenantPayProfiles),
                variables: {
                    tenant: props.tenant,
                    filter: {
                        promptPaySkills: {
                            contains: props.skill
                        }
                    }
                }
            });

            const filteredProfiles = paymentProfiles.data.tenantPayProfiles.items.filter(x => !isEmpty(JSON.parse(x.c2a)?.payment));
            if (filteredProfiles.length === 0) {
                enqueueSnackbar("There are no payment profiles available. Please configure one before sending a payment.", { variant: 'info', autoHideDuration: 6000 });
                setProfileOpen(false);
            } else {
                setPaymentProfiles(filteredProfiles);
                setProfileOpen(true);
                setStep(steps.ProfileSelect);
                setSelPayment(props.balance);
            }
        }
        if (props.balance) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.balance]);

    useEffect(() => {
        if (props.contact)
            setContact(props.contact);
    }, [props.contact]);

    const handleProfileSelect = async (profile) => {
        const c2aParsedInfo = JSON.parse(profile.c2a);
        setC3ApiKey(profile.apiKey);

        if (profile.channels) {
            const channels = JSON.parse(profile.channels)

            if (channels.smsEnabled){
                setSmsChannel(channels.sms)
            }

            if (channels.emailEnabled){
                setEmailChannel(channels.email)
            }

            let minimumPayment;
            // THERE IS GOING TO BE A LOT MORE CHECKS (IF YOU ARE NOT USING A CHANNEL THEN YOU CAN'T SEND THE INFO)
            if (c2aParsedInfo.payment && c2aParsedInfo.payment.minType === 'static') {
                minimumPayment = c2aParsedInfo.payment.minRate;
            } else if (c2aParsedInfo.payment && c2aParsedInfo.payment.minType === 'percentage') {
                minimumPayment = selPayment.amount * (c2aParsedInfo.payment.minRate / 100);
            }

            if (minimumPayment){
                minimumPayment = minimumPayment.toFixed(2);
            }

            const paymentRequest = {
                vendorId: profile.vendorId,
                subjectId: contact.id,
                agentId: props.agentId,
                contactName: `${contact.firstName} ${contact.lastName}`,
                ttl: profile.ttl,
                logoUrl: profile.siteLogo,
                header1: profile.header1,
                header2: profile.header2,
                primaryColor: profile.sitePrimary,
                secondaryColor: profile.siteSecondary,
                supportEmail: profile.supportEmail,
                supportPhone: profile.supportPhone,
                c2a: {

            }
        };
        // We need to check which c2as we have and add the specific ones to paymentRequest

        let balance;
        let agingInfo;
        let invoiceId;
        
        if (c2aParsedInfo.payment?.balanceReplacement?.staticField) {
            balance = c2aParsedInfo.payment.balanceReplacement.value;
        }

        if (c2aParsedInfo.payment?.invoiceReplacement?.staticField) {
            invoiceId = c2aParsedInfo.payment.invoiceReplacement.value;
        }

        if (c2aParsedInfo.payment?.agingReplacement?.staticField) {
            agingInfo = c2aParsedInfo.payment.agingReplacement.value;
        }

        if(c2aParsedInfo.payment && c2aParsedInfo.paymentEnabled){
            paymentRequest.c2a = {
                ...paymentRequest.c2a,
                payment:{
                    invoiceId: invoiceId ?? selPayment.id,
                    amountDue: balance ?? selPayment.amount,
                    agingInfo: agingInfo ?? selPayment.age,
                    minimumPayment: minimumPayment,
                }
            }

        }
        if(c2aParsedInfo.callMe && c2aParsedInfo.callMeEnabled){
            paymentRequest.c2a = {
                ...paymentRequest.c2a,
                callMeNow:{
                    text: c2aParsedInfo.callMeNow.text,
                    phoneNumber: contact.phone,
                    metaData:JSON.stringify({
                        skillId: c2aParsedInfo.callMeNow.skillId
                    })
                }
            }
        }
        if(c2aParsedInfo.callMeLater && c2aParsedInfo.callMeLaterEnabled){
            paymentRequest.c2a = {
                ...paymentRequest.c2a,
                callMeLater: {
                    text: c2aParsedInfo.callMeLater.text,
                    phoneNumber: contact.phone,
                    metaData:JSON.stringify({
                        skillId: c2aParsedInfo.callMeLater.skillId
                    })
                }
            }
        }
        if(c2aParsedInfo.callUs && c2aParsedInfo.callUsEnabled){
            paymentRequest.c2a = {
                ...paymentRequest.c2a,
                callUs: {
                    text: c2aParsedInfo.callUs.text,
                    pointOfContact: c2aParsedInfo.callUs.pointOfContact
                }
            }
        }
        if (c2aParsedInfo.optOut && c2aParsedInfo.optOutEnabled) {
            paymentRequest.c2a = {
                ...paymentRequest.c2a = {
                    ...paymentRequest.c2a,
                    optOut: {
                        text: c2aParsedInfo.optOut.text,
                        finalize: c2aParsedInfo.optOut.finalize,
                        fields: c2aParsedInfo.optOut.fields
                    }
                }
            }
        }
        if (c2aParsedInfo.optIn && c2aParsedInfo.optInEnabled) {
            paymentRequest.c2a = {
                ...paymentRequest.c2a = {
                    ...paymentRequest.c2a,
                    optIn: {
                        text: c2aParsedInfo.optIn.text,
                        finalize: c2aParsedInfo.optIn.finalize,
                        fields: c2aParsedInfo.optIn.fields
                    }
                }
            }
        }
        if (c2aParsedInfo.fileDownload && c2aParsedInfo.fileDownloadEnabled) {
            paymentRequest.c2a = {
              ...paymentRequest.c2a,
              fileDownload: {
                text: c2aParsedInfo.fileDownload.text,
                files: c2aParsedInfo.fileDownload.files,
              },
            };
          }
          if (c2aParsedInfo.urlRedirect && c2aParsedInfo.urlRedirectEnabled) {
            paymentRequest.c2a = {
              ...paymentRequest.c2a,
              redirect: {
                text: c2aParsedInfo.urlRedirect.text,
                redirects: c2aParsedInfo.urlRedirect.redirects,
              },
            };
          }
        
          curPaymentRequest.current = paymentRequest;
          setStep(steps.SelectType);
        }
    }

    async function getMessageTemplate(templateId) {
        const getMessageTemplate = await props.awsClient.query({
            query: gql(queries.getPaymentTemplate),
            variables: {
                id: templateId
            }
        })
        return getMessageTemplate.data.getPaymentTemplate;
    }

    async function generatePaymentRequest() {
        setStep(steps.Generating);

        const paymentLink = await request.post(`${process.env.REACT_APP_PP_URL}/payment-request`)
            .set("x-api-key", c3ApiKey)
            .type("json")
            .send(curPaymentRequest.current);
        generatedPaymentRequest.current = JSON.parse(paymentLink.text);
    }

    async function generateMessage() {
        setStep(steps.Generating);
        try {
            // Get Message Template
            let messageTemplate;
            if (selectedChannel.current === channels.Email) {
                messageTemplate = await getMessageTemplate(emailChannel.messageTemplate);
            } else if (selectedChannel.current === channels.SMS) {
                messageTemplate = await getMessageTemplate(smsChannel.messageTemplate);
            }

            let message;
            message = messageTemplate.textBody;

            //Replace Strings
            message = message.replace('~FirstName~', contact.firstName);
            message = message.replace('~LastName~', contact.lastName);
            message = message.replace('~Balance~', JSON.stringify(selPayment.amount));
            if (curPaymentRequest.current.c2a.payment.minimumPayment) {
                message = message.replace('~MinAmount~', curPaymentRequest.current.c2a.payment.minimumPayment);
            }
            message = message.replace('~AgeDays~', JSON.stringify(selPayment.age));
            message = message.replace('~PayLink~', generatedPaymentRequest.current.url);

            for (const customField in contact.customFields){
                message = message.replace(`~${customField}~`, contact.customFields[customField]);
            }

            setPaymentMessage(message);

            setStep(steps.Preview);
        } catch (err) {
            console.error(err);
        }
    }

    const handleChannelSelect = async (channel) => {
        selectedChannel.current = channel;

        switch (selectedChannel.current) {
            default:
                await generatePaymentRequest();
                await generateMessage();
                break;

            // case channels.DTMF:
            //     setStep(steps.ManualEntry);
            //     break;
            // case channels.ASR:
            //     setStep(steps.ASREntry);
            //     break;

        }
    }

    async function sendPaymentLink() {
        if (selectedChannel.current === channels.Email) {
            sendEmail();
        } else if (selectedChannel.current === channels.SMS) {
            sendSms();
        }
    }

    async function sendEmail() {
        setStep(steps.Sending);
        // Send Message
        let email;

        if (contact.email) {
            email = contact.email;
        }
        if (!emailChannel.emailSkill) {
            try {
                await API.post(
                    'cdyxpromptpay',
                    '/messaging/ppay-email',
                    {
                        headers: {
                            Authorization: props.token,
                            'x-api-key': tenantSettings.apiKey
                        },
                        body: {
                            toEmail: email,
                            // toEmail: 'chandler.dalton@customerdynamics.com',
                            message: paymentMessage,
                            subject: emailChannel.subject,
                            fromEmail: emailChannel.sendingEmail
                        }
                    }
                )
                setStep(steps.Sent);
                createSendLog('email');

                setTimeout(function () {
                    setProfileOpen(false);
                }, 2000);

            } catch (err) {
                setStep(steps.SendFailed);
                setTimeout(function () {
                    setProfileOpen(false);
                }, 2000);
                console.error(err);
            }
        } else {
            //trigger script
            await login(tenantSettings, props.token);
            const scriptId = await getScript(emailChannel.emailIVRScript);
            const response = await sendIcEmail(emailChannel.emailSkill, scriptId, email, paymentMessage, emailChannel.subject, props.agentId);

            if (!response) {
                setStep(steps.SendFailed);
            } else {
                
                setStep(steps.Sent);

                createSendLog('email');

                setTimeout(function () {
                    setProfileOpen(false);
                }, 2000);
            }
        }
    }

    async function sendSms() {
        setStep(steps.Sending);
        // Send Message
        let phoneNumber

        if (contact.cell) {
            phoneNumber = phone(contact.cell)[0];
        } else if (contact.phone) {
            phoneNumber = phone(contact.phone)[0];;
        }

        if (!smsChannel.smsSkill) {
            try {
                await API.post(
                    'cdyxpromptpay',
                    '/messaging/ppay-sms',
                    {
                        headers: {
                            Authorization: props.token,
                            'x-api-key': tenantSettings.apiKey
                        },
                        body: {
                            smsType: 'Promotional',
                            message: paymentMessage,
                            phoneNumber: phoneNumber
                        }
                    }
                )
                setStep(steps.Sent);
                setSendSuccess(true);

                createSendLog('sms');

                setTimeout(function () {
                    setProfileOpen(false);
                }, 2000);

            } catch (err) {
                setStep(steps.SendFailed);
                setTimeout(function () {
                    setProfileOpen(false);
                }, 2000);
                console.error(err);
            }
        } else {
            //trigger script
            await login(tenantSettings, props.token);
            const scriptId = await getScript(smsChannel.smsIVRScript);
            const send = await sendIcSMS(smsChannel.smsSkill, scriptId, contact, paymentMessage, props.agentId);

            console.log('NiC Job ID ', send);

            if (send) {
                setStep(steps.Sent);
                setSendSuccess(true);

                createSendLog('sms');

                setTimeout(function () {
                    setProfileOpen(false);
                }, 2000);
            } else {
                setStep(steps.SendFailed);
            }

        }

    }

    const createSendLog = async (channel) => {
        try {
            const sentLog = {
                tenant: props.tenant,
                type: 'C2A',
                title: 'C2A Sent Event',
                activityLogContactId: contact.id,
                timestamp: new Date(),
                agent: props.agentId,
                contactStatus: `C2A##${moment().toISOString(true)}`,
                metaData: JSON.stringify({
                    requestId: generatedPaymentRequest.current.id,
                    channel: channel,
                    paymentRequest: curPaymentRequest.current
                })
            };

            await props.awsClient.mutate({
                mutation: gql(mutations.createActivityLog),
                variables: {
                    input: sentLog
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    const handleEmailChange = (event) => {
        const editedEmail = event.target.value;
        const tempContact = contact;
        tempContact.email = editedEmail;
        setContact({ ...tempContact });
    }

    const handleSMSChange = (event) => {
        const editedSMS = event.target.value;
        const tempContact = contact;
        tempContact.cell = editedSMS;
        setContact({ ...tempContact });
    }

    // async function handleManualEntrySubmit(data) {
    //     curPaymentRequest.current.metaData = JSON.stringify(data);
    //     await generatePaymentRequest();

    //     await InContact.postCustomData(props.sessionId, props.icContactId, `OnHold=CreditCard`, 'scriptType=DTMF', `requestId=${generatedPaymentRequest.current.id}`);

    //     setStep(steps.Sent);

    //     setTimeout(function () {
    //         setProfileOpen(false);
    //         props.handleClose();
    //     }, 2000);
    // }
    
    // async function handleASREntrySubmit(data) {
    //     curPaymentRequest.current.metaData = JSON.stringify(data);
    //     await generatePaymentRequest();

    //     await InContact.postCustomData(props.sessionId, props.icContactId, `OnHold=CreditCard`, 'scriptType=ASR', `requestId=${generatedPaymentRequest.current.id}`);

    //     setStep(steps.Sent);

    //     setTimeout(function () {
    //         setProfileOpen(false);
    //         props.handleClose();
    //     }, 2000);
    // }

    return (
        <Dialog open={profileOpen} maxWidth='sm'>
            <DialogTitle>Generate Payment</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} direction="column">
                    {(function () {
                        switch (step) {
                            default:
                            case steps.ProfileSelect:
                                return (
                                    <>
                                        <Grid item>
                                            <Typography variant="overline">Select Payment Profile</Typography>
                                        </Grid>
                                        {paymentProfiles.length > 0 &&
                                            <List>
                                                {paymentProfiles.map((profile) => (
                                                    <ListItem button onClick={() => handleProfileSelect(profile)} key={profile.name}>
                                                        <ListItemText primary={profile.name} />
                                                    </ListItem>
                                                ))}
                                            </List>}
                                        {paymentProfiles.length === 0 &&
                                            <Grid>
                                                <Typography>There are no C2A payment profiles configured</Typography>
                                                <br></br>
                                            </Grid>}
                                    </>
                                );
                            case steps.SelectType:
                                return (
                                    <>
                                        <Grid item>
                                            <Typography variant="overline">Select Medium</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                {emailChannel &&
                                                    <Grid item>
                                                        <Tooltip title="Send Email">
                                                            <IconButton onClick={() => handleChannelSelect(channels.Email)}>
                                                                <EmailOutlinedIcon color="primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>}
                                                {smsChannel &&
                                                    <Grid item>
                                                        <Tooltip title="Send SMS">
                                                            <IconButton onClick={() => handleChannelSelect(channels.SMS)}>
                                                                <SmsOutlinedIcon color="primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>}
                                                {/* <Grid item>
                                                    <Tooltip title="Manual Card Entry">
                                                        <IconButton onClick={() => handleChannelSelect(channels.DTMF)}>
                                                            <DialpadOutlinedIcon color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Voice Card Entry">
                                                        <IconButton onClick={() => handleChannelSelect(channels.ASR)}>
                                                            <RecordVoiceOverOutlinedIcon color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            case steps.Generating:
                                return (
                                    <>
                                        <Grid item>
                                            <Typography variant='h6'>Generating Payment</Typography>
                                        </Grid>
                                        <Grid item>
                                            <CircularProgress size={24} />
                                        </Grid>
                                    </>
                                );
                            case steps.Preview:
                                return (
                                    <>
                                        <Grid item>
                                            <Typography variant="overline">Message Preview</Typography>
                                        </Grid>
                                        <Grid item style={{ maxHeight: '600px', overflow: 'auto' }}>
                                            <Typography className={classes.typography} paragraph="true" variant='h6' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paymentMessage) }} />
                                        </Grid>
                                        <Grid item>
                                            {selectedChannel.current === channels.Email &&
                                                <TextField
                                                    variant="outlined"
                                                    name="email"
                                                    label="Email"
                                                    type="text"
                                                    value={contact.email}
                                                    onChange={handleEmailChange}
                                                />}
                                            {selectedChannel.current === channels.SMS &&
                                                <TextField
                                                    variant="outlined"
                                                    name="sms"
                                                    label="SMS"
                                                    type="text"
                                                    value={contact.cell}
                                                    onChange={handleSMSChange}
                                                />}
                                            <Tooltip title="Send">
                                                <IconButton onClick={sendPaymentLink}>
                                                    <SendOutlinedIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                );
                            case steps.Sending:
                                return (
                                    <>
                                        <Grid item>
                                            <Typography variant='h6'>Sending</Typography>
                                        </Grid>
                                        <Grid item>
                                            <CircularProgress size={24} />
                                        </Grid>
                                    </>
                                );
                            case steps.Sent:
                                return (
                                    <>
                                        <Grid item>
                                            <Typography variant='h6'>Sent</Typography>
                                        </Grid>
                                        <Grid item>
                                            <DoneOutlineIcon color="primary" size={24} />
                                        </Grid>
                                    </>
                                );
                            case steps.SendFailed:
                                return (
                                    <Grid item>
                                        <Typography variant='h6'>Error Sending Message...</Typography>
                                    </Grid>
                                );
                            // case steps.ManualEntry:
                            //     return (
                            //         <ScreenpopManualCardEntryForm contact={props.contact} loading={props.gettingDTMFStuffs} handleManualEntrySubmit={handleManualEntrySubmit} />
                            //     );
                            // case steps.ASREntry:
                            //     return (
                            //         <ScreenpopManualCardEntryForm contact={props.contact} loading={props.gettingDTMFStuffs} handleManualEntrySubmit={handleASREntrySubmit} />
                            //     );
                        }
                    })()}
                    <Grid item>
                        <Button variant='outlined' color='primary' onClick={() => setProfileOpen(false)}>Close</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
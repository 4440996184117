import React, { useContext, useEffect, useState } from 'react';
import { 
    makeStyles, 
    Grid,TextField, 
    InputLabel, 
    Select, 
    MenuItem, 
    FormControl, 
    ListItem, 
    ListItemText, 
    ListItemSecondaryAction,
    Divider, 
    DialogContent, 
    Dialog, DialogTitle, 
    IconButton, 
    List, 
    DialogActions, 
    Button 
} from '@material-ui/core';
import UserContext from '../context/UserContext';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import * as _ from 'lodash';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
});

const deviceTypes = [
    {name: 'Andriod', value: 'android'},
    {name: 'Ios', value: 'ios'}

]

export default function DeviceDialog(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [deviceName, setDeviceName] = useState('');

    useEffect(() => {
        if (props.devices) {
            setDevices([...props.devices]);
        }
    }, [props.devices])

    useEffect(() => {
        if (props.currentDevice) {
            setDeviceId(props.currentDevice.id);
            setDeviceType(props.currentDevice.type);
        }
    }, [props.currentDevice])

    function handleDeviceClick() {
        props.setCurrentDevice({})
        setDeviceId('');
        setDeviceType('');
        props.setOpenDevice(!props.openDevice);
    }

    const handleDeviceId = (event) => {
        setDeviceId(event.target.value);
    }

    const handleDeviceType = (event) => {
        setDeviceType(event.target.value);
    }

    async function addDevice() {
        if(props.currentDevice.id) {
            const edit = devices.find(item => item.id===props.currentDevice.id)
            edit.id = deviceId
            edit.type = deviceType
            await props.setDevices(devices);
            await props.saveDevices();
            handleDeviceClick();
            return
        }
        if(deviceId && deviceType) {
            const device = {
                id: deviceId,
                type: deviceType,
            }
            devices.push(device)
            props.devices.push(device);
            await props.setDevices(devices);
            console.log(props.devices)
            await props.saveDevices();
            handleDeviceClick();
        }
    }

    return (
        <Dialog open={props.openDevice} onClose={handleDeviceClick} maxWidth="md">
            <DialogTitle>{props.currentDevice.id ? 'Edit Device' : 'New Device'}</DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <Grid item container direction="row" alignItems="center" alignContent="center" spacing={2}>
                        <Grid item>
                            <TextField
                                label="Device Id"
                                value={deviceId}
                                onChange={handleDeviceId}
                            >
                            </TextField>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                            <InputLabel id="deviceTypes-label">Device Type</InputLabel>
                            <Select
                                labelId="deviceTypes-label"
                                value={deviceType}
                                autoWidth={true}
                                name="deviceTypes"
                                onChange={handleDeviceType}>
                                {deviceTypes.map(device =>
                                    <MenuItem value={device.value}>{device.name}</MenuItem>
                                )}
                            </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleDeviceClick} color="primary" variant="outlined">Cancel</Button>
                    <Button onClick={addDevice} color="primary" variant="contained">Save</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
import React, {useContext} from 'react';
import {Grid, Typography, TextField, makeStyles, FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel, Button} from '@material-ui/core';
import { API, Auth } from 'aws-amplify';
import UserContext from '../context/UserContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "200px",
        maxWidth: "200px"
    },
  }));


export default function CreateUser(){
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    // Store all the values for a user
    const user = {tenant: "", name: "", email: "", password: "", tenantRole: "", securityRights: "", company: "", 
    dashboardCheck: false, inventoryCheck: false, listCheck: false, configuratonCheck: false};

    const userSchema = Yup.object(
        {
            tenant: Yup.string().required("Please enter in Tenant."),
            name: Yup.string().required("Please enter in name of user."),
            email: Yup.string().email().required("Please enter an email."),
            password: Yup.string().required("Please enter in a temporary password for the user.").min(8, "Password must be at least 8 characters long."),
            tenantRole: Yup.string().required("Please enter in tenant role."),
            dashboardCheck: Yup.boolean(),
            inventoryCheck: Yup.boolean(),
            listCheck: Yup.boolean(),
            configurationCheck: Yup.boolean(),
            securityRights: Yup.string(),
            company: Yup.string().required("Please enter in company name.")
        }
    )
      
    return(
        <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={userSchema}
            onSubmit={ async (values, formikBag) => {
                const securityRights = ['0', '0', '0', '0'];
                if(values.dashboardCheck)
                    securityRights[0] = '1'
                if(values.inventoryCheck)
                    securityRights[1] = '1'
                if(values.listCheck)
                    securityRights[2] = '1'
                if(values.configuratonCheck)
                    securityRights[3] = '1'
                const securityString = securityRights.join('|');
                values.securityRights = securityString;
                try{
                 await API.post("cdyxdialer", "/admin/user", {
                     body: {
                         email: values.email,
                         name: values.name,
                         password: values.password,
                         tenant: values.tenant,
                         tenantRole: values.tenantRole,
                         securityRights: values.securityRights,
                         company: values.company
                     },
                     headers:{
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey,
                        'Content-Type': 'application/json',
                    }
                 })
                 enqueueSnackbar('User has been created.')
                 formikBag.setSubmitting(false);
                 formikBag.resetForm();
                 console.log(values);
                }catch(err){
                     enqueueSnackbar('User creation failed. ' + err.message, {variant: 'error'});
                }
                
            }

        }
        >
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid container direction="column" spacing={2}  alignItems="stretch" className={classes.root}>
                        <Grid item>
                            <Typography variant="h4">Create User</Typography>
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Tenant" 
                            name="tenant" 
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur} 
                            value={formikProps.values.tenant}
                            required
                            error={formikProps.touched.tenant && formikProps.errors.tenant}
                            helperText={formikProps.touched.tenant && formikProps.errors.tenant}
                            />
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Name" 
                            name="name" 
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur} 
                            value={formikProps.values.name}
                            required
                            error={formikProps.touched.name && formikProps.errors.name}
                            helperText={formikProps.touched.name && formikProps.errors.name}
                            />
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Email" 
                            name="email" 
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur} 
                            value={formikProps.values.email}
                            required
                            error={formikProps.touched.email && formikProps.errors.email}
                            helperText={formikProps.touched.email && formikProps.errors.email}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                            label="Password"
                            name="password"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.password}
                            required
                            error={formikProps.touched.password && formikProps.errors.password}
                            helperText={formikProps.touched.password && formikProps.errors.password}
                            />

                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Tenant Role" 
                            name="tenantRole" 
                            placeholder="Admin" 
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.tenantRole}
                            required
                            error={formikProps.touched.tenantRole && formikProps.errors.tenantRole}
                            helperText={formikProps.touched.tenantRole && formikProps.errors.tenantRole}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <FormLabel component="Legend">Assign Security Rights</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox 
                                            checked={formikProps.values.dashboardCheck} 
                                            color="primary" onChange={() => formikProps.setFieldValue("dashboardCheck", !formikProps.values.dashboardCheck)} 
                                            name="dashboards"/>}
                                        label="Dashboards"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                            checked={formikProps.values.inventoryCheck} 
                                            color="primary" onChange={() => formikProps.setFieldValue("inventoryCheck", !formikProps.values.inventoryCheck)} 
                                            name="inventoryManagement" />}
                                        label="Inventory Management"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                            checked={formikProps.values.listCheck} 
                                            color="primary" onChange={() => formikProps.setFieldValue("listCheck", !formikProps.values.listCheck)} 
                                            name="listManagement" />}
                                        label="List Management"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                            checked={formikProps.values.configuratonCheck} 
                                            color="primary" onChange={() => formikProps.setFieldValue("configuratonCheck", !formikProps.values.configuratonCheck)} 
                                            name="configuration" />}
                                        label="Configuration"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Company Name" 
                            name="company" 
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur} 
                            value={formikProps.values.company}
                            required
                            error={formikProps.touched.company && formikProps.errors.company}
                            helperText={formikProps.touched.company && formikProps.errors.company}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" name="submitBtn" color="primary" type="submit">Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            
        </Formik>
    );
}
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import UserContext from '../context/UserContext';
import { Button, Paper, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    inputField: {
        minWidth: '350px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    paper: {
        padding: '1em',
        width: '100%'
    }
}));

const DynamAppConx = function(props) {
    const classes = useStyles();
    const settings = { dynamicsTenant: '', appSecret: '', clientId:'', xTokenUrl:''};
    const userContext = useContext(UserContext);
    const [tenantId, setTenantId] = useState('');
    const [clientId, setClientId] = useState('');
    const [appSecret, setAppSecret] = useState('');
    const [onPremAltUrl, setOnPremAltUrl] = useState('');
    const [onPremAuthUrl, setOnPremAuthUrl] = useState('');
    const [onPremVersion, setOnPremVersion] = useState('');


    useEffect(() => {
        async function getData() {
            try {
                const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
                const tempAll = dynamoSettings.data.getTenantSettings;
                settings.dynamicsTenant = tempAll.e2DynamTenantId;
                settings.appSecret = tempAll.e2ClientSecret;
                settings.clientId= tempAll.e2ClientId;
                settings.xTokenUrl = tempAll.xTokenUrl;
                if (settings.clientId){
                    const appSecretEncoded = '*'.repeat(settings.appSecret.length);
                    setTenantId(settings.dynamicsTenant);
                    setClientId(settings.clientId);
                    setAppSecret(appSecretEncoded);
                    setOnPremAltUrl(tempAll.onPremAccessUrl);
                    setOnPremAuthUrl(tempAll.onPremAuthUrl);
                    setOnPremVersion(tempAll.onPremVersion);
                }

            } catch (err) {
                console.error(err);
            }
        }
        if (userContext.tenant) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    function configureConnection(){
        props.history.push('/config/dynamics-configuration');
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (

                <div className={classes.root}>
                    <Paper className={classes.paper} >
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography variant="h6">Dynamics Credentials</Typography>
                            </Grid>
                            <div hidden={!clientId}>
                                <div hidden={!onPremAltUrl}>
                                    <Grid item container direction="row" spacing={4}>
                                        <Grid item>
                                            <Typography variant="overline">On Prem Alt Url</Typography>
                                            <Typography variant="body1">{onPremAltUrl}</Typography>
                                        </Grid>
                                        <Grid item hidden={!onPremAuthUrl}>
                                            <Typography variant="overline">On Prem Auth Url</Typography>
                                            <Typography variant="body1">{onPremAuthUrl}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="overline">Dynamics Version Number</Typography>
                                            <Typography variant="body1">{onPremVersion}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid item container direction="row" spacing={4}>
                                    <Grid item hidden={!tenantId}>
                                        <Typography variant="overline">Dynamics Tenant Id</Typography>
                                        <Typography variant="body1">{tenantId}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="overline">Dynamics Client Id</Typography>
                                        <Typography variant="body1">{clientId}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="overline">Dynamics App Secret</Typography>
                                        <Typography variant="body1">{appSecret}</Typography>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <Grid item container direction="row" justify="flex-end">
                                    <Grid item justify="flex-end">
                                        <Button color="primary" variant="contained" onClick={configureConnection}>Edit</Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div hidden={clientId}>
                                <Grid item container direction="row" justify="flex-end">
                                    <Grid item>
                                        <Button color="primary" variant="outlined" onClick={configureConnection}>Configure Connection</Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </Grid>
                    </Paper>
                </div>
            )}
        </UserContext.Consumer>
    );
}

export default withRouter(DynamAppConx)
export default class PricingInfo {
    dynamoRowId;
    selectedPricingType;
    providers = [];

    constructor(dynamoRowId = "", selectedPricingType = PricingTypes.ERR) {
        this.dynamoRowId = dynamoRowId;
        this.selectedPricingType = selectedPricingType;

        this.PopulateVMDPricing();
        this.PopulateAmexPricing();
        this.PopulateAllCardsFees();
    }

    AddProvider(provider) {
        this.providers.push(provider);
    }

    /**
     * Searches for a field with a specified name and sets the value accordingly. 
     * @param fieldName The field name by which to search.
     * @param value The value to set the field to.
     * @param providerName The provider name to avoid ambiguity (some field names are identical across providers).
     */
    SetFieldValue(fieldName, value, providerName) {
        for (const provider of this.providers) {
            if (provider.name === providerName) {
                for (const column of provider.columns) {
                    for (const field of column.fields) {
                        if (field.name === fieldName) {
                            field.currentValue = value;
                            return;
                        }
                    }
                }
            }
        }
    }

    /**
     * Returns a json object representation of the PricingInfo providers.
     * Mainly used for saving the data.
     * 
     * @param asString If true, the values of each of the parent keys in the return object will be stringified. If false, only pertinent fields will be saved in the object (not stringified). Use true for saving the fees in dynamo. Use false for sending them to CMS.
     */
    CondenseFields(asString = false) {
        const result = {};
        for (const provider of this.providers) {
            result[provider.name] = {};
            if (provider.enabled) {
                for (const column of provider.columns) {
                    if (column.pricingTypeHook === this.selectedPricingType || column.pricingTypeHook === PricingTypes.InternalExempt || asString) {
                        for (const field of column.fields) {
                            result[provider.name][field.name] = field.currentValue;
                        }
                    }
                }
            }

            if(asString) {
                result[provider.name] = JSON.stringify(result[provider.name]);
            }
        }

        return result;
    }

    /**
     * Returns a simplified array representation of the providers without column specifiers.
     * Mainly used for display purposes.
     */
    PrettifyFields() {
        const result = [];
        for (let i = 0; i < this.providers.length; i++) {
            if (this.providers[i].enabled) {
                result.push({displayName: this.providers[i].displayName, fields: []});
                for (const column of this.providers[i].columns) {
                    if (column.pricingTypeHook === this.selectedPricingType || column.pricingTypeHook === PricingTypes.InternalExempt) {
                        for (const field of column.fields) {
                            let valueString;

                            if (field.decorator === "percent") {
                                valueString = field.currentValue.toFixed(2).toString() + "%";
                            } else if (field.decorator === "currency") {
                                valueString = "$" + field.currentValue.toFixed(2).toString();
                            }

                            result[result.length - 1].fields.push({displayName: field.displayName, value: valueString});
                        }
                    }
                }
            }
        }

        return result;
    }

    /**
     * Parses a JSON string containing pricing information and sets each field accordingly.
     * @param input A JSON string containing the fields and their values.
     * @param providerName The name (not display name) of the provider. For example: vmdPricing
     */
    ParseFieldJsonString(input, providerName) {
        const obj = JSON.parse(input);

        // This outer for loop is to find which provider was specified in the JSON string in the input.
        for(const provider of this.providers) {
            if (provider.name === providerName) {
                if(input === "{}") {
                    provider.enabled = false;
                } else {
                    for (const field of Object.keys(obj)) {
                        this.SetFieldValue(field, obj[field], providerName);
                    }
                }
            }
        }
    }

    /**
     * Performs validation on each available field and returns true if each one is valid. False otherwise. 
     */
    CheckAllFields() {
        let isValid = true;

        for (const provider of this.providers) {
            if (provider.enabled) {
                for (const column of provider.columns) {
                    if (column.pricingTypeHook === this.selectedPricingType || column.pricingTypeHook === PricingTypes.InternalExempt) {
                        for (const field of column.fields) {
                            isValid = isValid && field.ValidateField();
                        }
                    }
                }
            }
        }

        return isValid;
    }

    /**
     * VMD Pricing information
     */
    PopulateVMDPricing() {
        const vmdPricing = new CardProviderInfo("vmdPricing", false, "Visa, Mastercard, Discover Card");

        const vmdPricingERR = new Column("ERR Rates", PricingTypes.ERR, FieldDirections.Row);
        const vmdPricingTiered = new Column("Tiered Rates", PricingTypes.Tiered, FieldDirections.Row);
        const vmdPricingInterchange = new Column("Interchange Rates", PricingTypes.Interchange, FieldDirections.Row);
        const vmdPricingFlat = new Column("Flat Rates", PricingTypes.Flat, FieldDirections.Row);

        vmdPricingERR.AddField(new Field("Qualified Rate", "qualifiedRate", "number", "percent", 4.24, 100));
        vmdPricingERR.AddField(new Field("Downgrade Fee", "downgradeFee", "number", "currency", 3.21));
        vmdPricingERR.AddField(new Field("Per Transaction Fee", "transactionFee", "number", "currency", 14.00));
        vmdPricingERR.AddField(new Field("Merchant Fee", "merchantFee", "number", "currency", 19.95));

        vmdPricingTiered.AddField(new Field("Qualified Rate", "qualifiedRateTiered", "number", "percent", 5.75, 100));
        vmdPricingTiered.AddField(new Field("Mid-Qualified Rate", "midQualifiedRate", "number", "percent", 4.75, 100));
        vmdPricingTiered.AddField(new Field("Non-Qualified Rate", "nonQualifiedRate", "number", "percent", 4.75, 100));
        vmdPricingTiered.AddField(new Field("Per Transaction Fee", "transactionFeeTiered", "number", "currency", 10.20));
        vmdPricingTiered.AddField(new Field("Merchant Fee", "merchantFeeTiered", "number", "currency", 19.95));

        vmdPricingInterchange.AddField(new Field("Interchange Rate", "interchangeRate", "number", "percent", 6.00, 100));
        vmdPricingInterchange.AddField(new Field("Per Transaction Fee", "transactionFeeIc", "number", "currency", 5.00));
        vmdPricingInterchange.AddField(new Field("Merchant Fee", "merchantFeeIc", "number", "currency", 10.00));

        vmdPricingFlat.AddField(new Field("Flat Rate", "flatRate", "number", "percent", 6.00, 100));
        vmdPricingFlat.AddField(new Field("Per Transaction Fee", "transactionFeeFlat", "number", "currency", 6.00, 100));
        vmdPricingFlat.AddField(new Field("Merchant Fee", "merchantFeeFlat", "number", "currency", 6.00, 100));

        vmdPricing.AddColumn(vmdPricingERR);
        vmdPricing.AddColumn(vmdPricingTiered);
        vmdPricing.AddColumn(vmdPricingInterchange);
        vmdPricing.AddColumn(vmdPricingFlat);

        this.AddProvider(vmdPricing);
    }

    /**
     * Amex pricing information.
     */
    PopulateAmexPricing() {
        const amexPricing = new CardProviderInfo("amexPricing", true, "American Express");

        const amexPricingERR = new Column("ERR Rates", PricingTypes.ERR, FieldDirections.Row);
        const amexPricingTiered = new Column("Tiered Rates", PricingTypes.Tiered, FieldDirections.Row);
        const amexPricingInterchange = new Column("Interchange Rates", PricingTypes.Interchange, FieldDirections.Row);
        const amexPricingFlat = new Column("Flat Rates", PricingTypes.Flat, FieldDirections.Row);

        amexPricingERR.AddField(new Field("Qualified Rate", "qualifiedErr", "number", "percent", 4.24, 100));
        amexPricingERR.AddField(new Field("Non-Qualified Rate", "nonQualifiedErr", "number", "percent", 5.75, 100));
        amexPricingERR.AddField(new Field("Per Transaction Fee", "transactionFeeErr", "number", "currency", 4.75));

        amexPricingTiered.AddField(new Field("Qualified Rate", "qualifiedRateTiered", "number", "percent", 4.75, 100));
        amexPricingTiered.AddField(new Field("Mid-Qualified Rate", "midQualifiedRate", "number", "percent", 24.24, 100));
        amexPricingTiered.AddField(new Field("Non-Qualified Rate", "nonQualifiedRate", "number", "percent", 10.20, 100));
        amexPricingTiered.AddField(new Field("Per Transaction Fee", "transactionFeeTiered", "number", "currency", 14.00));

        amexPricingInterchange.AddField(new Field("Interchange Rate", "interchangeRateIC", "number", "percent", 24.24, 100));
        amexPricingInterchange.AddField(new Field("Per Transaction Fee", "transactionFeeIC", "number", "currency", 24.24, 100));

        amexPricingFlat.AddField(new Field("Interchange Rate", "interchangeRateFlat", "number", "percent", 24.24, 100));
        amexPricingFlat.AddField(new Field("Per Transaction Fee", "transactionFeeFlat", "number", "currency", 24.24, 100));

        amexPricing.AddColumn(amexPricingERR);
        amexPricing.AddColumn(amexPricingTiered);
        amexPricing.AddColumn(amexPricingInterchange);
        amexPricing.AddColumn(amexPricingFlat);

        this.AddProvider(amexPricing);
    }

    /**
     * Misc. pricing information.
     */
    PopulateAllCardsFees() {
        const fees = new CardProviderInfo("fees", false, "Fees (All Cards)");

        const feesOneTime = new Column("One Time Fees", PricingTypes.InternalExempt, FieldDirections.Column);
        const feesAnnual = new Column("Annual Fees", PricingTypes.InternalExempt, FieldDirections.Column);
        const feesMonthly = new Column("Monthly Fees", PricingTypes.InternalExempt, FieldDirections.Column);

        feesOneTime.AddField(new Field("Account Setup Fee", "accountSetupFee", "number", "currency", 25.00));
        
        feesAnnual.AddField(new Field("Annual Membership Fee", "annualMembershipFee", "number", "currency", 20.00));

        feesMonthly.AddField(new Field("Minimum Monthly Fee", "minimumFee", "number", "currency", 10.99));
        feesMonthly.AddField(new Field("Debit Access Fee", "monthlyDebitAccessFee", "number", "currency", 13));
        feesMonthly.AddField(new Field("Online Reporting Fee", "onlineReportingAccessFee", "number", "currency", 10.00));
        feesMonthly.AddField(new Field("Monthly Statement Fee", "monthlyStatementFee", "number", "currency", 5.00));

        fees.AddColumn(feesOneTime);
        fees.AddColumn(feesAnnual);
        fees.AddColumn(feesMonthly);

        this.AddProvider(fees);
    }
}

class CardProviderInfo {
    enabled = true;
    displayName;
    name;
    canBeDisabled;
    columns = [];
    accordionExpanded = true;
    
    constructor(name, canBeDisabled, displayName) {
        this.name = name;
        this.canBeDisabled = canBeDisabled;
        this.displayName = displayName;
    }

    AddColumn(column) {
        this.columns.push(column);
    }
}

class Column {
    displayName;
    fields = [];
    pricingTypeHook;
    fieldDirection;

    constructor(displayName, pricingTypeHook, fieldDirection) {
        this.displayName = displayName;
        this.pricingTypeHook = pricingTypeHook;
        this.fieldDirection = fieldDirection;
    }

    AddField(field) {
        this.fields.push(field);
    }
}

class Field {
    displayName;
    name;
    inputType;
    decorator;
    min;
    max;
    currentValue;
    valid;
    errorText = "";

    constructor(displayName, name, inputType, decorator, min, max = Infinity) {
        this.displayName = displayName;
        this.name = name;
        this.inputType = inputType;
        this.decorator = decorator;
        this.min = min;
        this.max = max;
        this.currentValue = min;
        this.valid = true;
    }

    /**
     * Performs validation on a field.
     */
    ValidateField() {
        this.valid = true;

        if(this.currentValue < this.min) {
            this.errorText = `Minimum allowed value is ${this.min}`;
            this.valid = false;
        } else if (this.currentValue > this.max) {
            this.errorText = `Maximum allowed value is ${this.max}`;
            this.valid = false;
        }

        return this.valid;
    }

    /**
     * Sets a new field value and validates it.
     * @param {number} newValue The new value to set the field to.
     */
    ValidateAndSetFieldValue(newValue) {
        this.currentValue = newValue;
        this.ValidateField();
    }

    /**
     * Truncates a field to 2 decimal places (usually called when the field is blurred).
     */
    TruncateValue() {
        this.currentValue = Math.floor(this.currentValue * 100) / 100;
    }
}

const PricingTypes = {
    ERR: "ERR",
    Tiered: "Tiered",
    Interchange: "Interchange",
    Flat: "Flat",
    InternalExempt: "InternalExempt"
}

const FieldDirections = {
    Row: "row",
    Column: "column"
}
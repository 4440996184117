import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Polar } from 'react-chartjs-2';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

/**
 * A pie chart visualization for displaying the aggregated sources of dispostitioned contacts 
 * @component
 */
const PieChart = function(props) {
    const [data, setData] = useState({});
    const propsTitle = props.title;
   
    
    useEffect(() => {
        
        async function getData() {
            try {
                const idToken = await (await Auth.currentSession()).getIdToken();
                const tenant = idToken.payload['custom:tenant'];
                const propsField = props.field;
                const propsFilter = props.filter;
                let dataValue;
                let labelValue;
                let query = {};      

                const must = getMustIncludes(tenant);
               // console.log(must);
                if (props.field === 'requestDispo'){
                    const openedQuery = getLabelQuery('PromptPay Opened Event');
                    const expiredQuery = getLabelQuery('PromptPay Expired Event');
                    const acceptedQuery = 
                        {
                            "aggs": {
                                "dispo": {
                                    "terms": {
                                        "field": "metaData.transaction.id.keyword"
                                    }
                                }
                            },
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "match_phrase": {
                                                "type": "PromptPay"
                                            }
                                        }
                                    ]
                                }
                            },
                            "size": 0
                        }
                    
                    const openedJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(openedQuery)
                    }));
                    const expiredJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(expiredQuery)
                    }));  
                    const acceptedJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(acceptedQuery)
                    }));

                    const openedResult = JSON.parse(openedJSON.data.esQuery);
                    const expiredResult = JSON.parse(expiredJSON.data.esQuery);
                    const acceptedResult = JSON.parse(acceptedJSON.data.esQuery);

                    labelValue = ['Opened', 'Expired', 'Payment Successful'];
                    dataValue = [openedResult.aggregations.dispo.buckets.length, expiredResult.aggregations.dispo.buckets.length, acceptedResult.aggregations.dispo.buckets.length];

                    
                } else {
                    if (propsFilter){
                        query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                        "query":{
                            "bool":{
                                must,
                                "must_not":{
                                    "term": {[propsField]:propsFilter}
                                }
                            }
                        },
                        "size":0}
                    }
                    else{
                        query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                        "query":{
                            "bool":{
                                must
                            }
                        },    
                        "size":0}    
                    }               
                    const resultJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(query)
                    }));
                    const queryResult = JSON.parse(resultJSON.data.esQuery);
                    let resData = queryResult['aggregations'].dispo.buckets;
                    dataValue = resData.map(obj => {return obj.doc_count});
                    labelValue = resData.map(obj => {return obj.key});
                }          
                
                const colorTheme = [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 99, 132, 0.3)',
                    'rgba(54, 162, 235, 0.3)',
                    'rgba(255, 206, 86, 0.3)',
                    'rgba(75, 192, 192, 0.3)',
                    'rgba(153, 102, 255, 0.3)',
                    'rgba(255, 159, 64, 0.3)'
                ];
                setData({ datasets: [{ data: dataValue, backgroundColor: colorTheme}], labels: labelValue});
                //console.log(data);

        
            } catch (err) {
                console.error(err);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title, props.model, props.filter,props.field, props.campaignId, props.agent, props.startTime, props.endTime]);

    const getMustIncludes = (tenant) =>{
        const incArray = [];
        //Always include the tenent 
        incArray.push({"term": {"tenant.keyword": tenant}});
        //include Disposition Type 
        if (props.eventType){
            incArray.push({"match_phrase": {"type": props.eventType}});
        }
        if (props.campaignId){
            incArray.push({"match_phrase": {"metaData.AdditionalInfo.campaignId": props.campaignId}});
        }
        if (props.agent){
            incArray.push({"match_phrase": {"agent": props.agent}});
        }
        incArray.push({
            "range": {
                "createdAt": {
                    "gte":  props.startTime,
                    "lte": props.endTime
                }
            }
        })
        
        
        return incArray;
    }

    const getLabelQuery = (title) => {
        const query = {
            "aggs": {
                "dispo": {
                    "terms": {
                        "field": "metaData.paymentRequest.id.keyword",
                        "size": 2147483647
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "title": title
                            }
                        }
                    ]
                }
            },
            "size": 0
        }
        
        return query;
    }

    

     

    return (
        <Grid item >
            < Polar data={data} options={{title:{display: true, text:propsTitle},maintainAspectRatio: true}}/>
        </Grid>
 );
}


export default PieChart;
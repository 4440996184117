/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

export default function Help(props)
{
    const [help, setHelp] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setHelp(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleHelp = () => {
        setHelp(!help);
        props.onChange(!help)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={help}
                        onChange={handleHelp}
                        name="help"
                        color="primary"
                        />
                    }
                    label="Help"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Allow help access">
                        <HelpOutlineOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import MUIRichTextEditor from 'mui-rte';
import * as mutations from '../graphql/mutations';
import { makeStyles, Paper, Grid, Typography, TextField, Button, DialogContent, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ContentState, convertToRaw } from 'draft-js';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

import gql from 'graphql-tag';

import awsconfig from '../aws-exports';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
});

/**
 * Add notes to the contact's activity log
 * @component
 * @example
 * const contactId = '0266723e-f257-45fa-b526-2c3fd7ad628f'
 * return (
 *  <ContactNote contactId={contactId} />
 * )
 */
export default function PopUpNote(props) {
    const classes = useStyles();
    const emptyBody = JSON.stringify(convertToRaw(ContentState.createFromText('')));
    const { enqueueSnackbar } = useSnackbar();

    /** @var {string} ContactNote~title The title of the note */
    const [title, setTitle] = useState('');
    /** @var {string} ContactNote~body The body of the note. Uses MUIRichTextEditor. */
    const [body, setBody] = useState(emptyBody);

    const rteRef = useRef();
    const bodyRef = useRef('');
    const validation = /\w/;

    /**
     * Handle the save event on the 'mui-rte' component.
     * @param {any} data The 'mui-rte' onSave event object.
     */
    const handleRteSave = (data) => {
        bodyRef.current = data;
    }

    const handleCancel = () => {
        if (props.cancel) {
            props.cancel();
        }
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle id="dialog-title">Create New Note</DialogTitle>
            <DialogContent>
                <Paper className={classes.paper} elevation={2}>
                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item>
                            <Typography variant="h6">Note</Typography>
                            <Formik
                                initialValues={{ title: title }}
                                enableReinitialize={true}
                                onSubmit={async (values, formikBag) => {
                                    rteRef.current.save();
                                    let bodyText = JSON.parse(bodyRef.current).blocks[0].text;//Grab the text

                                    //Perform validation
                                    if(!validation.test(bodyText)) {
                                        enqueueSnackbar('Note body cannot be empty.', { variant: 'error' });
                                    }
                                    else if (!validation.test(values.title)) {
                                        enqueueSnackbar('Note title cannot be empty.', { variant: 'error' });
                                    }
                                    else {//If all is well, proceed
                                        const note = { ...values };
                                        for (const key in note) {
                                            note[key] = note[key] || null;
                                        }
                                        note.type = 'Note';
                                        note.body = bodyRef.current;
                                        note.tenant = props.tenant;
                                        note.activityLogContactId = props.contactId;
                                        note.timestamp = new Date();
                                        note.contactStatus = null;
                                        try {
                                            const client = new AWSAppSyncClient({
                                                url: awsconfig.aws_appsync_graphqlEndpoint,
                                                region: awsconfig.aws_appsync_region,
                                                auth: {
                                                    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                                                    jwtToken: props.token
                                                },
                                                disableOffline: true
                                            });
                                            await client.mutate(
                                                {
                                                    mutation: gql`${mutations.createActivityLog}`,
                                                    variables: {
                                                        input: note
                                                    }
                                                }
                                            );
                                            bodyRef.current = '';
                                            setTitle('');
                                            enqueueSnackbar('Note Created');
                                            formikBag.setSubmitting(false);
                                            formikBag.resetForm();
                                            setBody(undefined);
                                            //close the component if opened as a dialog
                                            props.noteCreated();
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    }
                                }}
                            >
                                {formikProps => (
                                    <form onSubmit={formikProps.handleSubmit}>
                                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                            <TextField
                                                name="title"
                                                margin="dense"
                                                label="Title"
                                                type="text"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.title}
                                            />
                                            <MUIRichTextEditor
                                                name="body"
                                                margin="dense"
                                                label="Body"
                                                type="text"
                                                multiline
                                                rows="4"
                                                ref={rteRef}
                                                value={body}
                                                onSave={handleRteSave}
                                                controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'numberList', 'bulletList']}
                                            />
                                            <Button type="submit" variant="contained" color="primary">Add</Button>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} color="primary">
                    {'Cancel'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
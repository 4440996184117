/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';

export default function ChatTransfer(props)
{
    const [chatTransfer, setChatTransfer] = useState(false);

    /** Set disposition to value being passed through props */
    useEffect(() => {
        setChatTransfer(props.value);
    },[props.value])

    // Handle hold checkbox change
    const handleChatTransfer = () => {
        setChatTransfer(!chatTransfer);
        props.onChange(!chatTransfer);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={chatTransfer}
                        onChange={handleChatTransfer}
                        name="chatTransfer"
                        color="primary"
                        />
                    }
                    label="Transfer"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission for chat transfers">
                        <ForwardOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>

            </Grid>
            
        </div>
    );
}


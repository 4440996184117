import React, { useEffect, useContext, useState } from 'react';
import { makeStyles, Typography, Button, Grid, TablePagination } from "@material-ui/core";
import { EditOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { Link, useHistory } from "react-router-dom";
import ConfirmDialog from "../components/ConfirmDialog";
import MaterialTable from 'material-table';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}));

export default function PaymentProfiles() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const history = useHistory();
    const [profiles, setProfiles] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [templatesLookupTable, setTemplatesLookupTable] = useState([]);

    useEffect(() => {
        async function getData() {
            const paymentTemplates = await API.graphql(graphqlOperation(queries.listPaymentTemplates));
            if (paymentTemplates && paymentTemplates.data.listPaymentTemplates) {
                setTemplatesLookupTable(paymentTemplates.data.listPaymentTemplates.items.reduce((lookupTable, template) => Object.assign(lookupTable, { [template.id]: template.name }), {}))
            }

            const savedPaymentProfiles = await API.graphql(graphqlOperation(queries.listPaymentProfiles));
            if (savedPaymentProfiles && savedPaymentProfiles.data) {
                setProfiles(savedPaymentProfiles.data.listPaymentProfiles.items);
            }
        }
        if (userContext.tenant) {
            getData();
        }
    }, [userContext.tenant]);

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    const confirmDelete = (id) => {
        setToDelete(id);
        setConfirmOpen(true);
    }

    async function handleDelete() {
        setConfirmOpen(false);

        try {
            const deleteResult = await API.graphql(graphqlOperation(mutations.deletePaymentProfile, { input: { id: toDelete } }));

            //if profile is sucessfull in deleting remove it from the current list.
            if (deleteResult.data) {
                const deletedProfile = deleteResult.data.deletePaymentProfile;
                const curProfiles = [...profiles];
                _.remove(curProfiles, function (e) {
                    return e.id === deletedProfile.id;
                });
                setProfiles([...curProfiles]);
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    function handleCancelDelete() {
        setConfirmOpen(false);
    }

    return (
        <div className={classes.root}>
            <Grid item container direction="row" justify="space-between" alignItems="flex-end">
                <Typography variant="h4">C2A Profiles</Typography>
                <Button variant="contained" color="primary" component={Link} to="/ppay/PaymentProfile/new">+ C2A Profile</Button>
            </Grid>
            <br></br>
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                <Grid item>
                    <MaterialTable
                        title=""
                        data={profiles}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Template', field: 'templateId', lookup: templatesLookupTable },
                            {
                                title: "Logo",
                                field: "siteLogo",
                                render: (rowData) => (
                                    <img
                                        src={rowData.siteLogo}
                                        alt={rowData.siteLogo}
                                        style={{ maxHeight: "100px", maxWidth: "200px" }}
                                    />
                                ),
                            },
                            {
                                title: 'Primary Color', field: 'sitePrimary', render: rowData => <div style={{
                                    backgroundColor: rowData.sitePrimary,
                                    borderRadius: 3, borderStyle: 'solid',
                                    borderWidth: 'thin'
                                }}>{'\xa0'}</div>
                            },
                            {
                                title: 'Secondary Color', field: 'siteSecondary', render: rowData => <div style={{
                                    backgroundColor: rowData.siteSecondary,
                                    borderRadius: 3,
                                    borderStyle: 'solid', borderWidth: 'thin'
                                }}>{'\xa0'}</div>
                            },
                            { title: 'Minimum Type', field: 'minType', render: rowData => <div>{_.capitalize(rowData.minType)}</div> },
                            {
                                title: 'Minimum Rate', field: 'minRate', render: rowData => {
                                    if (rowData.minType === 'static') {
                                        return <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.minRate)}</div>
                                    }
                                    else if (rowData.minType === 'percentage') {
                                        return <div>{`${rowData.minRate}%`}</div>
                                    }
                                    else return <i>{'Not required'}</i>;
                                }
                            },
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10
                        }}
                        components={{
                            Pagination: licensePaging
                        }}
                        actions={[
                            {
                                icon: () => (<EditOutlined color="primary" />),
                                tooltip: 'Edit Profile',
                                onClick: (event, rowData) => {
                                    history.push(`/ppay/PaymentProfile/${rowData.id}`);
                                }
                            },
                            {
                                icon: () => (<DeleteOutlineOutlined color="primary" />),
                                tooltip: 'Delete Profile',
                                onClick: (event, rowData) => {
                                    confirmDelete(rowData.id)
                                }
                            }
                        ]}
                        onRowClick={(event, rowData) => {
                            history.push(`/ppay/PaymentProfile/${rowData.id}`);
                        }}
                    />
                </Grid>
            </Grid>
            <ConfirmDialog
                open={confirmOpen}
                value={toDelete}
                onConfirm={handleDelete}
                onCancel={handleCancelDelete}
                confirmTxt="Yes">
                <Typography>This profile will be permanently deleted.</Typography>
            </ConfirmDialog>
        </div>
    )
}
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://ikctoit5hrgbjkqlpitjwuprze.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "cdyxdialer",
            "endpoint": "https://a1i2eyi90i.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "cdyxmaxpromptpay",
            "endpoint": "https://1tdug367fg.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "cdyxpreprocess",
            "endpoint": "https://a3n2es2qnf.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "cdyxpromptpay",
            "endpoint": "https://67b652vj94.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "e2cif",
            "endpoint": "https://f83wsie2j7.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "e2postcontact",
            "endpoint": "https://se94plaalc.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "importapi",
            "endpoint": "https://m08h1xg9li.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        },
        {
            "name": "veerabradely",
            "endpoint": "https://i9nguvyff7.execute-api.ap-southeast-2.amazonaws.com/auprod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:96e451be-fbcd-46f9-b7ad-fa0e9dede074",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_yiluuYkgf",
    "aws_user_pools_web_client_id": "6c2te91v494j0hqr33h5k77t54",
    "oauth": {},
    "aws_content_delivery_bucket": "cdyxlm-auprod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d3gfbx4ocbh438.cloudfront.net",
    "aws_user_files_s3_bucket": "beastimportfiles110237-auprod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;

import React from 'react';
import {
    Typography,
    withStyles,
    Grid,
} from '@material-ui/core';
import ContactList from '../components/ContactList'
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import ContactDialog from '../components/ContactDialog';
import UserContext from '../context/UserContext';

const styles = {
    table: {
        minWidth: 650
    }
};

/**
 * The contacts list page. It provides searching and real time content updates.
 * @category Pages
 * @component
 */
class Contacts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmOpen: false,
            toDelete: undefined
        }
        this.classes = props.classes;
        this.confirm = props.confirm;
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ tenant }) => (
                    <div>
                        <Grid container
                            direction="column"
                            justify="flex-start"
                            alignItems="stretch"
                            spacing={2}
                        >
                            <Grid item container direction="row" justify="space-between" alignItems="flex-start">
                                <Grid item>
                                    <Typography variant="h4">
                                        Contacts
                                </Typography>
                                </Grid>
                                <Grid item>
                                    <Connect mutation={graphqlOperation(mutations.createContact)}>
                                        {({ mutation }) => (
                                            <ContactDialog createContact={mutation} />
                                        )}
                                    </Connect>
                                </Grid>
                            </Grid>
                            <ContactList />
                        </Grid>
                    </div>
                )}
            </UserContext.Consumer>
        )
    }

}

export default withStyles(styles)(Contacts);
import React, { useContext } from 'react';
import { Route} from 'react-router-dom';
import UserContext from '../context/UserContext';

export default function AdminRoute({ children, ...rest }) {
    const userContext = useContext(UserContext);



    return (
        <Route
            {...rest}
            render={() =>
                userContext.tenantRole === 'SuperAdmin' ? (
                    children
                ): (

                        children
                    /* <Redirect to={{ pathname: '/', state: { from: location }}} /> */ 
                )
            }
/>
    )
}
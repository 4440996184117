import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, ListItemIcon, IconButton } from '@material-ui/core';
import { SpeedDial } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { LowPriority, SupervisorAccountTwoTone, PaymentOutlined, PersonOutlined, HelpOutlined, SettingsOutlined, ExitToAppOutlined, EmailOutlined, SupervisorAccountOutlined, ChromeReaderModeOutlined } from '@material-ui/icons';
import { Route, Switch, NavLink, useHistory, useLocation, Link } from 'react-router-dom';
import ConfigLayout from './ConfigLayout';
import MiddleWareLayout from './MiddleWareLayout';
import LMLayout from './LMLayout';
import { UserContext, loadContext } from '../context/UserContext';
import { login, getIcSkills } from '../context/InContact';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from "@aws-amplify/ui-react";
import AdminRoute from '../components/AdminRoute';
import AdminLayout from './AdminLayout';
import PPayLayout from './PPayLayout';
import ContactRO from '../pages/ContactRO';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: theme.zIndex.drawer + 1,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
		paddingTop: '65px'
	},
	title: {
		flexGrow: 1,
		padding: theme.spacing(1),
		textAlign: 'left',
		color: 'white',
		paddingLeft: theme.spacing(7)
	},
	primaryButton: {
		color: 'white'
	},
	fobCurrent: {
		color: 'white',
		backgroundColor: theme.palette.secondary.main
	},
	speedDial: {
		position: 'absolute',
		top: theme.spacing(.5),
		left: theme.spacing(1)
	}
}));

/**
 * This is the component in charge of Changing display between dashboards, Inventory Managment, and List Managment
 * @category Layouts
 * @component
 * @param {any} props The React props object passed into components.
 */
function MainLayout(props) {
	//const { container } = props;
	const classes = useStyles();

	//const [mobileOpen, setMobileOpen] = React.useState(false);

	/** @var {string} MainLayout~tenant The tenant id for the current User. Managed in a React context */
	const [userContext, setUserContext] = React.useState({
		tenant: '',
		tenantRole: '',
		company: '',
		name: '',
		apiKey: '',
		licenses: [],
		icSkills: [],
		customFields: [],
        queryFields: [],
		rights: []
	});

	const [open, setOpen] = React.useState(false);
	const [helpBtn, setHelpBtn] = React.useState(null);
	const [userBtn, setUserBtn] = React.useState(null);
	const [actions, setActions] = React.useState([
	]);
	const history = useHistory();
	const location = useLocation();


	useEffect(() => {
		async function getData() {
			try {

				const idToken = await (await Auth.currentSession()).getIdToken();
				const { tenantSettings, apiKey, customFields, queryFields, licenses } = await loadContext(idToken.payload['custom:tenant']);
				if (!tenantSettings.icBU) {
					history.push('/config');
				}
				const rights = idToken.payload['custom:securityRights'].split('|');

				let context = {
					tenant: idToken.payload['custom:tenant'],
					tenantRole: idToken.payload['custom:tenantRole'],
					company: idToken.payload['custom:company'],
					name: idToken.payload['name'],
					apiKey: apiKey,
					icSkills: [],
					customFields: customFields,
                    queryFields: queryFields,
					licenses: licenses,
					rights: rights
				};

				setUserContext(context);

				getSkills(tenantSettings, context);

			} catch (err) {
				console.error(err);
			}
		}
		getData();
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Only add those items enable by the license
	 */

	useEffect(() => {
		const securedActions = [...actions];
		if (userContext.licenses && userContext.licenses.includes('ListManager')) {
			securedActions.push({ icon: <LowPriority />, name: 'ReachCX', linkTo: '/' });
		}
		if (userContext.licenses && userContext.licenses.includes('MaxCIF')) {
			securedActions.push({ icon: <ChromeReaderModeOutlined />, name: 'E2', linkTo: '/e2/Dashboard' });
		}
		if (userContext.licenses && userContext.licenses.includes('PromptPay')) {
			securedActions.push({ icon: <PaymentOutlined />, name: 'C3', linkTo: '/ppay/Dashboard' });
		}
		if (userContext.tenantRole === 'SuperAdmin') {
			securedActions.push({ icon: <SupervisorAccountTwoTone />, name: 'Admin', linkTo: '/admin' });
		}
		if (userContext.licenses && userContext.licenses.includes('MaxCIF') && !userContext.licenses.includes('ListManager')) {
			history.push('/e2');
		}
		setActions(securedActions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.licenses])

	async function getSkills(settings, context) {
		await login(settings);
		const icSkills = await getIcSkills();
		setUserContext({
			...context,
			icSkills: icSkills
		});
	}

	/**
	 * Handle speedDial to close state.
	 */
	const handleClose = (event, reason) => {
		if (reason === 'mouseLeave' || reason === 'toggle') {
			setOpen(false);
		}
	};

	/**
	 * Handle speedDial to close state.
	 */
	const handleOpen = (event, reason) => {
		if (reason === 'mouseEnter' || reason === 'toggle') {
			setOpen(true);
		}
	};

	const openHelp = () => {
		window.open(`${location.pathname}.pdf`);

	}



	const handleOpenHelpMenu = (event) => {
		setHelpBtn(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		setUserBtn(event.currentTarget);
	}

	const handleCloseHelp = () => {
		setHelpBtn(null);
	}

	const handleCloseUser = () => {
		setUserBtn(null);
	}

	const handleContactUs = () => {
		window.location.href = 'mailto:support@customerDynamics.com';
	}


	const handleSignOut = (event) => {
		setUserBtn(null);
		Auth.signOut()
			.then(() => {
				history.go('/');
			})
			.catch(err => console.log(err));
	}

	const fobClass = (linkTo) => {
		if (linkTo === '/' && (!location.pathname.startsWith('/e2')
						   && !location.pathname.startsWith('/admin')
						   && !location.pathname.startsWith('/ppay'))) {
			return classes.fobCurrent;
		} else if (linkTo.startsWith('/e2') && location.pathname.startsWith('/e2')) {
			return classes.fobCurrent;
		} else if (linkTo.startsWith('/ppay') && location.pathname.startsWith('/ppay')) {
			return classes.fobCurrent;
		} else if (linkTo.startsWith('/admin') && location.pathname.startsWith('/admin')) {
			return classes.fobCurrent;
		} else {
			return undefined;
		}
	}

	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					{actions.length > 1 &&
						<SpeedDial
							ariaLabel="Main Menu"
							className={classes.speedDial}
							icon={<img src="/icon.png" alt="Customer Dynamics" />}
							onOpen={handleOpen}
							onClose={handleClose}
							open={open}
							direction={"right"}
						>
							{actions.length > 1 && actions.map(action => (
								<SpeedDialAction
									key={action.name}
									icon={action.icon}
									tooltipTitle={action.name}
									component={NavLink} to={action.linkTo}
									onClick={handleClose(action)}
									className={fobClass(action.linkTo)}
									hidden={!open}
								/>
							))}
						</SpeedDial>
					}
					{actions.length === 1 &&
						<IconButton aria-label="home" component={Link} to={actions[0].linkTo}>
							<img src="/icon.png" alt="Customer Dynamics" />
						</IconButton>
					}
					<Typography hidden={!open} className={classes.title} variant="h6" noWrap >
					</Typography>
					<Typography hidden={open} className={classes.title} variant="h6" noWrap >
						{!location.pathname.startsWith('/e2') && !location.pathname.startsWith('/ppay') && !location.pathname.startsWith('/admin') && `ReachCX`}
						{location.pathname.startsWith('/e2') && `e2`}
						{location.pathname.startsWith('/ppay') && `C3`}
						{location.pathname.startsWith('/admin') && `Admin`}
					</Typography>

					<Typography variant="button" noWrap >
						{userContext.company}
					</Typography>
					{userContext.rights[3] === '1' &&
						<IconButton aria-haspopup="true" className={classes.primaryButton} component={Link} to={`/config`}><SettingsOutlined /></IconButton>
					}
					<IconButton aria-haspopup="true" className={classes.primaryButton} onClick={handleOpenHelpMenu}><HelpOutlined /></IconButton>
					<Menu
						id="helpMenu"
						anchorEl={helpBtn}
						keepMounted
						open={Boolean(helpBtn)}
						onClose={handleCloseHelp}
					>
						<MenuItem onClick={handleContactUs}>
							<ListItemIcon><EmailOutlined /></ListItemIcon>
							Contact Support
						</MenuItem>
						<MenuItem onClick={openHelp}>
							<ListItemIcon><HelpOutlined /></ListItemIcon>
							Help Documentation
						</MenuItem>
					</Menu>
					<Button variant="text" aria-haspopup="true" className={classes.primaryButton} onClick={handleOpenUserMenu}>{userContext.name}&nbsp;<PersonOutlined /></Button>
					<Menu
						id="userMenu"
						anchorEl={userBtn}
						keepMounted
						open={Boolean(userBtn)}
						onClose={handleCloseUser}
					>
						<MenuItem onClick={handleSignOut}>
							<ListItemIcon><ExitToAppOutlined /></ListItemIcon>
							Logout
						</MenuItem>
						{userContext.tenantRole === 'SuperAdmin' &&
							<MenuItem component={NavLink} to={'/admin'} onClick={handleCloseUser}>
								<ListItemIcon><SupervisorAccountOutlined /></ListItemIcon>
								Admin
							</MenuItem>
						}
					</Menu>
				</Toolbar>
			</AppBar>
			<main className={classes.content}>
				{/* <UserHelp open={help} cancel={closeHelp}/>  */}
				<UserContext.Provider value={userContext}>
					<Switch>
						<Route path="/config">
							<ConfigLayout />
						</Route>
						<Route path="/e2">
							<MiddleWareLayout />
						</Route>
						<Route path="/ppay">
							<PPayLayout />
						</Route>
						<Route path="/screenpop">
							<ContactRO />
						</Route>
						<AdminRoute path="/admin">
							<AdminLayout />
						</AdminRoute>
						<Route path="/">
							<LMLayout />
						</Route>
					</Switch>
				</UserContext.Provider>
			</main>
		</div>
	);
}

MainLayout.propTypes = {
	container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element)
};

const signUpConfig = {
	header: 'Create a Smart Dialer Account',
	hiddenDefaults: [
		'phone_number',
		'username',
		'email',
		'password'
	],
	signUpFields: [
		{
			label: 'Email',
			key: 'email',
			required: true,
			displayOrder: 2,
			type: 'string'
		},
		{
			label: 'Name',
			key: 'name',
			required: true,
			displayOrder: 1,
			type: 'string'
		},
		{
			label: 'Password',
			key: 'password',
			required: true,
			displayOrder: 3,
			type: 'password'
		}
	]
};

export default withAuthenticator(MainLayout, { includeGreetings: false, signUpConfig, usernameAttributes: 'email' });
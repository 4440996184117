import React, { useContext, useEffect, useState, useRef } from 'react';
import { FieldArray, Formik } from 'formik';
import userContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import { TextField, Grid, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, makeStyles, FormHelperText, IconButton, Tooltip, LinearProgress, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DoneIcon from '@material-ui/icons/Done';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ClearIcon from '@material-ui/icons/Clear';
import theme from '../theme.js';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack'
import { getPointsOfContact } from '../context/InContact';

const useStyles = makeStyles(({
    longSelect: {
        minWidth: '300px',
        maxWidth: '300px'
    },
    regularSelect: {
        minWidth: '200px',
        maxWidth: '200px'
    }
}))

export default function ThirdPartySMS() {
    const { enqueueSnackbar } = useSnackbar();
    const tenant = useContext(userContext);
    const classes = useStyles();
    const [pointsOfContact, setPointsOfContact] = useState([]);
    const [mappingInfo, setMappingInfo] = useState({ enabled: false, mappings: [] });
    const [verifyingFeedback, setVerifyingFeedback] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const saveButtonRef = useRef();
    const smsProviders = [
        { dynamoName: "Twilio", displayName: "Twilio" },
        { dynamoName: "TwilioWhatsApp", displayName: "WhatsApp via Twilio" },
        { dynamoName: "Textel", displayName: "Textel" },
        { dynamoName: "TextelFoundation", displayName: "Textel Foundation" },
        { dynamoName: "MMS", displayName: "MMS" }
    ];

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            const response = await getPointsOfContact();
            if (Array.isArray(response)) {
                setPointsOfContact(response.filter(poc => poc.mediaTypeId === 3));
            }

            const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: tenant.tenant }));
            if (settings?.data?.getTenantSettings?.icSMSMapping) {
                setMappingInfo(settings.data.getTenantSettings.icSMSMapping);
            }
            setIsLoading(false);
        }

        if (tenant) {
            let tenantSubscription;
            try {
                getData();
                tenantSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateTenantSettings))
                    .subscribe({
                        next: tenantData => {
                            if (tenantData?.value?.data?.onUpdateTenantSettings?.icSMSMapping) {
                                setMappingInfo(tenantData.value.data.onUpdateTenantSettings.icSMSMapping);
                            }
                        }
                    }
                    );
            } catch (err) {
                enqueueSnackbar("Unable to retrieve SMS mapping settings", { varianit: 'error' });
                console.log(err);
            }

            return () => {
                if (tenantSubscription) {
                    tenantSubscription.unsubscribe();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant]);

    function isError(formikProps, index, field, returnBool) {
        const errors = formikProps.errors.mappings;
        const touched = formikProps.touched.mappings;
        if (errors && touched) {
            if (errors[index] && touched[index] && errors[index][field] && touched[index][field]) {
                if (returnBool) {
                    return true;
                } else {
                    return errors[index][field];
                }
            } else {
                if (returnBool) {
                    return false;
                } else {
                    return null;
                }
            }
        }
    }

    function handleMappingValueChange(event, formikProps, index) {
        formikProps.handleChange(event);
        formikProps.setFieldValue(`mappings[${index}].verified`, false);
    }

    /**
     * Verifies a mapping for validity. 
     * Right now, we are only checking for verified provider credentials.
     * In the future, we want to be able to do some fancy API calls
     * to check a few more things.
     * @param {*} formikProps The formikProps object (for setting field values).
     * @param {*} index The index of the mapping in the array.
     * @param {*} event The onclick event for the checkbox
     */
    async function verifyMapping(formikProps, index, event) {
        if (!event.target.checked) {
            formikProps.setFieldValue(`mappings[${index}].verified`, false);
        } else {
            verifyingFeedback[index] = true;
            setVerifyingFeedback({ ...verifyingFeedback });

            let error = "";
            let providerConfigs = [];
            try {
                const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: tenant.tenant }));
                if (settings?.data?.getTenantSettings?.icSMSProviderCredentials?.length > 0) {
                    providerConfigs = settings.data.getTenantSettings.icSMSProviderCredentials;
                }

                const provider = formikProps.values.mappings[index].provider;
                switch (provider) {
                    case "Twilio":
                    case "TwilioWhatsApp":
                        if (!providerConfigs.find(x => x.provider === "Twilio")?.verified) {
                            formikProps.setFieldValue(`mappings[${index}].verified`, false);
                            error = "Twilio credentials don't exist or haven't been verified.";
                            break;
                        }

                        formikProps.setFieldValue(`mappings[${index}].verified`, true);
                        break;

                    case "Textel":
                        if (!providerConfigs.find(x => x.provider === "Textel")?.verified) {
                            formikProps.setFieldValue(`mappings[${index}].verified`, false);
                            error = "Textel credentials don't exist or haven't been verified.";
                            break;
                        }

                        formikProps.setFieldValue(`mappings[${index}].verified`, true);
                        break;
                    case "TextelFoundation":
                        if (!providerConfigs.find(x => x.provider === "Textel")?.verified) {
                            formikProps.setFieldValue(`mappings[${index}].verified`, false);
                            error = "Textel Foundation credentials don't exist or haven't been verified.";
                            break;
                        }

                        formikProps.setFieldValue(`mappings[${index}].verified`, true);
                        break;
                    case "MMS":
                        if (!providerConfigs.find(x => x.provider === "MMS")?.verified) {
                            formikProps.setFieldValue(`mappings[${index}].verified`, false);
                            error = "MMS credentials don't exist or haven't been verified.";
                            break;
                        }
                        formikProps.setFieldValue(`mappings[${index}].verified`, true);
                        break;
                    default:
                        error = "Verification failed.";
                }

                if (error === "") {
                    enqueueSnackbar("Mapping has been verified.", { variant: 'success' });
                } else {
                    enqueueSnackbar(error, { variant: 'error' });
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar("Unable to verify mapping.");
            }

            verifyingFeedback[index] = false;
            setVerifyingFeedback({ ...verifyingFeedback });
        }
    }

    return (
        <div>
            {isLoading &&
                <LinearProgress variant='indeterminate' color='primary' />}
            <Formik
                initialValues={mappingInfo}
                validateOnBlur
                enableReinitialize
                onSubmit={async values => {
                    if (values.enabled && values.mappings.length === 0) {
                        enqueueSnackbar("You must have at least one mapping to enable third party SMS.", { variant: 'error' });
                    } else {
                        setIsSaving(true);
                        for (const mapping of values.mappings) { // This is probably completely unnecessary. Idk why we are storing the name.
                            const selectedPoc = pointsOfContact.find(poc => poc.contactAddress === mapping.nicPocGuid);
                            mapping.nicPocName = selectedPoc?.contactDescription;
                        }

                        try {
                            await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: { icSMSMapping: values, id: tenant.tenant } }));
                            enqueueSnackbar("SMS mappings saved successfully", { variant: 'success' });
                        } catch (err) {
                            enqueueSnackbar("Unable to save SMS mappings", { variant: 'error' });
                            console.log(err);
                        }

                        setIsSaving(false);
                    }
                }}
                validationSchema={Yup.object({
                    mappings: Yup.array()
                        .of(Yup.object({
                            nicPocGuid: Yup.string()
                                .required("A NiC PoC is required"),
                            phone: Yup.string()
                                .required("Please enter a phone number"),
                            provider: Yup.string()
                                .required("Please select a provider")
                        }))
                        .test({
                            name: "Unique phone numbers and pocs",
                            message: "Each phone number and InContact Point of Contact must be unique",
                            test: (mappings) => {
                                for (const mapping of mappings) {
                                    if (mappings.filter(x => x.phone === mapping.phone || x.nicPocGuid === mapping.nicPocGuid).length > 1) {
                                        return false;
                                    }
                                }
                                return true;
                            }
                        })
                })}

            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    name='enabled'
                                    control={<Checkbox checked={formikProps.values.enabled} onChange={formikProps.handleChange} color="primary" />}
                                    label="Enable third party SMS"
                                    labelPlacement="end" />
                            </Grid>
                            {typeof formikProps.errors.mappings === 'string' &&
                                <Grid item>
                                    <Typography variant='body1' color='error'>{formikProps.errors.mappings}</Typography>
                                </Grid>}
                            <Grid item>
                                <FieldArray
                                    disabled
                                    name='mappings'
                                    render={helpers => (
                                        <Grid container direction='column' spacing={2}>
                                            {formikProps.values.mappings.map((mapping, index) =>
                                                <Grid item key={index}>
                                                    <Grid container direction='row' spacing={4} alignItems='center' alignContent='center'>
                                                        <Grid item>
                                                            <Grid container direction='row' spacing={2} alignItems='center' alignContent='center' style={{ borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgray' }}>
                                                                <Grid item>
                                                                    <FormControl required error={isError(formikProps, index, "nicPocGuid", true)} disabled={!formikProps.values.enabled}>
                                                                        <InputLabel id="nicPoc">InContact PoC</InputLabel>
                                                                        <Select
                                                                            name={`mappings[${index}].nicPocGuid`}
                                                                            value={formikProps.values.mappings[index].nicPocGuid}
                                                                            className={classes.longSelect}
                                                                            onChange={event => handleMappingValueChange(event, formikProps, index)}
                                                                            onBlur={formikProps.handleBlur}>
                                                                            {pointsOfContact.map((poc, index) =>
                                                                                <MenuItem key={index} value={poc.contactAddress}>{poc.contactDescription}</MenuItem>
                                                                            )}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormHelperText error={isError(formikProps, index, "nicPocGuid", true)}>{isError(formikProps, index, "nicPocGuid", false)}</FormHelperText>
                                                                </Grid>
                                                                <Grid item>
                                                                    <DoubleArrowIcon fontSize='large' color={formikProps.values.enabled ? 'primary' : 'disabled'} style={{ transform: 'rotate(180deg)' }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container direction='row' spacing={2} style={{ borderRadius: '5px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgray' }}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                name={`mappings[${index}].phone`}
                                                                                label='Phone #'
                                                                                variant='standard'
                                                                                color='primary'
                                                                                required
                                                                                value={formikProps.values.mappings[index].phone}
                                                                                disabled={!formikProps.values.enabled}
                                                                                onChange={event => handleMappingValueChange(event, formikProps, index)}
                                                                                onBlur={formikProps.handleBlur}
                                                                                error={isError(formikProps, index, "phone", true)}
                                                                                helperText={isError(formikProps, index, "phone", false)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <FormControl required error={isError(formikProps, index, "provider", true)} disabled={!formikProps.values.enabled}>
                                                                                <InputLabel id="nicPoc">Provider</InputLabel>
                                                                                <Select
                                                                                    name={`mappings[${index}].provider`}
                                                                                    value={formikProps.values.mappings[index].provider}
                                                                                    className={classes.regularSelect}
                                                                                    onChange={event => handleMappingValueChange(event, formikProps, index)}
                                                                                    onBlur={formikProps.handleBlur}>
                                                                                    {smsProviders.map((provider, index) =>
                                                                                        <MenuItem key={index} value={provider.dynamoName}>{provider.displayName}</MenuItem>
                                                                                    )}
                                                                                </Select>
                                                                                <FormHelperText error={isError(formikProps, index, "provider", true)}>{isError(formikProps, index, "provider", false)}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        {formikProps.values.mappings[index].provider === 'MMS' && 
                                                                            <Grid item>
                                                                                <TextField
                                                                                    name={`mappings[${index}].appKey`}
                                                                                    label='App Key'
                                                                                    variant='standard'
                                                                                    color='primary'
                                                                                    required
                                                                                    value={formikProps.values.mappings[index].appKey}
                                                                                    disabled={!formikProps.values.enabled}
                                                                                    onChange={event => handleMappingValueChange(event, formikProps, index)}
                                                                                    onBlur={formikProps.handleBlur}
                                                                                    error={isError(formikProps, index, "appKey", true)}
                                                                                    helperText={isError(formikProps, index, "appKey", false)}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            {verifyingFeedback[index] &&
                                                                <CircularProgress style={{ marginRight: '13px' }} variant='indeterminate' color='primary' />
                                                            }
                                                            {!verifyingFeedback[index] &&
                                                                <Tooltip title={formikProps.values.mappings[index].verified ? "Mapping is verified" : "Mapping has not been verified. Click to perform verification."}>
                                                                    <Checkbox
                                                                        name={`mappings[${index}].verified`}
                                                                        checked={formikProps.values.mappings[index].verified}
                                                                        onChange={event => verifyMapping(formikProps, index, event)}
                                                                        disabled={!formikProps.values.enabled}
                                                                        checkedIcon={<DoneIcon color={formikProps.values.enabled ? 'inherit' : 'disabled'} style={{ color: formikProps.values.enabled ? theme.palette.success.main : '' }} fontSize='large' />}
                                                                        icon={<ClearIcon color={formikProps.values.enabled ? 'error' : 'disabled'} fontSize='large' />}
                                                                    />
                                                                </Tooltip>}
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title="Delete mapping">
                                                                <IconButton disabled={!formikProps.values.enabled} onClick={() => helpers.remove(index)}>
                                                                    <DeleteOutlineIcon color={formikProps.values.enabled ? 'primary' : 'disabled'} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>)}
                                            <Grid item>
                                                <Button
                                                    variant='outlined'
                                                    color='primary'
                                                    disabled={!formikProps.values.enabled}
                                                    onClick={() => helpers.push({ nicPocGuid: "", nicPocName: "", phone: "", verified: false, provider: "" })}
                                                >
                                                    + SMS/WhatsApp Mapping
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    ref={saveButtonRef}
                                    disabled={isSaving || isLoading}
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                >
                                    Save
                                </Button>
                                {isSaving && saveButtonRef.current &&
                                    <CircularProgress
                                        style={{ position: 'absolute', top: saveButtonRef.current.offsetTop, left: saveButtonRef.current.offsetLeft + (saveButtonRef.current.offsetWidth / 2) - (34 / 2) }}
                                        size={34}
                                        variant='indeterminate'
                                        color='secondary' />}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </div>
    );
}
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { withRouter } from 'react-router-dom';
import {Typography, Grid, Divider, List, ListItem, ListItemIcon, ListItemText, Tooltip, IconButton, Paper, Checkbox, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { login, getConfigurationIcSkills } from '../context/InContact';
import { API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as _ from 'lodash';
import { EditOutlined, NavigateBeforeOutlined, NavigateNextOutlined, SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';


function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        minHeight: '300px'
    },
    paper: {
      width: '250px',
      height: '300px',
      overflow: 'auto'
    },
    customList: {
      width: '245px',
      height: '243px',
      overflow: 'auto'
    },
    uiInfo: {
      height: '250px',
      width: '250px'
    }
}));

const Review = function(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [skills, setSkills] = useState([]);
    const [allSkills, setAllSkills] = useState([]);
    const [rightFilterTerm, setRightFilterTerm] = useState('');
    const [leftFilterTerm, setLeftFilterTerm] = useState('');
    const [edit, setEdit] = useState(false);
    const [uiInfo, setUiInfo] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getData(){
            const allSkills = await getSkills(props.mediaType);
            setAllSkills(allSkills.filter(skill => {
              const isOutbound = props.direction === 'Outbound';
              return skill.isOutbound === isOutbound && !props.usedSkills?.includes(`${skill.skillId}`);
            }));
            let skillNames = [];
            for (const skillId of props.skills){
                const parsedSkill = JSON.parse(skillId);
                const fullSkill = _.find(allSkills, ['skillId', parsedSkill]);
                if (fullSkill !== undefined){
                    skillNames.push(fullSkill);
                } else {
                    const skill = {
                        skillName : skillId
                    }
                    skillNames.push(skill);
                }
            }
            setSkills(skillNames);
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    useEffect(() => {
        let uiInfo = [];
            if(!props.uiInfo || !props.uiInfo[props.mediaType]){
                return;
            }
            for(const [key, value] of Object.entries(props.uiInfo[props.mediaType])){
                uiInfo.push({ display: formatUI(key), key, value})
            }
            setUiInfo(uiInfo);
    }, [userContext.tenant, props])

    /**
     * This is a helper method to properly print out the UI Customization feature instead of just the variable name
     * @param {string} key
     */
    function formatUI(key){
        switch(key){
            case "activityPanel":
                return "Activity Panel";
            case "liveChat":
                return "Live Chat";
            case "showMessage":
                return "Show Message";
            case "replyAll":
                return "Reply All";
            case "composeMessage":
                return "Compose Message";
            case "callback":
                return "Schedule a Commitment"; // It was decided that we want callback to display "Schedule a Commitment"
            case "transfer":
              return "Transfer/Conference"; // It was decided to combine both transfer and conference into one
            default:
              return key.charAt(0).toUpperCase() + key.slice(1);
        }
    }

    function prepUIToSave() {
      let newInfo = {};
      uiInfo.forEach(item => {
        newInfo[item.key] = item.value;
      });
      props.uiInfo[props.mediaType] = newInfo;
      return JSON.stringify(props.uiInfo);
    }

    async function getSkills(mediaType){
        const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
        await login(dynamoSettings.data.getTenantSettings);

        const mediaTypes = {
          phoneCall: 4,
          email: 1,
          chat: 3,
          voiceMail: 5,
          workItem: 6,
          sms: 6,
          social: 8,
        };

        const retrievedSkills = await getConfigurationIcSkills(mediaTypes[mediaType]);
        return retrievedSkills;

    }

    function updateInfo(e, info){
      setUiInfo(uiInfo.map(item => {
        if (item.display === info.display) {
          item.value = e.target.checked;
        }
        return item;
      }));
    }

    function openEdit(save = false){
      if (save) {
        saveProfile()
      }
      setEdit(!edit);
      props.setIsEditing(!edit)
    }

    async function saveProfile(){
      try{
        const profile = {
          tenant: userContext.tenant,
          skillIds: skills.map(skill => skill.skillId),
          uiInfo: prepUIToSave(),
        };

        delete profile.history;
        delete profile.location;

        await API.graphql(graphqlOperation(mutations.updateConfigProfile, {input: {id: props.id, ...profile}}));
        enqueueSnackbar('Profile saved successfully');
      } catch (err) {
        console.error(err);
        enqueueSnackbar('Save unsuccessful. ' + err, {
          variant: 'error'
        });
      }
    }

    function updateFilterTerm(event, right = false){
      if (right) {
        setRightFilterTerm(event.target.value);
      } else {
        setLeftFilterTerm(event.target.value);
      }
    }

    const [checked, setChecked] = useState([]);
  
    const leftChecked = intersection(checked, allSkills);
    const rightChecked = intersection(checked, skills);
  
    const handleToggle = (value: number) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const handleAllRight = () => {
      setSkills(skills.concat(allSkills));
      setChecked([])
      setAllSkills([]);
    };
  
    const handleCheckedRight = () => {
      setSkills(skills.concat(leftChecked));
      setAllSkills(not(allSkills, leftChecked));
      setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
      setAllSkills(allSkills.concat(rightChecked));
      setSkills(not(skills, rightChecked));
      setChecked(not(checked, rightChecked));
    };
  
    const handleAllLeft = () => {
      setAllSkills(allSkills.concat(skills));
      setChecked([])
      setSkills([]);
    };

    const customList = (items, right = false) => (
      <Paper classes={{root: classes.paper}} elevation={3}>

        <TextField variant='filled' label='Search Skills' fullWidth onChange={(e) => updateFilterTerm(e, right)}></TextField>

        <List classes={{root: classes.customList}} dense component="div" role="list">
          {items.filter(skill => {
            if (right) {
              return skill.skillName?.toLowerCase().includes(rightFilterTerm?.toLowerCase())
            } else {
              return skill.skillName?.toLowerCase().includes(leftFilterTerm?.toLowerCase())
            }
          }).map((skill) => {
            const labelId = `transfer-list-item-${skill.skillName}-label`;
  
            return (
              <ListItem
                key={skill.skillName}
                role="listitem"
                button
                onClick={handleToggle(skill)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(skill) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={skill.skillName} secondary={skill.skillId} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Typography variant="h6" color="primary">Profile Review</Typography>
                    <Grid container direction="column" spacing={1}>
                        <Grid item container direction="column" spacing={1}> {/*Profile Information name, organization, media type, direction  */}
                            <Grid item>
                                <Typography  variant="body2">{props.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography  variant="body2">{props.org.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography  variant="body2">{props.direction} - {props.mediaType.charAt(0).toUpperCase() + props.mediaType.slice(1)}</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item container direction='row' alignItems='center' spacing={1}>
                            <Typography variant="h6" color="primary">Skills</Typography>

                            {props.preview && <Tooltip title='Edit Skills'>
                              <>
                                { !edit &&
                                  <IconButton aria-label="Edit Skills" onClick={() => openEdit()}>
                                        <EditOutlined fontSize="small" color="primary" />
                                  </IconButton>
                                }
                                { edit &&
                                  <IconButton aria-label="Save Skills" onClick={() => openEdit(true)}>
                                        <SaveOutlined fontSize="small" color="primary" />
                                  </IconButton>
                                }
                              </>
                            </Tooltip>}
                        </Grid>
                        <Grid item container direction="row" spacing={4}> {/*What Skills  */}
                            {!edit && <List dense={true}>
                              {skills.map(skill => (
                                  <ListItem>
                                      <ListItemText primary={skill.skillName} secondary={skill.skillId}></ListItemText>
                                  </ListItem>
                              ))}
                            </List>}
                            {edit && <Grid item container spacing={2} justifyContent="center" alignItems="center">
                              <Grid item>
                                <Typography color="primary">Available Skills</Typography>
                                {customList(allSkills)}
                              </Grid>
                              <Grid item>
                                <Grid container direction="column" alignItems="center">
                                  <IconButton
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleAllRight}
                                    disabled={allSkills.length === 0}
                                    aria-label="move all right"
                                  >
                                    <NavigateNextOutlined fontSize='small' color='primary'></NavigateNextOutlined>
                                    <NavigateNextOutlined fontSize='small' color='primary'></NavigateNextOutlined>
                                  </IconButton>
                                  <IconButton
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                  >
                                    <NavigateNextOutlined fontSize='small' color='primary'></NavigateNextOutlined>
                                  </IconButton>
                                  <IconButton
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                  >
                                    <NavigateBeforeOutlined fontSize='small' color='primary'></NavigateBeforeOutlined>
                                  </IconButton>
                                  <IconButton
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleAllLeft}
                                    disabled={skills.length === 0}
                                    aria-label="move all left"
                                  >
                                    <NavigateBeforeOutlined fontSize='small' color='primary'></NavigateBeforeOutlined>
                                    <NavigateBeforeOutlined fontSize='small' color='primary'></NavigateBeforeOutlined>
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography color="primary">Current Assigned Skills</Typography>
                                {customList(skills, true)}
                              </Grid>
                            </Grid>}
                        </Grid>
                        <Divider />
                        <Grid item container direction="column" spacing={1}> {/*Search  Multi-search, Action, Configuration*/}
                            <Typography variant="h6" color="primary">Search</Typography>
                            <Grid item container direction="column" alignItems="start">
                                <Grid item>
                                    <Typography color="primary">Options</Typography>
                                </Grid>
                                <Grid item container direction="row" spacing={4}>
                                    {props.search.map(search => (
                                        <Grid item>
                                            <Typography variant="body2">Entity: {search.searchEntity}</Typography>
                                            <Typography variant="body2">Meta: {search.searchMeta}</Typography>
                                            <Typography variant="body2">Fields: </Typography>
                                            <List dense={true}>
                                            {search.searchFields.map(field => (
                                              <ListItem>
                                                <ListItemText>
                                                  {field}
                                                </ListItemText>
                                              </ListItem>
                                            ))}
                                            </List>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography color="primary">Actions</Typography>
                            </Grid>
                            <Grid item container direction="row" spacing={2} alignItems="center">
                                <Grid item>
                                    No Match: {props.noMatch}
                                </Grid>
                                <Grid item>
                                    Single Match: {props.singleMatch}
                                </Grid>
                                <Grid item>
                                    Multi Match: {props.multiMatch}
                                </Grid>
                                <Grid item>
                                    MultiSearch: {JSON.stringify(props.multiSearch)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item container direction='column' spacing={1}>
                          <Grid item>
                              <Typography variant="h6" color="primary">Loggings</Typography>
                          </Grid>
                          <Grid item container direction='row' spacing={2}>
                              <Grid item>
                                <Grid item>
                                    <Typography color="primary">Master</Typography>
                                </Grid>
                                <Grid container direction="row" spacing={4} alignItems="center">
                                    <Grid item>
                                        <Grid item container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Meta Data</Typography>
                                            </Grid>
                                            {props.masterLoggings.map(log => (
                                                <Grid item>
                                                {log.entity}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid item container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Fields</Typography>
                                            </Grid>
                                            {props.masterLoggings.map(log => (
                                                <Grid item>
                                                {log.field}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid item>
                                    <Typography color="primary">Transaction</Typography>
                                </Grid>
                                <Grid container direction="row" spacing={4} alignItems="center">
                                    <Grid item>
                                        <Grid item container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Meta Data</Typography>
                                            </Grid>
                                            {props.transactionLoggings.map(log => (
                                                <Grid item>
                                                {log.meta}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                    <Grid item container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Fields</Typography>
                                            </Grid>
                                            {props.transactionLoggings.map(log => (
                                                <Grid item>
                                                {log.fields}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid item>
                                    <Typography color="primary">Post Transaction</Typography>
                                </Grid>
                                <Grid container direction="row" spacing={4} alignItems="center">
                                    <Grid item>
                                        <Grid item container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Meta Data</Typography>
                                            </Grid>
                                            {props.postTransactionLoggings.map(log => (
                                                <Grid item>
                                                {log.meta}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                    <Grid item container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Fields</Typography>
                                            </Grid>
                                            {props.postTransactionLoggings.map(log => (
                                                <Grid item>
                                                {log.fields}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                              </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                        <Grid item>
                            {(props.uiInfo && (props.mediaType === 'chat' || props.mediaType === 'email' || props.mediaType === 'phoneCall')) 
                            && <Grid container direction='row' alignItems='center'>
                                    <Typography variant="h6" color="primary">UI Customizations</Typography>
                                    {props.preview && <Tooltip title='Edit UI Customizations'>
                                      <>
                                        { !edit &&
                                          <IconButton aria-label="Edit Skills" onClick={() => openEdit()}>
                                                <EditOutlined fontSize="small" color="primary" />
                                          </IconButton>
                                        }
                                        { edit &&
                                          <IconButton aria-label="Save Skills" onClick={() => openEdit(true)}>
                                                <SaveOutlined fontSize="small" color="primary" />
                                          </IconButton>
                                        }
                                      </>
                                    </Tooltip>}
                              </Grid>
                          }
                        </Grid>
                        <Grid container direction="row" spacing={4}>
                            <Grid item container direction="column" spacing={1} classes={{root: classes.uiInfo}}>
                                    {uiInfo.map(info => (
                                        <Grid item container direction='row'>
                                            <FormControlLabel
                                                label={info.display}
                                                control={
                                                  <Switch
                                                    checked={info.value}
                                                    color='primary'
                                                    disabled={!edit}
                                                    onChange={(e) => updateInfo(e, info)} />
                                                }
                                            ></FormControlLabel>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </div>
            )}
        </UserContext.Consumer>


    );
}

export default withRouter(Review);
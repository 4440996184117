import React, { useEffect, useState, useContext, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import UserContext from '../context/UserContext';
import { Divider, FormControlLabel, FormHelperText, Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, FormControl, Checkbox, MenuItem, Select, Switch, InputLabel, List, ListItem, ListItemText, ListSubheader, IconButton, TextField, Paper, Tooltip, CircularProgress, Typography, Backdrop } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DragHandle from '@material-ui/icons/DragHandleOutlined';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { graphqlOperation, API } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Container, Draggable } from "react-smooth-dnd";
import moment from 'moment';
import Validations from './YupValidations'
import { getIcSkills } from '../context/InContact';
import { Clear } from '@material-ui/icons';
import VanillaCampaign from '../models/Campaign';
import { CenterToggleContainer, CenterToggleItem, CenterToggleChild } from 'react-center-toggle';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    mainFields: {
        width: '250px'

    },
    secondaryFields: {
        minWidth: '100px'
    },
    dialogDiv: {
        minWidth: '600px'
    }
}));

/**
 * The CampaignDialog component is use to tie a campain with a List Managmeent Profile
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
export default function CampaignDialog(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [profiles, setProfiles] = useState([]);
    const [sort, setSort] = useState(false);
    const emptyPendingSegment = { segmentId: '', segmentName: '', segmentRatio: '1' };
    const [pendingSegment, setPendingSegment] = useState(emptyPendingSegment);
    const [availableSegments, setAvailableSegments] = useState([]);
    const [availableSkills, setAvailableSkills] = useState([]);
    const [campaign, setCampaign] = useState(VanillaCampaign);
    const [isActive, setActive] = useState(false);
    const [loading, setLoading] = useState({
        profiles: false,
        skills: false,
        segments: false,
        formattingData: false
    });

    const selectedSegments = useRef(new Set());

    useEffect(() => {
        // get the campaigns, profile and Satuses to use for the workbundle
        async function getData() {
            setLoading({
                profiles: true,
                skills: true,
                segments: true,
                formattingData: true
            });
            const promises = [];
            promises.push(new Promise(async (resolve, reject) => {
                try {
                    resolve(await getIcSkills());
                } catch (err) {
                    reject(err);
                }
            }));
            promises.push(API.graphql(graphqlOperation(queries.profileByTenant, {
                tenant: userContext.tenant
            })));
            promises.push(getSegments());
            const results = await Promise.all(promises);
            setLoading({
                profiles: false,
                skills: false,
                segments: false,
                formattingData: true
            });

            setAvailableSkills(results[0]);
            setAvailableSegments(results[2]);
            setProfiles(results[1].data.profileByTenant.items);

            setCampaign(formatCampaign());

            setLoading({
                profiles: false,
                skills: false,
                segments: false,
                formattingData: false
            });
        }
        getData();
        if (props.campaign.status != 'Active' && props.campaign.status != 'Paused') {
            setActive(false);
        }
        else {
            setActive(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    async function getSegments() {
        let nextToken = null;
        const allSegments = [];
        do {
            const segments = await API.graphql(graphqlOperation(queries.segmentByTenant, {
                tenant: userContext.tenant,
                nextToken: nextToken
            }));
            allSegments.push(...segments.data.segmentByTenant.items);
            nextToken = segments.data.segmentByTenant.nextToken;

        } while (nextToken != null);
        
        return allSegments;
    }

    function formatCampaign() {
        const tempCampaign = _.cloneDeep(VanillaCampaign);
        for (const key of Object.keys(VanillaCampaign)) {
            if (props.campaign[key] != null) {
                tempCampaign[key] = props.campaign[key];
            }
        }

        if (tempCampaign.segments && !Array.isArray(tempCampaign.segments)) {
            tempCampaign.segments = JSON.parse(tempCampaign.segments);
        }

        return tempCampaign;
    }

    function blurSegmentsField(formikProps) {
        formikProps.handleBlur({ target: { name: 'segments' } });
    }

    const handleSelectSegment = (event) => {
        const match = _.find(availableSegments, ['id', event.target.value]);
        setPendingSegment({
            ...pendingSegment,
            segmentId: event.target.value,
            segmentName: match.name
        })
    }
    const handleSelectRatio = (event, segment, formikProps) => {
        const updateSegmentIndex = _.findIndex(formikProps.values.segments, ['segmentId', segment.segmentId]);
        formikProps.values.segments[updateSegmentIndex].segmentRatio = event.target.value;
        formikProps.setFieldValue('segments', formikProps.values.segments);
    }
    const handleSort = () => {
        setSort(!sort);
    }
    const deleteSegment = (segment, formikProps) => {
        if (!isActive) {
            blurSegmentsField(formikProps);
            selectedSegments.current.delete(segment.segmentId);
            formikProps.setFieldValue('segments', formikProps.values.segments.filter(x => x.segmentId !== segment.segmentId));
        }
    }

    const clearSegments = formikProps => {
        blurSegmentsField(formikProps);
        selectedSegments.current.clear();
        formikProps.setFieldValue('segments', []);
    }

    const addSegment = formikProps => {
        if (!isActive) {
            blurSegmentsField(formikProps);
            selectedSegments.current.add(pendingSegment.segmentId);
            formikProps.setFieldValue('segments', [...formikProps.values.segments, pendingSegment]);
            setPendingSegment(emptyPendingSegment);
        }
    }

    const onDrop = ({ removedIndex, addedIndex }, formikProps) => {
        if (!isActive) {
            blurSegmentsField(formikProps);
            formikProps.values.segments.splice(addedIndex, 0, formikProps.values.segments.splice(removedIndex, 1)[0]);
            formikProps.setFieldValue('segments', formikProps.values.segments);
        }
    };

    return (
        <Dialog open={props.open}>
            <Backdrop open={loading.formattingData} style={{ zIndex: 2 }}>
                <CircularProgress variant='indeterminate' size={50} color='primary' />
            </Backdrop>
            {!props.campaign &&
                <DialogTitle id="dp-dialog-title">Create Campaign</DialogTitle>}
            {props.campaign &&
                <DialogTitle id="dp-dialog-title">Edit Campaign</DialogTitle>}
            <DialogContent className={classes.dialogDiv}>
                <Formik
                    initialValues={campaign}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .required('A Campaign name is required')
                            .matches(Validations.atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid campaign name'),
                        skillId: Yup.mixed()
                            .required('A skill is required'),
                        campaignProfileId: Yup.string()
                            .required('A profile is required'),
                        expireDate: Yup.date()
                            .required('A date is required')
                            .min(moment().format(), 'Expire date must be after today'),
                        segments: Yup.array().min(1, "At least one segment is required.").max(8, "Campaigns cannot have more than 8 segments.")
                    })}
                    onSubmit={async (formikValues) => {
                        const values = _.cloneDeep(formikValues);
                        let successMsg = 'Campaign Created';
                        let mutation = mutations.createCampaign;
                        if (props.operation === 'edit') {
                            mutation = mutations.updateCampaign;
                            successMsg = 'Campaign Updated'
                        } else if (props.operation === 'clone') {
                            values.status = 'Pending';
                        }

                        if (campaign.id) {
                            values.id = campaign.id;
                        } else {
                            delete values.id;
                        }
                        values.tenant = userContext.tenant;
                        values.segments = JSON.stringify(values.segments);
                        values.expireDate = (new Date(values.expireDate)).toISOString();
                        if (campaign.status == 'active') {

                        }

                        try {
                            const skillInfo = availableSkills.find(x => +x.skillId === +values.skillId);
                            values.skillName = skillInfo.skillName;
                            values.skillSource = skillInfo.source;
                            delete values.createdAt;
                            delete values.updatedAt;
                            await API.graphql(graphqlOperation(mutation, { input: values }));

                            enqueueSnackbar(successMsg);
                            props.setCampaignDialogOpen(false);
                            selectedSegments.current.clear();
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                >
                    {formikProps => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Grid container spacing={2} direction="row" justify='space-between' alignItems="center" alignContent='center'>
                                        <Grid item>
                                            <TextField
                                                color="primary"
                                                margin="dense"
                                                name="name"
                                                label="Name"
                                                type="text"
                                                disabled={isActive}
                                                required
                                                className={classes.mainFields}
                                                value={formikProps.values.name}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={formikProps.errors.name && formikProps.touched.name}
                                                helperText={formikProps.touched.name && formikProps.errors.name} />
                                        </Grid>
                                        <Grid item>
                                            <CenterToggleContainer toggled={loading.profiles}>
                                                <CenterToggleChild>
                                                    <FormControl required className={classes.formControl} disabled={loading.profiles} error={formikProps.errors.campaignProfileId && formikProps.touched.campaignProfileId}>
                                                        <InputLabel id="profile-label">Profile</InputLabel>
                                                        <Select
                                                            color="primary"
                                                            margin="dense"
                                                            name="campaignProfileId"
                                                            label="Profile"
                                                            disabled={isActive}
                                                            className={classes.mainFields}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            value={formikProps.values.campaignProfileId}>
                                                            {profiles.map(profile =>
                                                                <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                        {formikProps.touched.campaignProfileId && <FormHelperText>{formikProps.errors.campaignProfileId}</FormHelperText>}
                                                    </FormControl>
                                                </CenterToggleChild>
                                                <CenterToggleItem>
                                                    <CircularProgress variant='indeterminate' color='primary' />
                                                </CenterToggleItem>
                                            </CenterToggleContainer>
                                        </Grid>
                                        <Grid item>
                                            <CenterToggleContainer toggled={loading.skills}>
                                                <CenterToggleChild>
                                                    <FormControl required className={classes.mainFields} error={formikProps.errors.skillId && formikProps.touched.skillId} disabled={loading.skills}>
                                                        <InputLabel id="skill-label">Skill</InputLabel>
                                                        <Select
                                                            color="primary"
                                                            margin="dense"
                                                            name="skillId"
                                                            label="Skill"
                                                            disabled={isActive}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            value={formikProps.values.skillId}>
                                                            {availableSkills.map(skill =>
                                                                <MenuItem key={skill.skillId} value={skill.skillId}>{skill.source}-{skill.skillName}</MenuItem>
                                                            )}
                                                        </Select>
                                                        {formikProps.touched.skillId && <FormHelperText>{formikProps.errors.skillId}</FormHelperText>}
                                                    </FormControl>
                                                </CenterToggleChild>
                                                <CenterToggleItem>
                                                    <CircularProgress variant='indeterminate' color='primary' />
                                                </CenterToggleItem>
                                            </CenterToggleContainer>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                color="primary"
                                                margin="dense"
                                                name="expireDate"
                                                label="Expiration Date"
                                                disabled={isActive}
                                                type="datetime-local"
                                                value={moment(formikProps.values.expireDate).format('YYYY-MM-DDTHH:mm')}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={formikProps.errors.expireDate && formikProps.touched.expireDate}
                                                InputLabelProps={{
                                                    shrink: true
                                                }} />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                label="Stop at exhaustion"
                                                control={<Switch
                                                    color="primary"
                                                    name="stopOnCompletion"
                                                    disabled={isActive}
                                                    checked={formikProps.values.stopOnCompletion}
                                                    onBlur={formikProps.handleBlur}
                                                    onChange={formikProps.handleChange}
                                                />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                label="Static"
                                                control={<Switch
                                                    color="primary"
                                                    name="static"
                                                    disabled={isActive}
                                                    checked={formikProps.values.static}
                                                    onBlur={formikProps.handleBlur}
                                                    onChange={formikProps.handleChange}
                                                />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={sort}
                                                onChange={handleSort}
                                                disabled={isActive}
                                                value={true}
                                                color="primary"
                                            />
                                        }
                                        label="Sort"
                                    />
                                </Grid>
                                <Grid item>
                                    <Paper>
                                        <List dense={false}>
                                            <ListSubheader>
                                                <Grid container justify='space-between'>
                                                    <Grid item>
                                                        Selected Segments
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title='Clear Segment List'>
                                                            <IconButton aria-label="delete" disable={isActive} onClick={() => clearSegments(formikProps)}>
                                                                <Clear fontSize="small" color="primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                {typeof formikProps.errors.segments === 'string' &&
                                                    <Typography variant='body2' color='error'>{formikProps.touched.segments && formikProps.errors.segments}</Typography>}
                                            </ListSubheader>
                                            <Divider />
                                            <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={(e) => onDrop(e, formikProps)}>
                                                {formikProps.values.segments.map((selections, index) => {
                                                    if (availableSegments.some(x => x.id === selections.segmentId)) {
                                                        return <Draggable key={selections.segmentId}>
                                                            <ListItem style={{ height: '50px' }}>
                                                                <ListItemText primary={
                                                                    <Grid container alignItems='center' justify='space-between'>
                                                                        <Grid item xs={4}>
                                                                            {selections.segmentName}
                                                                        </Grid>
                                                                        {/* <Grid item>
                                                                                {`${selections.segmentRatio} in ${_.sumBy(formikProps.values.segments, (item) => { return Number(item.segmentRatio) })}`}
                                                                            </Grid> */}
                                                                        <Grid item>
                                                                            <TextField
                                                                                color="primary"
                                                                                margin="normal"
                                                                                name="ratio"
                                                                                label="Ratio"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    inputProps: {
                                                                                        max: 100, min: 0, defaultValue: 1
                                                                                    }
                                                                                }}
                                                                                value={selections.segmentRatio}
                                                                                onChange={(e) => handleSelectRatio(e, selections, formikProps)} />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {selections.segmentRatio < 1 &&
                                                                                (0 * 100).toFixed(0) + '%'
                                                                            }
                                                                            {selections.segmentRatio > 0 &&
                                                                                ((selections.segmentRatio / _.sumBy(formikProps.values.segments, (item) => { return Number(item.segmentRatio) })) * 100).toFixed(0) + '%'}
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container alignItems="center">
                                                                                <Grid item>
                                                                                    <IconButton aria-label="delete" disabled={isActive} onClick={() => deleteSegment(selections, formikProps)}>
                                                                                        <DeleteIcon fontSize="small" color={isActive ? "disabled" : "primary"} />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item className="drag-handle" style={{ marginTop: '6px', cursor: 'grab' }}>
                                                                                    <DragHandle color={isActive ? "disabled" : "primary"} />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                } />
                                                            </ListItem>
                                                        </Draggable>
                                                    } else {
                                                        formikProps.values.segments.splice(index, 1);
                                                        formikProps.setFieldValue('segments', formikProps.values.segments);
                                                        return null;
                                                    }
                                                })}
                                            </Container>
                                        </List>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <Grid container justify="space-between" alignItems='center' alignContent='center'>
                                        <Grid item>
                                            <CenterToggleContainer toggled={loading.segments}>
                                                <CenterToggleChild>
                                                    <FormControl className={classes.secondaryFields} disabled={loading.segments}>
                                                        <InputLabel color="primary" id="campaign-label">Segment</InputLabel>
                                                        <Select
                                                            color="primary"
                                                            margin="dense"
                                                            name="segmentId"
                                                            label="Segment"
                                                            disabled={isActive}
                                                            className={classes.mainFields}
                                                            value={pendingSegment.segmentId}
                                                            onChange={handleSelectSegment} >
                                                            {availableSegments.map(segment =>
                                                                !selectedSegments.current.has(segment.id) &&
                                                                <MenuItem key={segment.id} value={segment.id}>{segment.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </CenterToggleChild>
                                                <CenterToggleItem>
                                                    <CircularProgress variant='indeterminate' color='primary' />
                                                </CenterToggleItem>
                                            </CenterToggleContainer>
                                        </Grid>
                                        {/* <Grid item>
                                            <TextField
                                                color="primary"
                                                margin="dense"
                                                name="ratio"
                                                label="Ratio"
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        max: 100, min: 1
                                                    }
                                                }}
                                                value={pendingSegment.segmentRatio}
                                                onChange={handleSelectRatio} />
                                        </Grid> */}
                                        <Grid item>
                                            <Tooltip title={formikProps.values.segments.length >= 8 ? "A campaign cannot have more than 8 segments." : pendingSegment.segmentId === "" ? 'Select a segment' : pendingSegment.segmentRatio > 0 ? "Add Segment" : "Segment Ratio Is Invalid"}>
                                                <div>
                                                    <IconButton aria-label="add" onClick={() => addSegment(formikProps)} disabled={pendingSegment.segmentId === "" || !(pendingSegment.segmentRatio > 0) || formikProps.values.segments.length >= 8}>
                                                        <AddIcon color={pendingSegment.segmentId === "" || !(pendingSegment.segmentRatio > 0) || formikProps.values.segments.length >= 8 || isActive ? "disabled" : "primary"} fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <DialogActions style={{ paddingTop: '25px' }}>
                                <Button onClick={() => { props.setCampaignDialogOpen(false); setPendingSegment(emptyPendingSegment); }} color="secondary" variant="outlined">Cancel</Button>
                                <Button type="submit" color="primary" variant="contained">Save</Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import MicNoneIcon from '@material-ui/icons/MicNone';

export default function Mask(props)
{
    const [mask, setMask] = useState(false);

    /** Set mask to the value being passed through props */
    useEffect(() => {
        setMask(props.value);
    }, [props.value])

    /** Handle changes of mask checkboxs */
    const handleMask = () => {
        setMask(!mask);
        props.onChange(!mask);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={mask}
                        onChange={handleMask}
                        name="mask"
                        color="primary"
                        />
                    }
                    label="Mask"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission to mask phone call">
                        <MicNoneIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


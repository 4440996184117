/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, FormControlLabel, Checkbox} from '@material-ui/core';

export default function Visible(props)
{
    const [visible, setVisible] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setVisible(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleVisible = () => {
        setVisible(!visible);
        props.changeCheck(!visible)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={visible}
                        onChange={handleVisible}
                        name="visible"
                        color="primary"
                        />
                    }
                    label="Make Footer Visible"
                    />
                </Grid>
            </Grid>
        </div>
    );
}


import moment from "moment";

const Campaign = {
    id: '',
    name: '',
    tenant: '',
    segments: [],
    sort: false,
    skillId: '',
    skillName: '',
    skillSource: '',
    campaignProfileId: '',
    expireDate: moment().add(1, 'month'),
    status: 'Pending',
    stopOnCompletion: true,
    static: false,
};

export default Campaign;
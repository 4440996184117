import React, { useState, useContext, useEffect } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AgentReports from './E2IconComponents/AgentReports'
import Visible from './E2IconComponents/Visible';
import Settings from './E2IconComponents/Settings';
import RefreshE2 from './E2IconComponents/RefreshE2';
import RefreshSkills from './E2IconComponents/RefreshSkills';
import NavHistory from './E2IconComponents/NavHistory';
import CDSite from './E2IconComponents/CDSite';
import Help from './E2IconComponents/Help';
import Hold from './E2IconComponents/Hold';
import Mute from './E2IconComponents/Mute';
import Record from './E2IconComponents/Record';
import Mask from './E2IconComponents/Mask';
import Transfer from './E2IconComponents/Transfer';
import Disposition from './E2IconComponents/Disposition';
import Callback from './E2IconComponents/Callback';
import ActivityPanel from './E2IconComponents/ActivityPanel';
import Elevate from './E2IconComponents/Elevate';
import EmailDisposition from './E2IconComponents/EmailDisposition';
import EmailActivityPanel from './E2IconComponents/EmailActivityPanel';
import EmailShowMessage from './E2IconComponents/EmailShowMessage';
import EmailReply from './E2IconComponents/EmailReply';
import EmailReplyAll from './E2IconComponents/EmailReplyAll';
import EmailForward from './E2IconComponents/EmailForward';
import EmailPark from './E2IconComponents/EmailPark';
import EmailComposeMessage from './E2IconComponents/EmailComposeMessage';
import EmailElevate from './E2IconComponents/EmailElevate';
import ChatElevate from './E2IconComponents/ChatElevate'
import ChatTransfer from './E2IconComponents/ChatTransfer';
import ChatDisposition from './E2IconComponents/ChatDisposition';
import ChatActivityPanel from './E2IconComponents/ChatActivityPanel';
import LiveChat from './E2IconComponents/LiveChat';
import AllowC3 from './E2IconComponents/AllowC3';
import Click2Call from './E2IconComponents/Click2Call';
import Click2Text from './E2IconComponents/Click2Text';
import HideUnavailable from './E2IconComponents/HideUnavailable';
import PanelMinimized from './E2IconComponents/PanelMinimized';
import OutboundShortcuts from './E2IconComponents/OutboundShortcuts';
import UserContext from '../context/UserContext';
import E2IconDisplay from './E2IconComponents/E2IconDisplay';
import { DateRangeOutlined, PlaylistAddOutlined, CalendarTodayOutlined, ExpandMore, TodayOutlined, GroupAddOutlined } from '@material-ui/icons';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { useSnackbar } from 'notistack';
import WorkItemActivityPanel from './E2IconComponents/WorkItemActivityPanel';

export default function DefaultUIConfig() {
    const userContext = useContext(UserContext); // Get user context to check if they have access to C3
    const { enqueueSnackbar } = useSnackbar();
    const [allowC3, setAllowC3] = useState(false);
    const [visible, setVisible] = useState(true); // If user doesn't want footer to be visible we should hide all icons
    const [action, setAction] = useState('new');
    const [tenantId, setTenantId] = useState('');

    const mediaType = 'baseConfig'; // This is the specified mediaType for this specific page within OutReach
    const skillIds = ["9999999999"]; // This is the specified skillId for this specific page within OutReach

    // Object to hold all the information for uiInfo
    const info = {
        app: {
            c3: false,
            click2Call: true,
            click2Text: false,
            hideUnavailable: false,
            panelMinimized: false,
            elevate: false,
        },
        phoneCall: {
            hold: true,
            mute: true,
            record: true,
            mask: true,
            transfer: true,
            disposition: true,
            callback: true,
            activityPanel: true,
        },
        chat: {
            transfer: true,
            disposition: true,
            activityPanel: true,
            liveChat: true,
            elevate: true,
        },
        email: {
            disposition: true,
            activityPanel: true,
            showMessage: true,
            reply: true,
            replyAll: true,
            forward: true,
            park: true,
            composeMessage: true,
            elevate: true,
        },
        workItem: {
          activityPanel: true,
        },
        footer: {
            visible: true,
            settings: true,
            agentReports: true,
            wfm: true,
        },
        login: {
            setPhoneNumber: true,
            setStationId: true,
            integratedSoftphone: true,
        },
        miscellaneous: {
            createCommitment: true,
            createCase: true,
            createEntity: true,
            showScheduledCommitment: true,
            outboundShortcuts: true,
            help: true,
            refreshE2: true,
            refreshSkills: true,
            navHistory: true,
            cdSite: true,

        }
    }
    const [uiInfo, setUIInfo] = useState(info);


    // Query the information from the database if they are editing the UI configuration
    useEffect(() => {
        async function getData() {
            // Get the config profile
            const configProfile = await API.graphql(graphqlOperation(queries.configProfielsByTenant, { tenant: userContext.tenant }));
            const data = configProfile.data.configProfielsByTenant.items;

            // Filter skillId so that we only have the profile with skillId 999999999
            var defaultProfile;
            for (var profile in data) {
                defaultProfile = data[profile];
                if (defaultProfile.skillIds[0] === "9999999999") {
                    setTenantId(defaultProfile.id);
                    break;
                }
            }

            // If we didn't find a default profile then we just want to return
            if (defaultProfile === undefined) {
                return;
            }

            // Pull all the uiInfo from skillId and put it into our uiInfo
            let parsedProfile = JSON.parse(defaultProfile.uiInfo);
            if (parsedProfile) {
                // Make sure that they have all the fields, Incase there are more sections added in the future.
                if (!parsedProfile.app) {
                    parsedProfile = {
                        ...parsedProfile,
                        app: {
                            c3: false,
                            click2Call: true,
                            click2Text: false,
                            hideUnavailable: false,
                            elevate: false,
                        },
                    }
                }
                if (!parsedProfile.phoneCall) {
                    parsedProfile = {
                        ...parsedProfile,
                        phoneCall: {
                            hold: true,
                            mute: true,
                            record: true,
                            mask: true,
                            transfer: true,
                            disposition: true,
                            callback: true,
                            activityPanel: true,
                        },
                    }
                }
                if (!parsedProfile.chat) {
                    parsedProfile = {
                        ...parsedProfile,
                        chat: {
                            transfer: true,
                            disposition: true,
                            activityPanel: true,
                            liveChat: true,
                            elevate: true,
                        },
                    }
                }
                if (!parsedProfile.email) {
                    parsedProfile = {
                        ...parsedProfile,
                        email: {
                            disposition: true,
                            activityPanel: true,
                            showMessage: true,
                            reply: true,
                            replyAll: true,
                            forward: true,
                            park: true,
                            composeMessage: true,
                            elevate: true,
                        },
                    }
                }
                if (!parsedProfile.workItem) {
                  parsedProfile = {
                      ...parsedProfile,
                      workItem: {
                          activityPanel: true,
                      },
                  }
              }
                if (!parsedProfile.footer) {
                    parsedProfile = {
                        ...parsedProfile,
                        footer: {
                            visible: true,
                            settings: true,
                            agentReports: true,
                            wfm: true,
                        },
                    }
                }
                if (!parsedProfile.login) {
                    parsedProfile = {
                        ...parsedProfile,
                        login: {
                            setPhoneNumber: true,
                            setStationId: true,
                            integratedSoftphone: true
                        },
                    }
                }
                if (!parsedProfile.miscellaneous) {
                    parsedProfile = {
                        ...parsedProfile,
                        miscellaneous: {
                            createCommitment: true,
                            createCase: true,
                            createEntity: true,
                            showScheduledCommitment: true,
                            outboundShortcuts: true,
                            help: true,
                            refreshE2: true,
                            refreshSkills: true,
                            navHistory: true,
                            cdSite: true,
                        }
                    }
                }
                setUIInfo(parsedProfile);
                if (!parsedProfile.footer.visible) {
                    setVisible(false);
                }
                setAction('update');
            }
        }
        if (userContext.tenant) {
            getData();
        }
    }, [userContext.tenant])

    // Check if user has PromptPay license so they can check the C3 box in the App section
    useEffect(() => {
        if (userContext.licenses.includes('PromptPay') && action === 'new') {
            setAllowC3(true);
            uiInfo.app.c3 = true;
        }
        else if (userContext.licenses.includes('PromptPay')) {
            setAllowC3(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext]);

    // This section handles changing the app section
    const handleC3 = (value) => uiInfo.app.c3 = value;
    const handleClick2Call = (value) => uiInfo.app.click2Call = value;
    const handleClick2Text = (value) => uiInfo.app.click2Text = value;
    const handleHideUnavailable = (value) => uiInfo.app.hideUnavailable = value;
    const handlePanelMinimized = (value) => uiInfo.app.panelMinimized = value;
    const handleElevate = (value) => uiInfo.app.elevate = value;

    // This section handles changing the login section
    const handleSetPhoneNumber = (value) => uiInfo.login.setPhoneNumber = value;
    const handleSetStationId = (value) => uiInfo.login.setStationId = value;
    const handleIntegratedSoftphone = (value) => uiInfo.login.integratedSoftphone = value;

    // This section handles changing the footer section
    const handleVisible = (value) => { uiInfo.footer.visible = value; setVisible(value) };
    const handleSettings = (value) => uiInfo.footer.settings = value;
    const handleAgentReports = (value) => uiInfo.footer.agentReports = value;
    const handleWFM = (value) => uiInfo.footer.wfm = value;

    // This section handles changing the phone call section
    const handleHold = (value) => uiInfo.phoneCall.hold = value;
    const handleMute = (value) => uiInfo.phoneCall.mute = value;
    const handleRecord = (value) => uiInfo.phoneCall.record = value;
    const handleMask = (value) => uiInfo.phoneCall.mask = value;
    const handleTransfer = (value) => uiInfo.phoneCall.transfer = value;
    const handleDisposition = (value) => uiInfo.phoneCall.disposition = value;
    const handleCallback = (value) => uiInfo.phoneCall.callback = value;
    const handleActivityPanel = (value) => uiInfo.phoneCall.activityPanel = value;

    // This section handles changing the email section
    const handleEmailDisposition = (value) => uiInfo.email.disposition = value;
    const handleEmailActivityPanel = (value) => uiInfo.email.activityPanel = value;
    const handleEmailShowMessage = (value) => uiInfo.email.showMessage = value;
    const handleEmailReply = (value) => uiInfo.email.reply = value;
    const handleEmailReplyAll = (value) => uiInfo.email.replyAll = value;
    const handleEmailForward = (value) => uiInfo.email.forward = value;
    const handleEmailPark = (value) => uiInfo.email.park = value;
    const handleEmailComposeMessage = (value) => uiInfo.email.composeMessage = value;
    const handleEmailElevate = (value) => uiInfo.email.elevate = value;

    // This section handles changing the chat section
    const handleChatTransfer = (value) => uiInfo.chat.transfer = value;
    const handleChatDisposition = (value) => uiInfo.chat.disposition = value;
    const handleChatActivityPanel = (value) => uiInfo.chat.activityPanel = value;
    const handleChatLiveChat = (value) => uiInfo.chat.liveChat = value;
    const handleChatElevate = (value) => uiInfo.chat.elevate = value;

    // This section handles changing the work item section
    const handleWorkItemActivityPanel = (value) => uiInfo.workItem.activityPanel = value;

    // This section handles changing the misc section
    const handleCreateCommitment = (value) => uiInfo.miscellaneous.createCommitment = value;
    const handleCreateCase = (value) => uiInfo.miscellaneous.createCase = value;
    const handleCreateEntity = (value) => uiInfo.miscellaneous.createEntity = value;
    const handleShowScheduledCommitment = (value) => uiInfo.miscellaneous.showScheduledCommitment = value;
    const handleOutboundShortcuts = (value) => uiInfo.miscellaneous.outboundShortcuts = value;
    const handleHelp = (value) => uiInfo.miscellaneous.help = value;
    const handleRefreshE2 = (value) => uiInfo.miscellaneous.refreshE2 = value;
    const handleRefreshSkills = (value) => uiInfo.miscellaneous.refreshSkills = value;
    const handleNavHistory = (value) => uiInfo.miscellaneous.navHistory = value;
    const handleCDSite = (value) => uiInfo.miscellaneous.cdSite = value;



    /** Persist to the database when the page is saved */
    async function saveCustomization() {

        // If visible is false then we need to make all the footer items false
        if (!visible) {
            uiInfo.footer = {
                visible: false,
                agentReports: false,
                wfm: false,
                settings: false,
            }
        }

        // We need to add in connection, logging action, and org to our uiInfo so we can properly submit to the database
        const connection = {
            dynamicsTenant: "na",
            appSecret: "na",
            clientId: "na"
        };
        const loggingAction = "na";
        const org = {
            name: "na",
            id: "na",
            version: "na",
            url: "na"
        };

        // Create profile with all the new information
        const profile = {
            tenant: userContext.tenant,
            name: userContext.name,
            skillIds,
            mediaType,
            connection,
            loggingAction,
            org,
            uiInfo: JSON.stringify(uiInfo)
        }

        // Now we can save our new UIInfo to the database
        try {
            if (action === 'new') {
                await API.graphql(graphqlOperation(mutations.createConfigProfile, { input: profile }));
                enqueueSnackbar("Default UI Created")
            } else if (action === 'update') {
                await API.graphql(graphqlOperation(mutations.updateConfigProfile, { input: { id: tenantId, ...profile } }))
                enqueueSnackbar("Default UI Updated")
            }

        } catch (err) {
            enqueueSnackbar('Something went wrong updating default UI', { variant: 'error' })
            console.error(err);
        }
    }

    return (
        <Grid>
            <Typography variant="h4">Default UI Configuration</Typography>
            <br></br>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>App</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container direction="row" spacing={4}>
                        <Grid item>
                            <AllowC3 value={uiInfo.app.c3} onChange={handleC3} permission={allowC3} />
                        </Grid>
                        <Grid item>
                            <Click2Call value={uiInfo.app.click2Call} onChange={handleClick2Call} />
                        </Grid>
                        <Grid item>
                            <Click2Text value={uiInfo.app.click2Text} onChange={handleClick2Text} />
                        </Grid>
                        <Grid item>
                            <HideUnavailable value={uiInfo.app.hideUnavailable} onChange={handleHideUnavailable} />
                        </Grid>
                        <Grid item>
                            <PanelMinimized value={uiInfo.app.panelMinimized} onChange={handlePanelMinimized} />
                        </Grid>
                        <Grid item>
                            <Elevate value={uiInfo.app.elevate} onChange={handleElevate} />
                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>Login</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={5}>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.login.setPhoneNumber} onChange={handleSetPhoneNumber} name="setPhoneNumber" label="Set Phone Number" title="Allow Set Phone Number access" />
                        </Grid>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.login.setStationId} onChange={handleSetStationId} name="setStationId" label="Set Station ID" title="Allow Set Station ID access" />
                        </Grid>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.login.integratedSoftphone} onChange={handleIntegratedSoftphone} name="integratedSoftphone" label="Integrated Softphone (Max)" title="Allow Integrated Softphone Access" />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Footer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column">
                        <Grid container direction="row">
                            <Grid item>
                                <Visible value={visible} changeCheck={handleVisible} />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={3}>
                            <Grid item>
                                {visible && <Settings value={uiInfo.footer.settings} changeCheck={handleSettings} />}
                            </Grid>
                            <Grid item>
                                {visible && <AgentReports value={uiInfo.footer.agentReports} onChange={handleAgentReports} label="Show Agent Reports" title="Allow Agent Reports"/>}
                            </Grid>
                            <Grid item>
                                {visible && <E2IconDisplay value={uiInfo.footer.wfm} onChange={handleWFM} label="Show WFM" title="Allow Work Flow Management" icon={<TodayOutlined color="primary" />} />}
                            </Grid>
                        </Grid>
                    </Grid>


                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Phone Call</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={4}>
                        <Grid item>
                            <Hold value={uiInfo.phoneCall.hold} onChange={handleHold}></Hold>
                        </Grid>
                        <Grid item>
                            <Mute value={uiInfo.phoneCall.mute} onChange={handleMute}></Mute>
                        </Grid>
                        <Grid item>
                            <Record value={uiInfo.phoneCall.record} onChange={handleRecord}></Record>
                        </Grid>
                        <Grid item>
                            <Mask value={uiInfo.phoneCall.mask} onChange={handleMask}></Mask>
                        </Grid>
                        <Grid item>
                            <Transfer value={uiInfo.phoneCall.transfer} onChange={handleTransfer}></Transfer>
                        </Grid>
                        <Grid item>
                            <Disposition value={uiInfo.phoneCall.disposition} onChange={handleDisposition}></Disposition>
                        </Grid>
                        <Grid item>
                            <Callback value={uiInfo.phoneCall.callback} onChange={handleCallback}></Callback>
                        </Grid>
                        <Grid item>
                            <ActivityPanel value={uiInfo.phoneCall.activityPanel} onChange={handleActivityPanel}></ActivityPanel>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Email</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container direction="row" spacing={5}>
                        <Grid item>
                            <EmailDisposition value={uiInfo.email.disposition} onChange={handleEmailDisposition}></EmailDisposition>
                        </Grid>
                        <Grid item>
                            <EmailActivityPanel value={uiInfo.email.activityPanel} onChange={handleEmailActivityPanel}></EmailActivityPanel>
                        </Grid>
                        <Grid item>
                            <EmailShowMessage value={uiInfo.email.showMessage} onChange={handleEmailShowMessage} />
                        </Grid>
                        <Grid item>
                            <EmailReply value={uiInfo.email.reply} onChange={handleEmailReply} />
                        </Grid>
                        <Grid item>
                            <EmailReplyAll value={uiInfo.email.replyAll} onChange={handleEmailReplyAll} />
                        </Grid>
                        <Grid item>
                            <EmailForward value={uiInfo.email.forward} onChange={handleEmailForward} />
                        </Grid>
                        <Grid item>
                            <EmailPark value={uiInfo.email.park} onChange={handleEmailPark} />
                        </Grid>
                        <Grid item>
                            <EmailComposeMessage value={uiInfo.email.composeMessage} onChange={handleEmailComposeMessage} />
                        </Grid>
                        <Grid item>
                            <EmailElevate value={uiInfo.email.elevate} onChange={handleEmailElevate} />
                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Chat</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container direction="row" spacing={5}>
                        <Grid item>
                            <ChatTransfer value={uiInfo.chat.transfer} onChange={handleChatTransfer}></ChatTransfer>
                        </Grid>
                        <Grid item>
                            <ChatDisposition value={uiInfo.chat.disposition} onChange={handleChatDisposition}></ChatDisposition>
                        </Grid>
                        <Grid item>
                            <ChatActivityPanel value={uiInfo.chat.activityPanel} onChange={handleChatActivityPanel}></ChatActivityPanel>
                        </Grid>
                        <Grid item>
                            <LiveChat value={uiInfo.chat.liveChat} onChange={handleChatLiveChat} />
                        </Grid>
                        <Grid item>
                            <ChatElevate value={uiInfo.chat.elevate} onChange={handleChatElevate} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Work Item</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container direction="row" spacing={5}>
                        <Grid item>
                            <WorkItemActivityPanel value={uiInfo.workItem.activityPanel} onChange={handleWorkItemActivityPanel}></WorkItemActivityPanel>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>Miscellaneous</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="row" spacing={5}>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.miscellaneous.createCase} onChange={handleCreateCase} name="createCase" label="Create Case" title="Allow Create Case access" icon={<PlaylistAddOutlined color="primary" />} />
                        </Grid>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.miscellaneous.createEntity} onChange={handleCreateEntity} name="createEntity" label="Create Entity" title="Allow Create Entity access" icon={<GroupAddOutlined color="primary" />} />
                        </Grid>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.miscellaneous.createCommitment} onChange={handleCreateCommitment} name="createCommitment" label="Create Commitment" title="Allow Create Commitment access" icon={<CalendarTodayOutlined color="primary" />} />
                        </Grid>
                        <Grid item>
                            <E2IconDisplay value={uiInfo.miscellaneous.showScheduledCommitment} onChange={handleShowScheduledCommitment} label="Show Scheduled Commitment" title="Allow Show Scheduled Commitment access" icon={<DateRangeOutlined color="primary" />} />
                        </Grid>
                        <Grid item>
                            <Help value={uiInfo.miscellaneous.help} onChange={handleHelp} />
                        </Grid>
                        <Grid item>
                            <OutboundShortcuts value={uiInfo.miscellaneous.outboundShortcuts} onChange={handleOutboundShortcuts} label="Show Outbound Shortcut" title="Allow Outbound Shortcut access" icon={<DateRangeOutlined color="primary" />} />
                        </Grid>
                        <Grid item>
                            <RefreshE2 value={uiInfo.miscellaneous.refreshE2} onChange={handleRefreshE2} />
                        </Grid>
                        <Grid item>
                            <RefreshSkills value={uiInfo.miscellaneous.refreshSkills} onChange={handleRefreshSkills} />
                        </Grid>
                        <Grid item>
                            <NavHistory value={uiInfo.miscellaneous.navHistory} onChange={handleNavHistory} />
                        </Grid>
                        <Grid item>
                            <CDSite value={uiInfo.miscellaneous.cdSite} onChange={handleCDSite} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <br></br>
            <Button color="primary" variant="contained" onClick={saveCustomization}>Save</Button>
        </Grid>
    );
}
import React, { useEffect, useState, useContext } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, makeStyles, TextField, Grid, FormControlLabel, Switch, Select, MenuItem, InputLabel, FormControl, DialogActions, Paper, Typography } from '@material-ui/core';
import CountryRegionData from '../../node_modules/country-region-data/data.json';
import * as _ from 'lodash';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { Formik } from 'formik';
import * as moment from 'moment';
import UserContext, { isDateField } from '../context/UserContext';
import Validations from './YupValidations';
import timezones from './TimezoneData';
import * as customQueries from '../graphql/custom-queries'

import BalancesDialog from '../components/BalancesDialog';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  },
  timezoneMenuItems: {
    maxHeight: 25
  },
  paper: {
    padding: '15px'
  }
}));

/**
 * The dialog for creating and editing a contact.
 * @component
 */
const ContactDialog = function (props) {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [addContactOpen, setAddContactOpen] = useState(false)
  const [contact, setContact] = useState({
    firstName: '',
    lastName: '',
    source: '',
    phone: '',
    cell: '',
    email: '',
    dateCreated: new Date(),
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    complianceRequired: false,
    preview: false,
    timeZone: 'Mountain',
    dnc: false,
    customFields: {},
    balances: [],
    optout: {
      Voice: false,
      SMS: false,
      Email: false
    }
  })
  const [usStates, setUsStates] = useState([]);
  //const [statuses, setStatuses] = useState([]);

  //Balances
  const [openBalances, setOpenBalances] = useState(false);

  const [balances, setBalances] = useState([]);

  useEffect(() => {
    async function getData() {
      const usStates = _.find(CountryRegionData, ['countryShortCode', 'US']).regions;
      setUsStates(usStates);
      try {
        const contactData = await API.graphql(graphqlOperation(customQueries.getCreateContactData))
        //setStatuses(contactData.data.contactStatus.enumValues);
        //this.accounts = contactData.data.accounts.items;
      } catch (err) {
        console.error(err);
      }
    }
    getData();
  }, []);

  /**
   * Open the dialog.
   */
  const openAddContact = () => {
    setAddContactOpen(true);
  }

  /**
   * Close the dialog.
   */
  const closeAddContact = () => {
    setAddContactOpen(false);
  }

  const handleDate = (name, values) => {
    const dateValue = values.customFields[name];
    if (dateValue && Number(dateValue)) {
      return moment(dateValue, 'x').format('YYYY-MM-DDTHH:mm');
    } else if (dateValue) {
      return dateValue;
    } else {
      return '';
    }
  }

  function handleBalanceClick() {
    setOpenBalances(!openBalances);
  }

  return (
    <UserContext.Consumer>
      {({ tenant, customFields, }) => (
        <div>
          {!props.editContact &&
            <Button onClick={openAddContact} color="primary" variant="contained">+ Contact</Button>
          }
          <Dialog open={addContactOpen} onClose={closeAddContact} maxWidth="md">
            <DialogTitle id="contact-dialog-title">Add A Contact</DialogTitle>
            <DialogContent>
              <Formik
                initialValues={contact}
                validationSchema={Validations.contactValidation}
                onSubmit={async values => {
                  //dynamodb doesn't like empty strings turn empty into null
                  const contact = { ...values };
                  for (const key in contact) {
                    contact[key] = contact[key] === "" ? null : contact[key];
                  }
                  if (balances.length > 0) {
                    contact.balances = balances
                  }
                  contact.tenant = tenant;
                  delete contact.createdAt;
                  delete contact.updatedAt;
                  if (contact.customFields) {
                    for (const key in contact.customFields) {
                      if (isDateField(key)) {
                        contact.customFields[key] = moment(contact.customFields[key]).format('x');
                      }
                    }
                    contact.customFields = JSON.stringify(contact.customFields);
                  }
                  try {
                    const user = await (await Auth.currentSession()).getIdToken();
                    contact.updatedBy = user.payload.email;

                    await API.graphql(graphqlOperation(mutations.createContact, { input: contact }));
                    closeAddContact();
                    window.location.reload(false);

                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {props => (
                  <form onSubmit={props.handleSubmit}>
                    <Grid container spacing={2} direction='column'>
                      <Grid item>
                        <Paper elevation={4} className={classes.paper}>
                          <Grid container direction='column'>
                            <Grid item>
                              <Typography variant='overline'>Contact</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container spacing={2}>
                                <Grid item md={6}>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    name="firstName"
                                    label="First Name"
                                    type="text"
                                    required={true}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.firstName}
                                    error={props.errors.firstName && props.touched.firstName}
                                    helperText={props.errors.firstName} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="lastName"
                                    label="Last Name"
                                    type="text"
                                    required={true}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.lastName}
                                    error={props.errors.lastName && props.touched.lastName}
                                    helperText={props.errors.lastName} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                    error={props.errors.email && props.touched.email}
                                    helperText={props.errors.email} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="source"
                                    label="Source"
                                    type="text"
                                    value={props.values.source}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.source && props.touched.source} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="phone"
                                    label="Phone #"
                                    type="phone"
                                    required={props.values.cell ? false : true}
                                    value={props.values.phone}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.phone && props.touched.phone}
                                    helperText={props.errors.phone} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="cell"
                                    label="Cell #"
                                    type="phone"
                                    required={props.values.phone ? false : true}
                                    value={props.values.cell}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.cell && props.touched.cell}
                                    helperText={props.errors.cell} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="address1"
                                    label="Address Line 1"
                                    type="text"
                                    value={props.values.address1}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.address1 && props.touched.address1}
                                    helperText={props.errors.address1} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="address2"
                                    label="Address Line 2"
                                    type="text"
                                    value={props.values.address2 ? props.values.address2 : ''}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.address2 && props.touched.address2}
                                    helperText={props.errors.address2} />
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="city"
                                    label="City"
                                    type="text"
                                    value={props.values.city}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.city && props.touched.city}
                                    helperText={props.errors.city} />
                                </Grid>
                                <Grid item md={6}>
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="us-state-label">State</InputLabel>
                                    <Select
                                      labelId="us-state-label"
                                      value={props.values.state}
                                      autoWidth={true}
                                      name="state"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}>
                                      {usStates.map(state =>
                                        <MenuItem key={state.shortCode} value={state.shortCode}>{state.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    margin="dense"
                                    name="zip"
                                    label="Zip"
                                    type="text"
                                    value={props.values.zip}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={props.errors.zip && props.touched.zip}
                                    helperText={props.errors.zip} />
                                </Grid>
                                <Grid item md={6}>
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="timezone-label">Timezone</InputLabel>
                                    <Select
                                      labelId="timezone-label"
                                      value={props.values.timeZoneID}
                                      autoWidth={true}
                                      name="timeZone"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}>
                                      {timezones.map(timezone =>
                                        <MenuItem className={classes.timezoneMenuItems} key={timezone.shortname} value={timezone.shortName}>{timezone.longName}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Paper elevation={4} className={classes.paper}>
                          <Grid container direction='column'>
                            <Grid item>
                              <Typography variant='overline'>OptOuts</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container spacing={2}>
                                <Grid item>
                                  <FormControlLabel
                                    label='Voice'
                                    control={
                                      <Switch
                                        checked={props.values?.optout?.Voice ?? false}
                                        onChange={props.handleChange}
                                        name='optout.Voice'
                                        color='primary'
                                      />
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <FormControlLabel
                                    label='SMS'
                                    control={
                                      <Switch
                                        checked={props.values?.optout?.SMS ?? false}
                                        onChange={props.handleChange}
                                        name='optout.SMS'
                                        color='primary'
                                      />
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <FormControlLabel
                                    label='Email'
                                    control={
                                      <Switch
                                        checked={props.values?.optout?.Email ?? false}
                                        onChange={props.handleChange}
                                        name='optout.Email'
                                        color='primary'
                                      />
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Paper elevation={4} className={classes.paper}>
                          <Grid container direction='column'>
                            <Grid item>
                              <Typography variant='overline'>Custom Fields</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container spacing={2}>
                                {customFields && customFields.map(field => (
                                  <Grid item key={field.name} md={6}>
                                    {field.type === 'String' &&
                                      <TextField
                                        margin="dense"
                                        name={`customFields.${field.name}`}
                                        label={field.displayName}
                                        type="text"
                                        value={props.values.customFields[field.name] || ''}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur} />
                                    }
                                    {field.type === 'Number' &&
                                      <TextField
                                        margin="dense"
                                        name={`customFields.${field.name}`}
                                        label={field.displayName}
                                        type="number"
                                        value={props.values.customFields[field.name] || ''}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur} />
                                    }
                                    {field.type === 'DateTime' &&
                                      <TextField
                                        margin="dense"
                                        name={`customFields.${field.name}`}
                                        label={field.displayName}
                                        type="datetime-local"
                                        value={handleDate(field.name, props.values)}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        InputLabelProps={{
                                          shrink: true
                                        }} />
                                    }
                                    {field.type === 'Boolean' &&
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name={`customFields.${field.name}`}
                                            checked={props.values.customFields[field.name] || false}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value="true"
                                          />
                                        }
                                        label={field.displayName}
                                      />
                                    }
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <div hidden={!userContext.licenses.includes('PromptPay')}>
                      <br></br>
                      <Grid>
                        <Button variant="contained" color="primary" onClick={handleBalanceClick}>+ Balances</Button>
                      </Grid>
                    </div>
                    <DialogActions>
                      <Button onClick={closeAddContact} color="primary" variant="outlined">Cancel</Button>
                      <Button type="submit" color="primary" variant="contained">Save</Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
          {/* Balances Dialog*/}
          <BalancesDialog openBalances={openBalances} setOpenBalances={setOpenBalances} balances={balances} setBalances={setBalances} />
        </div>
      )
      }
    </UserContext.Consumer >
  )
};

export default (ContactDialog);
import React, { useContext, useEffect, useState} from 'react';
import { makeStyles, Button, Paper, Grid, Typography, TextField, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Divider, DialogContent, Dialog, DialogTitle, IconButton, List  } from '@material-ui/core';
import { Container, Draggable } from "react-smooth-dnd";
import UserContext from '../context/UserContext';
import * as _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import BalancesDialog from '../components/BalancesDialog';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
});


export default function BalancesPreview(props) {
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [contact, setContact] = useState({});
    const [balances, setBalances] = useState([]);
    const [updateBalances, setUpdateBalances] = useState(false);
    const [openBalances, setOpenBalances] = useState(false);

    useEffect(()=>{
        async function getData(){
            if(props.contactId){
                const contactId = props.contactId;
                const contactResult = await API.graphql(graphqlOperation(queries.getContact, {id: contactId}));
                const contact = contactResult.data.getContact;
                setContact({...contact});
                // console.log(contact.balances);
                if (contact.balances){
                    setBalances(contact.balances);
                }
            }
        }
        getData();
    }, [userContext.tenant])

    useEffect(()=>{
        console.log(balances);
        if (contact.balances){
            if (balances !== contact.balances){
                setUpdateBalances(true);
                console.log('update is true')
            } else {
                setUpdateBalances(false);
            }
        } else {
            if (balances.length > 0){
                setUpdateBalances(true)
            } else {
                setUpdateBalances(false)
            }
        }
        
    },[balances]);

    function handleBalanceClick(){
        setOpenBalances(!openBalances);
    }

    async function saveBalances(){
        delete contact.balances;
        delete contact.createdAt;
        delete contact.updatedAt;
        const updateContact = {...contact, balances: balances};
        try{
            const updateContactResult = await API.graphql(graphqlOperation(mutations.updateContact, {input: updateContact}));
            enqueueSnackbar('Saved Contact');
        } catch (err){
            console.error(err);
        }


    }

    return (
            <Grid item container direction="column">
                <Paper>
                    <List dense={true}>
                        <ListSubheader color="primary">Contact Balances</ListSubheader>
                        <Divider />
                        <Container>
                            <ListItem>
                                <ListItemText primary={
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            Id
                                        </Grid>
                                        <Grid item>
                                            Type
                                        </Grid>
                                        <Grid item>
                                            Amount
                                        </Grid>
                                        <Grid item>
                                            Age(days)
                                        </Grid>
                                    </Grid>
                                } />
                            </ListItem>
                            {balances.map(data => (
                                <Draggable>
                                    <ListItem >
                                        <ListItemText primary={
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    {data.id}
                                                </Grid>
                                                <Grid item>
                                                    {data.type}
                                                </Grid>
                                                <Grid item>
                                                    ${data.amount}
                                                </Grid>
                                                <Grid item>
                                                    {data.age}
                                                </Grid>
                                            </Grid>
                                        } />
                                    </ListItem>
                                </Draggable>
                                ))}
                        </Container>
                    </List>
                    <br></br>
                    <Grid item container direction="row" justify="space-between" className={classes.paper}>
                        <Grid item>
                            <Button variant="contained" onClick={handleBalanceClick} color="primary">Edit Balances</Button>
                        </Grid>
                        <div hidden={!updateBalances}>
                            <Grid item>
                                <Button variant="contained" onClick={saveBalances} color="primary">Save Changes</Button>
                            </Grid>
                        </div>
                    </Grid>
                </Paper>

                {/* Balances Dialog*/}
                <BalancesDialog openBalances={openBalances} setOpenBalances={setOpenBalances} balances={balances} setBalances={setBalances}/>

            </Grid>
    )
}
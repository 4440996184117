import React from "react";

import {
    FormControlLabel,
    Grid,
    TextField,
    Switch,
    Checkbox,

} from "@material-ui/core";

export default function C2ACommunication(props) {
    const { formikProps, classes } = props;

    function handleOptoutCheckChange(checked, field) {
        if (checked) {
            formikProps.setFieldValue('c2a.optOut.fields', [...formikProps.values?.c2a?.optOut?.fields ?? [], field]);
        } else {
            formikProps.setFieldValue('c2a.optOut.fields', (formikProps.values?.c2a?.optOut?.fields ?? []).filter(x => x !== field));
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Grid container direction="column" spacing={2}>
                    {formikProps?.values?.c2a?.optOutEnabled && (
                        <>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formikProps?.values?.c2a
                                                    ?.optOut
                                                    ?.finalize ?? false
                                            }
                                            onChange={
                                                formikProps.handleChange
                                            }
                                            onBlur={
                                                formikProps.handleBlur
                                            }
                                            name="c2a.optOut.finalize"
                                            color="primary"
                                        />
                                    }
                                    label="Finalize opting out of communication methods"
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    label="Message"
                                    name="c2a.optOut.text"
                                    type="text"
                                    className={classes.textArea}
                                    variant="outlined"
                                    multiline
                                    rows={12}
                                    value={
                                        formikProps?.values?.c2a?.optOut
                                            ?.text ?? ""
                                    }
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={
                                        formikProps.errors?.c2a?.optOut
                                            ?.text &&
                                        formikProps.touched?.c2a?.optOut
                                            ?.text
                                    }
                                    helperText={
                                        formikProps.touched?.c2a?.optOut
                                            ?.text &&
                                        formikProps.errors?.c2a?.optOut
                                            ?.text
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    alignItems="center"
                                    alignContent="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        (formikProps.values.c2a?.optOut?.fields ?? []).includes('Voice')
                                                    }
                                                    onChange={
                                                        e => handleOptoutCheckChange(e.target.checked, 'Voice')
                                                    }
                                                />
                                            }
                                            label="Opt Out Voice"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        (formikProps.values.c2a?.optOut?.fields ?? []).includes('SMS')
                                                    }
                                                    onChange={
                                                        e => handleOptoutCheckChange(e.target.checked, 'SMS')
                                                    }
                                                />
                                            }
                                            label="Opt Out SMS"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        (formikProps.values.c2a?.optOut?.fields ?? []).includes('Email')
                                                    }
                                                    onChange={
                                                        e => handleOptoutCheckChange(e.target.checked, 'Email')
                                                    }
                                                />
                                            }
                                            label="Opt Out Email"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

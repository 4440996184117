import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, LinearProgress, Typography, Dialog, DialogContent, Input } from '@material-ui/core';
import { getRoleAndAdmin, getAppUser, getCifChannel, getBaseBU, whoAmI, createAppUser, assignRole, createCifChannel } from '../E2/MSALConfig';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { useSnackbar } from 'notistack';
import { Button } from 'aws-amplify-react';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    selectFields: {
        minWidth: '220px',
        maxWidth: '220px'
    },
    main: {
        height: '300px'
    },
    progressBar: {
        width: '15%'
    },
    error: {
        color: 'red',
        fontSize: '10pt'
    },
    info: {
        fontSize: '10pt'
    }
}));

export default function NewProfile(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [profileName, setProfileName] = useState('');
    const [org, setOrg] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showUsernamePopup, setShowUsernamePopup] = useState(false);
    const [systemAdminRoleId, setSystemAdminRoleId] = useState(-1);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showProgress, setShowProgress] = useState(false);
    const [success, setSuccess] = useState(false);
    const [onPrem, setOnPrem] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getData(){
            setProfileName(props.profileName)
            setOrg(props.selOrg);
            if(props.selOrg){
                setSuccess(true);
            }
            if (props.onPremAuthUrl !== ''){
                setOnPrem(true);
            }
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const handleNameChange = (event) => {
        const name = event.target.value;
        setProfileName(name);
        props.setProfileName(name);
    }

    const handleSelectOrg = (event) => {
        const selectedOrg = event.target.value;
        setOrg(selectedOrg);
        updateOrg(selectedOrg);
    }

    async function updateOrg(selectedOrg){
        setSuccess(false);
        setError('');
        setShowError(false);
        setShowProgress(true);
        setProgress(0);
        let e2RoleId = -1;
        try {
            const roles = await getRoleAndAdmin(selectedOrg);
            if (roles.length === 1){
                showErrorMessage('The Customer Dynamics Package has not been installed for this organization.');
            } else {
                e2RoleId = roles[0].roleid;
                setSystemAdminRoleId(roles[1].roleid);
            }
            
            if (e2RoleId !== -1){
                setProgress(14);
                const appUser = await getAppUser(selectedOrg, props.appId);
                setProgress(28);
                if (appUser.length <= 0) { 
                    const baseBu = await getBaseBU(selectedOrg);
                    setProgress(42);
                    if (baseBu[0].businessunitid){
                        const createUserResponse = await createAppUser(selectedOrg, baseBu[0].businessunitid);
                        if (createUserResponse === 204){
                            setProgress(56);
                            const newUser = await getAppUser(selectedOrg, props.appId);
                            if(newUser[0].systemuserid){
                                const assignResponse = await assignRole(selectedOrg, newUser[0].systemuserid, e2RoleId);
                                if (assignResponse === 204){
                                    setProgress(70);
                                }
                            } else {
                                showErrorMessage('An error occured retrieving the application user.  Please contact us at support@customerdynamics.com.');
                            }
                        } else {
                            showErrorMessage('An error occured when trying to create the application user.  Please contact us at support@customerdynamics.com.');
                        }
                    } else {
                        showErrorMessage('An error occured when trying to retrieve the business unit.  Please contact us at support@customerdynamics.com.');
                    }
                }
                setProgress(84);
                const cifChannel = await getCifChannel(selectedOrg);
                if (cifChannel.length > 0 ){
                    setProgress(100);
                    props.setSelOrg(selectedOrg);
                    setSuccess(true);
                } else {
                    if (username === '') {
                      setShowUsernamePopup(true);
                    }
                }
            }
        } catch (err){
            console.error(err);
        }
    }

    async function createCIF() {
      if (systemAdminRoleId !== -1){
        const systemUserId = await whoAmI(org);
        const createCifResponse = await createCifChannel(org, systemAdminRoleId, systemUserId, userContext.tenant, username, password, userContext.apiKey);
        if (createCifResponse === 204){
            setProgress(100);
            props.setSelOrg(org);
            setSuccess(true);
        } else {
            showErrorMessage('An error occured when trying to create a CIF Channel for this orginization.  Please contact us at support@customerdynamics.com.');
        }
      } else {
          enqueueSnackbar('There was an error retrieving the System Administrator Id.  You will need to manually create your CIF Channel.', {
              autoHideDuration: 5000
          })
          setProgress(100);
          props.setSelOrg(org);
          setSuccess(true);
      }
    }

    function showErrorMessage(message) {
      setError(message);
      setShowError(true);
      setShowProgress(false);
    }

    function closeUsernamePopup() {
      setShowUsernamePopup(false);
    }

    function saveUsernamePopup() {
      setShowUsernamePopup(false);
      createCIF();
    }

    const handleUsernameChange = (event) => {
      const newUsername = event.target.value;
      setUsername(newUsername);
    }

    const handlePasswordChange = (event) => {
      const newPassword = event.target.value;
      setPassword(newPassword);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
              <>
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justifyContent="flex-start" alignItems="stretch">
                        <Grid item xs={12}>
                            <TextField
                                key="name"
                                autoFocus
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                value={profileName}
                                required={true}
                                className={classes.selectFields}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item container direction="row" spacing={4} alignItems="center" hidden={onPrem}>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="organization">Organization*</InputLabel>
                                        <Select
                                            key="orgSelect"
                                            labelId="orginization"
                                            name="origanization"
                                            value={org}
                                            displayEmpty
                                            onChange={handleSelectOrg}
                                            className={classes.selectFields}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {props.orgs.map(data =>(
                                                <MenuItem value={data} key={data.FriendlyName}>{data.FriendlyName}</MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>
                            </Grid>
                            <div hidden={!success}>
                                <Grid item>
                                    <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item container direction="row">
                            <div hidden={!showError}>
                                <Grid item>
                                    <a href="mailto: support@customerdynamics.com" className={classes.error}>{error}</a>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div hidden={!showProgress}>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={progress}></LinearProgress>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.info}>*If an organization you were expecting to see is not listed, you may not have the correct permissions for that organization.</Typography>
                    </Grid>
                </div>
                <Dialog open={showUsernamePopup} maxWidth='md'>
                    <DialogContent>
                        <Grid container direction="column">
                            <TextField
                                key="username"
                                autoFocus
                                margin="dense"
                                name="username"
                                label="Username"
                                type="text"
                                value={username}
                                required={true}
                                className={classes.selectFields}
                                onChange={handleUsernameChange}
                              />
                            <TextField
                                key="password"
                                autoFocus
                                margin="dense"
                                name="password"
                                label="Password"
                                type="text"
                                value={password}
                                required={true}
                                className={classes.selectFields}
                                onChange={handlePasswordChange}
                              />
                        </Grid>
                        <Grid container direction="row" justifyContent="space-between">
                            <Button onClick={closeUsernamePopup} variant="outlined" color="primary">Cancel</Button>
                            <Button onClick={saveUsernamePopup} variant="contained" color="primary">Save</Button>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
            )}
        </UserContext.Consumer>
    );
}
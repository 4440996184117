import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, 
    FormControl,
    InputLabel, 
    Select, 
    MenuItem, 
    Typography, 
    ListSubheader, 
    CircularProgress } from '@material-ui/core';
import * as _ from 'lodash';
import { getSearchEntities } from '../E2/MSALConfig';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        minHeight: '300px'
    },
    entities: {
        cursor: 'pointer'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    search: {
        padding: '1em'
    }
}));

export default function SearchConfig(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [searchEntities, setSearchEntities] = useState([
        {SchemaName: 'Account'},
        {SchemaName: 'Contact'},
        {SchemaName: 'Lead'},
        {SchemaName: 'Activity'},
        {SchemaName: 'Incident'},
        {SchemaName: 'Order'},
        {SchemaName: 'Opportunity'},
    ]);
    const [selSearchEntity, setSelSearchEntity] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [loadingEntities, setLoadingEntities] = useState(false);

    useEffect(() => {
        async function stopLoad(){
            setLoadingEntities(false);
        }
        if (searchEntities !== null && setSearchEntities !== []){
            stopLoad();
        }
    }, [searchEntities])

    useEffect(() => {
        async function getData(){
            setSelSearchEntity(props.entity)
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const handleSearchEntity = async (event) => {
        const searchEntity = event.target.value;
        if (searchEntity !== 'expandList' && searchEntity !== 'shrinkList'){
            setSelSearchEntity(searchEntity);
            props.setEntity(searchEntity);
        } else if (searchEntity === 'expandList'){
            setLoadingEntities(true);
            const expandedEntities = await getSearchEntities(props.org);
            const sortedEntities = _.sortBy(expandedEntities, ['SchemaName']);
            setSearchEntities([...sortedEntities]);
            setExpanded(true);
        } else if (searchEntity === 'shrinkList'){
            setLoadingEntities(true);
            let defaults = [
                {SchemaName: 'Account'},
                {SchemaName: 'Contact'},
                {SchemaName: 'Lead'},
                {SchemaName: 'Activity'},
                {SchemaName: 'Incident'},
                {SchemaName: 'Order'},
                {SchemaName: 'Opportunity'},
            ];
            setSearchEntities([...defaults]);
            setExpanded(false);
        } 
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Typography className={classes.heading}>Search Settings</Typography>
                            <Grid item container direction="row" alignItems="center" justify="flex-start" spacing={4}>
                                <div className={classes.wrapper}>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel shrink id="searchFor">Search Entity</InputLabel>
                                            <Select
                                                labelId="searchFor"
                                                name="searchFor"
                                                value={selSearchEntity}
                                                displayEmpty
                                                className={classes.selectFields}
                                                onChange={handleSearchEntity}
                                            >
                                                {searchEntities.map(data =>(
                                                    <MenuItem value={data.SchemaName}>{data.SchemaName}</MenuItem>
                                                ))}
                                                <div className={classes.entities} hidden={expanded} value={'expandList'} >
                                                    <ListSubheader color="primary">See all entities...</ListSubheader>
                                                </div>
                                                <div className={classes.entities} hidden={!expanded} value={'shrinkList'}>
                                                    <ListSubheader color="primary">See only default entities...</ListSubheader>
                                                </div>
                                            </Select>
                                        </FormControl>
                                        {loadingEntities && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Grid>
                                </div>
                            </Grid>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React, { useEffect, useContext, useState } from 'react';
import {Grid, TextField, FormControl, Select, MenuItem, InputLabel, LinearProgress} from '@material-ui/core';
import StepperController from './StepperController';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { API, Auth } from 'aws-amplify';
import UserContext from '../../context/UserContext';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import _ from 'lodash';
import { useHistory } from "react-router-dom";

export default function InitialInformation(props) {
    const history = useHistory();
    const {nextStep, setInitialInformation, initialInformation, classes, setVendorStatus} = props;
    const {enqueueSnackbar} = useSnackbar();
    const userContext = useContext(UserContext);
    const [cdyxTenants, setCdyxTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState("None");
    const [loading, setLoading] = useState(false);
    const [ppayVendorForValidation, setppayVendorForValidation] = useState("");
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        async function getTenants() {
            setLoading(true);
            try{
                const tenants = await API.get('cdyxdialer', '/admin/tenant', {
                    headers:{
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey,
                        'Content-Type': 'application/json',
                    }
                });

                if(tenants) {
                    setCdyxTenants(tenants);
                }
            } catch (err) {
                console.log(err);
            }

            setLoading(false);
        }

        if(userContext.apiKey) {
            getTenants();
        }
    }, [userContext])

    function checkTenantSelected() {
        for(const tenant of cdyxTenants) {
            if (tenant.id === ppayVendorForValidation) {
                setSelectedTenant(ppayVendorForValidation);
                break;
            }
        }
    }

    useEffect(() => {
        checkTenantSelected();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ppayVendorForValidation, cdyxTenants]);

    useEffect(() => {
        setppayVendorForValidation(initialInformation.ppayVendor);
    }, [initialInformation]);

    function handleSubmitFail(error) {
        if(error.response.status === 403) {
            enqueueSnackbar("A vendor with that id already exists", { variant: 'error' });
        } else {
            enqueueSnackbar("Unable to create vendor", { variant: 'error' });
        }
        setSubmitting(false);
    }

    function handleSubmitSuccess(values) {
        enqueueSnackbar("Vendor saved", { variant: 'success' });
        setInitialInformation(values);
        history.replace("/admin/onboarding/edit/" + values.ppayVendor);
        nextStep();
    }
    
    return (
        <div>
            {(loading || submitting) && <LinearProgress variant='indeterminate' color='secondary' />}
            <Formik
                initialValues={initialInformation}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .trim().required('Please provide a name')
                        .trim().matches(/^[a-zA-Z0-9 _.-]+$/, 'Please enter a valid name'),
                    ppayVendor: Yup.string()
                        .trim().required("The PromptPay vendor is required")
                        .trim().matches(/^[a-zA-Z0-9 _.-]+$/, 'Please enter a valid PromptPay vendor')
                    })}
                onSubmit={async (values) => {
                    setSubmitting(true);

                    // If the form was modified
                    if (!_.isEqual(values, initialInformation)) {
                        // If our original values were empty, then we need to create the tenant for the first time.
                        
                        if (_.isEqual(initialInformation, {name: "", cdyxTenant: "", ppayVendor: ""})) {
                            await axios.post(`${process.env.REACT_APP_PP_URL}/cms-onboard/create-vendor`, {
                                id: values.ppayVendor,
                                name: values.name,
                                cmsInfo: {
                                    cmsMerchId: "",
                                    cmsMerchHash: "",
                                    cmsAppToken: ""
                                }
                            }, {
                                headers: {
                                    'x-api-key': process.env.REACT_APP_PP_API_KEY
                                }
                            }).then(() => {handleSubmitSuccess(values); setVendorStatus("SignUp");}).catch(handleSubmitFail);
                        } else {
                            // If not, then the user likely just needs to save a new id or name.
                            await axios.put(`${process.env.REACT_APP_PP_URL}/cms-onboard/modify-vendor-id`, {
                                oldId: initialInformation.ppayVendor,
                                newId: values.ppayVendor,
                                newName: values.name
                            }, {
                                headers: {
                                    'x-api-key': process.env.REACT_APP_PP_API_KEY
                                }
                            }).then(() => handleSubmitSuccess(values)).catch(handleSubmitFail);
                        }
                    } else nextStep();

                    setSubmitting(false);
                }}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextField 
                                    label="Name" 
                                    name="name"
                                    className={props.classes.longTextField} 
                                    required 
                                    value={formikProps.values.name} 
                                    error={formikProps.errors.name && formikProps.touched.name} 
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    helperText={formikProps.errors.name}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.longTextField}>
                                    <InputLabel id="cdyxTenant">CDYX Tenant</InputLabel>
                                    <Select
                                        labelId="cdyxTenant"
                                        name="cdyxTenant"
                                        value={selectedTenant}
                                        onChange={event => {setSelectedTenant(event.target.value); if(event.target.value !== "None") formikProps.setFieldValue("ppayVendor", event.target.value, true); formikProps.handleChange(event);}}
                                    >
                                        <MenuItem value={"None"} key={"None"}>None</MenuItem>
                                        {cdyxTenants.map((tenant) => 
                                            <MenuItem value={tenant.id} key={tenant.id}>{tenant.id}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    label="PromptPay Vendor" 
                                    name="ppayVendor"
                                    required
                                    disabled={selectedTenant !== "None"}
                                    className={props.classes.longTextField} 
                                    value={formikProps.values.ppayVendor}
                                    error={formikProps.errors.ppayVendor && formikProps.touched.ppayVendor} 
                                    onChange={event => {formikProps.handleChange(event); setppayVendorForValidation(event.target.value);}}
                                    onBlur={formikProps.handleBlur}
                                    helperText={formikProps.errors.ppayVendor}
                                />
                            </Grid>
                        </Grid>
                        <StepperController backDisabled nextIsFormSubmit nextDisabled={ppayVendorForValidation === "new" || submitting} />
                    </form>
                )}
            </Formik>
        </div>
    );
}
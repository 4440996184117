import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Prerequisites from '../E2/Prerequisites';
import NewProfile from '../E2/NewProfile';
import MediaEntity from '../E2/MediaEntity';
import SearchConfig from './SearchConfig';
import Actions from '../E2/Actions';
import MasterLogging from '../E2/MasterLogging';
import TransactionLogging from '../E2/TransactionLogging';
import PostTransactionLogging from '../E2/PostTransactionLogging';
import TransferOptions from './TransferOptions';
import Review from '../E2/Review';
import E2Icons from '../E2/E2Icons';
import { API, graphqlOperation} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import UserContext from '../context/UserContext';
import { getOrgs, getEntities, getFields, setCustClientId, getAllAccounts } from '../E2/MSALConfig';
import { availableMeta, postTransactionMeta } from '../E2/mappings';
import { login, getConfigurationIcSkills } from '../context/InContact'
import { useSnackbar } from 'notistack';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Dialog, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '1em'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    padding: '1em',
    height: '100%'
  },
  paperHeight: {
    minHeight: '85vh',
    overflowY: 'auto',
    padding: '1em',
    width: '100%'
  },
  stepHeight: {
    height: '100%',
    flexWrap: 'nowrap'
  },
  fullHeight: {
    height: '77vh'
  }
}));

const ConfigProfile = function(props) {
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState('');
  const [comingFrom, setComingFrom] = useState('');
  const [accept, setAccept] = useState(false);
  const [profileName, setProfileName] = useState('');
  const [selOrg, setSelOrg] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [selMediaType, setSelMediaType] = useState('');
  const [activityType, setActivityType] = useState('');
  const [entities, setEntities] = useState([]);
  const [selEntity, setSelEntity] = useState('');
  const [skills, setSkills] = useState([]);
  const [selSkills, setSelSkills] = useState([]);
  const [search, setSearch] = useState([]);
  const [multiSearch, setMultiSearch] = useState(false);
  const [openClosed, setOpenClosed] = useState(false);
  const [searchType, setSearchType] = useState(false);
  const [noMatch, setNoMatch] = useState('');
  const [singleMatch, setSingleMatch] = useState('');
  const [multiMatch, setMultiMatch] = useState('');
  const [fields, setFields] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [postMetaData, setPostMetaData] = useState([]);
  const [loggingAction, setLoggingAction] = useState('');
  const [searchSettings, setSearchSettings] = useState(false);
  const [masterLoggings, setMasterLoggings] = useState([]);
  const [transactionLoggings, setTransactionLoggings] = useState([]);
  const [postTransactionLoggings, setPostTransactionLoggings] = useState([]);
  const [transferSkills, setTransferSkills] = useState({}); 
  const [uiInfo, setUIInfo] = useState({});
  const [closeActivity, setCloseActivity] = useState(true);
  const [openLoggingDialog, setOpenLoggingDialog] = useState(false);

  const [onPremAccessUrl, setOnPremAccessUrl] = useState('');
  const [onPremVersion, setOnPremVersion] = useState('');
  const [onPremAuthUrl, setOnPremAuthUrl] = useState('');

  const [tenantId, setTenantId] = useState('');
  const [clientId, setClientId] = useState('');
  const [appSecret, setAppSecret] = useState('');

    //Microsoft Accounts
    const [microsoftAccounts, setMicrosoftAccounts] = useState([]);
    const [selMicrosoftAccount, setMicrosoftAccount] = useState();
  
  useEffect(() => {
    async function getData(){
      const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
      const tenantParams = dynamoSettings.data.getTenantSettings;
      if (tenantParams.onPremAccessUrl){
        setOnPremAccessUrl(tenantParams.onPremAccessUrl);
        setOnPremVersion(tenantParams.onPremVersion);
        setOnPremAuthUrl(tenantParams.onPremAuthUrl);
        if (tenantParams.onPremAuthUrl){
          await setCustClientId(tenantParams.e2ClientId, 'Manual Auth', tenantParams.e2ClientSecret, tenantParams.onPremAuthUrl, userContext.apiKey, tenantParams.onPremAccessUrl);
        } else {
          await setCustClientId(tenantParams.e2ClientId, 'MSAL Auth');
        }
        const onPremOrg = {
            FriendlyName: 'On Prem Connection',
            Id: 'On Prem Connection',
            Url: tenantParams.onPremAccessUrl,
            Version: tenantParams.onPremVersion
        }
          setSelOrg({...onPremOrg});
      }
      setAppSecret(tenantParams.e2ClientSecret);
      setClientId(tenantParams.e2ClientId);
      setTenantId(tenantParams.e2DynamTenantId);
      if (props.location.state !== null){
        setComingFrom(props.location.state.comingFrom);
      } else {
        setComingFrom('New');
      }
      if (comingFrom === 'New'){
        setTitle('New Configuration Profile')
      } else if (comingFrom === 'Edit'){
        setTitle('Editing ' + props.location.state.profileData.name);
      } else if (comingFrom === 'Clone'){
        setTitle(props.location.state.profileData.name + ' - Clone')
      }
    }
    if (userContext.tenant){
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.tenant]);

  useEffect(() => {
    async function getData(){
      const entities = await getEntities(selOrg);
      if (entities){
        setEntities([...entities]);
      }
    }
    if (selOrg){
      getData();
    }
  }, [selOrg]);

  useEffect(() => {
    async function getData(){
      let updatedMetaData=[];
      for (const metaValue of availableMeta){
        if (metaValue.mediaType.indexOf(selMediaType) !== -1){
          updatedMetaData.push(metaValue);
        }
      }
      setMetaData(updatedMetaData);
    }
    if (selMediaType){
      getData();
    }
  }, [selMediaType]);

  useEffect(() => {
    async function getData(){
      const postMetaFilter = activityType + selMediaType;
      let updatedMetaData=[];
      for (const metaValue of postTransactionMeta){
        if (metaValue.mediaType.indexOf(postMetaFilter) !== -1){
          updatedMetaData.push(metaValue);
        }
      }
      setPostMetaData(updatedMetaData);
      const skills = await getSkills(selMediaType);
      let skillFilter = [];
      if (skills){
        if (activityType === 'Inbound'){
          for (const skill of skills){
            if (skill.isOutbound === false){
              skillFilter.push(skill);
            }
          }
        } else if (activityType === 'Outbound'){
          for (const skill of skills){
            if (skill.isOutbound === true){
              skillFilter.push(skill);
            }
          }
        }
        setSkills(skillFilter);
      }
    }
    if (activityType){
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityType, selMediaType])

  useEffect(() => {
    async function getData(){
      const entity = await _.find(entities, ['SchemaName', selEntity]);
      const fields = await getFields(selOrg, entity);
      if (fields){
        let tempFields = fields;
        _.remove(tempFields, ['SchemaName', 'To']);
        _.remove(tempFields, ['SchemaName', 'From']);
        _.remove(tempFields, ['SchemaName', 'Subject']);
        _.remove(tempFields, ['SchemaName', 'RegardingObjectId']);
        setFields(tempFields);
      }
    }
    if (selEntity){
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selEntity]);

  useEffect(() => {
    async function getData(){
      // If it is coming from new we don't want to do anything with uiInfo
      const action = props.location.state.comingFrom;

      const configProfile = await API.graphql(graphqlOperation(queries.configProfielsByTenant, {tenant: userContext.tenant}));
        const data = configProfile.data.configProfielsByTenant.items;

        // Filter skillId so that we only have the profile with skillId 999999999
        var defaultProfile;
        for(var profile in data){
            defaultProfile = data[profile];
            if(defaultProfile.skillIds[0] === "9999999999"){
                break;
            }
        }

        // If we didn't find a default profile then we just want to return
        if(defaultProfile === undefined){
            setUIInfo(undefined);
            return;
        }

        // Pull all the uiInfo from skillId and put it into our uiInfo
        const parsedProfile = JSON.parse(defaultProfile.uiInfo);
        if(selMediaType === 'phoneCall' || selMediaType === 'chat' || selMediaType === 'email'){
          setUIInfo(parsedProfile);
        }

      if(action === "Edit" || action === "Clone")
      {
        // Get the data of the uiInfo
        const uiInfo = JSON.parse(props.location.state.profileData.uiInfo);
        console.log(uiInfo);
        // If we are editing and we switch up our media type just set the ui customization to the baseConfig one 
        if(!(uiInfo.hasOwnProperty(selMediaType))){
          if(selMediaType === 'phoneCall' || selMediaType === 'chat' || selMediaType === 'email'){
            setUIInfo(parsedProfile);
          }
        }else{
          // Else we just parse out the information we already ahve 
          //const parsedInfo = JSON.parse(uiInfo);
          setUIInfo(uiInfo);
        }
      }
      // We are creating a new config profile so we want our uiInfo to be completely fresh
      else if(action === "New")
      {
        // We can pass in undefined so that our e2Icons can assign all the features;
        if(selMediaType === 'phoneCall' || selMediaType === 'chat' || selMediaType === 'email'){
          setUIInfo(parsedProfile);
        }
      }
    }
    if(userContext.tenant){
      getData();
    }
  },[userContext.tenant, selMediaType, props.location.state.comingFrom])

  async function getMicrosoftAccounts(){
    await setCustClientId(clientId, 'MSAL Auth');
    const accounts = await getAllAccounts();
    setMicrosoftAccounts([...accounts]);
  }

  async function pullOrgs(){
    const orgs = await getOrgs();
      let orgArray = [];
      if(orgs){
        for (const org of orgs){
          const newOrg = {
            FriendlyName: org.FriendlyName,
            Id: org.Id,
            Url: org.Url,
            Version: org.Version
          }
          orgArray.push(newOrg);
        }
        setOrgs(orgArray);
      }
      return orgArray;
  }

  async function getSkills(mediaType){
    const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
    const loginSettings = dynamoSettings.data.getTenantSettings;
    await login(loginSettings);
    if (mediaType === 'phoneCall'){    
        let retrievedSkills = await getConfigurationIcSkills(4);
        return retrievedSkills;
    } else if (mediaType === 'email'){
        const retrievedSkills = await getConfigurationIcSkills(1);
        return retrievedSkills;
    } else if (mediaType === 'chat'){
        const retrievedSkills = await getConfigurationIcSkills(3);
        return retrievedSkills;
    } else if (mediaType === 'voiceMail'){
        const retrievedSkills = await getConfigurationIcSkills(5);
        return retrievedSkills;
    } else if (mediaType === 'workItem'){
        const retrievedSkills = await getConfigurationIcSkills(6);
        return retrievedSkills;
    } else if (mediaType === 'social'){
        const retrievedSkills = await getConfigurationIcSkills(8);
        return retrievedSkills;
    }
}


async function getUsedSkills (){
  try{
    const curProfiles = await API.graphql(graphqlOperation(queries.listConfigProfiles, {tenant: userContext.tenant}));
    const usedProfiles = [];
    for (const profile of curProfiles.data.listConfigProfiles.items){
      const skills = profile.skillIds;
      for(const skill of skills){
        if (selSkills.indexOf(skill) === -1){
          usedProfiles.push(skill);
        }
      }
    }
    const usedSkillsConverted = [];
    for (const skill of usedProfiles){
        const parsedSkill = JSON.parse(skill);
        usedSkillsConverted.push(parsedSkill);
    }
    return usedSkillsConverted
  } catch (err){
    console.error('Error with retrieving used skills.  Please check tenants profile skills.');
    console.log(err);
    return [];
  }  
}

  function handleClose(){
    setOpenLoggingDialog(false);
  }

  function handleAccept(){
    if (activityType === 'Inbound'){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      if(loggingAction === 'none' && (selMediaType==='sms' || selMediaType === 'workItem' || selMediaType === 'social')){
        setActiveStep(10);
      }else{
        setActiveStep(8);
      }
    }
    if (comingFrom === 'Edit' || comingFrom === 'Clone'){
      setSearch(JSON.parse(props.location.state.profileData.search));
    }
    setOpenLoggingDialog(false);
  }

  function getSteps() {
    return ['Prerequisites',
    'Name profile and select Dynamics organization', 
    'Select media type, CRM entity, and skills',
    'Set search options',
    'Set search result pop actions',
    'Set master field loggings',
    'Set transaction loggings',
    'Set post transaction loggings',
    'Customize E2 User Interface',
    'Set Transfer Options',
    'Review and save'];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (<Prerequisites 
          accept={accept} 
          setAccept={setAccept} 
          e2ClientId={clientId} 
          e2AppSecret={appSecret}
          dynamicsTenant={tenantId} 
          getMicrosoftAccounts={getMicrosoftAccounts} 
          getOrgs={pullOrgs} orgs={orgs} 
          onPremAccessUrl={onPremAccessUrl} 
          microsoftAccounts={microsoftAccounts}
          selMicrosoftAccount={selMicrosoftAccount}
          setMicrosoftAccount={setMicrosoftAccount}></Prerequisites>);
      case 1:
        return (<NewProfile
          orgs={orgs} 
          selOrg={selOrg}
          appId={clientId}
          setSelOrg={setSelOrg}
          profileName={profileName}
          onPremAuthUrl={onPremAuthUrl}
          setProfileName={setProfileName}></NewProfile>);
      case 2:
        return (<MediaEntity
          appId={clientId} 
          entities={entities}
          selEntity={selEntity}
          setSelEntity={setSelEntity}
          activityType = {activityType}
          setActivityType={setActivityType}
          selMediaType={selMediaType}
          setSelMediaType={setSelMediaType}
          loggingAction = {loggingAction}
          setLoggingAction={setLoggingAction}
          searchSettings={searchSettings}
          setSearchSettings={setSearchSettings}
          skills={skills}
          selSkills={selSkills}
          setSelSkills={setSelSkills}
          getUsedSkills={getUsedSkills}
          masterLoggings={masterLoggings}
          setMasterLoggings={setMasterLoggings}
          transactionLoggings={transactionLoggings}
          setTransactionLoggings={setTransactionLoggings}
          postTransactionLoggings={postTransactionLoggings}
          setPostTransactionLoggings={setPostTransactionLoggings}></MediaEntity>);
      case 3:
        return (<SearchConfig org={selOrg}
          setMultiSearch={setMultiSearch}
          multiSearch={multiSearch}
          setSearch={setSearch}
          search={search}
          crmEntity={selEntity}
          mediaType={selMediaType}
          openClosed={openClosed}
          setOpenClosed={setOpenClosed}></SearchConfig>);
      case 4:
        return (<Actions 
          noMatch={noMatch} 
          setNoMatch={setNoMatch}
          singleMatch={singleMatch}
          setSingleMatch={setSingleMatch}
          multiMatch={multiMatch}
          setMultiMatch={setMultiMatch}
          searchType={searchType}
          setSearchType={setSearchType}
          ></Actions>);
      case 5:
        return (<MasterLogging masterLoggings={masterLoggings} setMasterLoggings={setMasterLoggings} activityType={activityType}></MasterLogging>);
      case 6:
        return (<TransactionLogging meta={metaData} fields={fields} masterLoggings={masterLoggings} transactionLoggings={transactionLoggings} setTransactionLoggings={setTransactionLoggings} setFields={setFields}></TransactionLogging>);
      case 7:
        return (<PostTransactionLogging meta={postMetaData} fields={fields} masterLoggings={masterLoggings} closeActivity={closeActivity} setCloseActivity={setCloseActivity} transactionLoggings={transactionLoggings} postTransactionLoggings={postTransactionLoggings} setPostTransactionLoggings={setPostTransactionLoggings}></PostTransactionLogging>);
      case 8:
        return (<E2Icons mediaType={selMediaType} permissions={uiInfo} setPermissions={setUIInfo}></E2Icons>);
      case 9: 
        return (<TransferOptions transferSkills={transferSkills} setTransferSkills={setTransferSkills} />);
      case 10: 
        return (<Review 
          name={profileName} 
          org={selOrg}
          preview={false}
          mediaType={selMediaType} 
          direction={activityType}
          skills={selSkills}
          allSkills={skills}
          multiSearch={multiSearch}
          search={search}
          noMatch ={noMatch}
          singleMatch={singleMatch}
          multiMatch={multiMatch}
          masterLoggings={masterLoggings}
          transactionLoggings={transactionLoggings}
          postTransactionLoggings={postTransactionLoggings}
          uiInfo={uiInfo}
          closeActivity={closeActivity}>
          </Review>);
      default:
        return 'Unknown stepIndex';
    }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 0){
      if (accept === true){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (comingFrom === 'Edit'){
            setProfileName(props.location.state.profileData.name);
            const configOrg = _.find(orgs, ['FriendlyName', props.location.state.profileData.org.name]);
            if(!onPremAccessUrl){
              setSelOrg(configOrg);
            }
        }
        if (comingFrom === 'Clone'){
            setProfileName(props.location.state.profileData.name + ' - Clone');
            const configOrg = _.find(orgs, ['FriendlyName', props.location.state.profileData.org.name]);
            if (!onPremAccessUrl){
              setSelOrg(configOrg);
            }
        }
      } else {
          enqueueSnackbar('You must run and pass the prerequisites test.', {
            variant: 'error'
          })
      }
    } else if (activeStep === 1){
      if (profileName !== null && profileName !== ''){
        if (selOrg !== null && selOrg !== ''){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (comingFrom === 'Edit' || comingFrom === 'Clone'){
            setSelMediaType(props.location.state.profileData.mediaType);
            setActivityType(props.location.state.profileData.direction);
            setLoggingAction(props.location.state.profileData.loggingAction);
            setSearchSettings(Boolean(props.location.state.profileData.searchSettings));
            setSelEntity(props.location.state.profileData.crmEntity);
            setCloseActivity(props.location.state.profileData.closeActivity);
            setTransferSkills(JSON.parse(props.location.state.profileData.transferSkills));
          }
          if (comingFrom === 'Edit'){
            let skills = props.location.state.profileData.skillIds
            setSelSkills(skills);
          }
        } else {
          enqueueSnackbar('You must select an orginization that has the Customer Dynamics package properly installed.', {
            variant: 'error'
          })
        } 
      } else {
          enqueueSnackbar('Please enter a name for the profile', {
            variant: 'error'
          })
      }
    } else if (activeStep === 2){
      if (selMediaType !== null && selMediaType !== ''){
        if (activityType !== null && activityType !== ''){
          if (selSkills.length > 0){
            if (loggingAction !== 'none' && loggingAction !== null){
              if (selEntity !== null && selEntity !== ''){
                if(activityType === 'Inbound' || searchSettings){
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                  setActiveStep(5)
                }
                if (comingFrom === 'Edit' || comingFrom === 'Clone'){
                  setMultiSearch(JSON.parse(props.location.state.profileData.multiSearch));
                  setSearch(JSON.parse(props.location.state.profileData.search));
                  if(loggingAction !== 'none'){
                    setMasterLoggings(JSON.parse(props.location.state.profileData.activityEntityMap))
                    setTransactionLoggings(JSON.parse(props.location.state.profileData.logging));
                    setPostTransactionLoggings(JSON.parse(props.location.state.profileData.postLogging)); 
                  } else {
                    setMasterLoggings([]);
                    setTransactionLoggings([]);
                    setPostTransactionLoggings([]);
                  }
                }
  
              } else {
                enqueueSnackbar('Please select an activity logging entity', {
                  variant: 'error'
                });
              }
            } else {
              setOpenLoggingDialog(true);
            }
          } else {
            enqueueSnackbar('You must select at least one skill for the profile', {
              variant: 'error'
            });
          }
        } else {
          enqueueSnackbar('You must select an activity type', {
            variant: 'error'
          });
        }
      } else {
        enqueueSnackbar('You must select a media type', {
          variant: 'error'
        })
    }
    } else if (activeStep === 3){
      if (selSkills.length < 1){
        selSkills.push('0')
      }
      if (search.length >= 1){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (comingFrom === 'Edit' || comingFrom === 'Clone'){
          const actions = JSON.parse(props.location.state.profileData.searchAction);
          setNoMatch(actions[0].noMatch);
          setSingleMatch(actions[1].singleMatch);
          setMultiMatch(actions[2].multiMatch);
        }
      } else {
          enqueueSnackbar('You must add at least one search setting', {
            variant: 'error'
          })
      }
    } else if (activeStep === 4){
      if (noMatch !== null && noMatch !== '' && singleMatch !== null && singleMatch !== '' && multiMatch !== null && multiMatch !== ''){
        if (loggingAction !== 'none'){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if(loggingAction === 'none' && (selMediaType === 'workItem' || selMediaType === 'social' || selMediaType === 'sms')){
          setActiveStep(10);
        }
        else {
          setActiveStep(8);
        } 
      } else {
          enqueueSnackbar('Please set all search actions', {
            variant: 'error'
          })
      }
    } else if (activeStep === 5 || activeStep === 6 || activeStep === 7 || activeStep === 8 || activeStep === 9){
        if((selMediaType === 'workItem' || selMediaType === 'social' || selMediaType === 'sms') && activityType === 'Outbound' && loggingAction === 'none'){
          console.log("hey")
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === steps.length - 1){
      submitProfile();
    }
  };

  const handleBack = () => {
    if (activeStep === 5 && activityType === 'Outbound'){
      setActiveStep(2);
    } else if (activeStep === 10 && loggingAction === 'none' && activityType === 'Outbound' && (selMediaType === 'workItem' || selMediaType === 'social' || selMediaType === 'sms')){
      setActiveStep(2);
    } else if (activeStep === 10 && loggingAction === 'none' && activityType === 'Inbound' && (selMediaType === 'workItem' || selMediaType === 'social' || selMediaType === 'sms')){
      setActiveStep(4);
    } else if (activeStep === 9 && loggingAction === 'none' && activityType === 'Outbound') {
      setActiveStep(2)
    } else if (activeStep === 9 && loggingAction === 'none' && activityType === 'Inbound'){
      setActiveStep(4)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

async function submitProfile(){
    try{
      const dynamoOrg = {
        name: selOrg.FriendlyName,
        id: selOrg.Id,
        url: selOrg.Url,
        version: selOrg.Version
      };

      const searchAction = [
        {noMatch: noMatch},
        {singleMatch: singleMatch},
        {multiMatch: multiMatch}
      ];

      let connection = {
        appSecret: appSecret,
        clientId: clientId
      };

      if(tenantId){
        // connection = {
        //   ...connection,
        //   dynamicsTenant: tenantId
        // }
        connection.dynamicsTenant = tenantId;
      };

      if(onPremAccessUrl){
        // connection = {
        //   ...connection,
        //   altUrl: onPremAccessUrl,
        //   onPremVersion: onPremVersion
        // }
        connection.altUrl = onPremAccessUrl;
        connection.onPremVersion = onPremVersion;
      };

      if(onPremAuthUrl){
        // connection = {
        //   ...connection,
        //   authUrl: onPremAuthUrl
        // }
        connection.authUrl = onPremAuthUrl;
      }

      // Filter down the json to what is only necessary by mediaType
      if(selMediaType === 'phoneCall'){
        delete uiInfo.chat;
        delete uiInfo.email;
      }
      else if(selMediaType === 'chat'){
        delete uiInfo.phoneCall;
        delete uiInfo.email;
      }
      else if(selMediaType === 'email'){
        delete uiInfo.phoneCall;
        delete uiInfo.chat;
      }
      const profile = {
        tenant: userContext.tenant,
        name: profileName,
        mediaType: selMediaType,
        crmEntity: selEntity,
        direction: activityType,
        org: dynamoOrg,
        connection: connection,
        skillIds: selSkills,
        multiSearch: multiSearch,
        openClosed,
        searchType,
        search: JSON.stringify([...search]),
        searchAction: JSON.stringify([...searchAction]),
        activityEntityMap: JSON.stringify([{entity: 'ContactID', field: 'cdx_incontactid'},...masterLoggings]),
        logging: JSON.stringify([...transactionLoggings]),
        postLogging: JSON.stringify([...postTransactionLoggings]),
        closeActivity: closeActivity,
        loggingAction: loggingAction,
        transferSkills: JSON.stringify(transferSkills),
        uiInfo: JSON.stringify(uiInfo)
      };
      if (comingFrom === 'New'){
        await API.graphql(graphqlOperation(mutations.createConfigProfile, {input: profile}));
      } else if (comingFrom === 'Edit'){
        await API.graphql(graphqlOperation(mutations.updateConfigProfile, {input: {id: props.location.state.profileData.id, ...profile}}));
      } else if (comingFrom === 'Clone'){
        await API.graphql(graphqlOperation(mutations.createConfigProfile, {input: profile}));
      }
        enqueueSnackbar('Profile saved successfully');
      props.history.push('/e2/')
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Save unsuccessful. ' + err, {
        variant: 'error'
      });
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant='h6'>{title}</Typography>
      <Paper classes={{ root: classes.paperHeight }}>
          <Grid container direction='column' justifyContent='flex-start' classes={{ root: classes.stepHeight }}>
              <Grid item>
                  {selMicrosoftAccount && <Typography className={classes.body} variant='h6'>Working as {selMicrosoftAccount.username}</Typography>}
              </Grid>
              <Grid item container direction="column" justifyContent="space-between" wrap="nowrap" classes={{ root: classes.fullHeight }}>
                  <Grid item container direction='column' justifyContent='space-around'>
                    <Grid item>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                    </Grid>
                    <Grid item>
                        {activeStep === steps.length ? (
                          <div>
                            <Typography className={classes.instructions}>All steps completed</Typography>
                            <Button onClick={handleReset}>Reset</Button>
                          </div>
                        ) : (
                          <Grid className={classes.instructions}>{getStepContent(activeStep)}</Grid>
                        )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Grid>
              </Grid>
          </Grid>
      </Paper>
      <Dialog
        open={openLoggingDialog}
        onClose={handleClose}
        >
        <DialogTitle id="alert-dialog-slide-title">{'Logging action is set to "None"'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            By setting your logging action to none we won't configure or save your activity loggings.
            Steps 6 - 8 will be skipped.
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleAccept} color="primary">
            Yes
          </Button>
        </DialogActions>
        </Dialog>
    </div>
  );
}

export default withRouter(ConfigProfile);
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    Input, 
    IconButton,
    Checkbox,
    Paper,
    List,
    ListSubheader,
    Divider,
    ListItem,
    ListItemText,
    ListItemSecondaryAction, 
    Typography} from '@material-ui/core';
import { Container } from "react-smooth-dnd";
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        minheight: '300px'
    },
    listHeader: {
        fontWeight: 'bold',
        color: 'black'
    }
}));

export default function Logging(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [metaData, setMetaData] = useState([]);
    const [originalMeta, setOriginalMeta] = useState([]);
    const [selMeta, setSelMeta] = useState('');
    const [fields, setFields] = useState([]);
    const [originalFields, setOriginalFields] = useState([]);
    const [selFields, setSelFields] = useState([]);
    const [transactionLoggings, setTransactionLoggings] = useState([]);

    useEffect(() => {
        async function getData(){
            setOriginalMeta(props.meta);
            setOriginalFields(props.fields);
            setTransactionLoggings(props.transactionLoggings);
            const tempMeta = props.meta;
            for (const meta of props.transactionLoggings){
                _.remove(tempMeta, ['metaData', meta.meta]);
            }
            setMetaData([...tempMeta]);
            let tempFields = await props.fields;
            for (const fields of props.transactionLoggings){
                for (const field of fields.fields){
                    _.remove(tempFields, ['SchemaName', field]);
                }
            };
            let sortedFields = _.sortBy(tempFields, ['SchemaName']);
            setFields([...sortedFields]);
        }
            getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.meta, props.fields]);

    const handleSelectedMeta = (event) => {
        const metaData = event.target.value;
        setSelMeta(metaData);
    }

    const handleSelectedField = (event) => {
        const fields = event.target.value;
        setSelFields(fields);
    }

    const addLogging = () => {
        if(selMeta !== null && selMeta !== '' && selFields !== null && selFields.length !== 0 ){
            const logging = {meta: selMeta, fields: selFields};
            transactionLoggings.push(logging);
            for (const field of selFields) {
                _.remove(fields, ['SchemaName', field])
            }
            _.remove(metaData, ['metaData', selMeta]);
            setSelMeta('');
            setSelFields([]);
            props.setTransactionLoggings(transactionLoggings);
        }
    }

    const deleteLogging = (data) => async () => {
        let tempArray = transactionLoggings;
        _.remove(tempArray, ['meta', data.meta]);
        setTransactionLoggings([...tempArray]);
        props.setTransactionLoggings([...tempArray]);
        //ReAdd Deleted meta and fields
        let updatedMeta = originalMeta;
        for (const meta of transactionLoggings){
            _.remove(updatedMeta, ['metaData', meta.meta])
        }
        setMetaData([...updatedMeta]);
        let updatedFields = originalFields;
        for (const fields of transactionLoggings){
            for (const field of fields.fields){
                _.remove(updatedFields, ['SchemaName', field]);
            }
        };
        let sortedFields = _.sortBy(updatedFields, ['SchemaName']);
        setFields([...sortedFields]);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Grid item container direction="row" alignItems="flex-start" spacing={4}>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="metaData">Meta Data</InputLabel>
                                    <Select
                                        labelId="metaData"
                                        name="metaData"
                                        value={selMeta}
                                        displayEmpty
                                        className={classes.selectFields}
                                        onChange={handleSelectedMeta}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {metaData.map(data =>(
                                            <MenuItem value={data.metaData}>{data.metaData}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Typography> maps to </Typography>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="field-label">Fields</InputLabel>
                                    <Select
                                        multiple
                                        labelId="field"
                                        value={selFields}
                                        onChange={handleSelectedField}
                                        className={classes.selectFields}
                                        input={<Input />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                                                            
                                        {fields.map(data =>(
                                            <MenuItem key={data.LogicalName} value={data.SchemaName}>
                                                <Checkbox checked={selFields.indexOf(data.SchemaName) > -1} color="primary" />
                                                <ListItemText primary={data.SchemaName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="add mapping" onClick={addLogging}>
                                    <AddIcon color="primary" fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item> 
                            <Paper>
                                <Grid container item direction="row">
                                <Grid item xs={12} >
                                        <List dense={true}>
                                            <ListSubheader className={classes.listHeader}>Logging Data</ListSubheader>
                                            <Divider />
                                            <ListSubheader color="primary">Master Loggings</ListSubheader>
                                            <Divider />
                                            <Container>
                                            <ListItem >
                                                <ListItemText primary={
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                              <Typography>
                                                                Meta Data
                                                              </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                              <Typography>
                                                                Field (CRM)
                                                              </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    } />
                                                    <ListItemSecondaryAction>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                {props.masterLoggings.map(data => (
                                                    <ListItem >
                                                        <ListItemText primary={
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    {data.entity}
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    {data.field}
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                        <ListItemSecondaryAction>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid item>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                 ))}
                                                    <ListItem >
                                                            <ListItemText primary={
                                                                <Grid container>
                                                                    <Grid item xs={2}>
                                                                        ContactId
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        InContactId
                                                                    </Grid>
                                                                </Grid>
                                                            } />
                                                            <ListItemSecondaryAction>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <Grid item>
                                                                    </Grid>
                                                                </Grid>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                            </Container>
                                            <Divider />
                                            <ListSubheader color="primary">Transaction Loggings</ListSubheader>
                                            <Divider />
                                            <Container>
                                                {transactionLoggings.map(data => (
                                                    <ListItem >
                                                        <ListItemText primary={
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    {data.meta}
                                                                </Grid>
                                                                <Grid item xs={8} container spacing={2} direction="row">
                                                                    {data.fields.map(fields => (
                                                                        <Grid item>
                                                                            {fields}
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                        <ListItemSecondaryAction>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid item>
                                                                    <IconButton aria-label="delete" onClick={deleteLogging(data)}>
                                                                        <DeleteIcon color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                 ))}
                                            </Container>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import customFieldTypes from '../models/CustomFieldsTypes';

/**
 * The tenant context. Setup independently so that the request for the
 * tentant id only has to be made once per page load.
 */
export const UserContext = React.createContext({
    tenant: '',
    tenantRole: '',
    company: '',
    name: '',
    secRole: '',
    icSkills: [],
    secToken: '',
    ftpExport: {},
    licenses: [],
    // CRMEntities: [{}],
    // CRMFields: [{}],
    customFields: [],
    queryFields: [],
    rights: []
});

export async function loadContext(tenant) {
    try {
        const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: tenant }));
        //Get the InContact Information 
        const tenantSettings = settings.data.getTenantSettings;
        const apiKey = settings.data.getTenantSettings.apiKey;
        const customFields = settings.data.getTenantSettings.customFields;
        const licenses = settings.data.getTenantSettings.licenses;
        const ftpExport = settings.data.getTenantSettings.ftpExport;

        const queryFields = {
            list: [
                { name: "firstName", label: "First Name" },
                { name: "lastName", label: "Last Name" },
                { name: "phone", label: "Phone" },
                { name: "source", label: "Source" },
                { name: "cell", label: "Cell" },
                { name: "email", label: "Email" },
                { name: "address1", label: "Address1" },
                { name: "address2", label: "Address2" },
                { name: "city", label: "City" },
                { name: "state", label: "State" },
                { name: "zip", label: "Zip" },
                /* { name: "expireDt", label: "Expiration Date", type: 'date' }, */
                { name: "outboundCallerId", label: "Outbound Caller ID" },
                { name: "createdAt", label: "Date Created", type: 'date' },
                ...customFields?.map(customField => ({ name: `custom:${customField.name}`, label: customField.displayName, type: customField.type === customFieldTypes.DateTime ? 'epoch' : customField.type === customFieldTypes.Boolean ? 'boolean' : 'text' }))
            ]
        };

        queryFields.typeLookup = field => queryFields.list.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {})[field]?.type;


        return { tenantSettings, apiKey, customFields, licenses, ftpExport, queryFields};
    } catch (err) {
        console.error(err);
    }
}

export function isDateField(fieldName) {
    return fieldName.endsWith('Date') || fieldName.toLowerCase().endsWith('dt');
}


export default UserContext;
import React, {useState, useRef} from 'react';
import {Grid, TextField, Typography, Button, Paper, CircularProgress, Link} from '@material-ui/core';
import {Formik} from 'formik'; 
import * as Yup from 'yup';
import request from 'superagent';
import { useSnackbar } from 'notistack';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

export default function AdminPayment(){
    const [paymentURL, setPaymentURL] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const generateLinkButtonRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    function copyUrlToClipboard() {
        document.addEventListener('copy', sendDataToClipboard);
        document.execCommand('copy');
        document.removeEventListener('copy', sendDataToClipboard);
        enqueueSnackbar('Payment link copied to clipboard', {variant: 'success'});
    }

    function sendDataToClipboard(event) {
        event.clipboardData.setData('text/plain', paymentURL);
        event.preventDefault();
    }

    return(
        <div>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <Typography variant='h4'>Bill Client</Typography>
                </Grid>
                <Grid item>
                    <Paper variant='elevation' style={{padding: '16px'}}>
                        <Grid container spacing={2} direction='column'>
                            <Grid item>
                                <Formik
                                    initialValues={{amount: 0, subjectId: "", debtorName: ""}}
                                    validationSchema={Yup.object({
                                        amount: Yup.number().positive("Entered amount must be positive.").required("An amount to be paid is required."),
                                        subjectId: Yup.string().trim("An account number is required.").required("An account number is required."),
                                        debtorName: Yup.string().trim("A name is required.").required("A name is required."),
                                    })}
                                    onSubmit={async values => {
                                            // Get Payment URL
                                            const paymentProfiles = await API.graphql(graphqlOperation(queries.listPaymentProfiles));

                                            let billClientDefaultTemplate = null;

                                            if(paymentProfiles?.data?.listPaymentProfiles) {
                                                billClientDefaultTemplate = paymentProfiles.data.listPaymentProfiles.items.find(profile => profile.name === "BillClientDefault");
                                            }

                                            if(!billClientDefaultTemplate) {
                                                enqueueSnackbar("No payment profile with name BillClientDefault found.", {variant: 'error'});
                                                setIsLoading(false);
                                            } else {
                                                const url = process.env.REACT_APP_PP_URL + "/payment-request";

                                                const profile = {
                                                    vendorId: billClientDefaultTemplate.vendorId,
                                                    subjectId: values.subjectId,
                                                    agentId: 'agentless',
                                                    contactName: values.debtorName,
                                                    ttl: billClientDefaultTemplate.ttl,
                                                    logoUrl: billClientDefaultTemplate.siteLogo,
                                                    header1: billClientDefaultTemplate.header1,
                                                    header2: billClientDefaultTemplate.header2,
                                                    primaryColor: billClientDefaultTemplate.sitePrimary,
                                                    secondaryColor: billClientDefaultTemplate.siteSecondary,
                                                    supportEmail: billClientDefaultTemplate.supportEmail,
                                                    supportPhone: billClientDefaultTemplate.supportPhone,
                                                    c2a: {
                                                        payment: {
                                                            amountDue: values.amount,
                                                            agingInfo: 30,
                                                        }
                                                    }
                                                };
                                        
                                                try{
                                                    setIsLoading(true);

                                                    const paymentLink = await request.post(url)
                                                        .set("x-api-key", billClientDefaultTemplate.apiKey)
                                                        .type("json")
                                                        .send(profile);
                                                    const response = JSON.parse(paymentLink.text);
                                                    setPaymentURL(response.url);

                                                    setIsLoading(false);
                                                } catch (err) {
                                                    console.error(err);
                                                    setIsLoading(false);
                                                }
                                            }
                                        }
                                    }
                                >
                                    {formikProps => 
                                        <form onSubmit={formikProps.handleSubmit}>
                                            <Grid container direction="column" alignItems="flex-start" spacing={2}>
                                                <Grid item>
                                                    <TextField 
                                                        label="Amount"
                                                        name="amount"
                                                        type="number"
                                                        required 
                                                        value={formikProps.values.amount}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors.amount && formikProps.touched.amount}
                                                        helperText={formikProps.errors.amount} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField 
                                                        label="Account Number"
                                                        name="subjectId"
                                                        type="text"
                                                        required 
                                                        value={formikProps.values.subjectId}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors.subjectId && formikProps.touched.subjectId}
                                                        helperText={formikProps.errors.subjectId} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField 
                                                        label="Name on card"
                                                        name="debtorName"
                                                        type="text"
                                                        required 
                                                        value={formikProps.values.debtorName}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors.debtorName && formikProps.touched.debtorName}
                                                        helperText={formikProps.errors.debtorName} />
                                                </Grid>
                                                <Grid item>
                                                    <Button ref={generateLinkButtonRef} type="submit" disabled={!formikProps.isValid || !formikProps.dirty} variant="contained" color="primary">Generate Link</Button>
                                                    {isLoading && generateLinkButtonRef.current && <CircularProgress style={{position: 'absolute', top: generateLinkButtonRef.current.offsetTop, left: generateLinkButtonRef.current.offsetLeft + 50}} size={34} variant='indeterminate' color='secondary' />}
                                                </Grid>
                                            </Grid>
                                        </form>}
                                </Formik>
                            </Grid>
                            {paymentURL !== "" && 
                            <Grid item>
                                <Grid container spacing={2} direction='column'>
                                    <Grid item>
                                        <Typography variant='body1'>
                                            <Link href={paymentURL} target="_blank" rel="noopener">
                                                {paymentURL}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid container item spacing={1} direction='row'>
                                        <Grid item>
                                            <Button variant="contained" color="primary" href={`mailto:?body=${paymentURL}`} target="_blank">Email Link</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={copyUrlToClipboard}>Copy Link</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
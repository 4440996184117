import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, Grid, FormControlLabel, Switch, Select, MenuItem, InputLabel, FormControl, DialogActions, makeStyles, Paper, Typography } from '@material-ui/core';
import CountryRegionData from '../../node_modules/country-region-data/data.json';
import * as _ from 'lodash';
import { Formik } from 'formik';
import * as moment from 'moment';
import * as mutations from '../graphql/mutations';
import AWSAppSyncClient, { AUTH_TYPE, } from 'aws-appsync';
import gql from 'graphql-tag';
import Validations from './YupValidations.js'
import timezones from './TimezoneData';
import awsconfig from '../aws-exports';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    timezoneMenuItems: {
        maxHeight: 25
    },
    paper: {
        padding: '15px'
    }
}));

/**
 * The dialog for creating and editing a contact.
 * @component
 */
export default function PopUpContact(props) {

    const classes = useStyles();
    const [tenant, setTenant] = useState();
    const [statuses, setStatuses] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [contact, setContact] = useState(
        {
            contactAccountId: '',
            firstName: '',
            lastName: '',
            source: '',
            status: 'Active',
            phone: '',
            cell: '',
            email: '',
            dateCreated: new Date(),
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            timeZone: '',
            optIn: false,
            optOut: false,
            dnc: false,
            customFields: {},
            optout: {
                Voice: false,
                SMS: false,
                Email: false
            }
        });


    const [usStates, setUSStates] = useState([]);

    /**
     * React hook for loading form options
     * Contact will not change on the popup page.
     * Load the contact info into the component
     */
    useEffect(() => {
        setTenant(props.tenant);
        //set necessary form values 
        //status enum
        const statusEnum = ["Active", "InActive"];
        setStatuses(statusEnum);
        //State Codes
        setUSStates(_.find(CountryRegionData, ['countryShortCode', 'US']).regions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setCustomFields(props.customFields);
    }, [props.customFields]);

    useEffect(() => {
        //Load the contact info from the popup 
        setContact({ ...props.contact });
    }, [props.contact]);

    const handleDate = (name, values) => {
        const dateValue = values.customFields[name];
        if (dateValue && Number(dateValue)) {
            return moment(dateValue, 'x').format('YYYY-MM-DDTHH:mm');
        } else if (dateValue) {
            return dateValue;
        } else {
            return '';
        }
    }

    const closeContact = () => {
        props.handleClose(false);
    }

    return (
        <div>
            <Dialog open={props.open} onClose={closeContact}>
                <DialogTitle id="contact-dialog-title">Edit Contact</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={contact}
                        validationSchema={Validations.contactValidation}
                        onSubmit={async values => {
                            //dynamodb doesn't like empty strings turn empty into null
                            const contact = { ...values };
                            for (const key in contact) {
                                contact[key] = contact[key] === "" ? null : contact[key];
                            }

                            contact.tenant = tenant;
                            delete contact.account;
                            if (contact.customFields) {
                                for (const key in contact.customFields) {
                                    const tenantCustomField = _.find(customFields, ['name', key]);
                                    if (tenantCustomField) {
                                        if (tenantCustomField.type === 'Number') {
                                            contact.customFields[key] = +contact.customFields[key];
                                        } else if (tenantCustomField.type === 'DateTime') {
                                            const dateField = contact.customFields[key];
                                            if (dateField && Number(dateField)) {
                                                contact.customFields[key] = dateField;
                                            } else {
                                                contact.customFields[key] = moment(dateField, 'YYYY-MM-DDTHH:mm').format('x');
                                            }
                                        } else if (tenantCustomField.type === 'Boolean') {
                                            contact.customFields[key] = Boolean(contact.customFieldsd[key].toLowerCase());
                                        }
                                    }
                                }
                                contact.customFields = JSON.stringify(contact.customFields);
                            }
                            try {
                                //const user = await (await Auth.currentSession()).getIdToken();
                                contact.updatedBy = 'InContact Agent';
                                try {
                                    const client = new AWSAppSyncClient({
                                        url: awsconfig.aws_appsync_graphqlEndpoint,
                                        region: awsconfig.aws_appsync_region,
                                        auth: {
                                            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                                            jwtToken: props.token
                                        },
                                        disableOffline: true
                                    });
                                    const contactResult = await client.mutate(
                                        {
                                            mutation: gql`${mutations.updateContact}`,
                                            variables: {
                                                input: contact
                                            }
                                        }
                                    );
                                    console.log(contactResult);
                                } catch (err) {
                                    console.error('Error Saving Contact', err);
                                    //enqueSnackbar('Error saving contact');
                                }
                                props.updateContact(contact);

                            } catch (err) {
                                console.error(err);
                            }
                        }}
                    >
                        {formikProps => (
                            <form onSubmit={formikProps.handleSubmit}>
                                <Grid container spacing={2} direction='column'>
                                    <Grid item>
                                        <Paper elevation={4} className={classes.paper}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography variant="overline">Contact Info</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                name="firstName"
                                                                label="First Name"
                                                                type="text"
                                                                required={true}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                value={formikProps.values.firstName}
                                                                error={formikProps.errors.firstName && formikProps.touched.firstName} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="lastName"
                                                                label="Last Name"
                                                                type="text"
                                                                required={true}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                value={formikProps.values.lastName}
                                                                error={formikProps.errors.lastName && formikProps.touched.lastName} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="email"
                                                                label="Email"
                                                                type="email"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                value={formikProps.values.email}
                                                                error={formikProps.errors.email && formikProps.touched.email} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel id="status-label">Status</InputLabel>
                                                                <Select
                                                                    labelId="status-label"
                                                                    value={formikProps.values.status}
                                                                    autoWidth={true}
                                                                    name="status"
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={formikProps.errors.status && formikProps.touched.status}>
                                                                    {statuses.map(status =>
                                                                        <MenuItem key={status} value={status}>{status}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="source"
                                                                label="Source"
                                                                type="text"
                                                                value={formikProps.values.source}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.source && formikProps.touched.source} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="phone"
                                                                label="Phone #"
                                                                type="phone"
                                                                required={true}
                                                                value={formikProps.values.phone}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.phone && formikProps.touched.phone} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="cell"
                                                                label="Cell #"
                                                                type="phone"
                                                                value={formikProps.values.cell}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.cell && formikProps.touched.cell} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="address1"
                                                                label="Address Line 1"
                                                                type="text"
                                                                value={formikProps.values.address1}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.address1 && formikProps.touched.address1} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="address2"
                                                                label="Address Line 2"
                                                                type="text"
                                                                value={formikProps.values.address2}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.address2 && formikProps.touched.address2} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="city"
                                                                label="City"
                                                                type="text"
                                                                value={formikProps.values.city}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.city && formikProps.touched.city} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel id="us-state-label">State</InputLabel>
                                                                <Select
                                                                    labelId="us-state-label"
                                                                    value={formikProps.values.state}
                                                                    autoWidth={true}
                                                                    name="state"
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}>
                                                                    {usStates.map(state =>
                                                                        <MenuItem key={state.shortCode} value={state.shortCode}>{state.name}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                margin="dense"
                                                                name="zip"
                                                                label="Zipcode"
                                                                type="text"
                                                                value={formikProps.values.zip}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.zip && formikProps.touched.zip} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel id="timezone-label">Timezone</InputLabel>
                                                                <Select
                                                                    labelId="timezone-label"
                                                                    value={formikProps.values.timeZone}
                                                                    autoWidth={true}
                                                                    name="timeZone"
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}>
                                                                    {timezones.map(timezone =>
                                                                        <MenuItem className={classes.timezoneMenuItems} value={timezone.shortName}>{timezone.longName}</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item>
                                        <Paper elevation={4} className={classes.paper}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography variant='overline'>OptOuts</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label='Voice'
                                                                control={
                                                                    <Switch
                                                                        checked={formikProps.values?.optout?.Voice ?? false}
                                                                        onChange={formikProps.handleChange}
                                                                        name='optout.Voice'
                                                                        color='primary'
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label='SMS'
                                                                control={
                                                                    <Switch
                                                                        checked={formikProps.values?.optout?.SMS ?? false}
                                                                        onChange={formikProps.handleChange}
                                                                        name='optout.SMS'
                                                                        color='primary'
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label='Email'
                                                                control={
                                                                    <Switch
                                                                        checked={formikProps.values?.optout?.Email ?? false}
                                                                        onChange={formikProps.handleChange}
                                                                        name='optout.Email'
                                                                        color='primary'
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item>
                                        <Paper elevation={4} className={classes.paper}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography variant="overline">Custom Fields</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        {customFields && customFields.map(field => (
                                                            <Grid item key={field.name} xs={6}>
                                                                {field.type === 'String' &&
                                                                    <TextField
                                                                        margin="dense"
                                                                        name={`customFields.${field.name}`}
                                                                        label={field.displayName}
                                                                        type="text"
                                                                        className={classes.inputFields}
                                                                        value={formikProps.values.customFields[field.name] || ''}
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur} />
                                                                }
                                                                {field.type === 'Number' &&
                                                                    <TextField
                                                                        margin="dense"
                                                                        name={`customFields.${field.name}`}
                                                                        label={field.displayName}
                                                                        type="number"
                                                                        className={classes.inputFields}
                                                                        value={formikProps.values.customFields[field.name] || ''}
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur} />
                                                                }
                                                                {field.type === 'DateTime' &&
                                                                    <TextField
                                                                        margin="dense"
                                                                        name={`customFields.${field.name}`}
                                                                        label={field.displayName}
                                                                        type="datetime-local"
                                                                        className={classes.inputFields}
                                                                        value={handleDate(field.name, formikProps.values)}
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }} />
                                                                }
                                                                {field.type === 'Boolean' &&
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                name={`customFields.${field.name}`}
                                                                                checked={formikProps.values.customFields[field.name] || false}
                                                                                onChange={formikProps.handleChange}
                                                                                onBlur={formikProps.handleBlur}
                                                                                value="true"
                                                                            />
                                                                        }
                                                                        label={field.displayName}
                                                                    />
                                                                }
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Button onClick={closeContact} color="primary" variant="outlined">Cancel</Button>
                                    <Button type="submit" color="primary" variant="contained">Save</Button>
                                </DialogActions>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div >
    )
}
/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
 
 export default function OutboundShortcuts(props)
 {
     const [outboundShortcuts, setOutboundShortcuts] = useState(false);
 
     /** Set activity panel to value being passed through props */
     useEffect(() => {
         setOutboundShortcuts(props.value);
     },[props.value]);
     
     /**Handle changes to activity panel checkbox */
     const handleOutboundShortcuts = () => {
         setOutboundShortcuts(!outboundShortcuts);
         props.onChange(!outboundShortcuts)
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={outboundShortcuts}
                         onChange={handleOutboundShortcuts}
                         name="outboundShortcuts"
                         color="primary"
                         />
                     }
                     label="Outbound Shortcuts"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Allow navigation history access">
                         <CallMadeOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
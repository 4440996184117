/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
 
 export default function Click2Call(props)
 {
     const [click2Call, setClick2Call] = useState(false);
 
     /** Set callback to value that is being passed through props */
     useEffect(() => {
        setClick2Call(props.value);
     },[props.value]);
 
     /**Handle Changes of callback checkbox */
     const handleClick2Call = () => {
         setClick2Call(!click2Call);
         props.onChange(!click2Call);
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={click2Call}
                         onChange={handleClick2Call}
                         name="click2call"
                         color="primary"
                         />
                     }
                     label="Click2Call"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Give permission for Click2Call">
                         <CallOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
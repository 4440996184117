import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserContext from '../context/UserContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Divider} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import ChartFieldSelect from '../components/ChartFieldSelelct';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    selectInput: {
        minWidth: '100px'
    },
    formControl: {
        minWidth: 120
    },
    dialogDiv: {
        minWidth: '600px'
    },
    customChart: {
        padding: '16px'
    },
    button : {
        minHeight: 40
    }
}));

/**
 * The ChartDialog component is used Create New Charts
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
const ChartDialog = function (props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [chart, setChart] = useState({
        chart: '',
        field: '',
        model: props.index,
        filter: undefined,
        agg: 'count',
        title: '',
        eventType: '',
        admin: false
    });

    const [field, setField] = useState('');
    const [toggleOpen, setOpen] = useState(false);

    const dispCharts = [
        {
            chart: 'BarChart',
            name: 'Bar'
        },
        {
            chart: 'PieChart',
            name: 'Pie '
        },
        {
            chart: 'HorBarChart',
            name: 'Horizontal Bar '
        },
        {
            chart: 'RadarChart',
            name: 'Radar'
        },
        // {
        //     chart: 'BubbleChart',
        //     name: 'Bubble'
        // },
        {
            chart: 'LineChart',
            name: 'Line'
        }
    ];

    const [aggFields] = useState([
        {
            data: 'agent.keyword',
            display: 'Agent'
        },
        {
            data: 'disposition.keyword',
            display: 'Disposition'
        },
        {
            data: 'updatedAt.keyword',
            display: 'Date'
        },
        {
            data: 'metaData.AdditionalInfo.zip.keyword',
            display: 'Contact Zip Code'
        },
        {
            data: 'metaData.AdditionalInfo.state.keyword',
            display: 'Contact State'
        },

        {
            data: 'metaData.AdditionalInfo.source.keyword',
            display: 'Contact Source'
        },
        {
            data: 'metaData.CommitmentAmount.keyword',
            display: 'Commitment Amount'
        }
    ]);

    useEffect(() => {
        if (props.editChart && props.open) {
            setChart(props.editChart);
            setOpen(true);
        }

    }, [props.editChart, props.open]);

    const handleClick = () => {
        setOpen(!toggleOpen);
    }
    
    return (
        <UserContext.Consumer>
            {({ tenant, icSkills }) => (
                <div>
                    {!props.editChart &&
                        <Button onClick={handleClick} className={classes.button} color="primary" variant="contained">+ Chart</Button>}

                    <Dialog open={toggleOpen}>
                        <DialogTitle id="dp-dialog-title">Add Chart</DialogTitle>
                        <DialogContent className={classes.dialogDiv}>
                            <Formik
                                initialValues={chart}
                                enableReinitialize={true}
                                onSubmit={async (values) => {
                                    let successMsg = 'Chart Created';
                                    let chart = {...values, field: field}
                                    try {
                                        if (props.onCreate) {
                                            props.onCreate(chart);
                                        }
                                        else {
                                            successMsg = 'Chart Updated'
                                            props.onEdit(chart);
                                        }
                                        enqueueSnackbar(successMsg);
                                        setOpen(false);
                                    } catch (err) {
                                        console.error(err);
                                    }
                                }}
                            >
                                {formikProps => (
                                    <form onSubmit={formikProps.handleSubmit}>
                                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                                            {/* <Grid item container direction="column" justify="flex-start" spacing={1}>
                                                <Paper>
                                                    <Grid container item direction="row">
                                                        <Grid item xs={12} >
                                                            <List dense={true}>
                                                                <ListSubheader ><Typography variant="h6" color="primary"> Add a Standard Chart</Typography></ListSubheader>
                                                                <Divider />
                                                                {cannedCharts.map(chart => (
                                                                    <ListItem >
                                                                        <ListItemText primary={chart} />
                                                                        <ListItemSecondaryAction>
                                                                            <Grid container direction="row" alignItems="center">
                                                                                <Grid item>
                                                                                    <IconButton aria-label="Pie Chart" onClick={addPie(chart)}>
                                                                                        <PieChart color="primary" fontSize="small" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <IconButton aria-label="Bar Chart" onClick={addBar(chart)}>
                                                                                        <BarChart color="primary" fontSize="small" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <IconButton aria-label="Hor. Bar Chart" onClick={addHBar(chart)} >
                                                                                        <HBarChart color="primary" fontSize="small" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <IconButton aria-label="Line Chart" onClick={addLine(chart)}>
                                                                                        <LineChart color="primary" fontSize="small" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <IconButton aria-label="Bubble Chart" onClick={addBubble(chart)}>
                                                                                        <BubbbleChart color="primary" fontSize="small" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <IconButton aria-label="Bubble Chart" onClick={addRadar(chart)}>
                                                                                        <RadarChart color="primary" fontSize="small" />
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid> */}
                                            <Grid item container direction="column" justify="flex-end" spacing={1}>
                                                <Paper className={classes.customChart}>
                                                    <Typography variant="h6" color="primary">Add a Custom Chart</Typography>
                                                    <Divider />
                                                    <Grid container item direction="row" justify="space-between">
                                                        {/* Chart Select Box */}
                                                        <Grid container item direction="row" xs={12} spacing={4} alignItems="center" alignContent="center" justify="flex-start">
                                                            <Grid item >
                                                                <TextField
                                                                    margin="dense"
                                                                    name="title"
                                                                    label="Chart Title"
                                                                    required
                                                                    type="text"
                                                                    value={formikProps.values.title}
                                                                    onChange={formikProps.handleChange} />
                                                            </Grid>
                                                            <Grid item>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel id="chart-label">Chart Type</InputLabel>
                                                                    <Select
                                                                        margin="dense"
                                                                        name="chart"
                                                                        required
                                                                        label="Chart"
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur}
                                                                        value={formikProps.values.chart}
                                                                        error={formikProps.errors.chart && formikProps.touched.chart}>
                                                                        {dispCharts.map(chart =>
                                                                            <MenuItem key={chart.chart} value={chart.chart}>{chart.name}</MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        {/* Field Select Box */}
                                                        <Grid item container direction="row" md={8} spacing={4} alignItems='center' justify="flex-start">
                            
                                                            <Grid item>
                                                                <ChartFieldSelect index={props.index} field={chart.field} standardFields={aggFields} setField={setField}></ChartFieldSelect>
                                                            </Grid>
                                                            <div hidden={props.index === 'activitylog'}>
                                                                <Grid item>
                                                                    <FormControl className={classes.formControl}>
                                                                        <InputLabel id="profile-label">Event Type</InputLabel>
                                                                        <Select
                                                                            margin="dense"
                                                                            name="eventType"
                                                                            label="eventType"
                                                                            onChange={formikProps.handleChange}
                                                                            onBlur={formikProps.handleBlur}
                                                                            value={formikProps.values.eventType}
                                                                            error={formikProps.errors.eventType && formikProps.touched.eventType}>
                                                                            
                                                                                <MenuItem key='created' value='created'>Created</MenuItem>
                                                                                <MenuItem key='login' value='login'>Login</MenuItem>
                                                                                <MenuItem key='inboundPhone' value='inboundPhone'>Inbound Phone</MenuItem>
                                                                                <MenuItem key='outboundPhone' value='outboundPhone'>Outbound Phone</MenuItem>
                                                                                <MenuItem key='inboundEmail' value='inboundEmail'>Inbound Email</MenuItem>
                                                                                <MenuItem key='outboundEmail' value='outboundEmail'>Outbound Email</MenuItem>
                                                                                <MenuItem key='inboundChat' value='inboundChat'>Inbound Chat</MenuItem>
                                                                                <MenuItem key='outboundChat' value='outboundChat'>Outbound Chat</MenuItem>
                                                                                <MenuItem key='inboundSMS' value='inboundSMS'>Inbound SMS</MenuItem>
                                                                                <MenuItem key='outboundSMS' value='outboundSMS'>Outbound SMS</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                            </div>
                                                              
                                                        </Grid>
                                                        <Grid container item direction="row" xs={12} spacing={4} alignItems="center" justify="flex-start">
                                                            {/* <Grid item>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel id="profile-label">Aggregation</InputLabel>
                                                                    <Select
                                                                        margin="dense"
                                                                        name="aggregation"
                                                                        label="Aggregation"
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur}
                                                                        value={formikProps.values.agg}
                                                                        error={formikProps.errors.dialerPackageProfileId && formikProps.touched.dialerPackageProfileId}>
                                                                        
                                                                            <MenuItem key='sum' value='sum'>Sum</MenuItem>
                                                                            <MenuItem key='max' value='max'>Max</MenuItem>
                                                                            <MenuItem key='average' value='average'>Average</MenuItem>
                                                                            <MenuItem key='count' value='count'>Count</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid> */}
                                                            <Grid item >
                                                                <TextField
                                                                    margin="dense"
                                                                    name="filter"
                                                                    label="Filter"
                                                                    type="text"
                                                                    value={formikProps.values.filter}
                                                                    onChange={formikProps.handleChange} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item direction="row" xs={12} justify="flex-start">
                                                                <Grid item >
                                                                    <Button type="submit" color="primary" variant="contained">Save</Button>
                                                                </Grid>
                                                        </Grid>            
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                        <DialogActions>
                                            <Button onClick={handleClick} color="primary" variant="outlined">Cancel</Button>
                                            {/* <Button type="submit" color="primary" variant="contained">Save</Button> */}
                                        </DialogActions>
                                    </form>
                                )}
                            </Formik>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </UserContext.Consumer>
    )
}

export default ChartDialog;

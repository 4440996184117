import React, {useState} from 'react';
import {FormControlLabel, Checkbox, LinearProgress} from '@material-ui/core';
import StepperController from './StepperController';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function FinalApproval(props) {
    const history = useHistory();
    const {stepBack, finalApproval, setFinalApproval, cmsInfo, vendorInfo} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);

    async function advanceStep() {
        if(!finalApproval) {
            enqueueSnackbar("Final approval is required", { variant: "error" });
        } else {
            setLoading(true);
            
            await axios.put(
                `${process.env.REACT_APP_PP_URL}/cms-onboard/update-vendor`, {
                    id: vendorInfo.ppayVendor,
                    status: "Approved",
                    cmsInfo: cmsInfo
                }, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_PP_API_KEY
                    }
                }
            ).then(() => {
                enqueueSnackbar("Vendor updated. Please wait while you are automatically redirected...", { variant: 'success' });
                setFinished(true);
                setTimeout(() => {history.push('/admin/onboarding/');}, 4000);
            }).catch(err => {
                console.log(err);
                enqueueSnackbar("Unable to update vendor.", { variant: 'error' });
            });

            setLoading(false);
        }
    }

    return (
        <div>
            {loading && <LinearProgress variant='indeterminate' color='secondary' />}
            <FormControlLabel control={<Checkbox checked={finalApproval} onChange={(event) => setFinalApproval(event.target.checked)} color="primary" disabled={loading || finished} />} label="Final Approval" labelPlacement="end"/>
            <StepperController onStepBack={stepBack} onStepForward={advanceStep} nextText="Finish" nextDisabled={loading || finished} backDisabled={loading || finished} />
        </div>
    );
}
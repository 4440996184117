import React, { useEffect, useRef, useState } from 'react';
import StepperController from './StepperController';
import { Grid, TextField, CircularProgress, Button, Typography, Paper } from '@material-ui/core';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';

export default function NexioInformation(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {vendorInfo, cmsInfo, setCmsInfo, stepBack, nextStep, classes, nexioTokenGenerated, setNexioTokenGenerated} = props;
    const [generatingNexioToken, setGeneratingNexioToken] = useState(false);
    const generateTokenButtonRef = useRef();
    const [initialValues, setInitialValues] = useState({username: "", password: ""})

    useEffect(() => {
        if(cmsInfo.nexioCredentials) {
            const credentials = atob(cmsInfo.nexioCredentials).split(":");
            initialValues.username = credentials[0];
            initialValues.password = credentials[1];
            setInitialValues({...initialValues});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function advanceStep() {
        if(!nexioTokenGenerated) {
            enqueueSnackbar("Please generate a nexio token before continuing", { variant: "error" });
        } else {
            nextStep();
        }
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object({
                    username: Yup.string()
                        .trim().required('Please provide a username')
                        .trim().matches(/^[a-zA-Z0-9 @_.-]+$/, 'Please enter a valid username')
                    })}
                onSubmit={async (values) => {
                    setGeneratingNexioToken(true);
                    try {
                        const token = btoa(values.username + ":" + values.password);

                        await axios.get("https://api.nexiopaysandbox.com/user/v3/account/whoAmI", { headers: {Authorization: "Basic " + token, Accept: "application/json"}})
                            .then(async () => {
                                cmsInfo.nexioCredentials = token;
                                setCmsInfo({...cmsInfo});

                                try {
                                    await axios.put(
                                        `${process.env.REACT_APP_PP_URL}/cms-onboard/update-vendor`, {
                                            id: vendorInfo.ppayVendor,
                                            status: "OnBoardComplete",
                                            cmsInfo: cmsInfo
                                        },
                                        {
                                            headers: {
                                                'x-api-key': process.env.REACT_APP_PP_API_KEY
                                            }
                                        }
                                    );
            
                                    enqueueSnackbar("Nexio token saved", { variant: 'success' });
                                    setNexioTokenGenerated(true);
                                } catch (err) {
                                    console.log(err);
                                    enqueueSnackbar("Unable to save nexio token", { variant: "error" });
                                }
                            });
                    } catch (err) {
                        enqueueSnackbar("Invalid nexio credentials.", { variant: 'error' });
                    }
                    
                    setGeneratingNexioToken(false);
                }}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Grid container direction="column" spacing={2} alignItems="center" alignContent="center">
                            <Grid item container direction="row" alignItems="center" alignContent="center" spacing={2}>
                                <Grid item>
                                    <TextField 
                                        name='username'
                                        label="Nexio Username" 
                                        required 
                                        value={formikProps.values.username} 
                                        className={classes.longTextField} 
                                        error={formikProps.errors.username && formikProps.touched.username} 
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        helperText={formikProps.errors.username} 
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                        name='password'
                                        label="Nexio Password" 
                                        type='password'
                                        required 
                                        value={formikProps.values.password} 
                                        className={classes.longTextField} 
                                        error={formikProps.errors.password && formikProps.touched.password} 
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        helperText={formikProps.errors.password} 
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" alignContent="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" disabled={generatingNexioToken} color="primary" type='submit' ref={generateTokenButtonRef}>Generate Token</Button>
                                    {generatingNexioToken && generateTokenButtonRef.current && 
                                    <CircularProgress 
                                        style={{position: 'absolute', top: generateTokenButtonRef.current.offsetTop, left: generateTokenButtonRef.current.offsetLeft + (generateTokenButtonRef.current.offsetWidth / 2) - (34 / 2)}} 
                                        size={34} 
                                        variant='indeterminate' 
                                        color='secondary' />}
                                </Grid>
                                {nexioTokenGenerated &&
                                <Grid container item direction='row' spacing={2} alignItems="center" alignContent="center">
                                    <Grid item>
                                        <Typography variant='h6'>Nexio Token: </Typography>
                                    </Grid>
                                    <Paper elevation={2}>
                                        <Typography variant='body1' style={{padding: '10px'}}>{cmsInfo.nexioCredentials}</Typography>
                                    </Paper>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
            <StepperController onStepBack={stepBack} onStepForward={advanceStep} />
        </div>
    );
}
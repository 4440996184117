/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';

export default function RefreshSkills(props)
{
    const [refreshSkills, setRefreshSkills] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setRefreshSkills(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleRefreshSkills = () => {
        setRefreshSkills(!refreshSkills);
        props.onChange(!refreshSkills)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={refreshSkills}
                        onChange={handleRefreshSkills}
                        name="refreshSkills"
                        color="primary"
                        />
                    }
                    label="Refresh Skills"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Allow Refresh Skills access">
                        <RefreshOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


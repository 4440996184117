/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';

export default function ActivityPanel(props)
{
    const [activityPanel, setActivityPanel] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setActivityPanel(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleActivityPanel = () => {
        setActivityPanel(!activityPanel);
        props.onChange(!activityPanel)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={activityPanel}
                        onChange={handleActivityPanel}
                        name="activityPanel"
                        color="primary"
                        />
                    }
                    label="Activity Panel"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission for activity panel">
                        <NoteOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


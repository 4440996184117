import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { 
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '450px',
        maxWidth: '450px'
    },
    main: {
        height: '300px'
    }

}));

export default function Action(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const userContext = useContext(UserContext);

    useEffect(() => {
        async function getData(){
        }
        if (userContext.tenant){
            getData();
        }
    }, [userContext.tenant]);

    const generateCredentials = async () =>{
        try{    
                window.open('https://login.microsoftonline.com/common/adminconsent?client_id=a53e8cfb-e738-4fee-81fa-359f180d150c&state=12345&redirect_uri=' + window.location.origin + '/writer/', "", "width=650,height=700");
                // window.open('http://localhost:3000/config/tester', "", "width=650,height=700");
        }catch (err) {
            enqueueSnackbar('Auto Generation unsuccessful, Please validate user has Global Admin Privileges', {
                variant: 'error',
                autoHideDuration: 5000
            });
        }        

    }

    const useExisting = () => {
        props.handleNext(3)
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography variant="h6">Would you like to generate credentials by granting Admin Consent to Customer Dynamics or by using your own application credentials?</Typography>
                        </Grid>
                        <br></br>
                        <Grid item container direction="row" spacing={2}>
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={generateCredentials}>Generate</Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="outlined" onClick={useExisting}>Use Own Application</Button>
                            </Grid>
                        </Grid>
                        <Button></Button>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

 export default function HideUnavailable(props)
 {
     const [hideUnavailable, setHideUnavailable] = useState(false);
 
     /** Set activity panel to value being passed through props */
     useEffect(() => {
         setHideUnavailable(props.value);
     },[props.value]);
     
     /**Handle changes to activity panel checkbox */
     const handleHideUnavailable = () => {
         setHideUnavailable(!hideUnavailable);
         props.onChange(!hideUnavailable)
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={hideUnavailable}
                         onChange={handleHideUnavailable}
                         name="hideunavailable"
                         color="primary"
                         />
                     }
                     label="Hide Unavailable"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Give permission to hide unavailable contacts">
                         <GroupOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
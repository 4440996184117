import React, { useEffect, useState, useRef, memo, useCallback, forwardRef } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

export default function RetryDelayPicker(props) {
    const { value, onChange, error, name, errorMessage, label } = props;
    const useStyles = makeStyles(theme => ({
        regular: {
            transition: 'all .2s ease-in-out',
            '&:hover': {
                fontWeight: 'bold'
            }
        },
        error: {
            color: theme.palette.error.main
        },
    }));
    const classes = useStyles();
    const [time, setTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0
    });
    const timeRef = useRef({ ...time }); // To avoid callback recreation for memoization.

    useEffect(() => {
        let tempValue = value;

        const tempDays = Math.trunc(tempValue / 1440);
        tempValue -= tempDays * 1440;

        const tempHours = Math.trunc(tempValue / 60);
        tempValue -= tempHours * 60;

        time.days = tempDays * 1440;
        time.hours = tempHours * 60;
        time.minutes = tempValue;

        timeRef.current = { ...time };
        setTime({ ...time });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onTimeChange = useCallback((newValue, key) => {
        timeRef.current[key] = newValue;
        onChange({
            type: 'change',
            target: {
                name,
                value: timeRef.current.days + timeRef.current.hours + timeRef.current.minutes
            }
        });
    }, [onChange, name]);

    return (
        <div>
            <Typography variant="caption" style={{ letterSpacing: '.03333em', color: 'rgba(255, 255, 255, 0.54)', textRendering: 'optimizeLegibility' }}>{label}</Typography>
            <Grid container spacing={2}>
                <Grid item className={clsx(classes.regular, error && classes.error)}>
                    <RetryDelayPickerColumn min={0} max={99} unitLabelText='days' unitKey='days' unitToMinutesRatio={1440} value={time.days} onChange={onTimeChange} />
                </Grid>
                <Grid item className={clsx(classes.regular, error && classes.error)}>
                    <RetryDelayPickerColumn min={0} max={23} unitLabelText='hours' unitKey='hours' unitToMinutesRatio={60} value={time.hours} onChange={onTimeChange} />
                </Grid>
                <Grid item className={clsx(classes.regular, error && classes.error)}>
                    <RetryDelayPickerColumn min={0} max={59} unitLabelText='minutes' unitKey='minutes' unitToMinutesRatio={1} value={time.minutes} onChange={onTimeChange} />
                </Grid>
            </Grid>
            <Typography variant="body2" color='error' style={{ opacity: error ? 1 : 0, fontSize: '.75rem', letterSpacing: '.03333em' }}>{`${errorMessage}`}</Typography>
        </div>
    );
}

const RetryDelayPickerColumn = memo((props) => {
    const { min, max, unitLabelText, unitToMinutesRatio, value, onChange, unitKey } = props;

    const maskCSS = 'linear-gradient(transparent 0%, rgba(0, 0, 0, .65) 12%, white 50%, rgba(0, 0, 0, .65) 88%, transparent 100%)';
    const useStyles = makeStyles(({
        mask: {
            overflow: 'hidden',
            maskImage: maskCSS,
            WebkitMaskImage: maskCSS,
            maxHeight: '60px',
            maxWidth: '20px',
            '&:hover': {
                cursor: 'grab'
            }
        }
    }));
    const classes = useStyles();
    const [values, setValues] = useState([]);
    const columnRef = useRef();
    const columnMaskRef = useRef();
    const isMouseDown = useRef(false);
    const scrollStartingOffset = useRef(0);

    useEffect(() => {
        for (let i = min; i <= max; i++) {
            values.push(i);
        }

        document.documentElement.addEventListener('mouseup', handleMouseUp);

        setValues([...values]);

        return () => {
            document.documentElement.removeEventListener('mouseup', handleMouseUp);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const heightPerValue = columnRef.current.offsetHeight / values.length;
        const normalizedPosition = ((value / unitToMinutesRatio) - 1) * heightPerValue;
        setColumnScrollPosition(-normalizedPosition);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, columnRef.current?.offsetHeight]);

    const handleMouseMove = useCallback((e) => {
        if (isMouseDown.current) {
            setColumnScrollPosition(e.y - scrollStartingOffset.current);
        }
    }, []);

    const handleMouseDown = useCallback((e) => {
        isMouseDown.current = true;
        scrollStartingOffset.current = e.clientY - getColumnScrollPosition();
        document.documentElement.addEventListener('mousemove', handleMouseMove);
    }, [handleMouseMove]);

    const handleMouseUp = useCallback((e) => {
        document.documentElement.removeEventListener('mousemove', handleMouseMove);
        isMouseDown.current = false;
        const heightPerValue = columnRef.current.offsetHeight / values.length;
        let selectedValue = Math.round(-getColumnScrollPosition() / heightPerValue);
        selectedValue = selectedValue < min - 1 ? min - 1 : selectedValue;
        selectedValue = selectedValue > max - 1 ? max - 1 : selectedValue;
        onChange((selectedValue + 1) * unitToMinutesRatio, unitKey);

        const normalizedPosition = selectedValue * heightPerValue;
        setColumnScrollPosition(-normalizedPosition);
    }, [handleMouseMove, max, min, onChange, unitKey, unitToMinutesRatio, values.length]);

    function getColumnScrollPosition() {
        return Number(columnRef.current.style.top.substring(0, columnRef.current.style.top.length - 2));
    }

    function setColumnScrollPosition(position) {
        columnRef.current.style.top = `${position}px`
    }

    return (
        <div style={{ userSelect: 'none', display: 'flex' }}>
            <div className={classes.mask} ref={columnMaskRef}>
                <Column values={values} ref={columnRef} onMouseDown={handleMouseDown} />
            </div>
            <div style={{ height: '20px', width: '20px', borderTop: 'solid', borderBottom: 'solid', borderWidth: '1px', borderRadius: '2px', marginTop: '19px', marginLeft: '-18px' }} />
            <div style={{ color: 'grey', marginTop: '18px' }}><i>{unitLabelText}</i></div>
        </div>
    );
});

const ColumnWithRef = forwardRef(({ onMouseDown, values }, ref) => {
    return (
        <Grid container direction='column' spacing={0} ref={ref} style={{ position: 'relative', textAlign: 'center' }} onMouseDown={onMouseDown}>
            {values.map(value =>
                <Grid key={value} item>
                    {value}
                </Grid>
            )}
        </Grid>
    );
});

const Column = memo(ColumnWithRef); // This is black magic. The parameters that memo hands to the component don't include a ref. However, doing it this way magically makes it work.
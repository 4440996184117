import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Slider } from '@material-ui/core';

const RetrySlider = forwardRef((props, ref) => {
    return (
        <Grid container spacing={2} alignContent='center' alignItems='center'>
            <Grid item>
                <Typography>{props.label}</Typography>
            </Grid>
            <Grid item style={{flexGrow: 1}}>
                <Slider
                    style={{ marginTop: '6px' }}
                    ref={ref}
                    {...props}
                    onChange={(_, newValue) => props.onChange({ target: { value: newValue, name: props.name }})}
                />
            </Grid>
            <Grid item>
                <Typography variant="body2">Use {props.value} retries in a batch of 100 contacts.</Typography>
            </Grid>
        </Grid>
    );
});

export default RetrySlider;

import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import {EditOutlined, DeleteOutlineOutlined, ControlPointDuplicate} from '@material-ui/icons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useHistory } from "react-router-dom";
import ConfirmDialog from '../../components/ConfirmDialog';
import PreviewVendor from './PreviewVendor';
import {Typography, Grid, Button, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, TablePagination} from '@material-ui/core';
import axios from 'axios';
import {useSnackbar} from 'notistack';

export default function OnboardingList(props) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [merchants, setMerchants] = useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [vendorToPreview, setVendorToPreview] = useState({});

    useEffect(() => {
        getVendors();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    async function getVendors() {
        setIsLoading(true);
        try {
            const result = await axios.get(`${process.env.REACT_APP_PP_URL}/cms-onboard/get-vendors`,
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_PP_API_KEY
                }
            }
            ).catch(err => {
                enqueueSnackbar("Unable to get vendor list", { variant: 'error' });
                console.log(err);
            });

            if(result && result.data && result.data.items && result.data.items.length > 0){
                setMerchants(result.data.items);
            }
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    async function handleDelete() {
        setIsLoading(true);

        try{
            await axios.put(`${process.env.REACT_APP_PP_URL}/cms-onboard/delete-vendor`, 
                {id: toDelete},
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_PP_API_KEY
                    }
                });
            setConfirmDeleteOpen(false);
            setMerchants([...merchants.filter(merchant => merchant.id !== toDelete)]);

            enqueueSnackbar("Vendor deleted", { variant: 'success' });
        } catch(err) {
            console.error(err);
        }

        setIsLoading(false);
    }

    async function cloneVendor(vendorId){
        setIsLoading(true);

        try{
            await axios.post(`${process.env.REACT_APP_PP_URL}/cms-onboard/clone-vendor`, 
                { vendorId: vendorId },
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_PP_API_KEY
                    }
                }
                );

            enqueueSnackbar("Vendor successfully cloned", { variant: 'success' });
            getVendors();
        } catch(err) {
            enqueueSnackbar("Unable to clone vendor", { variant: 'error' });
            console.log(err);
        }

        setIsLoading(false);
    }

    return (
        <div>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Grid container direction='row' justify='space-between'>
                        <Typography variant="h4">Onboarding</Typography>
                        <Button variant='contained' color='primary' onClick={() => history.push('/admin/onboarding/edit/new')}>+ Merchant</Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {isLoading && <LinearProgress variant='indeterminate' color='secondary'/>}
                    <MaterialTable
                        title="Applications"
                        data={merchants}
                        columns={[
                            {title: "Name", field: "name"},
                            {title: "PromptPay Vendor", field: "id"},
                            {title: "Status", field: "status"},
                            {title: "CMS Merchant ID", field: "cmsInfo.cmsMerchId"},
                            {title: "CMS App Token", field: "cmsInfo.cmsAppToken"},
                            {title: "Nexio Token", field: "cmsInfo.nexioCredentials"}
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            search: true
                        }}
                        components={{
                            Pagination: licensePaging
                        }}
                        actions={[ 
                            {
                                icon: () => (<VisibilityOutlinedIcon color="primary" />),
                                tooltip: 'Preview Vendor Info',
                                onClick: (event, rowData) => {
                                    setShowPreview(true);
                                    setVendorToPreview(rowData);
                                }
                            },
                            rowData => ({
                                disabled: rowData.status === 'Approved',
                                icon: () => (<EditOutlined color={rowData.status === 'Approved' ? "disabled" : "primary"} />),
                                tooltip: 'Edit Vendor',
                                onClick: (event, rowData) => {
                                    history.push(`/admin/onboarding/edit/${rowData.id}`);
                                }
                            }),
                            {
                                icon: () => (<ControlPointDuplicate color='primary' />),
                                tooltip: 'Clone Vendor',
                                onClick: (event, rowData) => {
                                    cloneVendor(rowData.id, rowData.name);
                                }
                            },{
                                icon: () => (<DeleteOutlineOutlined color="primary" />),
                                tooltip: 'Delete Vendor',
                                onClick: (event, rowData) => {
                                    setToDelete(rowData.id);
                                    setConfirmDeleteOpen(true);
                                }
                            },
                        ]}
                    />
                    <ConfirmDialog
                        open={confirmDeleteOpen}
                        value={toDelete}
                        onConfirm={handleDelete}
                        onCancel={() => setConfirmDeleteOpen(false)}
                        confirmTxt="Yes">
                        <Typography>Do you want to permamently delete this onboarding application?</Typography>
                    </ConfirmDialog>
                </Grid>
            </Grid>
            <Dialog open={showPreview} onClose={() => setShowPreview(false)} maxWidth={false}>
                <DialogTitle>Vendor Preview</DialogTitle>
                <DialogContent>
                    <PreviewVendor vendorInfo={vendorToPreview} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={() => setShowPreview(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React, { useContext, useState, useEffect } from 'react';
import {
    makeStyles,
    Button,
    Paper,
    Grid,
    Typography,
    TextField,
    Select,
    InputLabel,
    FormControl,
    ListSubheader,
    ListItem,
    DialogActions,
    ListItemText,
    Divider,
    IconButton,
    List,
    Tooltip,
    DialogContent,
    DialogTitle,
    MenuItem,
    Dialog,
} from '@material-ui/core';
import { Container } from "react-smooth-dnd";
import DeviceDialog from './DeviceDialog'
import UserContext from '../context/UserContext';
import * as _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';


export default function DevicePreview(props) {
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [contact, setContact] = useState({});
    const [devices, setDevices] = useState([]);
    const [openDevice, setOpenDevice] = useState(false);
    const [currentDevice, setCurrentDevice] = useState({})


    useEffect(() => {
        async function getData() {
            if (props.contactId) {
                const contactId = props.contactId;
                const contactResult = await API.graphql(graphqlOperation(queries.getContact, { id: contactId }));
                const contact = contactResult.data.getContact;
                setContact({ ...contact });
                // console.log(contact.balances);
                if (contact.mobileDevices) {
                    setDevices(contact.mobileDevices);
                }
            }
        }
        getData();
    }, [userContext.tenant])

    const editDevice = (data) => async () => {
        setCurrentDevice(data)
        setOpenDevice(!openDevice);
    }

    const deleteDevice = (data) => async () => {
        let tempArray = devices;
        _.remove(tempArray, ['id', data.id]);
        await setDevices([...tempArray]);
        await saveDevices();
    }

    function handleAddButtonClick() {
        console.log('clicked!')
        setOpenDevice(!openDevice);
    }

    const isActive = true

    async function saveDevices() {
        delete contact.mobileDevices;
        delete contact.createdAt;
        delete contact.updatedAt;
        const updateContact = { ...contact, mobileDevices: devices };
        try {
            await API.graphql(graphqlOperation(mutations.updateContact, { input: updateContact }));
            enqueueSnackbar('Saved Contact');
        } catch (err) {
            console.error(err);
        }


    }

    return (
        <Grid item container direction="column">
            <Paper>
                <List dense={false}>
                    <ListSubheader color="primary">
                        <Grid container justify='space-between'>
                            <Grid item>
                                Devices
                            </Grid>
                            <Grid item>
                                <Tooltip title='Clear Segment List'>
                                    <IconButton aria-label="delete" disable={isActive} onClick={() => handleAddButtonClick()}>
                                        <AddCircleIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListSubheader>

                    <Container>
                        <ListItem>
                            <ListItemText primary={
                                <Grid container justify="flex-start">
                                    <Grid item xs={7}>
                                        Device ID
                                    </Grid>
                                    <Grid item xs={2}>
                                        Type
                                    </Grid>
                                </Grid>
                            } />
                        </ListItem>
                        <Divider />
                        {devices.map(data => (
                            <ListItem >
                                <ListItemText primary={
                                    <Grid container justify="flex-start" alignItems="center">

                                        <Grid item xs={7} style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden', marginRight: '30px'}}>
                                            {data.id}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {data.type.charAt(0).toUpperCase() + data.type.slice(1)}
                                        </Grid>
                                        <Grid item>
                                            <IconButton aria-label="edit" onClick={editDevice(data)}>
                                                <EditIcon color="primary" fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton aria-label="delete" onClick={deleteDevice(data)}>
                                                <DeleteIcon color="primary" fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                        <Divider />
                                    </Grid>

                                } />
                                <Divider />
                            </ListItem>
                        ))}

                    </Container>
                </List>
            </Paper>


            {/* DeviceDialog */}
            <DeviceDialog setCurrentDevice={setCurrentDevice} currentDevice={currentDevice} devices={devices} openDevice={openDevice} setOpenDevice={setOpenDevice} setDevices={setDevices} saveDevices={saveDevices} />
        </Grid>
    )
}
import React from 'react';
import './App.css';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { SnackbarProvider } from 'notistack';
import axios from 'axios';
import ScreenPop from './layouts/ScreenPop';
import MaxReturns from './pages/MaxReturns';
axios.interceptors.request.use((config) => {
    config.timeout = 200000;
    return config;
}, (error) => {
    return Promise.reject(error);
});

Amplify.configure(awsconfig);

function App() {
    return (
        <SnackbarProvider maxSnack={3}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            autoHideDuration={3000}>
            <Router>
                <Switch>
                    <Route path="/screenpop/:id">
                        <ScreenPop />
                    </Route>
                    <Route path="/maxreturns/:id">
                        <MaxReturns />
                    </Route>
                    <Route>
                        <MainLayout />
                    </Route>
                </Switch>
            </Router>
        </SnackbarProvider>
    );
}


export default App;

import React from "react";

import {
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    Button,
    Typography,
} from "@material-ui/core";
import { StyleOutlined } from "@material-ui/icons";
import RemoteImage from "react-remote-image";

const StylingOptions = (props) => {
    const { classes, formikProps } = props;

    return (
        <Card variant="outlined">
            <CardHeader
                title="Styling Options"
                avatar={<StyleOutlined color="primary" />}
                titleTypographyProps={{
                    variant: "h6",
                    style: { marginBottom: "7px" },
                }}
            />
            <CardContent>
                <Grid
                    item
                    container
                    direction="row"
                    spacing={4}
                    alignItems="center"
                    alignContent="center"
                >
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="siteLogo"
                            label="Site Logo"
                            type="text"
                            className={classes.field}
                            required
                            value={formikProps.values.siteLogo}
                            onChange={formikProps.handleChange}
                            onBlur={(e) => {
                                formikProps.handleBlur(e);
                            }}
                            error={
                                formikProps.errors.siteLogo &&
                                formikProps.touched.siteLogo
                            }
                            helperText={
                                formikProps.touched.siteLogo
                                    ? formikProps.errors.siteLogo
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item className={classes.field}>
                        <RemoteImage
                            src={formikProps.values.siteLogo}
                            renderLoading={() => (
                                <CircularProgress
                                    variant="indeterminate"
                                    color="primary"
                                />
                            )}
                            renderFetched={({ src }) => (
                                <img src={src} alt="Site Logo" width="300px" />
                            )}
                            renderFailure={(error, retry) => (
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography variant="body2">
                                            {error.message}.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={retry}
                                            size="small"
                                        >
                                            Retry
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            alt="Site Logo"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="header1"
                            label="Header 1"
                            type="text"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.header1}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.header1 &&
                                formikProps.touched.header1
                            }
                            helperText={
                                formikProps.touched.header1
                                    ? formikProps.errors.header1
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="header2"
                            label="Header 2"
                            type="text"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.header2}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.header2 &&
                                formikProps.touched.header2
                            }
                            helperText={
                                formikProps.touched.header2
                                    ? formikProps.errors.header2
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="sitePrimary"
                            label="Site Primary"
                            type="color"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.sitePrimary}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.sitePrimary &&
                                formikProps.touched.sitePrimary
                            }
                            helperText={
                                formikProps.touched.sitePrimary
                                    ? formikProps.errors.sitePrimary
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="siteSecondary"
                            label="Site Secondary"
                            type="color"
                            required={true}
                            className={classes.longInput}
                            value={formikProps.values.siteSecondary}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.siteSecondary &&
                                formikProps.touched.siteSecondary
                            }
                            helperText={
                                formikProps.touched.siteSecondary
                                    ? formikProps.errors.siteSecondary
                                    : ""
                            }
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default StylingOptions;

import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, 
    FormControl,
    FormControlLabel, 
    InputLabel, 
    Select, 
    MenuItem, 
    Typography, 
    Input,
    Switch, 
    IconButton, 
    Paper, 
    List, 
    ListSubheader, 
    Divider,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    CircularProgress,
    Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DragHandle from '@material-ui/icons/DragHandleOutlined';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import { Container, Draggable } from "react-smooth-dnd";
import { getSearchFields, getSearchMetaDataId, getSearchEntities } from '../E2/MSALConfig';
import { availableMeta } from '../E2/mappings';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        minHeight: '300px'
    },
    entities: {
        cursor: 'pointer'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    search: {
        padding: '1em'
    }
}));

export default function SearchConfig(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const userContext = useContext(UserContext);
    const [loggings, setLoggings] = useState([]);
    const [searchEntities, setSearchEntities] = useState([
        {
            SchemaName: 'Account',
            value: 'Account'
        },
        {
            SchemaName: 'Contact',
            value: 'Contact'
        },
        {
            SchemaName: 'Lead',
            value: 'Lead'
        },
        {
            SchemaName: 'Activity',
            value: 'Activity'
        },
        {
            SchemaName: 'Case',
            value: 'Incident'
        },
        {
            SchemaName: 'Order',
            value: 'Order'
        },
        {
            SchemaName: 'Opportunity',
            value: 'Opportunity'
        }
    ]);
    const [selSearchEntity, setSelSearchEntity] = useState('');
    const [searchFields, setSearchFields] = useState([]);
    const [selSearchFields, setSelSearchFields] = useState([]);
    const [searchMeta, setSearchMeta] = useState([]);
    const [selSearchMeta, setSelSearchMeta] = useState('');
    const [multiSearch, setMultiSearch] = useState(false);
    const [openClosed, setOpenClosed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [loadingEntities, setLoadingEntities] = useState(false);

    useEffect(() => {
        async function stopLoad(){
            setLoadingEntities(false);
        }
        if (searchEntities !== null && setSearchEntities !== []){
            stopLoad();
        }
    }, [searchEntities])

    useEffect(() => {
        async function getData(){
            if (props.search){
                setLoggings(props.search);
            }
            setMultiSearch(props.multiSearch);
            let updatedMetaData=[];
            for (const metaValue of availableMeta){
                if (metaValue.mediaType.indexOf(props.mediaType) !== -1){
                updatedMetaData.push(metaValue);
                }
            }
            const sortedMeta = _.sortBy(updatedMetaData, ['metaData']);
            setSearchMeta([...sortedMeta]);
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    useEffect(() => {
        async function getData(){
            let MetaDataId = await '';
            if(selSearchEntity === 'Account'){
                MetaDataId = await getSearchMetaDataId(props.org, 'Account');
            } else if (selSearchEntity === 'Contact'){
                MetaDataId = await getSearchMetaDataId(props.org, 'Contact');
            } else if (selSearchEntity === 'Lead'){
                MetaDataId = await getSearchMetaDataId(props.org, 'Lead');
            } else if (selSearchEntity === 'Activity'){
                MetaDataId = await getSearchMetaDataId(props.org, props.crmEntity.SchemaName);
            } else if (selSearchEntity === 'Incident'){
                MetaDataId = await getSearchMetaDataId(props.org, 'Incident');
            } else if (selSearchEntity === 'Order'){
                MetaDataId = await getSearchMetaDataId(props.org, 'SalesOrder');
            } else if (selSearchEntity === 'Opportunity'){
                MetaDataId = await getSearchMetaDataId(props.org, 'Opportunity');
            } else {
                MetaDataId = await getSearchMetaDataId(props.org, selSearchEntity);
            }
            if (MetaDataId !== '' && MetaDataId !== undefined){
                const tempFields = await getSearchFields(props.org, MetaDataId[0].MetadataId);
                const fields = _.sortBy(tempFields, ['SchemaName']);
                setSearchFields(fields);
            }
        }
        if (selSearchEntity){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selSearchEntity])

    const handleSearchEntity = async (event) => {
        const searchEntity = event.target.value;
        console.log('Search Entity' + searchEntity);
        if (searchEntity !== 'expandList' && searchEntity !== 'shrinkList'){
            setSelSearchEntity(searchEntity);
        } else if (searchEntity === 'expandList'){
            let entities = [];
            setLoadingEntities(true);
            const expandedEntities = await getSearchEntities(props.org);
            const sortedEntities = _.sortBy(expandedEntities, ['SchemaName']);
            for (const entity of sortedEntities){
                const newEntity = {
                    SchemaName: entity.SchemaName,
                    value: entity.SchemaName
                }
                entities.push(newEntity);
            }
            setSearchEntities([...entities]);
            setExpanded(true);
        } else if (searchEntity === 'shrinkList'){
            setLoadingEntities(true);
            let defaults = [
                {
                    SchemaName: 'Account',
                    value: 'Account'
                },
                {
                    SchemaName: 'Contact',
                    value: 'Contact'
                },
                {
                    SchemaName: 'Lead',
                    value: 'Lead'
                },
                {
                    SchemaName: 'Activity',
                    value: 'Activity'
                },
                {
                    SchemaName: 'Case',
                    value: 'Incident'
                },
                {
                    SchemaName: 'Order',
                    value: 'Order'
                },
                {
                    SchemaName: 'Opportunity',
                    value: 'Opportunity'
                }
            ];
            setSearchEntities([...defaults]);
            setExpanded(false);
        } 
    }

    const handleSelSearchField = (event) => {
        const searchField = event.target.value;
        setSelSearchFields(searchField);
    }

    const handleSelectSearchMeta = (event) => {
        const searchMeta = event.target.value;
        setSelSearchMeta(searchMeta);
    }

    function handleMultiChange(){
        setMultiSearch(!multiSearch);
        props.setMultiSearch(!multiSearch);
    }

    function handleOpenCloseChange(){
        setOpenClosed(!openClosed);
        props.setOpenClosed(!openClosed);
    }

    const addLogging = () => {
        if(selSearchEntity && selSearchEntity !== '' && selSearchFields && selSearchFields !== [] && selSearchMeta && selSearchMeta !== ''){
            const searchAction = {
                searchEntity: selSearchEntity,
                searchFields: selSearchFields,
                searchMeta: selSearchMeta
            };
            loggings.push(searchAction);
            props.setSearch(loggings);
            setSelSearchEntity('');
            setSelSearchFields([]);
            setSelSearchMeta('');
        } else {
            enqueueSnackbar('Please enter values for all search parameters.', {
                variant: 'error'
              })
        }

    }

    const deleteLogging = (data) => () => {
        const tempLoggings = loggings;
        _.remove(tempLoggings, loggings[data]);
        props.setSearch(tempLoggings);
        setLoggings([...tempLoggings]);
        if(loggings.length < 2){
            setMultiSearch(false);
            props.setMultiSearch(false);
        }
    }

    const onDrop =({removedIndex, addedIndex}) => {
        const posRemove = removedIndex;
        const posAdded = addedIndex;
        const copy = [...loggings];
        const dragItem = copy[posRemove];
        _.pullAt(copy, [posRemove]);
        copy.splice(posAdded, 0, dragItem);
        setLoggings(copy);
        props.setSearch(copy);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Typography className={classes.heading}>Search Settings</Typography>
                            <Grid item container direction="row" alignItems="center" justify="flex-start" spacing={4}>
                                <div className={classes.wrapper}>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel shrink id="searchFor">Search Entity</InputLabel>
                                            <Select
                                                labelId="searchFor"
                                                name="searchFor"
                                                value={selSearchEntity}
                                                displayEmpty
                                                className={classes.selectFields}
                                                onChange={handleSearchEntity}
                                            >
                                                {searchEntities.map(data =>(
                                                    <MenuItem value={data.value}>{data.SchemaName}</MenuItem>
                                                ))}
                                                <div className={classes.entities} hidden={expanded} value={'expandList'} >
                                                    <ListSubheader color="primary">See all entities...</ListSubheader>
                                                </div>
                                                <div className={classes.entities} hidden={!expanded} value={'shrinkList'}>
                                                    <ListSubheader color="primary">See only default entities...</ListSubheader>
                                                </div>
                                            </Select>
                                        </FormControl>
                                        {loadingEntities && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Grid>
                                </div>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel shrink id="field-label">Search Fields</InputLabel>
                                        <Select
                                            multiple
                                            labelId="field"
                                            onChange={handleSelSearchField}
                                            value={selSearchFields}
                                            className={classes.selectFields}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                        >       
                                            {searchFields.map(data =>(
                                                <MenuItem key={data.LogicalName} value={data.SchemaName}>
                                                    <Checkbox checked={selSearchFields.indexOf(data.SchemaName) > -1} color="primary" />
                                                    <ListItemText primary={data.SchemaName} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel shrink id="metaData">Meta Data</InputLabel>
                                        <Select
                                            labelId="metaData"
                                            name="metaData"
                                            value={selSearchMeta}
                                            displayEmpty
                                            className={classes.selectFields}
                                            onChange={handleSelectSearchMeta}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {searchMeta.map(data =>(
                                                <MenuItem value={data.metaData}>{data.metaData}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <div onClick={addLogging}>
                                        <IconButton aria-label="add search params">
                                            <AddIcon color="primary" fontSize="small" />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        <br></br>
                        <Grid container item direction="row">
                            <Grid item xs={12}>
                                <Paper>
                                    <List dense={true}>
                                        <ListSubheader color="primary">Search Parameters</ListSubheader>
                                        <Divider/>
                                        <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={onDrop}>
                                            {loggings.map((data, index) => ( 
                                                <Draggable >
                                                    <ListItem>
                                                        <ListItemText primary={
                                                            <Grid container>
                                                                <Grid item xs={1}>
                                                                    {data.searchEntity}
                                                                </Grid>
                                                                <Grid item container direction="row" spacing={2} xs={8}>
                                                                    {data.searchFields.map(fields => (
                                                                        <Grid item>
                                                                            {fields}
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    {data.searchMeta}
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                        <ListItemSecondaryAction>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid item>
                                                                    <IconButton aria-label="delete" onClick={deleteLogging(index)}>
                                                                        <DeleteIcon color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    <DragHandle className="drag-handle" color="primary" />
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Draggable>
                                            ))}
                                        </Container>
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid item container direction='column'>
                            <Grid item container direction="row" alignItems="center">
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="multiSearch"
                                                checked={multiSearch}
                                                onChange={handleMultiChange}
                                                value={true}
                                                color="primary" />
                                        }
                                        label="Multi-Entity Search"
                                    />
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        placement="top-start"
                                        leaveDelay={500}
                                        title={
                                            <React.Fragment>
                                                <Typography variant="body1">
                                                    <strong>Multi Search</strong>
                                                    <br></br>
                                                    If you would like to return ALL matching search results across entities, set Multi Search to true.
                                                </Typography>
                                            </React.Fragment>
                                        }>
                                        <IconButton size="small"><HelpOutlineOutlined color="primary" /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center">
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="openClosed"
                                                checked={openClosed}
                                                onChange={handleOpenCloseChange}
                                                value="false"
                                                color="primary" />
                                        }
                                        label="Search Closed Entities"
                                    />
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        placement="top-start"
                                        leaveDelay={500}
                                        title={
                                            <React.Fragment>
                                                <Typography variant="body1">
                                                    <strong>Search Closed Entities</strong>
                                                    <br></br>
                                                    If you would like to search both Open and Closed entities, set Search Closed Entities to true.
                                                </Typography>
                                            </React.Fragment>
                                        }>
                                        <IconButton size="small"><HelpOutlineOutlined color="primary" /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br></br>
                    </Grid>
                    <br></br>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React from "react";

import {
    FormControl,
    Grid,
    Switch,
    Checkbox,
    InputLabel,
    FormControlLabel,
    Select,
    MenuItem,
    TextField,
    FormHelperText,
} from "@material-ui/core";

export default function C2AScheduleCallBack(props) {
    const { formikProps, classes, outboundSkills } = props;

    return (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item>
                                {formikProps.values?.c2a
                                    ?.callMeLaterEnabled && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    formikProps.values?.c2a
                                                        ?.callMeLater
                                                        ?.finalize ?? false
                                                }
                                                onChange={
                                                    formikProps.handleChange
                                                }
                                                onBlur={formikProps.handleBlur}
                                                name="c2a.callMeLater.finalize"
                                                color="primary"
                                            />
                                        }
                                        label="Finalize Schedule Call Back C2A"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {formikProps.values.c2a.callMeLaterEnabled && (
                        <>
                            <Grid item>
                                <FormControl>
                                    <InputLabel
                                        error={
                                            formikProps.errors?.c2a?.callMeLater
                                                ?.skillId &&
                                            formikProps.touched?.c2a
                                                ?.callMeLater?.skillId
                                        }
                                    >
                                        Skills
                                    </InputLabel>
                                    <Select
                                        name="c2a.callMeLater.skillId"
                                        className={classes.field}
                                        value={
                                            formikProps.values?.c2a?.callMeLater
                                                ?.skillId ?? false
                                        }
                                        onBlur={formikProps.handleBlur}
                                        onChange={formikProps.handleChange}
                                        error={
                                            formikProps.errors?.c2a?.callMeLater
                                                ?.skillId &&
                                            formikProps.touched?.c2a
                                                ?.callMeLater?.skillId
                                        }
                                    >
                                        {outboundSkills.map((skill) => (
                                            <MenuItem
                                                key={skill.skillId}
                                                value={skill.skillId}
                                            >
                                                {skill.skillName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formikProps.errors?.c2a?.callMeLater
                                        ?.pointOfContact &&
                                        formikProps.touched?.c2a?.callMeLater
                                            ?.pointOfContact && (
                                            <FormHelperText error>
                                                {
                                                    formikProps.errors?.c2a
                                                        ?.callMeLater
                                                        ?.pointOfContact
                                                }
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    label="Message"
                                    type="text"
                                    name="c2a.callMeLater.text"
                                    className={classes.textArea}
                                    variant="outlined"
                                    multiline
                                    rows={12}
                                    value={
                                        formikProps.values?.c2a?.callMeLater
                                            ?.text ?? ""
                                    }
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={
                                        formikProps.errors?.c2a?.callMeLater
                                            ?.text &&
                                        formikProps.touched?.c2a?.callMeLater
                                            ?.text
                                    }
                                    helperText={
                                        formikProps.touched?.c2a?.callMeLater
                                            ?.text &&
                                        formikProps.errors?.c2a?.callMeLater
                                            ?.text
                                    }
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
    );
}

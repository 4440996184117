import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { Formik } from 'formik';
import MUIRichTextEditor from 'mui-rte';
import * as mutations from '../graphql/mutations';
import { makeStyles, Paper, Grid, Typography, TextField, Button } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { ContentState, convertToRaw } from 'draft-js';
import UserContext from '../context/UserContext';

const useStyles = makeStyles({
    formControl: {
        minHeight: '100px'
    },
    paper: {
        padding: '15px'
    }
});

const rcxTheme = createTheme();

Object.assign(rcxTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                minHeight: '100px'
            }
        }
    }
});

/**
 * Add notes to the contact's activity log
 * @component
 * @example
 * const contactId = '0266723e-f257-45fa-b526-2c3fd7ad628f'
 * return (
 *  <ContactNote contactId={contactId} />
 * )
 */
const ContactNote = function (props) {
    const classes = useStyles();
    const emptyBody = JSON.stringify(convertToRaw(ContentState.createFromText('')));
    const { enqueueSnackbar } = useSnackbar();

    /** @var {string} ContactNote~title The title of the note */
    const [title, setTitle] = useState('');
    /** @var {string} ContactNote~body The body of the note. Uses MUIRichTextEditor. */
    const [body, setBody] = useState(emptyBody);

    const rteRef = useRef();
    const bodyRef = useRef('');
    const { tenant } = useContext(UserContext);
    const validation = /\w/;

    /**
     * Handle the save event on the 'mui-rte' component.
     * @param {any} data The 'mui-rte' onSave event object.
     */
    const handleRteSave = (data) => {
        bodyRef.current = data;
    }

    return (
        <Paper className={classes.paper} elevation={2}>
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                <Grid item>
                    <Typography variant="h6">Note</Typography>
                    <Formik
                        initialValues={{ title: title }}
                        enableReinitialize={true}
                        onSubmit={async (values, formikBag) => {
                            rteRef.current.save();//Save the editor
                            let bodyText = JSON.parse(bodyRef.current).blocks[0].text;//Grab the text

                            //Perform validation
                            if (!validation.test(bodyText)) {
                                enqueueSnackbar('Note body cannot be empty.', { variant: 'error' });
                            }
                            else if (!validation.test(values.title)) {
                                enqueueSnackbar('Note title cannot be empty.', { variant: 'error' });
                            }
                            else {//If all is well, proceed
                                const note = { ...values };
                                for (const key in note) {
                                    note[key] = note[key] || null;
                                }
                                note.type = 'Note';
                                note.body = bodyRef.current;
                                note.tenant = tenant;
                                note.activityLogContactId = props.contactId;
                                note.contactStatus = '';
                                note.timestamp = new Date();
                                try {
                                    await API.graphql(graphqlOperation(mutations.createActivityLog,
                                        {
                                            input: note
                                        }));
                                    bodyRef.current = '';
                                    setTitle('');
                                    enqueueSnackbar('Note Created');
                                    formikBag.setSubmitting(false);
                                    formikBag.resetForm();
                                    setBody(undefined);//Wipe out the body of the editor
                                    //close the component if opened as a dialog
                                    if (props.cancel) {
                                        props.cancel();
                                    }
                                } catch (err) {
                                    console.error(err);
                                }
                            }
                        }}
                    >
                        {formikProps => (
                            <form onSubmit={formikProps.handleSubmit}>
                                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                    <TextField
                                        name="title"
                                        margin="dense"
                                        label="Title"
                                        type="text"
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        value={formikProps.values.title}
                                    />
                                    <MuiThemeProvider theme={rcxTheme}>
                                        <MUIRichTextEditor
                                            name="body"
                                            margin="dense"
                                            label="Body"
                                            type="text"
                                            multiline
                                            rows="4"
                                            ref={rteRef}
                                            value={body}
                                            onSave={handleRteSave}
                                            controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'numberList', 'bulletList']}
                                        />
                                    </MuiThemeProvider>
                                    <Button type="submit" variant="contained" color="primary">Add</Button>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Paper>
    )
}

ContactNote.propTypes = {
    /**
     * The ID of the contact you want to add the note for.
     */
    contactId: PropTypes.string.isRequired,
}

export default ContactNote;
import React, { useEffect, useState, useContext, createRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserContext from '../context/UserContext';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LinearProgress, Paper } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce';
// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import useConfirmDialog from '../components/ConfirmDialog';
import DialogResult from '../models/DialogResults';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        marginBottom: theme.spacing(2)
    },
    selectInput: {
        minWidth: '100px'
    },
    paper: {
        padding: theme.spacing(2)
    },
    dialogDiv: {
        minWidth: '600px'
    },
    longInput: {
        minWidth: '200px'
    },
    textArea: {
        minWidth: '500px'
    },
    htmlArea: {
        minWidth: '500px',
        border: '1px solid black'
    }
}));

const newTemplate = {
    id: '',
    tenant: '',
    name: '',
    textBody: '',
    type: ''
};

export default function MessageTemplate() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    // const { showDialog } = useConfirmDialog();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(newTemplate);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewText, setPreviewText] = useState('');
    const [previewHTML, setPreviewHTML] = useState();
    const [messageLength, setMessageLength] = useState(0);
    const referenceToTextarea = createRef('');
    const [customFields, setCustomFields] = useState([]);

    const formikRef = createRef('');
    const [editorContent, setEditorContent] = useState('');

    const placeholders = {
        firstName: '~FirstName~',
        lastName: '~LastName~',
        balance: '~Balance~',
        ageDays: '~AgeDays~',
        minAmount: '~MinAmount~',
        payLinkDisplay: '~PayLink~',
    }

    useEffect(() => {
        if (userContext.tenant){
            getData();
        }

        async function getData(){
            const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
            const tenantSettings = dynamoSettings.data.getTenantSettings;
            const custom = tenantSettings.customFields;
            console.log(custom);
            setCustomFields([...custom]);
        }

    }, [userContext.tenant]);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const savedTemplate = await API.graphql(graphqlOperation(queries.getPaymentTemplate, { id: id }));
            setLoading(false);
            if (savedTemplate.data) {
                setTemplate(savedTemplate.data.getPaymentTemplate);
                setEditorContent(savedTemplate.data.getPaymentTemplate.textBody);
            }
        }

        if (id && id !== 'new') {
            getData();
        } else if (id === 'new') {
            setTemplate(newTemplate);
        }
    }, [id]);

    const UpdateLengthAndSaveButton = () => {
        const { values } = useFormikContext();
        useEffect(() => {
            let tempMessage = values.textBody

            tempMessage = tempMessage.replaceAll(placeholders.firstName, ' '.repeat(6))
                .replaceAll(placeholders.lastName, ' '.repeat(7))
                .replaceAll(placeholders.balance, ' '.repeat(7))
                .replaceAll(placeholders.ageDays, ' '.repeat(2))
                .replaceAll(placeholders.minAmount, ' '.repeat(6))
                .replaceAll(placeholders.payLinkDisplay, ' '.repeat(42));

            setMessageLength(tempMessage.length);
        }, [values.textBody])

        return null;
    }

    function closePreview() {
        setOpenPreview(false);
    }

    function generatePreview() {
        if (formikRef.current.values.type === "PlainText") {
            const previewString = formikRef.current.values.textBody.replaceAll(placeholders.firstName, 'Bruce')
                .replaceAll(placeholders.lastName, 'Wayne')
                .replaceAll(placeholders.balance, '434.76')
                .replaceAll(placeholders.ageDays, '20')
                .replaceAll(placeholders.minAmount, '282.60')
                .replaceAll(placeholders.payLinkDisplay, 'https://customerdynamics.com/samplepaylink');
            setPreviewText(previewString);
        } else if (formikRef.current.values.type === "HTML") {
            // Get the content of tinymce editor and set it as previewHTML
            const htmlInfo = tinymce.activeEditor.getContent();
            const previewString = htmlInfo.replaceAll(placeholders.firstName, 'Bruce')
                .replaceAll(placeholders.lastName, 'Wayne')
                .replaceAll(placeholders.balance, '434.76')
                .replaceAll(placeholders.ageDays, '20')
                .replaceAll(placeholders.minAmount, '282.60')
                .replaceAll(placeholders.payLinkDisplay, 'https://customerdynamics.com/samplepaylink');
            setPreviewHTML(previewString);
        }
    }

    const addPlaceholder = (input) => () => {
        // Save our html editor before we add in our placeholders
        if (formikRef.current.values.type === "PlainText") {
            const text = formikRef.current.values.textBody;
            const replaceText = text.slice(0, referenceToTextarea.current.selectionStart)
                + `~${input}~`
                + text.slice(referenceToTextarea.current.selectionEnd, text.length);

            formikRef.current.setFieldValue('textBody', replaceText);
        } else if (formikRef.current.values.type === "HTML") {
            // We are looking at an HTML editor, get the text and slice the text so we can add in the placeholder
            const replaceText = tinymce.activeEditor.selection.setContent(`~${input}~`);
            setEditorContent(replaceText);
            //formikRef.current.setFieldValue('textBody', replaceText);
        }
    }

    const previewMessage = () => {
        generatePreview();
        setOpenPreview(true);
    }

    function clearTemplate() {
        if (formikRef.current.values.type === "PlainText" || formikRef.current.values.type === "") {
            formikRef.current.setFieldValue('textBody', '');
        }
        else {
            setEditorContent("");
        }
    }

    // async function handleClose(dirty) {
    //     if (!dirty || await showDialog({ children: 'You have unsaved changes. Are you sure you want to leave this page?', confirmTxt: 'Yes', cancelTxt: 'Cancel' }) === DialogResult.Confirm) {
    //         history.push("/ppay/MessageTemplates")
    //     }
    // }

    const handleEditorChange = (event) => {
        setEditorContent(event);
    }

    return (
        <div>
            <Formik
                initialValues={template}
                enableReinitialize={true}
                innerRef={formikRef}
                validationSchema={Yup.object(
                    {
                        name: Yup.string()
                            .required('A Profile name is required')
                            .trim().matches(/^[a-zA-Z0-9 -]+$/),

                        textBody: Yup.string(),
                        // .required('A message body is required')
                        // .trim().matches(placeholders.payLinkDisplay, 'A pay link is required in the message body'),

                        type: Yup.string()
                            .required('A message type is required')

                    }
                )}
                onSubmit={async (values, formikBag) => {
                    let successMsg = 'Template Updated';
                    let mutation = mutations.updatePaymentTemplate;
                    //if(formikRef.current.values.type === "HTML")
                    //values.textBody = editorContent;
                    values = { ...values };
                    const templateValues = { ...values };
                    delete templateValues.createdAt;
                    delete templateValues.updatedAt;
                    if (id === 'new') {
                        mutation = mutations.createPaymentTemplate;
                        templateValues.tenant = userContext.tenant;
                        successMsg = 'Template Created';
                        delete templateValues.id
                    }
                    try {
                        const templateResult = await API.graphql(graphqlOperation(mutation, { input: templateValues }));
                        enqueueSnackbar(successMsg, { variant: 'success' });
                        formikBag.setSubmitting(false);
                        const savedTemplate = templateResult.data[id === 'new' ? 'createPaymentTemplate' : 'updatePaymentTemplate']
                        if (savedTemplate.id === id) {
                            setTemplate(savedTemplate);
                            formikBag.resetForm(savedTemplate);
                        } else {
                            formikBag.resetForm(template);
                            history.push(savedTemplate.id);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                       <Grid container justifyContent='space-between' className={classes.heading} alignItems='center' alignContent='center'>
                            <Grid item>
                                <Typography variant="h4">Message Template</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item>
                                        {/* <Button onClick={handleClose} color="primary" variant="outlined">Close</Button> */}
                                    </Grid>
                                    <Grid item>
                                        <Button disabled={!formikProps.dirty} type="submit" color="secondary" variant="contained">Save</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Paper className={classes.paper}>
                            <Grid container direction="column" spacing={1}>
                                {loading &&
                                    <Grid item>
                                        <LinearProgress variant="query" color="secondary" />
                                    </Grid>}
                                <Grid item>
                                    <Grid container direction="row" spacing={4} alignItems='center' alignContent='center'>
                                        <Grid item>
                                            <TextField
                                                margin="dense"
                                                name="name"
                                                label="Template Name"
                                                type="text"
                                                className={classes.longInput}
                                                required={true}
                                                value={formikProps.values.name}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={formikProps.errors.name && formikProps.touched.name}
                                                helperText={formikProps.touched.name ? formikProps.errors.name : ""}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <InputLabel>Message Type</InputLabel>
                                                <Select
                                                    value={formikProps.values.type}
                                                    onChange={(event) => {
                                                        if (event.target.value === "HTML") {
                                                            setEditorContent(formikProps.values.textBody);
                                                        }
                                                        else if (event.target.value === "PlainText" && editorContent !== "") {
                                                            formikProps.setFieldValue('textBody', tinymce.activeEditor.getContent({ format: 'text' }));
                                                        }
                                                        formikProps.setFieldValue('type', event.target.value);
                                                    }}
                                                    onBlur={formikProps.handleBlur}
                                                    className={classes.longInput}
                                                >
                                                    <MenuItem value='PlainText'>PlainText</MenuItem>
                                                    <MenuItem value='HTML'>HTML</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">Estimated message length: {messageLength} characters</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justifyContent='space-between' xs={12}>
                                        <Grid item xs={3}>
                                            {(formikProps.values.type === "" || formikProps.values.type === "PlainText") &&
                                                <TextField
                                                    inputRef={referenceToTextarea}
                                                    margin="dense"
                                                    name="textBody"
                                                    label="Template Text"
                                                    type="text"
                                                    className={classes.textArea}
                                                    variant="outlined"
                                                    multiline
                                                    rows={12}
                                                    required={true}
                                                    value={formikProps.values.textBody}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    error={formikProps.errors.textBody && formikProps.touched.textBody}
                                                    helperText={formikProps.touched.textBody ? formikProps.errors.textBody : ""}
                                                />}
                                            {formikProps.values.type === "HTML" &&
                                                <Editor
                                                    apiKey={'vkwggyc7pp9fodl07v5lexzjhiz79bzjf9v8gu0n1xwc9806'}
                                                    name="textBody"
                                                    value={editorContent}

                                                    init={{
                                                        initialValue: editorContent,
                                                        skin: true,
                                                        content_css: true,
                                                        height: 500,
                                                        width: 700,
                                                        menubar: true,
                                                        plugins: ['autolink lists advlist link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
                                                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustifyContent | numlist bullist| outdent indent | removeformat',
                                                        branding: false,
                                                        resize: false,
                                                        statusbar: false,
                                                        init_instance_callback: function (editor) {
                                                            editor.on('blur', function (e) {
                                                                formikProps.values.textBody = editor.getContent();
                                                                setEditorContent(editor.getContent())
                                                            });
                                                        }
                                                    }}
                                                    onEditorChange={(event) => { handleEditorChange(event); formikProps.setFieldValue('textBody', event); }}
                                                />
                                            }
                                            <UpdateLengthAndSaveButton />
                                        </Grid>
                                        <Grid item container direction="column" xs={3} spacing={1}>
                                            <Grid item>
                                                <Typography variant="button">Contact Tags</Typography>
                                            </Grid>
                                            <Divider />
                                            <Grid item>
                                                <Button size="small" color='primary' onClick={addPlaceholder('FirstName')}>First Name</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button size="small" color='primary' onClick={addPlaceholder('LastName')}>Last Name</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button size="small" color="secondary" onClick={addPlaceholder('PayLink')}>* Pay Link</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column" xs={3} spacing={1}>
                                            <Grid item>
                                                <Typography variant="button">Custom Fields</Typography>
                                            </Grid>
                                            <Divider />
                                            {userContext?.customFields?.map((customField) => (
                                                <Grid item>
                                                    <Button size="small" color='primary' onClick={addPlaceholder(customField.name)}>{customField.displayName}</Button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid item style={{ paddingTop: '16px' }}>
                                    <Grid container direction="row" spacing={6}>
                                        <Grid item>
                                            <Button variant="outlined" color="primary" onClick={previewMessage}>Preview Message</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" onClick={clearTemplate}>Clear All</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Dialog
                                open={openPreview}
                                onClose={closePreview}
                            >
                                <DialogTitle >Message Preview</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {formikProps.values.type === "PlainText" && <Typography variant="button" style={{ whiteSpace: 'pre-line' }} >{`${previewText}`}</Typography>}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogContent>
                                    {formikProps.values.type === "HTML" &&
                                        <Editor
                                            apiKey={'vkwggyc7pp9fodl07v5lexzjhiz79bzjf9v8gu0n1xwc9806'}
                                            initialValue={previewHTML}
                                            init={{
                                                height: 500,
                                                width: 500,
                                                readonly: 1,
                                                menubar: false,
                                                toolbar: false,
                                                branding: false,
                                                resize: false,
                                                statusbar: false,
                                            }}
                                        />}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closePreview} color="primary" autoFocus>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Paper>
                        {/* <ConfirmDialog
                        open={confirmOpen}
                        value={template}
                        onConfirm={handleDelete}
                        onCancel={handleCancelDelete}
                        confirmTxt="Yes">
                        <Typography>This template will be permanently deleted.</Typography>
                    </ConfirmDialog> */}

                    </form>
                )}
            </Formik>
        </div>
    )
}
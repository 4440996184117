import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { EditOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import ConfirmDialog from '../components/ConfirmDialog';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment';

export default function FieldMappings() {
    const history = useHistory();
    const userContext = useContext(UserContext);
    const { path } = useRouteMatch();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const [mappingList, setMappingList] = useState([]);

    useEffect(() => {
        async function getData() {
            const fieldMappingResponse = await API.graphql(graphqlOperation(queries.tenantMappings, { tenant: userContext.tenant }));
            setMappingList(fieldMappingResponse.data.tenantMappings.items);
        }
        if (userContext.tenant) {
            getData();
        }
    }, [userContext.tenant]);

    const confirmDelete = (id) => {
        setToDelete(id);
        setConfirmOpen(true);
    }

    async function handleDelete() {
        await API.graphql(graphqlOperation(mutations.deleteFieldsMapping, {input: {id: toDelete}}));
        window.location.reload(false)
        setConfirmOpen(false);
    }

    function handleCancelDelete() {
        setConfirmOpen(false);
    }

    return (
        <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item container justify="space-between">
                <Grid item>
                    <Typography variant="h4">Field Mappings</Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" component={Link} to={`${path}/new`}>+ Field Mapping</Button>
                </Grid>
            </Grid>
            <Grid item>
                    <MaterialTable
                        title=""
                        data={mappingList}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { 
                                title: 'Created Date', 
                                field: 'createdAt',
                                render: rowData => (moment(rowData.createdAt).format('LLL'))
                            },
                            { 
                                title: 'Updated Date',
                                field: 'updatedAt',
                                render: rowData => (moment(rowData.updatedAt).format('LLL'))
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10
                        }}
                        actions={[
                            {
                                icon: () => (<EditOutlined color="primary" />),
                                tooltip: 'Edit Profile',
                                onClick: (event, rowData) => {
                                    history.push(`${path}/${rowData.id}`);
                                }
                            },
                            {
                                icon: () => (<DeleteOutlineOutlined color="primary" />),
                                tooltip: 'Delete Profile',
                                onClick: (event, rowData) => {
                                    confirmDelete(rowData.id)
                                }
                            }
                        ]}
                        onRowClick={(event, rowData) => {
                            history.push(`${path}/${rowData.id}`);
                        }}>

                    </MaterialTable>
            </Grid>
            <ConfirmDialog
                open={confirmOpen}
                value={toDelete}
                onConfirm={handleDelete}
                onCancel={handleCancelDelete}
                confirmTxt="Yes">
                <Typography>Permanently delete this field mapping?</Typography>
            </ConfirmDialog>
        </Grid>
    )
}
import React, { useEffect, useState, useContext } from 'react';
import { API, Auth } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import UserContext from '../context/UserContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';

/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const ExportDialog = function (props) {
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [toSFTP, setToSFTP] = useState(false);
    const [toS3, setToS3] = useState(true);
    const [sftpConfigured, setSFTPConfigured] = useState(false);

    useEffect(() => {
        //If there is configuration for the export SFTP then allow it as an action.
        if (userContext.ftpExport && userContext.ftpExport.host) {
            setSFTPConfigured(true);
            setToSFTP(true);
        }
    }, [userContext]);

    async function handleExportClick() {
        try {
            if (toSFTP || toS3) {
                console.log(props.campaign)
                enqueueSnackbar('Exporting Campaign');
                await startExport(props.campaign);
                if (toSFTP) {
                    enqueueSnackbar('Export file being sent to SFTP Server');
                }
                if (toS3) {
                    enqueueSnackbar('Export Download link will be sent in an email');
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    async function startExport(campaignId) {
        try {
            const exportPromise = API.post(
                'cdyxdialer',
                '/export/campaign',
                {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey
                    },
                    body: {
                        campaignId: campaignId,
                        SFTP: toSFTP,
                        S3: toS3
                    }
                });
            await Promise.all([exportPromise]);
        } catch (err) {
            console.log(err);
        }
        props.onCancel();
    }

    async function closeDialog() {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    async function handleSFTPChange() {
        try {
            if (toSFTP) {
                setToSFTP(false);
            }
            else if (sftpConfigured) {
                setToSFTP(true);
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function handleS3Change() {
        setToS3(!toS3);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Dialog maxWidth="xs" open={props.open}>
                        <DialogTitle id="dp-dialog-title">Export Campaign</DialogTitle>
                        <DialogContent>
                            <Grid container direction="row" alignItems="stretch" spacing={2}>
                                <Grid item container direction="column" justify="flex-start" spacing={1}>
                                    <Grid container item direction="row">
                                        <Grid item xs={12} >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={toSFTP}
                                                        onChange={handleSFTPChange}
                                                        disabled={!sftpConfigured}
                                                        name="SFTP"
                                                        color="primary"
                                                    />
                                                }
                                                label="Export to SFTP"
                                            />
                                        </Grid>
                                        {!sftpConfigured && <Typography variant="button" color="primary">Export SFTP not <NavLink to="/config/sftp"> configured </NavLink> </Typography>}
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" justify="flex-end" spacing={1}>
                                    <Grid container item direction="row">
                                        <Grid item xs={12} >
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={toS3}
                                                    onChange={handleS3Change}
                                                    name="S3"
                                                    color="primary"
                                                />
                                            }
                                            label="Download Via Secured Link"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary" variant="outlined">Cancel</Button>
                            <Button disabled={!toS3 && !toSFTP} onClick={handleExportClick} color="primary" variant="contained">Export</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </UserContext.Consumer>
    )
}
export default ExportDialog;

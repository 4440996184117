export const availableMeta = [
    {metaData: 'Custom', mediaType: 'phoneCall, chat,  sms, social, workItem'},
    {metaData: 'MasterID', mediaType: 'phoneCall, chat,  sms, social, workItem'},
    {metaData: 'Other', mediaType: 'phoneCall, chat, sms, social, workItem'},
    {metaData: 'ParentID', mediaType: 'phoneCall, chat, sms, social, workItem'},
    {metaData: 'Skill', mediaType: 'phoneCall, chat, sms, social, workItem'},
    {metaData: 'StartTimeUTC', mediaType: 'phoneCall, chat, sms, social, workItem'},
    {metaData: 'Status', mediaType: 'phoneCall, sms, chat, social, workItem'},
    {metaData: 'Type', mediaType: 'phoneCall,  sms, chat, social, workItem'},
    {metaData: 'ANI', mediaType: 'phoneCall'},
    {metaData: 'DNIS', mediaType: 'phoneCall'},
    {metaData: 'Label', mediaType: 'phoneCall'},
    {metaData: 'Direction', mediaType: 'phoneCall'},
    {metaData: 'DisconnectCode', mediaType: 'phoneCall'},
    {metaData: 'EndTimeUTC', mediaType: 'phoneCall'},
    {metaData: 'Display', mediaType: 'phoneCall,'},
    {metaData: 'WorkItemID', mediaType: 'workItem'},
    {metaData: 'WorkItemPayload', mediaType: 'workItem'},
    {metaData: 'WorkItemType', mediaType: 'workItem'},
      
    {metaData: 'IISHost', mediaType: 'email'},
    {metaData: 'VCHost', mediaType: 'email'},
    {metaData: 'ContactID', mediaType: 'email'},
    {metaData: 'Status', mediaType: 'email'},
    {metaData: 'EmailType', mediaType: 'email'},
    {metaData: 'Skill', mediaType: 'email'},
    {metaData: 'IsInBound', mediaType: 'email'},
    {metaData: 'StartTimeUTC', mediaType: 'email'},
    {metaData: 'StartTIme', mediaType: 'email'},
    {metaData: 'LastStateChangeTimeUTC', mediaType: 'email'},
    {metaData: 'LastStateCHangeTIme', mediaType: 'email'},
    {metaData: 'ToAddress', mediaType: 'email'},
    {metaData: 'Label', mediaType: 'email'},
    {metaData: 'POCAddress', mediaType: 'email'},
    {metaData: 'Disposition1', mediaType: 'email'},
    {metaData: 'Disposition2', mediaType: 'email'},
    {metaData: 'Tags', mediaType: 'email'},
    {metaData: 'Note', mediaType: 'email'},
    {metaData: 'CustomData', mediaType: 'email'},
    {metaData: 'RefusalTimeout', mediaType: 'email'},
    {metaData: 'MasterID', mediaType: 'email'},
    {metaData: 'ParentContactId', mediaType: 'email'},
    {metaData: 'OmniGroupId', mediaType: 'email'},
    {metaData: 'InFocus', mediaType: 'email'},
    {metaData: 'FinalState', mediaType: 'email'},
    {metaData: 'SentDate', mediaType: 'email'},
    {metaData: 'FromAddress', mediaType: 'email'},
    {metaData: 'CcAddress', mediaType: 'email'},
    {metaData: 'BccAddress', mediaType: 'email'},
    {metaData: 'Attachments', mediaType: 'email'},
    {metaData: 'IsDraft', mediaType: 'email'},

    {metaData: 'P1', mediaType: 'chat, phoneCall, sms, email, workItem',},
    {metaData: 'P2', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P3', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P4', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P5', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P6', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P7', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P8', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P9', mediaType: 'chat, phoneCall, sms, email, workItem'},
    {metaData: 'P10', mediaType: 'chat, phoneCall, sms, email, workItem'},

];

export const postTransactionMeta = [
    {metaData: 'SkillId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'SkillName', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail , Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'SkipFinalization', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'AgentId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'Direction', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'MasterContactId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'ParentContactId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'PointOfContactId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'PointOfContactName', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'Ani', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'Dnis', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'DurationSeconds', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'DurationMinutes', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'ContactActualEnd', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'ContactActualStart', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'DispositionNotes', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'DispositionPrimaryId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'DispositionSecondaryId', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'PrimaryDisposition2OptionSet', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'SecondaryDisposition2OptionSet', mediaType: 'InboundphoneCall, OutboundphoneCall, Inboundemail, Outboundemail, Inboundchat, Inboundsms, Inboundsocial'},

    {metaData: 'DialerDispostion', mediaType: 'OutboundphoneCall'},
    {metaData: 'DialerAttemptedPhoneNumber', mediaType: 'OutboundphoneCall'},

    {metaData: 'GetTranscript', mediaType: 'Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'NameGiven', mediaType: 'Inboundchat, Inboundsms, Inboundsocial'},
    {metaData: 'EmailGiven', mediaType: 'Inboundchat'},
    {metaData: 'IpAddress', mediaType: 'Inboundchat'},
    {metaData: 'SocialChannel', mediaType: 'Inboundsocial'}
]
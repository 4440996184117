import React, { useState, useContext, useRef, useMemo, useEffect } from 'react';
import {
    TextField, Grid, Button, Switch, FormControlLabel,
    InputLabel, Typography, Paper, Select, FormControl, MenuItem, makeStyles, FormHelperText, CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    LinearProgress
} from '@material-ui/core';
import * as _ from 'lodash';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { Formik } from 'formik';
import * as queries from '../graphql/queries';
import MaterialTable from 'material-table';
import QueryBuilder from '../components/QueryBuilder';
import UserContext from '../context/UserContext';
import { useSnackbar } from 'notistack';
import YupValidations from '../components/YupValidations';
import * as yup from 'yup';
import countryRegionData from 'country-region-data';
import { CenterToggleContainer, CenterToggleItem, CenterToggleChild } from 'react-center-toggle';
import ConfirmDialog from '../components/ConfirmDialog'

const usStates = countryRegionData.find(x => x.countryShortCode === 'US').regions;
const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    },
    valueField: {
        minWidth: '185px'
    }
});

const types = {
    String: 0,
    ISODateTime: 1,
    Boolean: 2,
    Timezone: 3,
    State: 4,
    Epoch: 5,
    DateTime: 5, // The custom field DateTime is an epoch.
    Number: 6,
    Phone: 7
};

const modes = {
    Delete: 0,
    Update: 1
};

export default function BulkEdit(props) {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [total, setTotal] = useState(0);
    const [retrievedCount, setRetrievedCount] = useState(0);
    const newSearchFilter = useRef({});
    const [contacts, setContacts] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [queryFields, setQueryFields] = useState([]);
    const queryPreviewThrottler = useRef(_.throttle((query) => logQuery(query), 1000, { 'leading': false, 'trailing': true }));
    const mode = useRef(modes.Update);

    const progressCountRef = useRef(0);
    const [progressCount, setProgressCount] = useState(0);
    const errorCountRef = useRef(0);
    const [errorCount, setErrorCount] = useState(0);
    const [progressDialogOpen, setProgressDialogOpen] = useState(false);
    const [processDialogTitle, setProcessDialogTitle] = useState('');

    const valueInputTypes = useMemo(() => ({
        firstName: types.String,
        lastName: types.String,
        phone: types.Phone,
        source: types.String,
        cell: types.Phone,
        email: types.String,
        address1: types.String,
        address2: types.String,
        city: types.String,
        state: types.State,
        zip: types.String,
        /* expireDt: types.ISODateTime, */
        outboundCallerId: types.String,
        ...userContext.customFields?.reduce((acc, cur) => Object.assign(acc, { [`custom:${cur.name}`]: types[cur.type] }), {})
    }), [userContext.customFields]);

    useEffect(() => {
        if (userContext.queryFields.list) {
            setQueryFields([...userContext.queryFields.list])
        }
    }, [userContext.queryFields])

    function standardizeValue(values) {
        try {
            let value
            switch (valueInputTypes[values.fieldName]) {
                case types.String:
                case types.Phone:
                    return values.fieldValue;
                case types.ISODateTime:
                    return new Date(values.fieldValue).toISOString();
                case types.Epoch:
                    return new Date(values.fieldValue).getTime();
                case types.Number:
                    return Number(values.fieldValue)
                case types.Boolean:
                    return !!values.fieldName;
                default:
                    return null;
            }

        } catch (err) {
            console.error('Unable to get value', err)
        }

    }

    const logQuery = (query) => {
        if (query.rules) {
            if (query.rules.length > 0) {
                newSearchFilter.current = processFilterArray(query.rules, query.combinator);
                previewContacts();
            } else {
                //If we are missing any rules, clear the data
                setTotal(0);
            }
        }

        return;
    }

    const processFilterArray = (items, combinator) => {
        const ruleArray = [];
        if (userContext.tenant) {
            const ruleGroup = { [combinator]: ruleArray };
            //the rule array always has to have the tenant included for security
            _.forEach(items, (item) => { ruleArray.push(processFilterItem(item, items.combinator)) });
            const tenantRuleGroup = { and: [{ tenant: { eq: userContext.tenant } }, ruleGroup] };
            return tenantRuleGroup;
        } else {
            return {}
        }
    }

    const processFilterItem = (item) => {
        const cpyItem = { ...item };
        if (item.field) {
            //item.value = item.value.toLowerCase();
            if (!item.field.startsWith('custom:')) {
                if (cpyItem.operator === 'wildcard') {
                    cpyItem.value = cpyItem.value.toLowerCase();
                    cpyItem.value = `*${cpyItem.value}*`;
                }
                if (cpyItem.operator === 'wildcard*') {
                    cpyItem.value = cpyItem.value.toLowerCase();
                    cpyItem.value = `${cpyItem.value}*`;
                    cpyItem.operator = 'wildcard';
                }
                if (cpyItem.operator === '*wildcard') {
                    cpyItem.value = cpyItem.value.toLowerCase();
                    cpyItem.value = `*${cpyItem.value}`;
                    cpyItem.operator = 'wildcard';
                }
                const ruleItem = { [cpyItem.field]: { [cpyItem.operator]: cpyItem.value } };
                return ruleItem;
            } else {
                let fieldName = cpyItem.field.split(':')[1];
                if (cpyItem.operator === 'wildcard') {
                    cpyItem.value = `*${cpyItem.value}*`;
                    fieldName += '.keyword';
                } else if (cpyItem.operator === 'wildcard*') {
                    cpyItem.value = `${cpyItem.value}*`;
                    cpyItem.operator = 'wildcard';
                    fieldName += '.keyword';
                } else if (cpyItem.operator === '*wildcard') {
                    cpyItem.value = `*${cpyItem.value}`;
                    cpyItem.operator = 'wildcard';
                    fieldName += '.keyword';
                } else {
                    if (cpyItem.value === true || cpyItem.value === false || cpyItem.value === 'true' || cpyItem === 'false') {
                        if (_.isString(cpyItem.value)) {
                            cpyItem.value = cpyItem.value === 'true';
                        } else {
                            cpyItem.value = cpyItem.value === true;
                        }
                    } else if (!isNaN(cpyItem.value)) {
                        cpyItem.value = +cpyItem.value;
                    } else if (isCustomFieldDate(fieldName) && !!Date.parse(cpyItem.value)) {
                        cpyItem.value = Date.parse(cpyItem.value);
                    }
                }
                const ruleItem = { [`customFields.${fieldName}`]: { [cpyItem.operator]: cpyItem.value } };
                return ruleItem;
            }
        }

        return processFilterArray(cpyItem.rules, cpyItem.combinator);
    }

    const isCustomFieldDate = (fieldName) => {
        const customField = _.find(userContext.customFields, ['name', fieldName]);
        if (customField) {
            return customField.type === 'Date';
        } else {
            return false;
        }
    }

    const previewContacts = async () => {
        try {
            let options = {
                limit: 250
            };
            options.filter = JSON.stringify(newSearchFilter.current);

            const listData = await API.graphql(graphqlOperation(queries.expandedContactSearch, options));
            // We need to parse the custom fields out and NOT stringify them when we send them back out
            // because when these contacts are put back into DynamoDB, it is done through a DocumentClient.
            // The DocumentClient takes AWSJSON as is (not stringified). 
            for (const contact of listData.data.expandedContactSearch.items) {
                if (contact.customFields != null && typeof (contact.customFields) === 'string') {
                    try {
                        contact.customFields = JSON.parse(contact.customFields);
                    } catch (_) { }
                }
            }

            const contacts = listData.data.expandedContactSearch.items;

            setRetrievedCount(contacts.length ? contacts.length : 0);
            setTotal(listData.data.expandedContactSearch.total ? listData.data.expandedContactSearch.total : 0);

            setContacts([...contacts]);
        } catch (error) {
            console.log(error);
        }
    }

    function handleTypeChange(formikProps, e) {
        switch (valueInputTypes[e.target.value]) {
            default:
            case types.String:
            case types.ISODateTime:
            case types.DateTime:
            case types.Number:
            case types.Phone:
            case types.State:
            case types.Timezone:
                formikProps.setFieldValue('fieldValue', '');
                break;
            case types.Boolean:
                formikProps.setFieldValue('fieldValue', false);
                break;
        }

        formikProps.handleChange(e);
    }

    function customFieldUpdate(contact, field, value) {
        if (!('customFields' in contact)) {
            contact.customFields = {};
        }

        contact.customFields[field] = value;
    }

    function regularUpdate(contact, field, value) {
        contact[field] = value;
    }

    async function processContacts(values) {
        
        progressCountRef.current = 0;
        errorCountRef.current = 0;
        setProgressCount(0);
        setErrorCount(0);

        setConfirmOpen(false);
        // Retrieve each page of contacts and update them.
        if (mode.current === modes.Update) {
            setProcessDialogTitle('Updating Contacts...');
        } else {
            setProcessDialogTitle('Deleting Contacts...');
        }
        setProgressDialogOpen(true);
        let options = {
            limit: 500
        };
        let nextToken = null;

        options.filter = JSON.stringify(newSearchFilter.current);
        do {
            if (nextToken) {
                options.nextToken = nextToken;
            }

            try {

                let listData = await API.graphql(graphqlOperation(queries.expandedContactSearch, options));
                // We need to parse the custom fields out and NOT stringify them when we send them back out
                // because when these contacts are put back into DynamoDB, it is done through a DocumentClient.
                // The DocumentClient takes AWSJSON as is (not stringified).
                for (const contact of listData.data.expandedContactSearch.items) {
                    if (contact.customFields != null && typeof (contact.customFields) === 'string') {
                        try {
                            contact.customFields = JSON.parse(contact.customFields);
                        } catch (_) { }
                    }
                }

                const retrievedContacts = listData.data.expandedContactSearch.items;

                nextToken = listData.data.expandedContactSearch.nextToken;

                const chunkedContacts = _.chunk(retrievedContacts, 100);

                const promises = [];
                for (const chunk of chunkedContacts) {
                    promises.push(processBulkEdit(values, chunk));
                }

                await Promise.all(promises);

                progressCountRef.current += retrievedContacts.length;
                setProgressCount(progressCountRef.current);

            } catch (error) {
                const retrievedContacts = error.data.expandedContactSearch.items;
                const cleanedContacts = retrievedContacts.filter(contact => contact?.id);

                nextToken = error.data.expandedContactSearch.nextToken;

                const chunkedContacts = _.chunk(cleanedContacts, 100);
                const promises = [];
                for (const chunk of chunkedContacts) {
                    promises.push(processBulkEdit(values, chunk));
                }

                await Promise.all(promises);

                errorCountRef.current += retrievedContacts.length - cleanedContacts.length;

                progressCountRef.current += retrievedContacts.length;
                setProgressCount(progressCountRef.current);
                setErrorCount(errorCountRef.current);
            } 
            
        }while (nextToken);

            

            setProgressDialogOpen(false);
            enqueueSnackbar(`Successful Contacts: ${progressCountRef.current} | Error Contacts: ${errorCountRef.current}`, {
                variant: 'success',
                autoHideDuration: 5000
            });

            setContacts([]);
            progressCountRef.current = 0;
            setTotal(0);
            setRetrievedCount(0);
        }

    async function processBulkEdit(values, contacts) {
                if (mode.current === modes.Update) {
                    const value = standardizeValue(values);
                    // for (const chunk of chunkedContacts) {
                    try {
                        const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

                        const response = await API.post(
                            'cdyxdialer',
                            '/bulk-edit/edit',
                            {
                                headers: {
                                    Authorization: `Bearer ${jwtToken}`,
                                    'x-api-key': userContext.apiKey
                                },
                                body: {
                                    contacts,
                                    updateField: values.fieldName,
                                    updateValue: value,
                                    updatedBy: (await Auth.currentSession()).getIdToken().payload.name
                                }
                            }
                        );
                        console.log(response);
                    } catch (err) {
                        enqueueSnackbar('Unable to update contacts', {
                            variant: 'error',
                            autoHideDuration: 5000
                        });
                    }
                    // }
                } else {
                    try {
                        const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                        const response = await API.del(
                            'cdyxdialer',
                            '/bulk-edit/delete',
                            {
                                headers: {
                                    Authorization: `Bearer ${jwtToken}`,
                                    'x-api-key': userContext.apiKey
                                },
                                body: {
                                    contacts,
                                    deletedBy: (await Auth.currentSession()).getIdToken().payload.name
                                }
                            }
                        );
                        console.log(response);
                    } catch (err) {
                        enqueueSnackbar('Unable to delete contacts', {
                            variant: 'error',
                            autoHideDuration: 5000
                        });
                    }
                }

            }

    const handleCancelUpdate = () => {
            setConfirmOpen(false);
        }

        return (
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Formik
                            initialValues={{
                                fieldName: '',
                                fieldValue: '',
                                query: {
                                    rules: [],
                                    combinator: 'and'
                                }
                            }}
                            enableReinitialize={true}
                            validationSchema={yup.lazy(value => {
                                let subSchema = yup.mixed();

                                try {
                                    subSchema = typeof (value.fieldName) === 'string' ? yup.reach(YupValidations.contactValidation(userContext.customFields), value.fieldName.startsWith('custom:') ? `customFields.${value.fieldName.substring(7)}` : value.fieldName) : yup.mixed();
                                } catch (_) { }

                                if (mode.current === modes.Delete) {
                                    return yup.object({
                                        query: yup.object({
                                            rules: yup.array().min(1, 'You must have at least one rule in your query')
                                        })
                                    })
                                }

                                return yup.object({
                                    fieldName: yup.string().required('Please specify a field name'),
                                    fieldValue: subSchema,
                                    query: yup.object({
                                        rules: yup.array().min(1, 'You must have at least one rule in your query')
                                    })
                                })
                            })}
                            onSubmit={values => {
                                setConfirmOpen(true);
                            }}
                        >
                            {formikProps => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="h4">Bulk Edit</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Button onClick={() => { mode.current = modes.Delete; formikProps.submitForm(); }} color="primary" variant="outlined" disabled={loading}>Delete</Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button onClick={() => { mode.current = modes.Update; formikProps.submitForm(); }} color="primary" variant="contained" disabled={loading}>Update</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paper} elevation={2}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <Grid container direction='column' spacing={1}>
                                                            <Grid item>
                                                                <Typography variant="h6">Query Builder</Typography>
                                                            </Grid>
                                                            {formikProps.errors?.query?.rules && formikProps.touched?.query?.rules &&
                                                                <Typography variant='body2' color='error'>{formikProps.errors.query.rules}</Typography>}
                                                            <Grid item>
                                                                <QueryBuilder
                                                                    name='query'
                                                                    query={formikProps.values.query}
                                                                    fields={userContext.queryFields.list}
                                                                    onQueryChange={e => {
                                                                        formikProps.setFieldValue('query', e);
                                                                        queryPreviewThrottler.current(e);
                                                                    }}
                                                                    showCombinatorsBetweenRules
                                                                    getValueEditorType={field => userContext.queryFields.typeLookup(field)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" spacing={1}>
                                                            <Grid item>
                                                                <Typography variant="h6">Update</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container spacing={4}>
                                                                    <Grid item>
                                                                        <FormControl className={classes.formControl}>
                                                                            <InputLabel error={formikProps.errors.fieldName && formikProps.touched.fieldName}>Field Name</InputLabel>
                                                                            <Select
                                                                                error={formikProps.errors.fieldName && formikProps.touched.fieldName}
                                                                                name="fieldName"
                                                                                value={formikProps.values.fieldName}
                                                                                onChange={e => handleTypeChange(formikProps, e)}
                                                                                onBlur={formikProps.handleBlur}>
                                                                                {queryFields.map((field, index) =>
                                                                                    <MenuItem key={index} value={field.name}>{field.label}</MenuItem>)}
                                                                            </Select>
                                                                            {formikProps.errors.fieldName && formikProps.touched.fieldName &&
                                                                                <FormHelperText error>{formikProps.errors.fieldName}</FormHelperText>}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {function () {
                                                                            switch (valueInputTypes[formikProps.values.fieldName]) {
                                                                                case types.String:
                                                                                case types.ISODateTime:
                                                                                case types.DateTime:
                                                                                case types.Number:
                                                                                case types.Phone:
                                                                                    return (
                                                                                        <TextField
                                                                                            label='Field Value'
                                                                                            name='fieldValue'
                                                                                            type={valueInputTypes[formikProps.values.fieldName] === types.String || valueInputTypes[formikProps.values.fieldName] === types.Phone
                                                                                                ? 'text'
                                                                                                : valueInputTypes[formikProps.values.fieldName] === types.ISODateTime || valueInputTypes[formikProps.values.fieldName] === types.DateTime
                                                                                                    ? 'datetime-local'
                                                                                                    : 'number'}
                                                                                            value={formikProps.values.fieldValue}
                                                                                            onChange={formikProps.handleChange}
                                                                                            onBlur={formikProps.handleBlur}
                                                                                            error={formikProps.errors?.fieldValue && formikProps.touched?.fieldValue}
                                                                                            helperText={formikProps.touched?.fieldValue && formikProps.errors?.fieldValue}
                                                                                            className={classes.valueField}
                                                                                        />
                                                                                    );
                                                                                case types.State:
                                                                                    return (
                                                                                        <FormControl className={classes.valueField}>
                                                                                            <InputLabel error={formikProps.errors?.fieldValue && formikProps.touched?.fieldValue}>Field Value</InputLabel>
                                                                                            <Select name='fieldValue' onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={formikProps.values.fieldValue}>
                                                                                                {usStates.map(state =>
                                                                                                    <MenuItem key={state.shortCode} value={state.shortCode}>{state.name}</MenuItem>)}
                                                                                            </Select>
                                                                                            {formikProps.errors?.fieldValue && formikProps.touched?.fieldValue &&
                                                                                                <FormHelperText error>{formikProps.errors.fieldValue}</FormHelperText>}
                                                                                        </FormControl>
                                                                                    );
                                                                                case types.Boolean:
                                                                                    return (
                                                                                        <FormControlLabel label='Field Value' control={
                                                                                            <Switch name='fieldValue' checked={formikProps.values.fieldValue} onChange={formikProps.handleChange} />
                                                                                        } />
                                                                                    );
                                                                                default:
                                                                                    return null;
                                                                            }
                                                                        }()}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <MaterialTable title={`Previewing ${retrievedCount} of ${total} matched contacts.`}
                                                columns={[
                                                    { title: 'First Name', field: 'firstName' },
                                                    { title: 'Last Name', field: 'lastName' },
                                                    { title: 'Source', field: 'source' },
                                                    { title: 'Phone', field: 'phone' },
                                                    { title: 'Cell', field: 'cell' },
                                                    { title: 'Email', field: 'email' },
                                                    { title: 'State', field: 'state' },
                                                ]}
                                                data={contacts}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    pageSize: 10
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {mode.current === modes.Update &&
                                        <ConfirmDialog
                                            open={confirmOpen}
                                            value={formikProps.values}
                                            onConfirm={processContacts}
                                            onCancel={handleCancelUpdate}
                                            confirmTxt="Update">
                                            <Typography>Are you sure you want to update these contacts?</Typography>
                                        </ConfirmDialog>}
                                    {mode.current === modes.Delete &&
                                        <ConfirmDialog
                                            open={confirmOpen}
                                            value={formikProps.values}
                                            onConfirm={processContacts}
                                            onCancel={handleCancelUpdate}
                                            confirmTxt="Delete">
                                            <Typography>Are you sure you want to delete these contacts?</Typography>
                                        </ConfirmDialog>}
                                    <Dialog open={progressDialogOpen}>
                                        <DialogTitle>{processDialogTitle}</DialogTitle>
                                        <DialogContent>
                                            <Typography variant='subtitle2' >Processed {progressCount} of {total} contacts...</Typography>
                                            <LinearProgress variant='determinate' value={progressCount / total * 100} />
                                        </DialogContent>
                                    </Dialog>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>

            </>
        )
    }
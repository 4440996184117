import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem,
    IconButton,
    Paper,
    List,
    ListSubheader,
    Divider,
    Typography,
    TextField,
    ListItem,
    ListItemText,
    Tooltip,
    ListItemSecondaryAction } from '@material-ui/core';
import { Container } from "react-smooth-dnd";
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import * as _ from 'lodash';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        minheight: '300px'
    },
    listHeader: {
        fontWeight: 'bold',
        color: 'black'
    }
}));

export default function MasterLogging(props) {
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const fields = [
        {option: 'To'},
        {option: 'From'},
        {option: 'Regarding'}
    ];
    const metaOptions = [
        {option: 'Matched Record'},
        {option: 'Blank'}
    ]
    const [masterMeta, setMasterMeta] = useState([]);
    const [selMeta, setSelMeta] = useState('');
    const [unusedFields, setUnusedFields] = useState([]);
    const [selFields, setSelFields] = useState();
    const [masterLoggings, setMasterLoggings] = useState([]);
    const [hideMapping, setHideMapping] = useState(false);
    const [hideSubject, setHideSubject] = useState(false);
    const [subject, setSubject] = useState('');

    useEffect(() => {
        async function getData(){
            let tempFields = fields;
            if (props.activityType === 'Inbound'){
                _.remove(tempFields, ['option', 'To']);
            } else if (props.activityType === 'Outbound'){
                _.remove(tempFields, ['option', 'From']);
            }
            setMasterMeta(metaOptions);
            const tempMasterLoggings = props.masterLoggings;
            _.remove(tempMasterLoggings, ['field', 'cdx_incontactid']);
            setMasterLoggings(tempMasterLoggings);
            for (const field of props.masterLoggings){
                _.remove(tempFields, ['option', field.field])
                if (field.field === 'Subject'){
                    setHideSubject(true);
                }
            }
            setUnusedFields(tempFields);
        }
        if (userContext.tenant){
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    useEffect(() => {
        if (unusedFields.length < 1){
            setHideMapping(true);
        }
        if (unusedFields.length >= 1 ){
            setHideMapping(false);
        }
    }, [unusedFields]);

    const handleSelectedMeta = (event) => {
        const metaData = event.target.value;
        setSelMeta(metaData);
    }

    const handleSelectedField = (event) => {
        const fields = event.target.value;
        setSelFields(fields);
    }

    const handleSubjectChange = (event) => {
        const subject = event.target.value;
        setSubject(subject);
    }

    const addLogging = () => {
        if (selMeta !== null && selMeta !== '' && selFields !== null && selFields !== []){
            const logging = {'entity': selMeta, 'field': selFields};
            masterLoggings.push(logging);
            _.remove(unusedFields, ['option', selFields]);
            setSelMeta('');
            setSelFields([]);
            props.setMasterLoggings(masterLoggings);
            if (unusedFields.length < 1){
                setHideMapping(true);
            }
        }
    }

    const addSubject = () => {
        if (subject !== null && subject !== ''){
            const logging = {entity: subject, field: 'Subject'};
            masterLoggings.push(logging);
            setHideSubject(true);
            setSubject('');
            props.setMasterLoggings(masterLoggings);
        }
    }

    const deleteLogging = (data) => async () => {
        console.log(data);
        if (data.field !== 'Subject'){
            const deleteObject = {'option': data.field};
            unusedFields.push(deleteObject);
        } else if(data.field === 'Subject') {
            setHideSubject(false);
        }
        let tempArray = masterLoggings;
        _.remove(tempArray, ['field', data.field]);
        setMasterLoggings([...tempArray]);
        if (unusedFields.length >= 1 ){
            setHideMapping(false);
        }
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        { !hideMapping && <Grid item container direction="row" alignItems="flex-end" spacing={4}>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="field-label">Fields</InputLabel>
                                    <Select
                                        labelId="field"
                                        value={selFields}
                                        onChange={handleSelectedField}
                                        className={classes.selectFields}
                                    >                             
                                        {unusedFields.map(data =>(
                                            <MenuItem key={data.option} value={data.option}>{data.option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Typography> maps to </Typography>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="metaData">Meta Data</InputLabel>
                                    <Select
                                        labelId="metaData"
                                        name="metaData"
                                        value={selMeta}
                                        displayEmpty
                                        className={classes.selectFields}
                                        onChange={handleSelectedMeta}
                                    >
                                        {masterMeta.map(data =>(
                                            <MenuItem value={data.option}>{data.option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="add mapping" onClick={addLogging}>
                                    <AddIcon color="primary" fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>}
                        { !hideSubject && <Grid item container direction="row" alignItems="center" spacing={4}>
                            <Grid item>
                                    <Typography>Subject maps to</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                margin='dense'
                                required={true}
                                onChange={handleSubjectChange}
                                value={subject}
                                label="Subject Line" variant="outlined" />
                            </Grid>
                            <Grid item>
                                <div>
                                    <IconButton aria-label="add mapping" onClick={addSubject}>
                                        <AddIcon color="primary" fontSize="small" />
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    placement="top-start"
                                    leaveDelay={500}
                                    title={
                                        <React.Fragment>
                                            <Typography variant="body1">
                                                <strong>Adding Meta Data to Subject Line</strong>
                                                <p>You have the ability to add activity meta data to your subject by using double brackets [[]].</p>
                                                <p>Example: phoneCall from [[ANI]]</p>
                                            </Typography>
                                        </React.Fragment>
                                    }>
                                    <IconButton size="small"><HelpOutlineOutlined color="primary" /></IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>}
                        <Grid item> 
                            <Paper>
                                <Grid container item direction="row">
                                    <Grid item xs={12} >
                                        <List dense={true}>
                                            <ListSubheader className={classes.listHeader}>Logging Data</ListSubheader>
                                            <Divider />
                                            <ListSubheader color="primary">Master Loggings</ListSubheader>
                                            <Divider />
                                            <Container>
                                               <ListItem >
                                                    <ListItemText primary={
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                              <Typography>
                                                                Meta Data
                                                              </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                              <Typography>
                                                                Field (CRM)
                                                              </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    } />
                                                    <ListItemSecondaryAction>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                {masterLoggings.map(data => (
                                                    <ListItem >
                                                        <ListItemText primary={
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    {data.entity}
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    {data.field}
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                        <ListItemSecondaryAction>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid item>
                                                                    <IconButton aria-label="delete" onClick={deleteLogging(data)}>
                                                                        <DeleteIcon color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                 ))}
                                                    <ListItem >
                                                            <ListItemText primary={
                                                                <Grid container>
                                                                    <Grid item xs={2}>
                                                                        ContactId
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        InContactId
                                                                    </Grid>
                                                                </Grid>
                                                            } />
                                                            <ListItemSecondaryAction>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <Grid item>
                                                                    </Grid>
                                                                </Grid>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                            </Container>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
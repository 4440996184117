/**
 * This file is a component for E2 UI Customization
 */
 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import ReplyAllOutlinedIcon from '@material-ui/icons/ReplyAllOutlined';

 export default function EmailReplyAll(props)
 {
     const [replyAll, setReplyAll] = useState(false);
 
     /** Set live chat to value being passed through props */
     useEffect(() => {
         setReplyAll(props.value);
     },[props.value]);
     
     /**Handle changes to live chat checkbox */
     const handleReplyAll = () => {
         setReplyAll(!replyAll);
         props.onChange(!replyAll);
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={replyAll}
                         onChange={handleReplyAll}
                         name="replyAll"
                         color="primary"
                         />
                     }
                     label="Reply All"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Toggles the button that allows the agent to reply to everyone in the thread.">
                         <ReplyAllOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
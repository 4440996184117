import React, { useState } from 'react';
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Link} from '@material-ui/core';
import RefundHistoryDialog from './RefundHistoryDialog';

/** This file opens up a dialog box in C3Info to display all the information for a transaction */
export default function PreviewTransactionDialog(props){

    const refund = props.transaction.refunds;
    const [refundHistoryDialog, setRefundHistoryDialog] = useState();

    /** Close dialog when button is clicked */
    function closeDialog() {
        if (props.close) {
            props.close();
        }
    }

    /**Arrow functions to show and close refund dialog */
    const launchRefundHistoryDialog = () => {
        setRefundHistoryDialog(true);
    }
    const closeRefundHistoryDialog = () => {
        setRefundHistoryDialog(false);
    }

    return(
        <div>
            <Dialog maxWidth="xs" open={props.open}>
                <DialogTitle id="dp-dialog-title">Transaction Information</DialogTitle>
                <DialogContent>
                    <Typography><b>Agent ID: </b>{props.transaction.agentId}</Typography>
                    <Typography><b>Aging Info: </b>{props.transaction.agingInfo}</Typography>
                    <Typography><b>Card Holder: </b>{props.transaction.cardHolder}</Typography>
                    <Typography><b>Card Type: </b>{props.transaction.cardType}</Typography>
                    <Typography><b>Creation Date: </b>{props.transaction.createdAt}</Typography>
                    <Typography><b>Transaction ID: </b>{props.transaction.id}</Typography>
                    <Typography><b>Payment ID: </b>{props.transaction.paymentId}</Typography> 
                    {refund.items.length > 0 &&
                    <Typography><b>Refund Info: </b><Link component="button" onClick={launchRefundHistoryDialog}>View Info</Link></Typography>
                    }
                    {refundHistoryDialog &&
                    <RefundHistoryDialog open={refundHistoryDialog} close={closeRefundHistoryDialog} refunds={refund}/>
                    }
                    <Typography><b>Subject ID: </b>{props.transaction.subjectId}</Typography>
                    <Typography><b>Subject Name: </b>{props.transaction.subjectName}</Typography>
                    <Typography><b>Transaction Amount: </b>{props.transaction.transactionAmount}</Typography>
                    <Typography><b>Transaction Date: </b>{props.transaction.transactionDate}</Typography>
                    <Typography><b>Transaction Status: </b>{props.transaction.transactionStatus}</Typography>
                    <Typography><b>Transaction Type: </b>{props.transaction.transactionType}</Typography>
                    <Typography><b>Updated Date: </b>{props.transaction.updatedAt}</Typography>
                    <Typography><b>Vendor ID: </b>{props.transaction.vendorId}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React, { useState, useContext, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import {
    Grid, TextField, makeStyles, FormControl, Select, MenuItem,
    Divider, FormControlLabel, Switch, InputLabel,
    CircularProgress, Paper,
    Card,
    CardHeader,
    CardContent,
    FormHelperText,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import { MessageOutlined } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik } from 'formik';
import { graphqlOperation, API, Auth } from 'aws-amplify';
import { getMobileConfiguration } from '../graphql/queries';
import UserContext from '../context/UserContext';
import { createMobileConfiguration, updateMobileConfiguration } from '../graphql/mutations';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { CenterToggleContainer, CenterToggleItem, CenterToggleChild } from 'react-center-toggle';
import { useHistory, useParams } from 'react-router-dom';
import YupValidations from '../components/YupValidations';


Yup.addMethod(Yup.array, 'containsRequiredFields', function (message, mapper = a => a) {
    return this.test('containsRequiredFields', message, function (list) {
        const toFields = list.map(mapper);
        const requiredMappings = ['firstName', 'lastName', 'phone'];
        let missingFields = []
        for (let requisite of requiredMappings) {
            if (requisite === 'phone') {
                if (!toFields.includes(requisite) && !toFields.includes('cell')) missingFields.push(requisite)
            } else {
                if (!toFields.includes(requisite)) missingFields.push(requisite)
            }
        }
        if (missingFields.length > 0) return this.createError({ path: `mapping[0].from`, message: message })
        return true
    });
});

const useStyles = makeStyles(theme => ({
    endpoint: {
        width: '100%'
    },
    shortAuthField: {
        minWidth: '100px'
    },
    longField: {
        minWidth: '400px',
    },
    formError: {
        color: theme.palette.error.main
    },
    tableCell: {
        padding: '0px 24px 4px 16px',
        border: 'none'
    },
    paper: {
        padding: theme.spacing(2),
    }
}));


export default function MobileConfiguration() {
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const userContext = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editAndroid, setEditAndroid] = useState(false);
    const [editIos, setEditIos] = useState(false);
    const isNew = useRef(false);

    const [configObj, setConfigObj] = useState({
        name: '',
    });

    useEffect(() => {
        async function getData() {
            setLoading(true);

            const projectSettings = await API.graphql(graphqlOperation(getMobileConfiguration, { id }))
            if (projectSettings.data.getMobileConfiguration) setConfigObj(projectSettings.data.getMobileConfiguration);
            setLoading(false);
        }
        if (id !== 'new') {
            if (!isNew.current) {
                getData();
            } else {
                isNew.current = false;
            }
        } else {
            isNew.current = true;
        }
    }, [id])

    async function createProject(name) {
        try {
            const response = await API.post(
                'cdyxdialer',
                '/project/create',
                {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey
                    },
                    body: {
                        name
                    }
                }
            )

            return response.projectId;
        } catch (err) {
            console.error(err);
        }
    }

    async function updateChannel(request) {
        try {
            const response = await API.post(
                'cdyxdialer',
                '/project/updateChannel',
                {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey
                    },
                    body: request
                }
            )

            return response;
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Formik
            validationSchema={Yup.object({
                name: Yup.string().required("A name is required").matches(YupValidations.atLeastOneLettersNumbersSpacesRegex),
            })}
            initialValues={configObj}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async values => {
                const updateAndroid = (configObj.androidEnabled !== values.androidEnabled) || values.androidApiKey
                const updateIos = (configObj.iosEnabled !== values.iosEnabled) || values.certificate || values.privateKey

                let projectId;
                if (id === 'new') {
                    projectId = await createProject(values.name);
                    if (!projectId) {
                        enqueueSnackbar("Project could not be created", { variant: 'error' });
                        return;
                    }
                }

                setSaving(true);

                const clone = _.cloneDeep(values);

                delete clone.createdAt;
                delete clone.updatedAt;

                clone.tenant = userContext.tenant;
                clone.projectId = clone.projectId ?? projectId;
                clone.androidEnabled = clone.androidEnabled ?? false;
                clone.iosEnabled = clone.iosEnabled ?? false;

                if (updateAndroid) {
                    const updateResult = await updateChannel({
                        channel: 'android',
                        projectId: clone.projectId,
                        apiKey: clone.androidApiKey,
                        enabled: clone.androidEnabled
                    });
                    if (!updateResult) {
                        enqueueSnackbar("Android settings could not be updated", { variant: 'error' });
                        setSaving(false);
                        return;
                    }
                    delete clone.androidApiKey;
                }

                if (updateIos) {
                    const updateResult = await updateChannel({
                        channel: 'ios',
                        projectId: clone.projectId,
                        certificate: clone.certificate,
                        privateKey: clone.privateKey,
                        enabled: clone.iosEnabled
                    });
                    if (!updateResult) {
                        enqueueSnackbar("iOS settings could not be updated", { variant: 'error' });
                        setSaving(false);
                        return;
                    }
                    delete clone.certificate;
                    delete clone.privateKey;
                }

                try {
                    if (id === "new") {
                        const result = await API.graphql(graphqlOperation(createMobileConfiguration, { input: clone }));
                        setConfigObj(result.data.createMobileConfiguration);
                        history.push(`/config/mobile-config/${result.data.createMobileConfiguration.id}`);
                    } else {
                        await API.graphql(graphqlOperation(updateMobileConfiguration, { input: clone }));
                    }
                    enqueueSnackbar("Project saved successfully", { variant: 'success' });
                } catch (err) {
                    console.log(err);
                    enqueueSnackbar("Project could not be saved", { variant: 'error' });
                }

                setSaving(false);
            }}
        >
            {formikProps => {
                return (
                    <>
                        {loading &&
                            <CircularProgress variant='indeterminate' color='primary' />
                        }
                        {!loading &&
                            <form onSubmit={formikProps.handleSubmit}>
                                <Grid container direction='column' spacing={2}>
                                    <Grid item>
                                        <Grid container justifyContent='space-between' alignItems='center' alignContent='center'>
                                            <Grid item>
                                                <Typography variant='h4'>{id === 'new' ? 'New Application' : 'Edit Application'}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Button color='primary' variant='outlined' onClick={() => history.push('/config/mobile-config')}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <CenterToggleContainer toggled={saving}>
                                                            <CenterToggleChild>
                                                                <Button color='primary' variant='contained' type='submit' disabled={saving}>
                                                                    Save
                                                                </Button>
                                                            </CenterToggleChild>
                                                            <CenterToggleItem>
                                                                <CircularProgress variant='indeterminate' color='primary' />
                                                            </CenterToggleItem>
                                                        </CenterToggleContainer>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Paper className={classes.paper} elevation={4}>
                                            <Grid container spacing={2} direction='column'>
                                                <Grid item>
                                                    <TextField
                                                        variant='standard'
                                                        color='primary'
                                                        name='name'
                                                        label='Project Name'
                                                        disabled={id !== 'new'}
                                                        style={{ minWidth: '400px' }}
                                                        value={formikProps.values.name}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors?.name && formikProps.touched?.name}
                                                        helperText={formikProps.touched?.name && formikProps.errors?.name} />

                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant='standard'
                                                        color='primary'
                                                        name='description'
                                                        label='Description'
                                                        multiline
                                                        style={{ minWidth: '400px' }}
                                                        value={formikProps.values.description}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors?.description && formikProps.touched?.description}
                                                        helperText={formikProps.touched?.description && formikProps.errors?.description} />

                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>


                                <Card variant="outlined" style={{ marginTop: "16px", display: `${id === 'new' ? 'none' : 'block'}` }}>
                                    <CardHeader
                                        title="Channels"
                                        avatar={<MessageOutlined color="primary" />}
                                        titleTypographyProps={{
                                            variant: "h6",
                                            style: { marginBottom: "7px" },
                                        }}
                                    />
                                    <CardContent>
                                        <Grid container spacing={5} direction="column">
                                            <Grid item>
                                                <Grid container spacing={1} direction="column">
                                                    <Grid item>
                                                        <FormControlLabel
                                                            label="Android"
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={
                                                                        formikProps.values
                                                                            ?.androidEnabled ?? false
                                                                    }
                                                                    onChange={formikProps.handleChange}
                                                                    name="androidEnabled"
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Divider />
                                                    {Boolean(
                                                        formikProps.values?.androidEnabled
                                                    ) && (
                                                            <Grid item>

                                                                <Accordion defaultExpanded={configObj.androidEnabled !== formikProps.values.androidEnabled} onChange={(event, expanded) => {setEditAndroid(expanded)}}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        id="editAndroid"
                                                                    >
                                                                        <Typography color="primary" className={classes.heading}>Update Android settings</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Grid
                                                                            container
                                                                            spacing={2}
                                                                        >
                                                                            <Grid item>
                                                                                <FormControl className={classes.formControl}>
                                                                                    <TextField
                                                                                        name="androidApiKey"
                                                                                        label="Android API Key"
                                                                                        multiline
                                                                                        maxRows={15}
                                                                                        required={
                                                                                            formikProps.values.androidEnabled && (configObj.androidEnabled !== formikProps.values.androidEnabled || editAndroid)
                                                                                        }
                                                                                        className={
                                                                                            classes.longField
                                                                                        }
                                                                                        value={
                                                                                            formikProps.values?.androidApiKey ?? ""
                                                                                        }
                                                                                        onBlur={
                                                                                            formikProps.handleBlur
                                                                                        }
                                                                                        onChange={formikProps.handleChange}
                                                                                        error={
                                                                                            formikProps.errors?.androidApiKey &&
                                                                                            formikProps.touched?.androidApiKey
                                                                                        }
                                                                                        helperText={
                                                                                            formikProps.touched?.androidApiKey ? formikProps.errors?.androidApiKey : ""
                                                                                        }
                                                                                    >

                                                                                    </TextField>
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1} direction="column">
                                                    <Grid item>
                                                        <FormControlLabel
                                                            label="iOS"
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={
                                                                        formikProps.values?.iosEnabled ?? false
                                                                    }
                                                                    onChange={formikProps.handleChange}
                                                                    name="iosEnabled"
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Divider />
                                                    {Boolean(
                                                        formikProps.values?.iosEnabled
                                                    ) && (
                                                            <Grid item>
                                                                <Accordion defaultExpanded={configObj.iosEnabled !== formikProps.values.iosEnabled} onChange={(event, expanded) => {setEditIos(expanded)}}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        id="editIos"
                                                                    >
                                                                        <Typography color="primary" className={classes.heading}>Update iOS settings</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Grid container spacing={2} >

                                                                            <Grid item>
                                                                                <FormControl className={classes.formControl}>
                                                                                    <TextField
                                                                                        name="certificate"
                                                                                        label="Certificate"
                                                                                        multiline
                                                                                        maxRows={15}
                                                                                        required={
                                                                                            formikProps.values.iosEnabled && (configObj.iosEnabled !== formikProps.values.iosEnabled || editIos)
                                                                                        }
                                                                                        className={
                                                                                            classes.longField
                                                                                        }
                                                                                        value={
                                                                                            formikProps.values?.certificate ?? ""
                                                                                        }
                                                                                        onBlur={
                                                                                            formikProps.handleBlur
                                                                                        }
                                                                                        onChange={
                                                                                            formikProps.handleChange
                                                                                        }
                                                                                        error={
                                                                                            formikProps.errors?.certificate &&
                                                                                            formikProps.touched?.certificate
                                                                                        }
                                                                                        helperText={
                                                                                            formikProps.touched?.certificate ? formikProps.errors?.certificate : ""
                                                                                        }
                                                                                    >

                                                                                    </TextField>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <FormControl className={classes.formControl}>
                                                                                    <TextField
                                                                                        name="privateKey"
                                                                                        label="Private Key"
                                                                                        multiline
                                                                                        maxRows={15}
                                                                                        required={
                                                                                            formikProps.values.iosEnabled && (configObj.iosEnabled !== formikProps.values.iosEnabled || editIos)
                                                                                        }
                                                                                        className={
                                                                                            classes.longField
                                                                                        }
                                                                                        value={
                                                                                            formikProps.values?.privateKey ?? ""
                                                                                        }
                                                                                        onBlur={
                                                                                            formikProps.handleBlur
                                                                                        }
                                                                                        onChange={
                                                                                            formikProps.handleChange
                                                                                        }
                                                                                        error={
                                                                                            formikProps.errors?.privateKey &&
                                                                                            formikProps.touched?.privateKey
                                                                                        }
                                                                                        helperText={
                                                                                            formikProps.touched?.privateKey ? formikProps.errors?.privateKey : ""
                                                                                        }
                                                                                    >

                                                                                    </TextField>
                                                                                </FormControl>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>

                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>


                            </form>
                        }
                    </>
                )
            }}
        </Formik>
    )
}

import React , {useContext, useEffect, useState} from 'react';
import {Grid, TablePagination, Typography} from '@material-ui/core';
import MaterialTable from 'material-table';
import { API, Auth } from 'aws-amplify';
import UserContext from '../context/UserContext';
import CreateTenant from '../admin/CreateTenant'
import EditIcon from '@material-ui/icons/EditOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ConfirmDialog from '../components/ConfirmDialog';


export default function ListTenants(){
    const userContext = useContext(UserContext);
    const[tenantData, setTenantData] = useState([])
    const[editTenant, setEditTenant] = useState(false);
    const[disableTenant, setDisableTenant] = useState(false);
    const[tenantInfo, setTenantInfo] = useState({});

    useEffect(() => {
        if(userContext.tenant)
            getTenants();
    }, [userContext.tenant]);


    // Getter for Tenants
    async function getTenants(){
        try{
            const tenants = await API.get('cdyxdialer', '/admin/tenant', {
                headers:{
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': userContext.apiKey,
                    'Content-Type': 'application/json',
                }
            })
            // Format licenses
            for(let i = 0; i < tenants.length; i++){
                const formatLicenses = tenants[i].licenses;
                tenants[i].licenses = formatLicenses.join(',');
            }
            setTenantData(tenants);
        }catch(err){
            console.error(err);
        }
    }

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    function handleEdit(rowData){
        const licensesArray = rowData.licenses.split(',');
        const tenant = {
            tenantId: rowData.id,
            key: rowData.apiKey,
            licenses: licensesArray
        };
        setTenantInfo(tenant);
        setEditTenant(true);
    }

    function handleDisable(rowData){
        setDisableTenant(!disableTenant);
    }

    return(
        <div>
            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item>
                    <Typography variant="h4">List Tenants</Typography>
                </Grid>
                <Grid item>
                    <CreateTenant update={getTenants}/>
                </Grid>
            </Grid>
            <br></br>
            <Grid container direction="column">
                <Grid item>
                    <MaterialTable columns={
                        [
                            { title: 'Name', field: 'id' },
                            { title: 'API Key', field: 'apiKey' },
                            { title: 'Licenses', field: 'licenses'},
                        ]
                    } 
                    title='' 
                    data={tenantData}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10
                    }}
                    components={{
                        Pagination: licensePaging
                    }}
                    actions={[
                        // Add in Edit Button
                        rowData => ({
                            icon: () => (<EditIcon color="primary"/>),
                            tooltip: 'Edit Tenant',
                            onClick: (event, rowData) => {
                                handleEdit(rowData);
                            }
                        }),

                        rowData => ({
                            icon: () => (<BlockOutlinedIcon color="primary"/>),
                            tooltip: 'Disable Tenant',
                            onClick: (event, rowData) => {
                                handleDisable(rowData)
                            }
                        })
                    ]}>
                    </MaterialTable>
                </Grid>
                <CreateTenant open={editTenant} tenant={tenantInfo} update={getTenants}/>
                <ConfirmDialog
                open={disableTenant}
                onCancel={() => {setDisableTenant(false)}}
                onConfirm={() => {setDisableTenant(false)}}
                confirmTxt='Yes'
                cancelTxt='No'
                >
                <Typography>Are you sure that you want to disable this Tenant?</Typography>
                </ConfirmDialog>
            </Grid>

        </div>
        
    );
}
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, Typography, Button } from '@material-ui/core';
import listHOC from '../components/ListHOC';
import ConfigProfileList from '../E2/ConfigProfileList';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { withRouter } from 'react-router-dom';

const styles = {
    table: {
        minWidth: 650
    }
};

const ConfigProfiles = function(props) {
    
    const handleButtonClick = (path) => () => {
        props.history.push(path, {comingFrom: 'New'});
    }

    const ConfigProfileListHOC = listHOC(ConfigProfileList, queries.listConfigProfiles, subscriptions.onUpdateConfigProfile,
        subscriptions.onCreateConfigProfile, subscriptions.onDeleteConfigProfile);


    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Grid item container direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item container direction="row" justify="space-between" alignItems="flex-end">
                            <Grid item>
                                <Typography variant="h5">
                                    Channel Configuration Profiles
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleButtonClick('/e2/profiles')} color="primary" variant="contained">+ Profile</Button>
                            </Grid>
                        </Grid>
                        <br></br>
                        <ConfigProfileListHOC tenant={tenant} listProperty="listConfigProfiles" onCreateProperty="onCreateConfigProfile"
                        onUpdateProperty="onUpdateConfigProfile" onDeleteProperty="onDeleteConfigProfile" />
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}

export default withRouter(withStyles(styles)(ConfigProfiles));
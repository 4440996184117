import React, { useEffect, useState, useContext } from "react";
import { API, Auth } from "aws-amplify";
import UserContext from "../context/UserContext";
import CampaignStatusBreakdown from "./CampaignStatusBreakdown";
import {
  EqualizerOutlined,
  PhoneOutlined,
  PersonAddDisabledOutlined,
} from "@material-ui/icons";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Table,
  TableBody,
  Paper,
  TableCell,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Dialog,
  Divider,
} from "@material-ui/core";

/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const ExportDialog = function (props) {
  const userContext = useContext(UserContext);
  const [campaignStatus, setCampaignStatus] = useState();
  const [openBreakdown, setOpenBreakdown] = useState(false);
  const [selSegmentId, setSelSegmentId] = useState();
  const [selSegmentName, setSelSegmentName] = useState();
  const [channel, setChannel] = useState("voice");

  const [totalContacts, setTotalContacts] = useState();
  const [completedContacts, setCompletedContacts] = useState();
  const [percentageComplete, setPercentageComplete] = useState();

  useEffect(() => {
    //If there is configuration for the export SFTP then allow it as an action.
    if (props.campaignId && props.open) {
      getCampaignStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campaignId, props.open]);

  async function getCampaignStatus() {
    setCampaignStatus();
    try {
      const getData = await API.post("cdyxdialer", "/cache/status", {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "x-api-key": userContext.apiKey,
        },
        body: {
          campaignId: props.campaignId,
          channel: channel,
        },
      });

      //Setting Retrieved Data
      setTotalContacts(getData.totalContacts);
      
      //CompletedContacts
      let completed = 0;
      for (const segment of getData.campaignStats){
          completed = completed + segment.completed;
      }
      setCompletedContacts(completed);
      
      // CompletedPercentage
      let percentage = (completed / getData.totalContacts) * 100;
      setPercentageComplete(percentage.toFixed(2));
      setCampaignStatus([...getData.campaignStats]);

      console.log(getData);
    } catch (err) {
      console.log(err);
    }
  }

  async function closeDialog() {
    setCampaignStatus();
    if (props.onCancel) {
      props.onCancel();
    }
  }

  async function onCancel() {
    setOpenBreakdown(false);
    setSelSegmentId();
    setSelSegmentName();
  }

  const openStatusBreakdown = (id, name) => async (event) => {
    setOpenBreakdown(true);
    setSelSegmentId(id);
    setSelSegmentName(name);
  };

  const toggleChannel = (event, value) => {
    if (value) {
      setChannel(value);
    }
  };

  return (
    <UserContext.Consumer>
      {({ tenant }) => (
        <div>
          <Dialog maxWidth="sm" open={props.open}>
            <DialogTitle id="dp-dialog-title">Campaign Status</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {campaignStatus && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    style={{ marginBottom: "24px" }}
                  >
                    <Grid item container direction="column">
                      <Grid item>{`Total Campaign Contacts: ${totalContacts}`}</Grid>
                      <Grid item>{`Completed Contacts: ${completedContacts}`}</Grid>
                      <Grid item>{`Percentage Complete: ${percentageComplete}%`}</Grid>
                    </Grid>
                  </Grid>
                )}

                {campaignStatus && (
                  <Grid container item justifyContent="flex-end" xs={12}>
                    <ToggleButtonGroup
                      size="small"
                      value={channel}
                      exclusive
                      onChange={toggleChannel}
                    >
                      <ToggleButton value="voice">
                        <Tooltip title="View Voice Status">
                          <PhoneOutlined color="primary" fontSize="small" />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="Agentless">
                        <Tooltip title="View Agentless Status">
                          <PersonAddDisabledOutlined color="primary" fontSize="small" />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                )}

                {campaignStatus && channel === "voice" && (
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Segment</TableCell>
                            <TableCell>Fresh</TableCell>
                            <TableCell>InDialer</TableCell>
                            <TableCell>Retry</TableCell>
                            <TableCell>Call Back</TableCell>
                            <TableCell>View Breakdown</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {campaignStatus.map((row) => (
                            <TableRow key={row.segment}>
                              <TableCell component="th" scope="row">
                                {row.segment}
                              </TableCell>
                              <TableCell align="center">{row.fresh}</TableCell>
                              <TableCell align="center">{row.inDialer}</TableCell>
                              <TableCell align="center">{row.retry}</TableCell>
                              <TableCell align="center">{row.callBack}</TableCell>
                              <TableCell
                                onClick={openStatusBreakdown(row.segmentId, row.segment)}
                                align="center"
                              >
                                <EqualizerOutlined color="primary" />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {campaignStatus && channel === "Agentless" && (
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Segment</TableCell>
                            <TableCell>Fresh</TableCell>
                            <TableCell>InDialer</TableCell>
                            <TableCell>Retry</TableCell>
                            <TableCell>View Breakdown</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {campaignStatus.map((row) => (
                            <TableRow key={row.segment}>
                              <TableCell component="th" scope="row">
                                {row.segment}
                              </TableCell>
                              <TableCell align="center">
                                {row.alFresh}
                              </TableCell>
                              <TableCell align="center">
                                {row.inDialer}
                              </TableCell>
                              <TableCell align="center">
                                {row.alRetry}
                              </TableCell>
                              <TableCell
                                onClick={openStatusBreakdown(row.segmentId, row.segment)}
                                align="center"
                              >
                                <EqualizerOutlined color="primary" />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}

                {!campaignStatus && <CircularProgress />}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={getCampaignStatus}
                color="primary"
                variant="contained"
              >
                Refresh
              </Button>
              <Button onClick={closeDialog} color="primary" variant="outlined">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <CampaignStatusBreakdown
            open={openBreakdown}
            onCancel={onCancel}
            campaignId={props.campaignId}
            segmentId={selSegmentId}
            segmentName={selSegmentName}
            channel={channel}
          />
        </div>
      )}
    </UserContext.Consumer>
  );
};
export default ExportDialog;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, Typography, Paper } from '@material-ui/core';
import Hold from './E2IconComponents/Hold';
import Mute from './E2IconComponents/Mute';
import Record from './E2IconComponents/Record';
import Mask from './E2IconComponents/Mask';
import Transfer from './E2IconComponents/Transfer';
import Disposition from './E2IconComponents/Disposition';
import Callback from './E2IconComponents/Callback';
import ActivityPanel from './E2IconComponents/ActivityPanel';
import Visible from './E2IconComponents/Visible';
import Settings from './E2IconComponents/Settings';
import RefreshE2 from './E2IconComponents/RefreshE2';
import RefreshSkills from './E2IconComponents/RefreshSkills';
import NavHistory from './E2IconComponents/NavHistory';
import CDSite from './E2IconComponents/CDSite';
import Help from './E2IconComponents/Help';
import ChatTransfer from './E2IconComponents/ChatTransfer';
import ChatDisposition from './E2IconComponents/ChatDisposition';
import ChatActivityPanel from './E2IconComponents/ChatActivityPanel';
import LiveChat from './E2IconComponents/LiveChat';
import EmailDisposition from './E2IconComponents/EmailDisposition';
import EmailActivityPanel from './E2IconComponents/EmailActivityPanel';
import EmailShowMessage from './E2IconComponents/EmailShowMessage';
import EmailReply from './E2IconComponents/EmailReply';
import EmailReplyAll from './E2IconComponents/EmailReplyAll';
import EmailForward from './E2IconComponents/EmailForward';
import EmailPark from './E2IconComponents/EmailPark';
import EmailComposeMessage from './E2IconComponents/EmailComposeMessage';
import { trackUpdate } from 'aws-amplify-react';
import WorkItemActivityPanel from './E2IconComponents/WorkItemActivityPanel';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        height: '300px'
    }
}));

// THIS FILE WILL BE EDITED IN THE FUTURE, FOR NOW IT WILL WORK HOW IT IS
export default function E2Icons(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [channel, setChannel] = useState(props.mediaType);
    const permissions = {
        phoneCall: {
            hold: true,
            mute: true,
            record: true,
            mask: true,
            transfer: true,
            disposition: true,
            callback: true,
            activityPanel: true,
        },
        chat:{
            transfer: true,
            disposition: true,
            activityPanel: true,
            liveChat: true
        },
        email:{
            disposition: true,
            activityPanel: true,
            showMessage: true,
            reply: true,
            replyAll: true,
            forward: true,
            park: true,
            composeMessage: true
        },
        workItem: {
          activityPanel: true,
        },
    }
    /** Check what mediaType is passed in through props so we can filter permissions accordingly */
    const [uiInfo, setUIInfo] = useState(permissions);

    useEffect(() => {
        
        console.log(props.permissions);
        // If props.permissions is not empty then we can set uiInfo to it
        if(props.permissions && Object.keys(props.permissions).length > 0){
            setUIInfo(props.permissions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant])


    /** 
     * These methods handle the change for the different permissions and sets our permissions in props as well.
     *  We directly work on props.permissions at it makes things simpler and we don't repeat a lot of code.
     */ 


    const handleHold = (value) => {uiInfo.phoneCall.hold = value; props.setPermissions(uiInfo);}
    const handleMute = (value) => {uiInfo.phoneCall.mute = value; props.setPermissions(uiInfo)}
    const handleRecord = (value) => {uiInfo.phoneCall.record = value; props.setPermissions(uiInfo)}
    const handleMask = (value) => {uiInfo.phoneCall.mask = value; props.setPermissions(uiInfo)}
    const handleTransfer = (value) => {uiInfo.phoneCall.transfer = value; props.setPermissions(uiInfo)}
    const handleDisposition = (value) => {uiInfo.phoneCall.disposition = value; props.setPermissions(uiInfo)}
    const handleCallback = (value) => {uiInfo.phoneCall.callback = value; props.setPermissions(uiInfo)}
    const handleActivityPanel = (value) => {uiInfo.phoneCall.activityPanel = value; props.setPermissions(uiInfo)}

    const handleChatTransfer = (value) => {uiInfo.chat.transfer = value; props.setPermissions(uiInfo);} 
    const handleChatDisposition = (value) => {uiInfo.chat.disposition = value; props.setPermissions(uiInfo);}
    const handleChatActivityPanel = (value) => {uiInfo.chat.activityPanel = value; props.setPermissions(uiInfo);}
    const handleLiveChat = (value) => {uiInfo.chat.liveChat = value; props.setPermissions(uiInfo);}

    const handleWorkItemActivityPanel = (value) => {uiInfo.workItem.activityPanel = value; props.setPermissions(uiInfo);}

    const handleEmailDisposition = (value) => {uiInfo.email.disposition = value; props.setPermissions(uiInfo);}
    const handleEmailActivityPanel = (value) => {uiInfo.email.activityPanel = value; props.setPermissions(uiInfo);}
    const handleEmailShowMessage = (value) => {uiInfo.email.showMessage = value; props.setPermissions(uiInfo);}
    const handleEmailReply = (value) => {uiInfo.email.reply = value; props.setPermissions(uiInfo);}
    const handleEmailReplyAll = (value) => {uiInfo.email.replyAll = value; props.setPermissions(uiInfo);}
    const handleEmailForward = (value) => {uiInfo.email.forward = value; props.setPermissions(uiInfo);}
    const handleEmailPark = (value) => {uiInfo.email.park = value; props.setPermissions(uiInfo);}
    const handleEmailComposeMessage = (value) => {uiInfo.email.composeMessage = value; props.setPermissions(uiInfo);}

    
    
    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography variant="h5">Customize E2 User Interface</Typography>
                        </Grid>
                        {(channel === 'sms') && <Typography>There is no SMS UI configuration yet.</Typography>}
                        {(channel === 'phoneCall') && <Typography>Phone Call</Typography>}
                        {(channel === 'phoneCall') && <Grid item container direction="column">
                            <Grid item>
                                <Hold value={uiInfo.phoneCall.hold} onChange={handleHold}></Hold>
                            </Grid>
                            <Grid item>
                                <Mute value={uiInfo.phoneCall.mute} onChange={handleMute}></Mute>
                            </Grid>
                            <Grid item>
                                <Record value={uiInfo.phoneCall.record} onChange={handleRecord}></Record>
                            </Grid>
                            <Grid item>
                                <Mask value={uiInfo.phoneCall.mask} onChange={handleMask}></Mask>
                            </Grid>
                            <Grid item>
                                <Transfer value={uiInfo.phoneCall.transfer} onChange={handleTransfer}></Transfer>
                            </Grid>
                            <Grid item>
                                <Disposition value={uiInfo.phoneCall.disposition} onChange={handleDisposition}></Disposition>
                            </Grid>
                            <Grid item>
                                <Callback value={uiInfo.phoneCall.callback} onChange={handleCallback}></Callback>
                            </Grid>
                            <Grid item>
                                <ActivityPanel value={uiInfo.phoneCall.activityPanel} onChange={handleActivityPanel}></ActivityPanel>
                            </Grid>
                        </Grid>}

                        {/**This section handles the chat section (if media type is chat) */}
                        {(channel === 'chat') && 
                            <Grid container direction="row" spacing={4}>
                                <Grid item>
                                    <ChatTransfer value={uiInfo.chat.transfer} onChange={handleChatTransfer}></ChatTransfer>
                                </Grid>
                                <Grid item>
                                    <ChatDisposition value={uiInfo.chat.disposition} onChange={handleChatDisposition}></ChatDisposition>
                                </Grid>
                                <Grid item>
                                    <ChatActivityPanel value={uiInfo.chat.activityPanel} onChange={handleChatActivityPanel}></ChatActivityPanel>
                                </Grid>
                                <Grid item>
                                    <LiveChat value={uiInfo.chat.liveChat} onChange={handleLiveChat} />
                                </Grid>
                            </Grid>
                        }
                        {/**This section handles the email section (if media type is email) */}
                        {(channel === 'email') &&
                            <Grid container direction="row" spacing={4}>
                                <Grid item>
                                    <EmailShowMessage value={uiInfo.email.showMessage} onChange={handleEmailShowMessage} />
                                </Grid>
                                <Grid item>
                                    <EmailReply value={uiInfo.email.reply} onChange={handleEmailReply} />
                                </Grid>
                                <Grid item>
                                    <EmailReplyAll value={uiInfo.email.replyAll} onChange={handleEmailReplyAll} />
                                </Grid>
                                <Grid item>
                                    <EmailForward value={uiInfo.email.forward} onChange={handleEmailForward} />
                                </Grid>
                                <Grid item>
                                    <EmailPark value={uiInfo.email.park} onChange={handleEmailPark} />
                                </Grid>
                                <Grid item>
                                    <EmailComposeMessage value={uiInfo.email.composeMessage} onChange={handleEmailComposeMessage} />
                                </Grid>
                                <Grid item>
                                    <EmailDisposition value={uiInfo.email.disposition} onChange={handleEmailDisposition}></EmailDisposition>
                                </Grid>
                                <Grid item>
                                    <EmailActivityPanel value={uiInfo.email.activityPanel} onChange={handleEmailActivityPanel}></EmailActivityPanel>
                                </Grid>
                            </Grid>
                        }
                          {/**This section handles the work item section (if media type is work item) */}
                          {(channel === 'workItem') &&
                            <Grid container direction="row" spacing={4}>
                                <Grid item>
                                    <WorkItemActivityPanel value={uiInfo.workItem.activityPanel} onChange={handleWorkItemActivityPanel} />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React, { useEffect, useState, useContext, useRef } from "react";

import {
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Paper,
    Divider,
    IconButton,
    Select,
    InputLabel,
    Typography,
    Checkbox,
    MenuItem,
    Tooltip,
    FormHelperText,
    List,
    ListItem,
    ListSubheader,
    Switch,

} from "@material-ui/core";

import {
    HelpOutlineOutlined,
    Clear
} from "@material-ui/icons";

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';

export default function C2APayment(props) {
    const { formikProps, classes, contactFields } = props;
    const minPayTypes = ["None", "Percentage", "Static"];

    const emptyPendingField = { replacementField: { value: '', staticField: false }, contactField: '' };
    const [showTextInput, setTextInputState] = useState('none');
    const [showSelectInput, setSelectInputState] = useState('');
    const [pendingField, setPendingField] = useState(emptyPendingField);
    const [selectedFields, setSelectedFields] = useState([])
    const paymentFields = [
        {
            name: 'Balance',
            value: 'balanceReplacement',
        },
        {
            name: 'Aging Info',
            value: 'agingReplacement',
        },
        {
            name: 'Invoice Id',
            value: 'invoiceReplacement',
        },
    ]

    useEffect(() => {
        const fields = [];
        fields.push(...selectedFields)
        for (let key in formikProps.values.c2a.payment) {
            for (let field of paymentFields) {
                if (field.value === key && formikProps.values.c2a.payment[key].value) {
                    fields.push(
                        {
                            replacementField: {
                                value: key,
                                staticField: formikProps.values.c2a.payment[key].staticField
                            },
                            contactField: formikProps.values.c2a.payment[key].value,
                            display: field.name,
                        },
                    )
                    break;
                }
            }
        }
        setSelectedFields(fields);

    }, [])

    const clearSegments = formikProps => {
        setSelectedFields([]);
        const reset = { ...formikProps.values.c2a };
        reset.payment.balanceReplacement = { value: '', staticField: false };
        reset.payment.agingReplacement = { value: '', staticField: false };
        reset.payment.invoiceReplacement = { value: '', staticField: false };
        formikProps.setFieldValue('c2a', reset);
    }
    const deleteSegment = (segment, formikProps, index) => {
        selectedFields.splice(index, 1);
        const reset = { ...formikProps.values.c2a };
        reset.payment[segment.replacementField.value] = { value: '', staticField: false };
        formikProps.setFieldValue('c2a', reset);
    }
    const addSegment = formikProps => {
        setSelectedFields([...selectedFields, pendingField]);
        const added = { ...formikProps.values.c2a };
        const replacement = pendingField.replacementField.value;
        added.payment[replacement] = { value: pendingField.contactField, staticField: pendingField.replacementField.staticField };
        formikProps.setFieldValue('c2a', added);
        setPendingField(emptyPendingField);
        if (added.payment[replacement].staticField) {
            setSelectInputState('')
            setTextInputState('none')
        }

    }
    const handleReplacementField = (event) => {
        let display = '';
        for (let field of paymentFields) {
            if (field.value === event.target.value) {
                display = field.name;
                break;
            }
        }
        setPendingField({
            replacementField: {
                value: event.target.value,
                staticField: pendingField.replacementField.staticField
            },
            display: display
        })
    }
    const handleContactField = (event) => {
        setPendingField({
            ...pendingField,
            contactField: event.target.value
        })
    }
    const handleStaticField = (event) => {

        setPendingField({
            ...pendingField,
            replacementField: {
                value: pendingField.replacementField.value,
                staticField: !pendingField.replacementField.staticField
            }
        })

        if (pendingField.replacementField.staticField) {
            setSelectInputState('')
            setTextInputState('none')
        } else {
            setSelectInputState('none')
            setTextInputState('')
        }
    }

    return (
        <Grid item container direction="column" spacing={2}>
            <Grid item>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    alignContent="center"
                >
                    {formikProps.values.c2a.paymentEnabled && (
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            formikProps.values?.c2a
                                                ?.payment?.finalize ??
                                            false
                                        }
                                        onChange={
                                            formikProps.handleChange
                                        }
                                        onBlur={
                                            formikProps.handleChange
                                        }
                                        name="c2a.payment.finalize"
                                        color="primary"
                                    />
                                }
                                label="Finalize Payment C2A"
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {formikProps.values.c2a.paymentEnabled && (
                <>
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="c2a.payment.dynamicDescriptor"
                                    label="Descriptor"
                                    type="text"
                                    className={classes.field}
                                    value={
                                        formikProps.values?.c2a?.payment
                                            ?.dynamicDescriptor ?? ""
                                    }
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={
                                        formikProps.errors?.c2a?.payment
                                            ?.dynamicDescriptor &&
                                        formikProps.touched?.c2a
                                            ?.payment?.dynamicDescriptor
                                    }
                                    helperText={
                                        formikProps.touched?.c2a
                                            ?.payment
                                            ?.dynamicDescriptor &&
                                        formikProps.errors?.c2a?.payment
                                            ?.dynamicDescriptor
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel
                                        error={
                                            formikProps.errors?.c2a
                                                ?.payment?.minType &&
                                            formikProps.touched?.c2a
                                                ?.payment?.minType
                                        }
                                    >
                                        Min Pay Type
                                    </InputLabel>
                                    <Select
                                        labelId="min-pay-types-label"
                                        name="c2a.payment.minType"
                                        className={classes.field}
                                        value={
                                            formikProps.values?.c2a
                                                ?.payment?.minType ??
                                            false
                                        }
                                        onBlur={formikProps.handleBlur}
                                        onChange={
                                            formikProps.handleChange
                                        }
                                        error={
                                            formikProps.errors?.c2a
                                                ?.payment?.minType &&
                                            formikProps.touched?.c2a
                                                ?.payment?.minType
                                        }
                                    >
                                        {minPayTypes.map((type) => (
                                            <MenuItem
                                                key={type.toLowerCase()}
                                                value={type.toLowerCase()}
                                            >
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formikProps.errors?.c2a?.payment
                                        ?.minType &&
                                        formikProps.touched?.c2a
                                            ?.payment?.minType && (
                                            <FormHelperText error>
                                                {
                                                    formikProps.errors
                                                        ?.c2a?.payment
                                                        ?.minType
                                                }
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="c2a.payment.minRate"
                                    label="Min Rate"
                                    type="number"
                                    className={classes.field}
                                    value={
                                        formikProps.values?.c2a?.payment
                                            ?.minRate ?? 0
                                    }
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={
                                        formikProps.errors?.c2a?.payment
                                            ?.minRate &&
                                        formikProps.touched?.c2a
                                            ?.payment?.minRate
                                    }
                                    helperText={
                                        formikProps.touched?.c2a
                                            ?.payment?.minRate &&
                                        formikProps.errors?.c2a?.payment
                                            ?.minRate
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    alignContent="center"
                                >
                                    <Grid item>
                                        <Typography>
                                            Replacement Payment Fields
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Map contact fields for C3 Message Template text replacements">
                                            <HelpOutlineOutlined
                                                fontSize="small"
                                                color="primary"
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Paper hidden={selectedFields.length === 0}>
                                <Grid item>
                                    <List dense={true}>
                                        <ListSubheader>
                                            <Grid container justifyContent='space-between'>
                                                <Grid item>
                                                    Replacement Fields
                                                </Grid>
                                                <Grid item>
                                                    Contact Fields
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title='Clear Mappings List'>
                                                        <IconButton aria-label="delete" onClick={() => clearSegments(formikProps)}>
                                                            <Clear fontSize="small" color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListSubheader>
                                        <Divider />
                                        {selectedFields.map((selection, index) => {
                                            return <ListItem style={{ height: '40px' }}>
                                                <Grid container alignItems="center" alignContent="center" justifyContent='space-between'>
                                                    <Grid item className={classes.field} >
                                                        {selection.display}
                                                    </Grid>
                                                    <Grid item className={classes.field} >
                                                        {selection.contactField}
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid item>
                                                            <IconButton aria-label="delete" onClick={() => deleteSegment(selection, formikProps, index)}>
                                                                <DeleteIcon fontSize="small" color="primary" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        })}
                                    </List>
                                </Grid>
                            </Paper>
                            <Grid item hidden={selectedFields.length === paymentFields.length}>
                                <Grid container spacing={2} alignItems="center" alignContent="center" >
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel color="primary" id="campaign-label">Replacement Field</InputLabel>
                                            <Select
                                                color="primary"
                                                name="segmentId"
                                                label="Segment"
                                                className={classes.field}
                                                value={pendingField.replacementField.value}
                                                onChange={handleReplacementField}
                                                error={
                                                    formikProps.errors?.c2a?.payment?.balanceReplacement &&
                                                    formikProps.touched?.c2a?.payment?.balanceReplacement
                                                } >
                                                {
                                                    paymentFields.map(field =>
                                                        !selectedFields.some(item => item.replacementField.value === field.value) &&
                                                        <MenuItem key={field.value} value={field.value}>{field.name}</MenuItem>
                                                    )}
                                            </Select>
                                            {formikProps.errors?.c2a?.payment?.balanceReplacement && formikProps.touched?.c2a?.payment?.balanceReplacement && (
                                                <FormHelperText error >
                                                    {
                                                        formikProps.errors?.c2a?.payment?.balanceReplacement
                                                    }
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={pendingField.replacementField.staticField}
                                                    onChange={
                                                        handleStaticField
                                                    }
                                                    onBlur={
                                                        formikProps.handleChange
                                                    }

                                                    color="primary"
                                                />
                                            }
                                            label="Static"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel color="primary" id="campaign-label" style={{ "display": showSelectInput }}>Contact Field</InputLabel>
                                            <Select
                                                style={{ "display": showSelectInput }}
                                                color="primary"
                                                name="segmentId"
                                                label="Segment"
                                                className={classes.field}
                                                value={pendingField.contactField}
                                                error={
                                                    formikProps.errors?.c2a?.payment?.balanceReplacement &&
                                                    formikProps.touched?.c2a?.payment?.balanceReplacement
                                                }
                                                onChange={handleContactField} >
                                                {contactFields.map((field, index) =>
                                                    <MenuItem key={index} value={field.value}>{field.label}</MenuItem>)}
                                            </Select>

                                            <TextField
                                                style={{ "display": showTextInput }}
                                                label="Contact Field"
                                                margin="dense"
                                                name="segmentId"
                                                type="text"
                                                className={classes.field}
                                                value={pendingField.contactField}
                                                error={
                                                    formikProps.errors?.c2a?.payment?.balanceReplacement &&
                                                    formikProps.touched?.c2a?.payment?.balanceReplacement
                                                }
                                                onChange={handleContactField}
                                            />

                                            {formikProps.errors?.c2a?.payment?.balanceReplacement && formikProps.touched?.c2a?.payment?.balanceReplacement && (
                                                <FormHelperText error >
                                                    {
                                                        formikProps.errors?.c2a?.payment?.balanceReplacement
                                                    }
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={pendingField.replacementField === "" ? 'Select a field' : pendingField.contactField === "" ? "Select the replacing field" : "Add Field"}>
                                            <div>
                                                <IconButton aria-label="add" onClick={() => addSegment(formikProps)} disabled={pendingField.replacementField === "" || pendingField.contactField === ""}>
                                                    <AddIcon color={pendingField.replacementField === "" || pendingField.contactField === "" ? "disabled" : "primary"} fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { 
    Grid, 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        height: '300px'
    }

}));

export default function Action(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [action, setAction] = useState('');

    useEffect(() => {
        async function getData(){
            setAction(props.action);
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const handleAction = (event) => {
        const selAction = event.target.value;
        setAction(selAction);
        props.setAction(selAction);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid item>
                        <FormControl>
                            <InputLabel shrink id="entity">Action</InputLabel>
                            <Select
                                labelId="action"
                                name="action"
                                value={action}
                                displayEmpty
                                onChange={handleAction}
                                className={classes.selectFields}
                            >
                                <MenuItem value="search">Search</MenuItem>
                                <MenuItem value="create">Create</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
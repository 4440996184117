/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';

export default function AgentReports(props) {
    const [agentReports, setAgentReports] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setAgentReports(props.value);
    }, [props.value]);

    /**Handle changes to activity panel checkbox */
    const handleAgentReports = () => {
        setAgentReports(!agentReports);
        props.onChange(!agentReports)
    }

    return (
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agentReports}
                                onChange={handleAgentReports}
                                name="agentReports"
                                color="primary"
                            />
                        }
                        label="Agent Reports"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission for Agent Reports">
                        <BarChartOutlinedIcon color="primary" />
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Button, TextField, Typography, Paper, InputAdornment } from '@material-ui/core';
import Payment from '../components/Payment';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
});

const newBalance = {
    amount: 0.01,
    age: 1,
    cardHolderName: ''
}


export default function ManualPayment(props) {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [balance, setBalance] = useState(newBalance);
    const [submitBalance, setSubmitBalance] = useState();
    const [contact, setContact] = useState({});
    const [successfulPayment, setSuccessfulPayment] = useState(false);
    const [balanceString, setBalanceString] = useState(newBalance.amount.toString());

    useEffect(() => {
        setBalance(newBalance);
    }, [])

    useEffect(() => {
        if (props.contact){
            setContact(props.contact);
        }
    }, [props.contact]);

    function submitPayment () {
        if (balance.amount > 0) {
            setSubmitBalance({ ...balance});
        } else {
            enqueueSnackbar('Please enter a valid amount', {
                variant: 'error'
            });
        }
    }

    function handleClose(){
        props.handleClose();
    }

    const handleCardHolderChange = (event) => {
        const name = event.target.value;
        const tempContact = contact;
        contact.firstName = name;
        setContact({...tempContact});
    }

    const handlePaymentAmount = (event) => {
        const amount = event.target.value;
        const tempBalance = balance;
        tempBalance.amount = amount;
        setBalance({...tempBalance});
        setBalanceString(amount);
    }

    function handlePaymentAmountFieldBlur(event) {
        let amount = '';
        let encounteredDot = false;
        let placesAfterDot = 0;
        for (const char of event.target.value) {
            if (/^[0-9]$/.test(char)) {
                amount = `${amount}${char}`;

                placesAfterDot += Number(encounteredDot);

            } else if (char === '.') {
                encounteredDot = true;
            }

            if (placesAfterDot === 2) break;
        }

        amount = Number(amount);
        if (event.target.value.includes('.')) {
            amount /= Math.pow(10, placesAfterDot);
        }

        setBalance(balance => {
            balance.amount = amount;
            return { ...balance };
        });
        setBalanceString(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount).substring(1));
    }

    return (
        <div>
            <Grid item container direction="column" spacing={2}>
                <Paper className={classes.paper}>
                    <Grid item>
                        <Typography color="primary" variant="h6">C3 Purchase</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="cardHolderName"
                            label="Card Holder"
                            type="text"
                            value={contact.firstName + ' ' + contact.lastName}
                            onChange={handleCardHolderChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="amount"
                            label="Amount"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            required={true}
                            value={balanceString}
                            onChange={handlePaymentAmount}
                            onBlur={handlePaymentAmountFieldBlur}
                        />
                    </Grid>
                    <br></br>
                    {successfulPayment && <Grid item>
                        <Typography variant="overline">Payment Successfully Sent</Typography>
                    </Grid>}
                    <br></br>
                    <Grid item container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Button type="submit" disabled={successfulPayment} color="primary" onClick={submitPayment} variant="contained">Submit</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={handleClose} variant="outlined">Close</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Payment
                tenant={props.tenant}
                contact={contact}
                token={props.token}
                apiKey={props.apiKey}
                agentId={props.agentId}
                contactId={props.contactId}
                skill={props.skill}
                awsClient={props.awsClient}
                balance={submitBalance}
                setSuccessfulPayment={setSuccessfulPayment}
                handleClose={handleClose}
                // sessionId={props.sessionId}
                // icContactId={props.icContactId}
                // gettingDTMFStuffs={props.gettingDTMFStuffs}
            />

        </div>
    )
}
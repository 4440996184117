import React from 'react';
import {
    Typography,
    withStyles,
    Grid,
    Button
} from '@material-ui/core';
import listHOC from '../components/ListHOC';
import SegmentList from '../components/SegmentList'
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { NavLink } from 'react-router-dom';
import UserContext from '../context/UserContext';


const styles = {
    table: {
        minWidth: 650
    }
};
/**
 * The Segments component is a base route, renders the campaign list with ListHOC and SegmentList
 * Expects No params our query string values on the route
 * Redirects to a new Route, campaign, for the creation, edit, or cloneing of Segment.
 * * @category Pages
 *  @component
 *
 */
class Segments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmOpen: false,
            toDelete: undefined
        }
        this.classes = props.classes;
        this.confirm = props.confirm;
    }

    render() {
        const SegmentListHOC = listHOC(SegmentList, queries.segmentByTenant, subscriptions.onTenantUpdateSegment,
            subscriptions.onTenantCreateSegment, subscriptions.onTenantDeleteSegment);

        return (
            <UserContext.Consumer>
                {({ tenant }) => (
                    <div>
                        <Grid container
                            direction="column"
                            justify="flex-start"
                            alignItems="stretch"
                        >
                            <Grid container direction="row" justify="space-between" alignItems="flex-end">
                                <Grid item>
                                    <Typography variant="h4">
                                        Segments
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button component={NavLink} to="/segment/new" color="primary" variant="contained">+ Segment</Button>
                                </Grid>
                            </Grid>
                            <br></br>
                            <SegmentListHOC tenant={tenant} listProperty="segmentByTenant" onCreateProperty="onTenantCreateSegment"
                                onUpdateProperty="onTenantUpdateSegment" onDeleteProperty="onTenantDeleteSegment"/>
                        </Grid>
                    </div>
                )}
            </UserContext.Consumer>
        )
    }
}

export default withStyles(styles)(Segments);

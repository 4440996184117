import React, {useState, useEffect} from 'react';
import { Grid, Select, MenuItem, FormControl, InputLabel, makeStyles } from "@material-ui/core";
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as _ from 'lodash';


const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        maxWidth: 150
    }
}))

const ChartFieldSelect = function (props) {
    const tempArray = [];
    const classes = useStyles();
    const [aggFields, setAggFields] = useState([]);
    const [field, setField] = useState('');

    useEffect(() => {
        async function getData(){
            // Get tenant settings
            const idToken = (await Auth.currentSession()).getIdToken();
            const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: idToken.payload['custom:tenant']}));
            const customFields = await settings.data.getTenantSettings.customFields;

            // Get field mappings
            const fields = await API.graphql(graphqlOperation(queries.esQuery,{
                model: props.index,
                action: '_mapping',
                query: '{}'
            }));
            const fieldsList = [];
            const queryResult = await JSON.parse(fields.data.esQuery);
            const properties = await queryResult[props.index].mappings.doc.properties;
            if(props.index === 'ciflog'){
                await getProperties(properties, '');
                for (const field of tempArray){
                    const fieldWords = _.words(field.display);
                    let display = '';
                    for (const word of fieldWords){
                        display = display + word[0].toUpperCase() + word.substring(1) + ' ';
                    }
                    let option = {
                        display: display,
                        data: field.data
                    }
                    fieldsList.push(option);

                }
                const options = _.sortBy(fieldsList, 'display');
                setAggFields([...options]);
            } else if (props.index === 'activitylog'){
                await getProperties(properties, '');
                for (const field of tempArray){
                    if(_.findIndex(customFields, {'name': field.display}) !== -1 && field.data.includes('AdditionalInfo.customFields')){
                        const fieldWords = _.words(field.display);
                        let display = '';
                        for (const word of fieldWords){
                            display = display + word[0].toUpperCase() + word.substring(1) + ' ';
                        }
                        let option = {
                            display: display,
                            data: field.data
                        }
                        fieldsList.push(option);
                    }
                }
                const options = _.sortBy(fieldsList, 'display');
                setAggFields([...props.standardFields ,...options]);
            }
            if (props.field){
                setField(props.field);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.index]);

    async function getProperties(properties, keyName){
        for (const field in properties){
            if (field.indexOf('@odata') === -1 && field.indexOf('participationtypemask') === -1 && field.indexOf('_typename') === -1 && field.indexOf('tenant') === -1){
                if (!properties[field].properties){
                    if(keyName !== ''){
                        if (properties[field].fields && properties[field].fields.keyword.type === 'keyword'){
                            let option = {
                                display: field,
                                data: `${keyName}.${field}.keyword`
                            }
                            tempArray.push(option);
                        } else {
                            let option = {
                                display: field,
                                data: `${keyName}.${field}`
                            }
                            tempArray.push(option);
                        }
                    } else {
                        if (properties[field].fields && properties[field].fields.keyword.type === 'keyword'){
                            let option = {
                                display: field,
                                data: `${field}.keyword`
                            }
                            tempArray.push(option);
                        } else {
                            let option = {
                                display: field,
                                data: `${field}`
                            }
                            tempArray.push(option);
                        }
                    }
                } else {
                    if (keyName !== ''){
                        getProperties(properties[field].properties, `${keyName}.${field}`)
                    } else {
                        getProperties(properties[field].properties, field);
                    }
                }
            }
        }
    }

    const handleFieldChange = (event) => {
        setField(event.target.value);
        props.setField(event.target.value);
    }

  
    return (
        <div>
            <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id="fieldSelect">Field</InputLabel>
                    <Select
                        margin="dense"
                        name="field"
                        label="Field"
                        value={field}
                        onChange={handleFieldChange}
                    >
                        {aggFields.map(field =>
                            <MenuItem key={field.data} value={field.data}>{field.display}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
        </div>
    )
    
}

export default ChartFieldSelect;
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import UserContext from '../context/UserContext';
import { Button, CircularProgress } from '@material-ui/core';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { API, graphqlOperation} from 'aws-amplify';
import * as queries from '../graphql/queries';
import Validations from './YupValidations';
import * as _ from 'lodash';
const request = require('superagent');


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    inputField: {
        minWidth: '350px'
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function Central(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [toggleSaveButton, setSaveButtonActive] = useState(false);
    const [settings, setSettings] = useState({ icAccessKey: '', icApp: '', icAppSecret: '', icScriptName: '', icBU: '', icCluster: props.icCluster, icDiscoveryDomain: '', icGetAuthTokenURL: 'https://api.incontact.com/InContactAuthorizationServer/Token', icPassword: '', icUserName: '', icVendor: '' });
    const userContext = useContext(UserContext);
    const [testing, setTesting] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                console.log(userContext.tenant);
                const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
                const tempAll = dynamoSettings.data.getTenantSettings;

                if(!tempAll.icCluster){
                    tempAll.icCluster = props.icCluster;
                }
                
                setSettings({...tempAll});

                console.log(settings);

            } catch (err) {
                console.error(err);
            }
        }
        if (userContext.tenant) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const testCentralConnection = (values) => async () => {
        const tenantSettings = { ...values }

        const app = tenantSettings.icApp;
        const vendor = tenantSettings.icVendor;
        const businessUnit = tenantSettings.icBU;
        const icUserName = tenantSettings.icUserName;
        const icPassword = tenantSettings.icPassword;
        const url = tenantSettings.icGetAuthTokenURL;

        try {
            setTesting(true);
            const testConnectionResult = await request.post(url)
                .auth(`${app}@${vendor}`, businessUnit)
                .set({ "Accept-Encoding": "gzip,sdch" })
                .type('json')
                .send({
                    "grant_type": "password",
                    username: icUserName,
                    password: icPassword,
                    scope: ""
                });

            if (testConnectionResult.status === 200) {
                enqueueSnackbar('Connection Successful!');
                setSaveButtonActive(true);
            }
            else {
                enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                    variant: 'error',
                    autoHideDuration: 5000
                });
            }
            setTesting(false);
        } catch (err) {
            enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                variant: 'error',
                autoHideDuration: 5000
            });
            setTesting(false);
        }
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Formik
                        validateOnMount={true}
                        enableReinitialize={true}
                        initialValues={settings}
                        validationSchema={Validations.inContactValidation}
                        onSubmit={async values => {
                            //dynamodb doesn't like empty strings turn empty into null
                            try {
                                setSettings({...values})
                                const config = { id: tenant, ...values };
                                config.icAccessKey = '';
                                config.icAppSecret = '';
                                config.icDiscoveryDomain ='';
                                //alert(segment);
                                config.icAccessKey = '';
                                config.icAppSecret = '';
                                config.icDiscoveryDomain ='';
                                for (const key in config) {
                                    config[key] = config[key] || null;
                                }
                                delete config.createdAt;
                                delete config.updatedAt;
                                await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: config }));
                                enqueueSnackbar('Save Successful.')

                            } catch (err) {
                                enqueueSnackbar('Save Failed. ', { variant: 'error' });
                            }

                        }}
                    >

                        {formikprops => (
                            <form key="central" onSubmit={formikprops.handleSubmit}>
                                <Grid container direction='column' spacing={2}>
                                    <Grid item>

                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    name="icUserName"
                                                    label="User Name"
                                                    type="text"
                                                    required={true}
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icUserName}
                                                    error={formikprops.errors.icUserName && formikprops.touched.icUserName} 
                                                    helperText={formikprops.errors.icUserName} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icPassword"
                                                    label="Password"
                                                    type="password"
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icPassword}
                                                    error={formikprops.errors.icPassword && formikprops.touched.icPassword}
                                                    helperText={formikprops.errors.icPassword} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icBU"
                                                    label="Business Unit"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icBU}
                                                    error={formikprops.errors.icBU && formikprops.touched.icBU}
                                                    helperText={formikprops.errors.icBU} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icApp"
                                                    label="Application Name"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icApp}
                                                    error={formikprops.errors.icApp && formikprops.touched.icApp} 
                                                    helperText={formikprops.errors.icApp}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icVendor"
                                                    label="Vendor"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icVendor}
                                                    error={formikprops.errors.icVendor && formikprops.touched.icVendor}
                                                    helperText={formikprops.errors.icVendor} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icScriptName"
                                                    label="Script Name"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icScriptName}
                                                    error={formikprops.errors.icScriptName && formikprops.touched.icScriptName}
                                                    helperText={formikprops.errors.icScriptName} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="dense"
                                                    name="icGetAuthTokenURL"
                                                    label="Auth Token URL"
                                                    defaultValue="https://api.incontact.com/InContactAuthorizationServer/Token"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikprops.handleChange}
                                                    onBlur={formikprops.handleBlur}
                                                    value={formikprops.values.icGetAuthTokenURL}
                                                    error={formikprops.errors.icGetAuthTokenURL && formikprops.touched.icGetAuthTokenURL}
                                                    helperText={formikprops.errors.icGetAuthTokenURL} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Button disabled={!toggleSaveButton} type="submit" color="primary" variant="contained">Save</Button>
                                            </Grid>                                       
                                            <Grid item>   
                                                <Button color="primary" variant="outlined" disabled={!_.isEmpty(formikprops.errors)} onClick={testCentralConnection(formikprops.values)}>Test Connection</Button>
                                                {testing && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </Grid>  
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Hidden, Drawer } from '@material-ui/core';
import {
	PhoneEnabledOutlined,
	PeopleOutlined,
	BackupOutlined,
	MapOutlined,
	GetAppOutlined,
	RssFeedOutlined,
} from '@material-ui/icons';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { Route, Switch, useRouteMatch, NavLink, useLocation } from 'react-router-dom';
import Telephony from '../pages/Telephony';
import Downloads from '../pages/Downloads';
// import System from '../pages/System';
// import Users from '../pages/Users';
//import { Auth } from 'aws-amplify';
import InventorySource from '../pages/InventorySource';
import CustomFields from '../pages/CustomFields';
import SFTP from '../pages/SFTP';
import FieldMappings from '../pages/FieldMappings';
import FieldMapping from '../pages/FieldMapping';
import TestRedirector from '../pages/TestRedirector';
import DynamUpdate from '../pages/DynamUpdate';
import DynamConxStepper from '../components/DynamConxStepper';
import RESTConnections from '../pages/RESTConnections';
import RESTConnection from '../pages/RESTConnection';
import MobileConfigurations from '../pages/MobileConfigurations';
import MobileConfiguration from '../pages/MobileConfiguration';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: theme.zIndex.drawer + 1,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	title: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	currentPage: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)'
	},
}));

/**
 * This is the component in charge of laying out the configuration management page and giving
 * slots for other components to sit in.
 * @category Layouts
 * @component
 * @param {any} props The React props object passed into components.
 */
function MainLayout(props) {
	const { container } = props;
	const classes = useStyles();

	const [mobileOpen, setMobileOpen] = React.useState(false);
	

	let { path } = useRouteMatch();
	const location = useLocation();

	// useEffect(() => {
	// 	props.pageTitle('Settings');
	// },[]);

	/**
	 * Handle event to manage side drawer base on screen size.
	 */
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	/**
	 * The items to be listed on the left nav with the desired icon.
	 */
	const navItems = [
		{
			linkTo: '/',
			primaryText: 'Telephony',
			navIcon: (<PhoneEnabledOutlined />)
		},
		{
			linkTo: '/inventory-source',
			primaryText: 'CRM Configurations',
			navIcon: (<PeopleOutlined />)
		},
		// {
		// 	linkTo: '/System',
		// 	primaryText: 'System',
		// 	navIcon: (<CloudQueueOutlined />)
		// },
		// {
		// 	linkTo: '/Users',
		// 	primaryText: 'Account Users',
		// 	navIcon: (<PersonOutline />)
		// },
		{
			linkTo: '/sftp',
			primaryText: "SFTP Connections",
			navIcon: (<BackupOutlined />)
		},
		{
			linkTo: '/rest-connections',
			primaryText: "REST Connections",
			navIcon: (<RssFeedOutlined />)
		},
        {
			linkTo: '/mobile-config',
			primaryText: "Mobile Applications",
			navIcon: (<SmartphoneIcon />)
		},
		{
			linkTo: '/custom-fields',
			primaryText: 'Custom Fields',
			navIcon: (<TextFieldsIcon />)
		},
		{
			linkTo: '/field-mappings',
			primaryText: 'Field Mappings',
			navIcon: (<MapOutlined />)
		},
		{
			linkTo: '/downloads',
			primaryText: 'Downloads',
			navIcon: (<GetAppOutlined />)
		},

	];

	/**
	 * Generates HTML to be injected to handle left menu.
	 */
	const drawer = (
		<div>
			<div className={classes.toolbar} />
			<Divider />
			<List>
				{navItems.map((navItem, index) => {
					return (
						<ListItem button
							selected={`${location.pathname}` === `${path}${navItem.linkTo}`}
							key={index}
							component={NavLink} to={`${path}${navItem.linkTo}`}>
							<ListItemIcon>{navItem.navIcon}</ListItemIcon>
							<ListItemText primary={navItem.primaryText} />
						</ListItem>
					);
				})}
			</List>
		</div>
	);


	/**
	 * return function for rendering HTML
	 */
	return (
		<div className={classes.root}>
			<nav className={classes.drawer} aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						anchor="left"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<div className={classes.content}>
					<Switch>
						<Route exact path={path}>
							<Telephony />
						</Route>
						<Route path={`${path}/inventory-source`}>
							<InventorySource />
						</Route>
						<Route path={`${path}/custom-fields`}>
							<CustomFields />
						</Route>
						<Route path={`${path}/sftp`}>
							<SFTP />
						</Route>
						<Route path={`${path}/rest-connections`} exact>
							<RESTConnections />
						</Route>
						<Route path={`${path}/rest-connections/:id`}>
							<RESTConnection />
						</Route>
                        <Route path={`${path}/mobile-config`} exact>
							<MobileConfigurations />
						</Route>
                        <Route path={`${path}/mobile-config/:id`} exact>
							<MobileConfiguration />
						</Route>
						<Route path={`${path}/field-mappings`} exact>
							<FieldMappings />
						</Route>
						<Route path={`${path}/field-mappings/:id`}>
							<FieldMapping />
						</Route>
						<Route path={`${path}/downloads`} exact>
							<Downloads />
						</Route>
						<Route path={`${path}/tester`}>
							<TestRedirector />
						</Route>
						<Route path={`${path}/writer`}>
							<DynamUpdate />
						</Route>
						<Route path={`${path}/dynamics-configuration`}>
							<DynamConxStepper />
						</Route>
						{/* <Route path="/configlayout/source">
							<Source />
						</Route>
						<Route path="/configlayout/system">
							<System />
						</Route>
						<Route path="/configlayout/users">
							<Users />
						</Route> */}
					</Switch>

			</div>
		</div>
	);
}

MainLayout.propTypes = {
	container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element)
};

export default MainLayout;
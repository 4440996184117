import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Paper, Tabs, Tab, Grid, TextField, Typography, CircularProgress, Select, FormControl, InputLabel, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { CloudUploadOutlined, Delete, Edit } from '@material-ui/icons';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import * as moment from 'moment';
import clsx from 'clsx';
import MaterialTable from 'material-table';
import ConfirmDialog from '../components/ConfirmDialog';
import * as _ from 'lodash';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    inputField: {
        minWidth: '350px'
    },
    keyField: {
        minWidth: '525px'
    },
    portField: {
        minWidth: '50px'
    },
    tabContainer: {
        padding: '1em'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    fillSpace: {
        flexGrow: 1
    },
    form: {
        padding: '1em'
    }
}));

const emptyImport = { host: '', username: '', password: '', path: '', privateKey: '', port: '', fieldMappingId: '', fileNameToSource: false, reportEmail: ''};

export default function SFTPConnection() {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const [importSettings, setImport] = useState(emptyImport);
    const [exportSettings, setExport] = useState({ host: '', username: '', password: '', path: '', privateKey: '', port: '' });
    const [testing, setTesting] = useState(false);
    const [toggleSave, setToggleSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [tenant, setTenant] = useState();
    const [fieldMappings, setFieldMappings] = useState([]);
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [imports, setImports] = useState([]);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const [showForm, setShowForm] = useState(false);
    const [isNewConnection, setNewConnection] = useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {
        async function getData() {
            try {
                const idToken = await (await Auth.currentSession()).getIdToken();
                setTenant(idToken.payload['custom:tenant']);
                const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
                const tempAll = dynamoSettings.data.getTenantSettings;
                const fieldMappingsResult = await API.graphql(graphqlOperation(queries.tenantMappings, { tenant: userContext.tenant }));
                setFieldMappings(fieldMappingsResult.data.tenantMappings.items);

                const ftpImports = await API.graphql(graphqlOperation(queries.ftpImportsByTenant, { tenant: userContext.tenant }));
                setImports(ftpImports.data.ftpImportsByTenant.items);
                setExport({ ...JSON.parse(tempAll.ftpExport) });
                /* const importFields = {
                    ...emptyImport,
                    ...JSON.parse(tempAll.ftpImport)
                }
                setImport(importFields); */


            } catch (err) {
                console.error(err);
                enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                    variant: 'error',
                    autoHideDuration: 5000
                });
            }
        }
        if (userContext.tenant) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    }

    const testConnection = (values) => async () => {
        if (values.username === '' || values.host === '' || (values.password === '' && values.privateKey === '')) {
            enqueueSnackbar('You must provide a URL, UserName, and either a sftp key or a password for a valid connection', {
                variant: 'error',
                autoHideDuration: 5000
            });
        }
        else {
            try {
                setTesting(true);
                setSuccess(false);
                const conxResponse = await API.post(
                    'cdyxdialer',
                    '/ftp-check',
                    {
                        headers: {
                            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                            'x-api-key': userContext.apiKey
                        },
                        body: values
                    });
                console.log(conxResponse);
                if (conxResponse.success) {
                    //alert(conxPromise.Promise.v)
                    enqueueSnackbar('Connection Test Successful!', {
                        autoHideDuration: 3500
                    });
                    setToggleSave(true);
                    setSuccess(true);
                }
                else {
                    enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                        variant: 'error',
                        autoHideDuration: 5000
                    });
                    setSuccess(false);
                }
                setTesting(false);
            } catch (err) {
                console.log(err);
                enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                    variant: 'error',
                    autoHideDuration: 5000
                });
                setTesting(false);
                setSuccess(false);
            }
        }
    }

    const deleteFtpImport = (ftpImport) => {
        setToDelete(ftpImport);
        setConfirmOpen(true);
    }

    const handleDelete = async (ftpImport) => {
        setToDelete({});
        setConfirmOpen(false);
        await API.graphql(graphqlOperation(mutations.deleteFtpImport, { input: { id: ftpImport.id } }));
        setImports(imports.filter(item => ftpImport.id != item.id));
    }

    const cancelDelete = () => {
        setToDelete({});
        setConfirmOpen(false);
    }

    const handleNewConnection = () => {
        setNewConnection(true);
        setShowForm(true);
        setImport(emptyImport);
    }

    const handleCancel = () => {
        setShowForm(false);
    }

    const Formbody = (props) => {
        return (
            <Grid container direction="column" className={classes.form} spacing={2}>
                <Grid item container justifyContent="flex-start" direction="row">
                    <Grid item container direction="row" spacing={2} justifyContent="flex-start">
                        <Grid item>
                            <CloudUploadOutlined color="primary" />
                        </Grid>
                        <Grid item>
                            <Typography color="primary">Import Configuration</Typography>
                        </Grid>
                    </Grid>
                    {props.type === 'import' &&
                        <Grid item container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="name"
                                    label="Import Name"
                                    type="text"
                                    className={classes.inputField}
                                    onChange={props.formikprops.handleChange}
                                    onBlur={props.formikprops.handleBlur}
                                    value={props.formikprops.values.name}
                                    error={props.formikprops.errors.name && props.formikprops.touched.name}
                                    helperText={props.formikprops.errors.name} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="fileNameToSource"
                                            checked={props.formikprops.values.fileNameToSource}
                                            onChange={props.formikprops.handleChange}
                                            onBlur={props.formikprops.handleBlur}
                                            color="primary" />
                                    }
                                    label="File Name to Source field"
                                />
                            </Grid>

                        </Grid>
                    }
                    <Grid item container direction="row" spacing={2} justifyContent="flex-start">
                        <Grid item >
                            <TextField
                                margin="dense"
                                name="host"
                                label="SFTP URI"
                                type="text"
                                className={classes.inputField}
                                onChange={props.formikprops.handleChange}
                                onBlur={props.formikprops.handleBlur}
                                value={props.formikprops.values.host}
                                error={props.formikprops.errors.host && props.formikprops.touched.host}
                                helperText={props.formikprops.errors.host} />
                        </Grid>
                        <Grid item >
                            <TextField
                                margin="dense"
                                name="path"
                                label="Folder"
                                type="text"
                                className={classes.inputField}
                                onChange={props.formikprops.handleChange}
                                onBlur={props.formikprops.handleBlur}
                                value={props.formikprops.values.path}
                                error={props.formikprops.errors.path && props.formikprops.touched.path}
                                helperText={props.formikprops.errors.path} />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2} justifyContent="flex-start">
                        <Grid item >
                            <TextField
                                margin="dense"
                                name="username"
                                label="User Name"
                                type="text"
                                className={classes.inputField}
                                onChange={props.formikprops.handleChange}
                                onBlur={props.formikprops.handleBlur}
                                value={props.formikprops.values.username}
                                error={props.formikprops.errors.username && props.formikprops.touched.username}
                                helperText={props.formikprops.errors.username} />
                        </Grid>
                        <Grid item >
                            <TextField
                                margin="dense"
                                name="password"
                                label="SFTP Password"
                                type="password"
                                className={classes.inputField}
                                onChange={props.formikprops.handleChange}
                                onBlur={props.formikprops.handleBlur}
                                value={props.formikprops.values.password}
                                error={props.formikprops.errors.password && props.formikprops.touched.password}
                                helperText={props.formikprops.errors.password} />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2} justifyContent="flex-start">
                        <Grid item>
                            <TextField
                                margin="dense"
                                name="privateKey"
                                label="SFTP Private Key"
                                type="text"
                                multiline
                                rows={5}
                                className={classes.keyField}
                                onChange={props.formikprops.handleChange}
                                onBlur={props.formikprops.handleBlur}
                                value={props.formikprops.values.privateKey}
                                error={props.formikprops.errors.privateKey && props.formikprops.touched.privateKey}
                                helperText={props.formikprops.errors.privateKey} />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                name="port"
                                label="SFTP Port"
                                type="text"
                                className={classes.portField}
                                onChange={props.formikprops.handleChange}
                                onBlur={props.formikprops.handleBlur}
                                value={props.formikprops.values.port}
                                error={props.formikprops.errors.port && props.formikprops.touched.port}
                                helperText={props.formikprops.errors.port} />
                        </Grid>
                    </Grid>
                    {props.type === 'import' &&
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="import-field-mapping-label">Field Mapping</InputLabel>
                                    <Select
                                        labelId="import-field-mapping-label"
                                        name="fieldMappingId"
                                        onChange={props.formikprops.handleChange}
                                        onBlur={props.formikprops.handleBlur}
                                        error={props.formikprops.touched.fieldMappingId && props.formikprops.errors.fieldMappingId}
                                        helperText={props.formikprops.errors.fieldMappingId}
                                        value={props.formikprops.values.fieldMappingId}
                                    >
                                        {fieldMappings.map(mapping => (
                                            <MenuItem value={mapping.id}>{mapping.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item container direction="column">
                                <Grid item>
                                    <TextField
                                        margin="dense"
                                        name="reportEmail"
                                        label="Reporting Email"
                                        type="email"
                                        className={classes.inputField}
                                        onChange={props.formikprops.handleChange}
                                        onBlur={props.formikprops.handleBlur}
                                        value={props.formikprops.values.reportEmail}
                                        error={props.formikprops.errors.reportEmail && props.formikprops.touched.reportEmail}
                                        helperText={props.formikprops.errors.reportEmail} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">This email will receive a report of the import results.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>


                    }
                    <Grid item container direction="row" spacing={2} justifyContent="space-around" alignItems="center">
                        <Grid item>
                            <div className={classes.wrapper}>
                                <Button disabled={!toggleSave} type="submit" color="primary" variant="contained">Save</Button>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.wrapper}>
                                <Button color="primary" variant="outlined" className={buttonClassname} onClick={testConnection(props.formikprops.values)}>Test Connection</Button>
                                {testing && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Grid>
                        <Grid item className={classes.fillSpace}>
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="outlined" onClick={handleCancel}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    const importSFTP = (
        <div className={classes.tabContainer}>
            <Grid container direction="column" spacing={4} justifyContent="space-between" alignItems="stretch">
                {!showForm && <Grid item container direction="row" justifyContent="flex-end">
                    <Button color="primary" variant="contained" onClick={handleNewConnection}>+ SFTP Connection</Button>
                </Grid>
                }
                {showForm && <Grid item>
                    <Paper>
                        <Formik
                            initialValues={importSettings}
                            enableReinitialize={true}
                            validationSchema={Yup.object(
                                {
                                    host: Yup.string()
                                        .min(5, 'The Import URL must be 5 Characters long')
                                        .max(80, 'The Import URL can be a maximum of 80 Characters long')
                                        .required('You must provide a host to connect to'),
                                    name: Yup.string()
                                        .required('You must give the import a name'),
                                    username: Yup.string()
                                        .required('You must provide a user name'),
                                    privateKey: Yup.string()
                                        .nullable()
                                        .min(256, 'The Import Key must be at least 256 Characters long'),
                                    password: Yup.string()
                                        .nullable()
                                        .min(8, 'the password must be at least 8 characters'),
                                    path: Yup.string()
                                        .nullable()
                                        .min(1, 'Folder names needs at least 1 character')
                                        .max(40, 'We don\'t support more than 40 characters in a folder name'),
                                    port: Yup.number()
                                        .nullable()
                                        .min(0, "Ports must be at least 0.")
                                        .max(65535, 'Ports must be at most 65535'),
                                    fieldMappingId: Yup.string()
                                        .required('A mapping is required to handle importing contacts'),
                                    reportEmail: Yup.string()
                                        .email('Invalid email address')
                                }
                            )}
                            onSubmit={async values => {
                                //dynamodb doesn't like empty strings turn empty into null
                                try {
                                    let sftpConfig = { ...values, tenant: userContext.tenant, lastCheck: moment().unix() };
                                    for (const key in sftpConfig) {
                                        sftpConfig[key] = sftpConfig[key] || null;
                                    }

                                    console.log(sftpConfig);
                                    if (isNewConnection) {
                                        const newConnection = await API.graphql(graphqlOperation(mutations.createFtpImport, { input: sftpConfig }));
                                        setImports([...imports, newConnection.data.createFtpImport]);
                                        setNewConnection(false);
                                        setImport(emptyImport);
                                        setShowForm(false);
                                    } else {
                                        const updatedConnection = await API.graphql(graphqlOperation(mutations.updateFtpImport, { input: sftpConfig }));
                                        _.remove(imports, ['id', updatedConnection.data.updateFtpImport.id])
                                        setImports([...imports, updatedConnection.data.updateFtpImport]);
                                    }
                                    enqueueSnackbar('Save Successful.')

                                } catch (err) {
                                    enqueueSnackbar('Save Failed. ' + err);
                                }

                            }}
                        >
                            {formikprops => (
                                <form onSubmit={formikprops.handleSubmit}>
                                    <Formbody type="import" formikprops={formikprops} />
                                </form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>}
                <Grid item>
                    <MaterialTable
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Host', field: 'host' },
                            { title: 'path', field: 'path' }
                        ]}
                        data={imports}
                        title="SFTP Imports"
                        options={{
                            actionsColumnIndex: -1,
                            exportButton: false,
                            pageSize: 5
                        }}
                        actions={[
                            {
                                icon: () => (<Edit color="primary" />),
                                tooltip: 'Edit Import Connection',
                                onClick: (event, rowData) => {
                                    let connection = { ...rowData };
                                    delete connection.createdAt;
                                    delete connection.updatedAt;
                                    delete connection.tableData;
                                    setNewConnection(false);
                                    setImport(null);
                                    setImport(connection);
                                    setShowForm(true);
                                }
                            },
                            {
                                icon: () => (<Delete color="primary" />),
                                tooltip: 'Delete Import Connection',
                                onClick: (event, rowData) => {
                                    deleteFtpImport(rowData);
                                }
                            }
                        ]}
                    ></MaterialTable>
                </Grid>
            </Grid>
        </div>
    );

    const exportSFTP = (
        <div className={classes.tabContainer}>
            <Formik
                initialValues={exportSettings}
                enableReinitialize={true}
                validationSchema={Yup.object(
                    {
                        ftpExportURL: Yup.string()
                            .min(5, 'The Import URL must be 5 Characters long')
                            .max(80, 'The Import URL can be a maximum of 80 Characters long')
                            .required('You must provide a connection URL'),
                        ftpExportUserName: Yup.string()
                            .required('You must provide a user name'),
                        ftpExportPassword: Yup.string()
                            .min(8, 'the password must be at least 8 characters'),
                        ftpImportKey: Yup.string()
                            .min(256, 'The Import Key must be at least 256 Characters long'),
                        ftpExportPort: Yup.number()
                            .min(0, "Ports must be at least 0.")
                            .max(65535, 'Ports must be at most 65535'),
                    }
                )}
                onSubmit={async values => {
                    //dynamodb doesn't like empty strings turn empty into null
                    try {
                        let sftpConfig = { id: tenant, ftpImport: JSON.stringify({ ...values, lastCheck: moment().unix() }) };
                        for (const key in sftpConfig) {
                            sftpConfig[key] = sftpConfig[key] || null;
                        }
                        console.log(sftpConfig);
                        await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: sftpConfig }));
                        enqueueSnackbar('Save Successful.')

                    } catch (err) {
                        enqueueSnackbar('Save Failed. ' + err);
                    }

                }}
            >
                {formikprops => (
                    <form onSubmit={formikprops.handleSubmit}>
                        <Formbody type="export" formikprops={formikprops} />
                    </form>
                )}
            </Formik>
        </div>
    )

    return (
        <Grid container direction="column" justifyContent="center" alignItems="stretch" className={classes.grid}>
            <Paper className={classes.paper} elevation={2}>
                <Grid container direction="row" item justifyContent="space-between">
                    <Grid container direction="row" item justifyContent="flex-start">
                        <Grid item>
                            <Tabs value={currentTab} onChange={handleTabChange} aria-label="Contact and Account tabs">
                                <Tab label="SFTP Import" id="ftpImport-tab-0" />
                                <Tab label="SFTP Export" id="ftpExport-tab-1" />
                            </Tabs>
                        </Grid>

                    </Grid>
                </Grid>
                <div hidden={currentTab !== 0} >
                    {importSFTP}
                </div>
                <div hidden={currentTab !== 1} className={classes.framewrapper}>
                    {exportSFTP}
                </div>
            </Paper>
            <ConfirmDialog
                open={confirmOpen}
                value={toDelete}
                onConfirm={handleDelete}
                onCancel={cancelDelete}
                confirmTxt="Yes">
                <Typography>Your connection will be permamently deleted.</Typography>
            </ConfirmDialog>
        </Grid>
    );
}
import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField, FormControl, InputLabel, Select, MenuItem, Card, CardContent, CardActions, Typography } from '@material-ui/core';
import SettingsBackupRestoreOutlined from '@material-ui/icons/SettingsBackupRestoreOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router-dom';
import * as request from 'superagent';
import RefundDialog from './RefundDialog';
import PreviewTransactionDialog from './PreviewTransactionDialog';
import RefundHistoryDialog from './RefundHistoryDialog';
import MaterialTable from 'material-table';
import RefundAllDialog from './RefundAllDialog';
import {useSnackbar} from 'notistack';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    topPad: {
        paddingTop: '15px'
    },
    successfulPayment: {
        color: 'green'
    },
    paymentDeclined: {
        color: 'red'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

}));

/**
 * A form that presents a contacts informtion in a Read Only Optimized format
 * 
 */
const MaxRefunds = function (props) {
    const classes = useStyles();
    const [tenant, setTenant] = useState();
    const [agentId, setAgentId] = useState();
    const [vendor, setVendor] = useState();
    const [c3ApiKey, setC3ApiKey] = useState();
    const [cardHolder, setCardHolder] = useState('');
    const [cardType, setCardType] = useState('');
    const [transactionAmount, setTransactionAmount] = useState();
    const [transactionId, setTransactionId] = useState();
    const [processor, setProcessor] = useState();
    const [refundDialog, setRefundDialog] = useState(false);
    const [maxAmount, setMaxAmount] = useState();
    const [transactionDialog, setTransactionDialog] = useState();
    const [refundHistoryDialog, setRefundHistoryDialog] = useState();
    const [refunds, setRefunds] = useState();
    const [searching, setSearching] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [refundAll, setRefundAll] = useState();
    const [selected, setSelected] = useState([]);

    const [transactions, setTransactions] = useState([]);
    const [transaction, setTransaction] = useState(); // This is used to display the transaction preview

    

    useEffect(() => {
        setTenant(props.tenant);
        setAgentId(props.agentId);
        setVendor(props.vendorId);
        setC3ApiKey(props.c3ApiKey);
        if (props.vendorId && props.c3ApiKey) {
            getVendor(props.vendorId, props.c3ApiKey)
        }
    }, [props.vendorId, props.tenant, props.agentId, props.c3ApiKey])

    useEffect(() => {
        if (props.contact){
            let cardholderName = `${props.contact.firstName} ${props.contact.lastName}` 
            setCardHolder(cardholderName);
        }
    }, [props.contact]);

    async function getVendor(id, apiKey) {
        try {
            const result = await axios.get(`${process.env.REACT_APP_PP_URL}/vendor/${id}/gateway`,
            {
                headers: {
                    'x-api-key': apiKey
                }
            }
            ).catch(err => {
                enqueueSnackbar("Unable to get vendor", { variant: 'error' });
                console.log(err);
            });

            if(result && result.data && result.data.gateway){
                console.log('processor: ', result.data.gateway)
                setProcessor(result.data.gateway);
            }
        } catch (err) {
            console.log(err);
        }
    }


    async function handleSearch() {
        setSearching(true);
        setTransactions([]);
        const url = `${process.env.REACT_APP_PP_URL}/refunds/getTransactions`;
        console.log(vendor);
        console.log(cardHolder);
        const params = {
            vendorId: vendor,
            cardHolder: cardHolder
        }
        console.log(params);
        if (transactionAmount) {
            params.transactionAmount = transactionAmount;
        }
        if (cardType) {
            params.cardType = cardType
        }
        try {
            let response;
            do {
                response = await request.get(url)
                    .set("x-api-key", c3ApiKey)
                    .type("json")
                    .query(params);
                setTransactions(prevValue => { return [...prevValue, ...response.body.msg] });
                params.nextToken = response.body.nextToken;
            } while (response.body.nextToken)
            setSearching(false);
        } catch (err) {
            console.log('Error querying transactions ', err);
            setSearching(false);
            setTransactions([])
        }

    }

    async function handleClear(){
        setTransactions([]);
        setCardHolder('');
        setCardType('');
        setTransactionAmount('');
    }

    const handleCardHolder = (event) => {
        setCardHolder(event.target.value);
    }

    const handleCardType = (event) => {
        setCardType(event.target.value);
    }

    const handleTransactionAmount = (event) => {
        setTransactionAmount(event.target.value);
    }

    const launchRefundDialog = (rowData) => {
        setTransactionId(rowData.id);
        setRefundDialog(true);
        setMaxAmount(rowData.transactionAmount);
    }

    /** Arrow functions to show and close transaction dialog */
    const launchTransactionDialog = (rowData) => {
        console.log(rowData);
        setTransactionDialog(true);
    }
    const closeTransactionDialog = () => {
        setTransactionDialog(false);
    }

    /**Arrow functions to show and close refund dialog */
    const launchRefundHistoryDialog = () => {
        setRefundHistoryDialog(true);
    }
    const closeRefundHistoryDialog = () => {
        setRefundHistoryDialog(false);
    }


    const cancelRefund = () => {
        setRefundDialog(false);
    }

    /** Arrow functions to refund all */
    const launchRefundAll = () => {
        setRefundAll(true);
    }
    const closeRefundAll = () => {
        setRefundAll(false);
    }


    return (
        <div>
            <Card className={classes.root}>
                {/* <CardActionArea> */}
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h2" color="primary">
                            C3 Customer Refunds
                        </Typography>
                        <Typography variant="body3" color="textSecondary" component="p">
                            Refund Previous C3 Customer Purchases
                        </Typography>
                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                            <Grid item container direction="column" justifyContent="flex-start" spacing={1}>
                                <Grid container item direction="row">
                                    <TextField
                                        label="Card Holder"
                                        value={cardHolder}
                                        required={true}
                                        onChange={handleCardHolder}
                                        helperText={!cardHolder && "Card Holder is required"}
                                        error={!cardHolder}
                                    />
                                </Grid>
                                {(processor === 'Zift' || processor === 'Nexio') && <Grid container item direction="row">
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="cardtype-label">Card Type</InputLabel>
                                        <Select
                                            labelId="cardtype-label"
                                            value={cardType}
                                            onChange={handleCardType}>
                                            {/* {cardtypes.map(cardtype =>
                                                <MenuItem key={cardtype.cardName} value={cardtype.cardName}>{cardtype.cardDisplay}</MenuItem>
                                            )} */}
                                            <MenuItem key="Visa" value="VISA">Visa credit card</MenuItem>
                                            <MenuItem key="Mastercard" value="MASTERCARD">MasterCard credit card</MenuItem>
                                            <MenuItem key="Amex" value="AMEX">AmEx credit card</MenuItem>
                                            <MenuItem key="Discover" value="DISCOVER">Discover credit card</MenuItem>
                                            <MenuItem key="Other" value="OTHER">Other card types</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>}
                                <Grid container item direction="row">
                                    <TextField
                                        label="Transaction Amount"
                                        type="number"
                                        onChange={handleTransactionAmount}
                                        value={transactionAmount}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                {/* </CardActionArea> */}
                <CardActions>
                    <div className={classes.wrapper}>
                        <Button onClick={handleSearch} color="primary" variant="contained">Search</Button>
                        {searching && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <Button onClick={handleClear} color="primary" variant="outlined">Clear</Button>
                </CardActions>
            </Card>
            

            <Divider variant="inset" component="li" />
            <div>
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                    <MaterialTable
                        columns={[
                            { title: 'Card Holder', field: 'cardHolder' },
                            { title: 'Card Type', field: 'cardType' },
                            { title: 'Card Number', field: 'cardNumber' },
                            { title: 'Transaction Amount', field: 'transactionAmount' }
                        ]}
                        data={transactions}
                        title="Transaction Search Results"
                        options={{
                            actionsColumnIndex: -1,
                            exportButton: false,
                            pageSize: 10,
                            selection: true,
                            selectionProps: rowData => ({
                                disabled: rowData.refunds.items.length > 0,
                                color: 'primary'
                              }),
                            showSelectAllCheckbox: false
                        }}
                        onSelectionChange={(rows) => {
                            setSelected(rows);
                        }}
                        actions={[
                            {
                                icon: () => (<SettingsBackupRestoreOutlined color="primary" />),
                                tooltip: 'Refund Transaction',
                                onClick: (event, rowData) => {
                                    console.log('Event',event);
                                    console.log("RowData: ", rowData);
                                    launchRefundDialog(rowData);
                                },
                                position: 'row'
                            },
                            {
                                icon: () => (<VisibilityOutlinedIcon color="primary"/>),
                                tooltip: 'View Transaction',
                                onClick: (event, rowData) => {
                                    setTransaction(rowData);
                                    launchTransactionDialog(rowData);
                                }, 
                                position: 'row'
                            },
                            {
                                //icon: () => (<AttachMoneyOutlinedIcon color={rowData.refunds.items.length === 0 ? 'disabled': "primary"}/>),
                                icon: () => (<AttachMoneyOutlinedIcon color="primary"/>),
                                tooltip: 'View Previous Refunds',
                                onClick: (event, rowData) => {
                                    if(rowData.refunds.items.length > 0){
                                        setRefunds(rowData.refunds);
                                        launchRefundHistoryDialog(rowData);
                                    }
                                    else{
                                        // This can either be a snackbar or we could just display an empty screen
                                        enqueueSnackbar("This transaction has not been refunded", {variant: 'error'});
                                    }
                                }
                                , 
                                position: 'row'
                            },
                            {
                                icon: () => (<LoopOutlinedIcon color="primary"/>),
                                tooltip: 'Refund All Selected Transactions',
                                onClick: () => {
                                    launchRefundAll();
                                }
                            }
                        ]}
                        
                    />
                </Grid>

            </div>
            {refundDialog &&
                <RefundDialog
                    open={refundDialog}
                    transactionId={transactionId}
                    vendorId={vendor}
                    maxAmount={maxAmount}
                    agentId={agentId}
                    c3ApiKey={c3ApiKey}
                    onCancel={cancelRefund}
                    refresh={handleSearch} // This should be changed, while it works I'm sure there is a much more efficient way of doing it. (Popping up refund icon after refund has gone through)
                />
            }
            {transactionDialog &&
                <PreviewTransactionDialog
                open={transactionDialog}
                close={closeTransactionDialog}
                transaction={transaction}
                vendorId={tenant}
                />
            }
            {refundHistoryDialog &&
                <RefundHistoryDialog
                open={refundHistoryDialog}
                close={closeRefundHistoryDialog}
                refunds={refunds}
                />
            }
            {refundAll &&
                <RefundAllDialog 
                open={refundAll}
                onCancel={closeRefundAll}
                transactions={selected}
                c3ApiKey={c3ApiKey}
                refresh={handleSearch}
                />
            }


        </div>


    )
}

export default withRouter(MaxRefunds);
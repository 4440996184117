import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Onboarding from './Onboarding';
import OnboardingList from './OnboardingList';
import AppPreview from './AppPreview';

export default function OnboardingRoute() {
    return (
        <Switch>
            <Route path='/admin/onboarding/' exact>
                <OnboardingList />
            </Route>
            <Route path='/admin/onboarding/edit/:vendorId'>
                <Onboarding />
            </Route>
            <Route path='/admin/onboarding/app/:url'>
                <AppPreview />
            </Route>
        </Switch>
    );
}
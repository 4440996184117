import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ContactRO from '../pages/ContactRO';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			zIndex: theme.zIndex.drawer + 1,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	title: {
		flexGrow: 1,
		padding: theme.spacing(1),
	}
}));

/**
 * This is the component in charge of laying out the screen pop interface and giving
 * slots for other components to sit in.
 * @category Layouts
 * @component
 * @param {any} props The React props object passed into components.
 */
function ScreenPop(props) {
	const classes = useStyles();
	/**
	 * return function for rendering HTML
	 */
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<ContactRO />
			</div>
		</div>
	);
}

ScreenPop.propTypes = {
	container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element)
};

export default ScreenPop;
import React from 'react';
import { AccordionSummary, Typography, AccordionDetails, Accordion, Grid, Paper} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DynamAppConx from '../components/DynamAppConx';


export default function InventorySource(props) {


    return (
        <Grid container direction="column">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="inventory-control"
                    id="inventory-control-summary"
                >
                    <Typography>ReachCX Inventory Control</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item>
                            Manage Your contacts through our system.
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="dynamics-crm"
                    id="dynamics-crm-summary"
                >
                    <Typography>Dynamics CRM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item>
                            <Paper>    
                                <DynamAppConx />
                            </Paper>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}
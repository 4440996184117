import React, { useEffect, useState, useContext, useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, TextField, IconButton, Tooltip, makeStyles, TableBody, Grid, Typography, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import twilioLogo from '../assets/twilio-logo-red.png';
import textelLogo from '../assets/textel-1200px-logo.png';
import mmsLogo from '../assets/mms-logo.png';
import userContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import theme from '../theme.js';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(({
    images: {
        maxWidth: '150px'
    },
    longTextField: {
        minWidth: '350px',
        maxWidth: '350px'
    },
}));

/**
 * NOTE: this component is entirely generic except for where the label ****Provider specific goes here**** appears.
 * There is also a region where provider specific verification methods go. When adding providers in the future, 
 * you need to include provider specific code for each spot where the label appears as well as a verification method.
 * I am hoping that we won't need to overhaul this UI but we may have to if this component grows out of control 
 * as we try to keep it generic. 
 */
export default function ThirdPartySMSConfig() {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const tenant = useContext(userContext);
    const [loading, setLoading] = useState(false);
    const rawConfigData = useRef([]);
    const [providers, setProviders] = useState({ // ****Provider specific goes here****
        Twilio: {
            logosrc: twilioLogo,
            name: "Twilio",
            usernameLabel: "Account SID",
            passwordLabel: "Auth Token",
            username: "",
            password: "",
            verifyFunction: verifyTwilio,
            block: false,
            verifying: false,
            saving: false,
            verified: false,
            dirty: false,
            saved: false
        },
        Textel: {
            logosrc: textelLogo,
            name: "Textel",
            usernameLabel: "Bearer Token",
            passwordLabel: "",
            username: "",
            password: "",
            verifyFunction: verifyTextel,
            block: false,
            verifying: false,
            saving: false,
            verified: false,
            dirty: false,
            saved: false
        },
        TextelFoundation: {
            logosrc: textelLogo,
            name: "TextelFoundation",
            usernameLabel: "Bearer Token",
            passwordLabel: "",
            username: "",
            password: "",
            verifyFunction: verifyTextel,
            block: false,
            verifying: false,
            saving: false,
            verified: false,
            dirty: false,
            saved: false
        },
        MMS: {
            logosrc: mmsLogo,
            name: "MMS",
            usernameLabel: "App ID",
            passwordLabel: "",
            username: "",
            password: "",
            verifyFunction: verifyTextel,
            block: false,
            verifying: false,
            saving: false,
            verified: false,
            dirty: false,
            saved: false
        }
    });

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: tenant.tenant }));
            if (settings?.data?.getTenantSettings?.icSMSProviderCredentials?.length > 0) {
                rawConfigData.current = settings.data.getTenantSettings.icSMSProviderCredentials;
                for (const info of settings.data.getTenantSettings.icSMSProviderCredentials) {
                    switch (info.provider) {
                        case "Twilio": // ****Provider specific goes here****
                            const twilioData = JSON.parse(info.data);
                            providers.Twilio.username = twilioData.accountSid;
                            providers.Twilio.password = twilioData.authToken;
                            providers.Twilio.verified = providers.Twilio.block = info.verified;
                            providers.Twilio.saved = true;
                            break;

                        case "Textel":
                            const textelData = JSON.parse(info.data);
                            providers.Textel.username = textelData.bearerToken;
                            providers.Textel.verified = providers.Textel.block = info.verified;
                            providers.Textel.saved = true;
                            break;
                        case "TextelFoundation":
                            const textelFData = JSON.parse(info.data);
                            providers.TextelFoundation.username = textelFData.bearerToken;
                            providers.TextelFoundation.verified = providers.TextelFoundation.block = info.verified;
                            providers.TextelFoundation.saved = true;
                            break;
                        case "MMS":
                            const mmsData = JSON.parse(info.data);
                            providers.MMS.username = mmsData.appId;
                            providers.MMS.verified = providers.MMS.block = info.verified;
                            providers.MMS.saved = true;
                            break;
                        default:
                            break;
                    }
                }

                setProviders({ ...providers });
            }

            setLoading(false);
        }

        if (tenant?.tenant) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant.tenant]);

    //#region Provider specific verification functions

    async function verifyTwilio() {
        await axios.get("https://accounts.twilio.com/v1/Credentials/PublicKeys", {
            headers: {
                Authorization: `Basic ${btoa(`${providers.Twilio.username}:${providers.Twilio.password}`)}`
            }
        });
    }

    async function verifyTextel() {
        // TODO: Find something we can use to test the bearer token.
    }

    //#endregion

    async function performVerification(providerName, verificationMethod) {
        providers[providerName].dirty = providers[providerName].block = providers[providerName].verifying = true;
        providers[providerName].verified = false;

        setProviders({ ...providers });

        try {
            await verificationMethod();

            providers[providerName].verifying = false;
            providers[providerName].verified = true;
            setProviders({ ...providers });

            enqueueSnackbar(`${providerName} login succeeded!`, { variant: 'success' });
        } catch (err) {
            providers[providerName].block = false;
            enqueueSnackbar(`${providerName} login failed.`, { variant: 'error' });
        }

        providers[providerName].verifying = false;
        setProviders({ ...providers });
    }

    async function save(provider) {
        provider.saving = true;
        setProviders({ ...providers });

        try {
            let configData = {};

            switch (provider.name) {
                // ****Provider specific goes here****
                case "Twilio":
                    configData = {
                        provider: "Twilio",
                        verified: provider.verified,
                        // This 'data' key can contain whatever is needed for this provider.
                        data: JSON.stringify({ accountSid: providers.Twilio.username, authToken: providers.Twilio.password })
                    };
                    break;
                case "Textel":
                    configData = {
                        provider: "Textel",
                        verified: provider.verified,
                        // This 'data' key can contain whatever is needed for this provider.
                        data: JSON.stringify({ bearerToken: providers.Textel.username })
                    };
                    break;
                case "TextelFoundation":
                    configData = {
                        provider: "TextelFoundation",
                        verified: provider.verified,
                        // This 'data' key can contain whatever is needed for this provider.
                        data: JSON.stringify({ bearerToken: providers.TextelFoundation.username })
                    };
                    break;
                case "MMS":
                    configData = {
                        provider: "MMS",
                        verified: provider.verified,
                        // This 'data' key can contain whatever is needed for this provider.
                        data: JSON.stringify({ appId: providers.MMS.appId })
                    }
                default:
                    break;
            }

            const indexOfProviderInRaw = rawConfigData.current.findIndex(x => x.provider === provider.name);

            if (indexOfProviderInRaw !== -1) {
                rawConfigData.current[indexOfProviderInRaw] = configData;
            } else {
                rawConfigData.current.push(configData);
            }

            try {
                await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: { icSMSProviderCredentials: rawConfigData.current, id: tenant.tenant } }));
                provider.dirty = false;
                enqueueSnackbar("Provider info saved!", { variant: 'success' });

                if (!configData.verified) {
                    // Nullify any verified mappings 
                    const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: tenant.tenant }));
                    if (settings?.data?.getTenantSettings?.icSMSMapping?.mappings?.length > 0) {
                        let nullifiedCount = 0;
                        for (const mapping of settings.data.getTenantSettings.icSMSMapping.mappings) {
                            if (mapping.provider === provider.name && mapping.verified) {
                                mapping.verified = false;
                                nullifiedCount++;
                            }
                        }

                        if (nullifiedCount > 0) {
                            await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: { icSMSMapping: settings.data.getTenantSettings.icSMSMapping, id: tenant.tenant } }));
                            enqueueSnackbar(`Warning: ${nullifiedCount} SMS mappings are no longer valid.`, { variant: 'warning' });
                        }
                    }
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar("Warning: some SMS mappings may now be invalid but could not be marked as such.", { variant: 'warning' });
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar("Unable to save provider info", { variant: 'error' });
        }

        provider.saving = false;
        setProviders({ ...providers });
    }

    function updateField(provider, fieldString, newValue) {
        provider.dirty = true;
        provider[fieldString] = newValue;
        setProviders({ ...providers });
    }

    function enableEditForProvider(provider) {
        provider.block = false;
        provider.verified = false;
        setProviders({ ...providers });
    }

    function getStatusText(provider) {
        if (provider.verifying) {
            return "Verifying";
        }
        if (!provider.saved && !provider.verified) {
            return 'Not configured';
        }
        if (provider.verified && !provider.dirty) {
            return "Verified, Saved";
        }
        if (provider.verified && provider.dirty) {
            return "Verified, Unsaved"
        }
        if (!provider.verified && !provider.dirty) {
            return "Pending verification, Saved";
        }

        return "Pending verification, Unsaved";
    }

    return (
        <React.Fragment>
            {loading &&
                <Grid container direction='column' spacing={4} alignContent='center' alignItems='center'>
                    <Grid item>
                        <Typography variant='h6'>Retrieving data. Please wait...</Typography>
                    </Grid>
                    <Grid item>
                        <CircularProgress variant='indeterminate' color='primary' />
                    </Grid>
                </Grid>
            }
            {!loading &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Provider</TableCell>
                                <TableCell>Credential 1</TableCell>
                                <TableCell>Credential 2</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(providers).map((provider, index) =>
                                <TableRow key={index}>
                                    <TableCell>
                                        <img src={provider.logosrc} alt={provider.name} className={classes.images} />
                                        {provider.name === 'TextelFoundation' &&
                                            <>Foundation</>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {provider.usernameLabel &&
                                            <TextField
                                                label={provider.usernameLabel}
                                                value={provider.username}
                                                variant='outlined'
                                                color='primary'
                                                type='text'
                                                disabled={provider.block}
                                                className={classes.longTextField}
                                                onChange={event => updateField(provider, "username", event.target.value)} />}
                                        {!provider.usernameLabel && 'Not required'}
                                    </TableCell>
                                    <TableCell>
                                        {provider.passwordLabel &&
                                            <TextField
                                                label={provider.passwordLabel}
                                                value={provider.password}
                                                variant='outlined'
                                                color='primary'
                                                type='password'
                                                disabled={provider.block}
                                                className={classes.longTextField}
                                                onChange={event => updateField(provider, "password", event.target.value)} />}
                                        {!provider.passwordLabel && 'Not required'}
                                    </TableCell>
                                    <TableCell>
                                        <Grid container direction='row' spacing={1} alignContent='center' alignItems='center'>
                                            <Grid item>
                                                <Typography variant='body1'>{`${getStatusText(provider)}`}</Typography>
                                            </Grid>
                                            {provider.verified && !provider.dirty &&
                                                <Grid item>
                                                    <CheckCircleIcon style={{ color: theme.palette.success.main }} />
                                                </Grid>}
                                            {provider.verifying &&
                                                <CircularProgress size={60} variant='indeterminate' color='primary' />
                                            }
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container direction='row' spacing={1} alignContent='center' alignItems='center'>
                                            <Grid item>
                                                <Tooltip title='Verify Credentials'>
                                                    <IconButton onClick={() => performVerification(provider.name, provider.verifyFunction)}>
                                                        <VerifiedUserIcon color='primary' fontSize='large' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title='Edit Credentials'>
                                                    <IconButton onClick={() => enableEditForProvider(provider)}>
                                                        <EditIcon color='primary' fontSize='large' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                {!provider.saving &&
                                                    <Tooltip title='Save Information'>
                                                        <IconButton onClick={() => save(provider)}>
                                                            <SaveIcon color='primary' fontSize='large' />
                                                        </IconButton>
                                                    </Tooltip>}
                                                {provider.saving &&
                                                    <CircularProgress variant='indeterminate' color='primary' />
                                                }

                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>}
        </React.Fragment>
    );
}
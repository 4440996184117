import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, IconButton, Tooltip } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        height: '300px'
    }
}));

export default function Actions(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [noMatch, setNoMatch] = useState('');
    const [singleMatch, setSingleMatch] = useState('');
    const [multiMatch, setMultiMatch] = useState('');
    const [searchType, setSearchType] = useState(false);

    useEffect(() => {
        async function getData(){
            setNoMatch(props.noMatch);
            setSingleMatch(props.singleMatch);
            setMultiMatch(props.multiMatch);
        }
        if(userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant])

    const handleNoMatch = (event) => {
        setNoMatch(event.target.value);
        props.setNoMatch(event.target.value);
    }

    const handleSingleMatch = (event) => {
        setSingleMatch(event.target.value);
        props.setSingleMatch(event.target.value);
    }
    
    const handleMultiMatch = (event) => {
        setMultiMatch(event.target.value);
        props.setMultiMatch(event.target.value);
    }

    const handleSearchTypeChange = (event) => {
        setSearchType(!searchType);
        props.setSearchType(!searchType);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid item container direction="row" spacing={4}>
                        <Grid item spacing={4}>
                            <Typography className={classes.heading}>No Matching Records</Typography>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="noPop">Pop</InputLabel>
                                    <Select
                                        labelId="noPop"
                                        name="noPop"
                                        value={noMatch}
                                        displayEmpty
                                        className={classes.selectFields}
                                        onChange={handleNoMatch}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value="search">Search</MenuItem>
                                        <MenuItem value="newAccount">New Account</MenuItem>
                                        <MenuItem value="newContact">New Contact</MenuItem>
                                        <MenuItem value="newLead">New Lead</MenuItem>
                                        <MenuItem value="newCase">New Case</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item spacing={4}>
                            <Typography className={classes.heading}>Single Matching Record</Typography>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="singlePop">Pop</InputLabel>
                                    <Select
                                        labelId="singlePop"
                                        name="singlePop"
                                        value={singleMatch}
                                        displayEmpty
                                        className={classes.selectFields}
                                        onChange={handleSingleMatch}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value="details">Details</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item  spacing={4}>
                            <Typography className={classes.heading}>Multi Matching Record</Typography>
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="multiPop">Pop</InputLabel>
                                    <Select
                                        labelId="multiPop"
                                        name="multiPop"
                                        value={multiMatch}
                                        displayEmpty
                                        className={classes.selectFields}
                                        onChange={handleMultiMatch}
                                    >
                                        <MenuItem value="none">None</MenuItem>
                                        <MenuItem value="search">Search</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="searchType"
                                        checked={searchType}
                                        onChange={handleSearchTypeChange}
                                        value="false"
                                        color="primary" />
                                }
                                label="Use Dynamics Relevance Search"
                            />
                             <Grid item>
                                    <Tooltip
                                        placement="top-start"
                                        leaveDelay={500}
                                        title={
                                            <React.Fragment>
                                                <Typography variant="body1">
                                                    <strong>Use Dynamics Relevance Search</strong>
                                                    <br></br>
                                                    If you would like to use Dynamics Relevance Search instead of Cateogrized Search set Use Dynamics Relevance Search to true
                                                </Typography>
                                            </React.Fragment>
                                        }>
                                        <IconButton size="small"><HelpOutlineOutlined color="primary" /></IconButton>
                                    </Tooltip>
                                </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DragIndicator from '@material-ui/icons/DragIndicator';
import Edit from '@material-ui/icons/EditOutlined';
import PieChart from '../visualizations/PieChart';
import BarChart from '../visualizations/BarChart';
import HorBarChart from '../visualizations/HorBarChart';
import RadarChart from '../visualizations/RadarChart';
import BubbleChart from '../visualizations/BubbleChart';
import LineChart from '../visualizations/LineChart';
import StaticReport from '../visualizations/StaticReport';
import ChartDialog from '../components/ChartDialog';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import UserContext from '../context/UserContext';
import * as moment from 'moment';
import { Divider, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    formControl: {
        minWidth: 150,
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    paper: {
        padding: '2em',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    framewrapper: {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
        flex: 1
    },
    respiframe: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        flex: 1,
        border: 0,
    },
    select: {
        maxHeight: 40,
        minWidth: 150
    },
    inputLabel: {
        fontSize: '12pt',
 
    }
});

/**
 * A form for editing the contact's information. 
 * It displays the contact's details as well as a 
 * tab for the account information a contact is tied to.
 * @component
 */
const Dashboard = function (props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    /** @var {number} ContactTabForm~currentTab Zero indexed tab number */
    const [currentTab, setCurrentTab] = useState(0);
    const [edChart, setEdChart] = useState({});
    const [editChartOpen, setEditOpenChart] = useState(false);
    const [dispCharts, setDispCharts] = useState([]);
    const [tenant, setTenant] = useState();
    const [editIndex, setEditIndex] = useState(-1);
    const [skills, setSkills] = useState([]);
    const [agents, setAgents] = useState([]);
    const [agent, setAgent] = useState('');
    const [skill, setSkill] = useState('');
    const [startTime, setStartTime] = useState();
    const [startFilter, setStartFilter] = useState();
    const [endTime, setEndTime] = useState();
    const [endFilter, setEndFilter] = useState();

    /**
     * Load form field data at component start up.
     */
    useEffect(() => {
        // props.pageTitle('Dashboard');
        async function getData() {
            try {
                const idToken = (await Auth.currentSession()).getIdToken();
                setTenant(idToken.payload['custom:tenant']);
                //Read the tenants Array of JSON Charts and Set the dispCharts
                //GraphQl Tenant Settings for the original array
                const dashboard = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: idToken.payload['custom:tenant'] }));
                let tempCharts = dashboard.data.getTenantSettings.e2DashCharts;
                if (tempCharts) {
                    setDispCharts(JSON.parse(tempCharts));
                }

                /** Pull in used skills */
                const curProfiles = await API.graphql(graphqlOperation(queries.listConfigProfiles, { tenant: UserContext.tenant }));
                const usedSkills = [];
                for (const profile of curProfiles.data.listConfigProfiles.items) {
                    const skills = profile.skillIds;
                    for (const skill of skills) {
                        usedSkills.push(skill);
                    }
                }
                setSkills([...usedSkills]);
            } catch (err) {
                console.error(err);
            }
        }
        getData();
        getAgents();
        console.log(startFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getAgents() {
        let tempAgents = [];
        let query = {
            "aggs": {
                "dispo": {
                    "terms": {
                        "field": "agent.keyword"
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "term": {
                                "tenant.keyword": "development"
                            }
                        }

                    ]
                }
            }, "size": 0
        }
        const agentData = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'ciflog',
            action: '_search',
            query: JSON.stringify(query)
        }))
        const queryResult = await JSON.parse(agentData.data.esQuery);
        if (queryResult){
            for (const agent of queryResult.aggregations.dispo.buckets) {
                tempAgents.push(agent);
            }
            setAgents([...tempAgents]);
        }
    }

    /**
       * Handle changing the currentTab state.
       * @param {any} event The click event for the tab
       * @param {any} newValue The new tab index value
       */
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    }

    const addChart = (chart) => {
        let tempCharts = [...dispCharts];
        tempCharts.push(chart);
        setDispCharts(tempCharts);
        updateTenantCharts(tempCharts);
    }
    const onDeleteChart = pos => () => {
        let tempCharts = [...dispCharts];
        tempCharts.splice(pos, 1);
        setDispCharts(tempCharts);
        updateTenantCharts(tempCharts);
    }

    const onEditChart = (chart, index) => () => {
        //alert("editing chart " + chart.chart);
        setEdChart({ ...chart });
        setEditOpenChart(true);
        setEditIndex(index);

    }
    const updateChart = (chart) => {
        let tempCharts = [...dispCharts];
        tempCharts[editIndex] = chart;
        setDispCharts(tempCharts);
        updateTenantCharts(tempCharts);
    }

    const updateTenantCharts = async (charts) => {
        const fixCharts = [...charts];
        for (const key in fixCharts) {
            fixCharts[key] = fixCharts[key] || null;
        }
        const updtCharts = JSON.stringify(fixCharts);
        await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: { id: tenant, e2DashCharts: updtCharts } }));
    }

    const handleAgent = (event) => {
        const selAgent = event.target.value;
        setAgent(selAgent);
    }

    const handleSkill = (event) => {
        const selSkill = event.target.value;
        setSkill(selSkill);
    }

    const handleStart = (event) => {
        const startTime = moment(event.target.value).format('YYYY-MM-DDTHH:mm');
        if(!endFilter || endTime > startTime){
            setStartFilter(startTime);
            setStartTime(startTime);
        } else {
            enqueueSnackbar('Start date selected must be before selected end date.', {
                variant: 'error'
              })
        }

    }

    const handleEnd = (event) => {
        const endTime = moment(event.target.value).format('YYYY-MM-DDTHH:mm');
        if(!startFilter || endTime > startTime){
            setEndFilter(endTime);
            setEndTime(endTime);
        } else {
            enqueueSnackbar('End date selected must be after selected start date.', {
                variant: 'error'
              })
        }
 
    }

    function clearDates() {
        setStartFilter();
        setEndFilter();
        setStartTime("mm/dd/yyyy --:-- --");
        setEndTime("mm/dd/yyyy --:-- --");
    }

    const dashboard = (
        <Grid container spacing={2}>
            {
                dispCharts && dispCharts.map((chart, index) => {
                    return (
                        <Grid container direction="column" item xs={12} md={6} key={`chart${index}`}>
                            <Grid container direction="row" item justify="flex-end">
                                <Grid item >
                                    <div onClick={onEditChart(chart, index)}>
                                        <IconButton aria-label="drag" color="primary">
                                            <Edit />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item >
                                    <div onClick={onDeleteChart(index)}>
                                        <IconButton aria-label="drag" color="primary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                                {/* <Grid item >


                                    <IconButton aria-label="drag" onClick={onExport(chart, index)} color="primary">
                                        <GetAppOutlinedIcon />
                                    </IconButton>

                                </Grid> */}
                                <Grid item>
                                    <IconButton aria-label="drag" color="primary">
                                        <DragIndicator />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item >
                                {chart.chart === 'BarChart' &&
                                    <BarChart eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                                {chart.chart === 'PieChart' &&
                                    <PieChart eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                                {chart.chart === 'HorBarChart' &&
                                    <HorBarChart eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                                {chart.chart === 'RadarChart' &&
                                    <RadarChart eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                                {chart.chart === 'BubbleChart' &&
                                    <BubbleChart eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                                {chart.chart === 'LineChart' &&
                                    <LineChart eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                                {chart.chart === 'StaticReport' &&
                                    <StaticReport eventType={chart.eventType} model={chart.model} field={chart.field} agg={chart.agg} title={chart.title} items={chart.items} agent={agent} startTime={startFilter} endTime={endFilter} />
                                }
                            </Grid>
                        </Grid>
                    )
                }
                )}
        </Grid>
    );

    return (
        <Grid container direction="column" justify="center" alignItems="stretch" className={classes.grid}>
            <Paper className={classes.paper} elevation={2}>
                <Grid container direction="row" item justify="space-between">
                    <Grid container direction="row" item justify="flex-start">
                        <Grid item>
                            <Tabs value={currentTab} onChange={handleTabChange} aria-label="Contact and Account tabs">
                                <Tab label="Dashboard Visualizations" id="status-tab-0" />
                                {/* <Tab label="Custom Reports" id="performance-tab-1" /> } */}
                            </Tabs>
                        </Grid>

                    </Grid>
                    <Grid container direction="row" item spacing={2} justify="flex-end" alignItems="center" alignContent="space-around">
                        <Grid item>
                            <TextField
                                margin="dense"
                                name="startDate"
                                label="Filter Start Date"
                                type="datetime-local"
                                autoComplete
                                variant="outlined"
                                value={startTime}
                                onChange={handleStart}
                                InputLabelProps={{
                                    shrink: true
                                }} />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                name="endDate"
                                label="Filter End Date"
                                type="datetime-local"
                                variant="outlined"
                                value={endTime}
                                onChange={handleEnd}
                                InputLabelProps={{
                                    shrink: true
                                }} />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Clear Date Filter">
                                <IconButton onClick={clearDates} aria-label="Clear Date Filter">
                                    <EventBusyOutlinedIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Divider orientation="vertical"></Divider>
                        <Grid item>
                            <FormControl color="primary" variant="outlined" className={classes.formControl}>
                                <InputLabel id="chart-label" className={classes.inputLabel}>Skills</InputLabel>
                                <Select
                                    className={classes.select}
                                    name="skills"
                                    label="Skills"
                                    value={skill}
                                    onChange={handleSkill}
                                >
                                    <MenuItem value="">No Filter</MenuItem>
                                    {skills.map(skill =>
                                        <MenuItem key={skill} value={skill}>{skill}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl variant="outlined" color="primary" className={classes.formControl}>
                                <InputLabel id="chart-label" className={classes.inputLabel}>Agents</InputLabel>
                                <Select
                                    className={classes.select}
                                    name="agents"
                                    label="Agents"
                                    value={agent}
                                    onChange={handleAgent}
                                >
                                    <MenuItem value="">No Filter</MenuItem>
                                    {agents.map(agent =>
                                        <MenuItem key={agent.key} value={agent.key}>{agent.key}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item >
                            <ChartDialog index={'ciflog'} onCreate={addChart} />
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <Divider></Divider>
                <br></br>
                <div hidden={currentTab !== 0} >
                    <Grid container spacing={2}>
                        {dashboard}
                    </Grid>
                </div>
                <div hidden={currentTab !== 1} className={classes.framewrapper}>
                    {/* <Iframe url="https://search-amplify-elasti-1xe4xx5g3kw6j-x23crlvssuidigcdduxqclhpiq.us-west-2.es.amazonaws.com/_plugin/kibana/goto/c5247aca907cd0a07e938521a20fd46f?embed=true"  className={classes.respiframe}/> */}
                </div>
                <div hidden={currentTab !== 2} className={classes.framewrapper}>
                    {/* <Iframe url="https://search-amplify-elasti-1xe4xx5g3kw6j-x23crlvssuidigcdduxqclhpiq.us-west-2.es.amazonaws.com/_plugin/kibana/goto/c592dda41d868532f63b8c2be63315aa?embed=true"  className={classes.respiframe}/> */}
                </div>
                <ChartDialog index={'ciflog'} editChart={edChart} open={editChartOpen} onEdit={updateChart} />
            </Paper>
        </Grid>
    );
}

export default Dashboard;
import React, { useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import { Grid, Typography, Dialog, DialogContent, TablePagination } from '@material-ui/core';
import ConfirmDialog from '../components/ConfirmDialog';
import MaterialTable from 'material-table';
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Review from '../E2/Review';
import Button from '@material-ui/core/Button';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

const ProfilesList = function(props) {
    const [name, setName] = useState('');
    const [org, setOrg] = useState({});
    const [mediaType, setMediaType] = useState('');
    const [profileId, setProfileId] = useState('');
    const [direction, setDirection] = useState('');
    const [skills, setSkills] = useState([]);
    const [multiSearch, setMultiSearch] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [search, setSearch] = useState({});
    const [noMatch, setNoMatch] = useState('');
    const [singleMatch, setSingleMatch] = useState('');
    const [multiMatch, setMultiMatch] = useState('');
    const [masterLoggings, setMasterLoggings] = useState([]);
    const [transactionLoggings, setTransactionLoggings] = useState([]);
    const [postTransactionLoggings, setPostTransactionLoggings] = useState([]);
    const [uiInfo, setUIInfo] = useState({});
    const [usedSkills, setUsedSkills] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState();
    const [data, setData] = useState([]);
    const [openPreview, setOpenPreview] = useState(false);

    useEffect(() => {
        // Filter data from props so that it doesn't include baseConfig
        const data = props.data.filter(item => item.mediaType !== 'baseConfig');
        let usedSkills = [];
        data.forEach((item) => {
          usedSkills = usedSkills.concat(item.skillIds)
        })
        setUsedSkills(usedSkills);
        setData(data);
    }, [props.data]);

    const manageProfile = (path, rowData) => {
        props.history.push(path, {profileData: rowData, comingFrom: 'Edit'});
    }

    const cloneProfile = (path, rowData) => {
        props.history.push(path, {profileData: rowData, comingFrom: 'Clone'});
    }

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    const previewProfile = async (rowData) => {
        async function setData(){
            setUIInfo(JSON.parse(rowData.uiInfo)); // For some reason this should be one of the first things or else the program will break? 
            setProfileId(rowData.id);
            setName(rowData.name);
            setOrg(rowData.org);
            setMediaType(rowData.mediaType);
            setDirection(rowData.direction);
            setSkills(rowData.skillIds);
            setMultiSearch(JSON.parse(rowData.multiSearch));
            setSearch(JSON.parse(rowData.search));
            const searchAction = await JSON.parse((rowData.searchAction));
            setNoMatch(searchAction[0].noMatch);
            setSingleMatch(searchAction[1].singleMatch);
            setMultiMatch(searchAction[2].multiMatch);
            setMasterLoggings(JSON.parse(rowData.activityEntityMap));
            setTransactionLoggings(JSON.parse(rowData.logging));
            setPostTransactionLoggings(JSON.parse(rowData.postLogging));
        }
            if(rowData){
                setData();
                setOpenPreview(true);
            }
    }

    function closePreview(){
        setOpenPreview(false);
    }

    function printProfile(){
        window.print();
    }

    const deleteProfile = (profile) => {
        setConfirmOpen(true);
        setToDelete(profile);
    }

    const cancelDelete = () => {
        setConfirmOpen(false);
        setToDelete({});
    }

    const handleDelete = async (profile) => {
        setConfirmOpen(false);
        setToDelete({});
        try{
            await API.graphql(graphqlOperation(mutations.deleteConfigProfile, {input: { id: profile.id} }));
            enqueueSnackbar('Profile deleted.');
        } catch (err) {
            enqueueSnackbar('Delete unsuccessful. ' + err, {
                variant: 'error'
              });
        }

    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <MaterialTable
                            columns={[
                                { title: 'Name', field: 'name'},
                                { title: 'Organization', field: 'org.name'},
                                { title: 'Direction', field: 'direction'},
                                { title: 'Media Type', field: 'mediaType'},
                            ]}
                            data={data}
                            title=''
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: false,
                                pageSize: 10
                            }}
                            components={{
                                Pagination: licensePaging
                            }}
                            actions={[
                                {
                                    icon: () => (<VisibilityOutlinedIcon color="primary" />),
                                    tooltip: 'Preview Profile',
                                    onClick: (event, rowData) => {
                                        previewProfile(rowData);
                                    }
                                },
                                {
                                    icon: () => (<Edit color="primary" />),
                                    tooltip: 'Edit Profile',
                                    onClick: (event, rowData) => {
                                        manageProfile(`/e2/profiles/`, rowData);
                                    }
                                },
                                {
                                    icon: () => (<ControlPointDuplicate color="primary" />),
                                    tooltip: 'Clone Profile',
                                    onClick: (event, rowData) => {
                                        cloneProfile(`/e2/profiles/`, rowData);
                                    }
                                },
                                {
                                    icon: () => (<DeleteIcon color="primary" />),
                                    tooltip: 'Delete Profile',
                                    onClick: (event, rowData) => {
                                        deleteProfile(rowData);
                                    }
                                }
                                
                            ]}
                        />
                    </Grid>
                    <br></br>
                    <ConfirmDialog
                        open={confirmOpen}
                        value={toDelete}
                        onConfirm={handleDelete}
                        onCancel={cancelDelete}
                        confirmTxt="Yes"
                    >
                         <Typography>Your profile will be permamently deleted.</Typography>   
                    </ConfirmDialog>
                    <Dialog open={openPreview} maxWidth='xl'>
                        <DialogContent>
                            {skills &&  <Review
                                name={name}
                                preview={true}
                                id={profileId}
                                org={org} 
                                mediaType={mediaType} 
                                direction={direction}
                                skills={skills}
                                usedSkills={usedSkills}
                                multiSearch={multiSearch}
                                search={search}
                                noMatch ={noMatch}
                                singleMatch={singleMatch}
                                multiMatch={multiMatch}
                                masterLoggings={masterLoggings}
                                transactionLoggings={transactionLoggings}
                                postTransactionLoggings={postTransactionLoggings}
                                uiInfo={uiInfo}
                                setIsEditing={setIsEditing}
                                >
                            </Review>}
                            <Grid container direction="row" justify="space-between">
                                <Button onClick={closePreview} disabled={isEditing} variant="outlined" color="primary">Close</Button>
                                <Button onClick={printProfile} variant="contained" color="primary">Print</Button>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </UserContext.Consumer>
        
    
    );
}

export default withRouter(ProfilesList);
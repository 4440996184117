import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import DragIndicator from '@material-ui/icons/DragIndicator';
import PieChart from '../visualizations/PieChart';
import BarChart from '../visualizations/BarChart';
import HorBarChart from '../visualizations/HorBarChart';
import RadarChart from '../visualizations/RadarChart';
import BubbleChart from '../visualizations/BubbleChart';
import LineChart from '../visualizations/LineChart';
import StaticReport from '../visualizations/StaticReport';
import * as moment from 'moment';
import { Divider, Tooltip, Typography } from '@material-ui/core';
import { Day } from '@material-ui/pickers';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    framewrapper: {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
        flex: 1
    },
    respiframe: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        flex: 1,
        border: 0,
    },
    select: {
        maxHeight: 37,
    },
    textfield: {
        maxHeight: 36
    }
});

/**
 * A form for editing the contact's information. 
 * It displays the contact's details as well as a 
 * tab for the account information a contact is tied to.
 * @component
 */
const Dashboard = function (props) {

    const classes = useStyles();
    /** @var {number} ContactTabForm~currentTab Zero indexed tab number */
    const [currentTab, setCurrentTab] = useState(0);
    const [dispCharts, setDispCharts] = useState([]);
    const [startTime, setStartTime] = useState();
    const [startFilter, setStartFilter] = useState();
    const [endTime, setEndTime] = useState();
    const [endFilter, setEndFilter] = useState();
    const [days, setDays] = useState('1');

    const tempCharts = [
        {
            chart: 'BarChart',
            model: 'ciflog',
            agg: 'count',
            title: 'E2 Licences',
            eventType: 'Login',
            admin: true
        },
        {
            chart: 'BarChart',
            model: 'activitylog',
            agg: 'count',
            title: 'ReachCX Licences',
            eventType: 'Dial',
            admin: true
        }
    ]

    /**
     * Load form field data at component start up.
     */
    useEffect(() => {
        async function getData() {
            try {
                setDispCharts(tempCharts);

            } catch (err) {
                console.error(err);
            }
        }
        getData();
        console.log(startFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        //Set Start Times
        setStartFilter(moment().subtract(1, "days").startOf('day').format('YYYY-MM-DDTHH:mm'));
        setStartTime(moment().subtract(1, "days").startOf('day').format('YYYY-MM-DDTHH:mm'));
        setEndFilter(moment().startOf('day').format('YYYY-MM-DDTHH:mm'));
    }, []);     
    
    /**
       * Handle changing the currentTab state.
       * @param {any} event The click event for the tab
       * @param {any} newValue The new tab index value
       */
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    }

    const handleStart = (event) => {
        const selStartTime = event.target.value;
        setStartFilter(moment(selStartTime).format('YYYY-MM-DDTHH:mm'));
        setStartTime(moment(selStartTime).format('YYYY-MM-DDTHH:mm'));
        setEnd(days, selStartTime);
    }

    const handleEnd = (event) => {
        const selDays = event.target.value;
        setEnd(selDays, startTime)
    }

    function setEnd(selDays, start){
        setDays(selDays);
        setEndFilter(moment(start).add(selDays, "days").startOf('day').format('YYYY-MM-DDTHH:mm'));
        setEndTime(moment(start).add(selDays, "days").startOf('day').format('LLL'));
    }

    function clearDates() {
        setStartFilter();
        setEndFilter();
        setStartTime("mm/dd/yyyy --:-- --");
        setEndTime("mm/dd/yyyy --:-- --");
    }


    const dashboard = (
        <Grid container spacing={2}>
            {
                dispCharts && dispCharts.map((chart, index) => {
                    return (
                        <Grid container direction="column" item xs={11} key={`chart${index}`}>
                            <Grid container direction="row" item justify="flex-end">
                                <Grid item>
                                    <IconButton aria-label="drag" color="primary">
                                        <DragIndicator />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item >
                                {chart.chart === 'BarChart' &&
                                    <BarChart admin={chart.admin} eventType={chart.eventType} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }
                                {chart.chart === 'PieChart' &&
                                    <PieChart eventType={chart.eventType} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }
                                {chart.chart === 'HorBarChart' &&
                                    <HorBarChart eventType={chart.eventType} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }
                                {chart.chart === 'RadarChart' &&
                                    <RadarChart eventType={chart.eventType} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }
                                {chart.chart === 'BubbleChart' &&
                                    <BubbleChart eventType={chart.eventType} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }
                                {chart.chart === 'LineChart' &&
                                    <LineChart eventType={chart.eventType} field={chart.field} agg={chart.agg} filter={chart.filter} title={chart.title} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }
                                {chart.chart === 'StaticReport' &&
                                    <StaticReport eventType={chart.eventType} field={chart.field} agg={chart.agg} title={chart.title} items={chart.items} model={chart.model} startTime={startFilter} endTime={endFilter}/>
                                }    
                            </Grid>
                        </Grid>
                    )
                }
                )}
        </Grid>
    );





    return (
        <Grid container direction="column" justify="center" alignItems="stretch" className={classes.grid}>
            <Paper className={classes.paper} elevation={2}>
                <Grid container direction="row" item justify="space-between">
                    <Grid container direction="row" item justify="flex-start">
                        <Grid item>
                            <Tabs value={currentTab} onChange={handleTabChange} aria-label="Contact and Account tabs">
                                <Tab label="Static Charts" id="status-tab-0" />
                                {/* <Tab label="Custom Reports" id="performance-tab-1" /> } */}
                            </Tabs>
                        </Grid>
                       
                    </Grid>
                    <Grid container direction="row" item spacing={2} justify="flex-end" alignContent="center" alignItems="flex-end">
                        <Grid item>
                                <TextField
                                    margin="dense"
                                    name="startDate"
                                    className={classes.textfield}
                                    label="Start Date"
                                    type="datetime-local"
                                    value={startTime}
                                    variant="outlined"
                                    onChange={handleStart}
                                    InputLabelProps={{
                                        shrink: true
                                    }} />
                        </Grid>
                        {/* <Grid item>
                            <TextField
                                margin="dense"
                                name="endDate"
                                label="Filter End Date"
                                type="datetime-local"
                                value={endTime}
                                onChange={handleEnd}
                                InputLabelProps={{
                                    shrink: true
                                }} />
                        </Grid> */}
                        <Grid item>
                            <Grid item>
                                <Typography variant="overline">{endTime}</Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                margin="dense"
                                variant="outlined"
                                name="timeRange"
                                value={days}
                                label="Time Elapsed"
                                onChange={handleEnd}
                                defaultValue="1"
                                >
                                    <MenuItem value="1">24 hours</MenuItem>
                                    <MenuItem value="2">48 hours</MenuItem>
                                    <MenuItem value="3">72 hours</MenuItem>
                                    <MenuItem value="7">1 Week</MenuItem>
                                    <MenuItem value="28">4 Weeks</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        {/* <Grid item>
                            <Tooltip title="Clear Date Filter">
                                <IconButton onClick={clearDates} aria-label="Clear Date Filter">
                                    <EventBusyOutlinedIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </Grid> */}
                        {/* <Grid item >
                            <ChartDialog  index={'activitylog'} onCreate={addChart} />
                        </Grid> */}
                    </Grid>
                </Grid>
                <br></br>
                <Divider></Divider>
                <br></br>
                <div hidden={currentTab !== 0} >
                    <Grid container spacing={2}>
                        {dashboard}
                    </Grid>
                </div>
                <div hidden={currentTab !== 1} className={classes.framewrapper}>
                    {/* <Iframe url="https://search-amplify-elasti-1xe4xx5g3kw6j-x23crlvssuidigcdduxqclhpiq.us-west-2.es.amazonaws.com/_plugin/kibana/goto/c5247aca907cd0a07e938521a20fd46f?embed=true"  className={classes.respiframe}/> */}
                </div>
                <div hidden={currentTab !== 2} className={classes.framewrapper}>
                    {/* <Iframe url="https://search-amplify-elasti-1xe4xx5g3kw6j-x23crlvssuidigcdduxqclhpiq.us-west-2.es.amazonaws.com/_plugin/kibana/goto/c592dda41d868532f63b8c2be63315aa?embed=true"  className={classes.respiframe}/> */}
                </div>
                {/* <ChartDialog index={'activitylog'} editChart={edChart} open={editChartOpen} onEdit={updateChart} /> */}
            </Paper>
        </Grid>
    );
}


export default Dashboard;
import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as _ from 'lodash';


/**
 * The List HOC is a wrapper for 'real-time' data lists in the app.
 * @component
 * @param {any} WrappedComponent The component that expects a param of 'data'.
 * @param {string} listQuery The graphql query string to retreive data with.
 * @param {string} updateSubscription The graphql subscription string for update events.
 * @param {string} createSubscription The graphql subscription string for create events.
 * @param {string} deleteSubscription The graphql subscription string for delete events.
 */
const listHOC = function (WrappedComponent, listQuery, updateSubscription, createSubscription, deleteSubscription) {
    // ...and returns another component...
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                data: [],
                target: '',

            };
            this.listProperty = this.props.listProperty;
            this.subscriptionFilter = this.props.subscriptionFilter;
            this.tenant = this.props.tenant;

        }

        /**
         * @ignore
         */
        componentDidMount = async () => {
            // ... that takes care of the subscription...
            try {
                let options = {};
                if (this.props.options) {
                    options = this.props.options
                }
                else {
                    options = {
                        tenant:this.tenant,
                        limit: 1000
                    };
                    if (this.props.filter) {
                        options.filter = this.props.filter;
                    }
                }

                const listData = await API.graphql(graphqlOperation(listQuery, options));
                this.setState({ data: listData.data[this.listProperty].items })

                this.updateSubscription = API.graphql(
                    graphqlOperation(updateSubscription, { tenant: this.tenant })
                ).subscribe({
                    next: (updatedData) => this.handleUpdate(updatedData)
                });

                this.createSubscription = API.graphql(
                    graphqlOperation(createSubscription, { tenant: this.tenant })
                ).subscribe({
                    next: (updatedData) => this.handleCreate(updatedData)
                });

                this.deleteSubscription = API.graphql(
                    graphqlOperation(deleteSubscription, { tenant: this.tenant })
                ).subscribe({
                    next: (updatedData) => this.handleDelete(updatedData)
                });
                
            } catch (err) {
                console.log(err);
            }
        };

        componentWillUnmount() {
            if (this.updateSubscription) {
                this.updateSubscription.unsubscribe();
            }
            if (this.createSubscription) {
                this.createSubscription.unsubscribe();
            }
            if (this.deleteSubscription) {
                this.deleteSubscription.unsubscribe();
            }
        }

        handleDelete(updatedData) {
            this.setState((state) => {
                const data = [...state.data];
                const update = updatedData.value.data[this.props.onDeleteProperty];
                _.remove(data, function (e) {
                    return e.id === update.id;
                });
                return ({
                    data: data
                });
            });

        }

        handleCreate = (updatedData) => {
            const update = updatedData.value.data[this.props.onCreateProperty];
            if (this.subscriptionFilter) {
                if (update[this.subscriptionFilter.name] !== this.subscriptionFilter.value) {
                    return;
                }
            }
            this.setState((state) => {
                const data = [...state.data];
                console.log('Create Property: ', this.props.onCreateProperty);
                console.log('UpdatedDate:', updatedData);
                const update = updatedData.value.data[this.props.onCreateProperty];
                update.tableData = {};
                data.push(update);
                return ({
                    data: data
                });
            });
        };

        handleUpdate = (updatedData) => {
            const update = updatedData.value.data[this.props.onUpdateProperty];
            if (this.subscriptionFilter) {
                if (update[this.subscriptionFilter.name] !== this.subscriptionFilter.value) {
                    return;
                }
            }
            this.setState((state) => {
                const data = [...state.data];
                const update = updatedData.value.data[this.props.onUpdateProperty];
                //console.log(updatedData.value.data[this.props.onUpdateProperty]);
                update.tableData = {};
                const updatedId = update.id;
                var item = _.findIndex(data, ['id', updatedId]);
                data.splice(item, 1, update);
                return ({
                    data: data
                });
            });


        };

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return <WrappedComponent data={this.state.data} {...this.props} />;
        }
    };
}

export default listHOC;
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Card, CardContent, CardHeader, Grid, ListItemIcon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Note from '@material-ui/icons/NoteOutlined';
import Phone from '@material-ui/icons/PhoneEnabledOutlined';
import System from '@material-ui/icons/SettingsSystemDaydreamOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Button from '@material-ui/core/Button';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import PopUpNote from '../components/PopUpNote';
import PopUpContact from '../components/PopUpContact.js';
// import PopUpBalances from '../components/PopUpBalances';
import ManualPayment from '../components/ManualPayment';
import { API } from 'aws-amplify';
import * as customQueries from '../graphql/custom-queries';
import * as moment from 'moment';
import * as jwtDecode from 'jwt-decode';
import * as request from 'superagent';
import awsconfig from '../aws-exports';
import * as graphql from 'graphql';
import gql from 'graphql-tag';
import MUIRichTextEditor from 'mui-rte';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const { print } = graphql;

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    topPad: {
        paddingTop: '15px'
    },
    successfulPayment: {
        color: 'green'
    },
    paymentDeclined: {
        color: 'red'
    }

});

/**
 * A form that presents a contacts informtion in a Read Only Optimized format
 * 
 */
const ContactRO = function (props) {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const [tenant, setTenant] = useState();
    const [skill, setSkill] = useState();
    const [agentId, setAgentId] = useState();
    const [contactId, setContactId] = useState();
    const [authToken, setAuthToken] = useState();
    const [apiKey, setApiKey] = useState();
    const [editContactOpen, setEditContactOpen] = useState(false);
    const [createNoteOpen, setCreateNoteOpen] = useState(false);
    const [openBalances, setOpenBalances] = useState(false);
    const [appSyncClient, setClient] = useState();
    const [openManual, setOpenManual] = useState(false);
    const [activities, setActivities] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [contact, setContact] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactAccountId: '',
        account: {},
        status: '',
        source: '',
        phone: '',
        cell: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        timeZone: '',
        dnc: false,
        customFields: {},
        expireDt: undefined,
        complianceRequired: false,
        preview: false,
        outboundCallerId: '',
        balances: []
    });

    let client

    useEffect(() => {
        async function getData() {
            try {
                // lamda
                const passport = await API.post('cdyxdialer', '/screenpop/verify', {
                    body: {
                        id: id
                    }
                });

                const token = passport.token;
                const tokenInfo = jwtDecode(token);

                setContactId(tokenInfo.contactId);
                setTenant(tokenInfo.tenant);
                setAuthToken(tokenInfo.cognitoToken);
                setSkill(tokenInfo.skillId);
                setAgentId(tokenInfo.agentId);

                //Get the tenant
                const screenPopResponse = await request.post(awsconfig.aws_appsync_graphqlEndpoint)
                    .set('Authorization', tokenInfo.cognitoToken)
                    .send({
                        query: print(gql`${customQueries.screenPop}`),
                        variables: {
                            tenant: tokenInfo.tenant,
                            contactId: tokenInfo.contactId
                        }
                    });
                const tenantCustomFields = screenPopResponse.body.data.getTenantSettings.customFields;
                setApiKey(screenPopResponse.body.data.getTenantSettings.apiKey);
                setCustomFields(tenantCustomFields);
                const contact = screenPopResponse.body.data.getContact;
                if (contact.customFields) {
                    contact.customFields = JSON.parse(contact.customFields);
                } else {
                    contact.customFields = {};
                }
                setContact(contact);
                // Get the activity Log
                const actList = screenPopResponse.body.data.getContactActivity.items;
                const sortedActivities = _.orderBy(actList, ['timestamp'], ['desc']);
                setActivities(sortedActivities);

                // activitylog subscription
            const appSyncClient = new AWSAppSyncClient({
                url: awsconfig.aws_appsync_graphqlEndpoint,
                region: awsconfig.aws_appsync_region,
                auth: {
                    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                    jwtToken: tokenInfo.cognitoToken
                },
                disableOffline: true
            });
    
            client = await appSyncClient.hydrated();
            setClient(client);
            const activitylogSubscription = client.subscribe({ query: gql(customQueries.onActivityLogCreate), variables: {tenant: tokenInfo.tenant} }).subscribe({
                next: data => {
                    updateScreenPop(data.data.onTenantCreateActivityLog, tokenInfo.contactId, sortedActivities);
                  },
                  error: error => {
                    console.warn(error);
                  }
            })
            return () => {
                activitylogSubscription.unsubscribe();
            }

            } catch (err) {
                console.error(err);
                history.push('/')
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function updateScreenPop(data, id, activities){
        console.log(data);
        if(data.type === 'PromptPay' && data.activityLogContactId === id){
            setActivities([data, ...activities]);
            if (data.title === 'PromptPay Opened Event'){
                enqueueSnackbar('Payment link has been opened', { 
                    variant: 'info'
                });
            } else if (data.title === 'PromptPay Attempted Event'){
                const metaData = JSON.parse(data.metaData);
                console.log(metaData);
                if (metaData.transaction){
                    enqueueSnackbar('Payment Successful', { 
                        variant: 'success'
                    });
                } else if (metaData.transactionMeta) {
                    enqueueSnackbar('Payment Declined', { 
                        variant: 'error'
                    });
                }
            }
        }
    }

    const openEditContact = () => {
        setEditContactOpen(true);
    }

    const openCreateNote = () => {
        setCreateNoteOpen(true);
    }

    const handleOpenBalances = () => {
        setOpenBalances(true);
    }

    const handleCloseBalances = () => {
        setOpenBalances(false);
    }

    const cancelCreateNote = () => {
        setCreateNoteOpen(false);
    }
    
    const handleOpenManual = () => {
        setOpenManual(true);
    }

    const handleCloseManual = () => {
        setOpenManual(false);
    }

    const handleUpdatedContact = (contact) => {
        const updatedContact = { ...contact };
        updatedContact.customFields = JSON.parse(updatedContact.customFields);
        setContact(updatedContact);
        setEditContactOpen(false);
        //window.location.reload(false);
    }

    const handleNoteCreated = () => {
        setCreateNoteOpen(false);
        window.location.reload(false);
    }
    
    return (
        <Grid container direction="column" spacing={3} justify="flex-start">
            <Grid item container diretion="row" spacing={1} xs={12} >
                <Paper className={classes.paper} style={{ borderLeft: '3px solid #0277bd' }}>
                    <Grid item container direction="column" justify="center">
                        <Grid item container direction="row" justify="center">
                            <Grid item>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    {`${contact.firstName} ${contact.lastName}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" gutterBottom>
                            <Grid item container direction="row" justify="center">
                                <Grid item>
                                    {contact.address1 && `${contact.address1}`}{contact.address2 && `${contact.address2}`}
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" justify="center">
                                <Grid item>
                                    {contact.city && `${contact.city},`}{contact.state && ` ${contact.state}`}{contact.zip && ` ${contact.zip}`}
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" justify="center">
                                <Grid item>
                                    {contact.phone && <span><strong>Phone</strong>: {contact.phone}</span>} {contact.cell && <span>| <strong>Cell</strong>: {contact.cell}</span>} {contact.email && <span>| <strong>Email</strong>: {contact.email}</span>}
                                </Grid>
                            </Grid>
                        </Typography>
                        <Grid item container direction="row" justify="center" alignItems="center" spacing={2}>
                            {customFields.map(field => (
                                <Grid item key={field.name}>
                                    {field.type !== 'DateTime' &&
                                        <Typography><strong>{`${field.displayName}`}</strong>{`: ${contact.customFields[field.name]}`}</Typography>
                                    }
                                    {field.type === 'DateTime' &&
                                        <Typography><strong>{`${field.displayName}`}</strong>{`: ${moment(contact.customFields[field.name], 'x').format('LLL')}`}</Typography>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item container direction="row" justify="flex-end" className={classes.topPad} spacing={2}>
                            {/* <Grid item size="small" hidden={!contact.balances}>
                                <Button onClick={handleOpenBalances} color="primary" variant="contained">View Balances</Button>
                                <PopUpBalances
                                    tenant={tenant}
                                    contact={contact}
                                    token={authToken}
                                    apiKey={apiKey}
                                    agentId={agentId}
                                    skill={skill}
                                    balances={contact.balances}
                                    firstName={contact.firstName}
                                    lastName={contact.lastName}
                                    customFields={customFields}
                                    open={openBalances}
                                    handleClose={handleCloseBalances} />
                            </Grid> */}
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={handleOpenManual}>New Payment</Button>
                                <Dialog open={openManual} maxWidth='lg'>
                                    <DialogTitle id='manual-payment-title'>Manual Payment</DialogTitle>
                                    <DialogContent>
                                        <ManualPayment
                                            tenant={tenant}
                                            contact={_.cloneDeep(contact)}
                                            token={authToken}
                                            apiKey={apiKey}
                                            agentId={agentId}
                                            contactId={contactId}
                                            skill={skill}
                                            awsClient={appSyncClient}
                                            handleClose={handleCloseManual}
                                            // sessionId={sessionId}
                                            // icContactId={icContactId}
                                            // gettingDTMFStuffs={gettingDTMFStuffs}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                            <Grid item size="small">
                                <Button onClick={openEditContact} color="primary" variant="contained">Edit Contact</Button>
                                <PopUpContact
                                    tenant={tenant}
                                    contact={contact}
                                    token={authToken}
                                    customFields={customFields}
                                    open={editContactOpen}
                                    handleClose={setEditContactOpen}
                                    updateContact={handleUpdatedContact} />
                            </Grid>
                            <Grid item size="small">
                                <Button onClick={openCreateNote} color="primary" variant="outlined">+ Note</Button>
                                <PopUpNote
                                    tenant={tenant}
                                    contactId={contactId}
                                    token={authToken}
                                    open={createNoteOpen}
                                    noteCreated={handleNoteCreated}
                                    cancel={cancelCreateNote} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Paper>
            </Grid>
            <Grid item container diretion="row" spacing={1} xs={12} >
                <Paper className={classes.paper}>
                    <Typography variant="h6" color="primary" gutterBottom>
                        Activities
                </Typography>
                    <List className={classes.root}>
                        {activities.map(activity => { if(activity.metaData) {
                            const metaData = JSON.parse(activity.metaData)
                            return (
                            <ListItem alignItems="flex-start">
                                <ListItemIcon>
                                    {activity.type === 'System' && <System color="primary" />}
                                    {activity.type === 'Dial' && <Phone color="primary" />}
                                    {activity.type === 'Note' && <Note color="primary" />}
                                    {activity.type === 'PromptPay' && !metaData.transaction && !metaData.transactionMeta && <MonetizationOnOutlinedIcon color="primary"/>}
                                    {activity.type === 'PromptPay' && metaData.transaction && <MonetizationOnOutlinedIcon className={classes.successfulPayment}/>}
                                    {activity.type === 'PromptPay' && metaData.transactionMeta && <MonetizationOnOutlinedIcon className={classes.paymentDeclined}/>}
                                </ListItemIcon>
                                <ListItemText
                                    primary={activity.title}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {moment(activity.timestamp).format('LLL')}
                                            </Typography>
                                            <p> {activity.agent && `Agent: ${activity.agent}`}</p>
                                            <p>{activity.disposition && `Disposition: ${activity.disposition}`}</p>
                                            <p>{activity.type === 'Note' && <MUIRichTextEditor readOnly={true} value={activity.body} controls={[]} />}</p>
                                            <p>{activity.type === 'PromptPay' && metaData.transaction && `Paid $${metaData.transaction.transactionAmount}`}</p>
                                            <p>{activity.type === 'PromptPay' && metaData.transaction && `Card Ends in: ${metaData.transaction.cardNumber}`}</p>
                                            <p>{activity.type === 'PromptPay' && metaData.transactionMeta && `Payment Declined`}</p>
                                            <p>{activity.type === 'PromptPay' && metaData.channel && `Sent via ${metaData.channel}`}</p>

                                        </React.Fragment>
                                    }
                                />
                                <Divider variant="inset" component="li" />
                            </ListItem>

                        )

                        }}
                        )}

                    </List>
                </Paper>
            </Grid>

            {/* { <Grid item>
                <ActivityLogHOC
                    tenant={tenant}
                    options={{ activityLogContactId: contactId }}
                    subscriptionFilter={{ name: 'activityLogContactId', value: { contactId } }}
                    listProperty="getContactActivity"
                    onCreateProperty="onTenantCreateActivityLog"
                    onUpdateProperty="onTenantUpdateActivityLog"
                    onDeleteProperty="onTenantDeleteActivityLog" />
            </Grid> } */}
            {/* <Grid item container direction="row" justify="flex-start" space={2} xs={12}>
                <Grid item >
                    <Button onClick={openEditContact} color="primary" variant="contained" size="small">Edit Contact</Button>
                    <ContactDialog contact={contact} editContact={graphqlOperation(mutations.updateContact)} open={editContactOpen} cancel={cancelEditContact} />
                </Grid>
                <Grid item>
                    <Button onClick={openCreateNote} color="primary" size="small" variant="outlined">+ Note</Button>
                    <NoteDialog contactId={contactId} createNote={graphqlOperation(mutations.createActivityLog)} open={createNoteOpen} cancel={cancelCreateNote} />
                </Grid>
            </Grid> */}

        </Grid>
    )
}

export default withRouter(ContactRO);
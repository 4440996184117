/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';

export default function ChatActivityPanel(props)
{
    const [chatActivityPanel, setChatActivityPanel] = useState(false);

    /** Set disposition to value being passed through props */
    useEffect(() => {
        setChatActivityPanel(props.value);
    },[props.value])

    // Handle hold checkbox change
    const handleChatActivityPanel = () => {
        setChatActivityPanel(!chatActivityPanel);
        props.onChange(!chatActivityPanel);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={chatActivityPanel}
                        onChange={handleChatActivityPanel}
                        name="chatActivityPanel"
                        color="primary"
                        />
                    }
                    label="Activity Panel"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission for chat activity panels">
                        <NoteOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>

            </Grid>
            
        </div>
    );
}


import React, { useEffect, useRef, useState } from 'react';
import {Grid, CircularProgress, FormControlLabel, Checkbox, Typography, Link, Button} from '@material-ui/core';
import StepperController from './StepperController';
import {useSnackbar} from 'notistack';
import axios from 'axios';

export default function FinalizeAndEmail(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {stepBack, nextStep, applicationUpdated, setApplicationUpdated, cmsInfo, vendorInfo, vendorStatus, pricingInfo} = props;
    const [pricingInfoCompleteChecked, setPricingInfoCompleteChecked] = useState(false);
    const [applicationGenerated, setApplicationGenerated] = useState(false);
    const updateApplicationButtonRef = useRef();
    const [generatingApplication, setGeneratingApplication] = useState(false);
    const [appURL, setAppURL] = useState("");

    useEffect(() => {
        if(applicationUpdated) {
            setPricingInfoCompleteChecked(true);
            generateURL();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function copyUrlToClipboard() {
        document.addEventListener('copy', sendDataToClipboard);
        document.execCommand('copy');
        document.removeEventListener('copy', sendDataToClipboard);
        enqueueSnackbar('Application link copied to clipboard', { variant: 'success' });
    }
 
    function sendDataToClipboard(event) {
        event.clipboardData.setData('text/plain', appURL);
        event.preventDefault();
    }

    function generateURL() {
        const partnerHash = "658610680cac217db148ddb144efcf3b1115502b296342c314f1363db9192c44";
        const cdyxDecorator = window.location.protocol + "//" + window.location.host + "/admin/onboarding/app/";
        const responseURL = `https://onlineapp.cmsonline.com/par/${partnerHash}/app/${cmsInfo.cmsMerchHash}`;
        setAppURL(cdyxDecorator + encodeURIComponent(responseURL));
        
        setApplicationGenerated(true);
    }

    async function updateApplication() {
        setGeneratingApplication(true);

        try {
            const fields = pricingInfo.data.CondenseFields(false);
            // This is subject to change since field names, minimums, and aggregations are not finalized
            await axios.put(`https://api.cmsonline.com/onlineapp/v1/app/appDetails/Fees/${cmsInfo.cmsMerchId}`, {
                id: cmsInfo.cmsMerchId,
                merchantAppId: cmsInfo.cmsMerchId,
                pricingType: pricingInfo.data.selectedPricingType,
                ...fields.vmdPricing,
                ...fields.fees
            },{
                headers: {
                    Authorization: `Basic ${btoa("dan.greenwell@customerdynamics.com:CUSTDY1cms")}`
                }
            });

            if(Object.keys(fields.amexPricing) === 0) {
                await axios.put(`https://api.cmsonline.com/onlineapp/v1/app/appDetails/AmexFees/${cmsInfo.cmsMerchId}`, {
                        id: cmsInfo.cmsMerchId,
                        merchantAppId: cmsInfo.cmsMerchId,
                        pricingType: pricingInfo.data.selectedPricingType,
                        ...fields.amexPricing
                    },{
                        headers: {
                            Authorization: `Basic ${btoa("dan.greenwell@customerdynamics.com:CUSTDY1cms")}`
                        }
                    }
                );
            }

            generateURL();

            await axios.put(
                `${process.env.REACT_APP_PP_URL}/cms-onboard/update-vendor`, {
                    id: vendorInfo.ppayVendor,
                    status: vendorStatus === "SignUp" ? "ApplicationUpdated" : vendorStatus, // This prevents the application status from going backwards. This is also the last place we check for it because we don't need it later. 
                    cmsInfo: {...cmsInfo, nexioCredentials: cmsInfo.nexioCredentials ? cmsInfo.nexioCredentials : ""}
                },
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_PP_API_KEY
                    }
                }
            );
            
            setApplicationUpdated(true);
            enqueueSnackbar("Application changes saved", { variant: 'success' });
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Error updating application", { variant: "error" });
        }

        setGeneratingApplication(false);
    }

    function advanceStep() {
        if (!pricingInfoCompleteChecked) {
            enqueueSnackbar("Please verify that the pricing information is complete", { variant: "error" });
        } else if (!applicationGenerated) {
            enqueueSnackbar("Please generate a link to the application", { variant: "error" });
        } else {
            nextStep();
        }
    }

    function handleStepBack() {
        enqueueSnackbar("Please note that any changes made to the pricing will not appear in the application until the application is updated.", { variant: "warning", autoHideDuration: 5000 });
        stepBack();
    }

    return (
        <div>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <FormControlLabel control={<Checkbox checked={pricingInfoCompleteChecked} onChange={(event) => setPricingInfoCompleteChecked(event.target.checked)} color="primary"/>} label="I verify that the pricing information is complete" labelPlacement="end"/>
                </Grid>
                <Grid item>
                    <Button disabled={!pricingInfoCompleteChecked || generatingApplication} ref={updateApplicationButtonRef} variant="contained" color="primary" onClick={updateApplication}>Update Application</Button>
                    {generatingApplication && updateApplicationButtonRef.current && 
                    <CircularProgress 
                        style={{position: 'absolute', top: updateApplicationButtonRef.current.offsetTop, left: updateApplicationButtonRef.current.offsetLeft + (updateApplicationButtonRef.current.offsetWidth / 2) - (34 / 2)}} 
                        size={34} 
                        variant='indeterminate' 
                        color='secondary' />}
                </Grid>
                {appURL !== "" && 
                <React.Fragment>
                    <Grid item>
                        <Typography variant="body1" style={{wordWrap: 'break-word', maxWidth: '65vw'}}>
                            <Link href={appURL} target="_blank" rel="noopener">{appURL}</Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' spacing={1} alignItems='center' alignContent='center'>
                            <Grid item>
                                <Typography variant='h6' color='primary'>CMS App Token: </Typography>
                            </Grid>
                            <Grid item style={{paddingTop: '7px'}}>
                                <Typography variant='body1'>{cmsInfo.cmsAppToken}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} direction='row'>
                        <Grid item>
                            <Button disabled={!pricingInfoCompleteChecked} variant="contained" color="primary" href={`mailto:?body=${appURL}`} target="_blank">Email Link</Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={!pricingInfoCompleteChecked} variant="contained" color="primary" onClick={copyUrlToClipboard}>Copy Link</Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={!pricingInfoCompleteChecked} variant="contained" color="primary" href={appURL} target="_blank">Preview</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>}
            </Grid>
            <StepperController onStepForward={advanceStep} onStepBack={handleStepBack} />
        </div>
    );
}
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Typography,
    Grid,
    TablePagination,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate';
import Edit from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
//import * as moment from 'moment';
import ConfirmDialog from './ConfirmDialog';

/**
 * The SegmentList  Implements material table. Expects to be wrapped by listHOC.
 * @component
 * * return (
 *  return <WrappedComponent data={this.state.data} {...this.props} /> 
 * )
 * @param {any} WrappedComponent in this instance this parameter is SegmentList.
 * @param {string} listQuery The graphql query string to retreive data with.
 * @param {string} updateSubscription The graphql subscription string for update events.
 * @param {string} createSubscription The graphql subscription string for create events.
 * @param {string} deleteSubscription The graphql subscription string for delete events.
 */

class SegmentList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmOpen: false,
            toDelete: undefined,
            data: []
        };
        this.classes = props.classes;
        this.confirm = props.confirm;

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data })
        }
    }

    //
    manageSegment = (path) => {
        this.props.history.push(path); 
    }
   
    deleteSegment = (segment) => {
        this.setState({
            confirmOpen: true,
            toDelete: segment
        });
    }

    cancelDelete = () => {
        this.setState({
            confirmOpen: false,
            toDelete: undefined
        });
    }

    handleDelete = async (segment) => {
        this.setState({
            confirmOpen: false,
            toDelete: undefined
        });
        await API.graphql(graphqlOperation(mutations.deleteSegment, { input: { id: segment.id } }));
        console.log('segment deleted');
    }

    licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    
    render() {
        return (
            <div>
                <Grid container direction="column" justify="flex-start" alignItems="stretch">
                    <MaterialTable
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Description', field: 'description' },
                        ]}
                        data={this.state.data}
                        title=""
                        options={{
                            actionsColumnIndex: -1,
                            exportButton: false,
                            pageSize: 10
                        }}
                        components={{
                            Pagination: this.licensePaging
                        }}
                        onRowClick={(event, rowData) => {
                            this.manageSegment(`/segment/${rowData.id}?op=edit`);
                        }}
                        actions={[
                            {
                                icon: () => (<Edit color="primary" />),
                                tooltip: 'Edit Segment',
                                onClick: (event, rowData) => {
                                    this.manageSegment(`/segment/${rowData.id}?op=edit`);
                                }
                            },
                            {
                                icon: () => (<ControlPointDuplicate color="primary" />),
                                tooltip: 'Clone Segment',
                                onClick: (event, rowData) => {
                                    this.manageSegment(`/segment/${rowData.id}?op=clone`);
                                }
                            },
                            {
                                icon: () => (<DeleteIcon color="primary" />),
                                tooltip: 'Delete Segment',
                                onClick: (event, rowData) => {
                                    this.deleteSegment(rowData);
                                }
                            }
                        ]}
                    />
                </Grid>
                <ConfirmDialog
                    open={this.state.confirmOpen}
                    value={this.state.toDelete}
                    onConfirm={this.handleDelete}
                    onCancel={this.cancelDelete}
                    confirmTxt="Yes">
                    <Typography>Your segment will be permamently deleted.</Typography>
                </ConfirmDialog>
                
            </div>
        )
    }

}
export default withRouter(SegmentList);
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { 
    Grid,
    Button,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '450px',
        maxWidth: '450px'
    },
    main: {
        height: '300px'
    }

}));

export default function Online(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);

    useEffect(() => {
        async function getData(){
            console.log(props);
        }
        if (userContext.tenant){
            getData();
        }
    }, [userContext.tenant]);

    const handleOnline = () => {
            props.handleNext(1)
    }

    const handleOnPrem = () => {
            props.handleNext(3);
            props.setOnPrem(true);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography variant="h6">Is your connection online or on prem?</Typography>
                        </Grid>
                        <br></br>
                        <Grid item container direction="row" spacing={2}>
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={handleOnline}>Online</Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="outlined" onClick={handleOnPrem}>On Prem</Button>
                            </Grid>
                        </Grid>
                        <Button></Button>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Paper, makeStyles, TextField, Button, IconButton, Select, MenuItem, LinearProgress } from '@material-ui/core';
import { DeleteOutlined, AddCircleOutline } from '@material-ui/icons';
import { useRouteMatch, useHistory, NavLink } from 'react-router-dom';
import { Formik, FieldArray, getIn } from 'formik';
import UserContext from '../context/UserContext';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as Yup from 'yup';
import { graphqlOperation, API } from 'aws-amplify';
import { useSnackbar } from 'notistack';

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
        return list.length === new Set(list.map(mapper)).size;
    });
});

Yup.addMethod(Yup.array, 'containsRequiredFields', function (message, mapper = a => a) {
    return this.test('containsRequiredFields', message, function(list) {
        const toFields = list.map(mapper);
        const requiredMappings = ['externalId', 'firstName', 'lastName', 'phone'];
        let missingFields = []
        for (let requisite of requiredMappings) {
            if (requisite === 'phone') {
                if (!toFields.includes(requisite) && !toFields.includes('cell')) missingFields.push(requisite)
            } else {
                if (!toFields.includes(requisite)) missingFields.push(requisite)
            }
        }
        console.log(missingFields)
        return missingFields.length === 0
    });
});

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    },
    fromField: {
        minWidth: '200px'
    }
}))

export default function FieldMapping() {
    const classes = useStyles();
    const history = useHistory();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const { params } = useRouteMatch();
    const [loading, setLoading] = useState(false);
    const [fieldsMapping, setFieldsMapping] = useState({
        name: '',
        tenant: userContext.tenant,
        mappings: []

    });
    const defaultMappings = [
        {
            "fromField": "ID",
            "toField": "externalId"
        },
        {
            "fromField": "FirstName",
            "toField": "firstName"
        },
        {
            "fromField": "LastName",
            "toField": "lastName"
        },
        {
            "fromField": "Phone",
            "toField": "phone"
        },
        {
            "fromField": "Cell",
            "toField": "cell"
        },
        {
            "fromField": "City",
            "toField": "city"
        },
        {
            "fromField": "State",
            "toField": "state"
        },
        {
            "fromField": "Zip",
            "toField": "zip"
        },
        {
            "fromField": "TimeZone",
            "toField": "timeZone"
        }
    ];
    const [closeMessage, setCloseMessage] = useState('Cancel');

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const fieldsMappingResponse = await API.graphql(graphqlOperation(queries.getFieldsMapping, { id: params.id }));
            if (fieldsMappingResponse.data) {
                setFieldsMapping(fieldsMappingResponse.data.getFieldsMapping);
                setLoading(false);
            }
        }

        if (params.id === 'new') {
            setFieldsMapping({
                ...fieldsMapping,
                mappings: defaultMappings
            });
        } else {
            setCloseMessage('Close');
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const arrayFieldError = (name, errors, touched) => {
        const errorInfo = getIn(errors, name);
        const touch = getIn(touched, name);
        return touch && errorInfo ? errorInfo : null;
    }

    const ContactFields = (props) => {
        return (
            <Select
                {...props}
                fullWidth={true}
            >
                <MenuItem value="externalId">External Id</MenuItem>
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="source">Source</MenuItem>
                <MenuItem value="cell">Cell</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="address1">Address1</MenuItem>
                <MenuItem value="address2">Address2</MenuItem>
                <MenuItem value="city">City</MenuItem>
                <MenuItem value="state">State</MenuItem>
                <MenuItem value="zip">Zip</MenuItem>
                <MenuItem value="timeZone">Timezone</MenuItem>
                <MenuItem value="expireDt">Expiration Date</MenuItem>
                <MenuItem value="complianceRequired">Compliance Required</MenuItem>
                <MenuItem value="preview">Preview</MenuItem>
                <MenuItem value="outboundCallerId">Outbound Caller ID</MenuItem>
                {userContext.customFields && userContext.customFields.map(customField => (
                    <MenuItem value={`custom:${customField.name}`}>{customField.displayName}</MenuItem>
                ))}
            </Select>
        )
    }

    return (
        <Formik
            initialValues={fieldsMapping}
            validationSchema={Yup.object(
                {
                    name: Yup.string().required('A Field Mapping name is required'),
                    mappings: Yup.array()
                        .of(
                            Yup.object({
                                fromField: Yup.string().required('File header field is required'),
                                toField: Yup.string().required('Contact field is required')
                            })
                        )
                        .unique('Cannot map to the same contact field twice', a => a.toField)
                        .containsRequiredFields('Contact field mappings must contain External ID, First Name, Last Name, and either Phone or Cell', a => a.toField)
                }
            )}
            onSubmit={async (values, formikBag) => {
                let mutation = mutations.updateFieldsMapping;
                const fieldsMapping = { ...values };
                delete fieldsMapping.createdAt;
                delete fieldsMapping.updatedAt;
                if (params.id === 'new') {
                    mutation = mutations.createFieldsMapping;
                    fieldsMapping.tenant = userContext.tenant;
                    delete fieldsMapping.id
                }
                try {
                    const fieldsMappingResult = await API.graphql(graphqlOperation(mutation, { input: fieldsMapping }));

                    formikBag.setSubmitting(false);
                    const savedFieldMapping = fieldsMappingResult.data[params.id === 'new' ? 'createFieldsMapping' : 'updateFieldsMapping'];
                    if (savedFieldMapping.id === params.id) {
                        setFieldsMapping(savedFieldMapping);
                        formikBag.resetForm(savedFieldMapping);
                        enqueueSnackbar('Field Mapping saved');
                    } else {
                        formikBag.resetForm(savedFieldMapping);
                        history.push('./');
                    }
                } catch (err) {
                    console.error(err);
                }
            }}
            enableReinitialize={true}
        >
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid container direction="column" alignItems="stretch" spacing={2}>
                        <Grid container item direction="row" justify="space-between" alignItems="center">
                            <Typography variant="h4">Field Mapping</Typography>
                            <div>
                                <Grid item container direction="row" justify="flex-end" spacing={2}>
                                    <Grid item>
                                        <Button variant="outlined" color="primary" component={NavLink} to="./">{closeMessage}</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit">Save</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {loading &&
                            <Grid container item direction="column" justify="flex-start" alignItems="stretch">
                                <LinearProgress hidden={!loading} variant="query" color="secondary" />
                            </Grid>
                        }
                        <Grid item>
                            <Paper className={classes.paper}>
                                <Grid container direction="column" alignItems="stretch" spacing={2}>
                                    <Grid item>
                                        <TextField
                                            name="name"
                                            autoFocus
                                            label="Name"
                                            type="text"
                                            required={true}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                            value={formikProps.values.name}
                                            error={formikProps.touched.name && formikProps.errors.name}
                                            helperText={formikProps.touched.name && formikProps.errors.name}
                                        />
                                    </Grid>
                                    {typeof formikProps.errors.mappings === 'string' &&
                                        <Grid item>
                                            <Typography variant="body1" color="error">{formikProps.errors.mappings}</Typography>
                                        </Grid>
                                    }
                                    <Grid item container direction="row" alignItems="center" spacing={2}>
                                        <Grid item className={classes.fromField}>
                                            <Typography variant="overline" color="primary">File Header Field</Typography>
                                        </Grid>
                                        <Grid item className={classes.fromField}>
                                            <Typography variant="overline" color="primary">Contact Field</Typography>
                                        </Grid>
                                    </Grid>
                                    <FieldArray
                                        name="mappings"
                                        render={({ push, remove }) => (
                                            <React.Fragment>
                                                {formikProps.values &&
                                                    formikProps.values.mappings.map((mapping, index) => (
                                                        <Grid item container direction="row" alignItems="center" spacing={2}>
                                                            <Grid item className={classes.fromField}>
                                                                <TextField
                                                                    name={`mappings[${index}].fromField`}
                                                                    value={mapping.fromField}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={arrayFieldError(`mappings[${index}].fromField`, formikProps.errors, formikProps.touched)}
                                                                    helperText={arrayFieldError(`mappings[${index}].fromField`, formikProps.errors, formikProps.touched)} />
                                                            </Grid>
                                                            <Grid item className={classes.fromField}>
                                                                <ContactFields
                                                                    name={`mappings[${index}].toField`}
                                                                    value={mapping.toField}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={arrayFieldError(`mappings[${index}].toField`, formikProps.errors, formikProps.touched)}
                                                                    helperText={arrayFieldError(`mappings[${index}].toField`, formikProps.errors, formikProps.touched)} />
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() => remove(index)}>
                                                                    <DeleteOutlined />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<AddCircleOutline />}
                                                        onClick={() => push({ fromField: '', toField: '' })}
                                                    >
                                                        Add Mapping
                                                    </Button>
                                                </Grid>
                                            </React.Fragment>
                                        )} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}
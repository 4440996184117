import { API } from 'aws-amplify';
// import * as Msal from 'msal';
import * as msal from '@azure/msal-browser';

export const discoEndpoint = 'https://globaldisco.crm.dynamics.com/api/discovery/v2.0/Instances?$filter=State eq 0 and IsUserSysAdmin eq true&$orderby=FriendlyName';
export const apiEndpointPart = '/api/data/v';
export const entitiesEndpoint = '/EntityDefinitions?$select=DisplayName,LogicalName,SchemaName,MetadataId&$filter=IsActivity eq true';
export const attributesEndpointStart = '/EntityDefinitions(';
export const attributesEndpointEnd = ')/Attributes?$select=AttributeType,LogicalName,SchemaName,DisplayName,AttributeTypeName&'
export const searchEntitiesEndpoint = 'EntityDefinitions?$select=MetadataId,SchemaName'

let clientId;
let clientSecret;
let onPremAccessUrl;
let onPremAuthUrl;
let authProvider;
let authType;
let apiKey;

let selectedAccount;

export async function setCustClientId(importClientId, importAuthType, importClientSecret, importAuthUrl, importApiKey, importAccessUrl){
  try{
    clientId = importClientId;
    authType = importAuthType;
    clientSecret = importClientSecret;
    onPremAuthUrl = importAuthUrl;
    apiKey = importApiKey;
    onPremAccessUrl = importAccessUrl;

    const msalConfig = {
      auth: {
          clientId: clientId,
          authority: "https://login.microsoftonline.com/common", 
          redirectUri: window.location.origin,
        }
    };

    // authProvider = new Msal.UserAgentApplication(msalConfig);
    authProvider = new msal.PublicClientApplication(msalConfig);
    return true;
  } catch (err){
    console.error(err);
    return false;
  }
}

export async function getAllAccounts(){
    await signIn();
    return authProvider.getAllAccounts();
}

export function setSelectedAccount(account){
  selectedAccount = account;
}

export async function callDynamicsDisco(endpoint, token) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };
    try {
      const response = await fetch(endpoint, options);
      return await response.json();
    } catch (err){
      console.error('Call Dynamics Discovery Error', err);
    }
}

async function callDynamics(org, apiEnpointPart, endpoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  const finalEndpoint = org.Url + apiEnpointPart + org.Version.substring(0, 3)+ '/' + endpoint;

  headers.append("Authorization", bearer);
  headers.append("X-Requested-With", "");

  const options = {
      method: "GET",
      headers: headers
  };
  try{
    const response = await fetch(finalEndpoint, options);
    return await response.json();
  } catch (err){
     console.error('Call Dynamics', err);
  }

}

async function callDynamicsPost(org, apiEndpointPart, endpoint, body, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  const finalEndpoint = org.Url + apiEndpointPart + org.Version.substring(0, 3) + endpoint;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
  };
  try{
    const response = await fetch(finalEndpoint, options);
    return await response.status;
  } catch (err){
    console.error('Call Dynamics Post Error', err);
  }

  
}

export async function signIn() {
  try {
    const loginResponse = await authProvider.loginPopup({redirectUri: window.location.origin});
    return loginResponse;
  } catch (err) {
    console.error('Sign In Error', err);
  }
}

export async function getOrgs() {
  try{
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await signIn();
      console.log('Response', response);
    }
    if (authProvider.getAccountByUsername(selectedAccount.username)) {
        const response =  await getTokenPopup({account:selectedAccount, scopes: ["https://globaldisco.crm.dynamics.com/user_impersonation"]});
        const orgs = (await callDynamicsDisco(discoEndpoint, response.accessToken)).value;
        return orgs;
    }
  } catch(err) {
    console.error('Get Orgs', err);
  }
}

export async function getEntities(selectedOrg) {
  try {
      let token;
      if (authType !== 'Manual Auth'){
        if (!authProvider.getAccountByUsername(selectedAccount.username)){
          const response = await signIn();
         }
         if (authProvider.getAccountByUsername(selectedAccount.username)) {
           const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`]});
           token = response.accessToken;
         }
      } else {
          token = await getOnPremToken();
      }
        return (await callDynamics(selectedOrg, apiEndpointPart, entitiesEndpoint, token)).value;
  } catch (err) {
      console.error('Get Entities', err);
  }
}

export async function getSearchMetaDataId (selectedOrg, selSearchEntity) {
   try{
    let token;
    if (authType !== 'Manual Auth'){
      if (!authProvider.getAccountByUsername(selectedAccount.username)){
        const response = await signIn();
       }
       if (authProvider.getAccountByUsername(selectedAccount.username)) {
         const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`]});
         token = response.accessToken;
       }
    } else {
        token = await getOnPremToken();
    }
      return (await callDynamics(selectedOrg, apiEndpointPart, `EntityDefinitions?$select=MetadataId,SchemaName&$filter=SchemaName eq '${selSearchEntity}'`, token)).value;

  } catch (err) {
    console.error('Get Search Meta Data Id Error', err);
  }
}

export async function getSearchEntities (selectedOrg) {
  try{
    let token;
    if (authType !== 'Manual Auth'){
      if (!authProvider.getAccountByUsername(selectedAccount.username)){
        const response = await signIn();
       }
       if (authProvider.getAccountByUsername(selectedAccount.username)) {
         const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`]});
         token = response.accessToken;
       }
    } else {
        token = await getOnPremToken();
    }
      return (await callDynamics(selectedOrg, apiEndpointPart, searchEntitiesEndpoint, token)).value;
  } catch (err) {
    console.error('Get Search Entities', err);
  }
}

export async function getSearchFields(selectedOrg, entityId) {
  try{
    let token;
    if (authType !== 'Manual Auth'){
      if (!authProvider.getAccountByUsername(selectedAccount.username)){
        const response = await signIn();
       }
       if (authProvider.getAccountByUsername(selectedAccount.username)) {
         const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`]});
         token = response.accessToken;
       }
    } else {
        token = await getOnPremToken();
    }
      return (await callDynamics(selectedOrg, apiEndpointPart, attributesEndpointStart+entityId+attributesEndpointEnd, token)).value;

  } catch (err) {
    console.error('Get Search Fields Error', err);
  }
  
}

export async function getFields(selectedOrg, entity) {
  try {
    let token;
    if (authType !== 'Manual Auth'){
      if (!authProvider.getAccountByUsername(selectedAccount.username)){
        const response = await signIn();
       }
       if (authProvider.getAccountByUsername(selectedAccount.username)) {
         const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`]});
         token = response.accessToken;
       }
    } else {
        token = await getOnPremToken();
    }
      return (await callDynamics(selectedOrg, apiEndpointPart, attributesEndpointStart+entity.MetadataId+attributesEndpointEnd, token)).value;
  
  } catch (err) {
    console.error('Get Field Error', err);
  }
  
}

export async function getRoleAndAdmin(selectedOrg) {
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamics(selectedOrg, apiEndpointPart, "/roles?$select=name,roleid&$filter=name eq 'E2 Contact Center Service' or name eq 'System Administrator'&$orderby=name asc", response.accessToken)).value;
    }
  } catch (err) {
    console.error('Get Role and Admin Error', err);
  }
}

export async function getAppUser(selectedOrg) {
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamics(selectedOrg, apiEndpointPart, `/systemusers?$filter=applicationid eq '${clientId}'`, response.accessToken)).value;
    }
  }catch (err) {
    console.error('Get App User Error', err);
  }
}

export async function getBaseBU(selectedOrg) {
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamics(selectedOrg, apiEndpointPart, "/businessunits?$filter=_parentbusinessunitid_value eq null", response.accessToken)).value;
    }
  } catch (err) {
    console.error('Get Base BU Error', err);
  }
}

export async function whoAmI(selectedOrg) {
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      const dynamicsResponse = await callDynamics(selectedOrg, apiEndpointPart, "/WhoAmI", response.accessToken);
      return dynamicsResponse.UserId;
    }
  } catch (err) {
    console.error('Who Am I ', err);
  }
}

export async function createAppUser(selectedOrg, businessUnits) {
  const body = {
    "applicationid":"a53e8cfb-e738-4fee-81fa-359f180d150c",
    "domainname":"support.e2@customerdynamics.com",
    "internalemailaddress":"support.e2@customerdynamics.com",
    "userlicensetype":"3",
    "lastname":"E2 App User",
    "firstname":"CDyx",
    "businessunitid@odata.bind":`/businessunits(${businessUnits})`
}
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamicsPost(selectedOrg, apiEndpointPart, "/systemusers", body, response.accessToken));
    }
  } catch (err) {
    console.error('Create App User Error', err);
  }
}

export async function assignRole(selectedOrg, userId, e2RoleId) {
  const body = {
    "@odata.id":`${selectedOrg.Url + apiEndpointPart + selectedOrg.Version.substring(0, 3)}/roles(${e2RoleId})`
}
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamicsPost(selectedOrg, apiEndpointPart, `/systemusers(${userId})/systemuserroles_association`, body, response.accessToken));
    }
  } catch (err) {
    console.error('Assign Role Error', err);
  }
}

export async function getCifChannel(selectedOrg) {
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamics(selectedOrg, apiEndpointPart, "/msdyn_ciproviders?$filter=msdyn_name eq 'Contact Center E2'", response.accessToken)).value;
    }
  } catch (err) {
    console.error('Get Cif Channel Error', err);
  }
}

export async function createCifChannel(selectedOrg, systemAdminRoleId, systemUserId, tenant, username, password, orgApiKey){

  const customParams = `${tenant}|${selectedOrg.Id}|${username}|${password}|${orgApiKey}`;

  const body = {
    "msdyn_ciproviderapiversion":"0",
    "msdyn_sortorder":"1",
    "msdyn_landingurl":`https://essentials.customerdynamics.com/?ucilib=${selectedOrg.Url}`,
    "msdyn_ciproviderid":`${systemUserId}`,
    "msdyn_clicktoact":"True",
    "msdyn_label":"Contact Center E2 Agent",
    "msdyn_name":"Contact Center E2",
    "organizationid@odata.bind":`/organizations(${selectedOrg.Id})`,
    "msdyn_roleselector":`${systemAdminRoleId}`,
    "msdyn_trusteddomain":"https://essentials.customerdynamics.com/",
    "msdyn_customparams":`${Buffer.from(customParams).toString('base64')}`
  }
  try {
    if (!authProvider.getAccountByUsername(selectedAccount.username)){
      await signIn();
    }
    if(authProvider.getAccountByUsername(selectedAccount.username)){
      const response = await getTokenPopup({account:selectedAccount, scopes: [`${selectedOrg.Url}/user_impersonation`] });
      return (await callDynamicsPost(selectedOrg, apiEndpointPart, `/msdyn_ciproviders`, body, response.accessToken));
    }
  } catch (err) {
    console.error('Create CIf Channel Error', err);
  }
}

async function msalAuth(){

}

async function getOnPremToken(){
  const tokenCall = await API.post(
    'cdyxdialer',
    '/crm/dynamics/conx-test',{
        headers: {
            'x-api-key': apiKey
        },
        body: {
            dynamicsTenant: '',
            clientId: clientId,
            appSecret: clientSecret,
            altUrl: onPremAuthUrl,
            resource: onPremAccessUrl

        }
    }
  );
  const tokenText = JSON.parse(tokenCall.text);
  return tokenText.access_token;
}

async function getTokenPopup(request) {
  try{
    const token = await authProvider.acquireTokenSilent(request);
    return token;
  } catch (err){
    if (err.name === 'InteractionRequiredAuthError'){
      return await authProvider.acquireTokenPopup(request);
    } else {
      console.error(err);
    }
  }
}
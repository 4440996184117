import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { Button, Card, CardActionArea, CardActions, CardContent } from '@material-ui/core';
import {
    GetAppOutlined
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    nostyle: {
        textDecoration: 'none !important'
    },
}));

export default function Downloads() {
    const userContext = useContext(UserContext);
    const classes = useStyles();
    useEffect(() => {

    }, []);



    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Grid container spacing={3} alignItems="" direction="column">
                        <Grid item>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            ReachCX Dynamics Solution
                                    </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Dynamics solution to supports the entity import integration for Customer Dynamics ReachCX and Safe Select
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button variant="contained" color="primary" startIcon={<GetAppOutlined />} href="https://e2dynamsolution.s3-us-west-2.amazonaws.com/OutReach_Latest.zip" download>
                                            Download
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            e2 Dynamics Solution
                                    </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Dynamics solution that supports the implementation of the e2 agent CIF client by creating components, roles, and the automation of phone number standardization.
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button variant="contained" color="primary" startIcon={<GetAppOutlined />} href="https://e2dynamsolution.s3-us-west-2.amazonaws.com/ContactCenterE2_Latest.zip" download>
                                        Download
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import DynamConxAdmin from '../components/DynamConxAdmin';
import DynamConxAdminOptions from '../components/DynamConxAdminOptions';
import Online from '../components/DynamOnline';
import DynamConxManual from '../components/DynamConxManual';
import UserContext from '../context/UserContext';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as subscriptions from '../graphql/subscriptions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const DynamConxStepper = function(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [onPrem, setOnPrem] = useState(false);
  const steps = getSteps();
  let admin;

  useEffect(()=> {
    const tenantSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateTenantSettings))
      .subscribe({
          next: (tenantData) => updateTenantConnection(tenantData)
      });
    return () => {
      tenantSubscription.unsubscribe();
    }
  },[userContext.getStepContent]);

  function updateTenantConnection(tenantData){
    if (tenantData.value.data.onUpdateTenantSettings.e2ClientId){
        props.history.push('/config/inventory-source');
        enqueueSnackbar('Successfully generated connection information.', {
          autoHideDuration: 5000
      });
    } else {
        props.history.push('/config/inventory-source');
        enqueueSnackbar('Looks like there was a problem with the admin sign in. Please validate user has Global Admin privileges', {
            variant: 'error',
            autoHideDuration: 5000
        });
    }
}

  function getSteps() {
    return ['Online or On Prem','Global Admin Access', 'Generate or Use Existing Credentials(Admin)', 'Manual Configuration'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (<Online handleNext={handleNext} setOnPrem={setOnPrem}/>);
      case 1:
        return (<DynamConxAdmin handleNext={handleNext}/>);
      case 2:
            return (<DynamConxAdminOptions handleNext={handleNext} />)
      case 3:
        return (<DynamConxManual handleNext={handleNext} onPrem={onPrem}/>);
      default:
        return 'Unknown step';
    }
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(DynamConxStepper)
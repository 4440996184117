import React, { useEffect, useState, useContext } from 'react';
import Central from '../components/Central';
import UserHub from '../components/UserHub';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import Button from '@material-ui/core/Button';
import ThirdPartySMS from '../components/ThirdPartySMS';
import ThirdPartySMSConfig from '../components/ThirdPartySMSConfig';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
}));

export default function TelephonyPanel() {
    const [isLegacy, setProductType] = useState(false);
    const classes = useStyles();
    const [settings] = useState({ icAccessKey: '', icApp: '', icAppSecret: '', icBU: '', icDiscoveryDomain: '', icGetAuthTokenURL: '', icPassword: '', icUserName: '', icVendor: '' });
    const userContext = useContext(UserContext);
    const [nicCluster, setCluster] = useState('');
    const [openConfig, setOpenConfig] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                console.log(userContext.tenant);
                const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
                const tempAll = dynamoSettings.data.getTenantSettings;
                settings.icAccessKey = tempAll.icAccessKey;
                settings.icApp = tempAll.icApp;
                settings.icAppSecret = tempAll.icAppSecret;
                settings.icBU = tempAll.icBU;
                settings.icDiscoveryDomain = tempAll.icDiscoveryDomain;
                settings.icGetAuthTokenURL = tempAll.icGetAuthTokenURL;
                settings.icPassword = tempAll.icPassword;
                settings.icUserName = tempAll.icUserName;
                settings.icVendor = tempAll.icVendor;
                //console.log(settings);

            } catch (err) {
                console.error(err);
            }
        }
        if (userContext.tenant) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const selCluster = (event) => {
        setCluster(event.target.value);
        setOpenConfig(false);
    }
    const selProductType = () =>{
      if (nicCluster === 'Legacy'){
        setProductType(true);
      }  
      else{
          setProductType(false);
      }
      setOpenConfig(true);
    }

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className={classes.heading}>NICE inContact</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} alignItems="center" direction="row">
                            <Grid item>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="cluster-label">Product</InputLabel>
                                    <Select
                                        labelId="cluster-label"
                                        id="cluster"
                                        value={nicCluster}
                                        autoWidth={true}
                                        name="cluster"
                                        onChange={selCluster}
                                    >
                                        <MenuItem value='Legacy'>Legacy</MenuItem>
                                        <MenuItem value='CXOne'>CXOne</MenuItem>
                                        <MenuItem value='Legacy-Global'>Legacy Global</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button onClick={selProductType} color="primary" size="small" variant="outlined">Next</Button>
                            </Grid>
                            <Grid item>
                                {isLegacy && openConfig &&
                                    <Central icCluster={nicCluster}/>
                                }
                                {!isLegacy && openConfig &&
                                    <UserHub icCluster={nicCluster}/>
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {openConfig && 
                <Grid item>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className={classes.heading}>Third Party SMS/WhatsApp Provider Configuration</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ThirdPartySMSConfig />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            {openConfig && 
                <Grid item>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className={classes.heading}>Third Party SMS/WhatsApp</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ThirdPartySMS />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
        </Grid>
    );
}
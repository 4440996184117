import React , {useContext, useState, useEffect} from 'react';
import {Grid, TextField, makeStyles, FormControl, InputLabel, Select, Input, 
    MenuItem, Button, Checkbox, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@material-ui/core';
import { API, Auth} from 'aws-amplify';
import UserContext from '../context/UserContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "185px",
        //maxWidth: "185px"
    },
    dialogDiv: {
        minWidth: '300px',
        //maxWidth: '300px',
        minHeight: '200px',
        textAlign: 'center'
    }
  }));

  const products = [
    {
        name: 'E2',
        id: 349
    },
    {
        name: 'Essentials',
        id: 140
    },
    {
        name: 'Dialer',
        id: 141 
    },
    {
        name: 'OutReach',
        id: 348
    },
    {
        name: 'UMAX',
        id: 145
    }
];

  

export default function CreateTenant(props){
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const licenses = ["OutReach", "Essentials", "PromptPay", "ListManager", "USD", "MaxCIF"]
    const [tenant, setTenant] = useState({tenantId: '', licenses: []});
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(props.open && props.tenant){
            setOpenDialog(true);
            setTenant(props.tenant);
        }
    },[props.tenant, props.open])

    const tenantSchema = Yup.object(
        {
            tenantId: Yup.string().required("Please enter in a Tenant ID").matches(/^[a-z0-9-]*$/, "Tenant ID must contain only lowercase letters, numbers, and dashes").trim(),
            licenses: Yup.array().required("Please select Tenant Licenses").min(1, "You must select at least one license."),
        }
    )

    const handleClick = () => {
        setOpenDialog(!openDialog);
    }

    // <Typography variant="h5">Create Tenant</Typography>
    return(
        <div>
            {!props.tenant && <Button color="primary" variant="contained" onClick={handleClick}>+ Tenant</Button>}
            <Dialog open={openDialog}>
                    {!props.tenant && <DialogTitle textAlign='center' style={{textAlign: 'center'}}>Create Tenant</DialogTitle>}
                    {props.tenant && <DialogTitle textAlign='center' style={{textAlign: 'center'}}>Edit Tenant</DialogTitle>}
                    <DialogContent className={classes.dialogDiv}>
                        <Formik
                            initialValues={tenant}
                            enableReinitialize={true}
                            validationSchema={tenantSchema}
                            onSubmit={async (values, formikBag) => {

                                // Edit tenant instead of creating new one
                                // THIS MIGHT CHANGE WHEN DISABLED TENANT IS ADDED
                                if(props.tenant){
                                    try{
                                        await API.put('cdyxdialer', '/admin/tenant', {
                                            body: {
                                                tenantId: values.tenantId,
                                                licenses: values.licenses
                                            },
                                            headers:{
                                                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                                                'x-api-key': userContext.apiKey,
                                                'Content-Type': 'application/json',
                                            }
                                        });
                                        enqueueSnackbar('Tenant Updated');
                                        formikBag.setSubmitting(false);
                                        formikBag.resetForm();
                                        setOpenDialog(false);
                                        props.update();
                                    }catch(err){
                                        enqueueSnackbar('Tenant could not be updated.. ' + err.message, {variant: 'error'});
                                    }
                                }else{
                                    try{
                                        await API.post("cdyxdialer", "/admin/tenant", {
                                            body: {
                                                tenantId: values.tenantId,
                                                licenses: values.licenses
                                            },
                                            headers:{
                                                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                                                'x-api-key': userContext.apiKey,
                                                'Content-Type': 'application/json',
                                            }
                                        })
                                        enqueueSnackbar('Tenant created.');
                                        formikBag.setSubmitting(false);
                                        formikBag.resetForm();
                                        setOpenDialog(false);
                                        props.update();
                                    }catch(err){
                                        enqueueSnackbar('Tenant could not be created.. ' + err.message, { // Figure out how to display custom messages?
                                            variant: 'error'
                                        });
                                    }
                                }
                            }}
                        >
                            {formikProps => (
                                <form onSubmit={formikProps.handleSubmit} className={classes.root}>
                                    <Grid container direction="column" spacing = {2}>
                                        <Grid item>
                                            <TextField 
                                            margin="dense"
                                            label="Tenant ID" 
                                            name="tenantId"
                                            onChange={formikProps.handleChange}  
                                            onBlur={formikProps.handleBlur}
                                            value={formikProps.values.tenantId} 
                                            error={formikProps.touched.tenantId && formikProps.errors.tenantId}
                                            helperText={formikProps.touched.tenantId && formikProps.errors.tenantId}
                                            style={{maxWidth:'185px'}}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControl className={classes.root}>
                                                <InputLabel id="licensesLabel">Licenses</InputLabel>
                                                <Select multiple
                                                name='licenses'
                                                value={formikProps.values.licenses}
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                error={formikProps.touched.licenses && formikProps.errors.licenses}
                                                helperText={formikProps.touched.licenses && formikProps.errors.licenses}
                                                renderValue={(selected) => selected.join(',')}
                                                style={{textAlign: 'left'}}
                                                >
                                                    {!props.tenant && licenses.map((license) => (
                                                        <MenuItem key={license} value={license}>
                                                            <Checkbox checked={formikProps.values.licenses.indexOf(license) > -1} color="primary" />
                                                            <ListItemText primary={license} />
                                                        </MenuItem>
                                                    ))}
                                                    {props.tenant && licenses.map((license) => (
                                                        <MenuItem key={license} value={license}>
                                                            <Checkbox checked={formikProps.values.licenses.indexOf(license) > -1}
                                                             value={tenant.licenses}color="primary" />
                                                            <ListItemText primary={license} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {props.tenant &&
                                            <Grid item container direction='column'>
                                                <Grid item>
                                                    <Typography variant="h6">
                                                        Billing Overrides
                                                    </Typography>
                                                </Grid>
                                                <Grid item container direction='row' spacing={1}>
                                                    <Grid item container direction='column' spacing={2}>
                                                        <Grid item>
                                                            <Typography variant='h7'>Minimums (coming soon)</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction='column' spacing={2}>
                                                        <Grid item>
                                                        <Typography variant='h7'>Nice Sku (coming soon)</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        <DialogActions>
                                            <Button variant="contained" color="primary" onClick={handleClick}>Cancel</Button>
                                            {props.tenant && <Button variant='contained' color='primary' type='submit' style={{whiteSpace: 'nowrap'}}>Update Tenant</Button>}
                                            {!props.tenant && <Button variant='contained' color='primary' type='submit' style={{whiteSpace: 'nowrap'}}>Create Tenant</Button>}
                                        </DialogActions>
                                        
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </DialogContent>
        </Dialog>
        </div>
        
        
    );
}
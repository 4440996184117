/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onTenantCreateContact = /* GraphQL */ `
  subscription OnTenantCreateContact($tenant: ID!) {
    onTenantCreateContact(tenant: $tenant) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateContact = /* GraphQL */ `
  subscription OnTenantUpdateContact($tenant: ID!) {
    onTenantUpdateContact(tenant: $tenant) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteContact = /* GraphQL */ `
  subscription OnTenantDeleteContact($tenant: ID!) {
    onTenantDeleteContact(tenant: $tenant) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateSegment = /* GraphQL */ `
  subscription OnTenantCreateSegment($tenant: ID!) {
    onTenantCreateSegment(tenant: $tenant) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateSegment = /* GraphQL */ `
  subscription OnTenantUpdateSegment($tenant: ID!) {
    onTenantUpdateSegment(tenant: $tenant) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteSegment = /* GraphQL */ `
  subscription OnTenantDeleteSegment($tenant: ID!) {
    onTenantDeleteSegment(tenant: $tenant) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateActivityLog = /* GraphQL */ `
  subscription OnTenantCreateActivityLog($tenant: ID!) {
    onTenantCreateActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        static
        campaignSchedule
        segmentSchedule
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onTenantUpdateActivityLog = /* GraphQL */ `
  subscription OnTenantUpdateActivityLog($tenant: ID!) {
    onTenantUpdateActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        static
        campaignSchedule
        segmentSchedule
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onTenantDeleteActivityLog = /* GraphQL */ `
  subscription OnTenantDeleteActivityLog($tenant: ID!) {
    onTenantDeleteActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        static
        campaignSchedule
        segmentSchedule
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onTenantCreateTenantSettings = /* GraphQL */ `
  subscription OnTenantCreateTenantSettings($id: ID!) {
    onTenantCreateTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateTenantSettings = /* GraphQL */ `
  subscription OnTenantUpdateTenantSettings($id: ID!) {
    onTenantUpdateTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteTenantSettings = /* GraphQL */ `
  subscription OnTenantDeleteTenantSettings($id: ID!) {
    onTenantDeleteTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateCampaign = /* GraphQL */ `
  subscription OnTenantCreateCampaign($tenant: ID!) {
    onTenantCreateCampaign(tenant: $tenant) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      static
      campaignSchedule
      segmentSchedule
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateCampaign = /* GraphQL */ `
  subscription OnTenantUpdateCampaign($tenant: ID!) {
    onTenantUpdateCampaign(tenant: $tenant) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      static
      campaignSchedule
      segmentSchedule
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteCampaign = /* GraphQL */ `
  subscription OnTenantDeleteCampaign($tenant: ID!) {
    onTenantDeleteCampaign(tenant: $tenant) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      static
      campaignSchedule
      segmentSchedule
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateProfile = /* GraphQL */ `
  subscription OnTenantCreateProfile($tenant: ID!) {
    onTenantCreateProfile(tenant: $tenant) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateProfile = /* GraphQL */ `
  subscription OnTenantUpdateProfile($tenant: ID!) {
    onTenantUpdateProfile(tenant: $tenant) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteProfile = /* GraphQL */ `
  subscription OnTenantDeleteProfile($tenant: ID!) {
    onTenantDeleteProfile(tenant: $tenant) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateDoNotCall = /* GraphQL */ `
  subscription OnTenantCreateDoNotCall($tenant: ID!) {
    onTenantCreateDoNotCall(tenant: $tenant) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateDoNotCall = /* GraphQL */ `
  subscription OnTenantUpdateDoNotCall($tenant: ID!) {
    onTenantUpdateDoNotCall(tenant: $tenant) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteDoNotCall = /* GraphQL */ `
  subscription OnTenantDeleteDoNotCall($tenant: ID!) {
    onTenantDeleteDoNotCall(tenant: $tenant) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateCampaignLog = /* GraphQL */ `
  subscription OnTenantCreateCampaignLog($tenant: ID!) {
    onTenantCreateCampaignLog(tenant: $tenant) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateCampaignLog = /* GraphQL */ `
  subscription OnTenantUpdateCampaignLog($tenant: ID!) {
    onTenantUpdateCampaignLog(tenant: $tenant) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteCampaignLog = /* GraphQL */ `
  subscription OnTenantDeleteCampaignLog($tenant: ID!) {
    onTenantDeleteCampaignLog(tenant: $tenant) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onTenantCreateConfigProfile = /* GraphQL */ `
  subscription OnTenantCreateConfigProfile($tenant: ID!) {
    onTenantCreateConfigProfile(tenant: $tenant) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const onTenantUpdateConfigProfile = /* GraphQL */ `
  subscription OnTenantUpdateConfigProfile($tenant: ID!) {
    onTenantUpdateConfigProfile(tenant: $tenant) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const onTenantDeleteConfigProfile = /* GraphQL */ `
  subscription OnTenantDeleteConfigProfile($tenant: ID!) {
    onTenantDeleteConfigProfile(tenant: $tenant) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSegment = /* GraphQL */ `
  subscription OnCreateSegment {
    onCreateSegment {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSegment = /* GraphQL */ `
  subscription OnUpdateSegment {
    onUpdateSegment {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSegment = /* GraphQL */ `
  subscription OnDeleteSegment {
    onDeleteSegment {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivityLog = /* GraphQL */ `
  subscription OnCreateActivityLog {
    onCreateActivityLog {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        static
        campaignSchedule
        segmentSchedule
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateActivityLog = /* GraphQL */ `
  subscription OnUpdateActivityLog {
    onUpdateActivityLog {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        static
        campaignSchedule
        segmentSchedule
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteActivityLog = /* GraphQL */ `
  subscription OnDeleteActivityLog {
    onDeleteActivityLog {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        static
        campaignSchedule
        segmentSchedule
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateTenantSettings = /* GraphQL */ `
  subscription OnCreateTenantSettings {
    onCreateTenantSettings {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTenantSettings = /* GraphQL */ `
  subscription OnUpdateTenantSettings {
    onUpdateTenantSettings {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTenantSettings = /* GraphQL */ `
  subscription OnDeleteTenantSettings {
    onDeleteTenantSettings {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFtpImport = /* GraphQL */ `
  subscription OnCreateFtpImport {
    onCreateFtpImport {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFtpImport = /* GraphQL */ `
  subscription OnUpdateFtpImport {
    onUpdateFtpImport {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFtpImport = /* GraphQL */ `
  subscription OnDeleteFtpImport {
    onDeleteFtpImport {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaign = /* GraphQL */ `
  subscription OnCreateCampaign {
    onCreateCampaign {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      static
      campaignSchedule
      segmentSchedule
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign {
    onUpdateCampaign {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      static
      campaignSchedule
      segmentSchedule
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaign = /* GraphQL */ `
  subscription OnDeleteCampaign {
    onDeleteCampaign {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      static
      campaignSchedule
      segmentSchedule
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile {
    onCreateProfile {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile {
    onUpdateProfile {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile {
    onDeleteProfile {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDoNotCall = /* GraphQL */ `
  subscription OnCreateDoNotCall {
    onCreateDoNotCall {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDoNotCall = /* GraphQL */ `
  subscription OnUpdateDoNotCall {
    onUpdateDoNotCall {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDoNotCall = /* GraphQL */ `
  subscription OnDeleteDoNotCall {
    onDeleteDoNotCall {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCampaignLog = /* GraphQL */ `
  subscription OnCreateCampaignLog {
    onCreateCampaignLog {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCampaignLog = /* GraphQL */ `
  subscription OnUpdateCampaignLog {
    onUpdateCampaignLog {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCampaignLog = /* GraphQL */ `
  subscription OnDeleteCampaignLog {
    onDeleteCampaignLog {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConfigProfile = /* GraphQL */ `
  subscription OnCreateConfigProfile {
    onCreateConfigProfile {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConfigProfile = /* GraphQL */ `
  subscription OnUpdateConfigProfile {
    onUpdateConfigProfile {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConfigProfile = /* GraphQL */ `
  subscription OnDeleteConfigProfile {
    onDeleteConfigProfile {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePreProcess = /* GraphQL */ `
  subscription OnCreatePreProcess {
    onCreatePreProcess {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePreProcess = /* GraphQL */ `
  subscription OnUpdatePreProcess {
    onUpdatePreProcess {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePreProcess = /* GraphQL */ `
  subscription OnDeletePreProcess {
    onDeletePreProcess {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCifLog = /* GraphQL */ `
  subscription OnCreateCifLog {
    onCreateCIFLog {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCifLog = /* GraphQL */ `
  subscription OnUpdateCifLog {
    onUpdateCIFLog {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCifLog = /* GraphQL */ `
  subscription OnDeleteCifLog {
    onDeleteCIFLog {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUMaxLog = /* GraphQL */ `
  subscription OnCreateUMaxLog {
    onCreateUMaxLog {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUMaxLog = /* GraphQL */ `
  subscription OnUpdateUMaxLog {
    onUpdateUMaxLog {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUMaxLog = /* GraphQL */ `
  subscription OnDeleteUMaxLog {
    onDeleteUMaxLog {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFieldsMapping = /* GraphQL */ `
  subscription OnCreateFieldsMapping {
    onCreateFieldsMapping {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFieldsMapping = /* GraphQL */ `
  subscription OnUpdateFieldsMapping {
    onUpdateFieldsMapping {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFieldsMapping = /* GraphQL */ `
  subscription OnDeleteFieldsMapping {
    onDeleteFieldsMapping {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentProfile = /* GraphQL */ `
  subscription OnCreatePaymentProfile {
    onCreatePaymentProfile {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentProfile = /* GraphQL */ `
  subscription OnUpdatePaymentProfile {
    onUpdatePaymentProfile {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentProfile = /* GraphQL */ `
  subscription OnDeletePaymentProfile {
    onDeletePaymentProfile {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentTemplate = /* GraphQL */ `
  subscription OnCreatePaymentTemplate {
    onCreatePaymentTemplate {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentTemplate = /* GraphQL */ `
  subscription OnUpdatePaymentTemplate {
    onUpdatePaymentTemplate {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentTemplate = /* GraphQL */ `
  subscription OnDeletePaymentTemplate {
    onDeletePaymentTemplate {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWebhook = /* GraphQL */ `
  subscription OnCreateWebhook {
    onCreateWebhook {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWebhook = /* GraphQL */ `
  subscription OnUpdateWebhook {
    onUpdateWebhook {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWebhook = /* GraphQL */ `
  subscription OnDeleteWebhook {
    onDeleteWebhook {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRestConnection = /* GraphQL */ `
  subscription OnCreateRestConnection {
    onCreateRestConnection {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRestConnection = /* GraphQL */ `
  subscription OnUpdateRestConnection {
    onUpdateRestConnection {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRestConnection = /* GraphQL */ `
  subscription OnDeleteRestConnection {
    onDeleteRestConnection {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMobileConfiguration = /* GraphQL */ `
  subscription OnCreateMobileConfiguration {
    onCreateMobileConfiguration {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMobileConfiguration = /* GraphQL */ `
  subscription OnUpdateMobileConfiguration {
    onUpdateMobileConfiguration {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMobileConfiguration = /* GraphQL */ `
  subscription OnDeleteMobileConfiguration {
    onDeleteMobileConfiguration {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDailyLicenseCount = /* GraphQL */ `
  subscription OnCreateDailyLicenseCount {
    onCreateDailyLicenseCount {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDailyLicenseCount = /* GraphQL */ `
  subscription OnUpdateDailyLicenseCount {
    onUpdateDailyLicenseCount {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDailyLicenseCount = /* GraphQL */ `
  subscription OnDeleteDailyLicenseCount {
    onDeleteDailyLicenseCount {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCryptoPassport = /* GraphQL */ `
  subscription OnCreateCryptoPassport {
    onCreateCryptoPassport {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCryptoPassport = /* GraphQL */ `
  subscription OnUpdateCryptoPassport {
    onUpdateCryptoPassport {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCryptoPassport = /* GraphQL */ `
  subscription OnDeleteCryptoPassport {
    onDeleteCryptoPassport {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;

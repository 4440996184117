import React from "react";

import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    FormControlLabel,
    Switch,
    Divider,
    FormHelperText,
    CircularProgress,
} from "@material-ui/core";
import { MessageOutlined } from "@material-ui/icons";
import { CenterToggleChild, CenterToggleContainer, CenterToggleItem } from 'react-center-toggle';


const MessagingOptions = (props) => {
    const {
        classes,
        formikProps,
        availableMessageTemplates,
        emailMessagingSkills,
        smsMessagingSkills,
        loading,
        ivrScripts,
        projects
    } = props;
    return (
        <Card variant="outlined" style={{ marginBottom: "16px" }}>
            <CardHeader
                title="Messaging Options"
                avatar={<MessageOutlined color="primary" />}
                titleTypographyProps={{
                    variant: "h6",
                    style: { marginBottom: "7px" },
                }}
            />
            <CardContent>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <FormControlLabel
                                    control={<Switch
                                        checked={formikProps.values?.channels?.emailEnabled ?? false}
                                        onChange={
                                            formikProps.handleChange
                                        }
                                        name="channels.emailEnabled"
                                        color="primary"
                                        />}
                                    label="Email"
                                    />
                            </Grid>
                            {
                                formikProps.values?.channels?.emailEnabled
                            && (
                                    <Grid item style={{ paddingBottom: "32px" }}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <FormControl
                                                    className={classes.formControl}
                                                >
                                                    <InputLabel>
                                                        Message Template
                                                    </InputLabel>
                                                    <Select
                                                        name="channels.email.messageTemplate"
                                                        required
                                                        className={
                                                            classes.longInput
                                                        }
                                                        value={
                                                            formikProps.values
                                                                ?.channels?.email
                                                                ?.messageTemplate ??
                                                            ""
                                                        }
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                        onChange={
                                                            formikProps.handleChange
                                                        }
                                                        error={
                                                            formikProps.errors
                                                                ?.channels?.email
                                                                ?.messageTemplate &&
                                                            formikProps.touched
                                                                ?.channels?.email
                                                                ?.messageTemplate
                                                        }
                                                        helperText={
                                                            formikProps.touched
                                                                ?.channels?.email
                                                                ?.messageTemplate
                                                                ? formikProps.errors
                                                                    ?.channels
                                                                    ?.email
                                                                    ?.messageTemplate
                                                                : ""
                                                        }
                                                    >
                                                        {availableMessageTemplates.map(
                                                            (template) => (
                                                                <MenuItem
                                                                    key={
                                                                        template.id
                                                                    }
                                                                    value={
                                                                        template.id
                                                                    }
                                                                >
                                                                    {template.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    margin="dense"
                                                    name="channels.email.subject"
                                                    label="Subject"
                                                    type="text"
                                                    className={classes.longInput}
                                                    required
                                                    value={
                                                        formikProps.values?.channels
                                                            ?.email?.subject ?? ""
                                                    }
                                                    onChange={
                                                        formikProps.handleChange
                                                    }
                                                    onBlur={formikProps.handleBlur}
                                                    error={
                                                        formikProps.error?.channels
                                                            ?.email?.subject &&
                                                        formikProps.touched
                                                            ?.channels?.email
                                                            ?.subject
                                                    }
                                                    helperText={
                                                        formikProps.touched
                                                            ?.channels?.email
                                                            ?.subject
                                                            ? formikProps.errors
                                                                ?.channels?.email
                                                                ?.subject
                                                            : ""
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    margin="dense"
                                                    name="channels.email.sendingEmail"
                                                    label="Sending Email"
                                                    type="text"
                                                    className={classes.longInput}
                                                    required
                                                    value={
                                                        formikProps.values?.channels
                                                            ?.email?.sendingEmail ??
                                                        ""
                                                    }
                                                    onChange={
                                                        formikProps.handleChange
                                                    }
                                                    onBlur={formikProps.handleBlur}
                                                    error={
                                                        formikProps.errors?.channels
                                                            ?.email?.sendingEmail &&
                                                        formikProps.touched
                                                            ?.channels?.email
                                                            ?.sendingEmail
                                                    }
                                                    helperText={
                                                        formikProps.touched
                                                            ?.channels?.email
                                                            ?.sendingEmail
                                                            ? formikProps.errors
                                                                ?.channels?.email
                                                                ?.sendingEmail
                                                            : ""
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl
                                                    className={classes.formControl}
                                                >
                                                    <InputLabel>
                                                        Email Skill
                                                    </InputLabel>
                                                    <Select
                                                        margin="dense"
                                                        name="channels.email.emailSkill"
                                                        className={
                                                            classes.longInput
                                                        }
                                                        value={
                                                            formikProps.values
                                                                ?.channels?.email
                                                                ?.emailSkill ?? ""
                                                        }
                                                        onChange={
                                                            formikProps.handleChange
                                                        }
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                        error={formikProps.errors?.channels?.email?.emailSkill && formikProps.touched?.channels?.email?.emailSkill}
                                                        helperText={formikProps.touched?.channels?.email?.emailSkill && formikProps.errors?.channels?.email?.emailSkill}
                                                    >
                                                        {emailMessagingSkills.map(
                                                            (skill) => (
                                                                <MenuItem
                                                                    key={
                                                                        skill.skillId
                                                                    }
                                                                    value={
                                                                        skill.skillId
                                                                    }
                                                                >
                                                                    {
                                                                        skill.skillName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <CenterToggleContainer toggled={loading.scripts}>
                                                    <CenterToggleChild>
                                                        <FormControl disabled={loading.scripts}>
                                                            <InputLabel error={formikProps.errors.channels?.email?.emailIVRScript && formikProps.touched?.channels?.email?.emailIVRScript}>Email IVR Script</InputLabel>
                                                            <Select
                                                                margin="dense"
                                                                name="channels.email.emailIVRScript"
                                                                className={classes.field}
                                                                value={formikProps.values?.channels?.email?.emailIVRScript ?? ""}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                error={formikProps.errors.channels?.email?.emailIVRScript && formikProps.touched?.channels?.email?.emailIVRScript}
                                                            >
                                                                {ivrScripts.map(script =>
                                                                    <MenuItem key={script.scriptId} value={script.scriptName}>{script.scriptName}</MenuItem>)}
                                                            </Select>
                                                            {formikProps.touched.channels?.email?.emailIVRScript && formikProps.errors.channels?.email?.emailIVRScript &&
                                                                <FormHelperText error>{formikProps.errors.channels?.email?.emailIVRScript}</FormHelperText>}
                                                        </FormControl>
                                                    </CenterToggleChild>
                                                    <CenterToggleItem>
                                                        <CircularProgress variant='indeterminate' color='primary' />
                                                    </CenterToggleItem>
                                                </CenterToggleContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <FormControlLabel
                                    label="SMS"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={
                                                formikProps.values?.channels
                                                    ?.smsEnabled ?? false
                                            }
                                            onChange={formikProps.handleChange}
                                            name="channels.smsEnabled"
                                        />
                                    }
                                />
                            </Grid>
                            {Boolean(
                                formikProps.values?.channels?.smsEnabled
                            ) && (
                                    <Grid item style={{ paddingBottom: "32px" }}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item>
                                                <FormControl
                                                    error={formikProps.errors?.channels?.sms?.providerName && formikProps.touched?.channels?.sms?.providerName}
                                                    className={classes.formControl}
                                                >
                                                    <InputLabel>
                                                        SMS Provider
                                                    </InputLabel>
                                                    <Select
                                                        margin="dense"
                                                        name="channels.sms.providerName"
                                                        value={
                                                            formikProps.values
                                                                ?.channels?.sms
                                                                ?.providerName ??
                                                            ""
                                                        }
                                                        className={
                                                            classes.longInput
                                                        }
                                                        onChange={
                                                            formikProps.handleChange
                                                        }
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                        helperText={formikProps.touched?.channels?.sms?.providerName && formikProps.errors?.channels?.sms?.providerName}
                                                    >
                                                        <MenuItem key='incontact' value='incontact'>NICE inContact</MenuItem>
                                                        <MenuItem key='textel' value='textel'>Textel</MenuItem>
                                                    </Select>
                                                    {formikProps.errors?.channels?.sms?.providerName && formikProps.touched?.channels?.sms?.providerName &&
                                                        <FormHelperText error>{formikProps.errors?.channels?.sms?.providerName}</FormHelperText>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl
                                                    className={classes.formControl}
                                                >
                                                    <InputLabel>
                                                        Message Template
                                                    </InputLabel>
                                                    <Select
                                                        margin="dense"
                                                        name="channels.sms.messageTemplate"
                                                        value={
                                                            formikProps.values
                                                                ?.channels?.sms
                                                                ?.messageTemplate ??
                                                            ""
                                                        }
                                                        className={
                                                            classes.longInput
                                                        }
                                                        onChange={
                                                            formikProps.handleChange
                                                        }
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                        error={formikProps.errors?.channels?.sms?.messageTemplate && formikProps.touched?.channels?.sms?.messageTemplate}
                                                        helperText={formikProps.touched?.channels?.sms?.messageTemplate && formikProps.errors?.channels?.sms?.messageTemplate}
                                                    >
                                                        {availableMessageTemplates.map(
                                                            (template) => (
                                                                <MenuItem
                                                                    key={
                                                                        template.id
                                                                    }
                                                                    value={
                                                                        template.id
                                                                    }
                                                                >
                                                                    {template.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {formikProps.values.channels.sms.providerName === 'textel' &&
                                                <>
                                                    <Grid item>
                                                        <TextField
                                                            margin="dense"
                                                            name="channels.sms.sendingNumber"
                                                            label="Sending SMS"
                                                            type="text"
                                                            className={classes.longInput}
                                                            value={formikProps.values?.channels?.sms?.sendingNumber ?? ""}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            error={formikProps.errors?.channels?.sms?.sendingNumber && formikProps.touched?.channels?.sms?.sendingNumber}
                                                            helperText={formikProps.touched?.channels?.sms?.sendingNumber && formikProps.errors?.channels?.sms?.sendingNumber}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            margin="dense"
                                                            name="channels.sms.bearerToken"
                                                            label="Textel Bearer Token"
                                                            type="text"
                                                            className={classes.longInput}
                                                            value={formikProps.values.channels.sms.bearerToken}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            error={formikProps.errors?.channels?.sms?.bearerToken && formikProps.touched?.channels?.sms?.bearerToken}
                                                            helperText={formikProps.touched?.channels?.sms?.bearerToken && formikProps.errors?.channels?.sms?.bearerToken}
                                                        />
                                                    </Grid>
                                                </>}

                                            {formikProps.values.channels.sms.providerName === 'incontact' &&
                                                <Grid item>
                                                    <FormControl
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel>
                                                            SMS Skill
                                                        </InputLabel>
                                                        <Select
                                                            margin="dense"
                                                            name="channels.sms.smsSkill"
                                                            value={
                                                                formikProps.values
                                                                    ?.channels?.sms
                                                                    ?.smsSkill ?? ""
                                                            }
                                                            className={
                                                                classes.longInput
                                                            }
                                                            onChange={
                                                                formikProps.handleChange
                                                            }
                                                            onBlur={
                                                                formikProps.handleBlur
                                                            }
                                                            error={formikProps.errors?.channels?.sms?.smsSkill && formikProps.touched?.channels?.sms?.smsSkill}
                                                            helperText={formikProps.touched?.channels?.sms?.smsSkill && formikProps.errors?.channels?.sms?.smsSkill}
                                                        >
                                                            {smsMessagingSkills.map(
                                                                (skill) => (
                                                                    <MenuItem
                                                                        key={
                                                                            skill.skillId
                                                                        }
                                                                        value={
                                                                            skill.skillId
                                                                        }
                                                                    >
                                                                        {
                                                                            skill.skillName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>}
                                            {formikProps.values.channels.sms.providerName === 'incontact' &&
                                                <Grid item>
                                                    <CenterToggleContainer toggled={loading.scripts}>
                                                        <CenterToggleChild>
                                                            <FormControl disabled={loading.scripts}>
                                                                <InputLabel error={formikProps.errors.channels?.sms?.smsSkill && formikProps.touched?.channels?.sms?.smsSkill}>SMS IVR Script</InputLabel>
                                                                <Select
                                                                    margin="dense"
                                                                    name="channels.sms.smsIVRScript"
                                                                    value={formikProps.values?.channels?.sms?.smsIVRScript ?? ""}
                                                                    className={classes.field}
                                                                    onChange={formikProps.handleChange}
                                                                    error={formikProps.errors.channels?.sms?.smsIVRScript && formikProps.touched?.channels?.sms?.smsIVRScript}
                                                                >
                                                                    {ivrScripts.map(script =>
                                                                        <MenuItem key={script.scriptId} value={script.scriptName}>{script.scriptName}</MenuItem>)}
                                                                </Select>
                                                                {formikProps.touched.channels?.sms?.smsIVRScript && formikProps.errors.channels?.sms?.smsIVRScript &&
                                                                    <FormHelperText error>{formikProps.errors.channels?.sms?.smsIVRScript}</FormHelperText>}
                                                            </FormControl>
                                                        </CenterToggleChild>
                                                        <CenterToggleItem>
                                                            <CircularProgress variant='indeterminate' color='primary' />
                                                        </CenterToggleItem>
                                                    </CenterToggleContainer>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <FormControlLabel
                                    label="Push Notifications"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={
                                                formikProps.values?.channels
                                                    ?.pushNotificationsEnabled ?? false
                                            }
                                            onChange={formikProps.handleChange}
                                            name="channels.pushNotificationsEnabled"
                                            value = {formikProps.values.channels.pushNotificationsEnabled}
                                        />
                                    }
                                />
                            </Grid>
                            {
                                formikProps.values?.channels?.pushNotificationsEnabled && (
                                    <Grid item style={{ paddingBottom: "32px" }}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <TextField
                                                    margin="dense"
                                                    name="channels.pushNotifications.title"
                                                    label="Title"
                                                    type="text"
                                                    className={classes.longInput}
                                                    required
                                                    value={
                                                        formikProps.values?.channels
                                                            ?.pushNotifications?.title ?? ""
                                                    }
                                                    onChange={
                                                        formikProps.handleChange
                                                    }
                                                    onBlur={formikProps.handleBlur}
                                                    error={
                                                        formikProps.error?.channels
                                                            ?.pushNotifications?.title &&
                                                        formikProps.touched
                                                            ?.channels?.pushNotifications
                                                            ?.title
                                                    }
                                                    helperText={
                                                        formikProps.touched
                                                            ?.channels?.pushNotifications
                                                            ?.title
                                                            ? formikProps.errors
                                                                ?.channels?.pushNotifications
                                                                ?.title
                                                            : ""
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl
                                                    className={classes.formControl}
                                                >
                                                    <InputLabel>
                                                        Message Template
                                                    </InputLabel>
                                                    <Select
                                                        name="channels.pushnotification.messageTemplate"
                                                        required
                                                        className={
                                                            classes.longInput
                                                        }
                                                        value={
                                                            formikProps.values
                                                                ?.channels?.pushnotification
                                                                ?.messageTemplate ??
                                                            ""
                                                        }
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                        onChange={
                                                            formikProps.handleChange
                                                        }
                                                        error={
                                                            formikProps.errors
                                                                ?.channels?.pushnotification
                                                                ?.messageTemplate &&
                                                            formikProps.touched
                                                                ?.channels?.pushnotification
                                                                ?.messageTemplate
                                                        }
                                                        helperText={
                                                            formikProps.touched
                                                                ?.channels?.pushnotification
                                                                ?.messageTemplate
                                                                ? formikProps.errors
                                                                    ?.channels
                                                                    ?.pushnotification
                                                                    ?.messageTemplate
                                                                : ""
                                                        }
                                                    >
                                                        {availableMessageTemplates.filter((template) => template.type ==="PlainText").map(
                                                            (template) => (
                                                                <MenuItem
                                                                    key={
                                                                        template.id
                                                                    }
                                                                    value={
                                                                        template.id
                                                                    }
                                                                >
                                                                    {template.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl
                                                    className={classes.formControl}
                                                >
                                                    <InputLabel>
                                                        Projects
                                                    </InputLabel>
                                                    <Select
                                                        name="channels.pushnotification.projects"
                                                        required
                                                        className={
                                                            classes.longInput
                                                        }
                                                        value={
                                                            formikProps.values
                                                                ?.channels?.pushnotification
                                                                ?.projects ??
                                                            ""
                                                        }
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                        onChange={
                                                            formikProps.handleChange
                                                        }
                                                        error={
                                                            formikProps.errors
                                                                ?.channels?.pushnotification
                                                                ?.projects &&
                                                            formikProps.touched
                                                                ?.channels?.pushnotification
                                                                ?.projects
                                                        }
                                                        helperText={
                                                            formikProps.touched
                                                                ?.channels?.pushnotification
                                                                ?.projects
                                                                ? formikProps.errors
                                                                    ?.channels
                                                                    ?.pushnotification
                                                                    ?.projects
                                                                : ""
                                                        }
                                                    >
                                                        {
                                                        projects.map(
                                                            (project) => (
                                                                <MenuItem
                                                                    key={
                                                                        project.id
                                                                    }
                                                                    value={
                                                                        project.id
                                                                    }
                                                                >
                                                                    {project.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MessagingOptions;

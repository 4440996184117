import * as Yup from 'yup';
import * as moment from 'moment';

const atLeastOneLettersNumbersSpacesRegex = /^[a-zA-Z0-9 -]+$/;
const phoneRegex = /(^(\+?[0-9]{1,4})?\-[0-9]{3}\-[0-9]{3}\-[0-9]{4})|(^(\+?[0-9]{1,4})?[0-9]{10})$/;

const contactValidation = Yup.object().shape({
    firstName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .matches(atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid first name')
        .required('Please supply a first name'),
    lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .matches(/[a-zA-Z]+('[a-zA-Z])?[a-zA-Z]*/, 'Please enter a valid last name')
        .required('Please supply a last name'),
    source: Yup.string()
        .nullable()
        .max(40, 'Must be 40 characters or less'),
    phone: Yup.string()
        .when("cell", {
            is: (cell) => !cell || cell.length === 0,
            then: Yup.string()
                .required("Please supply phone or cell number")
                .matches(phoneRegex, 'Please enter a valid phone number'),
            otherwise: Yup.string()
                .nullable()
        }),
    cell: Yup.string()
        .when("phone", {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string()
                .required("Please supply phone or cell number")
                .matches(phoneRegex, 'Please enter a valid phone number'),
            otherwise: Yup.string()
                .nullable()
        }),
    address1: Yup.string()
        .nullable()
        .max(50, 'Must be 50 characters or less')
        .matches(atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid address'),
    address2: Yup.string()
        .nullable()
        .max(50, 'Must be 50 characters or less')
        .matches(atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid second address'),
    city: Yup.string()
        .nullable()
        .matches(atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid city'),
    zip: Yup.string()
        .nullable()
        .max(13, 'Please enter a valid zip code'),
    email: Yup.string()
        .nullable()
        .email('Please enter a valid email address')
}, [["phone", "cell"]]);

const segmentValidation = Yup.object({
    name: Yup.string()
        .max(50, 'Segment name must be 50 characters or less')
        .required('Please supply a segment name')
        .matches(atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid segment name')
});

const campaignValidation = Yup.object({
    name: Yup.string()
        .required('A Campaign name is required')
        .matches(atLeastOneLettersNumbersSpacesRegex, 'Please enter a valid campaign name'),
    skillId: Yup.string()
        .required('A skill is required'),
    campaignProfileId: Yup.string()
        .required('A profile is required'),
    expireDate: Yup.date()
        .required('A date is required')
        .min(moment().format(), 'Expire date must be after today')
    //Moment().format() returns 2020-09-10T08:39:23-06:00
    //Signifying september 10th, 2020 at 8:39:23 AM
});

const cxOneValidation = Yup.object({
    icBU: Yup.string()
        .nullable()
        .max(9, 'The Business Unit Number is at most 9 Characters Long')
        .min(7, 'The Business Unit Number is at least 7 Characters Long')
        .required('Please supply a CXOne Business Unit')
        .matches(/^\d{7,9}$/, 'Please supply a valid CXOne Business Unit'),
    icAccessKey: Yup.string()
        .nullable()
        .max(80, 'Must be 80 characters or less')
        .required('Please supply a CXOne access key'),
    icAppSecret: Yup.string()
        .nullable()
        .max(80, 'Must be 80 characters or less')
        .required('Please supply a CXOne application secret,'),
    icAPIOverride: Yup.string()
        .nullable()
        .url('Please provide a valid URL')
        .max(120, 'Please Provide the appropriate base URL')
});

const inContactValidation = Yup.object({
    icUserName: Yup.string()
        .nullable()
        .max(50, 'Must be 20 characters or less')
        .required('Please supply an inContact User Name'),
    icPassword: Yup.string()
        .nullable()
        .max(48, 'Must be 48 characters or less')
        .required('Please supply an inContact Password'),
    icBU: Yup.string()
        .nullable()
        .max(7, 'The Business Unit Number is 7 Characters Long')
        .min(7, 'The Business Unit Number is 7 Characters Long')
        .required('Please supply a business unit number')
        .matches(/^\d{7}$/, 'Please supply a valid business unit number'),
    icApp: Yup.string()
        .nullable()
        .max(40, 'Please Make your application name less than 40 characters')
        .required('Please supply an application name'),
    icVendor: Yup.string()
        .nullable()
        .max(50, 'Please Make your application name less than 50 characters')
        .required('Please supply a vendor name'),
    icGetAuthTokenURL: Yup.string()
        .nullable()
        .required('This field is required')
        .url('Please enter a valid authentication token URL')
});

export default { contactValidation, segmentValidation, campaignValidation, cxOneValidation, inContactValidation };
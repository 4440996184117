import * as request from 'superagent';
import * as moment from 'moment';
import awsExports from '../aws-exports';
import axios from 'axios';
import { API, Auth } from "aws-amplify";
import UserContext from "../context/UserContext";
const _ = require('lodash');
const { parse } = require('json2csv');

/*
* I disable eslint for the unused vars because removing 
* the unused vars caused too many cascaded errors.
* I am leaving them because of fear.
*/

/* eslint-disable no-unused-vars */
let icSettings;
let apiEndpoint;
let icCluster;
let refreshToken;
let expireTime;
let accessToken;
let refreshTokenUri;
let serverBaseUri;
let icAPIURL;
let icScriptName;
let isUserHub;
let isCentral;
let icBU;
let userHubBaseUrl = awsExports.aws_project_region.startsWith('us')
    ? process.env.REACT_APP_US_NIC_API_URL
    : awsExports.aws_project_region.startsWith('eu') 
        ? process.env.REACT_APP_UK_NIC_API_URL
        : process.env.REACT_APP_AU_NIC_API_URL;

const PROXY_URL = 'https://wjqomkhk14.execute-api.us-west-2.amazonaws.com/dev/proxy/';
//const PROXY_URL = 'http://localhost:3001/proxy/';
const USER_HUB_BASE_URI = 'https://api-na1.niceincontact.com/inContactAPI/services/';


export async function login(settings, token) {
    icSettings = settings;

    try {
        let authToken;

        if (token) {
            authToken = token;
        } else {
            authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        }

        const loginResult = await API.post('cdyxdialer', '/login/nic', {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "x-api-key": settings.apiKey,
            },
            body: {},
        })
        apiEndpoint = loginResult.api_endpoint + 'services/';
        accessToken = loginResult.token;

    } catch (err) {
        console.error('login failed', err);
    }

}

function getURL(input, version = 'v26.0') {
    return `${PROXY_URL}${encodeURIComponent(`${apiEndpoint}${version}/${input}`)}`;

}

export async function getIcSkills() {
    try {
        const skillsResult = await request.get(getURL('skills?mediaTypeId=4&isActive=true&outboundStrategy=Personal Connection&fields=skillId,skillName,scriptName'))
            .set('Authorization', `Bearer ${accessToken}`)
            .type('json');

        const tenantSkills = [];

        if (icScriptName) {
            for (const skill of skillsResult.body.skills) {
                if (icScriptName === skill.scriptName) {
                    tenantSkills.push({ ...skill, source: 'NiC' });
                }
            }
        }
        else {
            for (const skill of skillsResult.body.skills) {
                tenantSkills.push({ ...skill, source: 'NiC' });
            }
        }
        return tenantSkills

    } catch (err) {
        console.log(err);
        return [];
    }
}

export async function getConfigurationIcSkills(mediaType) {
    try {
        const skillsResult = await request.get(getURL(`skills?isActive=true&fields=skillId,skillName,isRunning,isOutbound${mediaType ? `&mediaTypeId=${mediaType}` : ''}`))
            .set('Authorization', `Bearer ${accessToken}`);

        return skillsResult.body.skills.map(skill => {
            return {
                ...skill,
                source: 'NiC'
            };
        });
    } catch (err) {
        return [];
    }
}

export async function getMessagingSkills(mediaType) {
    try {
        const skillsResult = await request.get(getURL('skills?fields=skillId,skillName,scriptName'))
            .set('Authorization', `Bearer ${accessToken}`);

        return skillsResult.body.skills.map(skill => {
            return {
                ...skill,
                source: 'NiC'
            };
        });

    } catch (err) {
        return (err)
    }
}

export async function getScript(scriptName) {
    try {
        const result = await axios.get(getURL('scripts/search'), {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            params: {
                scriptName: scriptName
            }
        });

        const scripts = result.data;
        if (scripts && scripts.scriptSearchDetails) {
            return scripts.scriptSearchDetails[0].masterID;
        } else {
            return null;
        }
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function sendIcSMS(skillId, scriptId, contact, c3Message) {
    try {
        const result = await axios.post(getURL(`scripts/${scriptId}/start`), {
            skillId,
            parameters: `${contact.cell || contact.phone}|${c3Message}|${contact.id}`
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const scriptResult = result.data;
        return scriptResult.contactId;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function sendIcEmail(skillId, scriptId, emailTo, emailBody, emailSubject) {
    try {
        const result = await axios.post(getURL(`scripts/${scriptId}/start`), {
            skillId: skillId,
            parameters: `${emailTo}|${emailBody}|${emailSubject}|${skillId}`
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const scriptResult = result.data;
        return scriptResult.contactId;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function getScripts() {
    try {
        const scriptsResult = await request.get(getURL('scripts?isActive=true', 'v17.0'))
            .set('Authorization', `Bearer ${accessToken}`)
        const scripts = _.filter(scriptsResult.body.resultSet.scripts, ["mediaTypeId", "4"]);
        const result = _.uniqBy(scripts, 'scriptName')
        return result;
    } catch (err) {
        console.error(err)
        console.log('error in scripts')
        return [];
    }
}

export async function startIcSkill(skillId) {
    try {
        const Result = await request.post(getURL(`skills/${skillId}/start`))
            .set('Authorization', `Bearer ${accessToken}`)
        return Result.status;
    } catch (err) {
        return [];
    }
}

export async function stopIcSkill(skillId) {
    try {
        const Result = await request.post(getURL(`skills/${skillId}/stop`))
            .set('Authorization', `Bearer ${accessToken}`)
        return Result.status;
    } catch (err) {
        return [];
    }
}

export async function submitNicLicense(licenseInformation) {
    try {
        const Result = await request.post(getURL(`partners/billing/product-usage`))
            .set('Authorization', `Bearer ${accessToken}`)
            .type('json')
            .send(licenseInformation);
        return Result;
    } catch (err) {
        console.error(err);
    }
}

export async function viewSubmittedLicenses(productId, startTime, endTime) {
    try {
        const Result = await request.get(getURL(`partners/billing/product-usage/${productId}?startDate=${startTime}&endDate=${endTime}`))
            .set('Authorization', `Bearer ${accessToken}`)
            .type('json');
        return Result;
    } catch (err) {
        console.error(err);
    }
}

export async function getPointsOfContact() {
    try {
        const result = await request.get(getURL(`points-of-contact`))
            .set('Authorization', `Bearer ${accessToken}`);
        return result?.body?.pointsOfContact ?? [];
    } catch (err) {
        console.error(err.message);
        return [];
    }
}

export async function createSMSList(tenantSettings) {
    try {
        const fieldMappings = {
            listName: `c3-sms-${moment().format('DDD-HH:mm')}`,
            externalIdColumn: 'id',
            firstNameColumn: 'firstName',
            lastNameColumn: 'lastName',
            cityColumn: 'city',
            stateColumn: 'state',
            zipColumn: 'zip',
            timeZoneColumn: 'timeZone',
            overrideFinalizationColumn: 'overrideFinalization',
            destinationMappings: [
                {
                    fieldName: 'SmsPhoneNumber',
                    fieldValue: 'cell'
                }
            ],
            customFieldMappings: [
                {
                    fieldName: 'C3Message',
                    fieldValue: 'message'
                }
            ]
        };

        const listResponse = await axios.post(getURL(`lists/call-lists`),
            fieldMappings,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                responseType: 'json'
            }
        );

        return listResponse.data.listId;

    } catch (err) {
        console.error(`Error creating list for tenant: ${tenantSettings.id}`, err);
        return null;
    }
}

export async function createEmailList(tenantSettings) {
    try {
        const fieldMappings = {
            listName: `c3-email-${moment().format('DDD-HH:mm')}`,
            externalIdColumn: 'id',
            firstNameColumn: 'firstName',
            lastNameColumn: 'lastName',
            cityColumn: 'city',
            stateColumn: 'state',
            zipColumn: 'zip',
            timeZoneColumn: 'timeZone',
            overrideFinalizationColumn: 'overrideFinalization',
            destinationMappings: [
                {
                    fieldName: 'EmailAddress',
                    fieldValue: 'email'
                }
            ],
            customFieldMappings: [
                {
                    fieldName: 'C3Message',
                    fieldValue: 'message'
                }
            ]
        };

        const listResponse = await axios.post(getURL(`lists/call-lists`),
            fieldMappings,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                responseType: 'json'
            }
        );

        return listResponse.data.listId;

    } catch (err) {
        console.error(`Error creating list for tenant: ${tenantSettings.id}`, err);
        return null;
    }
}


export async function addToList(tenantSettings, contacts, skillId, listId) {
    if (!contacts || contacts.length === 0) {
        return null;
    }

    try {
        console.info(`sending contacts skill: ${skillId}`, ...contacts);
        const body = {
            listId: listId,
            listFile: encodeList(contacts),
            fileName: `${tenantSettings.id}-agentless-${moment().unix()}.csv`,
            skillId: skillId,
            forceOverwrite: true,
            expirationDate: moment().add(1, 'month').format('YYYY-MM-DD'),
            startSkill: true
        };

        const listResponse = await axios.post(getURL(`lists/call-lists/${listId}/upload`),
            body,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                responseType: 'json'
            });

        return listResponse.data;
    } catch (err) {
        console.log('Error adding to list', err);
        return null;
    }
};

function encodeList(contacts) {
    const mappedContacts = contacts.map((contact) => {
        const simpleContact = {
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            city: contact.city,
            state: contact.state,
            zip: contact.zip,
            timeZone: contact.timeZone,
            message: contact.message,
            cell: contact.cell,
            email: contact.email,
            status: contact.status,
            preview: contact.preview,
            tenant: contact.tenant,
            complianceRequired: contact.complianceRequired,
            campaignId: contact.campaignId,
            segmentId: contact.segmentId,
            overrideFinalization: true
        };
        return simpleContact;
    });
    const csv = parse(mappedContacts, { includeEmptyRows: false, delimiter: ',' });

    const encodedCsv = Buffer.from(csv).toString('base64');

    return encodedCsv;
}

export async function getTimeZones() {
    try {
        const queryParams = {
            includeTrustedBusinessUnits: false,
            fields: 'businessUnitId,timeZones'
        };

        const timeZoneResponse = await axios.get(getURL(`business-unit`), {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            responseType: 'json',
            params: queryParams
        });

        let buIndex = 0;
        if (icBU) {
            buIndex = _.findIndex(timeZoneResponse.data.businessUnits, (item) => {
                const buId = Number(item.businessUnitId);
                const tenantBu = Number(icBU);

                return buId === tenantBu;
            });
        }
        if (buIndex === -1) {
            return [];
        }
        return timeZoneResponse.data.businessUnits[buIndex].timeZones;
    } catch (err) {
        console.error('error getting BU timezones', err);
        return [];
    }
}


/* eslint-enable no-unused-vars */
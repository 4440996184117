/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import WebOutlinedIcon from '@material-ui/icons/WebOutlined';

export default function CDSite(props)
{
    const [cdSite, setCDSite] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setCDSite(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleCDSite = () => {
        setCDSite(!cdSite);
        props.onChange(!cdSite)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={cdSite}
                        onChange={handleCDSite}
                        name="cdSite"
                        color="primary"
                        />
                    }
                    label="Customer Dynamics Website"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Allow access to the Customer Dynamics website">
                        <WebOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


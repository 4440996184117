import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IvrPrerequisites from '../E2/IVRPrerequisites';
import NewIvrProfile from '../E2/NewIvrProfile';
import IVRQuery from '../E2/IVRQuery';
import IvrSearchEntities from '../E2/IvrSearchSettings';
import Actions from '../E2/IvrAction';
import { API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import UserContext from '../context/UserContext';
import { getOrgs } from '../E2/MSALConfig';
import { useSnackbar } from 'notistack';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '1em'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    padding: '1em'
  }
}));

const IvrProfile = function(props) {
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState('');
  const [comingFrom, setComingFrom] = useState('');
  const [accept, setAccept] = useState(false);
  const [tenantId, setTenantId] = useState('');
  const [profileName, setProfileName] = useState('');
  const [selOrg, setSelOrg] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [action, setAction] = useState('');
  const [entity, setEntity] = useState('');
  const [dataQuery, setDataQuery] = useState('');

  const appSecret = 'h~2u.hy6.9EM7p~d-0-NZv63jX6t4zg-Qr';
  const clientId = 'a53e8cfb-e738-4fee-81fa-359f180d150c';

  useEffect(() => {
    async function getData(){
      if (props.location.state !== null){
        setComingFrom(props.location.state.comingFrom);
      } else {
        setComingFrom('New');
      }
      if (comingFrom=== 'New'){
        setTitle('New Configuration Profile')
      } else if (comingFrom === 'Edit'){
        console.log(props);
        setTitle('Editing ' + props.location.state.profileData.name);
      } else if (comingFrom === 'Clone'){
        setTitle(props.location.state.profileData.name + ' - Clone')
      }
      
    }
    if (userContext.tenant){
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.tenant]);
  
  async function pullOrgs(){
    const initialCall = (await getOrgs(clientId));
      let orgArray = [];
      if(initialCall.orgs){
        for (const org of initialCall.orgs){
          const newOrg = {
            FriendlyName: org.FriendlyName,
            Id: org.Id,
            Url: org.Url,
            Version: org.Version
          }
          orgArray.push(newOrg);
        }
        setOrgs(orgArray);
      }
      if(initialCall.tenantId){
        setTenantId(initialCall.tenantId);
      }
      return orgArray;
  }

  function getSteps() {
    return ['Prerequisites',
    'Name profile and select Dynamics organization', 
    'Select action',
    'Select Entity',
    'Input Query',
    'Review'];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (<IvrPrerequisites accept={accept} setAccept={setAccept}
        getOrgs={pullOrgs} orgs={orgs}></IvrPrerequisites>);
      case 1:
        return (<NewIvrProfile
          orgs={orgs} 
          appId={clientId}
          selOrg={selOrg}
          setSelOrg={setSelOrg}
          profileName={profileName}
          setProfileName={setProfileName}></NewIvrProfile>);
      case 2:
        return (<Actions action={action} setAction={setAction}></Actions>);
      case 3:
        return (<IvrSearchEntities org={selOrg} entity={entity} setEntity={setEntity}></IvrSearchEntities>)
      case 4:
        return (<IVRQuery dataQuery={dataQuery} setDataQuery={setDataQuery}></IVRQuery>);
      case 5: 
        return 'Review'
      default:
        return 'Unknown stepIndex';
    }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 0){
      if (accept === true){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (comingFrom === 'Edit'){
            setProfileName(props.location.state.profileData.name);
            const action = JSON.parse(props.location.state.profileData.action);
            console.log(action);
            setEntity(action.crmEntity);
            setAction(action.Action);
            const search = JSON.stringify(action.search);
            setDataQuery(search);
            const configOrg = _.find(orgs, ['FriendlyName', action.org.name]);
            setSelOrg(configOrg);
        }
      } else {
          enqueueSnackbar('You must run and pass the prerequisites test.', {
            variant: 'error'
          })
      }
    } else if (activeStep === 1){
      if (profileName !== null && profileName !== ''){
        if (selOrg !== null && selOrg !== ''){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          enqueueSnackbar('You must select an orginization that has the Customer Dynamics package properly installed.', {
            variant: 'error'
          })
        } 
      } else {
          enqueueSnackbar('Please enter a name for the profile', {
            variant: 'error'
          })
      }
    } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === steps.length - 1){
      submitProfile();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

async function submitProfile(){
    try{
      const dynamoOrg = {
        name: selOrg.FriendlyName,
        id: selOrg.Id,
        url: selOrg.Url,
        version: selOrg.Version
      }
      const profile = {
        tenant: userContext.tenant,
        name: profileName,
        action: JSON.stringify({
          Action: action,
          connection: {
            dynamicsTenant: tenantId,
            appSecret: appSecret,
            clientId: clientId
          },
          crmEntity: entity,
          org: dynamoOrg,
          returnResult: 'All',
          search: [...dataQuery]
        })
      };
      console.log(profile);
      // if (comingFrom === 'New'){
        await API.graphql(graphqlOperation(mutations.createPreProcess, {input: profile}));
      // } else if (comingFrom === 'Edit'){
      //   await API.graphql(graphqlOperation(mutations.updateConfigProfile, {input: {id: props.location.state.profileData.id, ...profile}}));
      // } else if (comingFrom === 'Clone'){
      //   await API.graphql(graphqlOperation(mutations.createConfigProfile, {input: profile}));
      // }
        enqueueSnackbar('Profile saved successfully');
      props.history.push('/e2/ivr')
    } catch (err) {
      enqueueSnackbar('Save unsuccessful. ' + err, {
        variant: 'error'
      });
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant='h6'>{title}</Typography>
      <br></br>
      <Paper>
        <div className={classes.body}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Grid className={classes.instructions}>{getStepContent(activeStep)}</Grid>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default withRouter(IvrProfile);
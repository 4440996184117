import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphqlOperation, API, Auth } from 'aws-amplify';
import * as customQueries from '../graphql/custom-queries';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { makeStyles, Paper, Tabs, Grid, Button, MenuItem, TextField, Switch, FormControlLabel, Tab, FormControl, InputLabel, Select, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Formik } from 'formik';
import countryRegionData from 'country-region-data';
import { useSnackbar } from 'notistack';
import UserContext from '../context/UserContext';
import moment from 'moment';
import Validations from './YupValidations';
import timezones from './TimezoneData';
import { ClearOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    formControl: {
        marginTop: '5px',
        minWidth: 120
    },
    paper: {
        padding: '15px',
    },
    inputFields: {
        width: 200
    },
    address: {
        width: 200
    },
    timezoneMenuItems: {
        maxHeight: 25
    }
});

/**
 * A form for editing the contact's information. 
 * It displays the contact's details as well as a 
 * tab for the account information a contact is tied to.
 * @component
 */
const ContactTabForm = function (props) {
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [contactState, setContact] = useState({
        firstName: '',
        lastName: '',
        email: '',
        source: '',
        phone: '',
        cell: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        timeZone: '',
        expireDt: '',
        complianceRequired: false,
        preview: false,
        outboundCallerId: '',
        customFields: {},
        optout: {
            Voice: false,
            SMS: false,
            Email: false
        }
    });

    /** @var {number} ContactTabForm~currentTab Zero indexed tab number */
    const [currentTab, setCurrentTab] = useState(0);
    /** @var {any[]} ContactTabForm~usStates The loaded list of states a contact can be from. */
    const [usStates, setUsStates] = useState([]);
    /** @var {any[]} ContactTabForm~statuses The allowed statuses of a contact */
    //const [statuses, setStatuses] = useState([]);
    /** @var {any[]} ContactTabForm~accounts The existing accounts a contact can be added to. */

    /**
     * Load form field data at component start up.
     */
    useEffect(() => {
        setUsStates(_.find(countryRegionData, ['countryShortCode', 'US']).regions);
        async function getData() {
            try {
                const contactData = await API.graphql(graphqlOperation(customQueries.getCreateContactData));
                //setStatuses(contactData.data.contactStatus.enumValues);
                // setAccounts(contactData.data.accounts.items);
            } catch (err) {
                console.error(err);
            }
        }
        getData();
    }, []);

    /**
     * Load the contact's data and redo if contactId changes.
     */
    useEffect(() => {
        async function getData() {
            try {
                const contactId = props.contactId;
                const contactResult = await API.graphql(graphqlOperation(queries.getContact, { id: contactId }));
                const contact = contactResult.data.getContact;

                for (const key in contact) {
                    if (contact[key] !== false) {
                        contact[key] = contact[key] || '';
                    }
                }
                if (contact.customFields) {
                    contact.customFields = JSON.parse(contact.customFields);
                } else {
                    contact.customFields = {};
                }
                const loadedContact = {
                    ...contactState,
                    ...contact
                };
                setContact(loadedContact);
            } catch (err) {
                console.error(err);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.customFields, props.contactId]);

    /**
     * Update the contact's info from the form data.
     * @param {any} contact 
     */
    const mutateContact = async (contact) => {
        try {
            const updateContactResult = await API.graphql(graphqlOperation(mutations.updateContact, { input: contact }));
            enqueueSnackbar('Contact Updated');
            return updateContactResult.data.updateContact;
        } catch (err) {
            console.error(err);
        }
    }
    /**
     * Handle changing the currentTab state.
     * @param {any} event The click event for the tab
     * @param {any} newValue The new tab index value
     */
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    }

    const handleDate = (name, values) => {
        const dateValue = values.customFields[name];
        if (dateValue && Number(dateValue)) {
            return moment(dateValue, 'x').format('YYYY-MM-DDTHH:mm');
        } else if (dateValue) {
            return dateValue;
        } else {
            return '';
        }
    }

    return (
        <Paper className={classes.paper} elevation={2}>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="Contact">
                <Tab label="Contact" id="contact-tab-0" />
            </Tabs>
            <Formik
                initialValues={contactState}
                enableReinitialize={true}
                validationSchema={Validations.contactValidation}
                onSubmit={async (values, formikBag) => {
                    if (values.expireDt && values.expireDt !== '') {
                        values.expireDt = moment(values.expireDt).format();
                    }
                    const contact = { ...values };
                    if (contact.customFields) {
                        for (const key in contact.customFields) {
                            const tenantCustomField = _.find(userContext.customFields, ['name', key]);
                            if (tenantCustomField) {
                                if (tenantCustomField.type === 'Number') {
                                    contact.customFields[key] = +contact.customFields[key];
                                } else if (tenantCustomField.type === 'DateTime') {
                                    const dateField = contact.customFields[key];
                                    if (dateField && Number(dateField)) {
                                        contact.customFields[key] = dateField;
                                    } else {
                                        contact.customFields[key] = moment(dateField, 'YYYY-MM-DDTHH:mm').format('x');
                                    }
                                } else if (tenantCustomField.type === 'Boolean') {
                                    contact.customFields[key] = Boolean(contact.customFields[key]);
                                }
                            }
                        }
                        contact.customFields = JSON.stringify(contact.customFields);
                    }
                    for (const key in contact) {
                        contact[key] = contact[key] === "" ? null : contact[key];
                    }
                    contact.updatedBy = (await (await Auth.currentSession()).getIdToken()).payload.email;
                    delete contact.createdAt;
                    delete contact.updatedAt;
                    try {
                        const updatedContact = await mutateContact(contact);
                        if (updatedContact.customFields) {
                            updatedContact.customFields = JSON.parse(updatedContact.customFields);
                        }
                        formikBag.setSubmitting(false);
                        setContact(updatedContact);
                        formikBag.resetForm(updatedContact);
                    } catch (err) {
                        console.error(err);
                    }

                }}
            >
                {formikProps => (
                    <form hidden={currentTab !== 0} onSubmit={formikProps.handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container justifyContent='flex-end' spacing={2}>
                                    <Grid item>
                                        <Button variant='outlined' color='primary' onClick={() => history.push('/contacts')}>Close</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="submit" disabled={!formikProps.dirty} variant="contained" color="primary">Save</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Paper elevation={4} className={classes.paper}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography variant="overline">Contact Info</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent='space-between' spacing={2}>
                                                <Grid item>
                                                    <TextField
                                                        autoFocus
                                                        name="firstName"
                                                        label="First Name"
                                                        type="text"
                                                        className={classes.inputFields}
                                                        required={true}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.firstName}
                                                        error={formikProps.errors.firstName && formikProps.touched.firstName}
                                                        helperText={formikProps.errors.firstName} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        autoFocus
                                                        name="lastName"
                                                        label="Last Name"
                                                        type="text"
                                                        className={classes.inputFields}
                                                        required={true}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.lastName}
                                                        error={formikProps.errors.lastName && formikProps.touched.lastName}
                                                        helperText={formikProps.errors.lastName} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        name="email"
                                                        label="Email"
                                                        type="email"
                                                        className={classes.inputFields}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.email}
                                                        error={formikProps.errors.email && formikProps.touched.email} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        name="phone"
                                                        label="Phone #"
                                                        type="phone"
                                                        className={classes.inputFields}
                                                        required={formikProps.values.cell ? false: true}
                                                        value={formikProps.values.phone}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors.phone && formikProps.touched.phone}
                                                        helperText={formikProps.errors.phone} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        name="cell"
                                                        label="Cell #"
                                                        type="phone"
                                                        required={formikProps.values.phone ? false: true}
                                                        className={classes.inputFields}
                                                        value={formikProps.values.cell}
                                                        error={formikProps.errors.cell && formikProps.touched.cell}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        helperText={formikProps.errors.phone} />
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <TextField
                                                            label="External Id"
                                                            margin="dense"
                                                            disabled={true}
                                                            className={classes.inputFields}
                                                            value={formikProps.values.externalId ? formikProps.values.externalId : ""}
                                                            name="externalId"
                                                            type="text"
                                                            inputProps={
                                                                { readOnly: true, }
                                                            } />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            margin="dense"
                                                            name="source"
                                                            label="Source"
                                                            type="text"
                                                            className={classes.inputFields}
                                                            value={formikProps.values.source}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            error={formikProps.errors.source && formikProps.touched.source} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <Grid container direction='column'>
                                                            <Grid item>
                                                                <TextField
                                                                    margin="dense"
                                                                    name="address1"
                                                                    label="Address Line 1"
                                                                    type="text"
                                                                    className={classes.address}
                                                                    value={formikProps.values.address1}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={formikProps.errors.address1 && formikProps.touched.address1}
                                                                    helperText={formikProps.errors.address1} />

                                                            </Grid>
                                                            <Grid item>
                                                                <TextField
                                                                    margin="dense"
                                                                    name="address2"
                                                                    label="Address Line 2"
                                                                    type="text"
                                                                    className={classes.address}
                                                                    value={formikProps.values.address2}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={formikProps.errors.address2 && formikProps.touched.address2}
                                                                    helperText={formikProps.errors.address2} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction='column'>
                                                            <Grid item>
                                                                <TextField
                                                                    margin="dense"
                                                                    name="city"
                                                                    label="City"
                                                                    type="text"
                                                                    className={classes.inputFields}
                                                                    value={formikProps.values.city}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={formikProps.errors.city && formikProps.touched.city}
                                                                    helperText={formikProps.errors.city} />
                                                            </Grid>
                                                            <Grid item>
                                                                <TextField
                                                                    margin="dense"
                                                                    name="zip"
                                                                    label="Zipcode"
                                                                    type="text"
                                                                    className={classes.inputFields}
                                                                    value={formikProps.values.zip}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    error={formikProps.errors.zip && formikProps.touched.zip}
                                                                    helperText={formikProps.errors.zip} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction='column'>
                                                            <Grid item>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel id="us-state-label">State</InputLabel>
                                                                    <Select
                                                                        labelId="us-state-label"
                                                                        value={formikProps.values.state}
                                                                        autoWidth={true}
                                                                        name="state"
                                                                        className={classes.inputFields}
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur}>
                                                                        {usStates.map(state =>
                                                                            <MenuItem key={state.shortCode} value={state.shortCode}>{state.name}</MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel id="timezone-label">Timezone</InputLabel>
                                                                    <Select
                                                                        labelId="timezone-label"
                                                                        value={formikProps.values.timeZone}
                                                                        autoWidth={true}
                                                                        name="timeZone"
                                                                        className={classes.inputFields}
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur}>
                                                                        {timezones.map(timezone =>
                                                                            <MenuItem key={timezone.shortname} className={classes.timezoneMenuItems} value={timezone.shortName}>{timezone.longName}</MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item style={{ flexGrow: 1 }}>
                                        <Paper elevation={4} className={classes.paper}>
                                            <Grid container direction='column' spacing={2}>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <TextField
                                                                type="datetime-local"
                                                                label="Expire Date"
                                                                name="expireDt"
                                                                value={moment(formikProps.values.expireDt).format('YYYY-MM-DDTHH:mm:ss')}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        step: 1
                                                                    }
                                                                }}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title="Clear Expire Date">
                                                                <IconButton onClick={() => { formikProps.setFieldValue('expireDt', '') }}><ClearOutlined color="primary" /></IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        type="text"
                                                        label="Caller ID"
                                                        value={formikProps.values.outboundCallerId}
                                                        name="outboundCallerId"
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    name="complianceRequired"
                                                                    checked={formikProps.values.complianceRequired}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    value="true"
                                                                    color="primary" />
                                                            }
                                                            label="Compliance Required"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    name="preview"
                                                                    checked={formikProps.values.preview}
                                                                    onChange={formikProps.handleChange}
                                                                    onBlur={formikProps.handleBlur}
                                                                    value="true"
                                                                    color="primary" />
                                                            }
                                                            label="Preview"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item style={{ flexGrow: 1 }}>
                                        <Paper elevation={4} className={classes.paper}>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography variant='overline'>OptOuts</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label='Voice'
                                                                control={
                                                                    <Switch
                                                                        checked={formikProps.values?.optout?.Voice ?? false}
                                                                        onChange={formikProps.handleChange}
                                                                        name='optout.Voice'
                                                                        color='primary'
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label='SMS'
                                                                control={
                                                                    <Switch
                                                                        checked={formikProps.values?.optout?.SMS ?? false}
                                                                        onChange={formikProps.handleChange}
                                                                        name='optout.SMS'
                                                                        color='primary'
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label='Email'
                                                                control={
                                                                    <Switch
                                                                        checked={formikProps.values?.optout?.Email ?? false}
                                                                        onChange={formikProps.handleChange}
                                                                        name='optout.Email'
                                                                        color='primary'
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Paper elevation={4} className={classes.paper}>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography variant="overline">Custom Fields</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container justify="flex-start" alignItems="center" spacing={4}>
                                                {userContext.customFields && userContext.customFields.map(field => (
                                                    <Grid item key={field.name}>
                                                        {field.type === 'String' &&
                                                            <TextField
                                                                margin="dense"
                                                                name={`customFields.${field.name}`}
                                                                label={field.displayName}
                                                                type="text"
                                                                className={classes.inputFields}
                                                                value={formikProps.values.customFields[field.name] || ''}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur} />
                                                        }
                                                        {field.type === 'Number' &&
                                                            <TextField
                                                                margin="dense"
                                                                name={`customFields.${field.name}`}
                                                                label={field.displayName}
                                                                type="number"
                                                                className={classes.inputFields}
                                                                value={formikProps.values.customFields[field.name] || ''}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur} />
                                                        }
                                                        {field.type === 'DateTime' &&
                                                            <TextField
                                                                margin="dense"
                                                                name={`customFields.${field.name}`}
                                                                label={field.displayName}
                                                                type="datetime-local"
                                                                className={classes.inputFields}
                                                                value={handleDate(field.name, formikProps.values)}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }} />
                                                        }
                                                        {field.type === 'Boolean' &&
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name={`customFields.${field.name}`}
                                                                        checked={formikProps.values.customFields[field.name] || false}
                                                                        onChange={formikProps.handleChange}
                                                                        onBlur={formikProps.handleBlur}
                                                                        value="true"
                                                                    />
                                                                }
                                                                label={field.displayName}
                                                            />
                                                        }
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik >
        </Paper >
    );
}

ContactTabForm.propTypes = {
    /**
     * The ID of the contact to edit.
     */
    contactId: PropTypes.string.isRequired,
};

export default ContactTabForm;

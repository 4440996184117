import React, {useState, useRef} from 'react';
import {Grid, Button, CircularProgress, Table, TableBody, TableRow, TableCell, TableContainer, Paper, LinearProgress} from '@material-ui/core';
import StepperController from './StepperController';
import {useSnackbar} from 'notistack';
import axios from 'axios';

export default function MerchantInfo(props) {
    const {nextStep, stepBack, merchantInfo, setMerchantInfo, initialInformation, cmsInfoGenerated, setCMSInfoGenerated, cmsInfoSaved, setCmsInfoSaved} = props;
    const [cmsAppLoading, setCMSAppLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const createCMSAppButtonRef = useRef();
    const {enqueueSnackbar} = useSnackbar();

    async function createCMSApp() {
        setCMSAppLoading(true);

        try {
            const cmsInfo = await axios.post("https://api.cmsonline.com/onlineapp/v1/app/partner/createAppFromLandingPage", {"guid": "081a28f8-fc58-45b5-8a3c-95bb5e0dcd9d"});

            if(cmsInfo?.data) {
                setCMSInfoGenerated(true);
                setMerchantInfo({cmsMerchId: cmsInfo.data.merchantId, cmsMerchHash: cmsInfo.data.merchantHash, cmsAppToken: cmsInfo.data.token});
            } else {
                enqueueSnackbar("No CMS information returned", { variant: "error" });
            }
            
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Error creating CMS app", { variant: "error" });
        }
        
        setCMSAppLoading(false);
    }

    async function advanceStep() {
        if (cmsInfoSaved) {
            nextStep();
        } else {
            if (!cmsInfoGenerated) {
                enqueueSnackbar("Please create a CMS app before continuing", { variant: "error" });
            } else {
                setSaving(true);

                try{
                    await axios.put(
                        `${process.env.REACT_APP_PP_URL}/cms-onboard/update-vendor`, {
                            id: initialInformation.ppayVendor,
                            status: "SignUp",
                            cmsInfo: {...merchantInfo, nexioCredentials: merchantInfo.nexioCredentials ? merchantInfo.nexioCredentials : ""}
                        }, {
                            headers: {
                                'x-api-key': process.env.REACT_APP_PP_API_KEY
                            }
                        }
                    );
                    enqueueSnackbar("Vendor information saved", { variant: 'success' });
                    setCmsInfoSaved(true);
                    nextStep();
                } catch (err) {
                    enqueueSnackbar("Unable save vendor information", { variant: 'error' });
                }

                setSaving(false);
            }
        }
    }

    return (
        <div>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Button variant="contained" color="primary" ref={createCMSAppButtonRef} onClick={createCMSApp} disabled={cmsInfoGenerated || cmsAppLoading}>Create CMS App</Button>
                    {cmsAppLoading && createCMSAppButtonRef.current && 
                    <CircularProgress 
                        style={{position: 'absolute', top: createCMSAppButtonRef.current.offsetTop, left: createCMSAppButtonRef.current.offsetLeft + (createCMSAppButtonRef.current.offsetWidth / 2) - (34 / 2)}} 
                        size={34} 
                        variant='indeterminate' 
                        color='secondary' />}
                </Grid>
                {cmsInfoGenerated && 
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <b>Merchant ID</b>
                                    </TableCell>
                                    <TableCell align='left'>
                                        {merchantInfo.cmsMerchId}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <b>Merchant Hash</b>
                                    </TableCell>
                                    <TableCell align='left'>
                                        {merchantInfo.cmsMerchHash}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <b>Merchant Token</b>
                                    </TableCell>
                                    <TableCell align='left'>
                                        {merchantInfo.cmsAppToken}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>}
            </Grid>
            <StepperController onStepBack={stepBack} onStepForward={advanceStep} nextDisabled={saving} backDisabled={saving} />
            {saving && 
            <div style={{paddingTop: '10px'}}>
                <LinearProgress variant='indeterminate' color='secondary'/>
            </div>}
        </div>
    );
}



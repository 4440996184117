import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { 
    Grid,
    TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '450px',
        maxWidth: '450px'
    },
    main: {
        height: '300px'
    }

}));

export default function Action(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [dataQuery, setDataQuery] = useState('');

    useEffect(() => {
        async function getData(){
            setDataQuery(props.dataQuery)
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const handleDataQuery = (event) => {
        const query = event.target.value;
        setDataQuery(query);
        props.setDataQuery(query);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid item>
                    <TextField
                        key="query"
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        name="query"
                        label="Query"
                        type="text"
                        value={dataQuery}
                        required={true}
                        className={classes.selectFields}
                        onChange={handleDataQuery}
                        multiline
                        rows={13}
                    />
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
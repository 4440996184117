import React from 'react';
import {Button, Grid} from '@material-ui/core';

export default function StepperController(props) {
    const {
        onStepBack,
        onStepForward, 
        backDisabled = false, 
        nextDisabled = false,
        backText = "Back",
        nextText = "Next" ,
        paddingTop = 30,
        nextIsFormSubmit = false} = props;

    return (
        <div style={{paddingTop: `${paddingTop}px`}}>
            <Grid container spacing={2} direction='row'>
                <Grid item>
                    <Button variant='outlined' color='primary' disabled={backDisabled} onClick={onStepBack}>{backText}</Button>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' type={nextIsFormSubmit ? "submit" : "button"} disabled={nextDisabled} onClick={onStepForward}>{nextText}</Button>
                </Grid>
            </Grid>
        </div>
    );
}
import React, { useContext } from 'react';
import { Route, Switch, NavLink, useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Divider, List, ListItem, ListItemIcon, ListItemText, Drawer, Collapse, IconButton } from '@material-ui/core';
import { DashboardOutlined, SettingsPhoneOutlined, LowPriorityOutlined, EditOutlined } from '@material-ui/icons';
import { RecentActorsOutlined, ChevronLeftOutlined, ChevronRightOutlined } from '@material-ui/icons';
import { MoveToInboxOutlined, ContactsOutlined, FilterListOutlined, ExpandLess, ExpandMore, AssessmentOutlined, PeopleOutlined } from '@material-ui/icons';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Campaigns from '../pages/Campaigns';
import Segment from '../pages/Segment';
import Segments from '../pages/Segments';
import Contact from '../pages/Contact';
import Contacts from '../pages/Contacts';
import Import from '../pages/Import';
import DynamUpdate from '../pages/DynamUpdate';
import clsx from 'clsx';
import DialingProfiles from '../pages/DialingProfiles';
import LayoutState from '../context/LayoutState';
import UserContext from '../context/UserContext';
import BulkEdit from '../pages/BulkEdit';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: theme.drawer.open,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: theme.drawer.open,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.drawer.closed,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        width: theme.drawer.open,
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            zIndex: theme.zIndex.drawer + 1,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(1),
        align: "center"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedPrimary: {
        paddingLeft: theme.spacing(4),
        color: theme.palette.primary.main
    },
    currentPage: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    nestedCurrent: {
        paddingLeft: theme.spacing(4),
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    doublenested: {
        paddingLeft: theme.spacing(8),
    },
}));

/**
 * This is the component in charge of laying out the list management page and giving
 * slots for other components to sit in.
 * @category Layouts
 * @component
 * @param {any} props The React props object passed into components.
 */
function ListLayout(props) {
    const { container } = props;
    const classes = useStyles();
    const userContext = useContext(UserContext);

    const [layoutState, setLayoutState] = React.useState({
        drawerOpen: true
    });
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [dashboardOpen, setDashboardOpen] = React.useState(true);
    const [inventoryOpen, setInventoryOpen] = React.useState(true);
    const [listOpen, setListOpen] = React.useState(true);

    const location = useLocation();

	/**
 * Handle event to manage side drawer base on screen size.
 */
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
        setLayoutState({
            ...layoutState,
            drawerOpen: !drawerOpen
        });
    };

    const handleToggleList = () => {
        setListOpen(!listOpen);
    };
    const handleToggleInventory = () => {
        setInventoryOpen(!inventoryOpen);
    };
    const handleToggleDashboard = () => {
        setDashboardOpen(!dashboardOpen);
    };

	/**
	 * Generates HTML to be injected to handle left menu.
	 */
    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <div onClick={handleDrawerToggle}>
                <IconButton>
                    {drawerOpen ? <ChevronLeftOutlined color="primary" /> : <ChevronRightOutlined color="primary" />}
                </IconButton>
            </div>
            <Divider />
            <List>
                {userContext.rights[0] === '1' &&
                    <React.Fragment>
                        <ListItem button
                            key="0"
                            component={NavLink} to={`/dashboard`}
                            onClick={handleToggleDashboard}>
                            {dashboardOpen ? <ExpandLess /> : <ExpandMore />}
                            <ListItemIcon><DashboardOutlined color="secondary" /></ListItemIcon>
                            <ListItemText primary="Dashboards" />
                        </ListItem>
                        <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
                            <List>
                                <ListItem button className={classes.nested}
                                    key="00"
                                    component={NavLink} to={`/dashboard`}>
                                    <ListItemIcon><AssessmentOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Visualizations/Reports" />
                                </ListItem>
                                {/* <ListItem button className={classes.nested}
                                    key="02"
                                    component={NavLink} to={`/dashboard`}>
                                    <ListItemIcon><NotificationsOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Alerts/Notifications" />
                                </ListItem> */}
                            </List>
                        </Collapse>
                    </React.Fragment>
                }
                {userContext.rights[1] === '1' &&
                    <React.Fragment>
                        <ListItem
                            key="1"
                            button
                            component={NavLink} to={`/contacts`}
                            onClick={handleToggleInventory}>
                            {inventoryOpen ? <ExpandLess /> : <ExpandMore />}
                            <ListItemIcon><PeopleOutlined color="secondary" /></ListItemIcon>
                            <ListItemText primary="Inventory Management" />
                        </ListItem>
                        <Collapse in={inventoryOpen} timeout="auto" unmountOnExit>
                            <List>
                                <ListItem button className={classes.nested}
                                    key="12"
                                    component={NavLink} to={`/contacts`}
                                    selected={`${location.pathname}` === `/contacts` ? classes.currentPage : null}>
                                    <ListItemIcon><ContactsOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Contacts" />
                                </ListItem>
                                {/* <ListItem button className={classes.nested}
                                    key="10"
                                    component={NavLink} to={`/accounts`}
                                    selected={`${location.pathname}` === `/accounts` ? classes.currentPage : null}>
                                    <ListItemIcon><AccountBalanceOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Accounts" />
                                </ListItem> */}
                                <ListItem button className={classes.nested}
                                    key="11"
                                    component={NavLink} to={`/import`}
                                    selected={`${location.pathname}` === `/import` ? classes.currentPage : null}>
                                    <ListItemIcon><MoveToInboxOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Contact Import" />
                                </ListItem>
                                <ListItem button className={classes.nested}
                                    key="13"
                                    component={NavLink} to={`/bulk-edit`}
                                    selected={`${location.pathname}` === `/bulk-edit` ? classes.currentPage : null}>
                                    <ListItemIcon><EditOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Bulk Edit" />
                                </ListItem>
                            </List>
                        </Collapse>
                    </React.Fragment>
                }
                {userContext.rights[2] === '1' &&
                    <React.Fragment>
                        <ListItem
                            key="2"
                            button
                            component={NavLink} to={`/segments`}
                            onClick={handleToggleList}>
                            {listOpen ? <ExpandLess /> : <ExpandMore />}
                            <ListItemIcon><LowPriorityOutlined color="secondary" /></ListItemIcon>
                            <ListItemText primary="List Management" />
                        </ListItem>
                        <Collapse in={listOpen} timeout="auto" unmountOnExit>
                            <List>
                                <ListItem button className={classes.nested}
                                    key="20"
                                    component={NavLink} to={`/segments`}
                                    selected={`${location.pathname}` === `/segments` ? classes.currentPage : null}>
                                    <ListItemIcon><FilterListOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Segments" />
                                </ListItem>
                                <ListItem button className={classes.nested}
                                    key="21"
                                    component={NavLink} to={`/campaigns`}
                                    selected={`${location.pathname}` === `/campaigns` ? classes.currentPage : null}>
                                    <ListItemIcon><SettingsPhoneOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Campaigns" />
                                </ListItem>
                                <ListItem button className={classes.nested}
                                    key="22"
                                    component={NavLink} to={`/profile`}
                                    selected={`${location.pathname}` === `/profile` ? classes.currentPage : null}>
                                    <ListItemIcon><RecentActorsOutlined color="primary" /></ListItemIcon>
                                    <ListItemText primary="Profiles" />
                                </ListItem>
                            </List>
                        </Collapse>
                    </React.Fragment>
                }
            </List>
        </div>
    );

	/**
	 * return function for rendering HTML
	 */
    return (
        <LayoutState.Provider value={layoutState}>
            <div className={classes.root}>
                <Drawer
                    container={container}
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen
                        })
                    }}
                >
                    {drawer}
                </Drawer>
                <div className={classes.content}>
                    <Switch>

                        <Route exact path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route path="/contacts" exact>
                            <Contacts />
                        </Route>
                        <Route path="/contact/:id">
                            <Contact />
                        </Route>
                        <Route path="/import">
                            <Import />
                        </Route>
                        <Route path="/bulk-edit">
                            <BulkEdit />
                        </Route>
                        <Route path="/segments" exact>
                            <Segments />
                        </Route>
                        <Route path="/segment/:id">
                            <Segment />
                        </Route>
                        <Route path="/campaigns/">
                            <Campaigns />
                        </Route>
                        <Route path="/profile/:id">
                            <Profile />
                        </Route>
                        <Route path="/profile">
                            <DialingProfiles />
                        </Route>
                        <Route path="/" exact>
                            <Redirect to="/dashboard" />
                        </Route>
                        <Route path="/dynamupdate" />
                            <DynamUpdate />
                    </Switch>
                </div>
            </div>
        </LayoutState.Provider>
    );
}

ListLayout.propTypes = {
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element)
};

export default ListLayout;
import React, { useEffect, useState, useRef, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Card, CardContent, CardHeader, Divider, IconButton, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AddCircleOutline, DeleteOutline, } from '@material-ui/icons';
import { graphqlOperation, API, Auth, } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import UserContext from '../context/UserContext';
import moment from 'moment';
import _, { set } from 'lodash';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    paper: {
        padding: '15px',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    topPad: {
        paddingTop: '8px',
        paddingBottom: '8px'
    },
    successfulPayment: {
        color: 'green'
    },
    paymentDeclined: {
        color: 'red'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    divider: {
        marginTop: '15px',
        marginBottom: '15px'
    },
    ratio: {
        width: '200px',
    }

}));

/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const CampaignScheduleDialog = function (props) {
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const actions = [
        { value: 'start', label: 'Start Campaign' },
        { value: 'pause', label: 'Pause Campaign' },
        { value: 'restart', label: 'Resume Campaign' },
        { value: 'reset', label: 'Reset Campaign' },
        { value: 'stop', label: 'End/Finalize Campaign' }
    ];
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [campaign, setCampaign] = useState();
    const [newActionEvent, setNewActionEvent] = useState({
        time: '08:00',
        days: [],
        action: '',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const [newRatioEvent, setNewRatioEvent] = useState({
        time: '08:00',
        days: [],
        segments: [],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const [actionEvents, setActionEvents] = useState([]);
    const [ratioEvents, setRatioEvents] = useState([]);
    const [toDelete, setToDelete] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);



    useEffect(() => {
        if (props.campaign) {
            setCampaign(props.campaign);
            if (props.campaign.campaignSchedule) {
                setActionEvents(JSON.parse(props.campaign.campaignSchedule));
            } else {
                setActionEvents([]);
            }
            if (props.campaign.segmentSchedule) {
                setRatioEvents(JSON.parse(props.campaign.segmentSchedule));
            } else {
                setRatioEvents([]);
            }
            if (props.campaign.segments && props.campaign.segments.length > 0) {
                const campaignSegments = JSON.parse(JSON.stringify(props.campaign.segments));
                setNewRatioEvent({ ...newRatioEvent, segments: JSON.parse(campaignSegments) });
            }

        }
    }, [props.campaign]);

    useEffect(() => {
        console.log('New Ratio Event', newRatioEvent)
    }, [newRatioEvent])

    /** Close refund dialog */
    async function closeDialog() {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    async function addEvent(type) {
        try {
            if ((newActionEvent.time || newRatioEvent.time) && (newActionEvent.days.length || newRatioEvent.days.length) && (newActionEvent.action || newRatioEvent.segments.length)) {
                const eventId = Math.floor(100000 + Math.random() * 900000);


                let body = {
                    tenant: userContext.tenant,
                    campaignId: campaign.id,
                    eventType: type,
                }

                if (type === 'campaignStatus') {
                    newActionEvent.id = eventId;
                    body.event = newActionEvent;
                } else if (type === 'segmentRatio') {
                    newRatioEvent.id = eventId;
                    body.event = newRatioEvent;
                }

                const result = await API.post(
                    'cdyxdialer',
                    '/cache/create-schedule',
                    {
                        headers: {
                            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                            'x-api-key': userContext.apiKey
                        },
                        body: {
                            ...body
                        }
                    }
                )

                newActionEvent.scheduleArn = result.ScheduleArn;

                if (type === 'campaignStatus') {

                    await API.graphql(graphqlOperation(mutations.updateCampaign, {
                        input: {
                            id: campaign.id,
                            campaignSchedule: JSON.stringify([...actionEvents, newActionEvent])

                        }
                    }))

                    setActionEvents([...actionEvents, newActionEvent]);
                    setNewActionEvent({ time: moment(newActionEvent.time, 'HH:mm').add(5, 'minutes').format('HH:mm'), days: newActionEvent.days, action: '', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
                } else if (type === 'segmentRatio') {
                    await API.graphql(graphqlOperation(mutations.updateCampaign, {
                        input: {
                            id: campaign.id,
                            segmentSchedule: JSON.stringify([...ratioEvents, newRatioEvent])
                        }
                    }))

                    setRatioEvents([...ratioEvents, newRatioEvent]);
                    setNewRatioEvent({ time: moment(newRatioEvent.time, 'HH:mm').add(5, 'minutes').format('HH:mm'), days: newRatioEvent.days, segments: newRatioEvent.segments, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });

                }
            } else {
                enqueueSnackbar('Please fill out all fields', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error adding event', error);
        }

    }

    async function deleteEvent() {
        try {
            let type = 'campaignStatus';

            if (toDelete.segments) {
                type = 'segmentRatio';
            }

            const result = await API.post(
                'cdyxdialer',
                '/cache/create-schedule',
                {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey
                    },
                    body: {
                        delete: true,
                        tenant: userContext.tenant,
                        campaignId: campaign.id,
                        event: toDelete,
                    }
                }
            )

            if (type === 'campaignStatus') {
                const updatedEvents = actionEvents.filter(event => event.id !== toDelete.id);
                await API.graphql(graphqlOperation(mutations.updateCampaign, {
                    input: {
                        id: campaign.id,
                        campaignSchedule: JSON.stringify(updatedEvents)
                    }
                }));
                setActionEvents(updatedEvents);
            } else if (type === 'segmentRatio') {
                const updatedEvents = ratioEvents.filter(event => event.id !== toDelete.id);
                await API.graphql(graphqlOperation(mutations.updateCampaign, {
                    input: {
                        id: campaign.id,
                        segmentSchedule: JSON.stringify(updatedEvents)
                    }
                }));
                setRatioEvents(updatedEvents);
            }
        } catch (err) {
            enqueueSnackbar('Error deleting event', { variant: 'error' });
            console.error('Error deleting event', err);
        }
        setDeleteDialogOpen(false);
    }


    return (
        <div>
            <Dialog maxWidth="sm" open={props.open}>
                <DialogTitle id="dp-dialog-title">Scheduling {props.campaign.name}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tabs
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                            >
                                <Tab onClick={() => setTab(0)} label="Campaign Actions" value={0}>


                                </Tab>
                                <Tab onClick={() => setTab(1)} label="Segment Ratios" value={1}>

                                </Tab>
                            </Tabs>
                            {tab === 0 &&
                                <Paper className={classes.paper}>
                                    <Card variant='outlined'>
                                        <CardHeader title='New Action Event' titleTypographyProps={{ variant: 'body2' }}
                                            action={<IconButton onClick={() => {
                                                addEvent('campaignStatus');
                                            }
                                            }>
                                                <AddCircleOutline color="primary" />
                                            </IconButton>}
                                        />
                                        <Divider />
                                        <CardContent>

                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography className={classes.topPad} variant='body2' color='textSecondary'>Days</Typography>
                                                    <ToggleButtonGroup value={newActionEvent.days} onChange={(e, days) => setNewActionEvent({ ...newActionEvent, days })}>
                                                        <ToggleButton value={0}>SU.
                                                        </ToggleButton>
                                                        <ToggleButton value={1}>MO.
                                                        </ToggleButton>
                                                        <ToggleButton value={2}>TU.
                                                        </ToggleButton>
                                                        <ToggleButton value={3}>WE.
                                                        </ToggleButton>
                                                        <ToggleButton value={4}>TH.
                                                        </ToggleButton>
                                                        <ToggleButton value={5}>FR.
                                                        </ToggleButton>
                                                        <ToggleButton value={6}>SA.
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.topPad} variant='body2' color='textSecondary'>Time</Typography>
                                                    <TextField
                                                        className={classes.formControl}
                                                        variant='standard'
                                                        type='time'
                                                        value={newActionEvent.time}
                                                        onChange={e => setNewActionEvent({ ...newActionEvent, time: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2' color='textSecondary'>This event's timing is based off of the {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.topPad} variant='body2' color='textSecondary'>Action</Typography>
                                                    <Select
                                                        className={classes.formControl}
                                                        value={newActionEvent.action}
                                                        onChange={e => setNewActionEvent({ ...newActionEvent, action: e.target.value })}
                                                    >
                                                        {actions.map(action => <MenuItem key={action.value} value={action.value}>{action.label}</MenuItem>)}
                                                    </Select>
                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Divider className={classes.divider} />

                                    <Card variant='outlined'>
                                        <CardHeader title='Scheduled Action Events' titleTypographyProps={{ variant: 'body2' }}
                                        />
                                        <Divider />
                                        <CardContent>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Time</TableCell>
                                                            <TableCell>Timezone</TableCell>
                                                            <TableCell>Days</TableCell>
                                                            <TableCell>Action</TableCell>
                                                            <TableCell>Delete</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {actionEvents.map(event => <TableRow key={event.id}>
                                                            <TableCell>{moment(event.time, 'HH:mm').format('hh:mm a')}</TableCell>
                                                            <TableCell>{event.timeZone}</TableCell>
                                                            <TableCell>
                                                                {event.days.includes(0) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SU.</ToggleButton>}
                                                                {event.days.includes(1) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>MO.</ToggleButton>}
                                                                {event.days.includes(2) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TU.</ToggleButton>}
                                                                {event.days.includes(3) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>WE.</ToggleButton>}
                                                                {event.days.includes(4) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TH.</ToggleButton>}
                                                                {event.days.includes(5) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>FR.</ToggleButton>}
                                                                {event.days.includes(6) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SA.</ToggleButton>}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event.action === 'start' && 'Start'}
                                                                {event.action === 'pause' && 'Pause'}
                                                                {event.action === 'restart' && 'Resume'}
                                                                {event.action === 'reset' && 'Reset'}
                                                                {event.action === 'stop' && 'End'}
                                                                </TableCell>
                                                            <TableCell><IconButton onClick={() => {
                                                                setToDelete(event);
                                                                setDeleteDialogOpen(true);
                                                            }} ><DeleteOutline color="primary" /></IconButton></TableCell>
                                                        </TableRow>)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                </Paper>}
                            {tab === 1 &&
                                <Paper className={classes.paper}>
                                    <Card variant='outlined'>
                                        <CardHeader title='New Segment Ratio Change Event' titleTypographyProps={{ variant: 'body2' }}
                                            action={<IconButton onClick={() => {
                                                addEvent('segmentRatio');
                                            }
                                            }>
                                                <AddCircleOutline color="primary" />
                                            </IconButton>}
                                        />
                                        <Divider />
                                        <CardContent>

                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <Typography className={classes.topPad} variant='body2' color='textSecondary'>Days</Typography>
                                                    <ToggleButtonGroup value={newRatioEvent.days} onChange={(e, days) => setNewRatioEvent({ ...newRatioEvent, days })}>
                                                        <ToggleButton value={0}>SU.
                                                        </ToggleButton>
                                                        <ToggleButton value={1}>MO.
                                                        </ToggleButton>
                                                        <ToggleButton value={2}>TU.
                                                        </ToggleButton>
                                                        <ToggleButton value={3}>WE.
                                                        </ToggleButton>
                                                        <ToggleButton value={4}>TH.
                                                        </ToggleButton>
                                                        <ToggleButton value={5}>FR.
                                                        </ToggleButton>
                                                        <ToggleButton value={6}>SA.
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.topPad} variant='body2' color='textSecondary'>Time</Typography>
                                                    <TextField
                                                        className={classes.formControl}
                                                        variant='standard'
                                                        type='time'
                                                        value={newRatioEvent.time}
                                                        onChange={e => setNewRatioEvent({ ...newRatioEvent, time: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2' color='textSecondary'>This event's timing is based off of the {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone</Typography>
                                                </Grid>
                                                <Grid item container direction='column'>
                                                    <Grid item>
                                                        <Typography className={classes.topPad} variant='body2' color='textSecondary'>Segments</Typography>
                                                    </Grid>
                                                    <Grid item container direction='row' spacing={2}>
                                                        {newRatioEvent.segments && newRatioEvent.segments.map(segment => <Grid className={classes.topPad} item key={segment.id}>
                                                            <TextField
                                                                className={classes.ratio}
                                                                label={segment.segmentName}
                                                                variant="outlined"
                                                                type='number'
                                                                InputProps={{
                                                                    inputProps: {
                                                                        max: 100, min: 0
                                                                    }
                                                                }}
                                                                value={segment.segmentRatio}
                                                                onChange={e => {
                                                                    const updatedSegments = newRatioEvent.segments.map(s => {
                                                                        if (s.segmentId === segment.segmentId) {
                                                                            return { ...s, segmentRatio: e.target.value }
                                                                        }
                                                                        return s;
                                                                    });
                                                                    setNewRatioEvent({ ...newRatioEvent, segments: updatedSegments });
                                                                }}
                                                            />
                                                        </Grid>)}
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Divider className={classes.divider} />

                                    <Card variant='outlined'>
                                        <CardHeader title='Scheduled Ratio Change Events' titleTypographyProps={{ variant: 'body2' }}
                                        />
                                        <Divider />
                                        <CardContent>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Time</TableCell>
                                                            <TableCell>Timezone</TableCell>
                                                            <TableCell>Days</TableCell>
                                                            <TableCell>Delete</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ratioEvents.map(event => <TableRow key={event.id}>
                                                            <TableCell>{moment(event.time, 'HH:mm').format('hh:mm a')}</TableCell>
                                                            <TableCell>{event.timeZone}</TableCell>
                                                            <TableCell>
                                                                {event.days.includes(0) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SU.</ToggleButton>}
                                                                {event.days.includes(1) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>MO.</ToggleButton>}
                                                                {event.days.includes(2) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TU.</ToggleButton>}
                                                                {event.days.includes(3) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>WE.</ToggleButton>}
                                                                {event.days.includes(4) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>TH.</ToggleButton>}
                                                                {event.days.includes(5) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>FR.</ToggleButton>}
                                                                {event.days.includes(6) && <ToggleButton style={{ width: '1.5em', height: '1em' }}>SA.</ToggleButton>}
                                                            </TableCell>
                                                            <TableCell><IconButton onClick={() => {
                                                                setToDelete(event);
                                                                setDeleteDialogOpen(true);
                                                            }} ><DeleteOutline color="primary" /></IconButton></TableCell>
                                                        </TableRow>)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                </Paper>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} variant='outlined' color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Delete Event</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this event?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => deleteEvent()} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
export default CampaignScheduleDialog;

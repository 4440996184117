import React, { useState, useEffect } from 'react';
import {Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaxRO from '../components/MaxRO';
import Paper from '@material-ui/core/Paper';

import { withRouter, useParams, useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import * as customQueries from '../graphql/custom-queries';
import * as queries from '../graphql/queries';
import * as jwtDecode from 'jwt-decode';
import * as request from 'superagent';
import awsconfig from '../aws-exports';
import * as graphql from 'graphql';
import gql from 'graphql-tag';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const { print } = graphql;

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    topPad: {
        paddingTop: '15px'
    },
    successfulPayment: {
        color: 'green'
    },
    paymentDeclined: {
        color: 'red'
    }

});

/**
 * A form that presents a contacts informtion in a Read Only Optimized format
 * 
 */
const ContactRO = function (props) {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const [apiKey, setApiKey] = useState();
    const [c3ApiKey, setC3ApiKey] = useState();
    const [authToken, setAuthToken] = useState();
    const [tenant, setTenant] = useState();
    const [vendorId, setVendorId] = useState();
    const [skill, setSkill] = useState();
    const [agentId, setAgentId] = useState();


    

    useEffect(() => {
        async function getData() {
            try {
                // lamda
                const passport = await API.post('cdyxdialer', '/direct-link/verify', {
                    body: {
                        id: id
                    }
                });

                const token = passport.token;
                const tokenInfo = jwtDecode(token);

                //setContactId(tokenInfo.contactId); Randall needs to remove this from the token
                setTenant(tokenInfo.tenant);
                setAuthToken(tokenInfo.cognitoToken);
                setSkill(tokenInfo.skillId);
                setAgentId(tokenInfo.agentId);
                setVendorId(tokenInfo.vendorId);
                setC3ApiKey(tokenInfo.apiKey);

                //Get the tenant
                const returnResponse = await request.post(awsconfig.aws_appsync_graphqlEndpoint)
                    .set('Authorization', tokenInfo.cognitoToken)
                    .send({
                        query: print(gql`${queries.getTenantSettings}`),
                        variables: {
                            id: tokenInfo.tenant,
                            //contactId: tokenInfo.contactId
                        }
                    });
                //const tenantCustomFields = screenPopResponse.body.data.getTenantSettings.customFields;
                setApiKey(returnResponse.body.data.getTenantSettings.apiKey);
                //setCustomFields(tenantCustomFields);
                // const contact = screenPopResponse.body.data.getContact;
                // if (contact.customFields) {
                //     contact.customFields = JSON.parse(contact.customFields);
                // } else {
                //     contact.customFields = {};
                // }
                //setContact(contact);
                // Get the activity Log
                // const actList = screenPopResponse.body.data.getContactActivity.items;
                // const sortedActivities = _.orderBy(actList, ['timestamp'], ['desc']);
                // setActivities(sortedActivities);

                // activitylog subscription
            // const appSyncClient = new AWSAppSyncClient({
            //     url: awsconfig.aws_appsync_graphqlEndpoint,
            //     region: awsconfig.aws_appsync_region,
            //     auth: {
            //         type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            //         jwtToken: tokenInfo.cognitoToken
            //     }
            // });
    
            // client = await appSyncClient.hydrated();
            // setClient(client);
            // const activitylogSubscription = client.subscribe({ query: gql(customQueries.onActivityLogCreate), variables: {tenant: tokenInfo.tenant} }).subscribe({
            //     next: data => {
            //         updateScreenPop(data.data.onTenantCreateActivityLog, tokenInfo.contactId, sortedActivities);
            //       },
            //       error: error => {
            //         console.warn(error);
            //       }
            // })
            // return () => {
            //     activitylogSubscription.unsubscribe();
            // }

            } catch (err) {
                console.error(err);
                history.push('/')
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // async function updateScreenPop(data, id, activities){
    //     console.log(data);
    //     if(data.type === 'PromptPay' && data.activityLogContactId === id){
    //         setActivities([data, ...activities]);
    //         if (data.title === 'PromptPay Opened Event'){
    //             enqueueSnackbar('Payment link has been opened', { 
    //                 variant: 'info'
    //             });
    //         } else if (data.title === 'PromptPay Attempted Event'){
    //             const metaData = JSON.parse(data.metaData);
    //             console.log(metaData);
    //             if (metaData.transaction){
    //                 enqueueSnackbar('Payment Successful', { 
    //                     variant: 'success'
    //                 });
    //             } else if (metaData.transactionMeta) {
    //                 enqueueSnackbar('Payment Declined', { 
    //                     variant: 'error'
    //                 });
    //             }
    //         }
    //     }
    // }

    // const openEditContact = () => {
    //     setEditContactOpen(true);
    // }

    // const openCreateNote = () => {
    //     setCreateNoteOpen(true);
    // }

    // const handleOpenBalances = () => {
    //     setOpenBalances(true);
    // }

    // const handleCloseBalances = () => {
    //     setOpenBalances(false);
    // }

    // const cancelCreateNote = () => {
    //     setCreateNoteOpen(false);
    // }
    
    // const handleOpenManual = () => {
    //     setOpenManual(true);
    // }

    // const handleCloseManual = () => {
    //     setOpenManual(false);
    // }

    // const handleUpdatedContact = (contact) => {
    //     const updatedContact = { ...contact };
    //     updatedContact.customFields = JSON.parse(updatedContact.customFields);
    //     setContact(updatedContact);
    //     setEditContactOpen(false);
    //     //window.location.reload(false);
    // }

    // const handleNoteCreated = () => {
    //     setCreateNoteOpen(false);
    //     window.location.reload(false);
    // }
    
    return (
        <Grid container direction="column" spacing={3} justify="flex-start">
            <Grid item container diretion="row" spacing={1} xs={12} >
                <Paper className={classes.paper} style={{ borderLeft: '3px solid #0277bd' }}>
                    <Grid item container direction="column" justify="center">
                        <MaxRO
                            tenant={tenant}
                            agentId={agentId}
                            vendorId={vendorId}
                            c3ApiKey={c3ApiKey}
                        />
                    </Grid>

                </Paper>
            </Grid>

        </Grid>
    )
}

export default withRouter(ContactRO);
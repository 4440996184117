import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

/**
 * A bar chart visualization for displaying the aggregated sources of dispostitioned contacts 
 * @component
 */
const BarChart = function (props) {
    //const classes = useStyles();
    const [data, setData] = useState({});
    const propsTitle = props.title;


    useEffect(() => {

        async function getData() {
            try {
                const idToken = await (await Auth.currentSession()).getIdToken();
                const tenant = idToken.payload['custom:tenant'];
                const propsField = props.field;
                const propsFilter = props.filter;
                let query = {};  
                const must = getMustIncludes(tenant);
                let dataValue;
                let labelValue;

                if (props.agg === 'sum' && props.eventType === 'PromptPay'){
                    const requestedAmountQuery = getPromptPaySums('metaData.paymentRequest.amountDue');
                    const paidAmountQuery = getPromptPaySums('metaData.transaction.transactionAmount');

                    const requestedJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(requestedAmountQuery)
                    }));
                    const collectedJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(paidAmountQuery)
                    }));
                    const requestedResult = JSON.parse(requestedJSON.data.esQuery);
                    const collectedResult = JSON.parse(collectedJSON.data.esQuery);
                    dataValue = [requestedResult.aggregations.dispo.value.toFixed(2), collectedResult.aggregations.dispo.value.toFixed(2)];
                    labelValue = ['Requested Sum', 'Collected Sum'];
                    // if (props.admin !== true){
                    //     let resData = queryResult['aggregations'].dispo.buckets;
                    //     dataValue = resData.map(obj => {return obj.doc_count});
                    //     labelValue = resData.map(obj => {return obj.key});
                    // } else if (props.admin === true){
                    //     let resData = queryResult['aggregations'].tenant.buckets;
                    //     dataValue = resData.map(obj => {return obj.agent.buckets.length});
                    //     labelValue = resData.map(obj => {return obj.key});
                    // }
                } else {
                   
                    let agent;
                    if (props.model === 'ciflog'){
                        agent = 'agent'
                    } else {
                        agent = 'agent.keyword'
                    }



                    if (props.admin === true){
                        query = {
                            "aggs": {
                                "tenant": {
                                    "terms": {
                                        "field": "tenant.keyword",
                                        "size": 2147483647
                                    },
                                    "aggs": {
                                        "agent": {
                                            "terms": {
                                                "field": agent,
                                                "size": 2147483647
                                            }
                                        }
                                    }
                                }
                            },
                            "query": {
                                "bool": {
                                    "must": [
                                        {
                                            "match_phrase": {
                                                "type": props.eventType
                                            }
                                        },
                                        {
                                            "range": {
                                                "createdAt": {
                                                    "gte":  props.startTime,
                                                    "lte": props.endTime
                                                }
                                            }
                                        }
                                    ]
                                }
                            },
                            "size": 0
                        }
                    } else {
                        if (propsFilter){
                            query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                            "query":{
                                "bool":{
                                    must,
                                    "must_not":{
                                        "term": {[propsField]:propsFilter}
                                    }
                                }
                            },
                            "size":0}
                        }
                        else{
                            query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                            "query":{
                                "bool":{
                                    must
                                }
                            },    
                            "size":0}    
                        }    
                    }
                            
                    const resultJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                        model: props.model,
                        action: '_search',
                        query: JSON.stringify(query)
                    }));
                    const queryResult = JSON.parse(resultJSON.data.esQuery);

                    if (props.admin !== true){
                        let resData = queryResult['aggregations'].dispo.buckets;
                        dataValue = resData.map(obj => {return obj.doc_count});
                        labelValue = resData.map(obj => {return obj.key});
                    } else if (props.admin === true){
                        let resData = queryResult['aggregations'].tenant.buckets;
                        dataValue = resData.map(obj => {return obj.agent.buckets.length});
                        labelValue = resData.map(obj => {return obj.key});
                    }
                }

                const colorTheme = [];
                for (let i = 0; i < dataValue.length; i++) {
                    colorTheme.push(`rgba(${Math.floor((Math.random() * 255) + 1)}, ${Math.floor((Math.random() * 255) + 1)}, ${Math.floor((Math.random() * 255) + 1)}, .6)`);
                }
                

                setData({ datasets: [{ barPercentage: 0.6, barThickness: 30, maxBarThickness: 40, minBarLength: 2, data: dataValue, backgroundColor: colorTheme}], labels: labelValue});
                //console.log(data);

        } catch (err) {
            console.error(err);
        }
    }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title,props.filter,props.field, props.campaignId, props.model, props.agent, props.startTime, props.endTime]);

    const getPromptPaySums = (field) => {
        const query = {
            "aggs": {
                "dispo": {
                    "sum": {
                        "field": field
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "type": "PromptPay"
                            }
                        },
                        {
                            "range": {
                                "createdAt": {
                                    "gte":  props.startTime,
                                    "lte": props.endTime
                                }
                            }
                        }
                    ]
                }
            },
            "size": 0
        }
        return query;
    }



const getMustIncludes = (tenant) => {
    const incArray = [];
    //Always include the tenent 
    incArray.push({ "term": { "tenant.keyword": tenant } });
    //include Disposition Type 
    if (props.eventType) {
        incArray.push({ "match_phrase": { "type": props.eventType } });
    }
    if (props.campaignId) {
        incArray.push({ "match_phrase": { "metaData.AdditionalInfo.campaignId": props.campaignId } });
    }
    if (props.agent) {
        incArray.push({ "match_phrase": { "agent": props.agent } });
    }
    incArray.push({
        "range": {
            "createdAt": {
                "gte": props.startTime,
                "lte": props.endTime
            }
        }
    })


    return incArray;
}

return (
    <Grid item >
        < Bar data={data} options={{
            title: { display: true, text: propsTitle }, legend: false, scales: {
                yAxes: [{
                    ticks: {
                        min: 0,
                    }
                }]
            }
        }} />
    </Grid>
);
}


export default BarChart;
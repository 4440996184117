import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import ConfigIcon from '@material-ui/icons/SettingsOutlined';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Grid, Typography, IconButton, CircularProgress, Button, Dialog, Select, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import * as queries from '../graphql/queries';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { setSelectedAccount } from './MSALConfig';
const request = require('superagent');


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    }, 
    formControl: {
        minWidth: 120,
        maxWidth: 350,
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        height: '350px',
        padding: '20px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    test: {
        height: '4em'
    }
}));

const Prerequisites = function(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const [success, setSuccess] = useState(false);

    const [showAccountSelect, setShowAccountSelect] = useState(false);
    const [microsoftAccounts, setMicrosoftAccounts] = useState([]);
    const [selAccount, setSelAccount] = useState();

    const [uiConfigReady, setUIConfigReady] = useState('');
    const [nicConfigReady, setNICConfigReady] = useState('');
    const [crmConfigReady, setCrmConfigReady] = useState('');
    const [microsftConfigReady, setMicrosoftConfigReady] = useState('');

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {
        async function getData(){
            if (props.accept === true){
                setSuccess(true);
            }
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant])


    useEffect(() => {
        if (props.microsoftAccounts){
            setMicrosoftAccounts([...props.microsoftAccounts]);
        }
        if (props.microsoftAccounts.length === 1){
            setSelAccount(props.microsoftAccounts[0]);
            setSelectedAccount(props.microsoftAccounts[0]);
            props.setMicrosoftAccount(props.microsoftAccounts[0]);
            setShowAccountSelect(false);
        }
        
    }, [props.microsoftAccounts])

    useEffect(() => {
        if (uiConfigReady === 'true' && nicConfigReady === 'true' && crmConfigReady === 'true' && microsftConfigReady === 'true'){
            props.setAccept(true);
            setSuccess(true);
        }
    }, [uiConfigReady, nicConfigReady, crmConfigReady, microsftConfigReady]);

    useEffect(() => {
        if (selAccount){
            testOrgs();
        }

        async function testOrgs(){
            setMicrosoftConfigReady('');

            if (props.onPremAccessUrl === ''){
                try {
                    const testOrgs = await props.getOrgs();
                    if (testOrgs.length >= 1){
                        setMicrosoftConfigReady('true');
                    } else {
                        setMicrosoftConfigReady('false');
                    }
                } catch (err){
                    enqueueSnackbar(err, {
                        variant: 'error'
                    });
                    setMicrosoftConfigReady('false');
                }
                
            } else {
                setMicrosoftConfigReady('true')
            }
        }

    }, [selAccount])

    async function chooseAccount(){
        props.getMicrosoftAccounts();
        setShowAccountSelect(true);
    }

    async function testPrerequisites() {
        setUIConfigReady('');
        setNICConfigReady('');
        setCrmConfigReady('');
        setMicrosoftConfigReady('');

        
        const crmResult = await testCrmConfig();
        if (crmResult){
            const nicResult = await testNICConfig();
            if (nicResult){
                const uiResult = await testUIConfig();
                if (uiResult){
                    await testMicrosoftAccount();
                }
            }
        }
    }

    async function testUIConfig(){
        let defaultConfig;
        const configProfile = await API.graphql(graphqlOperation(queries.configProfielsByTenant, {tenant: userContext.tenant}));
        const data = configProfile.data.configProfielsByTenant.items;

        // Check for base config

        for(const profile of data){
            if (profile.mediaType === 'baseConfig'){
                defaultConfig = profile;
            }
        }

        if (defaultConfig) {
            setUIConfigReady('true');
            return true
        } else {
            setUIConfigReady('false')
            return false
        }
    }

    async function testNICConfig(){
        const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
        const tenantSettings = dynamoSettings.data.getTenantSettings;
        let testConnectionResult;

        try {
            const loginResult = await API.post('cdyxdialer', '/login/nic', {
                headers: {
                  Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
                  "x-api-key": tenantSettings.apiKey,
                },
                body: {},
              })

              console.log('login result', loginResult);
              if (loginResult.token){
                setNICConfigReady('true');
                return true
              } else {
                setNICConfigReady('false');
                return false
              }
    
        } catch (err){
            console.error('login failed', err);
            setNICConfigReady('false');
            return false
        }

    }

    async function testCrmConfig(){
        if (props.e2ClientId && props.e2AppSecret){
            setCrmConfigReady('true');
            return true
        } else {
            setCrmConfigReady('false');
            return false
        }
    }

    async function testMicrosoftAccount(){
        await chooseAccount();
    }

    const handleAccountChange = (event) => {
        const account = event.target.value;
        setSelAccount(account);
        setSelectedAccount(account);
        props.setMicrosoftAccount(account);
    }
    
    const goToConfig = (path) => () => {
        props.history.push(path);
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justifyContent="flex-start" alignItems="stretch">
                        <Grid item>
                            <Typography>Before continuing you must:</Typography>
                            <ol>
                                <Grid item container direction="row" spacing={2} alignItems="center" className={classes.test}>
                                    <Grid item>
                                        1. Have set up your Dynamics CRM configuration of this interface.
                                        <IconButton onClick={goToConfig('/config/inventory-source')}>
                                            <ConfigIcon color="primary" fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    {crmConfigReady === 'true' && <Grid item>
                                        <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                    </Grid>}
                                    {crmConfigReady === 'false' && <Grid item>
                                        <CloseOutlinedIcon color="error"></CloseOutlinedIcon>
                                    </Grid>}
                                </Grid>
                                <Grid item container direction="row" spacing={2} alignItems="center" className={classes.test}>
                                    <Grid item>
                                        2. Have set up your InContact configuration of this interface.
                                        <IconButton onClick={goToConfig('/config')}>
                                            <ConfigIcon color="primary" fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    {nicConfigReady === 'true' && <Grid item>
                                        <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                    </Grid>}
                                    {nicConfigReady === 'false' && <Grid item>
                                        <CloseOutlinedIcon color="error"></CloseOutlinedIcon>
                                    </Grid>}
                                </Grid>
                                <Grid item container direction="row" spacing={2} alignItems="center" className={classes.test}>
                                    <Grid item>
                                        3. Set up your Default UI Configuration.
                                        <IconButton onClick={goToConfig('/e2/ui-config')}>
                                            <ConfigIcon color="primary" fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    {uiConfigReady === 'true' && <Grid item>
                                        <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                    </Grid>}
                                    {uiConfigReady === 'false' && <Grid item>
                                        <CloseOutlinedIcon color="error"></CloseOutlinedIcon>
                                    </Grid>}
                                </Grid>
                                {!props.onPremAccessUrl && <Grid item container direction="row" spacing={2} alignItems="center" className={classes.test}>
                                    <Grid item>
                                        4. Select the Microsoft account you'll be using. You must have system adminstration privileges for the Dynamics orginization you are configuring.
                                    </Grid>
                                    {microsftConfigReady === 'true' && <Grid item>
                                        <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                    </Grid>}
                                    {microsftConfigReady === 'false' && <Grid item>
                                        <CloseOutlinedIcon color="error"></CloseOutlinedIcon>
                                    </Grid>}
                                </Grid>}
                            </ol>
                            <Grid item container direction="row" spacing={2} alignItems="center">
                                <Button onClick={testPrerequisites} variant="outlined" className={buttonClassname} color="primary">
                                    Run Tests
                                </Button>
                                {success && <Grid item>
                                    <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                </Grid>}
                            </Grid>

                        </Grid>
                    </Grid>
                    <br></br>
                    <Dialog open={showAccountSelect} onClose={() => setShowAccountSelect(false)}>
                        <DialogTitle>Microsoft Account Selection</DialogTitle>
                        <DialogContent>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Microsoft Account</InputLabel>
                                <Select
                                    margin="dense"
                                    name="accountSelect"
                                    value={selAccount}
                                    className={classes.selectFields}
                                    onChange={handleAccountChange}>
                                    {microsoftAccounts.map(account => 
                                        <MenuItem key={account.username} value={account}>{account.username}</MenuItem>    
                                    )}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' color='primary' onClick={() => setShowAccountSelect(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                
            )}
        </UserContext.Consumer>
    );
}


export default withRouter(Prerequisites);
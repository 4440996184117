import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Grid,
  TablePagination,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { HelpOutlineOutlined } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Connect } from 'aws-amplify-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql/custom-queries';
import ContactDialog from '../components/ContactDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import UserContext from '../context/UserContext';
import * as _ from 'lodash';
import * as moment from 'moment';
import LayoutState from '../context/LayoutState';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  mainWidth: {
    maxWidth: '100%',
    flexShrink: 1
  },
  flexGrow: {
    flexGrow: 1
  },
  padRight: {
    paddingRight: theme.spacing(2)
  },
  drawerOpenTableWidth: {
    maxWidth: `calc(100vw - ${theme.drawer.open + theme.spacing(4)}px)`
  },
  drawerClosedTableWidth: {
    maxWidth: `calc(100vw - ${theme.drawer.closed + theme.spacing(4)}px)`
  }
}));

/**
 * The Contact List material table.
 * @component
 */
export default function ContactList(props) {

  const history = useHistory();
  const userContext = useContext(UserContext);
  const layoutContext = useContext(LayoutState);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toDelete, setToDelete] = useState(undefined);
  const [docCount, setDocCount] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const contact = {};
  const editContactOpen = false;
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [nextToken, setNextToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageTokens, setPageTokens] = useState({});
  const [contactColumns, setContactColumns] = useState([
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    { title: 'Source', field: 'source' },
    { title: 'Phone', field: 'phone' },
    { title: 'Cell', field: 'cell' },
    { title: 'Email', field: 'email' },
    { title: 'Address', field: 'address1' },
    { title: 'City', field: 'city' },
    { title: 'State', field: 'state' },
    { title: 'Zipcode', field: 'zip' },
    { title: 'Timezone', field: 'timeZone' }
  ]);

  const docCountQuery = {
    "aggs": {
      "dispo": {
        "terms": {
          "field": "tenant.keyword"
        }
      }
    },
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "tenant.keyword": userContext.tenant
            }
          }
        ]
      }
    },
    "size": 0
  }

  useEffect(() => {
    if (userContext.customFields) {
      const newContactColumns = [...contactColumns];
      for (const customField of userContext.customFields) {
        //preview column setup
        const existingColumn = _.find(newContactColumns, ['field', customField.name]);
        if (!existingColumn) {
          newContactColumns.push({
            title: customField.displayName,
            field: `custom:${customField.name}`,
            sorting: false,
            render: rowData => (rowData.customFields && renderCustomField(customField.name, customField.type, rowData))
          });
        }
      }
      setContactColumns(newContactColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.customFields]);

  useEffect(() => {
    if (userContext.tenant) {
      loadTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.tenant])

  const loadTotal = async () => {
    const resultJSON = await API.graphql(graphqlOperation(queries.esQuery, {
      query: JSON.stringify(docCountQuery),
      action: '_search',
      model: 'contact'
    }));
    const queryResult = JSON.parse(resultJSON.data.esQuery);
    const docHits = queryResult?.hits?.total ? queryResult.hits.total : 0;
    setTotalDocs(docHits);
  }

  /**
   * Render the field data in the table
   * @param {String} fieldName 
   * @param {String} fieldType One of String, DateTime, Number, Boolean
   * @param {any} rowData 
   */
  const renderCustomField = (fieldName, fieldType, rowData) => {
    const customFields = JSON.parse(rowData.customFields);
    if (customFields[fieldName]) {
      if (fieldType === 'DateTime') {
        return moment(customFields[fieldName], 'x').format('LLL');
      } else if (fieldType === 'Number' || fieldType === 'String') {
        return customFields[fieldName] + '';
      } else if (fieldType === 'Boolean') {
        return customFields[fieldName] ? 'True' : 'False';
      }
    } else {
      return '';
    }
  }

  /**Currently unused
   * 
   * Open the edit contact dialog.
   * @param {any} contact The contact you want to edit.
   *
  const editContact = (contact) => {
      const editContact = { ...contact };
      if (editContact.account) {
          editContact.contactAccountId = editContact.account.id;
          delete editContact.account;
      }
      delete editContact.tableData;
      for (const key in editContact) {
          editContact[key] = editContact[key] || '';
      }
      setContact(editContact);
      setEditContactOpen(true);
  }
  */

  /**
   * Open confirmation dialog to make sure you want to delete a contact.
   */
  const deleteContact = (contact) => {
    setToDelete(contact);
    setConfirmOpen(true);
  }

  /**
   * You don't actually want to delete anyhting.
   */
  const cancelDelete = () => {
    setConfirmOpen(false);
    setToDelete(undefined);
  }

  /**
   * Send the mutation request to appsync to delete the contact.
   * @param {any} contact The contact you want to delete.
   */
  const handleDelete = async (contact) => {
    setConfirmOpen(false);
    setToDelete(undefined);
    //console.log('yes, you are');
    await API.graphql(graphqlOperation(mutations.deleteContact, { input: { id: contact.id } }));
    const user = await (await Auth.currentSession()).getIdToken();
    await API.graphql(graphqlOperation(customQueries.createActivityLogDeleteContact, {
      input: {
        type: 'System',
        title: 'Contact Deleted',
        body: `Contact deleted by ${user.payload.email}`,
        metaData: JSON.stringify(contact),
        timestamp: new Date(),
        activityLogContactId: contact.id,
        agent: user.payload.email,
        tenant: userContext.tenant,
        contactStatus: null
      }
    }));
    enqueueSnackbar('Contact Deleted');
    console.log('contact deleted');
    window.location.reload(false);
  }

  const searchContacts = async (query) => {
    if (!userContext.tenant) {
      return {
        data: [],
        page: currentPage,
        totalCount: docCount
      };
    }
    try {
      let currentPageTokens = {
        ...pageTokens
      }
      console.log('query', query);
      const params = {
        tenant: userContext.tenant
      };
      if (query.search) {
        params.searchQuery = query.search;
        setSearchQuery(query.search);
      }
      if (query.page > currentPage) {
        params.nextToken = nextToken;
        currentPageTokens[query.page] = nextToken;
      } else {
        if (query.search === searchQuery) {
          params.nextToken = currentPageTokens[query.page];
        } else {
          params.nextToken = null;
          currentPageTokens = {
            0: null
          }
          setCurrentPage(0);
        }
      }
      if (query.pageSize) {
        params.limit = query.pageSize;
        setPageSize(query.pageSize);
      }
      if (query.orderBy) {
        let sortField = `${query.orderBy.field}.keyword`;
        if (sortField.startsWith('custom:')) {
          const customName = sortField.replace('custom:', '');
          const customField = _.find(userContext.customFields, ['name', customName])
          if (customField && customField.type === 'String') {
            sortField = `${sortField.replace('custom:', 'customFields.')}`;
          } else {
            sortField = `${sortField.replace('custom:', 'customFields.').replace('.keyword', '')}`;
          }
        }
        params.sort = [
          { field: sortField, direction: query.orderDirection }
        ]
      }
      const simpleSearch = await API.graphql(graphqlOperation(queries.simpleContactSearch, params));

      if (simpleSearch.data.simpleContactSearch !== null) {
        setDocCount(simpleSearch.data.simpleContactSearch.total === null ? 0 : simpleSearch.data.simpleContactSearch.total);
        setNextToken(simpleSearch.data.simpleContactSearch.nextToken);
        setCurrentPage(query.page);
        setPageTokens(currentPageTokens);
        console.log(simpleSearch);
        return {
          data: simpleSearch.data.simpleContactSearch.items,
          page: query.page,
          totalCount: simpleSearch.data.simpleContactSearch.total
        };
      } else {
        return {
          data: [],
          page: query.page,
          totalCount: 0
        };
      }
    } catch (err) {
      console.error(err);
    }
  }

  const contactPaging = (props) => {
    return (
      <TablePagination
        count={props.count || 0}
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        onChangePage={props.onChangePage}
        onChangeRowsPerPage={props.onChangeRowsPerPage} />
    );
  }

  const toolBar = (props) => {
    return (
      <Grid container direction="column" alignItems="stretch">
        <Grid item container direction="row" alignItems="center">
          <Grid item className={classes.flexGrow}>
            <MTableToolbar {...props} />
          </Grid>
          <Grid item className={classes.padRight}>
            <Tooltip
              placement="top-start"
              leaveDelay={500}
              title={
                <React.Fragment>
                  <Typography variant="body1">
                    <strong>Advanced search options:</strong><br />
                    <ul>
                      <li>+ signifies AND operation</li>
                      <li>| signifies OR operation</li>
                      <li>- negates a single token</li>
                      <li>" wraps a number of tokens to signify a phrase for searching</li>
                      <li>* at the end of a term signifies a prefix query</li>
                      <li>( and ) signify precedence</li>
                    </ul>
                  </Typography>
                </React.Fragment>
              }>
              <IconButton size="small"><HelpOutlineOutlined color="primary" /></IconButton>
            </Tooltip>
          </Grid>

        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item container
      direction="column"
      justify="flex-start"
      alignItems="stretch">
      {userContext.tenant &&
        <Grid item zeroMinWidth
          className={
            clsx({
              [classes.drawerOpenTableWidth]: layoutContext.drawerOpen,
              [classes.drawerClosedTableWidth]: !layoutContext.drawerOpen
            })
          }>
          <MaterialTable
            columns={contactColumns}
            data={searchContacts}
            title={<Typography variant="body1">{`Showing ${docCount} of ${totalDocs} contacts`}</Typography>}
            options={{
              filtering: false,
              pageSize: pageSize,
              paginationType: "normal",
              emptyRowsWhenPaging: true,
              debounceInterval: 500,
              searchAutoFocus: true,
            }}
            actions={[
              {
                icon: () => (<DeleteIcon color="primary" />),
                tooltip: 'Delete Contact',
                onClick: (event, rowData) => {
                  deleteContact(rowData);
                }
              }
            ]}
            onRowClick={(event, rowData) => {
              history.push(`/contact/${rowData.id}`);
            }}
            components={{
              Pagination: contactPaging,
              Toolbar: toolBar
            }}
            style={{ maxWidth: '100%' }}
          />
        </Grid>
      }
      <ConfirmDialog
        open={confirmOpen}
        value={toDelete}
        onConfirm={handleDelete}
        onCancel={cancelDelete}
        confirmTxt="Yes">
        <Typography>Your contact will be permanently deleted.</Typography>
      </ConfirmDialog>
      <Connect mutation={graphqlOperation(mutations.updateContact)}>
        {({ mutation }) => (
          <ContactDialog contact={contact} editContact={mutation} open={editContactOpen} />
        )}
      </Connect>
    </Grid>
  )

}

ContactList.propTypes = {

};
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function AppPreview() {
    const { url } = useParams();
    const [urlToDisplay, setUrlToDisplay] = useState("");
    useEffect(() => {
        if(url){
            const decoded = decodeURIComponent(url);
            setUrlToDisplay(decoded);
        }
    }, [url]);

    return (
        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'white', zIndex: 10000}}>
            <Grid container direction='column' justify='center' alignItems='center' alignContent='center' spacing={2} style={{width: '100%'}}>
                <Grid container item direction='row' justify='center' spacing={2} style={{paddingTop: '30px'}}>
                    <Grid item>
                        <img src="https://images.squarespace-cdn.com/content/5da77db8bef9e657e838aa20/1579031685035-GA11ULZGNMLGUXNRKYNW/CdyxLogo.png?content-type=image%2Fpng" alt="CDYX Logo" />
                    </Grid>
                    <Grid item>
                        <Typography variant='h4'>Onboarding</Typography>
                    </Grid>
                </Grid>
                <Grid item style={{width: '90%', height: '80vh'}}>
                    <iframe title='cdyx-onboarding-app-container' width='100%' height='100%' src={urlToDisplay} style={{borderStyle: 'none'}}/>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={window.close}>Close</Button>
                </Grid>
            </Grid>
        </div>
    );

}
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, Typography, Button } from '@material-ui/core';
import listHOC from '../components/ListHOC';
import IVRProfileList from '../E2/IVRProfileList';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { withRouter } from 'react-router-dom';

const styles = {
    table: {
        minWidth: 650
    }
};

const IvrProfiles = function(props) {
   
    const handleButtonClick = (path) => () => {
        props.history.push(path, {comingFrom: 'New'});
    }

    const IVRProfileListHOC = listHOC(IVRProfileList, queries.listPreProcesss, subscriptions.onUpdatePreProcess,
        subscriptions.onCreatePreProcess, subscriptions.onDeletePreProcess);


    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Grid item container direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item container direction="row" justify="space-between" alignItems="flex-end">
                            <Grid item>
                                <Typography variant="h5">
                                    PreProcess Configuration Profiles
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleButtonClick('/e2/ivr/profiles')} color="primary" variant="contained">+ Profile</Button>
                            </Grid>
                        </Grid>
                        <br></br>
                        <IVRProfileListHOC tenant={tenant} listProperty="listPreProcesss" onCreateProperty="onCreatePreProcess"
                        onUpdateProperty="onUpdatePreProcess" onDeleteProperty="onDeletePreProcess" />
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}

export default withRouter(withStyles(styles)(IvrProfiles));
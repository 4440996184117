import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import UserContext from '../context/UserContext';
import { Button, CircularProgress} from '@material-ui/core';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { Formik } from 'formik';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import Validations from './YupValidations';
import * as queries from '../graphql/queries';
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    inputField: {
        minWidth: '350px'
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function UserHub(props) {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [toggleSaveButton, setSaveButtonActive] = useState(false);
    const [settings, setSettings] = useState({ icAccessKey: '', icUserName: '', icApp: '', icPassword: '',  icVendor: '', icAppSecret: '', icScriptName: undefined, icBU: '', icCluster: props.icCluster, icAPIOverride: '', icDiscoveryDomain: 'https://na1.nice-incontact.com/.well-known/cxone-configuration', icGetAuthTokenURL: 'https://na1.niceincontact.com/authentication/v1/token/access-key' });
    const userContext = useContext(UserContext);
    const [testing, setTesting] = useState(false);
    const [success, setSuccess] = useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {
        async function getData() {
            try {
                console.log(userContext.tenant);
                const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
                const tempAll = dynamoSettings.data.getTenantSettings;
                
                if(!tempAll.icCluster){
                    tempAll.icCluster = props.icCluster;
                }
                
                setSettings({...tempAll});
                
                console.log(settings);

            } catch (err) {
                console.error(err);
            }
        }
        if (userContext.tenant) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const testCXOneConnection = (values) => async () => {
        const tenantSettings = { ...values }

        try {
            setTesting(true);
            setSuccess(false);
            const postTestAuth = await API.post(
                'cdyxdialer',
                '/login/nic/test',
                {
                    body: {
                        tenantSettings
                    },
                    headers:{
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey,
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (postTestAuth) {
                enqueueSnackbar('Connection Successful' );
                setSaveButtonActive(true);
                setSuccess(true);
            }
            else {
                enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                    variant: 'error',
                    autoHideDuration: 5000
                });
                setSuccess(false);
            }
            setTesting(false);
            
        } catch (err) {
            enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                variant: 'error',
                autoHideDuration: 5000
            });
            setTesting(false);
            setSuccess(false);
        }
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Formik
                        validateOnMount={true}
                        initialValues={settings}
                        enableReinitialize={true}
                        validationSchema={Validations.cxOneValidation}
                        onSubmit={async values => {
                            //dynamodb doesn't like empty strings turn empty into null
                            try {
                                setSettings({...values});
                                const nicConfig = { id: tenant, ...values};
                                nicConfig.icUserName = '';
                                nicConfig.icPassword = '';
                                nicConfig.icVendor = '';
                                nicConfig.icApp = '';
                                for (const key in nicConfig) {
                                    nicConfig[key] = nicConfig[key] || null;
                                }
                                console.log(nicConfig);
                                delete nicConfig.createdAt;
                                delete nicConfig.updatedAt;
                                await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: nicConfig }));
                                enqueueSnackbar('Save Successful.')

                            } catch (err) {
                                enqueueSnackbar('Save Failed. ', { variant: 'error' });
                            }

                        }}
                    >
                        {formikCxoneprops => (
                            <form key="cxone" onSubmit={formikCxoneprops.handleSubmit}>
                                <Grid container direction='column' spacing={2}>
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icBU"
                                                    label="Business Unit"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikCxoneprops.handleChange}
                                                    onBlur={formikCxoneprops.handleBlur}
                                                    value={formikCxoneprops.values.icBU}
                                                    error={formikCxoneprops.errors.icBU && formikCxoneprops.touched.icBU} 
                                                    helperText={formikCxoneprops.errors.icBU}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icAccessKey"
                                                    label="Access Key"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikCxoneprops.handleChange}
                                                    onBlur={formikCxoneprops.handleBlur}
                                                    value={formikCxoneprops.values.icAccessKey}
                                                    error={formikCxoneprops.errors.icAccessKey && formikCxoneprops.touched.icAccessKey}
                                                    helperText={formikCxoneprops.errors.icAccessKey} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icAppSecret"
                                                    label="Application Secret"
                                                    type="password"
                                                    className={classes.inputField}
                                                    onChange={formikCxoneprops.handleChange}
                                                    onBlur={formikCxoneprops.handleBlur}
                                                    value={formikCxoneprops.values.icAppSecret}
                                                    error={formikCxoneprops.errors.icAppSecret && formikCxoneprops.touched.icAppSecret}
                                                    helperText={formikCxoneprops.errors.icAppSecret} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    margin="dense"
                                                    name="icScriptName"
                                                    label="Script Name"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikCxoneprops.handleChange}
                                                    onBlur={formikCxoneprops.handleBlur}
                                                    value={formikCxoneprops.values.icScriptName}
                                                    error={formikCxoneprops.errors.icScriptName && formikCxoneprops.touched.icScriptName}
                                                    helperText={formikCxoneprops.errors.icScriptName} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="dense"
                                                    name="icAPIOverride"
                                                    label="Override API URL"
                                                    type="text"
                                                    className={classes.inputField}
                                                    onChange={formikCxoneprops.handleChange}
                                                    onBlur={formikCxoneprops.handleBlur}
                                                    value={formikCxoneprops.values.icAPIOverride}
                                                    error={formikCxoneprops.errors.icAPIOverride && formikCxoneprops.touched.icAPIOverride} 
                                                    helperText={formikCxoneprops.errors.icAPIOverride}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Button disabled={!toggleSaveButton} type="submit" color="primary" variant="contained">Save</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button color="primary" variant="outlined" disabled={!_.isEmpty(formikCxoneprops.errors)} className={buttonClassname} onClick={testCXOneConnection(formikCxoneprops.values)}>Test Connection</Button>
                                                {testing && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </div>
            )}
        </UserContext.Consumer>
     );
}
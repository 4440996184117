import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, LinearProgress, Typography } from '@material-ui/core';
import { getRoleAndAdmin, getAppUser, getCifChannel, getBaseBU, whoAmI, createAppUser, assignRole, createCifChannel } from '../E2/MSALConfig';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    selectFields: {
        minWidth: '220px',
        maxWidth: '220px'
    },
    main: {
        height: '300px'
    },
    progressBar: {
        width: '15%'
    },
    error: {
        color: 'red',
        fontSize: '10pt'
    },
    info: {
        fontSize: '10pt'
    }
}));

export default function NewProfile(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [profileName, setProfileName] = useState('');
    const [org, setOrg] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        async function getData(){
            setProfileName(props.profileName)
            setOrg(props.selOrg);
            if(props.selOrg){
                setSuccess(true);
            }
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    const handleNameChange = (event) => {
        const name = event.target.value;
        setProfileName(name);
        props.setProfileName(name);
    }

    const handleSelectOrg = (event) => {
        const org = event.target.value;
        testOrg(org);
        setOrg(org);
    }

    async function testOrg(setOrg){
        setSuccess(false);
        setError('');
        setShowError(false);
        setShowProgress(true);
        setProgress(0);
        const response = await getRoleAndAdmin(setOrg);
        if (response.length > 1){
            const systemAdminRoleId = response[0].roleid;
            const e2Id = response[1].roleid;
            setProgress(14);
            const appUser = await getAppUser(setOrg, props.appId);
            setProgress(28);
            if (appUser.length > 0)
            {   
                setProgress(84)
                const cifChannel = await getCifChannel(setOrg);
                if (cifChannel.length > 0 ){
                    setProgress(100);
                    props.setSelOrg(setOrg);
                    setSuccess(true);
                } else {
                    const systemUserId = await whoAmI(setOrg);
                    const createCifResponse = await createCifChannel(setOrg, systemAdminRoleId, systemUserId);
                    if (createCifResponse === 204){
                        setProgress(100);
                        props.setSelOrg(setOrg);
                        setSuccess(true);
                    } else {
                        setError('An error occured when trying to create a CIF Channel for this orginization.  Please contact us at support@customerdynamics.com.');
                        setShowError(true);
                        setShowProgress(false);
                    }
                }
            } else {
                const response = await getBaseBU(setOrg);
                setProgress(42);
                const buId = await response[0].businessunitid;
                if (buId){
                    const createUserResponse = await createAppUser(setOrg, buId);
                    if (createUserResponse === 204){
                        setProgress(56);
                        const newUser = await getAppUser(setOrg);
                        const userId = newUser[0].systemuserid;
                        if(userId){
                            const assignResponse = await assignRole(setOrg, userId, e2Id);
                            if (assignResponse === 204){
                                setProgress(70);
                                const createCifResponse = await createCifChannel(setOrg, systemAdminRoleId, userId);
                                if (createCifResponse === 204){
                                    setProgress(100);
                                    props.setSelOrg(setOrg);
                                    setSuccess(true);
                                } else {
                                    setError('An error occured when trying to create a CIF Channel for this orginization.  Please contact us at support@customerdynamics.com.');
                                    setShowError(true);
                                    setShowProgress(false);
                                }
                            }
                        } else {
                            setError('An error occured retrieving the application user.  Please contact us at support@customerdynamics.com.');
                            setShowError(true);
                            setShowProgress(false);
                        }
                    } else {
                        setError('An error occured when trying to create the application user.  Please contact us at support@customerdynamics.com.');
                        setShowError(true);
                        setShowProgress(false);
                    }
                } else {
                    setError('An error occured when trying to retrieve the business unit.  Please contact us at support@customerdynamics.com.');
                    setShowError(true);
                    setShowProgress(false);
                }
            }
        } else {
            setError('The Customer Dynamics Package has not been installed for this organization.');
            setShowError(true);
            setShowProgress(false);
        }
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Grid item xs={12}>
                            <TextField
                                key="name"
                                autoFocus
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                value={profileName}
                                required={true}
                                className={classes.selectFields}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item container direction="row" spacing={4} alignItems="center">
                            <Grid item>
                                <FormControl>
                                    <InputLabel shrink id="organization">Organization*</InputLabel>
                                        <Select
                                            key="orgSelect"
                                            labelId="orginization"
                                            name="origanization"
                                            value={org}
                                            displayEmpty
                                            onChange={handleSelectOrg}
                                            className={classes.selectFields}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {props.orgs.map(data =>(
                                                <MenuItem value={data}>{data.FriendlyName}</MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>
                            </Grid>
                            <div hidden={!success}>
                                <Grid item>
                                    <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item container direction="row">
                            <div hidden={!showError}>
                                <Grid item>
                                    <a href="mailto: support@customerdynamics.com" className={classes.error}>{error}</a>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div hidden={!showProgress}>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={progress}></LinearProgress>
                            </div>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid item>
                        <Typography className={classes.info}>*If an organization you were expecting to see is not listed, you may not have the correct permissions for that organization.</Typography>
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
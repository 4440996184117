import React, { useContext, useEffect, useState, forwardRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import MaterialTable from 'material-table';
import UserContext from '../context/UserContext';
import { listRestConnections } from '../graphql/queries';
import { CheckOutlined, EditOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { deleteRestConnection } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography, useTheme } from '@material-ui/core';

export default function RESTConnections() {
    const history = useHistory();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const userContext = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        async function getData() {
            setLoading(true);

            setItems((await API.graphql(graphqlOperation(listRestConnections, { tenant: userContext.tenant })))?.data?.listRestConnections?.items ?? []);

            setLoading(false);
        }

        if (userContext.tenant) getData();
    }, [userContext]);

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <Grid container justifyContent='space-between' alignItems='center' alignContent='center'>
                    <Grid item>
                        <Typography variant='h4'>REST Connections</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={() => history.push('/config/rest-connections/new')}>+ REST Connection</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <MaterialTable
                    isLoading={loading}
                    title=''
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Enabled', field: 'enabled', render: rowData => rowData.enabled && <CheckOutlined style={{ color: theme.palette.success.main }} /> },
                        { title: 'Date Created', field: 'createdAt', render: rowData => new Date(rowData.createdAt).toLocaleString(), defaultSort: 'desc' }
                    ]}
                    onRowClick={(_, row) => history.push(`/config/rest-connections/${row.id}`)}
                    options={{
                        padding: 'dense',
                        actionsColumnIndex: -1,
                    }}
                    icons={{
                        Delete: forwardRef((props, ref) => <DeleteOutlineOutlined color='primary' {...props} ref={ref} />),
                    }}
                    data={items}
                    editable={{
                        onRowDelete: row => new Promise(async (resolve, reject) => {
                            try {
                                await API.graphql(graphqlOperation(deleteRestConnection, { input: { id: row.id } }));
                                setItems(items.filter(x => x.id !== row.id));
                                enqueueSnackbar('REST Connection deleted', { variant: 'success' });
                                resolve();
                            } catch (err) {
                                console.error(err);
                                reject(err);
                            }
                        })
                    }}
                    actions={[
                        {
                            icon: () => <EditOutlined color='primary' />,
                            onClick: (event, rowData) => {
                                console.log('rowData:', rowData);
                                history.push(`/config/rest-connections/${rowData.id}`);
                            } 
                        }
                    ]}
                />
            </Grid>
        </Grid>
    );
}
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSnackbar } from 'notistack';
import * as request from 'superagent'; 


/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const RefundDialog = function (props) {
    const { enqueueSnackbar } = useSnackbar();
    const [validAmount, setValidAmount] = useState(false);
    const [refundReason, setRefundReason] = useState('');
    const [refundPercentage, setRefundPercentage] = useState();

    /** This arrow function handles change for percentage. We check that the amount is valid */
    const handleRefundPercentageChange = (event) => {
        if(Number(event.target.value) <= 100){
            setRefundPercentage(Number(event.target.value));
            setValidAmount(true);
        }
    }

    /** Arrow function to handle change of the reason textbox within the refund dialog */
    const handleReasonChange = (event) => {
        setRefundReason(event.target.value);
    }    

    /** This function makes a submission of a refund to the refunds lambda */
    async function handleRefundClick() {
        const transactions = props.transactions;
        for(let i = 0; i < transactions.length; i++){
            if(transactions[i].refunds.items.length > 0){
                enqueueSnackbar("One or more transaction has already been refunded", {variant: 'error'});
                return;
            }
        }
        for(let i = 0; i < transactions.length; i++){
            const refundAmount = transactions[i].transactionAmount * (refundPercentage / 100);
            console.log(refundAmount);
            try{
                const reqBody = {
                    vendorId: transactions[i].vendorId,
                    transactionId: transactions[i].id,
                    refundAmount: refundAmount,
                    refundReason: refundReason,
                    agentId: transactions[i].agentId
                }
                const url = `${process.env.REACT_APP_PP_URL}/refunds/refund`;
                await request.post(url)
                    .set("x-api-key", props.c3ApiKey)
                    .type("json")
                    .send(reqBody);
                enqueueSnackbar(`Transaction ${i+1} completed`, {variant: 'success'})
            }catch(error){
                enqueueSnackbar("Something went wrong during the refunds process")
                console.error(error);
            }
        }
        props.refresh();
        props.onCancel();
    }

    /** Close refund dialog */
    async function closeDialog() {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <div>
            <Dialog maxWidth="sm" open={props.open}>
                <DialogTitle id="dp-dialog-title">Customer Refund</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" alignItems="stretch" spacing={2}>
                        <Grid item container direction="column" justifyContent="flex-start" spacing={1}>
                        </Grid>
                        <Grid item container direction="column" justifyContent="flex-end" spacing={1}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item>
                                    <TextField
                                        placeholder="0"
                                        label="Refund Percentage"
                                        value={refundPercentage}
                                        required={true}
                                        onChange={handleRefundPercentageChange}
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            inputProps: { 
                                                max: 100, min: 0.01, step: 0.01
                                            }
                                        }}
                                        InputLabelProps={{ shrink: true}}
                                        style={{width: "200px"}}
                                    >
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row">
                                <TextField
                                    label="Refund Reason"
                                    value={refundReason}
                                    required={true}
                                    onChange={handleReasonChange}
                                    type="Text"
                                    multiline
                                    rowsMax={4}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" variant="outlined">Cancel</Button>
                    <Button disabled={!validAmount} onClick={handleRefundClick} color="primary" variant="contained">Refund</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default RefundDialog;

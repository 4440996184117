import React from 'react';
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography} from '@material-ui/core';

/** This file pops up a dialog in C3Info.js that will display the Refund History of a transaction.
 *  For now we are only allowing one refund per transaction. (I believe this is subject to change in the future)
 */
export default function RefundHistoryDialog(props){

    const refund = props.refunds.items[0];

    /** Close dialog when button is clicked */
    function closeDialog() {
        if (props.close) {
            props.close();
        }
    }


    return(
        <div>
            <Dialog maxWidth="xs" open={props.open}>
                <DialogTitle id="dp-dialog-title">Refund History</DialogTitle>
                <DialogContent>
                    <Typography><b>Agent ID: </b>{refund.agentId}</Typography>
                    <Typography><b>Creation Date: </b>{refund.createdAt}</Typography>
                    <Typography><b>Refund Amount: </b>{refund.refundAmount}</Typography>
                    <Typography><b>Refund Reason: </b>{refund.refundReason}</Typography>
                    <Typography><b>Updated Date:</b>{refund.updatedAt}</Typography>
                    <Typography><b>Vendor ID: </b>{refund.vendorId}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Input, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, Typography} from '@material-ui/core';
import { login, getConfigurationIcSkills } from '../context/InContact';

import * as _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        minheight: '300px'
    },
    listHeader: {
        fontWeight: 'bold',
        color: 'black'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function TransferOptions(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [loginSettings, setLoginSettings] = useState();
    const [allSkills, setAllSkills] = useState([]);
    const [availableSkills, setAvailableSkills] = useState([]);
    const [skillFilter, setSkillFilter] = useState('all');
    const [manualList, setManualList] = useState([]);
    const [manualFilter, setManualFilter] = useState();
    const [loadingSkills, setLoadingSkills] = useState(false);

    useEffect(() => {
        async function getData(){
            const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
            setLoginSettings(dynamoSettings.data.getTenantSettings);
        }
            if(userContext.tenant){
                getData();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant]);

    useEffect(() => {
            if (props.transferSkills && props.transferSkills.skillGroup){
                setSkillFilter(props.transferSkills.skillGroup);
            }
    
            if (props.transferSkills && props.transferSkills.skills){
                setManualList(props.transferSkills.skills);
            }
    }, [props.transferSkills])

    useEffect(() => {
        if(loginSettings){
            getSkills();
        }
    }, [loginSettings])

    useEffect(() => {
        setLoadingSkills(false);
    }, [availableSkills])

    async function getSkills(){
        setLoadingSkills(true);
        await login(loginSettings);
        const retrievedSkills = await getConfigurationIcSkills(4);
        setAllSkills(retrievedSkills);
        setAvailableSkills(retrievedSkills);
    }

    function filterSkills(filter){
        setLoadingSkills(true);
        let filteredSkills = [];

        if(filter === 'inbound'){
            for (const skill of allSkills){
                if (skill.isOutbound === false){
                    filteredSkills.push(skill);
                }
            }
        } else if (filter === 'outbound'){
            for (const skill of allSkills){
                if (skill.isOutbound === true){
                    filteredSkills.push(skill);
                }
            }
        } else {
            filteredSkills = allSkills;
        }
        setAvailableSkills([...filteredSkills]);
    }

    const handleSkillFilterChange = (event) => {
        if(skillFilter !== 'manual'){
            const transferSkills = {
                skillGroup: event.target.value
            }
            props.setTransferSkills(transferSkills);
        } else {
            const transferSkills = {
                skillGroup: event.target.value,
                skills: manualList
            }
            props.setTransferSkills(transferSkills);
        }
    }

    const handleManualFilterChange = (event) => {
        setManualFilter(event.target.value);
        filterSkills(event.target.value);
    }

    const handleSelectedSkills = (event) => {
        const skills = event.target.value;
        const transferSkills = {
            skillGroup: skillFilter,
            skills: skills
        }
        props.setTransferSkills(transferSkills);
    }

    async function handleDialogAccept(){
        
    }

    function handleDialogClose(){

    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Grid item><Typography variant='h6'>Select Skills Available to Transfer to</Typography></Grid>

                        {/* Radio Buttons */}
                        <Grid item>
                            <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>Skills</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label='skills'
                                    value={skillFilter}
                                    onChange={handleSkillFilterChange}
                                    name='skill-radio-buttons'>
                                        <FormControlLabel value='all' control={<Radio />} label="All" />
                                        <FormControlLabel value='inbound' control={<Radio />} label="Inbound" />
                                        <FormControlLabel value='outbound' control={<Radio />} label="Outbound" />
                                        <FormControlLabel value='voicemail' control={<Radio />} label="Voice Mail" />
                                        <FormControlLabel value='manual' control={<Radio />} label="Manual" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {skillFilter === 'manual' && <Grid item container direction='column' spacing={2}>
                            <Grid item>
                                <FormControl component='fieldset' className={classes.formControl}>
                                    <FormLabel component='legend'>Manual List Filter</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label='manual list filter'
                                        defaultValue='none'
                                        value={manualFilter}
                                        onChange={handleManualFilterChange}
                                        name='skill-radio-buttons'>
                                            <FormControlLabel value='none' control={<Radio />} label="None" />
                                            <FormControlLabel value='inbound' control={<Radio />} label="Inbound" />
                                            <FormControlLabel value='outbound' control={<Radio />} label="Outbound" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <div className={classes.wrapper}>
                                <Grid item>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink id="skills">Manual Skill Selection</InputLabel>
                                        <Select
                                            multiple
                                            labelId="skills"
                                            value={manualList}
                                            onChange={handleSelectedSkills}
                                            input={<Input />}
                                            displayEmpty
                                            className={classes.selectFields}
                                            renderValue={(selected) => selected.join(', ')}
                                            >
                                                {availableSkills.map(data => (
                                                    <MenuItem key={data.skillId} value={JSON.stringify(data.skillId)}>
                                                        <Checkbox checked={manualList.indexOf(JSON.stringify(data.skillId)) > -1} color="primary" />
                                                        <ListItemText primary={data.skillName} />
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                    {loadingSkills && <CircularProgress size={24} className={classes.buttonProgress} />}    
                                </Grid>
                            </div>            
                        </Grid>}
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}
/**
 * This file is a component for E2 UI Customization
 */
 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';

 export default function LiveChat(props)
 {
     const [liveChat, setLiveChat] = useState(false);
 
     /** Set live chat to value being passed through props */
     useEffect(() => {
         setLiveChat(props.value);
     },[props.value]);
     
     /**Handle changes to live chat checkbox */
     const handleLiveChat = () => {
         setLiveChat(!liveChat);
         props.onChange(!liveChat)
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={liveChat}
                         onChange={handleLiveChat}
                         name="liveChat"
                         color="primary"
                         />
                     }
                     label="Live Chat"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Hides/shows the live-chat component allowing the agent to chat with the patron.">
                         <AnnouncementOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
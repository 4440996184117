import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    selectFields: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    main: {
        height: '300px',
        padding: '20px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

const IvrPrerequisites = function(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const [success, setSuccess] = useState(false);
    const [testing, setTesting] = useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    async function testPrerequisites() {
        setTesting(true);
        setSuccess(false);
        
        const testOrgs = await props.getOrgs();
        if (testOrgs.length >= 1){
            setSuccess(true);
            props.setAccept(true);
        } else {
            enqueueSnackbar('Please make sure you have system admin rights for your organizations.', {
                variant: 'error'
            });
        }
        setTesting(false);
    }

    useEffect(() => {
        async function getData(){
            if (props.accept === true){
                setSuccess(true);
            }
        }
        if (userContext.tenant){
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.tenant])

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                    <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
                        <Typography>Before continuing you must:</Typography>
                        <ol>
                            <Grid item>
                                1. Have system adminstration privileges for the Dynamics orginization you are configuring.
                            </Grid>
                        </ol>
                        <Grid item container direction="row" spacing={2} alignItems="center">
                            <div className={classes.wrapper}>
                                <Button onClick={testPrerequisites} variant="outlined" className={buttonClassname} color="primary">
                                    Test Prerequisites
                                </Button>
                                {testing && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            <div hidden={!success}>
                                <Grid item>
                                    <DoneOutlineIcon color="primary"></DoneOutlineIcon>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <br></br>
                </div>
            )}
        </UserContext.Consumer>
    );
}

export default withRouter(IvrPrerequisites);
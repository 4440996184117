import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    Tooltip,
    Switch,
    Checkbox,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import {
    HelpOutlineOutlined,
} from "@material-ui/icons";

import React from "react";

export default function C2ACallUs(props) {
    const { formikProps, classes, contactsList } = props;

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Grid
                    container
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        {formikProps.values.c2a.callUsEnabled && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            formikProps.values?.c2a
                                                ?.callUs?.finalize ??
                                            false
                                        }
                                        onChange={
                                            formikProps.handleChange
                                        }
                                        onBlur={formikProps.handleBlur}
                                        name="c2a.callUs.finalize"
                                        color="primary"
                                    />
                                }
                                label="Finalize Call Us C2A"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {formikProps.values.c2a.callUsEnabled && (
                <>
                    <Grid item>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            alignContent="center"
                        >
                            <Grid item>
                                <FormControl>
                                    <InputLabel
                                        error={
                                            formikProps.errors?.c2a
                                                ?.callUs
                                                ?.pointOfContact &&
                                            formikProps.touched?.c2a
                                                ?.callUs?.pointOfContact
                                        }
                                    >
                                        Point of Contact
                                    </InputLabel>
                                    <Select
                                        labelId="pointOfContact-label"
                                        name="c2a.callUs.pointOfContact"
                                        className={classes.field}
                                        value={
                                            formikProps.values.c2a
                                                ?.callUs
                                                ?.pointOfContact ?? ""
                                        }
                                        onBlur={formikProps.handleBlur}
                                        onChange={
                                            formikProps.handleChange
                                        }
                                        error={
                                            formikProps.errors?.c2a
                                                ?.callUs
                                                ?.pointOfContact &&
                                            formikProps.touched?.c2a
                                                ?.callUs?.pointOfContact
                                        }
                                    >
                                        {contactsList.map(
                                            (contact, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={
                                                        contact.contactAddress
                                                    }
                                                >
                                                    {
                                                        contact.contactDescription
                                                    }
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                    {formikProps.errors?.c2a?.callUs
                                        ?.pointOfContact &&
                                        formikProps.touched?.c2a?.callUs
                                            ?.pointOfContact && (
                                            <FormHelperText error>
                                                {
                                                    formikProps.errors
                                                        ?.c2a?.callUs
                                                        ?.pointOfContact
                                                }
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <TextField
                                            margin="dense"
                                            name="c2a.callUs.extension"
                                            label="Menu/Extension"
                                            type="text"
                                            value={
                                                formikProps.values?.c2a
                                                    ?.callUs
                                                    ?.extension ?? false
                                            }
                                            onChange={
                                                formikProps.handleChange
                                            }
                                            onBlur={
                                                formikProps.handleBlur
                                            }
                                            className={classes.field}
                                            error={
                                                formikProps.errors?.c2a
                                                    ?.callUs
                                                    ?.extension &&
                                                formikProps.touched?.c2a
                                                    ?.callUs?.extension
                                            }
                                            helperText={
                                                (formikProps.touched
                                                    ?.c2a?.callUs
                                                    ?.extension &&
                                                    formikProps.errors
                                                        ?.c2a?.callUs
                                                        ?.extension) ??
                                                "Use a comma for a pause, semicolon for a wait."
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Tooltip
                                            title="A comma between the point of contact and the extension will make the dialer wait about three seconds after dialing and then automatically sends the digits after the comma.
                                        A semi-colon between the point of contact and the extension will make the dialer wait for another button to be pressed before automatically sending the digits."
                                        >
                                            <HelpOutlineOutlined color="primary" />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                label="Message"
                                name="c2a.callUs.text"
                                type="text"
                                className={classes.textArea}
                                variant="outlined"
                                multiline
                                rows={12}
                                value={
                                    formikProps.values?.c2a?.callUs
                                        ?.text ?? ""
                                }
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                error={
                                    formikProps.errors?.c2a?.callUs
                                        ?.text &&
                                    formikProps.touched?.c2a?.callUs
                                        ?.text
                                }
                                helperText={
                                    formikProps.touched?.c2a?.callUs
                                        ?.text &&
                                    formikProps.errors?.c2a?.callUs
                                        ?.text
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

import React, { useEffect, useContext, useState } from 'react';
import queryString from 'query-string';
import { useParams, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import {Typography } from '@material-ui/core';
import * as mutations from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

/**
 * The segment class implements creation, update and cloning of campains
 * Called Via a route
 * Can have URL params of Segment ID and Action (Edit, or Clone) No params for create
 * * @category Pages
 * @component
 * @param {string} segmentId The component that expects a param of 'data'.
 * @param {string} action The graphql query string to retreive data with.
 */
export default function DynamUpdate(props) {
//Query String from MS should look like this 
//admin_consent=True&tenant=a0fc534f-b940-4218-86d1-4f6c9932a5af&state=12345    
const { params } = useParams();
const userContext = useContext(UserContext);
const location = useLocation();
const [tenantSettings, setTenantSettings] = useState();

useEffect(() => {
    async function getData(){
        // Query Dynamo for the Settings 
        const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
        setTenantSettings(dynamoSettings);
    }
    getData();

},[userContext.tenant]);

useEffect(() => {
    async function getData(){
        const values = queryString.parse(location.search);
        const result = values.admin_consent;
        const tenant = values.tenant;
        const appSecret = 'h~2u.hy6.9EM7p~d-0-NZv63jX6t4zg-Qr';
        const clientId = 'a53e8cfb-e738-4fee-81fa-359f180d150c';

        const allSettings = await tenantSettings.data.getTenantSettings;

        if (result === 'True'){
            const updateSettings  = {...allSettings,
                e2ClientId: clientId,
                e2ClientSecret: appSecret,
                e2DynamTenantId: tenant,
            }
            delete updateSettings.createdAt;
            delete updateSettings.updatedAt;
            await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: updateSettings }));
            window.close();
        }
        else{
            const updateSettings  = {...allSettings,
                e2ClientId: null,
                e2ClientSecret: null,
                e2DynamTenantId: tenant
            }
            delete updateSettings.createdAt;
            delete updateSettings.updatedAt;
            await API.graphql(graphqlOperation(mutations.updateTenantSettings, { input: updateSettings }));
            window.close();
        }
    }
    if (tenantSettings && tenantSettings !== null){
        getData();
    }

}, [tenantSettings]);


return (
    <Typography variant = "h6" color="primary">Updating Settings from Dynamics. Please Wait</Typography>

)

}
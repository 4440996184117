import React, { useState, useEffect, useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { Grid, Typography, TextField, TablePagination } from '@material-ui/core';
import moment from 'moment';
import { API, graphqlOperation} from 'aws-amplify';
import UserContext from '../context/UserContext';
import * as queries from '../graphql/queries';
import * as _ from 'lodash';
import MaterialTable from 'material-table';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    selectInput: {
        minWidth: '100px'
    },
    formControl: {
        minWidth: 150,
        maxWidth: 350,
    },
    dialogDiv: {
        minWidth: '600px'
    },
    checkbox: {
        maxWidth: 20
    },
    padRight: {
        paddingRight: theme.spacing(2)
    },
}));

export default function NicLicSubmit() {
    const userContext = useContext(UserContext)
    const [nicTenants, setNicTenants] = useState([]);
    const [submitTime, setSubmitTime] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [tableTitle, setTableTitle] = useState('');

    useEffect(() => {
        if (userContext.tenant){
            setSubmitTime(moment().startOf('month').format('YYYY-MM-DDTHH:mm'));
            setStartTime(moment().startOf('month').format('YYYY-MM'));
            setEndTime(moment().endOf('month').format('YYYY-MM-DDTHH:mm'));
        }
    }, [userContext.tenant])

    useEffect(() => {
        if(startTime){
            getLicenses();
        }
    }, [startTime]);

    useEffect(() => {
        if (nicTenants){
            setTableTitle(moment(startTime).format('MM-YYYY'));
        }
    }, [nicTenants])

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    async function getLicenses(){
        const licenses = [];

        const outreachQuery = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "max_count": {
                            "max": {
                                "field": "Count"
                            }
                        }
                    }
                }
            },
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match_phrase": {
                                        "cdProd": "OutReach"
                                    }
                                },
                                {
                                    "range": {
                                        "Date": {
                                            "gte":  submitTime,
                                            "lte": endTime
                                        }
                                    }
                                }
                            ]
                        }
                    },
            "size": 0
        }

        const e2Query = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "max_count": {
                            "max": {
                                "field": "Count"
                            }
                        }
                    }
                }
            },
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match_phrase": {
                                        "cdProd": "E2"
                                    }
                                },
                                {
                                    "range": {
                                        "Date": {
                                            "gte":  submitTime,
                                            "lte": endTime
                                        }
                                    }
                                }
                            ]
                        }
                    },
            "size": 0
        }

        const essentialsQuery = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "max_count": {
                            "max": {
                                "field": "Count"
                            }
                        }
                    }
                }
            },
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match_phrase": {
                                        "cdProd": "Essentials"
                                    }
                                },
                                {
                                    "range": {
                                        "Date": {
                                            "gte":  submitTime,
                                            "lte": endTime
                                        }
                                    }
                                }
                            ]
                        }
                    },
            "size": 0
        }

        const dialerQuery = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "max_count": {
                            "max": {
                                "field": "Count"
                            }
                        }
                    }
                }
            },
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match_phrase": {
                                        "cdProd": "Dialer"
                                    }
                                },
                                {
                                    "range": {
                                        "Date": {
                                            "gte":  submitTime,
                                            "lte": endTime
                                        }
                                    }
                                }
                            ]
                        }
                    },
            "size": 0
        }

        const uMaxQuery = {
            "aggs": {
                "tenant": {
                    "terms": {
                        "field": "tenant.keyword",
                        "size": 2147483647
                    },
                    "aggs": {
                        "max_count": {
                            "max": {
                                "field": "Count"
                            }
                        }
                    }
                }
            },
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match_phrase": {
                                        "cdProd": "UMAX"
                                    }
                                },
                                {
                                    "range": {
                                        "Date": {
                                            "gte":  submitTime,
                                            "lte": endTime
                                        }
                                    }
                                }
                            ]
                        }
                    },
            "size": 0
        }

        //Make Queries
        //OutReach
        const outReachJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'dailylicensecount',
            action: '_search',
            query: JSON.stringify(outreachQuery)
        }));
        const outReachResult = JSON.parse(outReachJSON.data.esQuery);
        if(outReachResult){
            const outReachData = outReachResult['aggregations'].tenant.buckets;
            outReachData.map(obj => {
                const license = {
                    tenant: obj.key,
                    OutReach: obj.max_count.value
                }
                licenses.push(license);
            });
        }


        //E2
        const e2JSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'dailylicensecount',
            action: '_search',
            query: JSON.stringify(e2Query)
        }));
        const e2Result = JSON.parse(e2JSON.data.esQuery);
        if(e2Result){
            const e2Data = e2Result['aggregations'].tenant.buckets;
            e2Data.map(obj => {
                const index = licenses.findIndex(license => license.tenant === obj.key);
                if (index > -1){
                    licenses[index] = {...licenses[index], E2: obj.max_count.value};
                } else {
                    const license = {
                        tenant: obj.key,
                        E2: obj.max_count.value
                    }
                    licenses.push(license);
                }
            });
        }

        //UMAX
        const uMaxJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'dailylicensecount',
            action: '_search',
            query: JSON.stringify(uMaxQuery)
        }));
        const uMaxResult = JSON.parse(uMaxJSON.data.esQuery);
        if(uMaxResult){
            const uMaxData = uMaxResult['aggregations'].tenant.buckets;
            uMaxData.map(obj => {
                const index = licenses.findIndex(license => license.tenant === obj.key);
                if (index > -1){
                    licenses[index] = {...licenses[index], uMax: obj.max_count.value};
                } else {
                    const license = {
                        tenant: obj.key,
                        uMax: obj.max_count.value
                    }
                    licenses.push(license);
                }
            });
        }

        //Essentials
        const essentialsJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'dailylicensecount',
            action: '_search',
            query: JSON.stringify(essentialsQuery)
        }));
        const essentialsResult = JSON.parse(essentialsJSON.data.esQuery);
        if(essentialsResult){
            const essentialsData = essentialsResult['aggregations'].tenant.buckets;
            essentialsData.map(obj => {
                const index = licenses.findIndex(license => license.tenant === obj.key);
                if (index > -1){
                    licenses[index] = {...licenses[index], Essentials: obj.max_count.value};
                } else {
                    const license = {
                        tenant: obj.key,
                        Essentials: obj.max_count.value
                    }
                    licenses.push(license);
                }
            });
        }

        //Dialer
        const dialerJSON = await API.graphql(graphqlOperation(queries.esQuery, {
            model: 'dailylicensecount',
            action: '_search',
            query: JSON.stringify(dialerQuery)
        }));
        const dialerResult = JSON.parse(dialerJSON.data.esQuery);
        if(dialerResult){
            const dialerData = dialerResult['aggregations'].tenant.buckets;
            dialerData.map(obj => {
                const index = licenses.findIndex(license => license.tenant === obj.key);
                if (index > -1){
                    licenses[index] = {...licenses[index], Dialer: obj.max_count.value};
                } else {
                    const license = {
                        tenant: obj.key,
                        Dialer: obj.max_count.value
                    }
                    licenses.push(license);
                }
            });
        }

        setNicTenants(licenses);
    }

    const handleStart = (event) => {
        const start = event.target.value;
        setStartTime(moment(start).startOf('month').format('YYYY-MM'));
        setEndTime(moment(start).endOf('month').format('YYYY-MM-DDTHH:mm'));
        setSubmitTime(moment(start).startOf('month').format('YYYY-MM-DDTHH:mm'));
    }
    
    return (
        
        <div>
            <Grid item container direction="row" spacing={1} justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h6">Max License Count</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        margin="dense"
                        name="startDate"
                        label="Filter Start Date"
                        type="month"
                        variant="outlined"
                        color="primary"
                        value={startTime}
                        onChange={handleStart}
                        InputLabelProps={{
                            shrink: true
                        }} />
                </Grid>
            </Grid>
            <br></br>
            <MaterialTable
                columns={[
                    { title: 'Tenant', field: 'tenant', render: rowData => <div>{rowData.tenant}</div>},
                    { title: 'E2', field: 'E2', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.E2 ? rowData.E2 : 0}
                        </Grid>
                    </div>
                    },
                    { title: 'Essentials', field: 'Essentials', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.Essentials ? rowData.Essentials : 0}
                        </Grid>
                    </div>
                    },
                    { title: 'OutReach', field: 'OutReach', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.OutReach ? rowData.OutReach : 0}
                        </Grid>
                    </div>
                    },
                    { title: 'Dialer', field: 'Dialer', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.Dialer ? rowData.Dialer : 0}
                        </Grid>
                    </div>
                    },
                    { title: 'UMAX', field: 'uMax', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.uMax ? rowData.uMax : 0}
                        </Grid>
                    </div>
                    },
                    { title: 'PromptPay', field: 'ppayLicenses', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.ppayLicenses ? rowData.ppayLicenses : 0}
                        </Grid>
                    </div>
                    },
                    { title: 'OutReach Select', field: 'orsLicenses', render: rowData => 
                    <div>
                        <Grid item>
                            {rowData.orsLicenses ? rowData.orsLicenses : 0}
                        </Grid>
                    </div>
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10
                    
                }}
                components={{
                    Pagination: licensePaging
                }}
                data={nicTenants}
                title={tableTitle}
            />
        </div>
    );
}



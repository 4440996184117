/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

 import React, {useState, useEffect} from 'react';
 import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
 import LowPriorityOutlinedIcon from '@material-ui/icons/LowPriorityOutlined';
 
 export default function ChatElevate(props)
 {
     const [chatElevate, setChatElevate] = useState(false);
 
     /** Set disposition to value being passed through props */
     useEffect(() => {
         setChatElevate(props.value);
     },[props.value])
 
     // Handle hold checkbox change
     const handleChatElevate = () => {
         setChatElevate(!chatElevate);
         props.onChange(!chatElevate);
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={chatElevate}
                         onChange={handleChatElevate}
                         name="chatElevate"
                         color="primary"
                         />
                     }
                     label="Elevate"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Give permission for chat elevate property">
                         <LowPriorityOutlinedIcon color="primary" style= {{"transform": "rotate(180deg)"}} />
                     </Tooltip>
                 </Grid>
 
             </Grid>
             
         </div>
     );
 }
 
 
import React, { useEffect, useState, useRef, useContext } from 'react';
import MaterialDataTable from '@material-table/core';
import { UserContext } from '../context/UserContext';
import { startIcSkill, stopIcSkill } from '../context/InContact';
import ConfirmDialog from '../components/ConfirmDialog';
import { Grid, Paper, Typography, makeStyles, CircularProgress, Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CampaignDialog from '../components/CampaignDialog';
import PlayArrow from '@material-ui/icons/PlayArrowOutlined';
import Pause from '@material-ui/icons/PauseCircleOutlineOutlined';
import Stop from '@material-ui/icons/StopOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import StatusIcon from '@material-ui/icons/VisibilityOutlined';
import ExportIcon from '@material-ui/icons/GetAppOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ScheduleOutlined from '@material-ui/icons/ScheduleOutlined';
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import ExportDialog from '../components/ExportDialog';
import CampaignStatus from '../components/CampaignStatus';
import { graphqlOperation, API, Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TablePagination } from '@material-ui/core';
import VanillaCampaign from '../models/Campaign';
import CampaignScheduleDialog from '../components/CampaignScheduleDialog';
import { RestoreOutlined } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    selectInput: {
        minWidth: '100px'
    }
}));

/**
 * The Campaigns component is use to tie a segment with a List Management Profile
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
export default function Campaigns() {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const campaignsData = useRef([]);
    const mTable = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [tenantSettings, setTenantSettings] = useState();
    const [campaign, setCampaign] = useState({});
    const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [toStop, setToStop] = useState(undefined);
    const [toDelete, setToDelete] = useState(undefined);
    const [exportOpen, setExportOpen] = useState(false);
    const [exportCampaign, setExportCampaign] = useState('');
    const [campaignStatusOpen, setCampaignStatusOpen] = useState(false);
    const [campaignStatusId, setCampaignStatusId] = useState('');
    const userContext = useContext(UserContext);
    const [dialogOperation, setDialogOperation] = useState('edit');
    const [loading, setLoading] = useState(false);
    const [campaignRealtimeStatus, setCampaignRealtimeStatus] = useState({});
    const [scheduleOpen, setScheduleOpen] = useState(false);
    const [toReset, setToReset] = useState(undefined);
    const [resetOpen, setResetOpen] = useState(false);


    useEffect(() => {
        // get the campaigns, profile and Statuses to use for the workbundle
        async function getData() {
            setLoading(true);
            try {
                const campaigns = await getCampaigns(userContext.tenant);
                if (campaigns && campaigns.length > 0) {
                    campaignsData.current = campaigns;
                    campaignsData.current.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
                    setData(campaigns);
                }

                const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
                console.log(settings.data.getTenantSettings);
                setTenantSettings({ ...settings.data.getTenantSettings });
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        }

        if (userContext.tenant) {
            // Handle Updates
            const onCreateSubscription = API.graphql(graphqlOperation(subscriptions.onTenantCreateCampaign, { tenant: userContext.tenant }))
                .subscribe({
                    next: (campaignData) => handleNewCampaign(campaignData)
                });

            const onDeleteSubscription = API.graphql(graphqlOperation(subscriptions.onTenantDeleteCampaign, { tenant: userContext.tenant }))
                .subscribe({
                    next: (campaignData) => handleDeleteCampaign(campaignData)
                });

            const onUpdateSubscription = API.graphql(graphqlOperation(subscriptions.onTenantUpdateCampaign, { tenant: userContext.tenant }))
                .subscribe({
                    next: (campaignData) => handleUpdateCampaign(campaignData)
                });

            getData();

            return () => {
                onCreateSubscription.unsubscribe();
                onDeleteSubscription.unsubscribe();
                onUpdateSubscription.unsubscribe();
            }
        }
    }, [userContext.tenant]);

    async function getCampaigns(tenant) {
        const campaignList = [];
        try {
            let nextToken;
            do {
                const campaigns = await API.graphql(graphqlOperation(queries.campaignsByTenant, {
                    tenant: tenant,
                    nextToken
                }));
                if (campaigns?.data?.campaignsByTenant?.items) {
                    campaignList.push(...campaigns.data.campaignsByTenant.items);
                    nextToken = campaigns.data.campaignsByTenant.nextToken;
                } else {
                    nextToken = null;
                }
            } while (nextToken);

            return campaignList;
        } catch (err) {
            console.error(err);
            return campaignList;
        }
    }

    const licensePaging = (props) => {
        return (
            <TablePagination
                count={props.count || 0}
                page={props.page}
                rowsPerPage={props.rowsPerPage}

                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    const editCampaign = (data) => {
        const editCampaign = _.cloneDeep(data);
        if (data.status === "Completed") {
            enqueueSnackbar(`Unable to edit a campaign in a completed state.`, {
                variant: 'error',
                autoHideDuration: 5000
            });
        } else if (data.status === "Expired") {
            enqueueSnackbar(`Unable to edit an expired campaign.`, {
                variant: 'error',
                autoHideDuration: 5000
            });
        } else {
            setDialogOperation('edit');
            setCampaign(editCampaign);
            setCampaignDialogOpen(true);
        }
    }

    const cloneCampaign = (data) => {
        const cloneCampaign = _.cloneDeep(data);
        cloneCampaign.name += ' - Clone';
        cloneCampaign.status = 'Pending';
        cloneCampaign.id = '';
        setDialogOperation('clone')
        setCampaign(cloneCampaign);
        setCampaignDialogOpen(true);
    }

    const deleteCampaign = async (data) => {
        setDeleteOpen(false);
        setToDelete(undefined);
        if (data.status === "Active" || data.status === "Paused") {
            await handleEndCampaign(data);
        }

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [data.id]: "Deleting campaign..." });
        const campaignId = data.id;
        await API.graphql(graphqlOperation(mutations.deleteCampaign, { input: { id: campaignId } }));
        delete campaignRealtimeStatus[data.id]; // Don't re-render the page. Let the subscription of the deletion of the campaign do that.
    };

    /**
    *Subscription functions to handle updates
    */
    function handleNewCampaign(campaignData) {
        const createdCampaign = campaignData.value.data.onTenantCreateCampaign;
        campaignsData.current.unshift(createdCampaign);
        setData([...campaignsData.current]);
    }

    function handleDeleteCampaign(campaignData) {
        const campaign = campaignData.value.data.onTenantDeleteCampaign;
        _.remove(campaignsData.current, function (e) {
            return e.id === campaign.id;
        });
        setData([...campaignsData.current]);
    }

    function handleUpdateCampaign(campaignData) {
        if (campaignData.value.data.onTenantUpdateCampaign?.profile) { // When changing the campaign status, this kept getting executed twice with bad data. The profile wasn't there on those other times.
            const updatedCampaign = campaignData.value.data.onTenantUpdateCampaign;
            //const syncSub = [...data];
            let pos = _.findIndex(campaignsData.current, function (e) {
                return e.id === updatedCampaign.id;
            });
            campaignsData.current.splice(pos, 1, updatedCampaign);
            delete campaignRealtimeStatus[updatedCampaign.id];
            setCampaignRealtimeStatus({ ...campaignRealtimeStatus });
            setData([...campaignsData.current]);
            if (updatedCampaign.status === 'Active') {
                enqueueSnackbar(`Campaign ${updatedCampaign.name} Started`);
            } else if (updatedCampaign.status === 'Completed') {
                enqueueSnackbar(`Campaign ${updatedCampaign.name} Stopped`);
            }
        }
    }

    async function handleStartCampaign(campaignData) {

        if (await checkCampaignIntegrity(campaignData)) {
            campaignRealtimeStatus[campaignData.id] = 'Loading campaign';
            setCampaignRealtimeStatus({ ...campaignRealtimeStatus });
            try {
                await startCampaignLoad(campaignData);
            } catch (err) {
                console.log(err);
            }
        } else {
            enqueueSnackbar("The skill and/or one or more segments are in use by another campaign", { variant: 'error', autoHideDuration: 5000 });
        }
    }

    async function openExportDialog(campaignData) {
        const campId = campaignData.id
        setExportCampaign(campId);
        setExportOpen(true);
    }

    async function openCampaignStatus(campaignData) {
        const campId = campaignData.id;
        setCampaignStatusId(campId);
        setCampaignStatusOpen(true);
    }

    function closeCampaignStatus() {
        setCampaignStatusOpen(false);
    }

    async function startCampaignLoad(campaignData) {
        try {
            const createResult = await API.post(
                'cdyxdialer',
                '/cache/create',
                {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'x-api-key': userContext.apiKey
                    },
                    body: {
                        campaignId: campaignData.id,
                        dataSource: 'beast'
                    },
                    timeout: -1
                });
            return createResult;
        } catch (err) {
            console.log(err);
        }

    }




    // async function startExport(campaignData){
    //     try{
    //         const exportPromise = API.post(
    //             'cdyxdialer',
    //             '/export/campaign',
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    //                     'x-api-key': userContext.apiKey
    //                 },
    //                 body: {
    //                     campaignId: campaignData.id
    //                 }
    //             });
    //         const responses = await Promise.all([exportPromise]);
    //     }catch (err) {
    //         console.log(err);
    //     } 
    // }

    async function handlePauseCampaign(campaignData) {
        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Stopping the skill" });
        const stopSkill = await stopIcSkill(campaignData.skillId);
        handleIcSkillStatus(campaignData.skillName, stopSkill, 'stop', 'stopped', 'stopping');

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Updating status" });
        await API.graphql(graphqlOperation(mutations.updateCampaign, {
            input: {
                id: campaignData.id,
                status: 'Paused'
            }
        }));

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Pausing cache" });
        await API.post(
            'cdyxdialer',
            `/cache/pause/${campaignData.id}/${campaignData.skillId}`,
            {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': userContext.apiKey
                }
            });

        enqueueSnackbar("Campaign successfully paused", { variant: 'success' });

        delete campaignRealtimeStatus[campaignData.id];
        setCampaignRealtimeStatus({ ...campaignRealtimeStatus });
    }
    async function handleRestartCampaign(campaignData) {
        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Starting the skill" });
        const startSkill = await startIcSkill(campaignData.skillId);
        handleIcSkillStatus(campaignData.skillName, startSkill, 'start', 'started', 'starting');

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Updating status" });
        await API.graphql(graphqlOperation(mutations.updateCampaign, {
            input: {
                id: campaignData.id,
                status: 'Active'
            }
        }));

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Restarting cache" });
        await API.post(
            'cdyxdialer',
            `/cache/restart/${campaignData.id}/${campaignData.skillId}`,
            {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': userContext.apiKey
                },
                body: {
                    campaignId: campaignData.id
                }
            });

        enqueueSnackbar("Campaign successfully restarted", { variant: 'success' });
        delete campaignRealtimeStatus[campaignData.id];
        setCampaignRealtimeStatus({ ...campaignRealtimeStatus });
    }
    const stopCampaign = (campaignData) => {
        setConfirmOpen(true);
        setToStop(campaignData);
    }

    const confirmDelete = (campaignData) => {
        setDeleteOpen(true);
        setToDelete(campaignData);
    }

    const cancelFinalDelete = (campaignData) => {
        setDeleteOpen(false);
        setToDelete(undefined);
    }

    async function handleEndCampaign(campaignData) {
        if (campaignData.status === 'Active') {
            setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Stopping the skill" });
            const stopSkill = await stopIcSkill(campaignData.skillId);
            handleIcSkillStatus(campaignData.skillName, stopSkill, 'stop', 'stopped', 'stopping');
        }

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [campaignData.id]: "Clearing cache" });
        await API.del(
            'cdyxdialer',
            `/cache/delete/${campaignData.id}/${campaignData.skillId}`,
            {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': userContext.apiKey
                },
                timeout: -1
            });

        setConfirmOpen(false);
    }

    async function handleResetCampaign() {
        setResetOpen(false);

        if (toReset.status === 'Active') {
            setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [toReset.id]: "Stopping the skill" });
            const stopSkill = await stopIcSkill(toReset.skillId);
            handleIcSkillStatus(toReset.skillName, stopSkill, 'stop', 'stopped', 'stopping');
        }

        setCampaignRealtimeStatus({ ...campaignRealtimeStatus, [toReset.id]: "Clearing cache" });
        await API.post(
            'cdyxdialer',
            `/cache/reset/${toReset.id}`,
            {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': userContext.apiKey
                }
            });
        
        setConfirmOpen(false);
    }

    function handleIcSkillStatus(skillName, status, present, preterit, gerund) {
        switch (status) {
            case 202:
                enqueueSnackbar(`${skillName} ${preterit} successfully`, { variant: 'success' });
                break;
            case 400:
                enqueueSnackbar(`Unable to ${present} ${skillName} - Invalid Force, must be true or false`, { variant: 'error' });
                break;
            case 401:
                enqueueSnackbar(`Unable to ${present} ${skillName} - Connection Error`, { variant: 'error' });
                break;
            case 403:
                enqueueSnackbar(`Unable to ${present} ${skillName} - Insufficient Permissions`, { variant: 'error' });
                break;
            case 404:
                enqueueSnackbar(`Unable to ${present} ${skillName} - Invalid skillID`, { variant: 'error' });
                break;
            case 409:
                enqueueSnackbar(`Unable to ${present} ${skillName} - Skill is in an invalid state.`, { variant: 'error' });
                break;
            default:
                enqueueSnackbar(`Unknown problem ${gerund} the skill`, { variant: 'error' });
        }
    }


    const getPlayActionInfo = (rowData) => {
        const isMidStatus = Boolean(campaignRealtimeStatus[rowData.id]);
        switch (rowData.status) {
            case "Pending":
                return {
                    disabled: isMidStatus,
                    icon: () => (<PlayArrow color={isMidStatus ? "disabled" : "primary"} />),
                    tooltip: isMidStatus ? '' : 'Start Campaign',
                    onClick: (_, rowData) => { handleStartCampaign(rowData); }
                };

            case "Active":
                return {
                    disabled: isMidStatus,
                    icon: () => (<Pause color={isMidStatus ? "disabled" : "primary"} />),
                    tooltip: isMidStatus ? '' : 'Pause Campaign',
                    onClick: (_, rowData) => { handlePauseCampaign(rowData); }
                };

            case "Paused":
                return {
                    disabled: isMidStatus,
                    icon: () => (<PlayArrow color={isMidStatus ? "disabled" : "primary"} />),
                    tooltip: isMidStatus ? '' : 'Restart Campaign',
                    onClick: (_, rowData) => { handleRestartCampaign(rowData); }
                };

            default:
                return {
                    disabled: true,
                    icon: () => (<PlayArrow color='disabled' />),
                };
        }
    }

    const getTerminateActionInfo = (rowData) => {
        const disabled = rowData.status === "Pending" || rowData.status === "Completed" || rowData.status === "Expired" || Boolean(campaignRealtimeStatus[rowData.id]);

        return {
            disabled,
            icon: () => (<Stop color={disabled ? 'disabled' : 'primary'} />),
            tooltip: disabled ? '' : 'End Campaign',
            onClick: (_, rowData) => stopCampaign(rowData)
        }
    }

    const handleTerminate = async (campaignData) => {
        try {
            setConfirmOpen(false);
            setToStop(undefined);
            await handleEndCampaign(campaignData);
        } catch (err) {
            console.log(err);
        }
    }
    const cancelTerminate = (campaignData) => {
        setConfirmOpen(false);
        setToStop(undefined);
    }

    const cancelReset = () => {
        setResetOpen(false);
        setToReset(undefined);
    }

    const cancelExport = () => {
        setExportOpen(false);
    }

    const handleClickAddCampaign = () => {
        setDialogOperation('new');
        setCampaignDialogOpen(true);
        setCampaign(VanillaCampaign);
    }

    async function checkCampaignIntegrity(campaignData) {
        const campaigns = await API.graphql(graphqlOperation(queries.campaignsByTenant, { tenant: userContext.tenant, filter: { or: [{ status: { eq: 'Active' } }, { status: { eq: 'Paused' } }] } }));

        let campaignDataSegments = campaignData.segments;
        if (!Array.isArray(campaignDataSegments)) {
            campaignDataSegments = JSON.parse(campaignDataSegments);
        }

        if (campaigns.data?.campaignsByTenant?.items) {
            return !campaigns.data.campaignsByTenant.items.some(x => x.id !== campaignData.id && (+x.skillId === +campaignData.skillId || campaignDataSegments.some(curSegment => JSON.parse(x.segments).some(segment => segment.segmentId === curSegment.segmentId))));
        }
    }

    return (
        <div className={classes.root}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4">Campaigns</Typography>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={handleClickAddCampaign}>+ Campaign</Button>
                </Grid>
            </Grid>
            <Paper style={{ marginTop: '10px' }}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                    <MaterialDataTable tableRef={mTable}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Skill', field: 'skillName' },
                            { title: 'Profile', field: 'profile.name' },
                            {
                                title: 'Status', field: 'status', render: rowData =>
                                    <div>
                                        {campaignRealtimeStatus[rowData.id] &&
                                            <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                                                <div style={{ marginRight: '10px' }}>
                                                    {campaignRealtimeStatus[rowData.id]}
                                                </div>
                                                <div>
                                                    <CircularProgress variant='indeterminate' color='primary' size={16} />
                                                </div>
                                            </div>}
                                        {!campaignRealtimeStatus[rowData.id] &&
                                            rowData.status}
                                    </div>
                            },
                            {
                                title: 'Expires', field: 'expireDate', render: rowData => {
                                    if (rowData.expireDate) {
                                        return moment(rowData.expireDate).format('LLL');
                                    } else {
                                        return '';
                                    }
                                }
                            },
                            { title: 'Sort', field: 'sort', render: rowData => <span>{rowData.sort && <CheckCircleOutlineIcon color="primary" />}</span> },
                        ]}
                        data={data}
                        isLoading={loading}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10
                        }}
                        
                        actions={[
                            rowData => ({
                                icon: () => (<StatusIcon color={rowData.status === 'Pending' || rowData.status === 'Completed' || rowData.status === 'Expired' ? 'disabled' : 'primary'} />),
                                tooltip: 'Campaign Status',
                                onClick: (event, rowData) => {
                                    openCampaignStatus(rowData);
                                }
                            }),
                            rowData => getPlayActionInfo(rowData),
                            rowData => getTerminateActionInfo(rowData),
                            rowData => function () {
                                const disabled = Boolean(campaignRealtimeStatus[rowData.id]);
                                return ({
                                    disabled,
                                    icon: () => (<ControlPointDuplicate color={disabled ? "disabled" : "primary"} />),
                                    tooltip: disabled ? '' : 'Clone Campaign',
                                    onClick: (_, rowData) => {
                                        cloneCampaign(rowData);
                                    }
                                });
                            }(),
                            rowData => function () {
                                const disabled = rowData.status === 'Completed' || rowData.status === "Expired" || Boolean(campaignRealtimeStatus[rowData.id]);
                                return ({
                                    disabled,
                                    icon: () => (<EditIcon color={disabled ? 'disabled' : 'primary'} />),
                                    tooltip: disabled ? '' : 'Edit Campaign',
                                    onClick: (_, rowData) => {
                                        editCampaign(rowData);
                                    }
                                });
                            }(),
                            rowData => function () {
                                const disabled = rowData.status === 'Completed';
                                return ({
                                    disabled,
                                    icon: () => (<ScheduleOutlined color={disabled ? 'disabled' : 'primary'} />),
                                    tooltip: disabled ? '' : 'Schedule Campaign',
                                    onClick: (_, rowData) => {
                                        setCampaign(rowData);
                                        setScheduleOpen(true);
                                    }
                                });
                            }(),
                            rowData => function () {
                                const disabled = Boolean(rowData.status === 'Pending' || rowData.status === 'Completed' || rowData.status === 'Expired' || Boolean(campaignRealtimeStatus[rowData.id]));
                                return ({
                                    disabled,
                                    icon: () => (<RestoreOutlined color={disabled ? 'disabled' : 'primary'} />),
                                    tooltip: disabled ? '' : 'Reset Campaign',
                                    onClick: (_, rowData) => {
                                        setToReset(rowData);
                                        setResetOpen(true);
                                    }
                                });
                            }(),
                            rowData => function () {
                                const disabled = Boolean(campaignRealtimeStatus[rowData.id]);
                                return ({
                                    disabled,
                                    icon: () => (<DeleteIcon color={disabled ? "disabled" : "primary"} />),
                                    tooltip: disabled ? '' : 'Delete Campaign',
                                    onClick: (_, rowData) => {
                                        confirmDelete(rowData);
                                    }
                                });
                            }(),
                            rowData => function () {
                                const disabled = rowData.status === 'Pending' || Boolean(campaignRealtimeStatus[rowData.id]);
                                return ({
                                    disabled,
                                    icon: () => (<ExportIcon color={disabled ? 'disabled' : 'secondary'} />),
                                    tooltip: disabled ? '' : 'Export Campaign',
                                    onClick: (_, rowData) => {
                                        openExportDialog(rowData);
                                    }
                                });
                            }()
                        ]}
                        title="" />
                </Grid>
                <CampaignDialog campaign={campaign} open={campaignDialogOpen} operation={dialogOperation} setCampaignDialogOpen={setCampaignDialogOpen} />
                <ConfirmDialog
                    open={confirmOpen}
                    value={toStop}
                    onConfirm={handleTerminate}
                    onCancel={cancelTerminate}
                    confirmTxt="Yes">
                    <Typography>If you Terminate the campaign it will be removed from the list manager and set to a completed state. Do you wish to continue?.</Typography>
                </ConfirmDialog>
                <ConfirmDialog
                    open={deleteOpen}
                    value={toDelete}
                    onConfirm={deleteCampaign}
                    onCancel={cancelFinalDelete}
                    confirmTxt="Yes">
                    <Typography>Are you sure that you want to permanently delete this campaign?</Typography>
                </ConfirmDialog>
                <ConfirmDialog
                    open={resetOpen}
                    value={toReset}
                    onConfirm={handleResetCampaign}
                    onCancel={cancelReset}
                    confirmTxt="Yes">
                    <Typography>Are you sure that you want to reset this campaign?</Typography>
                </ConfirmDialog>
                <ExportDialog
                    open={exportOpen}
                    campaign={exportCampaign}
                    onCancel={cancelExport}
                />
                <CampaignStatus
                    open={campaignStatusOpen}
                    campaignId={campaignStatusId}
                    onCancel={closeCampaignStatus}
                />
                <CampaignScheduleDialog
                    open={scheduleOpen}
                    campaign={campaign}
                    onCancel={() => setScheduleOpen(false)}
                />
            </Paper>
        </div>
    );
}


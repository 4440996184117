import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserContext from '../context/UserContext';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { Divider, Button, FormControl, InputLabel, Select, MenuItem, Menu, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import RetrySlider from '../components/RetrySlider';
import RetryDelayPicker from '../components/RetryDelayPicker';
import CustRetryDialog from '../components/CustRetryDialog';
import * as _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import * as queries from '../graphql/queries';
import API, { graphqlOperation } from '@aws-amplify/api';
import { login, getTimeZones } from '../context/InContact';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    selectInput: {
        minWidth: '200px',
        maxWidth: '220px'
    },
    formControl: {
        minWidth: 120
    },
    dialogDiv: {
        minWidth: '600px'
    },
    customChart: {
        padding: '16px'
    },
    button: {
        minHeight: 40
    },
    timezoneMenuItems: {
        maxHeight: 25
    }
}));

/**
 * The ChartDialog component is used Create New Charts
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
const ChannelDialog = function (props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);

    const [open, setOpen] = useState();
    // const [channel, setChannel] = useState({
    //     type: '',
    //     order: 0,
    //     retryDelay: 0,
    //     maxAttempts: 1,
    //     maxContacts: 0,
    //     ratio: 0,
    //     paymentProfileId: '',
    //     custRetry: {}
    // });
    const [selCustRetries, setCustRetries] = useState([]);
    const [openCustom, setOpenCustom] = useState(false);
    const [unUsedDispos, setUnUsedDispos] = useState([]);
    const [paymentProfiles, setPaymentProfiles] = useState([]);
    const [showTypes, setShowTypes] = useState();
    const [type, setType] = useState();
    const [retryDelay, setRetryDelay] = useState();
    const [maxAttempts, setMaxAttempts] = useState(1);
    const [maxContacts, setMaxContacts] = useState(1);
    const [ratio, setRatio] = useState(0);
    const [paymentProfile, setPaymentProfile] = useState();
    const [editOrder, setEditOrder] = useState();
    const [defaultTimeZone, setDefaultTimeZone] = useState(null);
    const [timeZoneList, setTimeZoneList] = useState([]);



    useEffect(() => {
        async function getData() {
            const appsyncPromises = [];
            appsyncPromises.push(await API.graphql(graphqlOperation(queries.tenantPayProfiles, { tenant: userContext.tenant })))
            appsyncPromises.push(await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant})))
            const results = await Promise.all(appsyncPromises);
            const profiles = results[0];
            if (profiles && profiles.data) {
                setPaymentProfiles(profiles.data.tenantPayProfiles.items);
            }
            if (results[1] && results[1].data.getTenantSettings) {
                const tenantSettings = results[1].data.getTenantSettings;
                await login(tenantSettings);
                setTimeZoneList(await getTimeZones());
            }
        }
        if (userContext.tenant) {
            getData();
        }
    }, [userContext.tenant]);

    useEffect(() => {
        if (type) {
            setOpen(true);
            setUnUsedDispos(props.unUsed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        if (props.editChannel) {
            console.log(props.editChannel);
            setOpen(true);
            setMaxAttempts(props.editChannel.maxAttempts);
            setEditOrder(props.editChannel.order);
            setRetryDelay(props.editChannel.retryDelay);
            setType(props.editChannel.type);
            setDefaultTimeZone(props.editChannel.defaultTimeZone);
            if (props.editChannel.paymentProfileId) {
                let profile = _.find(paymentProfiles, ['id', props.editChannel.paymentProfileId]);
                setPaymentProfile(profile);
            }
            if (props.editChannel.type === 'Voice') {
                setMaxContacts(props.editChannel.maxContacts);
                setRatio(props.editChannel.ratio)
                setCustRetries(JSON.parse(props.editChannel.custRetry));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editChannel]);

    useEffect(() => {
        if (selCustRetries.length > 0) {
            const availableDispos = [...unUsedDispos];
            for (const custRetry of selCustRetries) {
                for (const code of custRetry.codes) {
                    _.remove(availableDispos, ['code', code]);
                }
            }
            setUnUsedDispos([...availableDispos]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selCustRetries]);

    const toggleCustom = () => {
        setOpenCustom(!openCustom);
    }

    const addRetry = (dispos, retry) => {
        let cloneCodes = [];
        for (const codeName of dispos) {
            let inCode = _.find(unUsedDispos, ['name', codeName]);
            cloneCodes.push(inCode.code);
        }
        retry.codes = cloneCodes;
        const availableDispos = [...unUsedDispos];
        for (const codeName of dispos) {
            _.remove(availableDispos, ['name', codeName]);
        }
        setUnUsedDispos(availableDispos);
        setCustRetries([...selCustRetries, retry]);
    }

    const deleteCustRetry = selections => () => {
        const curList = [...selCustRetries]
        _.remove(curList, selections);
        setCustRetries(curList);

        const unUsedItems = [...props.unUsed];
        for (const entry of curList) {
            for (const remCode of entry.codes) {
                _.remove(unUsedItems, ['code', remCode]);
            }
        }
        setUnUsedDispos(unUsedItems);
    }


    const setDelay = (event) => {
        setRetryDelay(event.target.value)
    }

    const setAttempts = (event) => {
        setMaxAttempts(event.target.value);
    }

    const setContacts = (event) => {
        setMaxContacts(event.target.value);
    }

    function handleClose() {
        setOpen(false);
        setType(null);
        setMaxAttempts(1);
        setMaxContacts(1);
        setRatio(0);
        setRetryDelay();
        setCustRetries([]);
        setPaymentProfile();
        setEditOrder();
        setDefaultTimeZone();
    }

    function handleTypeClose() {
        setShowTypes(null);
    }

    const handleTypesOpen = (event) => {
        setShowTypes(event.currentTarget);
    }

    const c2aProfile = event => {
        setPaymentProfile(event.target.value);
    }

    const setTimeZone = event => {
        setDefaultTimeZone(event.target.value);
    }

    const typeSet = (type) => () => {
        setType(type);
        setShowTypes(null);
    }

    function saveChannel() {
        let channel;
        let order;

        if (editOrder) {
            order = editOrder;
        } else {
            order = props.allChannels.length + 1;
        }
        if (type === 'Voice') {
            channel = {
                type: type,
                order: order,
                retryDelay: retryDelay,
                maxAttempts: JSON.parse(maxAttempts),
                maxContacts: JSON.parse(maxContacts),
                ratio: ratio,
                custRetry: JSON.stringify(selCustRetries)
            }
        } else {
            channel = {
                type: type,
                order: order,
                retryDelay: retryDelay,
                maxAttempts: JSON.parse(maxAttempts),
                paymentProfileId: paymentProfile.id,
                paymentProfileName: paymentProfile.name,
                defaultTimeZone: defaultTimeZone
            }
        }
        console.log(channel);
        const channels = [...props.allChannels];
        if (editOrder) {
            channels[editOrder - 1] = channel;
        } else {
            channels.push(channel);
        }
        props.setAllChannels([...channels]);
        setOpen(false);

        setType(null);
        setMaxAttempts(1);
        setMaxContacts(1);
        setRatio(0);
        setRetryDelay();
        setCustRetries([]);
        setPaymentProfile();
        setEditOrder();
    }

    return (
        <UserContext.Consumer>
            {({ tenant, icSkills }) => (
                <div>
                    <div>
                        <Grid container direction="column" xs={12} justifyContent="flex-end">
                            <Grid item justifyContent="flex-end" >
                                <Button variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleTypesOpen}>+ New Channel</Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={showTypes}
                                    keepMounted
                                    open={Boolean(showTypes)}
                                    onClose={handleTypeClose}
                                >
                                    <MenuItem onClick={typeSet('Voice')}>Voice</MenuItem>
                                    <MenuItem onClick={typeSet('SMS')}>SMS</MenuItem>
                                    <MenuItem onClick={typeSet('Email')}>Email</MenuItem>
                                </Menu>
                            </Grid>

                        </Grid>

                    </div>

                    <Dialog open={open} maxWidth="lg">
                        <DialogTitle id="dp-dialog-title">{`New ${type} Channel`}</DialogTitle>
                        <DialogContent className={classes.dialogDiv}>
                            <Grid container direction="column" justifyContent="flex-start" spacing={2}>
                                {type !== 'Voice' && <Grid item>
                                    <Typography variant="caption">Retries aren't activated till ttl has been met</Typography>
                                </Grid>}
                                <Grid item container direction="row" alignContent="center" alignItems="flex-start" justifyContent="space-between">
                                    <Grid item>
                                        <RetryDelayPicker
                                            name="retry.minDuration"
                                            label="Retry Delay"
                                            value={retryDelay}
                                            onChange={setDelay}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            className={classes.formControl}
                                            margin="dense"
                                            name="retry.maxAttempts"
                                            label="Max Attempts"
                                            type="number"
                                            required={false}
                                            value={maxAttempts}
                                            onChange={setAttempts}
                                            InputProps={{
                                                inputProps: {
                                                    max: 300, min: 1
                                                }
                                            }} />
                                    </Grid>
                                    {type === 'Voice' && <Grid item>
                                        <TextField
                                            className={classes.formControl}
                                            margin="dense"
                                            name="retry.maxContacts"
                                            label="Max Contacts"
                                            type="number"
                                            required={false}
                                            value={maxContacts}
                                            onChange={setContacts}
                                            InputProps={{
                                                inputProps: {
                                                    max: 300, min: 1
                                                }
                                            }} />
                                    </Grid>}
                                </Grid>
                                {type !== 'Voice' && <Grid item container direction='row' alignContent="center" alignItems="flex-start" justifyContent="space-between">
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel shrink id="c2aProfile">C2A Profile</InputLabel>
                                            <Select
                                                labelId="c2aProfile"
                                                name="c2aProfile"
                                                value={paymentProfile}
                                                displayEmpty
                                                className={classes.selectInput}
                                                onChange={c2aProfile}
                                            >
                                                {paymentProfiles.map(data => (
                                                    <MenuItem value={data}>{data.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                    <FormControl>
                                        <InputLabel shrink id="defaultTimeZone">Default Time Zone</InputLabel>
                                        <Select
                                            labelId="defaultTimeZone"
                                            name="defaultTimeZone"
                                            value={defaultTimeZone}
                                            displayEmpty
                                            className={classes.selectInput}
                                            onChange={setTimeZone}
                                            >
                                            <MenuItem key="0" className={classes.timezoneMenuItems} value={null}>None</MenuItem>
                                            {timeZoneList.map(timezone =>
                                                <MenuItem key={timezone.standardName} className={classes.timezoneMenuItems} value={timezone.standardName}>{timezone.displayName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                </Grid>}

                                {type === 'Voice' && <Grid item container direction="row" justifyContent="flex-start">
                                    <Grid item container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                                        <Grid item container direction="row" justifyContent="flex-end">
                                            <Grid item>
                                                {/* <CustRetryDialog dispos={unUsedDispos} pending={setDispoList} retry={setCustRetry} open={openCustom} addCustom={addRetry} toggle={toggleCustom} /> */}
                                                <CustRetryDialog dispos={unUsedDispos} open={openCustom} addCustom={addRetry} toggle={toggleCustom} />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Paper>
                                                <Grid container direction="row" justifyContent="space-between">
                                                    <Grid item xs={12} >

                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <List dense={true}>
                                                            <ListSubheader >Custom Retries</ListSubheader>
                                                            <Divider />
                                                            {selCustRetries.map(selections => (
                                                                <ListItem key={selections.name}>
                                                                    <ListItemText key={selections.name} primary={
                                                                        <Grid container justifyContent="flex-start" alignItems="center">
                                                                            <Grid item xs={2}>
                                                                                <b>{`Name:`}</b> {selections.name}
                                                                            </Grid>
                                                                            {/* <Grid item xs={5}>
                                                                                {`Dispositions: ${selections.codes}`}
                                                                            </Grid> */}
                                                                            <Grid container direction="column" spacing={1} item xs={5}>
                                                                                {selections.retriesbyattempt && selections.retriesbyattempt.map(interval => (
                                                                                    <Grid item>
                                                                                        <b>{`Min Duration:`}</b> {interval.minDuration}   <b>{`Start Attempt:`}</b> {interval.start}   <b>{`End Attempt:`}</b> {interval.end} {interval.priority && <b> {`Priotity:`}</b>}{interval.priority && <CheckIcon color="primary" />}
                                                                                    </Grid>
                                                                                ))}

                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <b>{`Max Attempts:`}</b> {selections.max}
                                                                            </Grid>
                                                                        </Grid>
                                                                    } />
                                                                    <ListItemSecondaryAction>
                                                                        <Grid container direction="row" alignItems="center">
                                                                            <Grid item>
                                                                                <IconButton aria-label="delete" onClick={deleteCustRetry(selections)}>
                                                                                    <DeleteIcon color="primary" fontSize="small" />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            ))}
                                                            {selCustRetries.length === 0 &&
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Grid container justifyContent="center">
                                                                            <Grid item>
                                                                                No Custom Retries
                                                                            </Grid>
                                                                        </Grid>
                                                                    } />
                                                                </ListItem>
                                                            }
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>

                                    </Grid>
                                </Grid>}
                                <Grid item container direction="row" justifyContent="flex-end">
                                    <div>
                                        <Grid item container direction="row" spacing={2} justifyContent="flex-end">
                                            <Grid item>
                                                <Button onClick={handleClose} color="primary" variant="outlined">Cancel</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={saveChannel} color="primary" variant="contained">Save</Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </UserContext.Consumer>
    )
}

export default ChannelDialog;

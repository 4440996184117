/**
 * This file is a component for E2 UI Customization
 */
 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'; 
 
 export default function EmailComposeMessage(props)
 {
     const [composeMessage, setComposeMessage] = useState(false);
 
     /** Set compose message to value being passed through props */
     useEffect(() => {
         setComposeMessage(props.value);
     },[props.value]);
     
     /**Handle changes to live chat checkbox */
     const handleComposeMessage = () => {
         setComposeMessage(!composeMessage);
         props.onChange(!composeMessage);
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={composeMessage}
                         onChange={handleComposeMessage}
                         name="composeMessage"
                         color="primary"
                         />
                     }
                     label="Compose Message"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Toggles the button that allows the agent to compose an outbound email message.">
                         <OpenInNewOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
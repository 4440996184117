/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

export default function Settings(props)
{
    const [settings, setSettings] = useState(false);

    /** Set activity panel to value being passed through props */
    useEffect(() => {
        setSettings(props.value);
    },[props.value]);
    
    /**Handle changes to activity panel checkbox */
    const handleSettings = () => {
        setSettings(!settings);
        props.changeCheck(!settings)
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={settings}
                        onChange={handleSettings}
                        name="settings"
                        color="primary"
                        />
                    }
                    label="Settings"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Allow Settings access">
                        <SettingsOutlinedIcon color="primary"/>
                    </Tooltip>
                    
                </Grid>
            </Grid>
        </div>
    );
}


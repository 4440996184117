import React, { useEffect, useState, useContext } from 'react';
import { API, Auth } from 'aws-amplify';
import UserContext from '../context/UserContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { Bar } from 'react-chartjs-2';
import { useSnackbar } from 'notistack';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
const moment = require('moment');

//Styles
const useStyles = makeStyles(theme => ({
    graphHolder: {
        width: '50vw'
    }
}))

/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const CampaignStatusBreakdown = function (props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [campaignId, setCampaignId] = useState();
    const [segmentId, setSegmentId] = useState();
    const [segmentName, setSegmentName] = useState();
    const [channel, setChannel] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [retries, setRetries] = useState();
    const [callbacks, setCallbacks] = useState();
    const [readyRetries, setReadyRetries] = useState();
    const [readyCallbacks, setReadyCallbacks] = useState();

    const labelValue = [
        '12 am', 
        '1 am', 
        '2 am', 
        '3 am',
        '4 am',
        '5 am',
        '6 am',
        '7 am',
        '8 am',
        '9 am',
        '10 am',
        '11 am',
        '12 pm', 
        '1 pm', 
        '2 pm', 
        '3 pm',
        '4 pm',
        '5 pm',
        '6 pm',
        '7 pm',
        '8 pm',
        '9 pm',
        '10 pm',
        '11 pm'
    ]

    let data = {};
    if(channel === 'voice'){
        data= { 
            datasets: [
                { 
                    barPercentage: 0.6, 
                    barThickness: 30, 
                    maxBarThickness: 40, 
                    minBarLength: 2, 
                    data: retries, 
                    backgroundColor: '#0277bd',
                    label: 'Retry'
                },
                { 
                    barPercentage: 0.6, 
                    barThickness: 30, 
                    maxBarThickness: 40, 
                    minBarLength: 2, 
                    data: callbacks, 
                    backgroundColor: '#f9a45f',
                    label: 'Callback'
                }
            ], 
            labels: labelValue
        }
    } else {
        data= { 
            datasets: [
                { 
                    barPercentage: 0.6, 
                    barThickness: 30, 
                    maxBarThickness: 40, 
                    minBarLength: 2, 
                    data: retries, 
                    backgroundColor: '#0277bd',
                    label: 'Retry'
                }
            ], 
            labels: labelValue
        }
    }
    
    
    useEffect(() => {
        if (props.segmentId){
            setCampaignId(props.campaignId);
            setSegmentId(props.segmentId);
            setSegmentName(props.segmentName);
        }
    },[props.segmentId]);

    useEffect(() => {
        if (props.channel){
            setChannel(props.channel);
        }
    }, [props.channel])

    useEffect(() => {
        if(segmentId){
            getStatusBreakdown();
        }
    }, [segmentId]);


    async function getStatusBreakdown() {
        setRetries();
        setCallbacks();
        try {
          const getData = await API.post(
              'cdyxdialer',
              '/cache/status-breakdown',
              {
                  headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'x-api-key': userContext.apiKey
                  },
                  body: {
                      campaignId,
                      segmentId,
                      channel,
                      utcOffset: await getUTCOffset()
                  }
              });

            // setRetries([...getData[`retries`]]);
            // setCallbacks([...getData[`callbacks`]])
            console.log(getData);

            // format Data
            let retryCounts = []
            for (const hour of getData['hourlyBreakdown'][`retries`]){
                retryCounts.push(hour.length)
            }
            let callbackCounts = []
            for (const hour of getData['hourlyBreakdown'][`callbacks`]){
                callbackCounts.push(hour.length)
            }

            setRetries([...retryCounts]);
            setCallbacks([...callbackCounts]);

            setReadyRetries(getData.ready.retry.length)
            setReadyCallbacks(getData.ready.callbacks.length);
        } catch (err) {
            console.log(err);
        }
    }

    async function getUTCOffset(){
        return moment().utcOffset()
    }

    async function closeDialog(){
        if (props.onCancel){
            props.onCancel();
        }
        setRetries();
        setCallbacks();
        setSegmentId();
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div>
                    <Dialog maxWidth="xl" minWidth="xl" open={props.open}>
                        <DialogTitle id="dp-dialog-title">
                            <Grid container direction='row' justifyContent='space-between'>
                                <Grid item>
                                    Segment {segmentName} - {channel} Breakdown
                                </Grid>
                                <Grid item>
                                    Breakdown as of {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction="row" spacing={4} xs={12} justifyContent='space-between'>
                                {retries && callbacks && 
                                <Grid xs={9} item className={classes.graphHolder} container direction="column">
                                    <Grid item>
                                        <Typography variant='h6'>Hourly Breakdown</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Bar data={data} options={{
                                            legend: {
                                                display: true
                                            }, scales: {
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            min: 0
                                                        }
                                                    }],
                                                    xAxes: [{
                                                        stacked: true,
                                                    }]
                                                }

                                        }} />
                                    </Grid>
                                </Grid>}
                                <Grid item container direction='column' xs={3}>
                                    <Grid item>
                                        <Typography variant='h6'>Total Ready Breakdown</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='body'>Ready Retries: {readyRetries}</Typography>
                                    </Grid>
                                    {channel === 'voice' && <Grid item>
                                        <Typography variant='body' >Ready Callbacks: {readyCallbacks}</Typography>
                                    </Grid>}
                                </Grid>
                                {!retries && !callbacks && <Grid item className={classes.graphHolder}>
                                     <CircularProgress/>
                                </Grid>}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={getStatusBreakdown} color="primary" variant="contained">Refresh</Button>
                            <Button onClick={closeDialog} color="primary" variant="outlined">Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </UserContext.Consumer>
    )
}
export default CampaignStatusBreakdown;

/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useEffect, useState} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';


export default function Hold(props)
{
    const [hold, setHold] = useState(false);

    useEffect(() => {
        // We need what the value of the checkbox should be (clicked or not clicked)
        setHold(props.value);
    }, [props.value]);

    // Handle hold checkbox change
    const handleHold = () => {
        setHold(!hold);
        props.onChange(!hold);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={hold}
                        onChange={handleHold}
                        name="hold"
                        color="primary"
                        />
                    }
                    label="Hold"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission to hold phone call">
                        <PauseCircleOutlineOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
            
        </div>
    );
}


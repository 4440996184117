import React, { useEffect, useContext, useState } from 'react';
import { Grid, Typography, Paper, makeStyles, List, ListSubheader, ListItem, ListItemSecondaryAction, IconButton, ListItemText, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { API, graphqlOperation } from 'aws-amplify';
import * as _ from 'lodash';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import UserContext from '../context/UserContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from '../components/ConfirmDialog';

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    list: {
        width: '100%'
    },
    form: {
        flexGrow: 1
    },
    newCustom: {
        padding: '16px'
    },
    listItems: {
        width: 300
    },
    typeSelect: {
        minWidth: '100px'
    }
}));


export default function CustomFields() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [customFields, setCustomFields] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState({});
    const [usedFields, setUsedFields] = useState([
        'id',
        'tenant',
        'externalid',
        'account',
        'firstname',
        'lastname',
        'source',
        'phone',
        'cell',
        'email',
        'datecreated',
        'status',
        'address',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'timezone',
        'optin',
        'optout',
        'dnc',
        'updatedby',
        'customfields',
    ])

    const initialValues = {
        name: '',
        displayName: ''
    }

    useEffect(() => {
        const getData = async () => {
            const settings = await API.graphql(graphqlOperation(queries.getTenantSettings, { id: userContext.tenant }));
            setCustomFields(settings.data.getTenantSettings.customFields);
        }
        if (userContext.tenant) {
            getData();
        }
    }, [userContext.tenant]);

    
    useEffect(() => {
        if (customFields) {
            const updatedUsedFields = [...usedFields];
            updatedUsedFields.push(...customFields.map(field => { return field.name.toLowerCase() }))
            setUsedFields(updatedUsedFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFields])


    const deleteCustomField = (field) => () => {
        setToDelete(field);
        setConfirmOpen(true);
    }

    const cancelDeleteCustomField = () => {
        setConfirmOpen(false);
        setToDelete({});
    }

    const handleDeleteCustomField = async (field) => {
        _.remove(customFields, ['name', field.name]);
        await API.graphql(graphqlOperation(mutations.updateTenantSettings, {
            input: {
                id: userContext.tenant,
                customFields: customFields
            }
        }));
        setCustomFields([...customFields]);
        userContext.customFields = customFields;
        setConfirmOpen(false);
        setToDelete({});
    }

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item container direction="row" justify="flex-start">
                <Typography variant="h4">Custom Fields</Typography>
            </Grid>
            <Grid item container direction="column" alignItems="stretch">
                <Paper className={classes.content}>
                    <Grid item container direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item container xs={12} md={6}>
                            <List className={classes.list}>
                                <ListSubheader>
                                    <Grid container>
                                        <Grid item className={classes.listItems}>
                                            Display Label
                                                </Grid>
                                        <Grid item>
                                            Field Name
                                        </Grid>
                                    </Grid>
                                </ListSubheader>
                                {customFields && customFields.map((customField) => (
                                    <ListItem key={`${customField.name}`}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Grid item className={classes.listItems}>
                                                        {customField.displayName}
                                                    </Grid>
                                                    <Grid>
                                                        {customField.name}
                                                    </Grid>
                                                </Grid>
                                            }
                                            secondary={
                                                <Typography variant="caption">{customField.type}</Typography>
                                            }>
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={deleteCustomField(customField)}><DeleteOutline color="primary" /></IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item container>
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validationSchema={
                                    Yup.object(
                                        {
                                            displayName: Yup.string().required().trim().min(3, "Custom fields need at least 3 characters"),
                                            name: Yup.string().lowercase().notOneOf(usedFields, "Custom fields must be unique"),
                                            type: Yup.string().required()
                                        }
                                    )
                                }
                                onSubmit={async (values, formikBag) => {
                                    const tempFields = customFields;
                                    tempFields.push(values);
                                    const tenantSettings = await API.graphql(graphqlOperation(mutations.updateTenantSettings, {
                                        input: {
                                            id: userContext.tenant,
                                            customFields: tempFields
                                        }
                                    }));
                                    userContext.customFields = tempFields;
                                    setCustomFields(tenantSettings.data.updateTenantSettings.customFields);
                                }}
                            >
                                {formikProps => (
                                    <form className={classes.form} onSubmit={formikProps.handleSubmit}>
                                        <Grid className={classes.newCustom} container direction="column" alignItems="stretch" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle1">Create a new custom field:</Typography>
                                            </Grid>
                                            <Grid item container direction="row" alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        name="displayName"
                                                        label="Display Name"
                                                        type="text"
                                                        required={true}
                                                        value={formikProps.values.displayName}
                                                        onChange={(e) => {formikProps.handleChange(e);  formikProps.setFieldValue('name', _.camelCase(e.target.value));}}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.touched.displayName && formikProps.errors.displayName}
                                                        helperText={formikProps.touched.displayName && formikProps.errors.displayName}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        margin="dense"
                                                        name="name"
                                                        label="Field Name"
                                                        type="text"
                                                        disabled
                                                        value={formikProps.values.name}
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        error={formikProps.errors.name}
                                                        helperText={formikProps.errors.name}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControl className={classes.typeSelect}>
                                                        <InputLabel id="data-type-label">Type</InputLabel>
                                                        <Select
                                                            labelId="data-type-label"
                                                            autoWidth={true}
                                                            name="type"
                                                            value={formikProps.values.type}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                        >
                                                            <MenuItem value="String">String</MenuItem>
                                                            <MenuItem value="Number">Number</MenuItem>
                                                            <MenuItem value="DateTime">DateTime</MenuItem>
                                                            <MenuItem value="Boolean">Boolean</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" color="primary" type="submit">Add</Button>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>

                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <ConfirmDialog
                open={confirmOpen}
                value={toDelete}
                onConfirm={handleDeleteCustomField}
                onCancel={cancelDeleteCustomField}
                confirmTxt="Yes">
                <Typography>Your custom field will be permanently deleted. <br />*Custom data in contacts will not be removed.</Typography>
            </ConfirmDialog>
        </Grid>
    );
}
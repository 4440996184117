import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../context/UserContext';
import { graphqlOperation, API, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { 
    Grid,
    Button,
    Typography,
    TextField,
    CircularProgress
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        minWidth: 120
    },
    selectFields: {
        minWidth: '450px',
        maxWidth: '450px'
    },
    main: {
        height: '300px'
    },
    inputField: {
        minWidth: '350px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

}));

const DynamConxManual = function(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [testing, setTesting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [tenantSettings, setTenantSettings] = useState();

    const [testClientId, setTestClientId] = useState('');
    const [testTenantId, setTestTenantId] = useState('');
    const [testAppSecret, setTestAppSecret] = useState('');
    const [onPremAuthUrl, setOnPremAuthUrl] = useState('');
    const [onPremAccessUrl, setOnPremAccessUrl] = useState('');
    const [onPremVersion, setOnPremVersion] = useState('');

    useEffect(() => {
        async function getData(){
            const dynamoSettings = await API.graphql(graphqlOperation(queries.getTenantSettings, {id: userContext.tenant}));
            setTenantSettings(dynamoSettings);
        }
        if (userContext.tenant){
            getData();
        }
    }, [userContext.tenant]);

    const handleTenantId = (event) => {
        setSuccess(false);
        const test = event.target.value;
        setTestTenantId(test);
    }

    const handleClientId = (event) => {
        setSuccess(false);
        const test = event.target.value;
        setTestClientId(test);
    }

    const handleAppSecret = (event) => {
        setSuccess(false);
        const test = event.target.value;
        setTestAppSecret(test);
    }

    const handleAccessUrl = (event) => {
        setSuccess(false);
        const url = event.target.value;
        setOnPremAccessUrl(url);
    }

    const handleAuthUrl = (event) => {
        setSuccess(false);
        const url = event.target.value;
        setOnPremAuthUrl(url);
    }

    const handleVersion = (event) => {
        setSuccess(false);
        const version = event.target.value;
        setOnPremVersion(version);
    }

    async function testDynamConnection() {
        try {
            setTesting(true);
            setSuccess(false);
            let altUrl;

            if (testTenantId !== ''){
                altUrl = onPremAccessUrl;
            } else {
                altUrl = onPremAuthUrl;
            }

            const response = await API.post(
                'cdyxdialer',
                '/crm/dynamics/conx-test',{
                    headers: {
                        'x-api-key': userContext.apiKey
                    },
                    body: {
                        dynamicsTenant: testTenantId,
                        clientId: testClientId,
                        appSecret: testAppSecret,
                        altUrl: altUrl
                    }
                }
            )
            setSuccess(true)
            setTesting(false);

        } catch (err) {
            enqueueSnackbar('Connection unsuccessful, Please check connection settings.', {
                variant: 'error',
                autoHideDuration: 5000
            });
            console.error('Unsuccessful Connection', err);
            setTesting(false);
            setSuccess(false);
        }
    }

    async function saveConnection(){
        const allSettings = await tenantSettings.data.getTenantSettings;
        const updateSettings = {
            ...allSettings,
            e2ClientId: testClientId,
            e2ClientSecret: testAppSecret,
            e2DynamTenantId: testTenantId,
            onPremAccessUrl: onPremAccessUrl,
            onPremAuthUrl: onPremAuthUrl,
            onPremVersion: onPremVersion
        }
        delete updateSettings.createdAt;
        delete updateSettings.updatedAt;
        try {
            await API.graphql(graphqlOperation(mutations.updateTenantSettings, {input: updateSettings}));
            props.history.push('/config/inventory-source');
            enqueueSnackbar('Save Successful.', {
                autoHideDuration: 5000
            });
        } catch {
            enqueueSnackbar('Unable to save connection settings.', {
                variant: 'error',
                autoHideDuration: 5000
            });
        }
    
    }

    return (
        <UserContext.Consumer>
            {({ tenant }) => (
                <div className={classes.main}>
                   <Grid item>
                    <Typography variant="h6">Connection Credentials</Typography>
                    <Typography variant="Button">Information used to create a server to server connection</Typography>
                    <Typography variant="subtitle2">*You must have granted Admin Conset to application you connect to</Typography>
                    </Grid>
                    <div hidden={!props.onPrem}>
                        <Grid item container direction="row" spacing={4}>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="onPremUrl"
                                    label="On Prem Access URL"
                                    type="text"
                                    required={true}
                                    onChange={handleAccessUrl}
                                    className={classes.inputField}/>
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="xTokenUrl"
                                    label="Auth Token URL"
                                    type="text"
                                    onChange={handleAuthUrl}
                                    className={classes.inputField} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="version"
                                    label="Version"
                                    type="text"
                                    onChange={handleVersion}
                                    className={classes.inputField} />
                            </Grid>
                        </Grid>
                    </div> 
                    <Grid item container direction="row" spacing={4}>
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="dynamicsTenant"
                                label="Dynamics Tenant id"
                                type="text"
                                className={classes.inputField}
                                onChange={handleTenantId}
                                value={testTenantId} />
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                name="clientId"
                                label="Client ID"
                                type="text"
                                required={true}
                                className={classes.inputField}
                                onChange={handleClientId}
                                value={testClientId}/>
                        </Grid>
                        <Grid item>
                            <TextField
                                margin="dense"
                                name="appSecret"
                                label="Application Secret"
                                type="password"
                                required={true}
                                className={classes.inputField}
                                onChange={handleAppSecret}
                                value={testAppSecret} />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        alignItems="center">
                        <Grid item>
                            <div className={classes.wrapper}> 
                                <Button disabled={!success} type="submit" color="primary" onClick={saveConnection} variant="contained">Save</Button>
                            </div>    
                        </Grid>
                        <Grid item>
                            <div className={classes.wrapper}>    
                                <Button color="primary" variant="outlined" onClick={testDynamConnection} disabled={!testClientId || !testAppSecret}>Test Connection</Button>
                                {testing && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div> 
                        </Grid>   
                    </Grid>
                </div>
            )}
        </UserContext.Consumer>
    );
}

export default withRouter(DynamConxManual)
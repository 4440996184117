const timezones = [
    {shortName: 'AFT', longName: 'Afghanistan Time'},
    {shortName: 'AKST', longName: 'Alaska Standard Time'},
    {shortName: 'AST', longName: 'Atlantic Standard Time'},
    {shortName: 'ACST', longName: 'Australian Central Time'},
    {shortName: 'AEST', longName: 'Australian Eastern Time'},
    {shortName: 'AZOST', longName: 'Azores Summer Time'},
    {shortName: 'CVT', longName: 'Cape Verde Time'},
    {shortName: 'AMT', longName: 'Caucasus Standard Time'},
    {shortName: 'CST', longName: 'Central Standard Time'},
    {shortName: 'BTT', longName: 'Bhutan Time'},
    {shortName: 'BST', longName: 'British Summer Time'},
    {shortName: 'CET', longName: 'Central European Time'},
    {shortName: 'CEST', longName: 'Central Summer European Time'},
    {shortName: 'SBT', longName: 'Soloman Islands Time'},
    {shortName: 'BIT', longName: 'Baker Island Time (Dateline)'},
    {shortName: 'EAT', longName: 'East Africa Time'},
    {shortName: 'EAST', longName: 'Easter Island Standard Time'},
    {shortName: 'EEST', longName: 'Eastern European Summer Time'},
    {shortName: 'ESAST', longName: 'E. South American Standard Time'},
    {shortName: 'EST', longName: 'Eastern Standard Time'},
    {shortName: 'EET', longName: 'Eastern European Time'},
    {shortName: 'EGST', longName: 'Egypt Standard Time'},
    {shortName: 'YEKT', longName: 'Yekaterinburg Time'},
    {shortName: 'FJT', longName: 'Fiji Time'},
    {shortName: 'GMT', longName: 'Greenwich Mean Time'},
    {shortName: 'GNST', longName: 'Greenland Standard Time'},
    {shortName: 'GST', longName: 'Gulf Standard Time'},
    {shortName: 'HST', longName: 'Hawaiian-Aleutian Standard Time'},
    {shortName: 'IST', longName: 'Indian Standard Time'},
    {shortName: 'IRST', longName: 'Iran Standard Time'},
    {shortName: 'IST', longName: 'Israel Standard Time'},
    {shortName: 'KST', longName: 'Korea Standard Time'},
    {shortName: 'MYT', longName: 'Malaysia Time'},
    {shortName: 'MST', longName: 'Mountain Standard Time'},
    {shortName: 'MMT', longName: 'Myanmar Standard Time'},
    {shortName: 'NCAST', longName: 'N. Central Asia Standard Time'},
    {shortName: 'NPT', longName: 'Nepal Standard Time'},
    {shortName: 'NZST', longName: 'New Zealand Standard Time'},
    {shortName: 'NST', longName: 'Newfoundland Standard Time'},
    {shortName: 'KRAT', longName: 'North Asia Standard Time'},
    {shortName: 'IRKT', longName: 'North Asia East Standard Time'},
    {shortName: 'PST', longName: 'Pacific Standard Time'},
    {shortName: 'PSAST', longName: 'Pacific SA Standard Time'},
    {shortName: 'RST', longName: 'Romance Standard Time'},
    {shortName: 'MSK', longName: 'Russian Standard Time'},    
    {shortName: 'SAEST', longName: 'SA Eastern Standard Time'},
    {shortName: 'SAPST', longName: 'SA Pacific Standard Time'},
    {shortName: 'SAWST', longName: 'SA Western Standard Time'},
    {shortName: 'SMST', longName: 'Samoa Standard Time'},
    {shortName: 'THA', longName: 'SE Asia Standard Time'},
    {shortName: 'SAST', longName: 'South Africa Standard Time'},
    {shortName: 'SLT', longName: 'Sri Lanka Standard Time'},
    {shortName: 'TIST', longName: 'Taipei Standard Time'},
    {shortName: 'TAST', longName: 'Tasmania Standard Time'},
    {shortName: 'TST', longName: 'Tokyo Standard Time'},
    {shortName: 'PHOT', longName: 'Tonga Standard Time'},
    {shortName: 'VLAT', longName: 'Vladivostok Standard Time'},
    {shortName: 'AWST', longName: 'W. Australia Standard Time'},
    {shortName: 'ECT', longName: 'W. Central Africa Standard Time'},
    {shortName: 'WET', longName: 'W. Europe Standard Time'},
    {shortName: 'PKT', longName: 'West Asia Standard Time'},
    {shortName: 'WPST', longName: 'West Pacific Standard Time'},
    {shortName: 'YAKT', longName: 'Yakutsk Standard Time'},
]

export default timezones
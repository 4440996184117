/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useEffect, useState} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import VolumeUpOutlinedIcon from '@material-ui/icons/VolumeUpOutlined';


export default function Mute(props)
{
    const [mute, setMute] = useState(false);

    /** Set value of mute to what is being passed in by props */
    useEffect(() => {
        setMute(props.value);
    }, [props.value])

    /** Handle change of mute checkbox */
    const handleMute = () => {
        setMute(!mute);
        props.onChange(!mute);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={mute}
                        onChange={handleMute}
                        name="mute"
                        color="primary"
                        />
                    }
                    label="Mute"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission to put phone call on mute">
                        <VolumeUpOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import { CenterToggleChild, CenterToggleContainer, CenterToggleItem } from 'react-center-toggle';


import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
    Input,
    ListItemText,
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    FormHelperText,
    CircularProgress,
} from "@material-ui/core";
import { DetailsOutlined } from "@material-ui/icons";

const Details = (props) => {
    const { classes, formikProps, skills, loading } = props;
    const userContext = useContext(UserContext);


    return (
        <Card variant="outlined" style={{ marginBottom: "16px" }}>
            <CardHeader
                title="Details"
                avatar={<DetailsOutlined color="primary" />}
                titleTypographyProps={{
                    variant: "h6",
                    style: { marginBottom: "7px" },
                }}
            />
            <CardContent>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    alignContent="center"
                >
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="name"
                            label="Name"
                            type="text"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.name}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.name &&
                                formikProps.touched.name
                            }
                            helperText={
                                formikProps.touched.name
                                    ? formikProps.errors.name
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="vendorId"
                            label="Vendor Id"
                            type="text"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.vendorId}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.vendorId &&
                                formikProps.touched.vendorId
                            }
                            helperText={
                                formikProps.touched.vendorId
                                    ? formikProps.errors.vendorId
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="apiKey"
                            label="Api Key"
                            type="text"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.apiKey}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.apiKey &&
                                formikProps.touched.apiKey
                            }
                            helperText={
                                formikProps.touched.apiKey
                                    ? formikProps.errors.apiKey
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="ttl"
                            label="Expiration Time"
                            type="number"
                            className={classes.longInput}
                            required={true}
                            value={formikProps.values.ttl}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={
                                formikProps.errors.ttl &&
                                formikProps.touched.ttl
                            }
                            helperText={
                                formikProps.touched.ttl
                                    ? formikProps.errors.ttl
                                    : ""
                            }
                        />
                    </Grid>
                    {userContext.telephonyProvider !== "None" &&
                        <Grid item>
                            <CenterToggleContainer toggled={loading.profileSkills}>
                                <CenterToggleChild>
                                    <FormControl required disabled={loading.profileSkills}>
                                        <InputLabel error={formikProps.errors.promptPaySkills && formikProps.touched.promptPaySkills}>C3 Skills</InputLabel>
                                        <Select
                                            multiple
                                            name='promptPaySkills'
                                            value={formikProps.values?.promptPaySkills ?? []}
                                            onChange={formikProps.handleChange}
                                            className={classes.field}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            error={formikProps.errors.promptPaySkills && formikProps.touched.promptPaySkills}
                                        >
                                            {skills.map(skill =>
                                                <MenuItem key={skill.skillId} value={`${skill.skillId}`}>
                                                    <Checkbox checked={formikProps.values.promptPaySkills.indexOf(`${skill.skillId}`) > -1} color="primary" />
                                                    <ListItemText primary={skill.skillName} />
                                                </MenuItem>)}
                                        </Select>
                                        {formikProps.touched.promptPaySkills && formikProps.errors.promptPaySkills &&
                                            <FormHelperText error>{formikProps.errors.promptPaySkills}</FormHelperText>}
                                    </FormControl>
                                </CenterToggleChild>
                                <CenterToggleItem>
                                    <CircularProgress variant='indeterminate' color='primary' />
                                </CenterToggleItem>
                            </CenterToggleContainer>
                        </Grid>}
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="supportEmail"
                            label="Support Email"
                            type="text"
                            className={classes.field}
                            required={true}
                            value={formikProps.values.supportEmail}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors.supportEmail && formikProps.touched.supportEmail}
                            helperText={formikProps.touched.supportEmail && formikProps.errors.supportEmail}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="dense"
                            name="supportPhone"
                            label="Support Phone"
                            type="text"
                            className={classes.field}
                            required={true}
                            value={formikProps.values.supportPhone}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors.supportPhone && formikProps.touched.supportPhone}
                            helperText={formikProps.touched.supportPhone && formikProps.errors.supportPhone}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Details;

/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

export default function Disposition(props)
{
    const [disposition, setDisposition] = useState(false);

    /** Set disposition to value being passed through props */
    useEffect(() => {
        setDisposition(props.value);
    },[props.value])

    // Handle hold checkbox change
    const handleDisposition = () => {
        setDisposition(!disposition);
        props.onChange(!disposition);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={disposition}
                        onChange={handleDisposition}
                        name="disposition"
                        color="primary"
                        />
                    }
                    label="Disposition"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission for call dispositions">
                        <DoneOutlineIcon color="primary"/>
                    </Tooltip>
                </Grid>

            </Grid>
            
        </div>
    );
}


import React, { useEffect, useContext, useState } from 'react';
import { makeStyles, Typography, Button, Grid, Paper, TablePagination } from "@material-ui/core";
import { EditOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { Link, useHistory } from "react-router-dom";
import ConfirmDialog from "../components/ConfirmDialog";
import MaterialTable from 'material-table';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}));

export default function MessageTemplates() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [templates, setTemplates] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    useEffect(() => {
         async function getData() {
             const savedTemplates = await API.graphql(graphqlOperation(queries.listPaymentTemplates));
             if (savedTemplates && savedTemplates.data) {
                 setTemplates(savedTemplates.data.listPaymentTemplates.items);
             }
         }
         if (userContext.tenant) {
             getData();
         }
    }, [userContext.tenant]);

    const licensePaging = (props) => {
        return (
            <TablePagination
            count={props.count || 0}
            page={props.page}
            rowsPerPage={props.rowsPerPage}
            onChangePage={props.onChangePage}
            onChangeRowsPerPage={props.onChangeRowsPerPage} />
        )
    }

    const confirmDelete = (id) => {
        setToDelete(id);
        setConfirmOpen(true);
    }

    async function handleDelete() {
        setConfirmOpen(false);
        
        const deleteResult = await API.graphql(graphqlOperation(mutations.deletePaymentTemplate, { input: { id: toDelete } }));
        console.log (deleteResult);
        //if profile is sucessfull in deleting remove it from the current list.
        if (deleteResult.data){
            const deletedTemplate = deleteResult.data['deletePaymentTemplate'];
            const currentTemplates = [...templates];
            _.remove(currentTemplates, function(e) {
                return e.id === deletedTemplate.id;
            });
            setTemplates([ ...currentTemplates]);
        }
       
    }

    function handleCancelDelete() {
        setConfirmOpen(false);
    }

    return (
        <div className={classes.root}>
            <Grid item container direction="row" justify="space-between" alignItems="flex-end">
                <Typography variant="h4">Payment Templates</Typography>
                <Button variant="contained" color="primary" component={Link} to="/ppay/MessageTemplate/new">+ Payment Template</Button>
            </Grid>
            <br></br>
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                <Grid item>
                    <MaterialTable
                        title=""
                        data={templates}
                        columns={[
                            { title: 'Name', field: 'name' },
                            { title: 'Sample Text', field: 'textBody', cellStyle: {
                                maxWidth: 20,
                                textOverflow: "ellipsis",
                                overflow: 'hidden'
                              }},
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10
                        }}
                        components={{
                            Pagination: licensePaging
                        }}
                        actions={[
                            {
                                icon: () => (<EditOutlined color="primary" />),
                                tooltip: 'Edit Profile',
                                onClick: (event, rowData) => {
                                    history.push(`/ppay/MessageTemplate/${rowData.id}`);
                                }
                            },
                            {
                                icon: () => (<DeleteOutlineOutlined color="primary" />),
                                tooltip: 'Delete Profile',
                                onClick: (event, rowData) => {
                                    confirmDelete(rowData.id)
                                }
                            }
                        ]}
                        onRowClick={(event, rowData) => {
                            history.push(`/ppay/MessageTemplate/${rowData.id}`);
                        }}
                    />
                </Grid>
            </Grid>
            <ConfirmDialog
                        open={confirmOpen}
                        value={toDelete}
                        onConfirm={handleDelete}
                        onCancel={handleCancelDelete}
                        confirmTxt="Yes">
                        <Typography>This profile will be permanently deleted.</Typography>
                    </ConfirmDialog>
        </div>
    )
}
/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

 import React, {useState, useEffect} from 'react';
 import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
 import FullscreenExitOutlinedIcon from '@material-ui/icons/FullscreenExitOutlined';

 export default function PanelMinimized(props)
 {
     const [panelMinimized, setPanelMinimized] = useState(false);
 
     /** Set Minimized panel to value being passed through props */
     useEffect(() => {
         setPanelMinimized(props.value);
     },[props.value]);
     
     /**Handle changes to Minimized panel checkbox */
     const handlePanelMinimized = () => {
         setPanelMinimized(!panelMinimized);
         props.onChange(!panelMinimized)
     }
 
     return(
         <div>
             <Grid container direction="row" alignItems="center">
                 <Grid item>
                     <FormControlLabel
                     control={
                         <Checkbox
                         checked={panelMinimized}
                         onChange={handlePanelMinimized}
                         name="panelminimized"
                         color="primary"
                         />
                     }
                     label="Minimize App Section Panel"
                     />
                 </Grid>
                 <Grid item>
                     <Tooltip title="Give permission to minimize app section panel">
                         <FullscreenExitOutlinedIcon color="primary"/>
                     </Tooltip>
                 </Grid>
             </Grid>
         </div>
     );
 }
 
 
import React, { useEffect, useState, useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserContext from '../context/UserContext';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import ContactPhoneOutlined from '@material-ui/icons/ContactPhoneOutlined';
import PersonOutLine from '@material-ui/icons/PersonOutline';
import PhoneOutLine from '@material-ui/icons/PhoneOutlined';
import NotInterestedOutlined from '@material-ui/icons/NotInterestedOutlined';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DragHandle from '@material-ui/icons/DragHandleOutlined';
import { useParams, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LinearProgress } from '@material-ui/core';
import ConfirmDialog from '../components/ConfirmDialog';
import ChannelDialog from '../components/ChannelDialog';
import { Container, Draggable } from "react-smooth-dnd";
import * as _ from 'lodash';
import { HelpOutlineOutlined } from '@material-ui/icons';
import RetrySlider from '../components/RetrySlider';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    selectInput: {
        minWidth: '100px'
    },
    formControl: {
        minWidth: 120,
        maxWidth: 350,
    },
    dialogDiv: {
        minWidth: '600px'
    },
    tableText: {
        padding: '1em'
    }
}));

const newProfile = {
    id: '',
    tenant: '',
    name: '',
    useAi: false,
    scrubber: {
        cell: false,
        natDnc: false,
        stateDnc: false,
        orgDnc: true,
        phoneDup: false,
        contactDup: false,
    },
    channels: [],
    retry: {
        minDuration: 0,
        maxAttempts: 0,
        maxContacts: 0,
        custRetry: {},
        ratio: 20,
    },
    ai: {
        org: false,
        system: false
    },
    freshLIFO: false,
    retryLIFO: false,
};

//Curently unused
/*
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};*/

export default function Profile() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(newProfile);
    const [selCustRetries, setCustRetries] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [phoneDup, setPhoneDup] = useState(false);
    const [channels, setChannels] = useState([]);
    const [editChannel, setEditChannel] = useState();
    //const [pendingDispos, setPendingDispos] = useState([]);
    //const [pendingRetry, setPendingRetry] = useState({ name: '', codes: [], retriesbyattempt: [], max: '' });
    const unUsed = [{ code: '24', name: 'Abandon' },
    { code: '25', name: 'Agent Abandon' },
    { code: '19', name: 'Agent Override Answering Machine No Message' },
    //{ code: '11', name: 'Agent Override Answering Machine Message'},
    { code: '41', name: 'Agent Requeued' },
    { code: '53', name: 'Agent Specific - Agent Unavailable' },
    { code: '49', name: 'Agent Queue is Full' },
    { code: '82', name: 'Answering Machine Proactive Identified' },
    { code: '14', name: 'Answering Machine Left Message' },
    { code: '84', name: 'Answering Machine Left Message - Proactive Identified' },
    { code: '86', name: 'Agent Override Answering Machine - No Message' },
    { code: '87', name: 'Agent Override Answering Machine - System Left Message' },
    { code: '15', name: 'Busy' },
    { code: '61', name: 'Contact Reactivated' },
    { code: '60', name: 'Dialer Blending Retry' },
    { code: '17', name: 'Fax' },
    { code: '81', name: 'Fax - Proactive Identified' },
    { code: '13', name: 'Fax Machine' },
    //{ code: '', name: 'Force Agent Disconnect'},
    { code: '55', name: 'ISDN Cause Code 102' },
    { code: '56', name: 'ISDN Cause Code 18' },
    { code: '57', name: 'ISDN Cause Code 21' },
    { code: '90', name: 'Call Blocked By Carrier Analytics' },
    { code: '23', name: 'Network Busy' },
    { code: '20', name: 'No Answer' },
    { code: '39', name: 'No Ports' },
    { code: '90', name: 'Call Blocked by Carrier Analytics' },
    { code: '11', name: 'Retry - Anwering Machine' },
    { code: '10', name: 'Retry - Not Available' },
    { code: '9', name: 'Retry - Rescheduled later date/time unspecified' },
    { code: '72', name: 'Retry - Snooze' },
    { code: '46', name: 'Script Action 1' },
    { code: '47', name: 'Script Action 2' },
    { code: '38', name: 'System Failure' },
    { code: '44', name: 'Third Party - Time Zone Restricted' },
    { code: '45', name: 'Third Party - Legislative Restricted' },
    { code: '43', name: 'Third Party - DNC' },
    { code: '91', name: 'Call Refused by Customer' }];

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const savedProfile = await API.graphql(graphqlOperation(queries.getProfile, { id: id }));
            setLoading(false);
            if (savedProfile.data && savedProfile.data.getProfile) {
                setProfile(savedProfile.data.getProfile);
                const pDup = JSON.parse(savedProfile.data.getProfile.scrubber.phoneDup);
                const remCodes = [...unUsed];
                if (savedProfile.data.getProfile.channels) {
                    setChannels(savedProfile.data.getProfile.channels);
                    for (const channel of savedProfile.data.getProfile.channels) {
                        const custom = JSON.parse(channel.custRetry);
                        if (custom) {
                            //remove any dispose from unUsedDispos if they are already in use
                            for (const retry of custom) {
                                if (retry.codes) {
                                    for (const code of retry.codes) {
                                        _.remove(remCodes, ['code', code]);
                                    }
                                }
                            }
                            // setCustRetries(custom);
                        }
                    }
                }
                //set Duplicate options
                setPhoneDup(pDup);
            }
        }


        if (id && id !== 'new') {
            getData();
        } else if (id === 'new') {
            setProfile(newProfile);
            setCustRetries([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        console.log(channels);
    }, [channels])

    //Currently unused
    /*function confirmDelete() {
        setConfirmOpen(true);
    }*/

    async function handleDelete() {
        setConfirmOpen(false);
        const campaignResult = await API.graphql(graphqlOperation(queries.listCampaigns, { filter: { and: [{ tenant: { eq: userContext.tenant } }, { campaignProfileId: { eq: id } }] } }));
        if (campaignResult.data.listCampaigns.items.length > 0) {
            enqueueSnackbar('Cannot delete the profile. Campaigns are using it.', {
                variant: 'error',
                autoHideDuration: 5000
            });
            return;
        }
        await API.graphql(graphqlOperation(mutations.deleteProfile, { input: { id: profile.id } }));
        history.push('/profile');
    }

    function handleCancelDelete() {
        setConfirmOpen(false);
    }
    function handleClose() {
        history.push("/profile")
    }

    const onDrop = ({ removedIndex, addedIndex }) => {
        const posRemove = removedIndex;
        const posAdded = addedIndex;
        const copy = [...channels];
        const dragItem = copy[posRemove];
        _.pullAt(copy, [posRemove]);
        copy.splice(posAdded, 0, dragItem);
        for (const [index, channel] of copy.entries()) {
            channel.order = index + 1;
        }
        setChannels([...copy]);
    }

    const deleteChannel = (data) => () => {
        const copy = channels;
        _.remove(copy, ['order', data]);
        for (const [index, channel] of copy.entries()) {
            channel.order = index + 1;
        }
        setChannels([...copy]);
    }

    const updateEditChannel = (data) => () => {
        setEditChannel({ ...data });
    }

    return (
        <Formik
            initialValues={profile}
            enableReinitialize={true}
            validationSchema={Yup.object(
                {
                    name: Yup.string().required('A Profile name is required')
                        .matches(/[a-zA-Z0-9]/)
                }
            )}
            onSubmit={async (values, formikBag) => {
                let successMsg = 'Profile Updated';
                let mutation = mutations.updateProfile;
                const profileValues = { ...values };
                profileValues.scrubber.phoneDup = phoneDup;
                profileValues.channels = channels;
                profileValues.retry.custRetry = JSON.stringify(selCustRetries);
                delete profileValues.createdAt;
                delete profileValues.updatedAt;
                if (id === 'new') {
                    mutation = mutations.createProfile;
                    profileValues.tenant = userContext.tenant;
                    successMsg = 'Profile Created';
                    delete profileValues.id
                }
                try {
                    const profileResult = await API.graphql(graphqlOperation(mutation, { input: profileValues }));
                    enqueueSnackbar(successMsg);
                    formikBag.setSubmitting(false);
                    const savedProfile = profileResult.data[id === 'new' ? 'createProfile' : 'updateProfile']
                    if (savedProfile.id === id) {
                        setProfile(savedProfile);
                        formikBag.resetForm(savedProfile);
                    } else {
                        formikBag.resetForm(profile);
                        history.push(savedProfile.id);
                    }
                } catch (err) {
                    console.error(err);
                }
            }}
        >
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid container direction="column" alignItems="stretch" spacing={2}>
                        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4">Dialing Profile</Typography>
                            <div>
                                <Grid item container direction="row" spacing={2} justifyContent="flex-end">
                                    <Grid item>
                                        <Button onClick={handleClose} color="primary" variant="outlined">Close</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="submit" color="primary" variant="contained">Save</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid container item direction="column" justifyContent="flex-start" alignItems="stretch">
                            <Grid item>
                                <LinearProgress hidden={!loading} variant="query" color="secondary" />
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" justifyContent="space-between">
                            <Grid item>
                                <TextField
                                    margin="dense"
                                    name="name"
                                    label="Profile Name"
                                    type="text"
                                    className={classes.longInput}
                                    required={true}
                                    value={formikProps.values.name}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    error={formikProps.errors.name && formikProps.touched.name}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactPhoneOutlined color="primary" />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                        </Grid>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color="primary" className={classes.heading}>Scrubbing</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="scrubber.orgDnc"
                                                    checked={formikProps.values.scrubber.orgDnc}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    value={true}
                                                    color="primary" />
                                            }
                                            label="Use Organization DNC"
                                        />

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControlLabel label="Remove Duplicate Users" control={<Switch checked={formikProps.values.scrubber.contactDup} name="scrubber.contactDup" onChange={formikProps.handleChange} color="primary" />} />
                                    </Grid>
                                    <Grid item md={6} xs={12}>

                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography color="primary" className={classes.heading}>Contact Flow</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction='column' spacing={4}>
                                    <Grid item container direction="row" >
                                        <Grid item xs={12}>
                                            <Grid item container direction="column" justifyContent="flex-start" alignItems="stretch">
                                                <RetrySlider
                                                    label="Retry Vs New"
                                                    name="retry.ratio"
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={value => (`${value}%`)}
                                                    type="number"
                                                    value={formikProps.values.retry.ratio}
                                                    onChange={(e) => formikProps.setFieldValue('retry.ratio', e.target.value)}
                                                    min={0}
                                                    max={100}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction='row' alignItems='center'>
                                        <Grid item md={6} xs={12} container direction='row' alignItems='center'>
                                            <Grid item container direction="column" xs={12}>

                                                <Grid item>
                                                    <Typography color="primary" className={classes.heading}>Fresh</Typography>
                                                </Grid>

                                                <Grid item container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Typography>First In - First Out</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Switch
                                                            name="freshLIFO"
                                                            checked={formikProps.values.freshLIFO}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            value={formikProps.values.freshLIFO}
                                                            color="primary" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>Last In - First Out</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} xs={12} container direction='row' alignItems='center'>
                                            <Grid item container direction="column" xs={12}>

                                                <Grid item>
                                                    <Typography color="primary" className={classes.heading}>Retry</Typography>
                                                </Grid>

                                                <Grid item container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Typography>First In - First Out</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Switch
                                                            name="retryLIFO"
                                                            checked={formikProps.values.retryLIFO}
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            value={formikProps.values.retryLIFO}
                                                            color="primary" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>Last In - First Out</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color="primary" className={classes.heading}>Contact Channels</Typography>
                            </AccordionSummary>
                            <Grid item container direction="column" xs={12} justifyContent="flex-end">
                                <div>
                                    <Grid item container direction="row" xs={12} justifyContent="flex-end">
                                        <ChannelDialog
                                            allChannels={channels}
                                            setAllChannels={setChannels}
                                            unUsed={unUsed}
                                            editChannel={editChannel}
                                        />
                                    </Grid>
                                </div>
                                <Grid item xs={12}>
                                    <List dense={true}>
                                        <ListSubheader>
                                            <Grid container justifyContent="flex-start" alignItems="center">
                                                <Grid item xs={1}>
                                                    Order
                                                </Grid>
                                                <Grid item xs={1}>
                                                    Media Type
                                                </Grid>
                                                <Grid item xs={1}>
                                                    Attempts
                                                </Grid>
                                                <Grid item container direction="row" spacing={2} alignItems="center" alignContent="center" xs={2}>
                                                    <Grid item>
                                                        Retry Delay
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="When running agentless campaigns - retries aren't activated till ttl has been met">
                                                            <HelpOutlineOutlined fontSize="small" color="primary" />
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    C2A Profile
                                                </Grid>
                                            </Grid>
                                        </ListSubheader>
                                        <Divider />
                                        <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={onDrop}>
                                            {channels.map(channel => (
                                                <Draggable >
                                                    <ListItem>
                                                        <ListItemText primary={
                                                            <Grid container justifyContent="flex-start" alignItems="center">
                                                                <Grid className={classes.tableText} item xs={1}>
                                                                    {channel.order}
                                                                </Grid>
                                                                <Grid className={classes.tableText} item xs={1}>
                                                                    {channel.type}
                                                                </Grid>
                                                                <Grid className={classes.tableText} item xs={1}>
                                                                    {channel.maxAttempts}
                                                                </Grid>
                                                                <Grid className={classes.tableText} item xs={2}>
                                                                    {channel.retryDelay}
                                                                </Grid>
                                                                <Grid className={classes.tableText} item xs={5}>
                                                                    {channel.paymentProfileName || 'N/A'}
                                                                </Grid>
                                                            </Grid>
                                                        } />
                                                        <ListItemSecondaryAction>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid item>
                                                                    <IconButton aria-label="delete" onClick={updateEditChannel(channel)}>
                                                                        <EditIcon color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton aria-label="delete" onClick={deleteChannel(channel.order)}>
                                                                        <DeleteIcon color="primary" fontSize="small" />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    <DragHandle className="drag-handle" color="primary" />
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Draggable>
                                            ))}
                                        </Container>
                                    </List>
                                </Grid>
                            </Grid>
                        </Accordion>
                        {/* <Grid container item direction="row" justifyContent="flex-end" >
                            <Button disabled={id === 'new'} onClick={confirmDelete} variant="contained">Delete</Button>
                        </Grid> */}
                    </Grid>
                    <ConfirmDialog
                        open={confirmOpen}
                        value={profile}
                        onConfirm={handleDelete}
                        onCancel={handleCancelDelete}
                        confirmTxt="Yes">
                        <Typography>This profile will be permanently deleted.</Typography>
                    </ConfirmDialog>

                </form>
            )}
        </Formik>
    )
}
/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useEffect, useState} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import VoicemailIcon from '@material-ui/icons/Voicemail';

export default function Record(props)
{
    const [record, setRecord] = useState(false);

    /** Set record to whatever value is being passed through props */
    useEffect(() => {
        setRecord(props.value);
    }, [props.value]);

    /** Handle changes of record checkbox */
    const handleRecord = () => {
        setRecord(!record);
        props.onChange(!record);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={record}
                        onChange={handleRecord}
                        name="record"
                        color="primary"
                        />
                    }
                    label="Record"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission to record phone call">
                        <VoicemailIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


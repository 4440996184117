import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Route, Switch, NavLink } from 'react-router-dom';
import Dashboard from '../admin/Dashboard';
import OnboardingRoute from '../pages/Onboarding/OnboardingRoute';
import { List, 
    Divider, 
    ListItem, 
    Drawer, 
    IconButton, 
    ListItemIcon, 
    ListItemText, 
    Collapse } from '@material-ui/core';
import { ChevronLeftOutlined, 
    ChevronRightOutlined, 
    ExpandLess, 
    ExpandMore, 
    DashboardOutlined, 
    AssessmentOutlined, 
    PaymentOutlined,
    WorkOutlined,
    NetworkCheckOutlined,
    ListOutlined,
    PersonOutlineOutlined,
    ReceiptOutlined,
    PeopleAltOutlined, 
    TrendingUpOutlined} from '@material-ui/icons';
import LayoutState from '../context/LayoutState';
import NicLicSubmit from '../admin/NicLicSubmit';
import MaxLicCount from '../admin/MaxLicCount';
import CreateUser from '../admin/CreateUser';
import ListTenants from '../admin/ListTenants';
import Payments from '../admin/Payment';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.drawer.open,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: theme.drawer.open,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.drawer.closed,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        width: theme.drawer.open,
    },
    table: {
        minWidth: 650
    },
	content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    root: {
		display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

const MiddleWare = function (props) {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [dashboardOpen, setDashboardOpen] = useState(true);
    const [tenantOpen, setTenantOpen] = useState();
    const [paymentOpen, setPaymentOpen] = useState(true);
    const [layoutState, setLayoutState] = useState({
        drawerOpen: true
    });
    const[promptPayOpen, setPromptPayOpen] = useState(true);
    const[tenantsOpen, setTenantsOpen] = useState(true);
    const container = props;

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
        setLayoutState({
            ...layoutState,
            drawerOpen: !drawerOpen
        });
    };

    const handleToggleDashboard = () => {
        setDashboardOpen(!dashboardOpen);
    }

    const handleToggleTenant = () => {
        setTenantOpen(!tenantOpen);
    };

    const handleTogglePayments = () => {
        setPaymentOpen(!paymentOpen);
    };

    const handleToggleTenants = () => setTenantsOpen(!tenantsOpen);

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <div onClick={handleDrawerToggle}>
                <IconButton>
                    {drawerOpen ? <ChevronLeftOutlined color="primary"/> : <ChevronRightOutlined color="primary" />}
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItem button key="0" onClick={handleToggleDashboard}>
                    {dashboardOpen ? <ExpandLess /> : <ExpandMore />}
                    <ListItemIcon>
                        <DashboardOutlined color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Dashboards" />
                </ListItem>
                <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
                    <List>
                        <ListItem button key="01" className={classes.nested} component={NavLink} to={`/admin`}>
                            <ListItemIcon>
                                <AssessmentOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Visualizations/Reports" />
                        </ListItem>
                        <ListItem button className={classes.nested} key="02" component={NavLink} to={`/admin/licSubmit`}>
                            <ListItemIcon>
                                <NetworkCheckOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="License Usage" />
                        </ListItem>
                        <ListItem button className={classes.nested} key="02" component={NavLink} to={`/admin/maxlicense`}>
                            <ListItemIcon>
                                <TrendingUpOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Max License Count" />
                        </ListItem> 
                    </List>
                </Collapse>
            </List>
            <List>
                <ListItem button key="0" onClick={() => {setPromptPayOpen(!promptPayOpen)}}>
                    {promptPayOpen ? <ExpandLess /> : <ExpandMore />}
                    <ListItemIcon>
                        <PaymentOutlined color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="PromptPay" />
                </ListItem>
                <Collapse in={promptPayOpen} timeout="auto" unmountOnExit>
                    <List>
                        <ListItem button key="01" className={classes.nested}
                        component={NavLink} to={`/admin/onboarding`}>
                            <ListItemIcon><WorkOutlined color="primary" /></ListItemIcon>
                            <ListItemText primary="Merchant Onboarding" />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
            <List>
                <ListItem button key="0" onClick={handleToggleTenants} >
                    {tenantsOpen ? <ExpandLess /> : <ExpandMore />}
                    <ListItemIcon>
                        <PeopleAltOutlined color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Tenant Management" />
                            
                </ListItem>
                <Collapse in={tenantsOpen} timeout="auto" unmountOnExit>
                    <List>
                        <ListItem button key="01" className={classes.nested} component={NavLink} to={`/admin/ListTenants`}>
                            <ListItemIcon>
                                <ListOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Tenants" />
                        </ListItem>
                    </List>
                    <List>
                        <ListItem button key="01" className={classes.nested} component={NavLink} to={`/admin/CreateUser`}>
                            <ListItemIcon>
                                <PersonOutlineOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Create User" />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
            <List>
                <ListItem button key="0" onClick={handleTogglePayments}>
                    {paymentOpen ? <ExpandLess /> : <ExpandMore />}
                    <ListItemIcon>
                        <PaymentOutlined color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Payments" />
                </ListItem>
                <Collapse in={paymentOpen} timeout="auto" unmountOnExit>
                    <List>
                        <ListItem button key="01" className={classes.nested} component={NavLink} to={`/admin/payments`}>
                            <ListItemIcon>
                                <ReceiptOutlined color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Bill Client" />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </div>
    );
        
    return (
        <LayoutState.Provider value={layoutState}>
            <div className={classes.root}>
                <Drawer
                    container={container}
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen
                        })
                    }}>
                    {drawer}
                </Drawer>
                <div className={classes.content}>
                    <Switch>
                        <Route exact path={`/admin`}>
                            <Dashboard />
                        </Route>
                        <Route path={`/admin/onboarding`}>
                            <OnboardingRoute />
                        </Route>
                        <Route exact path={'/admin/licSubmit'}>
                            <NicLicSubmit/>
                        </Route>
                        <Route exact path={'/admin/maxlicense'}>
                            <MaxLicCount/>
                        </Route>   
                        <Route exact path={`/admin/CreateUser`}>
                            <CreateUser />
                        </Route>
                        <Route exact path={`/admin/ListTenants`}>
                            <ListTenants />
                        </Route>
                        <Route exact path={`/admin/payments`}>
                            <Payments />
                        </Route>
                    </Switch>
                </div>
            </div>
        </LayoutState.Provider>
    );
}
export default MiddleWare;
import React, { useContext, useEffect, useState} from 'react';
import { makeStyles, Paper, Grid, Typography, TextField, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Divider, DialogContent, Dialog, DialogTitle, IconButton, List  } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { Container, Draggable } from "react-smooth-dnd";
import * as _ from 'lodash';
import UserContext from '../context/UserContext';

const useStyles = makeStyles({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    }
});


export default function BalancesDialog(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [balances, setBalances] = useState([]);
    const [balanceId, setBalanceId] = useState('');
    const [balanceType, setBalanceType] = useState('');
    const [balanceAmount, setBalanceAmount] = useState('');
    const [balanceAge, setBalanceAge] = useState('');

    useEffect(()=>{
        if(props.balances){
            setBalances([...props.balances]);
        }
    }, [props.balances])

    function handleBalanceClick(){
        props.setOpenBalances(!props.openBalances);
    }

    const handleBalanceId = (event) => {
        setBalanceId(event.target.value);
    }

    const handleBalanceType = (event) => {
        setBalanceType(event.target.value);
    }

    const handleBalanceAmount = (event) => {
        setBalanceAmount(event.target.value);
    }

    const handleBalanceAge = (event) => {
        setBalanceAge(event.target.value);
    }

    function addBalance(){
        if (balanceId && balanceType && balanceAmount && balanceAge){
            const balance = {
                id: balanceId,
                type: balanceType,
                amount: JSON.parse(balanceAmount),
                age: JSON.parse(balanceAge)
            }
            balances.push(balance);
            props.setBalances(balances);
            console.log(balances);
            setBalanceId('');
            setBalanceType('');
            setBalanceAmount('');
            setBalanceAge('');
        }
    }

    const deleteBalance = (data) => async () =>{
        let tempArray = balances;
        _.remove(tempArray, ['id', data.id]);
        setBalances([...tempArray]);
        props.setBalances([...tempArray]);
    }

    return (
        <Dialog open={props.openBalances} onClose={handleBalanceClick} maxWidth="md">
        <DialogTitle>Contact Balances</DialogTitle>
        <DialogContent>
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="h6">Outstanding Balances</Typography>
                </Grid>
                <Typography variant="overline">New Balance</Typography>
                <Grid item container direction="row" alignItems="center" alignContent="center" spacing={2}>
                    <Grid item>
                        <TextField
                        label="Id"
                        value={balanceId}
                        onChange={handleBalanceId}
                        >    
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                        label="Type"
                        value={balanceType}
                        onChange={handleBalanceType}
                        >    
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                        label="Amount"
                        value={balanceAmount}
                        onChange={handleBalanceAmount}
                        >    
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                        label="Age"
                        value={balanceAge}
                        onChange={handleBalanceAge}
                        >    
                        </TextField>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <AddIcon onClick={addBalance} color="primary" fontSize="smaill" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <br></br>
            <Divider></Divider>
            <br></br>
            <Grid item container direction="column">
                <Paper>
                    <List dense={true}>
                        <ListSubheader color="primary">Contact Balances</ListSubheader>
                        <Divider />
                        <Container>
                            {balances.map(data => (
                                <Draggable>
                                    <ListItem >
                                        <ListItemText primary={
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    {data.id}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {data.type}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {data.amount}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {data.age}
                                                </Grid>
                                            </Grid>
                                        } />
                                        <ListItemSecondaryAction>
                                        <Grid container direction="row" alignItems="center">
                                                <Grid item>
                                                    <IconButton aria-label="delete" onClick={deleteBalance(data)}>
                                                        <DeleteIcon color="primary" fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Draggable>
                                ))}
                        </Container>
                    </List>
                </Paper>
            </Grid>
        </DialogContent>
    </Dialog>
    )
}
import React, { useRef, useState } from 'react';
import {Grid, FormControl, Select, InputLabel, Accordion, AccordionDetails, AccordionSummary, TextField,
     CircularProgress, Button, Typography, MenuItem, InputAdornment, FormControlLabel, Checkbox, Paper, Divider, Backdrop} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import StepperController from './StepperController';
import {useSnackbar} from 'notistack';
import axios from 'axios';

export default function PricingInfoForm(props) {
    const {stepBack, nextStep, classes, pricingInfo, setPricingInfo, merchantInfo, vendorId} = props;
    const {enqueueSnackbar} = useSnackbar();
    const savePricingInfoRef = useRef();
    const [savingPricingInfo, setSavingPricingInfo] = useState(false);
    const [loading, setLoading] = useState(false);

    function submitInformation() {
        const isValid = pricingInfo.data.CheckAllFields();
        redraw();
        if(!isValid) {
            enqueueSnackbar("Please fix the highlighted fields", { variant: "error" });
        } else {
            savePricingInfo(false);
            nextStep();
        }
    }

    async function savePricingInfo(makeSnack = true) {
        if(!makeSnack) {
            setLoading(true);
        }

        setSavingPricingInfo(true);
        

        try {
            const result = await axios.post(
                `${process.env.REACT_APP_PP_URL}/cms-onboard/save-fees`, {
                    id: pricingInfo.data.dynamoRowId,
                    vendor: vendorId,
                    type: pricingInfo.data.selectedPricingType,
                    ...pricingInfo.data.CondenseFields(true)
                },
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_PP_API_KEY
                    }
                }
            );

            pricingInfo.data.dynamoRowId = result.data;
            redraw();
            if(makeSnack) {
                enqueueSnackbar("Pricing info saved", { variant: "success" });
            }
        } catch (err) {
            console.log(err);

            if(makeSnack) {
                enqueueSnackbar("Error saving pricing info", { variant: "error" });
            }
        }

        setLoading(false);
        setSavingPricingInfo(false);
    }

    function textFieldDecorator(type) {
        if(type === "currency") {
            return "$";
        } else if(type === "percent") {
            return "%";
        } else {
            return null;
        }
    }

    function handleAccordionClick(provider, isExpanded) {
        provider.accordionExpanded = isExpanded;
        redraw();
    }

    function handleTogglePricingEnable(provider, isEnabled) {
        provider.enabled = isEnabled;
        redraw();
    }

    /**
     * Simply tricks React into re-rendering the page (to show any changes in our pricing info variable).
     */
    function redraw() {
        setPricingInfo({...pricingInfo});
    }

    return (
        <div>
            <Grid container direction="column" spacing={2}>
                <Grid container item direction='row' justify='space-between' spacing={4}>
                    <Grid item>
                        <FormControl className={classes.select}>
                            <InputLabel id="pricingType">Type</InputLabel>
                            <Select labelId="pricingType" onChange={(event) => {pricingInfo.data.selectedPricingType = event.target.value; redraw();}} value={pricingInfo.data.selectedPricingType}>
                                <MenuItem value="ERR" key="ERR">ERR</MenuItem>
                                <MenuItem value="Tiered" key="Tiered">Tiered</MenuItem>
                                <MenuItem value="Interchange" key="Interchange">Interchange</MenuItem>
                                <MenuItem value="Flat" key="Flat">Flat</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' spacing={2}>
                            <Grid item>
                                <TextField
                                    label="ID"
                                    value={merchantInfo.cmsMerchId}
                                    className={classes.longTextField}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Merchant App ID"
                                    value={merchantInfo.cmsMerchId}
                                    className={classes.longTextField}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item direction='column' spacing={2} style={{width: "100%"}}>
                    <Grid item>
                        {pricingInfo.data.providers.map((provider, providerIndex) => 
                            <Accordion expanded={provider.accordionExpanded && provider.enabled} onChange={(event, isExpanded) => {handleAccordionClick(provider, isExpanded);}} className={providerIndex % 2 === 1 ? classes.accordionOdd : classes.accordionEven} key={providerIndex}>
                                <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel2a-content" id={provider.displayName}>
                                    <div style={{flexGrow: 1}} />
                                    <Typography style={{flexGrow: 1}}>{provider.displayName}</Typography>
                                    <FormControlLabel control={<Checkbox checked={provider.enabled} disabled={!provider.canBeDisabled} onChange={(event) => {handleTogglePricingEnable(provider, event.target.checked);}} color="primary"/>} label="Enabled" labelPlacement="start" />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="row" spacing={4}>
                                        {provider.columns.map(function(column, columnIndex) {
                                            if(column.pricingTypeHook === pricingInfo.data.selectedPricingType || column.pricingTypeHook === "InternalExempt") {
                                                return ( 
                                                    <Grid item key={columnIndex}>
                                                        <Paper elevation={4} style={{padding: '10px'}}>
                                                            <Grid container direction='column' spacing={2}>
                                                                <Grid item>
                                                                    <Typography variant='body1'>{column.displayName}</Typography>
                                                                    <Divider />
                                                                </Grid>
                                                                <Grid item container direction={column.fieldDirection} spacing={2}>
                                                                    {column.fields.map((field, fieldIndex) => 
                                                                        <Grid item key={fieldIndex}>
                                                                            <TextField 
                                                                                variant='outlined'
                                                                                label={field.displayName} 
                                                                                className={classes.longTextField} 
                                                                                type={field.inputType} 
                                                                                error={!field.valid} 
                                                                                onChange={(event) => {field.ValidateAndSetFieldValue(Number(event.target.value)); redraw();}} 
                                                                                onBlur={() => {field.TruncateValue(); redraw();}}
                                                                                value={field.currentValue}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">{textFieldDecorator(field.decorator)}</InputAdornment>
                                                                                    )
                                                                                }}
                                                                                helperText={!field.valid && `${field.errorText}`}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                );
                                            } else return null; }
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" ref={savePricingInfoRef} onClick={savePricingInfo}>Save Pricing Information</Button>
                    {savingPricingInfo && savePricingInfoRef.current && 
                    <CircularProgress 
                        style={{position: 'absolute', top: savePricingInfoRef.current.offsetTop, left: savePricingInfoRef.current.offsetLeft + (savePricingInfoRef.current.offsetWidth / 2) - (34 / 2)}} 
                        size={34} 
                        variant='indeterminate' 
                        color='secondary' />}
                </Grid>
            </Grid>
            <StepperController onStepBack={stepBack} onStepForward={submitInformation} />
            <Backdrop open={loading} className={classes.backdrop}>
                <Grid container direction='column' justify='center' alignItems='center' spacing={4}>
                    <Grid item>
                        <Typography variant='h6'>Please wait...</Typography>
                    </Grid>
                    <Grid item>
                        <CircularProgress variant='indeterminate' size={60} />
                    </Grid>
                </Grid>
            </Backdrop>
        </div>
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';

const ValueEditor = ({
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values
}) => {
  if (operator === 'null' || operator === 'notNull') {
    return null;
  }

  switch (type) {
    case 'select':
      return (
        <Grid item>
          <FormControl>
            <InputLabel>{title}</InputLabel>
              <Select 
              labelWidth={0}
              value={value} 
              onChange={(e) => handleOnChange(e.target.value)} >
                {values.map((v) => (
                  <MenuItem key={v.name} value={v.name}> {v.label}</MenuItem>
                ))}
              </Select>
          </FormControl>
        </Grid>
      );

    case 'checkbox':
      return (
        <Grid item>
          <FormControlLabel variant=""
            control={
              <Checkbox
                checked={!!value}
                onChange={(e) => handleOnChange(e.target.checked)}
                value={!!value}
                color="primary"
              />
            }
            label={title}
          />
        </Grid>
      );

    case 'radio':
      return (
        <Grid item>
          <RadioGroup aria-label={title} onChange={(e) => handleOnChange(e.target.value)}>
            {values.map((v) => (
              <FormControlLabel variant="" value={v.name} control={<Radio />} label={v.name} />
            ))}
          </RadioGroup>
        </Grid>
      );

    default:
      return (
        <Grid item>
          <TextField
            id={title}
            label={title}
            value={value}
            onChange={(e) => handleOnChange(e.target.value)}
          />
        </Grid>
      );
  }
};

ValueEditor.displayName = 'ValueEditor';

ValueEditor.propTypes = {
  field: PropTypes.string,
  operator: PropTypes.string,
  value: PropTypes.any,
  handleOnChange: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['select', 'checkbox', 'radio', 'text']),
  inputType: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.object)
};

export default ValueEditor;

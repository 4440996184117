import React, { useState, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';
import {
    TextField, Grid, Button,
    InputLabel, Typography, Paper, Select, FormControl, MenuItem, makeStyles
} from '@material-ui/core';
import * as _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import { Formik } from 'formik';
import * as moment from 'moment';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import MaterialTable from 'material-table';
import QueryBuilder from '../components/QueryBuilder';
import UserContext, { isDateField } from '../context/UserContext';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import LayoutState from '../context/LayoutState';
import Validations from '../components/YupValidations';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    },
    inputTopPad: {
        paddingTop: '15px !important'
    },
    descriptionField: {
        minWidth: '400px'
    },
    drawerOpenTableWidth: {
        maxWidth: `calc(100vw - ${theme.drawer.open + theme.spacing(4)}px)`
    },
    drawerClosedTableWidth: {
        maxWidth: `calc(100vw - ${theme.drawer.closed + theme.spacing(4)}px)`
    }
}));


/**
 * The segment class implements creation, update and cloning of campains
 * Called Via a route
 * Can have URL params of Segment ID and Action (Edit, or Clone) No params for create
 * * @category Pages
 * @component
 * @param {string} segmentId The component that expects a param of 'data'.
 * @param {string} action The graphql query string to retreive data with.
 */
export default function Segment(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const userContext = useContext(UserContext);
    const layoutContext = useContext(LayoutState);
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [segmentState, setSegment] = useState({
        name: '',
        description: '',
        query: {
            qbQuery: '',
            searchFilter: '',
            sort: {
                field: '',
                direction: ''
            }
        }
    });

    const sort = useRef({
        field: '',
        direction: ''
    });

    const [action, setAction] = useState('');

    const [searchFilter, setSearchFilter] = useState({});
    const [initQuery, setInitQuery] = useState({});
    const [qbQuery, setQbQuery] = useState({});
    const [qbFields, setQbFields] = useState([
        { name: 'firstName', label: 'First Name' },
        { name: 'lastName', label: 'Last Name' },
        { name: 'source', label: 'Source' },
        { name: 'phone', label: 'Phone' },
        { name: 'cell', label: 'Cell' },
        { name: 'email', label: 'eMail' },
        { name: 'dateCreated', label: 'Date Created' },
        { name: 'status', label: 'Status' },
        { name: 'city', label: 'City' },
        { name: 'state', label: 'State' },
        { name: 'zip', label: 'Zip' },
        { name: 'timeZone', label: 'Time Zone' }
    ]);

    const sortFields = [
        { name: 'firstName', label: 'First Name' },
        { name: 'lastName', label: 'Last Name' },
        { name: 'source', label: 'Source' },
        { name: 'phone', label: 'Phone' },
        { name: 'cell', label: 'Cell' },
        { name: 'email', label: 'eMail' },
        { name: 'dateCreated', label: 'Date Created' },
        { name: 'status', label: 'Status' },
        { name: 'city', label: 'City' },
        { name: 'state', label: 'State' },
        { name: 'zip', label: 'Zip' },
        { name: 'timeZone', label: 'Time Zone' }
    ];
    const sortDirections = [
        { name: 'asc', label: 'Ascending' },
        { name: 'desc', label: 'Descending' }
    ];
    const [previewColumns, setPreviewColumns] = useState([
        { title: 'First Name', field: 'firstName' },
        { title: 'Last Name', field: 'lastName' },
        { title: 'Source', field: 'source' },
        { title: 'Phone', field: 'phone' },
        { title: 'Cell', field: 'cell' },
        { title: 'Email', field: 'email' },
        { title: 'State', field: 'state' },
        { title: 'Timezone', field: 'timeZone' }
    ]);

    const hiddenInputRef = useRef(null);

    useEffect(() => {
        async function getData() {
            //Are we creating? Editing? Or Cloning
            try {
                //If an ID is present on the route we are editing or cloneing
                if (id) {
                    // Get the current Segment information
                    const search = await API.graphql(graphqlOperation(queries.getSegment, { id: id }));
                    const dbSegment = { ...search.data.getSegment }
                    const values = queryString.parse(location.search);
                    //if we are editing, set the values for the object includeing the id, set the mutation
                    if (values.op === 'edit') {
                        setAction('edit');
                        if (!dbSegment.query.sort) {
                            dbSegment.query.sort = {
                                field: '',
                                direction: ''
                            };
                        }
                    }
                    if (values.op === 'clone') {
                        setAction('clone');
                        dbSegment.name = `${dbSegment.name} - Clone`
                    }
                    sort.current = dbSegment.query.sort;
                    setSegment(dbSegment);
                    setInitQuery(JSON.parse(dbSegment.query.qbQuery));
                }
                else {
                    setAction('create');
                }

            } catch (err) {
                console.error(err);
            }
        }
        getData();
    }, [id, location.search]);

    useEffect(() => {
        if (userContext.customFields) {
            const newQbFields = [...qbFields];
            const newPreviewColumns = [...previewColumns];
            for (const customField of userContext.customFields) {
                const existingField = _.find(newQbFields, ['name', customField.name]);
                if (!existingField) {
                    newQbFields.push({ name: `custom:${customField.name}`, label: customField.displayName });
                }
                //preview column setup
                const existingColumn = _.find(newPreviewColumns, ['field', customField.name]);
                if (!existingColumn) {
                    newPreviewColumns.push({
                        title: customField.displayName,
                        field: customField.name,
                        render: rowData => (rowData.customFields && renderCustomField(customField.name, rowData))
                    });
                }
            }
            setQbFields(newQbFields);
            setPreviewColumns(newPreviewColumns);
            logQuery(qbQuery);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext.customFields, userContext.tenant]);

    const renderCustomField = (fieldName, rowData) => {
        const customFields = JSON.parse(rowData.customFields);
        if (customFields[fieldName]) {
            if (isDateField(fieldName)) {
                return moment(customFields[fieldName]).format('LLL');
            } else {
                return customFields[fieldName];
            }
        } else {
            return '';
        }
    }

    const logQuery = (query) => {
        setQbQuery({ ...query });

        if (query.sort) {
            sort.current = query.sort;
            delete query.sort;
        }
        if (query.rules) {
            if (query.rules.length > 0) {

                hiddenInputRef.current.value = JSON.stringify(query);
                const newSearchFilter = processFilterArray(query.rules, query.combinator);
                setSearchFilter(newSearchFilter);
                previewContacts(newSearchFilter);
            }
            else {
                //If we are missing any rules, clear the data
                setData([]);
                setTotal(0);
            }
        }
        return;
    }

    const processFilterArray = (items, combinator) => {
        const ruleArray = [];
        if (userContext.tenant) {
            const ruleGroup = { [combinator]: ruleArray };
            //the rule array always has to have the tenant included for security
            _.forEach(items, (item) => { ruleArray.push(processFilterItem(item, items.combinator)) });
            const tenantRuleGroup = { and: [{ tenant: { eq: userContext.tenant } }, ruleGroup] };
            return tenantRuleGroup;
        } else {
            return {}
        }
    }

    const processFilterItem = (item) => {
        const cpyItem = { ...item };
        if (item.field) {
            //item.value = item.value.toLowerCase();
            if (!item.field.startsWith('custom:')) {
                if (cpyItem.operator === 'wildcard') {
                    cpyItem.value = cpyItem.value.toLowerCase();
                    cpyItem.value = `*${cpyItem.value}*`;
                }
                if (cpyItem.operator === 'wildcard*') {
                    cpyItem.value = cpyItem.value.toLowerCase();
                    cpyItem.value = `${cpyItem.value}*`;
                    cpyItem.operator = 'wildcard';
                }
                if (cpyItem.operator === '*wildcard') {
                    cpyItem.value = cpyItem.value.toLowerCase();
                    cpyItem.value = `*${cpyItem.value}`;
                    cpyItem.operator = 'wildcard';
                }
                const ruleItem = { [cpyItem.field]: { [cpyItem.operator]: cpyItem.value } };
                return ruleItem;
            } else {
                let fieldName = cpyItem.field.split(':')[1];
                if (cpyItem.operator === 'wildcard') {
                    cpyItem.value = `*${cpyItem.value}*`;
                    fieldName += '.keyword';
                } else if (cpyItem.operator === 'wildcard*') {
                    cpyItem.value = `${cpyItem.value}*`;
                    cpyItem.operator = 'wildcard';
                    fieldName += '.keyword';
                } else if (cpyItem.operator === '*wildcard') {
                    cpyItem.value = `*${cpyItem.value}`;
                    cpyItem.operator = 'wildcard';
                    fieldName += '.keyword';
                } else {
                    if (cpyItem.value === true || cpyItem.value === false || cpyItem.value === 'true' || cpyItem === 'false') {
                        if (_.isString(cpyItem.value)) {
                            cpyItem.value = cpyItem.value === 'true';
                        } else {
                            cpyItem.value = cpyItem.value === true;
                        }
                    } else if (!isNaN(cpyItem.value)) {
                        cpyItem.value = +cpyItem.value;
                    } else if (isCustomFieldDate(fieldName) && !!Date.parse(cpyItem.value)) {
                        cpyItem.value = Date.parse(cpyItem.value);
                    }
                }
                const ruleItem = { [`customFields.${fieldName}`]: { [cpyItem.operator]: cpyItem.value } };
                return ruleItem;
            }
        }
        return processFilterArray(cpyItem.rules, cpyItem.combinator);

    }

    const isCustomFieldDate = (fieldName) => {
        const customField = _.find(userContext.customFields, ['name', fieldName]);
        if (customField) {
            return customField.type === 'Date';
        } else {
            return false;
        }
    }

    const previewContacts = async (updatedSearchFilter) => {
        try {
            let options = {
                limit: 250
            };
            options.filter = JSON.stringify(updatedSearchFilter);
            if (sort.current.field !== '') {
                options.sort = sort.current;
            }
            //console.log(options.filter);
            const listData = await API.graphql(graphqlOperation(queries.expandedContactSearch, options));
            setData(listData.data.expandedContactSearch.items);
            setTotal(listData.data.expandedContactSearch.total === null ? 0 : listData.data.expandedContactSearch.total);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleButtonClose = (path) => {
        history.push('/segments');
    }

    return (

        <Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
            <Grid item className={clsx({
                [classes.drawerOpenTableWidth]: layoutContext.drawerOpen,
                [classes.drawerClosedTableWidth]: !layoutContext.drawerOpen
            })}>
                <Formik
                    initialValues={segmentState}
                    enableReinitialize={true}
                    validationSchema={Validations.segmentValidation}
                    onSubmit={async values => {
                        //First we need to check to see if there are rules specified
                        //Convert the query object to JSON
                        //Then see if it contains the word 'operator'.
                        //If it does not, then there are no rules specified in the query builder.
                        const queryString = JSON.stringify(qbQuery);
                        if (!queryString.includes('operator'))
                            enqueueSnackbar('You must create at least one rule.', { variant: 'error' });
                        else {//Otherwise, continue like normal
                            //dynamodb doesn't like empty strings. This will turn empty strings into null
                            const segment = { ...values };
                            for (const key in segment) {
                                if (key === 'startDate' || key === 'expireDate') {
                                    const date = moment(segment[key]);
                                    segment[key] = date.toISOString();
                                }
                                segment[key] = segment[key] || null;
                            }

                            //DynamoDB flips out if the externalId is null.
                            //If the externalId is present and is null, remove it.
                            if ('externalId' in segment) {
                                if (segment['externalId'] === null)
                                    delete segment['externalId'];
                            }

                            segment.tenant = userContext.tenant;
                            if (qbQuery && searchFilter) {
                                try {
                                    segment.query = {
                                        qbQuery: JSON.stringify(qbQuery),
                                        searchFilter: JSON.stringify(searchFilter),
                                        sort: values.query.sort
                                    };
                                    delete segment.createdAt;
                                    delete segment.updatedAt;

                                    if (action === 'edit') {
                                        await API.graphql(graphqlOperation(mutations.updateSegment, { input: segment }));
                                    }
                                    else {
                                        if (action === 'clone') {
                                            delete segment.id;
                                        }
                                        await API.graphql(graphqlOperation(mutations.createSegment, { input: segment }));
                                    }
                                    handleButtonClose('/segments');
                                } catch (err) {
                                    console.error(err);
                                }
                            }
                            else {
                                alert("The segment must contain a contact query");
                            }
                        }
                    }}
                >
                    {formikProps => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item container direction="row" spacing={1} alignItems="center">
                                    <Grid item xs={6}>
                                        {action === 'create' &&
                                            <Typography variant="h4">Create Segment</Typography>
                                        }
                                        {action === 'edit' &&
                                            <Typography variant="h4">Edit Segment</Typography>
                                        }
                                        {action === 'clone' &&
                                            <Typography variant="h4">Clone Segment</Typography>
                                        }
                                    </Grid>
                                    <Grid container item xs={6} spacing={2} justify="flex-end">
                                        <Grid item>
                                            <Button size="small" onClick={() => handleButtonClose('/segment')} color="primary" variant="outlined">Close</Button>
                                        </Grid>
                                        <Grid item>
                                            {/* <Button size="small" type="submit" disabled={!formikProps.dirty} variant="contained" color="primary">Save</Button> */}
                                            <Button size="small" type="submit" variant="contained" color="primary">Save</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Paper className={classes.paper} elevation={2}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    name="name"
                                                    label="Name"
                                                    type="text"
                                                    required={true}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    value={formikProps.values.name}
                                                    error={formikProps.errors.name && formikProps.touched.name} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="dense"
                                                    name="description"
                                                    label="Description"
                                                    type="text"
                                                    multiline
                                                    rows={2}
                                                    className={classes.descriptionField}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                    value={formikProps.values.description}
                                                    error={formikProps.errors.description && formikProps.touched.description} />
                                            </Grid>
                                            <Grid container item direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h6">Query Builder</Typography>
                                                </Grid>
                                                {_.isEmpty(initQuery) &&
                                                    <QueryBuilder fields={qbFields} onQueryChange={_.throttle(logQuery, 1000, { 'leading': false, 'trailing': true })} showCombinatorsBetweenRules={true} />
                                                }
                                                {!_.isEmpty(initQuery) &&
                                                    <QueryBuilder fields={qbFields} query={initQuery} onQueryChange={_.throttle(logQuery, 1000, { 'leading': false, 'trailing': true })} showCombinatorsBetweenRules={true} />
                                                }
                                                <TextField ref={hiddenInputRef} type="hidden" name="query.qbQuery" value={formikProps.values.query.qbQuery} onBlur={formikProps.handleBlur} onChange={formikProps.handleChange} />
                                            </Grid>
                                            <Grid item container direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="h6">Sorting</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container direction="row" spacing={4}>
                                                    <Grid item>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel shrink id="field-name-sort">Field Name</InputLabel>
                                                            <Select
                                                                labelId="field-name-sort"
                                                                name="query.sort.field"
                                                                value={formikProps.values.query.sort.field}
                                                                displayEmpty
                                                                onChange={formikProps.handleChange}
                                                            >
                                                                <MenuItem value="">None</MenuItem>
                                                                {sortFields.map(field => (
                                                                    <MenuItem key={field.name} value={field.name}>{field.label}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel shrink id="direction-sort">Direction</InputLabel>
                                                            <Select
                                                                labelId="direction-sort"
                                                                name="query.sort.direction"
                                                                value={formikProps.values.query.sort.direction}
                                                                displayEmpty
                                                                onChange={formikProps.handleChange}
                                                            >
                                                                <MenuItem value="">None</MenuItem>
                                                                {sortDirections.map(direction => (
                                                                    <MenuItem key={direction.name} value={direction.name}>{direction.label}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className={classes.inputTopPad}>
                                                <InputLabel id="Total">Total Segment Contacts</InputLabel>
                                                <TextField
                                                    disabled
                                                    margin="dense"
                                                    name="query"
                                                    value={total}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid item className={clsx({
                [classes.drawerOpenTableWidth]: layoutContext.drawerOpen,
                [classes.drawerClosedTableWidth]: !layoutContext.drawerOpen
            })}>
                <MaterialTable title="Preview"
                    columns={previewColumns}
                    data={data}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10
                    }}
                    onRowClick={(event, rowData) => {
                        history.push(`/contact/${rowData.id}`);
                    }}
                />
            </Grid>

        </Grid >

    )
}

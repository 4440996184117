import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Route, Switch, useRouteMatch, NavLink } from 'react-router-dom';
import ConfigProfile from '../E2/ConfigProfile';
import ConfigProfiles from '../E2/ConfigProfiles';
import IvrProfile from '../E2/IVRProfile';
import IvrProfiles from '../E2/IVRProfiles';
import E2Dash from '../E2/E2Dash';
import DefaultUIConfig from '../E2/DefaultUIConfig';
import { List, 
    Divider, 
    ListItem, 
    Drawer, 
    IconButton, 
    ListItemIcon, 
    ListItemText, 
    Collapse } from '@material-ui/core';
import { PermDataSettingOutlined, 
    ChevronLeftOutlined, 
    ChevronRightOutlined, 
    ExpandLess, 
    ExpandMore, 
    DashboardOutlined, 
    AssessmentOutlined, 
    RecordVoiceOverOutlined,
    PermPhoneMsgOutlined,
    ColorLensOutlined,
    GetAppOutlined} from '@material-ui/icons';
import LayoutState from '../context/LayoutState';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.drawer.open,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: theme.drawer.open,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.drawer.closed,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        width: theme.drawer.open,
    },
    table: {
        minWidth: 650
    },
	content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    root: {
		display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

const MiddleWare = function (props) {

    const classes = useStyles();
    let { path } = useRouteMatch();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [dashboardOpen, setDashboardOpen] = useState(true);
    const [configOpen, setConfigOpen] = useState(true);
    const [layoutState, setLayoutState] = useState({
        drawerOpen: true
    });
    const container = props;

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
        setLayoutState({
            ...layoutState,
            drawerOpen: !drawerOpen
        });
    };

    const handleToggleDashboard = () => {
        setDashboardOpen(!dashboardOpen);
    };

    const handleToggleConfig = () => {
        setConfigOpen(!configOpen)
    };
        
        const drawer = (
            <div>
                <div className={classes.toolbar} />
                <div onClick={handleDrawerToggle}>
                    <IconButton>
                        {drawerOpen ? <ChevronLeftOutlined color="primary"/> : <ChevronRightOutlined color="primary" />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button
                        key="0"
                        onClick={handleToggleDashboard}>
                        {dashboardOpen ? <ExpandLess /> : <ExpandMore />}
                        <ListItemIcon><DashboardOutlined color="secondary" /></ListItemIcon>
                        <ListItemText primary="Dashboards" />
                    </ListItem>
                    <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem button key="01" className={classes.nested}
                            component={NavLink} to={`/e2/Dashboard`}>
                                <ListItemIcon><AssessmentOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="Visualizations/Reports" />
                            </ListItem>
                            {/* <ListItem button className={classes.nested}
                                key="02"
                                component={NavLink} to={`/e2`}>
                                <ListItemIcon><NotificationsOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="Alerts/Notifications" />
                            </ListItem> */}
                        </List>
                    </Collapse>
                    <ListItem button
                        key="03"
                        onClick={handleToggleConfig}>
                        {configOpen ? <ExpandLess /> : <ExpandMore />}
                        <ListItemIcon><PermDataSettingOutlined color="secondary" /></ListItemIcon>
                        <ListItemText primary="Channel Configuration"></ListItemText>
                    </ListItem>
                    <Collapse in={configOpen} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem button key="06" className={classes.nested} component={NavLink} to={'/e2/ui-config'}>
                                <ListItemIcon><ColorLensOutlined color="primary"/></ListItemIcon>
                                <ListItemText primary="Default UI Configuration"/>
                            </ListItem>
                            <ListItem button key="04" className={classes.nested}
                                component={NavLink} to={'/e2'}>
                                <ListItemIcon><RecordVoiceOverOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="Configuration Profiles" />
                            </ListItem>
                        </List>
                    </Collapse> 
                </List>
            </div>
        );
        
        return (
            <LayoutState.Provider value={layoutState}>
                <div className={classes.root}>
                    <Drawer
                        container={container}
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen
                            })
                        }}>
                        {drawer}
                    </Drawer>
                    <div className={classes.content}>
                    <Switch>
                        <Route exact path={path}>
                            <ConfigProfiles />
                        </Route>
                        <Route path="/e2/profiles" exact>
                            <ConfigProfile />
                        </Route>
                        <Route path="/e2/Dashboard" exact>
                            <E2Dash />
                        </Route>
                        <Route path="/e2/ui-config">
                            <DefaultUIConfig />
                        </Route>
                        <Route path="/e2/ivr" exact>
                            <IvrProfiles />
                        </Route>
                        <Route path="/e2/ivr/profiles" exact>
                            <IvrProfile />
                        </Route>
                    </Switch>
                    </div>
                </div>
            </LayoutState.Provider>
        );
    }

export default MiddleWare;

/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import PhoneForwardedOutlinedIcon from '@material-ui/icons/PhoneForwardedOutlined';

export default function Transfer(props)
{
    const [transfer, setTransfer] = useState(false);

    /** Set transfer to value that is being passed through props */
    useEffect(() => {
        setTransfer(props.value);
    }, [props.value])

    // Handle hold checkbox change
    const handleTransfer = () => {
        setTransfer(!transfer);
        props.onChange(!transfer);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                <FormControlLabel
                control={
                    <Checkbox
                    checked={transfer}
                    onChange={handleTransfer}
                    name="transfer"
                    color="primary"
                    />
                }
                label="Transfer/Conference"
                />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission to transfer/conference a phone call">
                        <PhoneForwardedOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


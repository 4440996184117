import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0277bd',
    },
    secondary: {
      main: '#f9a45f',
    },
    warning: {
      main: '#ff9800',
    },
    error: {
      main: '#f44336'
    },
    success: {
      main: '#4caf50'
    }
  },
  drawer: {
    open: 275,
    closed: 73
  }
});

export default theme;
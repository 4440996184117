import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSnackbar } from 'notistack';
import * as request from 'superagent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px',
        flex: 1
    },
    grid: {
        height: '100%'
    },
    topPad: {
        paddingTop: '15px'
    },
    successfulPayment: {
        color: 'green'
    },
    paymentDeclined: {
        color: 'red'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }

}));

/**
 * The ExportDialog component is used Allow users to choose how the export data
 * Expects No params our query string values on the route
 * * @category Components
 *  @component
 *
 */
const RefundDialog = function (props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [validAmount, setValidAmount] = useState(false);
    const [refundAmount, setRefundAmount] = useState(); // Removed the 0 from useState. If something breaks it could be this
    const [refundReason, setRefundReason] = useState('');
    const [usePercentage, setUsePercentage] = useState(false);
    const [refundPercentage, setRefundPercentage] = useState();
    const [submitting, setSubmitting] = useState(false);
    

    useEffect(() => {
    }, []);

    /** Arrow function handle amount change within the Refund Dialog */
    const handleRefundChange = (event) => {
        setRefundAmount(Number(event.target.value));
        if (Number(event.target.value) <= props.maxAmount) {
            setValidAmount(true);
        }
         else {
            setValidAmount(false);
         }
    }

    /** This arrow function handles change for percentage. We check that the amount is valid */
    const handleRefundPercentageChange = (event) => {
        if(Number(event.target.value) <= 100 && Number(event.target.value) >= 0){
            setRefundPercentage(Number(event.target.value));
            let number = (event.target.value / 100) * props.maxAmount;
            number = number.toFixed(2);
            setRefundAmount(Number(number));
            setValidAmount(true);
        }else{
            setValidAmount(false);
        }
    }

    /** Arrow function to handle change of the reason textbox within the refund dialog */
    const handleReasonChange = (event) => {
        setRefundReason(event.target.value);
    }    

    /** This function makes a submission of a refund to the refunds lambda */
    async function handleRefundClick() {
        setSubmitting(true);
        try {
            if (refundAmount) {
                const reqBody = {
                    vendorId: props.vendorId,
                    transactionId: props.transactionId,
                    refundAmount: refundAmount,
                    refundReason: refundReason,
                    agentId: props.agentId
                }
                const url = `${process.env.REACT_APP_PP_URL}/refunds/refund`;
                const refundResponse = await request.post(url)
                    .set("x-api-key", props.c3ApiKey)
                    .type("json")
                    .send(reqBody);
                if (refundResponse.status !== 200) {
                    enqueueSnackbar(`Refund unsuccessful ${refundResponse.body.msg}`, {
                        variant: 'error',
                        autoHideDuration: 5000
                    });
                    setSubmitting(false);
                }
                else {
                    enqueueSnackbar(`Refund of $${refundAmount} successful`, {
                        variant: 'success',
                        autoHideDuration: 5000
                    });
                    props.refresh();
                    props.onCancel();
                    setSubmitting(false);
                }
            }
            else {
                enqueueSnackbar('Refund value is null', {
                    variant: 'error',
                    autoHideDuration: 5000
                });
                setSubmitting(false);
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Refund unsuccessful ${err}`, {
                variant: 'error',
                autoHideDuration: 5000
            });
            setSubmitting(false);
        }
    }

    /** Close refund dialog */
    async function closeDialog() {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    /** Function for Percentage switch in issueRefundDialog */
    function handlePercentageChange(){
        setUsePercentage(!usePercentage);
    }

    return (
        <div>
            <Dialog maxWidth="sm" open={props.open}>
                <DialogTitle id="dp-dialog-title">Customer Refund</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" alignItems="stretch" spacing={2}>
                        <Grid item container direction="column" justifyContent="flex-start" spacing={1}>
                            <Grid container item direction="row">
                                <Typography variant="button" color="primary">Enter a refund amount that does not exceed ${props.maxAmount.toFixed(2)} </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" justifyContent="flex-end" spacing={1}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item>
                                    {!usePercentage && 
                                    <TextField
                                        placeholder="0"
                                        label="Refund Amount"
                                        value={refundAmount}
                                        required={true}
                                        onChange={handleRefundChange}
                                        type="number"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputProps: { 
                                                max: props.maxAmount, min: 0.01 , step: 0.01
                                            }
                                        }}
                                        style={{width: "200px"}}
                                    >
                                    </TextField>}
                                    {usePercentage &&
                                    <TextField
                                        placeholder="0"
                                        label="Refund Percentage"
                                        value={refundPercentage}
                                        required={true}
                                        onChange={handleRefundPercentageChange}
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            inputProps: { 
                                                max: 100, min: 0.01, step: 0.01
                                            }
                                        }}
                                        InputLabelProps={{ shrink: true}}
                                        style={{width: "200px"}}
                                    >
                                    </TextField>
                                    }
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Switch checked={usePercentage} onChange={handlePercentageChange} color="primary"/>}
                                        label="Calculate by Percentage"
                                    >
                                    </FormControlLabel>
                                </Grid>
                            </Grid>
                            <Grid container item direction ="row">
                                {usePercentage &&
                                <TextField
                                placeholder="0"
                                label="Refund Amount"
                                value={refundAmount}
                                style={{width: "200px"}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    readOnly: true,
                                  }}
                                >
                                </TextField>
                                }
                            </Grid>
                            <Grid container item direction="row">
                                <TextField
                                    label="Refund Reason"
                                    value={refundReason}
                                    required={true}
                                    onChange={handleReasonChange}
                                    type="Text"
                                    multiline
                                    rowsMax={4}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" variant="outlined">Cancel</Button>
                    <div className={classes.wrapper}>
                        <Button disabled={!validAmount} onClick={handleRefundClick} color="primary" variant="contained">Refund</Button>
                        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default RefundDialog;

/**
 * This file is a component for E2 UI Customization (This is subject to change based off of mediaType)
 */

import React, {useState, useEffect} from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import PhoneCallbackOutlinedIcon from '@material-ui/icons/PhoneCallbackOutlined';

export default function Callback(props)
{
    const [callback, setCallback] = useState(false);

    /** Set callback to value that is being passed through props */
    useEffect(() => {
        setCallback(props.value);
    },[props.value]);

    /**Handle Changes of callback checkbox */
    const handleCallback = () => {
        setCallback(!callback);
        props.onChange(!callback);
    }

    return(
        <div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={callback}
                        onChange={handleCallback}
                        name="callback"
                        color="primary"
                        />
                    }
                    label="Schedule a Commitment"
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Give permission for callback">
                        <PhoneCallbackOutlinedIcon color="primary"/>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}


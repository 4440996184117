import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Route, Switch, useRouteMatch, NavLink } from 'react-router-dom';
import { List, 
    Divider, 
    ListItem, 
    Drawer, 
    IconButton, 
    ListItemIcon, 
    ListItemText, 
    Collapse } from '@material-ui/core';
import { ChevronLeftOutlined, 
    ChevronRightOutlined, 
    ExpandLess, 
    ExpandMore, 
    DashboardOutlined, 
    AssessmentOutlined, 
    PaymentOutlined,
    MonetizationOnOutlined, 
    CreditCardOutlined,
    FlipToBackOutlined,
    ShowChartOutlined } from '@material-ui/icons'; 
import LayoutState from '../context/LayoutState';
import MessageTemplate from '../ppay/MessageTemplate';
import MessageTemplates from '../ppay/MessageTemplates';
import Dashboard from '../ppay/Dashboard';
import PayProfiles from '../ppay/PaymentProfiles';
import PayProfile from '../ppay/PaymentProfile';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.drawer.open,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: theme.drawer.open,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.drawer.closed,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        width: theme.drawer.open,
    },
    table: {
        minWidth: 650
    },
	content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    root: {
		display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

const PromptPay = function (props) {

    const classes = useStyles();
    let { path } = useRouteMatch();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [dashboardOpen, setDashboardOpen] = useState(true);
    const [configOpen, setConfigOpen] = useState(true);
    const [layoutState, setLayoutState] = useState({
        drawerOpen: true
    });
    const container = props;

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
        setLayoutState({
            ...layoutState,
            drawerOpen: !drawerOpen
        });
    };

    const handleToggleDashboard = () => {
        setDashboardOpen(!dashboardOpen);
    };

    const handleToggleConfig = () => {
        setConfigOpen(!configOpen)
    };
        
        const drawer = (
            <div>
                <div className={classes.toolbar} />
                <div onClick={handleDrawerToggle}>
                    <IconButton>
                        {drawerOpen ? <ChevronLeftOutlined color="primary"/> : <ChevronRightOutlined color="primary" />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button
                        key="0"
                        onClick={handleToggleDashboard}>
                        {dashboardOpen ? <ExpandLess /> : <ExpandMore />}
                        <ListItemIcon><DashboardOutlined color="secondary" /></ListItemIcon>
                        <ListItemText primary="Dashboards" />
                    </ListItem>
                    <Collapse in={dashboardOpen} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem button key="01" className={classes.nested}
                            component={NavLink} to={`/ppay/Dashboard/or`}>
                                <ListItemIcon><AssessmentOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="ReachCX Reporting" />
                            </ListItem>
                            <ListItem button key="01" className={classes.nested}
                            component={NavLink} to={`/ppay/Dashboard/e2`}>
                                <ListItemIcon><ShowChartOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="e2 Reporting" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button
                        key="02"
                        onClick={handleToggleConfig}>
                        {configOpen ? <ExpandLess /> : <ExpandMore />}
                        <ListItemIcon><MonetizationOnOutlined color="secondary" /></ListItemIcon>
                        <ListItemText primary="Payment Configurations"></ListItemText>
                    </ListItem>
                    <Collapse in={configOpen} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem button key="04" className={classes.nested}
                                component={NavLink} to={'/ppay/MessageTemplates'}>
                                <ListItemIcon><FlipToBackOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="Message Templates" />
                            </ListItem>
                            <ListItem button key="05" className={classes.nested}
                                component={NavLink} to={'/ppay/PaymentProfiles'}>
                                <ListItemIcon><CreditCardOutlined color="primary" /></ListItemIcon>
                                <ListItemText primary="Payment Profiles" />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </div>
        );
        
        return (
            <LayoutState.Provider value={layoutState}>
                <div className={classes.root}>
                    <Drawer
                        container={container}
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen
                            })
                        }}>
                        {drawer}
                    </Drawer>
                    <div className={classes.content}>
                    <Switch>
                        <Route path="/ppay/MessageTemplates" exact>
                            <MessageTemplates />
                        </Route>
                        <Route path="/ppay/MessageTemplate/:id">
                            <MessageTemplate />
                        </Route>
                        <Route path="/ppay/Dashboard/or" exact>
                            <Dashboard model="activitylog"/>
                        </Route>
                        <Route path="/ppay/Dashboard/e2" exact>
                            <Dashboard model="ciflog"/>
                        </Route>
                        <Route path="/ppay/PaymentProfiles" exact>
                            <PayProfiles />
                        </Route>
                        <Route path="/ppay/PaymentProfile/:id">
                            <PayProfile />
                        </Route>
                    </Switch>
                    </div>
                </div>
            </LayoutState.Provider>
        );
    }

export default PromptPay;

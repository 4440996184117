import React, { useState, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import {Typography, makeStyles, Grid, Button } from '@material-ui/core';
import * as mutations from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { queries } from '@testing-library/react';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    },
    paper: {
        padding: '15px'
    },
    inputTopPad: {
        paddingTop: '15px !important'
    },
    descriptionField: {
        minWidth: '400px'
    },
    drawerOpenTableWidth: {
        maxWidth: `calc(100vw - ${theme.drawer.open + theme.spacing(4)}px)`
    },
    drawerClosedTableWidth: {
        maxWidth: `calc(100vw - ${theme.drawer.closed + theme.spacing(4)}px)`
    }
}));


/**
 * The segment class implements creation, update and cloning of campains
 * Called Via a route
 * Can have URL params of Segment ID and Action (Edit, or Clone) No params for create
 * * @category Pages
 * @component
 * @param {string} segmentId The component that expects a param of 'data'.
 * @param {string} action The graphql query string to retreive data with.
 */
const TestRedirector = function (props) {
//Query String from MS should look like this
//Approved
//admin_consent=True&tenant=a0fc534f-b940-4218-86d1-4f6c9932a5af&state=12345
//Denied
//error=access_denied&error_subcode=cancel&state=12345

function handleCancel(){
    props.history.push('/writer/?error=access_denied&error_subcode=cancel&state=12345');
}

function handleApprove(){
    props.history.push('/writer/?admin_consent=True&tenant=a0fc534f-b940-4218-86d1-4f6c9932a5af&state=12345');
}

return (
    <div>
    <Typography variant = "h6" color="primary">Testing The Microsoft Redirect</Typography>
    <Grid container spacing={2}>
        <Grid  item xs={6}>
            <Button color="primary" variant="outlined" onClick={handleCancel}>Reject Request</Button>
        </Grid>
        <Grid  item xs={6}>
            <Button color="primary" variant="contained" onClick={handleApprove}>Approve Request</Button>
        </Grid>
    </Grid>
    </div>
)

}

export default withRouter(TestRedirector);
import React, { useState, useContext, useEffect } from 'react';
import * as _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Storage, API, Auth, graphqlOperation } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress'
import { Select, MenuItem } from '@material-ui/core';
import UserContext from '../context/UserContext';
import { useSnackbar } from 'notistack';
import * as queries from '../graphql/queries';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    input: {
        display: 'none'
    },
    spinner: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2)
    },
    inputFields: {
        width: '200px'
    },
    deleteMargin: {
        marginTop: '1em'
    },
    mappingSelect: {
        minWidth: '150px'
    },
    outlinedPaper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

/**
 * This page component handles uploading CSV files to an S3 bucket so we can then process them with
 * a lambda function and insert the rows in the database.
 * 
 * The UI contains the ability to specify how fields from their files can be mapped to our existing fields.
 * @category Pages
 * @component
 * @param {any} props React props object
 */
const Import = function (props) {
    const classes = useStyles();
    const snackBar = useSnackbar();
    const userContext = useContext(UserContext);
    const [fileUploading, setFileUploading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [contactsImporting, setContactsImporting] = useState(false);
    const [contactsImported, setContactsImported] = useState(false);
    const [signedUrl, setSignedUrl] = useState('');
    const [fieldMapping, setFieldMapping] = useState([]);
    const [tenant, setTenant] = useState('');
    const [fieldMappings, setFieldMappings] = useState([]);


    useEffect(() => {
        async function getData() {
            const fieldMappingsResponse = await API.graphql(graphqlOperation(queries.tenantMappings, { tenant: userContext.tenant }));
            setFieldMappings(fieldMappingsResponse.data.tenantMappings.items);
        }
        if (userContext.tenant) {
            setTenant(userContext.tenant);
            getData();
        }
    }, [userContext.tenant]);

    /**
     * The click handler for uploading a file to S3.
     * @param {any} event The click event object
     */
    const handlePick = async (event) => {
        console.log(event);

        if (!event.target.files[0].name.endsWith(".csv")) {
            snackBar.enqueueSnackbar('File is not of the correct type', {variant: 'error'});
        }
        else{
            setFileUploading(true);
            setFileUploaded(false);
            const file = event.target.files[0];
            const { name, type } = file;
            try {
                const key = `contacts/${_.snakeCase(name)}`;
                const uploadData = await Storage.put(
                    key,
                    file,
                    {
                        level: 'private',
                        contentType: type
                    }
                );

                const signedUrl = await Storage.get(uploadData.key, { level: 'private' });
                setFileUploading(false);
                setFileUploaded(true);
                setSignedUrl(signedUrl);

            } catch (err) {
                console.error(err);
            }
        }
    }

    /**
     * The click handler to kick of the process of importing contacts. If there is mapping setup
     * it will also send that data in.
     * @param {any} event The click event object.
     */
    const importContacts = async (event) => {
        setFileUploaded(false);
        setContactsImporting(true);
        const cognitoUser = await Auth.currentSession();
        try {
            const postResponse = await API.post(
                'importapi',
                '/contacts/import',
                {
                    body: {
                        contactsUrl: signedUrl,
                        jwtToken: cognitoUser.getAccessToken().getJwtToken(),
                        tenant: tenant,
                        fieldMapping: fieldMapping
                    },
                    timeout: 90000
                }
            );
            setContactsImporting(false);
            setContactsImported(true);
            console.log('File was processed', postResponse);
        } catch (err) {
            snackBar.enqueueSnackbar(err.message);
            setFileUploaded(true);
            setContactsImporting(false);
        }
    }
    /**
     * Reset the page to its base state.
     */
    const reset = () => {
        setFileUploaded(false);
        setContactsImported(false);
        setFieldMapping([]);
    }

    /**
     * Handle {@link Import~MappingRule} change event.
     * @param {Import~MappingRow} row The row data.
     */
    const mappingChanged = (event) => {
        setFieldMapping(event.target.value);
    }

    return (
        < Grid container direction="column" spacing={2} justify="flex-start" alignItems="stretch">
            <Grid item container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4">Import Contacts</Typography>
                </Grid>
                <Grid item></Grid>
            </Grid>
            <Grid item>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid container item md={6} xs={12} spacing={2} direction="column" justify="flex-start" alignItems="stretch">
                            {fieldMappings.length === 0 &&
                                <Grid item>
                                    <Typography>No mappings created yet... </Typography>
                                    <Button variant="outlined" color="primary" component={NavLink} to="/config/field-mappings/new">Create a mapping</Button>
                                </Grid>
                            }
                            {fieldMappings.length > 0 &&
                                <Grid item container spacing={2} direction="row" justify="flex-start">
                                    <Typography>Select a mapping configuration:</Typography>
                                    <Select
                                        onChange={mappingChanged}
                                        className={classes.mappingSelect}
                                    >
                                        {fieldMappings.map(mapping => (
                                            <MenuItem value={mapping.mappings}>{mapping.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            }
                            <Grid item>
                                Please select a CSV file to import contacts from:
                            </Grid>
                            <Grid container item direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <input
                                        accept=".csv"
                                        id="import-file-upload"
                                        type="file"
                                        className={classes.input}
                                        onChange={handlePick} />
                                    <label htmlFor="import-file-upload">
                                        <Button disabled={fieldMapping.length === 0} variant="contained" color="primary" component="span">
                                            Upload
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid item></Grid>
                            </Grid>
                        </Grid>
                        <Grid container item md={6} xs={12} spacing={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Paper variant="outlined" className={classes.outlinedPaper} >
                                <Grid container direction="column" spacing={1} justify="flex-start" alignItems="stretch">
                                    <Grid item>
                                        <Typography variant="overline">Mapping Details:</Typography>
                                    </Grid>
                                    {fieldMapping.map(mapping => (
                                        <Grid item>
                                            {`${mapping.fromField} -> ${mapping.toField}`}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    {(fileUploaded || contactsImported || fileUploading || contactsImporting) &&
                        <Grid container item spacing={2} direction="column" justify="flex-start" alignItems="center">
                            {fileUploading &&
                                <Grid container item direction="column" justify="flex-start" alignItems="center">
                                    <Typography>Your file is uploading.</Typography>
                                    <LinearProgress className={classes.spinner} color="secondary" />
                                </Grid>
                            }
                            {fileUploaded &&
                                <Grid container item direction="column" justify="flex-start" alignItems="center">
                                    <Typography>Thanks for uploading your file!</Typography>
                                    <Button variant="contained" color="primary" onClick={importContacts}>
                                        Import Contacts
                                            </Button>
                                </Grid>
                            }
                            {contactsImporting &&
                                <Grid container item direction="column" justify="flex-start" alignItems="center">
                                    <Typography>Your contacts are importing.</Typography>
                                    <LinearProgress className={classes.spinner} color="secondary" />
                                </Grid>
                            }
                            {contactsImported &&
                                <Grid container item direction="column" justify="flex-start" alignItems="center">
                                    <Typography>Your file is being processed. We will email you when it is done.</Typography>
                                    <Button variant="outlined" color="primary" onClick={reset}>
                                        Import another file
                                    </Button>
                                </Grid>
                            }

                        </Grid>
                    }
                </Paper>
            </Grid>
        </Grid>
    )

}

export default Import;
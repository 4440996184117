import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Bubble } from 'react-chartjs-2';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';

/**
 * A pie chart visualization for displaying the aggregated sources of dispostitioned contacts 
 * @component
 */
const BubbleChart = function(props) {
    const [data, setData] = useState({});
    const propsTitle = props.title;
    
    useEffect(() => {
        
        async function getData() {
            try {
                const idToken = await (await Auth.currentSession()).getIdToken();
                const tenant = idToken.payload['custom:tenant'];
                const propsField = props.field;
                const propsFilter = props.filter;
                
                let query = {};                
                if (propsFilter){
                    query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                    "query":{
                        "bool":{
                            "must":{
                                "term": {"tenant.keyword": tenant}
                            },
                            "must_not":{
                                "term": {[propsField]:propsFilter}
                            }
                        }
                    },
                    "size":0}
                }
                else{
                    query = {"aggs":{"dispo":{"terms":{"field":propsField}}},
                    "query":{
                        "bool":{
                            "must":{
                                "term": {"tenant.keyword": tenant}
                            }
                        }
                    },    
                        "size":0}    
                }    
               
                const resultJSON = await API.graphql(graphqlOperation(queries.esQuery, { 
                    model: props.model,
                    action: '_search',
                    query: JSON.stringify(query)
                }));
                const queryResult = JSON.parse(resultJSON.data.esQuery);
                let resData = queryResult['aggregations'].dispo.buckets;
                const dataValue = resData.map(obj => {return obj.doc_count});
                const labelValue = resData.map(obj => {return obj.key});
                const colorTheme = [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 99, 132, 0.3)',
                    'rgba(54, 162, 235, 0.3)',
                    'rgba(255, 206, 86, 0.3)',
                    'rgba(75, 192, 192, 0.3)',
                    'rgba(153, 102, 255, 0.3)',
                    'rgba(255, 159, 64, 0.3)'
                ];
                setData({ datasets: [{ data: dataValue, backgroundColor: colorTheme}], labels: labelValue});
                //console.log(data);

        
            } catch (err) {
                console.error(err);
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.title,props.filter, props.field]);

     

    return (
        <Grid item >
            < Bubble data={data} options={{title:{display: true, text:propsTitle},maintainAspectRatio: true,  scales: {
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                }
                            }]
                        }}}/>
        </Grid>
 );
}


export default BubbleChart;
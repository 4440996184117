export const getCreateContactData = `query GetEnums {
    contactStatus: __type(name: "ContStatus") {
      enumValues {
        name
      }
    }
  }`;

export const getCreateProfileData = `query getLists($tenant:ID) {
    segments:
      segmentByTenant(tenant: $tenant, limit: 100) {
          items{
            id
            name
          }
    			nextToken
        }
    profiles:
      listProfiles(limit: 100) {
        items{
          id
          name
        }
    		nextToken
      }
    campaigns:
      campaignsByTenant(tenant: $tenant, limit: 100) {
        items{
          id
          skillId
          status
        }
    		nextToken
      }
    }
`;


export const getCreateAccountData = `query GetEnums {
    
    accountStatus: __type(name: "AcctStatus") {
      enumValues {
        name
      }
    }

    accountIndustry: __type(name: "AcctIndustry") {
      enumValues {
        name
      }
    }
    
    
  }`;

export const getCreateCampaignData = `query GetEnums {
    campaignStatus: __type(name: "CampaignStatus") {
      enumValues {
        name
      }
    }
   }`;

export const getWorkBundleStatus = `query GetEnums {
    workBundleStatus: __type(name: "WorkBundleStatus") {
      enumValues {
        name
      }
    }
  }`;

export const listAccounts = `query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dateCreated
        industry
        status
        address1
        address2
        city
        state
        zip
        timeZoneID
        timeZone {
          id
          abbrev
          name
          offSet
        }
        website
        ticker
      }
      nextToken
    }
  }
  `;

export const createActivityLogDeleteContact =
  `mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      agent
      timestamp
      metaData
    }
  }
  `;

export const screenPop =
  `query ScreenPop($tenant: ID!, $contactId: ID!) {
    getTenantSettings(id: $tenant) {
      id
      customFields {
        name
        displayName
        type
      }
      apiKey
    }
    getContact(id: $contactId) {
      id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        optout {
            Voice
            Email
            SMS
        }
    }
    getContactActivity(activityLogContactId: $contactId) {
      items {
          id
          tenant
          type
          disposition
          title
          body
          activityLogContactId
          contact {
            id
            tenant
            externalId
            firstName
            lastName
            source
            phone
            cell
            email
            dateCreated
            address1
            address2
            city
            state
            zip
            timeZone
            dnc
            updatedBy
            customFields
            expireDt
            complianceRequired
            preview
            outboundCallerId
          }
          segment {
            id
            tenant
            name
            description
          }
          agent
          timestamp
          metaData
        }
        nextToken
      
    }
  }`;

  export const onActivityLogCreate = `
  subscription OnTenantCreateActivityLog($tenant: ID!) {
    onTenantCreateActivityLog(tenant: $tenant) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      agent
      metaData
    }
  }
`;